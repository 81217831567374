import { Helmet } from 'react-helmet-async'
import { useQuery } from 'urql'
import NotFound from '../404/NotFound'
import Html from '../data/Html'
import Loader from '../shared/Loader'
import ShopListingsLayout from './ShopListingsLayout'
import useSearchQuery, {
  DEFAULT_PER_PAGE,
  DEFAULT_SORT,
} from './useSearchQuery'
import { flattenFilters } from './utilities'

const GET_PAGE = `
  query GetPage {
    entry(section: "shop") {
      id
      title
      url
      ... on shop_shop_Entry {
        heroTitle
        heroText
        seoTitle
        seoDescription
        sectionLinks {
          ... on sectionLinks_item_BlockType {
            image {
              id
              xs: url(transform: "xs")
              small: url(handle: "small")
              width
              height
              title
            }
            itemLink {
              text
              url
              element {
                ... on categories_categories_Entry {
                  sectionLinkImage {
                    id
                    xs: url(transform: "xs")
                    small: url(handle: "small")
                    width
                    height
                    title
                  }
                }
                ... on collections_default_Entry {
                  sectionLinkImage {
                    id
                    xs: url(transform: "xs")
                    small: url(handle: "small")
                    width
                    height
                    title
                  }
                }
                ... on brands_brands_Entry {
                  sectionLinkImage {
                    id
                    xs: url(transform: "xs")
                    small: url(handle: "small")
                    width
                    height
                    title
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const GET_CANONICAL_ENTRY = `
  query GetCanonicalEntry($section: [String], $title: [String], $relatedToEntries: [EntryCriteriaInput]) {
    entry(section: $section, title: $title, relatedToEntries: $relatedToEntries) {
      id
      url
    }
  }
`

export default function ShopCategoryPage() {
  const [{ data, fetching, error }] = useQuery({ query: GET_PAGE })

  const {
    query: { keywords, filters },
    paginationVars: { perPage },
    sort,
  } = useSearchQuery()

  let noindex =
    !!keywords ||
    (sort && sort !== DEFAULT_SORT) ||
    (perPage && perPage !== DEFAULT_PER_PAGE) ||
    filters.length > 1

  const variables = {}
  if (!noindex && filters.length === 1) {
    const flatFilters = flattenFilters(filters)
    if (flatFilters.length === 1) {
      if (flatFilters[0].key === 'category') {
        // Search for a canonical category entry by title
        variables.section = 'categories'
        variables.title = flatFilters[0].value
      } else if (flatFilters[0].key === 'brand') {
        // Search for a canonical brand entry by title
        variables.section = 'brands'
        variables.title = flatFilters[0].value
      } else {
        noindex = true
      }
    } else {
      noindex = true
    }
  }

  let [{ data: canonicalData }] = useQuery({
    query: GET_CANONICAL_ENTRY,
    variables,
    pause: noindex || !Object.keys(variables).length,
  })

  if (canonicalData && (noindex || !Object.keys(variables).length)) {
    canonicalData = null
  }

  if (fetching) return <Loader background="#fff" />

  if (error) return <p>{error.message}</p>

  if (!data.entry) {
    return <NotFound />
  }

  const { entry } = data

  return (
    <>
      <Helmet>
        {noindex ? (
          <meta name="robots" value="noindex" />
        ) : (
          <link rel="canonical" href={canonicalData?.entry?.url || entry.url} />
        )}
      </Helmet>
      <ShopListingsLayout
        heroTitle={entry.heroTitle || entry.title}
        heroText={entry.heroText && <Html html={entry.heroText} />}
        sectionLinks={entry.sectionLinks}
        seoTitle={entry.seoTitle || entry.title}
        seoDescription={entry.seoDescription}
      />
    </>
  )
}
