import PropTypes from 'prop-types'
import styled from 'styled-components'
import { SmallLetters } from '../_abstracts/Type'
import Button, { ButtonStyle } from '../_atoms/Button'
import { useMutation } from 'urql'
import Warning from '../shared/Warning'

const Info = styled.div`
  display: flex;
  gap: 15px;

  ${ButtonStyle} {
    ${SmallLetters}
  }
`

const UPDATE_STATE_MUTATION = `
mutation FrontendUpdateState($id: ID!) {
  lineItemUpdateState(id: $id, state: SHIP_TO_BUYER, audit: { craftUserId: 0 }) {
    success
    lineItem {
      id
      state
    }
  }
}
`

const BroughtToShippingButton = ({ lineItem }) => {
  const [, setLineItemState] = useMutation(UPDATE_STATE_MUTATION)

  return (
    <Info>
      {lineItem.state === 'SOLD' ? (
        <Button
          onClick={() => {
            setLineItemState({
              id: lineItem.id,
            })
          }}
        >
          Brought to shipping team
        </Button>
      ) : (
        <Warning color="aqua" text="Item has been brought to shipping" />
      )}
    </Info>
  )
}

BroughtToShippingButton.propTypes = {
  lineItem: PropTypes.shape({
    id: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
  }).isRequired,
}

export default BroughtToShippingButton
