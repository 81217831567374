import styled, { css } from 'styled-components'
import { up } from 'styled-breakpoints'
import { useQuery } from 'urql'
import { HeaderM } from '../_abstracts/Type'
import Meta from '../_helpers/Meta'
import { Navigate } from 'react-router-dom'
import NotFound from '../404/NotFound'
import Container from '../_helpers/Container'
import Loader from '../shared/Loader'
import { UserConsumer } from './UserContext'
import RegisterForm from './RegisterForm'
import LoginForm from './LoginForm'

const GET_PAGE = `
  query GetPage {
    entry(section: "loginRegister") {
      id
      title
      url
      ... on loginRegister_loginRegister_Entry {
        heroTitle
        loginRegisterLoginHeading
        loginRegisterRegisterHeading
        seoTitle
        seoDescription
      }
    }
  }
`

const Wrapper = styled.div`
  flex-grow: 1;
  padding-top: 25px;
  padding-bottom: 25px;
  background: ${(props) => props.theme.colors.lighterGrey};

  ${up('medium')} {
    padding-bottom: 50px;
  }
`

const Inner = styled(Container)`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
`

const Hero = styled.h1`
  ${HeaderM}
  width: 100%;
  text-align: center;
  padding: 13px;
  margin-bottom: 20px;
  font-weight: 600;
`

const Item = styled.div`
  width: 100%;
  border: 1px solid transparent;
  border-radius: 5px;
  margin-bottom: 20px;
  border-color: ${(props) =>
    props.color === 'accent'
      ? props.theme.colors.accent
      : props.theme.colors.midGrey};

  ${(props) =>
    props.color === 'accent'
      ? css`
          border-color: ${(props) => props.theme.colors.accent};
          border-bottom: 1px solid ${(props) => props.theme.colors.accent};
          background-color: ${(props) => props.theme.colors.accentTint};

          ${Header} {
            border-bottom: 1px solid ${(props) => props.theme.colors.accent};
          }
        `
      : css`
          border-color: ${(props) => props.theme.colors.midGrey};
          background-color: ${(props) => props.theme.colors.white};

          ${Header} {
            border-bottom: 1px solid ${(props) => props.theme.colors.midGrey};
            background-color: ${(props) => props.theme.colors.lightGrey};
          }
        `}

  ${up('medium')} {
    width: 48%;
    margin-bottom: 0;
  }
`

const Header = styled.h2`
  ${HeaderM}
  width: 100%;
  text-align: center;
  padding: 13px;
  font-weight: 600;
  border-top-right-radius: inherit;
  border-top-left-radius: inherit;
`
const Content = styled.div`
  padding: 25px;

  ${up('medium')} {
    padding: 35px;
  }
`

export default function RegisterPage() {
  const [{ data, fetching, error }] = useQuery({ query: GET_PAGE })

  return (
    <UserConsumer>
      {({ user, reloadUser }) => {
        if (user) return <Navigate to="/" />

        if (fetching) return <Loader background="#fff" />

        if (error) return <p>{error.message}</p>

        if (!data.entry) {
          return <NotFound />
        }

        const { entry } = data

        return (
          <>
            <Meta
              title={entry.seoTitle || entry.title}
              description={entry.seoDescription}
              url={entry.url}
            />

            <Wrapper>
              <Inner>
                {entry.heroTitle && <Hero>{entry.heroTitle}</Hero>}

                <Item color="accent">
                  {entry.loginRegisterLoginHeading && (
                    <Header>{entry.loginRegisterLoginHeading}</Header>
                  )}
                  <Content>
                    <LoginForm reloadUser={reloadUser} />
                  </Content>
                </Item>

                <Item color="grey">
                  {entry.loginRegisterRegisterHeading && (
                    <Header>{entry.loginRegisterRegisterHeading}</Header>
                  )}
                  <Content>
                    <RegisterForm reloadUser={reloadUser} />
                  </Content>
                </Item>
              </Inner>
            </Wrapper>
          </>
        )
      }}
    </UserConsumer>
  )
}
