import PropTypes from 'prop-types'
import Money from '../data/Money'

export default function OrderSummaryTable({ order }) {
  return (
    <table>
      <tbody>
        <tr>
          <th>Subtotal</th>
          <td>
            <Money
              amount={order.subTotal.amount}
              currency={order.subTotal.currency.iso}
            />
          </td>
        </tr>

        <tr>
          <th>Shipping</th>
          <td>
            <Money
              amount={order.shippingCost.amount}
              currency={order.shippingCost.currency.iso}
            />
          </td>
        </tr>

        <tr>
          <th>Sales Tax</th>
          <td>
            <Money
              amount={order.tax.amount}
              currency={order.tax.currency.iso}
            />
          </td>
        </tr>

        {order.discountTotal.amountCents > 0 && (
          <tr>
            <th>Discount</th>
            <td>
              <Money
                amount={order.discountTotal.amount}
                currency={order.discountTotal.currency.iso}
              />
            </td>
          </tr>
        )}

        <tr>
          <th>Total</th>
          <td>
            <Money
              amount={order.total.amount}
              currency={order.total.currency.iso}
            />
          </td>
        </tr>

        {order.paymentBalance && order.paymentBalance.amountCents > 0 && (
          <>
            <tr>
              <th>Already paid</th>
              <td>
                <Money
                  amount={order.paymentBalance.amount}
                  currency={order.paymentBalance.currency.iso}
                />
              </td>
            </tr>
            <tr>
              <th>Remaining Balance</th>
              <td>
                <Money
                  amount={order.amountRemainingLessRefunds.amount}
                  currency={order.amountRemainingLessRefunds.currency.iso}
                />
              </td>
            </tr>
          </>
        )}
      </tbody>
    </table>
  )
}

OrderSummaryTable.propTypes = {
  order: PropTypes.shape({
    subTotal: PropTypes.shape({
      amount: PropTypes.string.isRequired,
      currency: PropTypes.shape({
        iso: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    shippingCost: PropTypes.shape({
      amount: PropTypes.string.isRequired,
      currency: PropTypes.shape({
        iso: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    discountTotal: PropTypes.shape({
      amount: PropTypes.string.isRequired,
      amountCents: PropTypes.number.isRequired,
      currency: PropTypes.shape({
        iso: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    tax: PropTypes.shape({
      amount: PropTypes.string.isRequired,
      currency: PropTypes.shape({
        iso: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    amountRemaining: PropTypes.shape({
      amount: PropTypes.string.isRequired,
      currency: PropTypes.shape({
        iso: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    amountRemainingLessRefunds: PropTypes.shape({
      amount: PropTypes.string.isRequired,
      currency: PropTypes.shape({
        iso: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    total: PropTypes.shape({
      amount: PropTypes.string.isRequired,
      currency: PropTypes.shape({
        iso: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    paymentBalance: PropTypes.shape({
      amount: PropTypes.string.isRequired,
      amountCents: PropTypes.number.isRequired,
      currency: PropTypes.shape({
        iso: PropTypes.string.isRequired,
      }).isRequired,
    }),
  }).isRequired,
}
