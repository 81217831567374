import { hideVisually } from 'polished'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  Icon,
  AmexIcon,
  DiscoverIcon,
  MastercardIcon,
  VisaIcon,
} from '../_base/Icon'

export const Wrapper = styled.div`
  width: 36px;

  ${Icon} {
    width: 100%;
  }
`

const Title = styled.span`
  ${hideVisually};
`

// In case payment options change, current icons are from: https://github.com/slaterjohn/payment-logos/tree/master/Rounded%20Corners/SVG

export const icons = {
  amex: {
    title: 'American Express',
    icon: <AmexIcon />,
  },
  visa: { title: 'Visa', icon: <VisaIcon /> },
  mastercard: { title: 'Mastercard', icon: <MastercardIcon /> },
  discover: { title: 'Discover', icon: <DiscoverIcon /> },
}

export default function PaymentIcon({ icon }) {
  return icons[icon] ? (
    <Wrapper>
      <Title>{icons[icon].title}</Title>
      {icons[icon].icon}
    </Wrapper>
  ) : null
}

PaymentIcon.propTypes = {
  icon: PropTypes.oneOf(Object.keys(icons)).isRequired,
}
