import { useContext, useEffect } from 'react'
import { UNSAFE_NavigationContext } from 'react-router-dom'

export default function ScrollToTop() {
  const navigator = useContext(UNSAFE_NavigationContext).navigator

  useEffect(() => {
    const listener = ({ location, action }) => {
      // Reset scroll position when navigating (unless action is 'POP', e.g. browser back button)
      // `state.scrollToTop` can be set to `false` for links that should not reset scroll position (e.g. tabs)
      if (action === 'PUSH' && location.state?.scrollToTop !== false) {
        window.scrollTo(0, 0)
      }
    }

    const unlisten = navigator.listen(listener)
    return unlisten
  }, [navigator])

  return null
}
