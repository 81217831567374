export const offerStates = {
  OFFERED: 'OFFERED',
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
  COUNTERED: 'COUNTERED',
}

export default function OfferState({ state }) {
  switch (state) {
    case offerStates.OFFERED:
      return 'Offer Received'
    case offerStates.ACCEPTED:
      return 'Offer Accepted'
    case offerStates.REJECTED:
      return 'Offer Rejected'
    case offerStates.COUNTERED:
      return 'Offer Countered'
    default:
      return 'Unknown'
  }
}
