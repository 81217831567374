import PropTypes from 'prop-types'
import { useRef, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

// Portal wrapper to avoid SSR undefined document error
// Taken from https://github.com/vercel/next.js/tree/canary/examples/with-portals/components

export default function ClientOnlyPortal({ children, selector }) {
  const ref = useRef()
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    ref.current = document.querySelector(selector)
    setMounted(true)
  }, [selector])

  return mounted ? createPortal(children, ref.current) : null
}

ClientOnlyPortal.defaultProps = {
  selector: 'body',
}

ClientOnlyPortal.propTypes = {
  children: PropTypes.node.isRequired,
  selector: PropTypes.string.isRequired,
}
