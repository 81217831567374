import PropTypes from 'prop-types'
import Carousel, { themeColor } from './Carousel'
import { useRecentlyViewed } from '../hooks'
import Loader from './Loader'

export default function RecentlyViewedCarousel({ theme, ...props }) {
  const { items, fetching } = useRecentlyViewed()

  if (fetching) {
    return <Loader background={themeColor[theme]} height="200px" />
  }

  if (items.length) {
    return <Carousel listings={items} theme={theme} {...props} />
  }

  return null
}

RecentlyViewedCarousel.defaultProps = {
  header: 'Recently Viewed',
  theme: 'light',
  view: '/recently-viewed',
  viewText: 'View All',
}

RecentlyViewedCarousel.propTypes = {
  header: PropTypes.string.isRequired,
  theme: PropTypes.oneOf(['light', 'dark', 'grey', 'lightGrey']).isRequired,
  view: PropTypes.string,
  viewText: PropTypes.string,
}
