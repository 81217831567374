import { useState, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import styled from 'styled-components'
import { down } from 'styled-breakpoints'
import { useQuery } from 'urql'
import { ButtonStyle } from '../_atoms/Button'
import SearchBar, { Form as SearchForm } from '../search/SearchBar'

import { scrollToElement } from '../_abstracts/Layout'
import Subheader from '../shared/Subheader'
import Order from './Order'
import Loader from '../shared/Loader'
import NextPrevious from '../shared/NextPrevious'

const DEFAULT_ITEMS_PER_PAGE = 12

const GET_ORDERS = `
  query GetOrders($first: Int, $last: Int, $after: String, $before: String, $keywords: String) {
    orders: ordersCompleted(first: $first, last: $last, after: $after, before: $before, filter: AS_SELLER, keywords: $keywords) {
      totalCount

      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }

      edges {
        node {
          id
          createdAt
          completedAt
          fullyPaidAt

          shipment {
            id
            status
            carrierName
            trackingNumber
            trackingUrl
          }

          canSetTracking

          seller {
            id
            username
          }

          # Buyer account
          account {
            id
            username
          }

          total {
            amount
            amountCents
            currency { iso }
          }

          paymentBalance {
            amount
            amountCents
            currency { iso }
          }

          refundedAmount {
            amount
            amountCents
            currency { iso }
          }

          paymentState

          lineItems {
            id
            state

            listing {
              id
              createdAt
              viewCount
              type

              item {
                id
                slug
                title
                offerCount
                wishlistCount

                images {
                  id
                  url(size: "orders")
                }
              }

              askingPrice {
                amount
                currency { iso }
              }
            }

            amount {
              amount
              currency { iso }
            }
          }
        }
      }
    }
  }
`

const Grid = styled.div`
  display: flex;
  border-top: 1px solid ${(props) => props.theme.colors.midGrey};
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;

  ${down('medium')} {
    flex-wrap: wrap;
  }
`

const Middle = styled.div`
  flex-grow: 1;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
`

const Message = styled.p`
  padding: 30px;
  text-align: center;
`

const Search = styled.div`
  display: flex;
  justify-content: center;

  ${SearchForm} {
    max-width: 600px;
  }

  ${ButtonStyle} {
    margin: 0;
  }
`

export default function SellingOrdersPage() {
  const panelRef = useRef()
  const [pagination, setPagination] = useState({
    first: DEFAULT_ITEMS_PER_PAGE,
  })

  const [searchParams] = useSearchParams()
  const keywords = searchParams.get('q')

  const [{ fetching, data, error }] = useQuery({
    query: GET_ORDERS,
    variables: {
      keywords,
      ...pagination,
    },
  })

  const scrollToTop = () => {
    if (panelRef.current) scrollToElement(panelRef.current)
  }

  if (error) {
    console.error(error)
  }

  const {
    orders: { totalCount, pageInfo, edges },
  } = data || { orders: {} }

  // Create an order object per line item, as the UI shows each line item as a separate order row
  let orders = edges
    ?.map(({ node: { lineItems, ...order } }) =>
      lineItems.map((lineItem) => {
        lineItem.listing.createdAt = new Date(lineItem.listing.createdAt)
        order.createdAt = new Date(order.createdAt)
        order.completedAt = new Date(order.completedAt)
        if (order.fullyPaidAt) order.fullyPaidAt = new Date(order.fullyPaidAt)

        return {
          lineItem,
          order,
        }
      })
    )
    .flat()

  return (
    <>
      <Helmet>
        <title>Profile | Selling | Orders</title>
      </Helmet>
      <Subheader header="Orders">
        {totalCount ? (
          <p>
            {totalCount} order{totalCount === 1 ? '' : 's'}
          </p>
        ) : null}
        <Search>
          <SearchBar
            action=""
            name="q"
            placeholder="Search orders"
            scrollToTop={false}
          />
        </Search>
      </Subheader>
      <Grid ref={panelRef}>
        <Middle>
          {(orders && (
            <>
              {orders.length ? (
                orders.map((order, index) => {
                  return <Order key={`${index}-${order.order.id}`} {...order} />
                })
              ) : (
                <Message>No sales history</Message>
              )}

              <NextPrevious
                includeNext={pageInfo?.hasNextPage || !!pagination.before}
                includePrevious={
                  pageInfo?.hasPreviousPage || !!pagination.after
                }
                onNext={() => {
                  scrollToTop()
                  setPagination({
                    after: pageInfo.endCursor,
                    first: DEFAULT_ITEMS_PER_PAGE,
                  })
                }}
                onPrevious={() => {
                  scrollToTop()
                  setPagination({
                    before: pageInfo.startCursor,
                    last: DEFAULT_ITEMS_PER_PAGE,
                  })
                }}
              />
            </>
          )) ||
            (fetching && <Loader background="#fff" height="250px" />) ||
            (error && <Message>{error.message}</Message>)}
        </Middle>
      </Grid>
    </>
  )
}

SellingOrdersPage.propTypes = {}
