import { Field } from 'formik'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Input, { InputField } from '../_atoms/Input'

const Wrapper = styled(Input)`
  ${InputField} {
    padding-right: 70px;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`

export default function Currency({ required, currencyFieldName, ...props }) {
  return (
    <>
      {currencyFieldName && (
        <Field name={currencyFieldName}>
          {({ field }) => <input {...field} type="hidden" />}
        </Field>
      )}
      <Wrapper
        min={0}
        required={required}
        step="any"
        type="number"
        prefix="$"
        suffix="USD"
        parse={(value) => {
          const parsed = parseFloat(value)
          return isNaN(parsed) ? '' : parsed
        }}
        {...props}
      />
    </>
  )
}

Currency.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.node.isRequired,
  note: PropTypes.node,
  required: PropTypes.bool,
  currencyFieldName: PropTypes.string,
}
