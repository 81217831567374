import PropTypes from 'prop-types'
import { down } from 'styled-breakpoints'

import styled from 'styled-components'

import DateTime from '../data/DateTime'
import Transition from '../_abstracts/Animation'
import { Flag2Icon, Icon } from '../_base/Icon'
import { Media, MediaImage } from '../_base/Media'
import CardList from '../shared/CardList'
import ListingCard from '../shared/ListingCard'
import MessageBubble from './MessageBubble'
import Offer, { Wrapper as OfferWrapper } from '../shared/Offer'

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  ${down('small')} {
    flex-direction: column-reverse;
  }
`

const Header = styled.div`
  position: absolute;
  display: flex;
  justify-content: flex-end;
  font-size: 12px;
  color: ${(props) => props.theme.colors.xDarkGrey};
  top: 0;
  right: 0;
  transform: translateY(-10px);

  ${down('small')} {
    position: relative;
    transform: translateY(0);
    margin-top: 20px;
  }

  div + div {
    margin-left: 20px;
  }
`

const InnerWrapper = styled.div``

const Report = styled.div`
  display: flex;
  align-items: center;
  text-decoration: underline;
  text-decoration-color: transparent;
  cursor: pointer;
  ${Transition({ property: 'text-decoration-color' })}

  ${Icon} {
    width: 10px;
    margin-right: 5px;
  }
  svg path {
    stroke: ${(props) => props.theme.colors.xDarkGrey};
    fill: none;
  }
  &:hover {
    text-decoration-color: currentColor;
  }
`

const Message = styled.div`
  margin-top: 15px;

  ${down('small')} {
    margin-top: 0;
  }

  ${OfferWrapper} {
    background-color: transparent;
    border: 0;
    border-radius: 0;
  }
`

const MediaGroup = styled.div`
  margin-top: 20px;
  cursor: pointer;
  ${Media} {
    height: 290px;
  }
`

const ListingGroup = styled.div`
  margin-top: 20px;
`

export default function StandardMessage({
  date,
  state,
  message,
  media,
  listings,
  user,
  offers,
  refetchMessages,
}) {
  return (
    <MessageBubble user={user} state={state} color={state}>
      <Wrapper>
        <Header>
          <div>
            <DateTime date={date} />
          </div>
          {false && state != 'sent' && (
            <Report>
              <Flag2Icon /> Report user
            </Report>
          )}
        </Header>
        <InnerWrapper>
          <div>
            <Message>
              {message}

              {offers?.map((offer) => (
                <Offer
                  key={offer.id}
                  includeReply={false}
                  item={offer.item}
                  offer={offer}
                  onStateChange={refetchMessages}
                  layout="minimal"
                />
              ))}
            </Message>
            {media && (
              <MediaGroup>
                <CardList minWidth="200px">
                  {media.map((img, index) => (
                    <Media key={index}>
                      <MediaImage src={img.url} />
                    </Media>
                  ))}
                </CardList>
              </MediaGroup>
            )}
            {listings && (
              <ListingGroup>
                <CardList minWidth="200px">
                  {listings.map((listing, index) => (
                    <ListingCard key={index} {...listing} />
                  ))}
                </CardList>
              </ListingGroup>
            )}
          </div>
        </InnerWrapper>
      </Wrapper>
    </MessageBubble>
  )
}

StandardMessage.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  state: PropTypes.PropTypes.oneOf(['sent', 'received']),
  message: PropTypes.node,
  media: PropTypes.array,
  listings: PropTypes.array,
  user: PropTypes.shape({
    username: PropTypes.string.isRequired,
  }).isRequired,
  offers: PropTypes.arrayOf(PropTypes.object),
  refetchMessages: PropTypes.func,
}
