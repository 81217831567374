import { Helmet } from 'react-helmet-async'
import { useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import { useQuery } from 'urql'
import Container from '../_helpers/Container'
import Spacer from '../_helpers/Spacer'
import { UserConsumer } from '../auth/UserContext'
// import DateTime from '../data/DateTime'
import { useGlobals } from '../hooks'
import Alert from '../shared/Alert'
import Back from '../shared/Back'
import Loader from '../shared/Loader'
import Translation from '../shared/Translation'
import Editor from './Editor'
import MessageBubble from './MessageBubble'
import Summary from './Summary'

const GET_ITEM = `
  query GetItem($itemId: ID!) {
    item(id: $itemId) {
      id
      title

      images {
        id
        url(size: "productThumb")
      }

      listing {
        id
        askingPrice {
          amount
          currency { iso }
        }
        createdAt
        isAvailable
      }

      account {
        id
        username
        isPrivateSeller
        isProfessionalSeller
        avatarImage {
          id
          url
        }
      }
    }
  }
`

const GET_USER = `
  query GetUser($id: ID!) {
    account(id: $id) {
      id
      username
      avatarImage {
        id
        url
      }
    }
  }
`

const Inner = styled(Container)`
  padding-top: 25px;
  padding-bottom: 30px;
  border-top: 1px solid ${({ theme }) => theme.colors.midGrey};
`

const Content = styled.div`
  max-width: 1035px;
  margin-right: auto;
  margin-left: auto;
`

const Footer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 30px;
  border-top: 1px solid ${({ theme }) => theme.colors.midGrey};
`

const Message = styled.p`
  margin: 30px;
  text-align: center;
`

export default function MessagesComposePage() {
  const [params] = useSearchParams()
  const itemId = params.get('itemId')
  const accountId = params.get('accountId')
  const { messages } = useGlobals('messages')

  const [itemQuery] = useQuery({
    query: GET_ITEM,
    variables: { itemId },
    pause: !itemId,
  })
  const [accountQuery] = useQuery({
    query: GET_USER,
    variables: { id: accountId },
    pause: !accountId,
  })

  if (!itemId && !accountId) return <Message>No message recipient</Message>
  if (itemQuery.fetching || accountQuery.fetching)
    return <Loader background="#fff" height="250px" />
  if (itemQuery.error) return <Message>{itemQuery.error.message}</Message>
  if (accountQuery.error) return <Message>{accountQuery.error.message}</Message>
  if (itemId && !itemQuery.data?.item) return <Message>Item not found</Message>
  if (accountId && !accountQuery.data?.account)
    return <Message>User not found</Message>

  const { item } = itemQuery.data || {}
  const { account } = accountQuery.data || {}

  return (
    <>
      <Helmet>
        <title>Compose a Message | Messages</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Back url="/messages" header="Back to Inbox" size="small" wrapped />
      <Inner>
        <Summary anchor="#reply" user={account || item.account} item={item} />
        <UserConsumer>
          {({ user }) => (
            <Content>
              <Spacer size="30px" />
              {messages?.messagesOffsiteWarning && (
                <>
                  <Alert>
                    <Translation>
                      {messages?.messagesOffsiteWarning}
                    </Translation>
                  </Alert>
                  <Spacer size="30px" />
                </>
              )}
              <MessageBubble id="reply" state="sent" user={user} fullWidth>
                <Editor
                  autofocus
                  participants={[user.id, account?.id || item.account.id]}
                  itemId={itemId}
                />
              </MessageBubble>
            </Content>
          )}
        </UserConsumer>
        <Spacer fromSize="30px" toSize="50px" />
        {messages?.messagesTnagDirectMessageWarning && (
          <Footer>
            <Alert>
              <Translation>
                {messages?.messagesTnagDirectMessageWarning}
              </Translation>
            </Alert>
          </Footer>
        )}
      </Inner>
    </>
  )
}

MessagesComposePage.propTypes = {}
