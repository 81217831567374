const GET_GlOBAL_SET = `
  query GetLoginRegister {
    globalSet(handle: "loginRegister") {
      ... on loginRegister_GlobalSet {
        id
        loginRedirectUrl
        registerRedirectUrl
      }
    }
  }
`

export default GET_GlOBAL_SET
