import { Helmet } from 'react-helmet-async'
import DetailsForm from './DetailsForm'
import DetailsProfileForm from './DetailsProfileForm'
import DetailsPasswordForm from './DetailsPasswordForm'
import Subheader from '../../shared/Subheader'

export default function DetailsPage() {
  return (
    <>
      <Helmet>
        <title>Profile | Settings | Details</title>
      </Helmet>
      <div>
        <Subheader border header="My details" />
        <div>
          <DetailsForm />
          <DetailsProfileForm />
          <DetailsPasswordForm />
        </div>
      </div>
    </>
  )
}
