import PropTypes from 'prop-types'
import { useQuery } from 'urql'
import Carousel, { themeColor } from './Carousel'
import Loader from './Loader'

const GET_POPULAR_LISTINGS = `
  query PopularListings {
    popularListings {
      id
      isAvailable

      askingPrice {
        amount
        currency { iso }
      }

      item {
        id
        slug
        title

        images {
          id
          url(size: "productThumb")
        }

        account {
          id
          username
          displayName
          isPrivateSeller
          isProfessionalSeller
        }
      }
    }
  }
`

export default function PopularListingsCarousel({ theme, ...props }) {
  const [{ data, fetching, error }] = useQuery({
    query: GET_POPULAR_LISTINGS,
  })

  if (error) {
    console.error(error)
  }

  if (fetching) {
    return <Loader background={themeColor[theme]} height="200px" />
  }

  if (data?.popularListings?.length) {
    return <Carousel listings={data.popularListings} theme={theme} {...props} />
  }

  return null
}

PopularListingsCarousel.defaultProps = {
  header: 'Popular Listings',
  theme: 'light',
}

PopularListingsCarousel.propTypes = {
  header: PropTypes.string.isRequired,
  theme: PropTypes.oneOf(['light', 'dark', 'grey', 'lightGrey']).isRequired,
  view: PropTypes.string,
  viewText: PropTypes.string,
}
