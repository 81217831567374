import PropTypes from 'prop-types'
import Meta from '../_helpers/Meta'
import styled, { css } from 'styled-components'
import { SubHeader } from '../shared/Hero'
import Circles from '../shared/Circles'
import Container, { ContainerStyles } from '../_helpers/Container'
import linearGradient from '../_helpers/linearGradient'
import Spacer from '../_helpers/Spacer'
import { colors } from '../theme'
import Listings from './Listings'
import Breadcrumbs from '../shared/Breadcrumbs'

const Wrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  background-color: ${(props) => props.theme.colors.xLightGrey};
`

const InnerWrapper = styled.div`
  ${ContainerStyles}
  position: relative;
  flex-grow: 1;
  display: flex;
`

const Hero = styled.div`
  position: relative;
  padding-top: 40px;
  padding-bottom: ${({ hasSectionLinks }) =>
    hasSectionLinks ? '20px' : '35px'};
  margin-bottom: 20px;

  ${SubHeader} {
    opacity: 1;
    transform: translateX(0);
    position: relative;
  }

  &::before {
    content: '';
    position: absolute;
    background: linear-gradient(90deg, ${colors.blue}, ${colors.darkBlue});
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    ${({ hasSectionLinks }) =>
      hasSectionLinks &&
      css`
        @media (min-width: 580px) {
          bottom: 120px;
        }
      `}
  }

  @media (min-width: 0px) and (max-width: 579px) {
    padding-top: 20px;
    padding-bottom: 20px;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 85%;
      right: 0;
      bottom: 0;
      z-index: 2;
      pointer-events: none;
      background: ${linearGradient(
        'to right',
        'darkBlue 0 0%',
        'darkBlue 1 100%'
      )};
    }
  }
`

const HeroText = styled.div`
  position: relative;
  margin-top: 10px;
  margin-right: auto;
  margin-left: auto;
  font-size: 16px;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
`

const SubcategoriesWrap = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`

const BreadcrumbsWrapper = styled.div`
  ${ContainerStyles}
  margin-bottom: 20px;
`

export default function ShopListingsLayout({
  heroTitle,
  heroText,
  sectionLinks,
  hiddenKeywords,
  hiddenSkus,
  hiddenFilters,
  seoTitle,
  seoDescription,
  breadcrumbs,
}) {
  const circleArgs = []
  for (const section of sectionLinks) {
    const image =
      section.image[0] || section.itemLink.element?.sectionLinkImage[0]

    circleArgs.push({
      title: section.itemLink.text,
      url: section.itemLink.url,
      image,
    })
  }

  return (
    <>
      <Meta title={seoTitle} description={seoDescription} />
      <Wrapper>
        <Hero flex hasSectionLinks={sectionLinks.length > 0}>
          <SubHeader as="h1">{heroTitle}</SubHeader>
          {heroText && (
            <HeroText>
              <Container>{heroText}</Container>
            </HeroText>
          )}
          {circleArgs && circleArgs.length ? (
            <SubcategoriesWrap>
              <Circles circles={circleArgs} />
            </SubcategoriesWrap>
          ) : undefined}
        </Hero>
        {breadcrumbs?.length ? (
          <BreadcrumbsWrapper>
            <Breadcrumbs
              items={[{ text: 'Shop All', url: '/shop' }].concat(breadcrumbs)}
            />
          </BreadcrumbsWrapper>
        ) : null}
        <InnerWrapper>
          <Listings
            hiddenKeywords={hiddenKeywords}
            hiddenSkus={hiddenSkus}
            hiddenFilters={hiddenFilters}
          />
        </InnerWrapper>
        <Spacer fromSize="20px" toSize="40px" />
      </Wrapper>
    </>
  )
}

ShopListingsLayout.propTypes = {
  heroTitle: PropTypes.string.isRequired,
  heroText: PropTypes.node,
  hiddenFilters: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  hiddenKeywords: PropTypes.string,
  hiddenSkus: PropTypes.arrayOf(PropTypes.string),
  sectionLinks: PropTypes.arrayOf(
    PropTypes.shape({
      itemTitle: PropTypes.string,
      itemEntry: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired,
          uri: PropTypes.string.isRequired,
          sectionLinkImage: PropTypes.arrayOf(
            PropTypes.shape({
              xs: PropTypes.string.isRequired,
              small: PropTypes.string.isRequired,
              width: PropTypes.number.isRequired,
              height: PropTypes.number.isRequired,
              title: PropTypes.string,
            })
          ),
        })
      ).isRequired,
    })
  ),
  seoTitle: PropTypes.string.isRequired,
  seoDescription: PropTypes.string,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      url: PropTypes.string,
    })
  ),
}
