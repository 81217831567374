import { useState, useRef } from 'react'
import styled, { css } from 'styled-components'
import { down } from 'styled-breakpoints'
import { useQuery } from 'urql'
import { useToggle } from '../hooks'
import { Icon, ArrowIcon } from '../_base/Icon'
import Button, { ButtonStyle } from '../_atoms/Button'

import Loader from '../shared/Loader'
import ListingCard from '../shared/ListingCard'
import NextPrevious from '../shared/NextPrevious'
import Tabs from '../shared/Tabs'
import Subheader from '../shared/Subheader'
import Toggle from '../shared/Toggle'
import { colors } from '../theme'
import { HeaderL } from '../_abstracts/Type'

import RecentlyViewedCarousel from '../shared/RecentlyViewedCarousel'
import { Sidebar as SideFilter } from '../filter/FilterList'
import { Formik } from 'formik'
import Input from '../_atoms/Input'
import Form from '../_atoms/Form'

import SavedSearchList from './SavedSearchList'

const Wrapper = styled.div`
  flex-grow: 1;
  padding: 25px 0;
  background-color: ${(props) => props.theme.colors.xLightGrey};

  ${SideFilter} {
    max-width: none;
  }
`

const Inner = styled.div`
  max-width: 1310px;
  margin-left: auto;
  margin-right: auto;
  padding: 25px;
`

const Grid = styled.div`
  display: flex;
  border-top: 1px solid ${(props) => props.theme.colors.midGrey};

  ${(props) =>
    props.toggleState
      ? css`
          ${Side} {
            display: none;
          }

          ${Item} {
            width: 20%;

            ${down('medium')} {
              width: 50%;
            }

            ${down('small')} {
              width: 100%;
            }
          }
        `
      : css`
          ${Item} {
            width: 25%;

            ${down('medium')} {
              width: 50%;
            }

            ${down('small')} {
              width: 100%;
            }
          }
        `}

  ${down('medium')} {
    flex-wrap: wrap;
  }
`

const Side = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 335px;
  padding: 20px 0 20px 20px;

  > * + * {
    margin-top: 10px;
  }

  ${down('medium')} {
    max-width: initial;
    padding-right: 20px;
  }
`

const Middle = styled.div`
  flex-grow: 1;
  padding: 10px;

  > * + * {
    margin-top: 20px;
  }
`

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const Item = styled.div`
  padding: 10px;
  display: flex;

  > * {
    min-width: unset;
    width: 100% !important;
  }
`

const None = styled.div`
  padding: 10px;
  width: 100%;

  h2 {
    ${HeaderL}
    padding: 20px;
    background-color: ${(props) => props.theme.colors.lighterGrey};
  }

  p {
    font-size: 18px;
    line-height: 1.5;
    padding: 20px 20px 0;
    max-width: 600px;
  }

  a {
    display: inline-flex;
    align-items: center;
  }

  ${ButtonStyle} {
    display: inline-block;
    margin-left: 20px;
    margin-top: 10px;
  }

  ${Icon} {
    min-width: 17px;
    width: 17px;
    display: inline-block;
    margin-right: 10px;
  }

  span {
    display: inline-block;
    font-weight: 700;
    border-bottom: 1px solid;
    transition: 0.15s;
  }

  a:hover span {
    border-bottom-color: transparent;
  }
`

const tabsArgs = {
  tabs: [
    { title: 'My Saved Searches', url: '/my-saved-searches' },
    { title: 'Wishlist', url: '/wishlist', end: true },
    { title: 'Recently Viewed', url: '/recently-viewed', end: true },
  ],
}

const DEFAULT_ITEMS_PER_PAGE = 12

const FEED_QUERY = `
  query Feed($first: Int, $after: String, $last: Int, $before: String) {
    feed(first: $first, after: $after, last: $last, before: $before) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }

      edges {
        node {
          id
          askingPrice {
            amount
            currency {
              iso
            }
          }
          item {
            id
            slug
            images {
              id
              url(size: "productThumb")
            }
            title
            inViewerWishlist
            account {
              id
              username
              isPrivateSeller
              isProfessionalSeller
            }
          }
        }
      }
    }

    listingSavedSearches {
      id
      keywords
      filters {
        key
        label
        value
      }
    }
  }
`

export default function MyFeedIndexPage() {
  const panelRef = useRef()
  const [sidebarShown, toggleSidebarShown] = useToggle(true)

  const [perPage, setPerPage] = useState(DEFAULT_ITEMS_PER_PAGE)
  const [pagination, setPagination] = useState({
    first: DEFAULT_ITEMS_PER_PAGE,
  })

  const [{ fetching, data, error }] = useQuery({
    query: FEED_QUERY,
    variables: {
      ...pagination,
    },
  })

  const scrollToTop = () =>
    panelRef.current?.scrollIntoView({ behavior: 'smooth' })

  if (fetching) return <Loader background={colors.xLightGrey} />

  if (error) {
    return <p>{error.message}</p>
  }

  const {
    feed: { pageInfo, edges },
    listingSavedSearches,
  } = data

  return (
    <Wrapper ref={panelRef}>
      <Inner>
        <Tabs {...tabsArgs}>
          <Subheader header="My saved searches">
            <>
              <Formik initialValues={{}}>
                <Form>
                  <Toggle
                    label="Sidebar"
                    toggleState={sidebarShown}
                    onChange={toggleSidebarShown}
                  />
                </Form>
              </Formik>

              <Formik initialValues={{}}>
                <Form>
                  <Input
                    inline={true}
                    label="Per page"
                    name="pp"
                    inputAs="select"
                    value={perPage}
                    onChange={(event) => {
                      const perPage = parseInt(event.target.value, 10)

                      setPerPage(perPage)
                      setPagination({ first: perPage })
                    }}
                  >
                    <option value="12">12</option>
                    <option value="24">24</option>
                    <option value="48">48</option>
                  </Input>
                </Form>
              </Formik>
            </>
          </Subheader>

          <Grid toggleState={!sidebarShown}>
            {listingSavedSearches.length > 0 && (
              <Side>
                <SavedSearchList savedSearches={listingSavedSearches} />
                <Button
                  fullWidth
                  variant="primaryDark"
                  to="/my-saved-searches/customize"
                >
                  Customize my saved searches
                </Button>
              </Side>
            )}

            <Middle>
              <List>
                {edges.length ? (
                  edges
                    .map((edge) => edge.node)
                    .map((listing) => (
                      <Item key={listing.id}>
                        <ListingCard {...listing} />
                      </Item>
                    ))
                ) : (
                  <None>
                    <h2>We&apos;re sorry…</h2>
                    {listingSavedSearches.length > 0 ? (
                      <p>There are no results matching your search.</p>
                    ) : (
                      <>
                        <p>You have not saved any searches yet.</p>
                        <Button
                          variant="primaryDark"
                          to="/my-saved-searches/customize"
                        >
                          Customize my saved searches
                        </Button>
                      </>
                    )}
                    <p>
                      <a href="/shop">
                        <ArrowIcon />
                        <span>Return to shop index</span>
                      </a>
                    </p>
                  </None>
                )}
              </List>
            </Middle>
          </Grid>

          <NextPrevious
            includeNext={pageInfo?.hasNextPage || !!pagination.before}
            includePrevious={pageInfo?.hasPreviousPage || !!pagination.after}
            onNext={() => {
              scrollToTop()
              setPagination({
                after: pageInfo.endCursor,
                first: perPage,
              })
            }}
            onPrevious={() => {
              scrollToTop()
              setPagination({
                before: pageInfo.startCursor,
                last: perPage,
              })
            }}
          />
        </Tabs>
      </Inner>

      <RecentlyViewedCarousel theme="grey" />
    </Wrapper>
  )
}

MyFeedIndexPage.propTypes = {}
