import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Body, HeaderM } from '../_abstracts/Type'

const Wrapper = styled.div`
  padding: 20px;
`

const Inner = styled.div`
  background-color: ${(props) => props.theme.colors.blueTint};
  padding: 20px;
  text-align: center;
  border: 1px solid ${(props) => props.theme.colors.darkBlue};
`

const Heading = styled.p`
  ${HeaderM};
  font-weight: 700;
  margin-bottom: 10px;
`

const Text = styled.p`
  ${Body};
  color: ${(props) => props.theme.colors.darkBlue};
  max-width: 562px;
  margin: 0 auto;
`

export default function GreyTextBlockBorder({ text, heading }) {
  return (
    <Wrapper>
      <Inner>
        <Heading>{heading}</Heading>
        <Text>{text}</Text>
      </Inner>
    </Wrapper>
  )
}

GreyTextBlockBorder.propTypes = {
  heading: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}
