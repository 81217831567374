import { css } from 'styled-components'

const Transition = ({
  property,
  ease = 'ease',
  duration = '150ms',
  delay = '0s',
  enableForReducedMotion = true,
}) => css`
  transition-property: ${property};
  transition-timing-function: ${ease};
  transition-duration: ${duration};
  transition-delay: ${delay};

  ${enableForReducedMotion &&
  css`
    @media (prefers-reduced-motion: reduce) {
      transition-duration: 0s;
    }
  `}
`

export default Transition

// USE
// To just state which properties: ${Transition({ property : 'background, border-color, color' })};
// To make other changes, duration for example: ${Transition({ property : 'background, border-color, color', duration : '300ms' })};
// example
// const Example = styled.button`
//   border: 1px solid black;
//   background: white;
//   color: white;
//   ${Transition({ property : 'background, border-color, color' })};
// `
