import styled from 'styled-components'
import ModalLayout from './ModalLayout'
import { HeaderM, BodyS } from '../_abstracts/Type'
import CraftForm from '../shared/CraftForm'
import { useUserContext } from '../auth/UserContext'
import PropTypes from 'prop-types'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 20px 32px;

  form {
    width: 100%;
  }
`

const Header = styled.h2`
  ${HeaderM};
  font-weight: 700;
  text-align: center;
  margin-bottom: 15px;
`

const Text = styled.p`
  ${BodyS};
  text-align: center;
  margin-bottom: 15px;
`

export default function ModalForm({
  content = <Header>Enter your message</Header>,
  successMessage,
  info,
  loaderHeight,
  header = 'Report an issue',
  handle = 'reportIssue',
  fields = {},
  onSubmit,
  requireUser,
}) {
  const { user } = useUserContext()
  // truthy
  if (requireUser !== false) requireUser = true

  if (user) {
    const userFields = {
      senderEmail: user.email,
      senderName: `${user.firstName} ${user.lastName}`,
      senderUsername: user.username,
    }

    fields = { ...fields, ...userFields }
  }

  const form = (
    <CraftForm
      handle={handle}
      successMessage={successMessage}
      initialValues={fields}
      loaderHeight={loaderHeight}
      onSubmit={onSubmit}
    />
  )

  let body = null
  if (requireUser) {
    if (user) {
      body = form
    } else {
      body = <Text>Please login to submit your message</Text>
    }
  } else {
    body = form
  }

  return (
    <ModalLayout header={header} content={info && <p>{info}</p>}>
      <Wrapper>
        {content}

        {body}
      </Wrapper>
    </ModalLayout>
  )
}

ModalForm.propTypes = {
  content: PropTypes.node,
  fields: PropTypes.object,
  header: PropTypes.string,
  handle: PropTypes.string,
  loaderHeight: PropTypes.string,
  successMessage: PropTypes.string.isRequired,
  info: PropTypes.string,
  onSubmit: PropTypes.func,
  requireUser: PropTypes.bool,
}
