import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom'
import { useQuery } from 'urql'
import Back from '../../shared/Back'
import Invoice from '../../orders/Invoice'
import Subheader from '../../shared/Subheader'
import Loader from '../../shared/Loader'
import NotFound from '../../404/NotFound'

const REFUND_STATEMENT = `
  query OrderRefundStatement($refundId: ID!) {
    orderRefund(id: $refundId) {
      id

      order {
        id
        number
      }

      refundedAmount {
        amount
        currency { iso }
      }

      createdAt
    }
  }
`

export default function DetailsPage() {
  const { refundId } = useParams()
  const [{ fetching, data, error }] = useQuery({
    query: REFUND_STATEMENT,
    variables: { refundId },
  })

  return (
    <>
      <Helmet>
        <title>Profile | Settings | Statement</title>
      </Helmet>
      <div>
        <Subheader border header="Statement" />

        <Back
          wrapped={true}
          url="/profile/settings/invoices"
          header="Back to invoices"
        />

        {fetching ? (
          <Loader background="#fff" />
        ) : error ? (
          <p>{error.message}</p>
        ) : !data?.orderRefund ? (
          <NotFound />
        ) : (
          <>
            <Invoice
              refund
              orderDate={new Date(data.orderRefund.createdAt)}
              orderNumber={data.orderRefund.order.number}
              breakdown={[
                {
                  name: 'Refund',
                  amount: data.orderRefund.refundedAmount.amount,
                  currency: data.orderRefund.refundedAmount.currency.iso,
                },
              ]}
            />
          </>
        )}
      </div>
    </>
  )
}
