import { useRef } from 'react'
import toast from 'react-hot-toast'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useMutation } from 'urql'
import Button from '../_atoms/Button'

export const UPDATE_LINE_ITEM_QUANTITY = `
  mutation UpdateLineItemQuantity($lineItemId: ID!, $orderId: [ID!], $quantity: Int!) {
    cartUpdateLineItemQuantity(lineItemId: $lineItemId, orderId: $orderId, quantity: $quantity) {
      success
      cart(orderId: $orderId) {
        orders {
          id
        }
      }
    }
  }
`

const Wrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  margin-right: 20px;
  text-align: left;
`

const StyledInput = styled.input`
  border: 1px solid #e6e7ea;
  border-radius: 3px;
  padding: 3px 5px;
  width: 80px;
`

export default function UpdateQuantityInput({
  openOrderIds,
  lineItemId,
  quantity,
  stockLevel,
}) {
  const inputRef = useRef()
  const [{ fetching }, updateQuantity] = useMutation(UPDATE_LINE_ITEM_QUANTITY)

  return (
    <Wrapper>
      <StyledInput
        type="number"
        min={1}
        max={stockLevel}
        disabled={fetching}
        ref={inputRef}
        defaultValue={quantity}
      />
      <Button
        disabled={fetching}
        variant="small"
        onClick={async (event) => {
          event.preventDefault()

          if (inputRef.current) {
            const result = await updateQuantity({
              orderId: openOrderIds,
              lineItemId,
              quantity: parseInt(inputRef.current.value, 10),
            })

            if (result.data?.cartUpdateLineItemQuantity?.success) {
              toast.success('Updated quantity', { id: 'quantity' })
            } else {
              const error = result.error?.graphQLErrors?.find(
                (err) => err.path[0] === 'cartUpdateLineItemQuantity'
              )

              if (error) {
                toast.error(error.message, { id: 'quantity' })
              } else {
                toast.error('Failed to update quantity', { id: 'quantity' })
              }
            }
          }
        }}
      >
        {fetching ? 'Updating...' : 'Update Quantity'}
      </Button>
    </Wrapper>
  )
}

UpdateQuantityInput.propTypes = {
  openOrderIds: PropTypes.arrayOf(PropTypes.string),
  lineItemId: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  stockLevel: PropTypes.number,
}
