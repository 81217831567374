import { useNavigate, useSearchParams } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { Formik, Form as FormikForm, Field } from 'formik'
import { up } from 'styled-breakpoints'

import PropTypes from 'prop-types'

import { InputField } from '../_atoms/Input'
import Button, { ButtonStyle } from '../_atoms/Button'
import { Icon, SearchIcon } from '../_base/Icon'
import Transition from '../_abstracts/Animation'

const Label = styled.span`
  display: block;
  width: 100%;
  font-size: 14px;
`

const Flex = styled.div`
  display: flex;
`

export const Form = styled(FormikForm)`
  width: 100%;
  min-width: 270px;
  max-width: 400px;

  @media (hover: none) {
    min-width: 305px;
    max-width: 400px;
  }

  ${InputField} {
    padding-top: 0;
    padding-bottom: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
    font-size: 15px;
    border-color: ${({ theme }) => theme.colors.darkBlue};

    @media (hover: none) {
      font-size: 16px;
    }

    &:focus {
      border-color: ${({ theme }) => theme.colors.accent};
      box-shadow: none;
    }
  }

  ${ButtonStyle} {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: 42px;
    min-height: 42px;
    min-width: 42px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border: 0;
    background: ${(props) => props.theme.colors.darkBlue};
    ${Transition({ property: 'background, color' })};

    svg path,
    svg circle {
      stroke: ${(props) => props.theme.colors.white};
      ${Transition({ property: 'stroke' })};
    }

    &::after,
    &::before {
      width: 0;
      height: 0;
    }

    ${Icon} {
      display: block;
      width: 50%;
      margin: 0;
      pointer-events: none;
    }

    &:hover {
      border-color: ${(props) => props.theme.colors.accent};
      background: ${(props) => props.theme.colors.accent};

      svg path,
      svg circle {
        stroke: ${(props) => props.theme.colors.white};
      }
    }

    &:active {
      background: ${(props) => props.theme.colors.lightBtnGrey};
      border-color: ${(props) => props.theme.colors.midGrey};

      svg > * {
        stroke: ${(props) => props.theme.colors.darkBlue};
      }
    }
  }

  ${Icon} {
    width: 21px;
    pointer-events: none;
  }

  ${(props) =>
    props.$variant &&
    css`
      max-width: 620px;

      ${InputField} {
        border-color: ${(props) => props.theme.colors.accent};
      }

      ${ButtonStyle} {
        width: 57px;
        min-width: 57px;

        &:active {
          background: ${(props) => props.theme.colors.lightBtnGrey};
          border-color: ${(props) => props.theme.colors.accent};
        }
      }

      ${Icon} {
        width: 60%;
      }

      ${up('small')} {
        ${InputField} {
          padding: 15px 25px;
          font-size: 16px;
        }
      }
    `};
`

export default function SearchBar({
  action,
  autoFocus,
  homepage,
  label,
  name,
  placeholder,
  onSubmit,
  scrollToTop,
}) {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  function submitSearch({ [name]: query }) {
    navigate(`${action}?${name}=${encodeURIComponent(query)}`, {
      state: {
        scrollToTop,
      },
    })
  }

  const query = searchParams.get(name)

  return (
    <Formik
      initialValues={{ [name]: query || '' }}
      onSubmit={onSubmit || submitSearch}
    >
      <Form $variant={homepage}>
        {label && <Label>{label}</Label>}
        <Flex>
          <Field name={name}>
            {({ field }) => (
              <InputField
                aria-label="Search keywords"
                autoFocus={autoFocus}
                placeholder={placeholder}
                name={field.name}
                value={field.value}
                onBlur={field.onBlur}
                onChange={field.onChange}
                autoComplete="off"
              />
            )}
          </Field>
          <Button submit>
            <SearchIcon />
          </Button>
        </Flex>
      </Form>
    </Formik>
  )
}

SearchBar.defaultProps = {
  name: 'keywords',
  placeholder: 'Search by model, type, brand',
}

SearchBar.propTypes = {
  action: PropTypes.string.isRequired,
  autoFocus: PropTypes.bool,
  homepage: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onSubmit: PropTypes.func,
  scrollToTop: PropTypes.bool,
}
