import { Formik } from 'formik'
import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { Link } from 'react-router-dom'
import { useMutation } from 'urql'
import * as Yup from 'yup'
import Form from '../../_atoms/Form'
import Button from '../../_atoms/Button'
import Input from '../../_atoms/Input'
import { useUserContext } from '../../auth/UserContext'
import Field from '../../forms/Field'
import { SimpleFieldset } from '../../forms/Fieldset'
import Alert from '../../shared/Alert'
import Loader from '../../shared/Loader'
import Oembed from '../../_atoms/Oembed'
import Video from '../../_atoms/Video'

const UPDATE_USER = `
  mutation UpdateUser($account: AccountUpdateInput!) {
    accountUpdate(account: $account) {
      success
    }
  }
`

const Schema = Yup.object().shape({
  bio: Yup.string()
    .nullable()
    .transform((value) => value || null)
    .trim(),

  displayName: Yup.string()
    .nullable()
    .transform((value) => value || null)
    .trim(),

  video: Yup.string()
    .nullable()
    .transform((value) => value || null)
    .trim(),
})

export default function DetailsForm() {
  const { user, setUserData } = useUserContext()
  const [update, updateAccount] = useMutation(UPDATE_USER)
  const [oembedNeeded, setOembedNeeded] = useState(false)

  useEffect(() => {
    if (update.fetching) {
      toast('Saving…', { id: 'form' })
    } else if (update.data?.accountUpdate.success) {
      toast.success('Changes saved', { id: 'form' })
    } else if (update.error || update.data?.accountUpdate.success === false) {
      toast.error('Unable to save changes', { id: 'form' })
    }
  }, [update])

  if (!user) return <Loader background="#fff" />

  async function handleSubmit(fields) {
    const parsedFields = Schema.cast(fields, { strict: true })
    const { data } = await updateAccount({
      account: parsedFields,
    })

    if (data?.accountUpdate.success) {
      // If bio is edited, call setUserData to update bio currently shown on profile page
      if (parsedFields.bio !== user.bio) {
        setUserData({ bio: parsedFields.bio })
      }
    }
  }

  let videoPreview = null
  if (oembedNeeded) {
    videoPreview = <Oembed url={oembedNeeded} />
  } else {
    if (user?.videoEmbed && user?.videoAspect) {
      videoPreview = <Video embed={user.videoEmbed} aspect={user.videoAspect} />
    }
  }

  let videoFieldChangedInterval
  function videoFieldChanged(event) {
    clearInterval(videoFieldChangedInterval)
    videoFieldChangedInterval = setTimeout(() => {
      setOembedNeeded(event.target.value)
    }, 1000)
  }

  return (
    <>
      <Formik
        onSubmit={handleSubmit}
        validationSchema={Schema}
        initialValues={{
          bio: user.bio || '',
          displayName: user.displayName,
          video: user.video || '',
        }}
      >
        <Form $border justify="center" id="profile">
          <SimpleFieldset legend="Profile page">
            <Field>
              <Input
                disabled
                label="Username"
                readonly
                defaultValue={user.username}
              />
              <Alert>
                To change username <Link to="/contact">contact us</Link>
              </Alert>
            </Field>
            <Field>
              <Input label="Display Name" name="displayName" />
            </Field>
            <Field>
              <Input inputAs="textarea" label="Bio" name="bio" />
            </Field>
            <Field>
              <Input
                label="Video"
                name="video"
                onChange={videoFieldChanged}
                description="YouTube and Vimeo URLs are accepted."
              />
            </Field>
            {videoPreview && <Field>{videoPreview}</Field>}
          </SimpleFieldset>

          <Field justify="end">
            <Button disabled={update.fetching} submit variant="primaryDark">
              Save
            </Button>
          </Field>
        </Form>
      </Formik>
    </>
  )
}
