import { useState } from 'react'
import toast from 'react-hot-toast'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useMutation } from 'urql'
import Button from '../_atoms/Button'

export const UPDATE_LINE_ITEM_NOTES = `
  mutation UpdateLineItemNotes($lineItemId: ID!, $orderId: [ID!], $notes: String!) {
    cartUpdateLineItemNotes(lineItemId: $lineItemId, orderId: $orderId, notes: $notes) {
      success
      cart(orderId: $orderId) {
        orders {
          id
        }
      }
    }
  }
`

const Container = styled.div`
  margin-top: 7px;
  width: 100%;
  text-align: left;

  textarea {
    border: 1px solid;
    width: 100%;
    display: block;
    resize: vertical;
    margin-bottom: 7px;
    padding: 7px;
  }
`

export default function NotesForm({
  openOrderIds,
  lineItemId,
  notes: initialNotes,
}) {
  const [notes, setNotes] = useState(initialNotes)
  const [{ fetching }, updateNotes] = useMutation(UPDATE_LINE_ITEM_NOTES)

  async function performNotesUpdate(variables) {
    const result = await updateNotes(variables)

    if (result.data?.cartUpdateLineItemNotes?.success) {
      toast.success('Updated notes', { id: 'notes' })
    } else {
      const error = result.error?.graphQLErrors?.find(
        (err) => err.path[0] === 'cartUpdateLineItemNotes'
      )

      if (error) {
        toast.error(error.message, { id: 'notes' })
      } else {
        toast.error('Failed to update notes', { id: 'notes' })
      }
    }
  }

  return (
    <Container>
      <label>
        Notes
        <textarea onChange={(e) => setNotes(e.target.value)}>{notes}</textarea>
      </label>

      <Button
        disabled={fetching}
        variant="small"
        onClick={() =>
          performNotesUpdate({
            orderId: openOrderIds,
            lineItemId,
            notes,
          })
        }
      >
        {fetching ? 'Updating...' : 'Update Notes'}
      </Button>
    </Container>
  )
}

NotesForm.propTypes = {
  openOrderIds: PropTypes.arrayOf(PropTypes.string),
  lineItemId: PropTypes.string.isRequired,
  notes: PropTypes.string,
}
