import PropTypes from 'prop-types'
import { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { useMutation, useQuery } from 'urql'

import Back from '../profile/Back'
import Scatter from '../profile/Scatter'
import Summary from '../profile/Summary'
import { ID as ESTIMATE_FIELD_ID } from '../portfolio/EstimateField'

import { HeaderL, HeaderS } from '../_abstracts/Type'
import Button, { ButtonStyle } from '../_atoms/Button'
import NotFound from '../404/NotFound'
import Html from '../data/Html'
import { useGlobals } from '../hooks'
import Loader from '../shared/Loader'
import ButtonGroup from '../shared/ButtonGroup'
import Modal from '../shared/Modal'
import ModalForm from '../shared/ModalForm'
import { Media, MediaImage } from '../_base/Media'
import { Icon, EditIcon } from '../_base/Icon'

import TradeProcess from '../trade/Process'
import { useCartContext } from '../cart/CartContext'
import Transition from '../_abstracts/Animation'

const GET_ITEM = `
  query GetItem($id: ID!) {
    item(id: $id) {
      id
      title
      slug
      acceptingOffers
      privacy
      images {
        id
        url(size: "productThumb")
      }
      catalogProduct {
        id
        tpn
      }
      offerCount
      wishlistCount
      listing {
        id
        createdAt
        viewCount
      }

      account {
        id
        username
      }

      category { id }
      brand { id title }
      model { id }
      madeInYear
      handedness
      originalFinish
      originalParts

      materials {
        body { id title }
        neck { id title }
        fretboard { id title }
        top { id title }
        back { id title }
      }
    }
  }
`

const Message = styled.p`
  padding: 30px 0;
  text-align: center;
`

const ValueGraph = styled.div`
  padding: 50px 20px;
  background-color: ${(props) => props.theme.colors.blueTint};
  border-top: 1px solid ${(props) => props.theme.colors.midGrey};
  border-radius: inherit;
`

const ValueGraphTitle = styled.h2`
  ${HeaderL}
  text-align: center;
  margin-bottom: 18px;
`

const ValueGraphEstimateText = styled.div`
  margin-bottom: 18px;
  text-align: center;
`

const ValueGraphEstimateLink = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${Icon} {
    ${Transition({ property: 'color' })};
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    margin-right: 0;
    margin-left: 8px;

    circle {
      ${Transition({ property: 'fill' })};
    }
  }

  a:hover & {
    ${Icon} {
      color: ${(props) => props.theme.colors.white};

      circle {
        fill: ${(props) => props.theme.colors.darkBlue};
      }
    }
  }
`

const NoValueData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;

  ${ButtonStyle} {
    margin: 10px 0;
  }
`

const NoValueDataMessage = styled.p`
  text-align: center;
`

const AppraisalInfo = styled.div`
  margin-bottom: 25px;

  h2 {
    ${HeaderS}
  }
`

const AppraisalDetails = styled.div`
  margin-top: 22px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 25px;
`

const AppraisalImage = styled.div`
  width: 100px;
  overflow: hidden;
  border: 1px solid ${(props) => props.theme.colors.midGrey};
  border-radius: ${(props) => props.theme.radii};

  ${Media} {
    padding-bottom: 100%;
  }
`

const APPRAISAL_ITEM_IDS_LOCALSTORAGE_KEY = 'appraisalItemIds'

const getAppraisalItemIds = () => {
  let appraisalItemIds = []

  if (typeof window !== 'undefined') {
    try {
      let itemIds = localStorage.getItem(APPRAISAL_ITEM_IDS_LOCALSTORAGE_KEY)

      if (itemIds) {
        itemIds = JSON.parse(itemIds)

        if (Array.isArray(itemIds)) {
          appraisalItemIds = itemIds
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  return appraisalItemIds
}

export const setAppraisalItemId = (itemId) => {
  const appraisalItemIds = getAppraisalItemIds()

  // Remove current item ID from appraisals (if already there)
  if (appraisalItemIds.includes(itemId)) {
    appraisalItemIds.splice(appraisalItemIds.indexOf(itemId), 1)
  }

  appraisalItemIds.push(itemId)

  // Re-save appraisal item IDs
  try {
    localStorage.setItem(
      APPRAISAL_ITEM_IDS_LOCALSTORAGE_KEY,
      JSON.stringify(appraisalItemIds)
    )
  } catch (error) {
    console.error(error)
  }
}

const CREATE_APPRAISAL_LINE_ITEM = `
mutation AddAppraisalLineItem($orderId: [ID!], $listingId: ID!, $config: [ConfigPlistInput!]) {
  cartAddListing(listingId: $listingId, config: $config, orderId: $orderId) {
    success
    cart(orderId: $orderId) {
      orders { id }
    }
  }
}
`

export const RequestAppraisal = ({ profile, item }) => {
  const [appraised, setAppraised] = useState(
    getAppraisalItemIds().includes(item?.id)
  )

  const { openOrderIds, updateOpenOrderIds } = useCartContext()
  const [, addAppraisalLineItem] = useMutation(CREATE_APPRAISAL_LINE_ITEM)
  const navigate = useNavigate()

  let itemUrl
  let content = null

  if (item) {
    itemUrl = item.listing?.isAvailable
      ? `${process.env.RAZZLE_BASE_FRONTEND_URL}/shop/${item.slug}/${item.id}`
      : `${process.env.RAZZLE_BASE_FRONTEND_URL}/profile/${item.account.username}/portfolio/${item.id}`
    const image = item.images?.[0]
    let handedness
    switch (item.handedness) {
      case 'LEFT_HANDEDNESS':
        handedness = 'Left'
        break
      case 'RIGHT_HANDEDNESS':
        handedness = 'Right'
        break
    }

    const spec = {
      Brand: item.brand.title,
      Model: item.model.title,
      Handedness: handedness,
      'Made In Year': item.madeInYear,
      'Made In Country': item.madeInCountry,
      'Body Material': item.materials.body?.title || undefined,
      'Neck Material': item.materials.neck?.title || undefined,
      'Fretboard Material': item.materials.fretboard?.title || undefined,
      'Top Material': item.materials.top?.title || undefined,
      'Back Material': item.materials.back?.title || undefined,
    }

    content = (
      <AppraisalInfo>
        <h2>{item.title}</h2>
        <AppraisalDetails>
          {image && (
            <AppraisalImage>
              <Media>
                <MediaImage src={image.url} alt="" />
              </Media>
            </AppraisalImage>
          )}
          <ul>
            {Object.entries(spec)
              .filter(([, val]) => val)
              .map(([key, val]) => (
                <li key={key}>
                  <p>
                    <b>{key}:</b>{' '}
                    {typeof val == 'object' ? val[Object.keys(val)[0]] : val}
                  </p>
                </li>
              ))}
          </ul>
        </AppraisalDetails>
      </AppraisalInfo>
    )
  }

  return (
    <Modal
      size="large"
      trigger={
        appraised ? null : (
          <Button variant="primaryDark">
            {profile.appraisalRequestButtonLabel}
          </Button>
        )
      }
    >
      <ModalForm
        handle="appraisalRequest"
        header={profile.appraisalRequestFormTitle}
        info={profile.appraisalRequestFormText}
        loaderHeight="43px"
        successMessage={profile.appraisalRequestSuccessMessage}
        content={content}
        fields={{
          itemUrl,
        }}
        onSubmit={async (_fields, data) => {
          if (item) setAppraisalItemId(item.id)
          setAppraised(true)

          const { save_appraisalRequest_Submission: appraisal } = data

          if (profile.appraisalRequestListingId && appraisal.id) {
            // the appraisal product is created and set in the backend
            const res = await addAppraisalLineItem({
              listingId: profile.appraisalRequestListingId,
              orderId: openOrderIds,
              config: [{ key: 'appraisalId', value: appraisal.id }],
            })

            if (res?.data?.cartAddListing?.success) {
              const orderIds = res.data.cartAddListing.cart.orders.map(
                (o) => o.id
              )
              updateOpenOrderIds(orderIds)
              navigate('/checkout/shipping')
            }
          }
        }}
      />
    </Modal>
  )
}

RequestAppraisal.propTypes = {
  item: PropTypes.object,
  profile: PropTypes.object.isRequired,
}

export default function ProfilePortfolioItemPage() {
  const { profile } = useGlobals('profile')
  const { itemId } = useParams()
  const [result] = useQuery({
    query: GET_ITEM,
    variables: { id: itemId },
  })
  const { data, fetching, error } = result
  const [priceHistory, setPriceHistory] = useState()

  if (fetching) return <Loader background="#fff" height="250px" />

  if (error) {
    console.error(error)
    return <Message>An error occurred</Message>
  }

  if (data?.item == null) {
    return <NotFound />
  }

  const { item } = data

  if (item.listing) {
    item.listing.createdAt = new Date(item.listing.createdAt)
  }

  return (
    <>
      <Helmet>
        <title>Profile | Portfolio | {item.title}</title>
      </Helmet>
      <Back header="Back" link="/profile/portfolio">
        <ButtonGroup>
          {(item.listing?.id && (
            <Button variant="primaryDark" to={`/shop/${item.slug}/${item.id}`}>
              View current listing
            </Button>
          )) || (
            <Button variant="primaryDark" to={`/selling/list-item/${item.id}`}>
              Sell this instrument
            </Button>
          )}
          <Modal
            trigger={<Button variant="secondary">Submit for Trade</Button>}
            size="standard"
          >
            <TradeProcess />
          </Modal>
        </ButtonGroup>
      </Back>
      <Summary item={item} priceHistory={priceHistory} />
      <Scatter
        id={item.id}
        text={profile?.valueGraphText && <Html html={profile.valueGraphText} />}
        tpn={item.catalogProduct?.tpn}
        attributes={{
          categoryId: item.category?.id,
          brandId: item.brand?.id,
          modelId: item.model?.id,
          year: item.madeInYear,
        }}
        onPriceHistoryAvailable={setPriceHistory}
        noDataContent={
          profile && (
            <NoValueData>
              {profile.valueGraphNoDataMessage && (
                <NoValueDataMessage>
                  {profile.valueGraphNoDataMessage}
                </NoValueDataMessage>
              )}
              <RequestAppraisal item={item} profile={profile} />
            </NoValueData>
          )
        }
        madeInYear={item.madeInYear}
        showAverage={false}
      >
        {(content) => (
          <ValueGraph>
            <ValueGraphTitle>{profile?.valueGraphTitle}</ValueGraphTitle>
            {profile?.valueGraphEstimateText && (
              <ValueGraphEstimateText>
                <Button
                  to={`/profile/portfolio/${itemId}/edit#${ESTIMATE_FIELD_ID}`}
                  variant="secondary"
                >
                  <ValueGraphEstimateLink>
                    <Html html={profile.valueGraphEstimateText} />
                    <EditIcon />
                  </ValueGraphEstimateLink>
                </Button>
              </ValueGraphEstimateText>
            )}
            {content}
          </ValueGraph>
        )}
      </Scatter>
    </>
  )
}

ProfilePortfolioItemPage.propTypes = {}
