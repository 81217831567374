import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { SmallLetters } from '../_abstracts/Type'

export const Notification = styled.div`
  ${SmallLetters}
  position: relative;
  width: ${(props) => props.width || 'auto'};
  background: ${(props) => props.theme.colors.blueTint};
  color: ${(props) => props.theme.colors.darkBlue};
  border: 1px solid;
  border-radius: 5px;
  padding: 7px 10px 7px 35px;
  display: inline-block;

  ${(props) =>
    (props.color === 'red' &&
      css`
        border-color: ${(props) => props.theme.colors.red};
        color: ${(props) => props.theme.colors.red};
        background-color: ${(props) => props.theme.colors.redTint};
      `) ||
    (props.color === 'grey' &&
      css`
        border-color: ${(props) => props.theme.colors.darkBlue};
        color: ${(props) => props.theme.colors.darkBlue};
        background-color: ${(props) => props.theme.colors.blueTint};
      `) ||
    (props.color === 'gold' &&
      css`
        border-color: ${(props) => props.theme.colors.gold};
        color: ${(props) => props.theme.colors.gold};
        background-color: ${(props) => props.theme.colors.goldTint};
      `) ||
    (props.color === 'aqua' &&
      css`
        border-color: ${(props) => props.theme.colors.teal};
        color: ${(props) => props.theme.colors.teal};
        background-color: ${(props) => props.theme.colors.aquaTint};
      `)}
`

const Check = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  line-height: 14px;
  font-size: 12px;
  border: 1px solid;
  background-color: ${(props) => props.theme.colors.xLightGrey};
  text-align: center;
  display: inline-block;
  user-select: none;
`

export default function Warning({ color = 'grey', text, width }) {
  return (
    <Notification color={color} width={width}>
      <Check>!</Check>
      {text}
    </Notification>
  )
}

Warning.propTypes = {
  color: PropTypes.string.isRequired,
  text: PropTypes.node.isRequired,
  width: PropTypes.string,
}
