import styled from 'styled-components'
import { useQuery } from 'urql'
import Link from '../_atoms/Link'
import Transition from '../_abstracts/Animation'
import { AllCaps, HeaderSansXS } from '../_abstracts/Type'
import Container from '../_helpers/Container'
import Html, { Wrapper as HtmlWrapper } from '../data/Html'
import { useGlobals } from '../hooks'
import PaymentIconList from './PaymentIconList'
import Socials from './Socials'
import { down } from 'styled-breakpoints'
import { Icon, PoweredByTnagIcon } from '../_base/Icon'
import Newsletter from './Newsletter'

const GET_NAVIGATION = `
  query GetNavigation($handle: String!) {
    nodes(navHandle: $handle, level: 1) {
      id
      title
      url
      ... on footer_Node {
        children {
          id
          title
          url
        }
      }
    }
  }
`

const StyledFooter = styled.footer`
  padding-top: 32px;
  background-color: ${(props) => props.theme.colors.lightGrey};
  color: ${(props) => props.theme.colors.xDarkGrey};
  border-top: 1px solid ${(props) => props.theme.colors.lightBtnGrey};

  @media print {
    display: none;
  }
`

const FooterNav = styled(Container)`
  display: flex;
  gap: 35px;

  ${down('medium')} {
    flex-wrap: wrap;
  }
`

const ListTitle = styled.h4`
  ${HeaderSansXS};
  font-size: 15px;
  font-weight: 700;
`

const AllCapsLink = styled(Link)`
  ${AllCaps};
  font-size: 15px;
  white-space: nowrap;
`

const NavList = styled.div`
  flex: 1 1 100px;

  &:last-child {
    display: flex;
    flex-direction: column;
    align-items: center;

    ${AllCapsLink} {
      margin-top: auto;
      text-align: center;
      padding: 8px 10px;
      border-radius: ${(props) => props.theme.radii};
      position: relative;
      overflow: hidden;
      z-index: 0;
      color: ${(props) => props.theme.colors.darkBlue};

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: -1;
        transform: translateX(-110%);
        background-color: ${(props) => props.theme.colors.white};
        ${Transition({ property: 'transform' })};
      }

      &:hover {
        &::after {
          transform: translateX(0%);
        }
      }
    }
  }
`

const Showroom = styled.div`
  flex: 1 3 200px;

  ${Link} {
    color: ${(props) => props.theme.colors.accent};

    &:hover {
      color: ${(props) => props.theme.colors.darkBlue};
    }
  }

  ${HtmlWrapper} > * + * {
    margin-top: 0.5em;
  }

  ${down('medium')} {
    min-width: 50%;
  }

  ${down('small')} {
    flex: initial;
  }
`

const CardTypes = styled.div`
  width: 100%;
  flex: 1 1 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid ${(props) => props.theme.colors.lightBtnGrey};
  padding-top: 35px;
  padding-bottom: 35px;
  margin-top: 55px;

  ${down('small')} {
    margin-top: 20px;
  }
`

const NavListItems = styled.ul`
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${(props) => props.theme.colors.lightBtnGrey};
  margin-top: 10px;

  ${Link},
  p {
    font-size: 14px;
    margin-top: 10px;
  }

  ${Link} {
    text-transform: capitalize;
  }
`

const FooterBottom = styled.div`
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: ${(props) => props.theme.colors.darkBlue};
  color: ${(props) => props.theme.colors.white};
`

const FooterBottomInner = styled(Container)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  ${down('small')} {
    flex-direction: column;
    justify-content: center;
    text-align: center;
    gap: 15px;
  }
`

const Power = styled.a`
  ${Transition({ property: 'opacity' })};
  display: inline-block;

  &:hover {
    opacity: 0.8;
  }

  ${Icon} {
    width: 175px;
    margin-top: 8px;
    margin-bottom: -2px;
    fill: currentColor;
  }
`

export default function Footer() {
  const [{ data, error }] = useQuery({
    query: GET_NAVIGATION,
    variables: {
      handle: 'footer',
    },
  })

  if (error) {
    console.error(error)
  }

  const { footer } = useGlobals('footer')
  const { social } = useGlobals('social')

  const backToTop = (ev) => {
    ev.preventDefault()

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })

    const focusableElements = document.querySelectorAll(
      'button, a, input, select, textarea, [tabindex]:not([tabindex="-1"])'
    )
    focusableElements[0]?.focus({
      preventScroll: true,
    })
  }

  return (
    <>
      <Newsletter />
      <StyledFooter>
        <FooterNav>
          {data?.nodes?.map((node) => (
            <NavList key={node.id}>
              <ListTitle>
                {node.url ? (
                  <Link to={node.url}>{node.title}</Link>
                ) : (
                  node.title
                )}
              </ListTitle>
              {node.children.length ? (
                <NavListItems>
                  {node.children.map((childNode) => (
                    <Link key={childNode.id} to={childNode.url} $reverse="true">
                      {childNode.title}
                    </Link>
                  ))}
                </NavListItems>
              ) : null}
            </NavList>
          ))}
          {footer && (
            <>
              {(footer.heading || footer.textSimple) && (
                <NavList>
                  {footer.heading && <ListTitle>{footer.heading}</ListTitle>}
                  {footer.textSimple && (
                    <NavListItems>
                      <Html html={footer.textSimple} />
                    </NavListItems>
                  )}
                </NavList>
              )}
              <Showroom>
                {footer.footerTitle && (
                  <ListTitle>{footer.footerTitle}</ListTitle>
                )}
                <NavListItems>
                  {footer.footerText && <Html html={footer.footerText} />}
                  {footer.footerLink?.url && (
                    <AllCapsLink to={footer.footerLink?.url}>
                      {footer.footerLink?.text}
                    </AllCapsLink>
                  )}
                </NavListItems>
              </Showroom>
            </>
          )}
          <NavList>
            {social && (
              <Socials
                facebook={social?.socialFacebook}
                instagram={social?.socialInstagram}
                twitter={social?.socialTwitter}
                youtube={social?.socialYoutube}
              />
            )}
            <AllCapsLink onClick={backToTop} to="#root">
              Back to the top
            </AllCapsLink>
          </NavList>
        </FooterNav>
        <CardTypes>
          <PaymentIconList gap={20} />
        </CardTypes>
        <FooterBottom>
          <FooterBottomInner>
            <small>
              ©{new Date().getFullYear()} The North American Guitar Nashville,
              LLC. All rights reserved.
              <br />
              <Power
                href="https://tnag.com/"
                target="_blank"
                aria-label="Powered by TNAG"
              >
                <PoweredByTnagIcon />
              </Power>
            </small>
            <small>
              Website Designed and{' '}
              <Link to="https://builtbybuffalo.com/">Built by Buffalo</Link>
            </small>
          </FooterBottomInner>
        </FooterBottom>
      </StyledFooter>
    </>
  )
}

Footer.propTypes = {}
