import styled from 'styled-components'
import PropTypes from 'prop-types'
import { HeaderXL, HeaderSansXS, BodyS } from '../_abstracts/Type'
import Button from '../_atoms/Button'

const Wrapper = styled.div`
  flex-grow: 1;
  padding: 75px 20px 50px;
  background-color: ${(props) => props.theme.colors.darkBlue};
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
`

const Inner = styled.div`
  position: relative;
  max-width: 800px;
  margin-right: auto;
  margin-left: auto;
  background: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.midGrey};
  border-radius: ${(props) => props.theme.radii};
  text-align: center;
`

const Error = styled.h2`
  ${BodyS}
  text-transform: uppercase;
  text-align: center;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  max-width: 400px;
  margin-bottom: 35px;
  border-bottom: 1px solid ${(props) => props.theme.colors.accent};
  padding-bottom: 10px;
  color: ${(props) => props.theme.colors.white};
`

const Header = styled.h1`
  ${HeaderXL};
  padding: 50px 25px 0;
  font-weight: 700;
`

const Children = styled.div`
  ${HeaderSansXS};
  font-weight: 300;
  padding: 10px 25px 50px;

  > * + * {
    margin-top: 20px;
  }

  p {
    max-width: 664px;
    margin-right: auto;
    margin-left: auto;
  }

  strong {
    font-weight: normal;
  }
`

const Footer = styled.div`
  border-top: 1px solid ${(props) => props.theme.colors.midGrey};
  background-color: ${(props) => props.theme.colors.lightGrey};
  padding: 20px;
  text-align: center;
  font-size: 0;

  a {
    width: 100%;
    max-width: 225px;
  }
`

export default function Lost({ header, children, image }) {
  return (
    <Wrapper style={{ backgroundImage: 'url(' + image + ')' }}>
      <Error>Error</Error>
      <Inner>
        <Header>{header}</Header>
        <Children>{children}</Children>
        <Footer>
          <Button to="/" variant="primaryDark">
            Return to homepage
          </Button>
        </Footer>
      </Inner>
    </Wrapper>
  )
}

Lost.propTypes = {
  header: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  image: PropTypes.string.isRequired,
}
