import { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { useMutation } from 'urql'
import { Formik } from 'formik'
import Input from '../_atoms/Input'
import Button from '../_atoms/Button'

const CREATE_PAYMENT = `
  mutation CreateStripePayment($orderId: ID!, $amountCents: Int) {
    orderCreateStripePayment(id: $orderId, amountCents: $amountCents) {
      success
      payment { id clientSecret }
    }
  }
`

export default function StripePaymentButton({
  orderId,
  remainingAmountCents,
  taxCents,
  allowAmountOverride,
  setError,
  onSuccess,
  savedCardId,
  savePaymentMethod,
}) {
  const stripe = useStripe()
  const elements = useElements()
  const inputRef = useRef()
  const [, createPayment] = useMutation(CREATE_PAYMENT)
  const [amountCents, setAmountCents] = useState(remainingAmountCents)
  const [remainingAfterCents, setRemainingAfterCents] = useState(0)

  const [processing, setProcessing] = useState(false)

  useEffect(() => {
    setAmountCents(remainingAmountCents)
  }, [remainingAmountCents])

  async function handleClick(event) {
    event.preventDefault()

    if (processing) return

    setProcessing(true)
    setError(null)

    // TODO: Should really validate the card details before calling this
    const payment = await createPayment({
      orderId,
      amountCents: allowAmountOverride ? amountCents : undefined,
    })

    if (!payment.data?.orderCreateStripePayment?.success) {
      setError('Unable to create payment.')
      setProcessing(false)

      return
    }

    const clientSecret =
      payment.data.orderCreateStripePayment.payment.clientSecret

    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: savedCardId || {
        card: elements.getElement(CardElement),
      },
      setup_future_usage: savePaymentMethod ? 'on_session' : undefined,
    })

    if (payload.error) {
      setError(payload.error.message)
      setProcessing(false)
    } else {
      setError(null)

      const result = await onSuccess(
        payment.data.orderCreateStripePayment.payment.id
      )

      if (result?.endProcessing) {
        setProcessing(false)

        if (inputRef.current) inputRef.current.value = remainingAfterCents / 100
      }
    }
  }

  return (
    <Formik initialValues={{}}>
      <>
        {allowAmountOverride && (
          <Input
            ref={inputRef}
            type="number"
            min={0}
            max={remainingAmountCents / 100}
            label="Manual price override"
            prefix="$"
            /* TODO: This will need a slight change for some currencies */
            step="0.01"
            value={amountCents / 100}
            onChange={(event) => {
              const proposedAmountCents = parseFloat(event.target.value) * 100
              const remainingAfterCents =
                remainingAmountCents - proposedAmountCents
              setRemainingAfterCents(remainingAfterCents)
              setAmountCents(proposedAmountCents)
            }}
          />
        )}
        {remainingAfterCents > 0 && remainingAfterCents < taxCents ? (
          <p>Cannot leave less than the tax amount in an order</p>
        ) : (
          <Button
            variant={/* FIXME */ false ? 'success' : 'primaryDark'} // eslint-disable-line no-constant-condition
            disabled={processing}
            onClick={handleClick}
          >
            {processing ? 'Processing...' : 'Pay now'}
          </Button>
        )}
      </>
    </Formik>
  )
}

StripePaymentButton.propTypes = {
  orderId: PropTypes.string.isRequired,
  remainingAmountCents: PropTypes.number,
  taxCents: PropTypes.number,
  allowAmountOverride: PropTypes.bool,
  setError: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  savedCardId: PropTypes.string,
  savePaymentMethod: PropTypes.bool,
}
