import PropTypes from 'prop-types'
import { Link as ReactLink } from 'react-router-dom'

const baseFrontendUrl = process.env.RAZZLE_BASE_FRONTEND_URL

export default function Link({ children, to, forceRegular, ...props }) {
  if (
    !forceRegular &&
    to &&
    (!to.includes(':') || to.startsWith(baseFrontendUrl))
  ) {
    return (
      <ReactLink to={to.replace(baseFrontendUrl, '')} {...props}>
        {children}
      </ReactLink>
    )
  }

  return (
    <a href={to} target="_blank" rel="noopener noreferrer" {...props}>
      {children}
    </a>
  )
}

Link.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  to: PropTypes.string,
  forceRegular: PropTypes.boolean,
}
