import PropTypes from 'prop-types'
import { useCallback, useState } from 'react'
import { useLocation } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { scrollToElement } from '../_abstracts/Layout'
import Field from '../forms/Field'
import Currency from '../inputs/Currency'

const EstimateFieldWrap = styled.div`
  width: 100%;
  display: flex;

  ${(props) =>
    props.highlight &&
    css`
      margin-top: 10px;
      background-color: ${props.theme.colors.lightBlue};
      border-radius: 6px;
    `};
`

export const ID = 'estimate-field'

const EstimateField = ({ description }) => {
  const { hash } = useLocation()
  const [highlight, setHighlight] = useState(false)

  // If #estimate-field is the location hash, highlight and scroll to the estimate field
  const wrapRef = useCallback(
    (node) => {
      if (hash === `#${ID}` && node && !highlight) {
        scrollToElement(node)
        setHighlight(true)
      }
    },
    [hash, highlight]
  )

  return (
    <EstimateFieldWrap ref={wrapRef} highlight={highlight} id={ID}>
      <Field>
        <Currency
          description={description}
          label="Your value estimate (optional)"
          name="item.userEstimate.amountCents"
          currencyFieldName="item.userEstimate.currencyIso"
        />
      </Field>
    </EstimateFieldWrap>
  )
}

EstimateField.propTypes = {
  description: PropTypes.string,
}

export default EstimateField
