import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { useMutation } from 'urql'
import Transition from '../_abstracts/Animation'
import StyledLink from '../_atoms/Link'
import { Icon, TrashIcon } from '../_base/Icon'
import Money from '../data/Money'
import Alert, { Wrapper as AlertStyle } from '../shared/Alert'
import { REMOVE_FROM_CART, removeItemFromCart } from './utilities'
import { down } from 'styled-breakpoints'
import { useCartContext } from './CartContext'
import UpdateQuantityInput from './UpdateQuantityInput'
import UpdatePriceButton from './UpdatePriceButton'
import NotesForm from './NotesForm'
import { useUserContext } from '../auth/UserContext'

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
`

const Image = styled.img`
  width: 120px;
  height: auto;
  margin-right: 26px;
  flex-shrink: 0;
`

const Content = styled.div`
  flex-grow: 1;

  ${AlertStyle} {
    margin-top: 12px;
  }
`

const Title = styled.p`
  margin-bottom: 2px;
  font-size: 14px;
  font-weight: 700;
`

const Info = styled.div`
  font-size: 12px;
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.darkGrey};

  span {
    color: ${({ theme }) => theme.colors.accent};
  }
`

const Details = styled.div`
  flex-shrink: 0;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  min-width: 210px;
  margin-right: 20px;
  margin-left: 20px;
  text-align: right;

  ${down('small')} {
    align-items: flex-start;
    text-align: left;
    margin-top: 15px;
    margin-left: 0;
    margin-right: 0;
  }
`

const Price = styled.p`
  line-height: 1.4;

  small {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.darkGrey};
  }
`

const PriceNote = styled.p`
  font-size: 12px;
`

const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
  margin-top: 7px;
`

const Remove = styled.button.attrs({ type: 'button' })`
  ${Transition({ property: 'color, background-color' })};
  position: relative;
  width: 24px;
  height: 24px;
  color: ${({ theme }) => theme.colors.darkBlue};
  background-color: ${({ theme }) => theme.colors.midGrey};
  border-radius: 50%;

  &:hover {
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.darkBlue};
  }

  ${Icon} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 15px;
  }
`

export default function Item({
  lineItemId,
  orderCompleted,
  allowCartNotes,
  cartNotes,
  listing,
  location,
  quantity,
  price,
  originalCurrencyPrice,
}) {
  const [, removeFromCart] = useMutation(REMOVE_FROM_CART)

  const { item } = listing
  const image = item.images?.[0]
  const url = `/shop/${item.slug}/${item.id}`
  const { openOrderIds, updateOpenOrderIds } = useCartContext()
  const { user } = useUserContext()

  return (
    <Wrapper>
      {image && (
        <Link to={url}>
          <Image src={image.url} alt="" />
        </Link>
      )}
      <Content>
        <Title>
          <StyledLink to={url} $reverse>
            {item.title}
          </StyledLink>
        </Title>
        <Info>
          <p>
            Sold by <span>{item.account.displayName}</span>
          </p>
          {location && <p>Shipping from {location}</p>}
        </Info>
        {/* Using existence of original currency price prop as a placeholder to determine whether import alert is shown */}
        {originalCurrencyPrice && (
          <Alert>
            <p>
              This item is listed in {originalCurrencyPrice.currency}. The shown
              price is an estimate and you will be required to pay in{' '}
              {originalCurrencyPrice.currency}. This may incur charges with your
              bank.
            </p>
            <p>
              This item is being shipped from {location}. You may be required to
              pay import duties or taxes upon delivery.
            </p>
          </Alert>
        )}
      </Content>
      <Details>
        <div>
          <Price>
            <Money
              amount={price.amount}
              currency={price.currency.iso}
              includeCurrency
            />
          </Price>
          {originalCurrencyPrice && (
            <Price>
              <small>
                (
                <Money
                  amount={originalCurrencyPrice.amount}
                  currency={originalCurrencyPrice.currency.iso}
                  includeCurrency
                />
                )
              </small>
            </Price>
          )}
          <PriceNote>+ shipping, calculated at checkout</PriceNote>
          {/* Using existence of original currency price prop as a placeholder to determine whether import note is shown */}
          {originalCurrencyPrice && (
            <PriceNote>+ Applicable tax, paid on import</PriceNote>
          )}
        </div>
        <Actions>
          {user?.isAdmin && (
            <>
              <UpdateQuantityInput
                openOrderIds={openOrderIds}
                lineItemId={lineItemId}
                quantity={quantity}
                stockLevel={item.retail?.stockLevel}
              />
              <UpdatePriceButton
                openOrderIds={openOrderIds}
                lineItemId={lineItemId}
                amount={price.amount}
              />
            </>
          )}
          {!orderCompleted && (
            <Remove
              onClick={() =>
                removeItemFromCart({
                  listingId: listing.id,
                  mutation: removeFromCart,
                  openOrderIds,
                  updateOpenOrderIds,
                })
              }
              aria-label="Remove item"
            >
              <TrashIcon />
            </Remove>
          )}
        </Actions>
        {user?.isAdmin && allowCartNotes && (
          <NotesForm
            notes={cartNotes}
            openOrderIds={openOrderIds}
            lineItemId={lineItemId}
          />
        )}
      </Details>
    </Wrapper>
  )
}

Item.propTypes = {
  lineItemId: PropTypes.string.isRequired,
  quantity: PropTypes.number,
  orderCompleted: PropTypes.bool,
  allowCartNotes: PropTypes.bool,
  cartNotes: PropTypes.string,
  listing: PropTypes.shape({
    id: PropTypes.string.isRequired,
    item: PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      images: PropTypes.arrayOf(
        PropTypes.shape({ url: PropTypes.string.isRequired })
      ),
      account: PropTypes.shape({
        displayName: PropTypes.string.isRequired,
      }).isRequired,
      retail: PropTypes.shape({
        stockLevel: PropTypes.number,
      }),
    }).isRequired,
  }).isRequired,
  location: PropTypes.string,
  price: PropTypes.shape({
    amount: PropTypes.string.isRequired,
    currency: PropTypes.shape({
      iso: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  originalCurrencyPrice: PropTypes.shape({
    amount: PropTypes.string.isRequired,
    currency: PropTypes.shape({
      iso: PropTypes.string.isRequired,
    }).isRequired,
  }),
}
