import { useGlobals } from '../hooks'
import Html from '../data/Html'
import styled from 'styled-components'
import Transition from '../_abstracts/Animation'
import { down } from 'styled-breakpoints'

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.colors.darkBlue};
  color: ${(props) => props.theme.colors.white};
  padding-bottom: 10px;
  font-family: raleway, sans-serif !important;

  ${down('medium')} {
    padding: 10px;
    text-align: center;
  }

  @media print {
    display: none;
  }
`

const WrapperInner = styled.div`
  width: 80%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;

  ${down('medium')} {
    flex-wrap: wrap;
    width: 100%;
  }

  #klaviyo-form-overrides input:hover {
    border-color: ${(props) => props.theme.colors.white} !important;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 30px;

  ${down('medium')} {
    margin-right: none;
  }
`

const Heading = styled.h2`
  font-size: 34px;
  font-weight: 700;
`

const NewsletterForm = styled.div`
  width: 75%;

  ${down('medium')} {
    width: 100%;
  }

  form {
    padding-top: 10px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  input {
    border: none !important;
    background-color: transparent !important;
    border-bottom: 1px solid ${(props) => props.theme.colors.white} !important;
    border-radius: 0 !important;
    padding-left: 0 !important;
    box-shadow: none !important;
    color: #fff !important;
  }

  button {
    padding: 0.5em 1em !important;
    border-radius: 5px !important;
    border: 1px solid !important;
    cursor: pointer !important;
    display: inline-block !important
    font-family: ${(props) => props.theme.fonts.sansSerif} !important;
    font-size: 15px !important;
    text-align: center !important;
    text-decoration: none !important;
    overflow: hidden !important;
    text-transform: capitalize !important;
    z-index: 0 !important;
    ${Transition({ property: 'background-color, color, border-color' })};
    border-color: ${(props) => props.theme.colors.darkBlue} !important;
    color: ${(props) => props.theme.colors.white} !important;
    background: transparent !important;
    position: relative !important;
    font-weight: 300 !important;
    height: 30px !important;

    &::before,
    &::after {
      content: '' !important;
      position: absolute !important;
      top: 0 !important;
      left: 0 !important;
      height: 100% !important;
      width: 100% !important;
      z-index: -1 !important;
    }
    &::before {
      background-color: ${(props) => props.theme.colors.accent} !important;
    }
    &::after {
      transform: translateX(-110%) !important;
      background-color: ${(props) => props.theme.colors.white} !important;
      ${Transition({ property: 'transform' })};
    }

    &:hover {
      border-color: ${(props) => props.theme.colors.darkBlue} !important;
      color: ${(props) => props.theme.colors.darkBlue} !important;

      &::after {
        transform: translateX(0%) !important;
      }
    }

    &:active {
      background-color: ${(props) =>
        props.theme.colors.lightBtnGrey} !important;
      border-color: ${(props) => props.theme.colors.darkBlue} !important;
      color: ${(props) => props.theme.colors.darkBlue} !important;
    }
  }
`

export default function Newsletter() {
  const { footer } = useGlobals('footer')

  if (!footer) {
    return null
  }

  const { newsletterHeading, newsletterText } = footer

  return (
    <Wrapper>
      <WrapperInner>
        <ContentWrapper>
          {newsletterHeading && <Heading>{newsletterHeading}</Heading>}
          {newsletterText && <Html dangerousRaw={true} html={newsletterText} />}
        </ContentWrapper>
        <NewsletterForm
          id="klaviyo-form-overrides"
          className="klaviyo-form-XG95GU"
        />
      </WrapperInner>
    </Wrapper>
  )
}
