import PropTypes from 'prop-types'
import styled from 'styled-components'
import Money from '../data/Money'
import Button from '../_atoms/Button'

const Codes = styled.ul`
  margin-top: 20px;
  margin-bottom: 20px;
`

const Code = styled.li`
  padding: 0.3em 1em;
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  column-gap: 1em;
  background-color: ${({ theme }) => theme.colors.lighterGrey};
  border: 1px solid ${({ theme }) => theme.colors.blueTint};
  border-radius: ${({ theme }) => theme.radii};

  &:first-child {
    margin-top: 0;
  }

  p {
    margin-right: auto;
  }

  strong {
    margin-right: 0.5em;
  }

  button {
    margin-top: 3px;
    margin-bottom: 3px;
  }
`

export default function ViewerPrepaidCodes({
  showPaymentMethods,
  processCode,
  prepaidCodes,
  orderId,
}) {
  return (
    <>
      <Codes>
        {prepaidCodes.map((code) => (
          <Code key={code.id}>
            <p>
              <strong>{code.code}</strong>
              <Money
                amount={code.amountRemaining.amount}
                currency={code.amountRemaining.currency.iso}
              />
            </p>
            <Button
              onClick={() => processCode(orderId, code.code)}
              variant="secondary"
            >
              Use Code
            </Button>
          </Code>
        ))}
      </Codes>
      <Button onClick={() => showPaymentMethods(true)}>
        Proceed without codes
      </Button>
    </>
  )
}

ViewerPrepaidCodes.propTypes = {
  showPaymentMethods: PropTypes.func.isRequired,
  processCode: PropTypes.func.isRequired,
  prepaidCodes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
      value: PropTypes.shape({
        amount: PropTypes.string.isRequired,
        currency: PropTypes.shape({
          iso: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
      amountRemaining: PropTypes.shape({
        amount: PropTypes.string.isRequired,
        amountCents: PropTypes.number.isRequired,
        currency: PropTypes.shape({
          iso: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    })
  ).isRequired,
  orderId: PropTypes.string.isRequired,
}
