import KSUID from 'ksuid'
import { Helmet } from 'react-helmet-async'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import Back from '../shared/Back'
import Subheader from '../shared/Subheader'
import ShippingForm from './Form'

const Wrapper = styled.div`
  padding: 32px;
`

export default function ShippingMethodsNewPage() {
  const id = KSUID.randomSync().string

  const values = {
    ref: id,
    name: '',
    country: '',
    ordinal: '',
    regions: [],
    currency: 'USD',
    matrix: [
      {
        price: {
          amountCents: 0,
          currency: { iso: 'USD' },
        },
        category: 'GUITAR',
        strategy: 'CHARGE_PER_ITEM',
      },
    ],
  }

  const navigate = useNavigate()
  return (
    <>
      <Helmet>
        <title>New Shipping Method</title>
      </Helmet>
      <Subheader border header="New Shipping Method" />
      <Back
        header="Back to shipping methods"
        url="/profile/settings/shipping"
        size="small"
        wrapped
      />
      <Wrapper>
        <ShippingForm
          values={values}
          editable={true}
          showDelete={false}
          onSuccess={() => navigate('/profile/settings/shipping')}
        />
      </Wrapper>
    </>
  )
}
