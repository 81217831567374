import toast from 'react-hot-toast'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { down } from 'styled-breakpoints'
import { BodyS, Body, HeaderSansL, HeaderSansM } from '../_abstracts/Type'
import { useUserContext } from '../auth/UserContext'
import Spacer from '../_helpers/Spacer'
import Money from '../data/Money'
import DateTime from '../data/DateTime'
import { offerStates } from '../data/OfferState'
import { useMutation } from 'urql'
import Avatar from '../shared/Avatar'
import { Icon, MailIcon, FlagIcon } from '../_base/Icon'
import Button from '../_atoms/Button'
import ButtonGroup from './ButtonGroup'
import Popup from '../shared/Popup'
import Modal from '../shared/Modal'
import ModalForm from '../shared/ModalForm'
import MakeOfferModal from '../offers/MakeOfferModal'
import History from '../offers/History'
import Loader from '../shared/Loader'
import ProductCartButton from '../shop/ProductCartButton'
import OfferReplyButton from './OfferReplyButton'

const ACCEPT_OFFER = `
  mutation AcceptOffer($offer: OfferAcceptInput!) {
    offerAccept(offer: $offer) {
      success
    }
  }
`

const REJECT_OFFER = `
  mutation RejectOffer($offer: OfferRejectInput!) {
    offerReject(offer: $offer) {
      success
    }
  }
`

export const Wrapper = styled.div`
  border-radius: ${(props) => props.theme.radii};
  border: 1px solid ${(props) => props.theme.colors.darkBlue};
  background-color: ${(props) => props.theme.colors.accentTint};
`

const Header = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid ${(props) => props.theme.colors.darkBlue};
  padding-left: 144px;

  ${down('small')} {
    padding-left: 20px;
  }

  ${({ layout }) =>
    layout === 'minimal' &&
    css`
      padding-right: 0;
      padding-left: 0;
    `}
`

const DateElement = styled.p`
  ${BodyS}
`

const Main = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 20px;

  ${down('small')} {
    flex-wrap: wrap;
    column-gap: 20px;
  }

  ${({ layout }) =>
    layout === 'minimal' &&
    css`
      padding-right: 0;
      padding-bottom: 0;
      padding-left: 0;
    `}
`

const Left = styled.div`
  width: 104px;
  min-width: 104px;

  ${down('small')} {
    width: 100%;
    max-width: 150px;
    margin-left: auto;
    margin-right: auto;
  }
`

const Center = styled.div`
  padding-left: 20px;
  flex-grow: 1;
  max-width: 454px;

  &:first-child {
    padding-left: 0;
  }

  ${down('small')} {
    padding-left: 0;
    padding-top: 15px;
    padding-bottom: 15px;
  }
`

const Right = styled.div`
  width: 259px;
  min-width: 259px;
  margin-left: auto;

  ${down('small')} {
    width: 100%;
  }
`

const Top = styled.h3`
  ${Body}
  margin-bottom: 10px;
  font-weight: 600;
`

const Desc = styled.p`
  ${Body}
  margin-bottom: 10px;
`

const Offers = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const Item = styled.div`
  display: inline-block;
  margin-top: 10px;
  margin-right: 25px;

  &:last-child p:first-child {
    margin-bottom: 10px;
  }

  &:last-child p:last-child {
    ${HeaderSansM}
  }
`

const Label = styled.p`
  ${BodyS}
  margin-bottom: 5px;
`

const Mons = styled.p`
  ${HeaderSansL}
  line-height: 1;
`

const Meta = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 7px;
  margin-left: auto;

  > *:not(:last-child) {
    border-right: 1px solid ${(props) => props.theme.colors.midGrey};
    padding-right: 15px;
    margin-right: 15px;
  }
`

const MetaItem = styled.button.attrs({ type: 'button' })`
  ${BodyS}
  display: inline-flex;
  align-items: center;
  background-color: transparent;
  border: 0;
  margin-top: 2px;
  margin-bottom: 2px;
  cursor: pointer;

  ${Icon} {
    display: inline-block;
    width: 13px;
    height: 13px;
    min-width: 13px;
    margin-right: 8px;
  }

  &:hover {
    color: ${(props) => props.theme.colors.accent};
  }

  &:last-child ${Icon} {
    width: 10px;
    min-width: 10px;
  }
`

export default function Offer({
  offer,
  includeReply = true,
  item,
  onStateChange,
  layout = 'standard',
}) {
  const [, acceptOffer] = useMutation(ACCEPT_OFFER)
  const [, rejectOffer] = useMutation(REJECT_OFFER)
  const { user } = useUserContext()

  if (!user) return <Loader background="#fff" height="175px" />

  const currentIteration = offer?.currentIteration || offer
  const date = new Date(currentIteration.createdAt)
  const isCurrentSender = currentIteration.fromAccount.id === user.id
  const isSeller = user.id === item.account.id

  async function handleAccept() {
    const acceptPromise = acceptOffer({
      offer: { offerId: currentIteration.id },
    })

    toast.promise(
      acceptPromise,
      {
        loading: 'Accepting offer…',
        success: 'Offer accepted',
        error: 'Unable to accept offer',
      },
      { id: 'offer' }
    )

    let response
    try {
      response = await acceptPromise

      if (response.error || response.data?.offerAccept?.success === false) {
        throw new Error(response.error?.toString())
      }
    } catch (error) {
      toast.error('Unable to accept offer', { id: 'offer' })
      console.error(error)
      return
    }

    if (response?.data?.offerAccept?.success) {
      onStateChange?.(offer)
    }
  }

  async function handleReject() {
    const rejectPromise = rejectOffer({
      offer: { offerId: currentIteration.id },
    })

    toast.promise(
      rejectPromise,
      {
        loading: 'Rejecting offer…',
        success: 'Offer rejected',
        error: 'Unable to reject offer',
      },
      { id: 'offer' }
    )

    let response
    try {
      response = await rejectPromise

      if (response.error || response.data?.offerReject?.success === false) {
        throw new Error(response.error?.toString())
      }
    } catch (error) {
      toast.error('Unable to reject offer', { id: 'offer' })
      console.error(error)
      return
    }

    if (response?.data?.offerReject?.success) {
      onStateChange?.(offer)
    }
  }

  return (
    <Wrapper>
      <Header layout={layout}>
        {layout === 'standard' && (
          <DateElement>
            <DateTime date={date} noTime />
          </DateElement>
        )}

        <Meta>
          <Popup
            trigger={
              <MetaItem>
                <MailIcon />
                View Offer History
              </MetaItem>
            }
          >
            <History id={currentIteration.id} />
          </Popup>

          <Modal
            size="large"
            trigger={
              <MetaItem>
                <FlagIcon />
                Report an Issue
              </MetaItem>
            }
          >
            <ModalForm
              successMessage="Message sent"
              info="Please let us know if you're having issues."
            />
          </Modal>
        </Meta>
      </Header>

      <Main layout={layout}>
        {layout === 'standard' && (
          <Left>
            <Avatar
              user={
                isCurrentSender
                  ? currentIteration.toAccount
                  : currentIteration.fromAccount
              }
            />
          </Left>
        )}

        <Center>
          <Top>
            {isCurrentSender
              ? `You have sent ${currentIteration.toAccount.username} an offer`
              : `${currentIteration.fromAccount.username} has sent you an offer`}
          </Top>

          {currentIteration.details && (
            <Desc>Message: {currentIteration.details}</Desc>
          )}

          <Offers>
            <Item>
              <Label>Offer Amount</Label>
              <Mons>
                <Money
                  amount={currentIteration.amountOffered.amount}
                  currency={currentIteration.amountOffered.currency.iso}
                />
              </Mons>
            </Item>
          </Offers>
        </Center>

        <Right>
          {isCurrentSender ? (
            (currentIteration.state === offerStates.ACCEPTED &&
              (isSeller ? (
                <p>This offer has been accepted by the buyer.</p>
              ) : (
                <>
                  <p>This offer has been accepted by the seller.</p>
                  <Spacer size="10px" />
                  <ProductCartButton
                    addButtonLabel="Pay now"
                    listingId={item.listing?.id}
                    offerId={currentIteration.id}
                    inViewerCart={item.listing?.inViewerCart}
                    removeButtonLabel="Remove from cart"
                  />
                </>
              ))) ||
            (currentIteration.state === offerStates.REJECTED && (
              <>
                <p>
                  The {isSeller ? 'buyer' : 'seller'} has rejected this offer.
                </p>
                <Spacer size="10px" />
                <MakeOfferModal
                  buttonLabel="Make a new offer"
                  buttonFullWidth
                  item={item}
                  onStateChange={onStateChange}
                />
              </>
            )) ||
            (currentIteration.state === offerStates.COUNTERED && (
              <p>
                The {isSeller ? 'buyer' : 'seller'} has responded to this offer
                with a counter offer.
              </p>
            )) ||
            (currentIteration.state === offerStates.OFFERED && !isSeller && (
              <ProductCartButton
                addButtonLabel="Buy now"
                listingId={item.listing?.id}
                inViewerCart={item.listing?.inViewerCart}
                removeButtonLabel="Remove from cart"
              />
            ))
          ) : (
            <>
              {(currentIteration.state === offerStates.OFFERED && (
                <>
                  <ButtonGroup direction="column" fullWidth gap={12}>
                    <Button
                      fullWidth
                      variant="primaryDark"
                      onClick={handleAccept}
                    >
                      Accept
                    </Button>
                    <MakeOfferModal
                      buttonLabel="Counter Offer"
                      item={item}
                      offer={currentIteration}
                      onStateChange={onStateChange}
                    />
                    {includeReply && (
                      <OfferReplyButton
                        participantId={currentIteration.fromAccount.id}
                        itemId={item.id}
                      />
                    )}
                    <Popup
                      trigger={<Button variant="tertiary">Decline</Button>}
                    >
                      <>
                        <p>Are you sure you wish to decline?</p>
                        <Button
                          fullWidth
                          variant="primaryDark"
                          onClick={handleReject}
                        >
                          Decline
                        </Button>
                        <MakeOfferModal
                          buttonLabel="Counter Offer"
                          item={item}
                          offer={offer}
                          onStateChange={onStateChange}
                        />
                      </>
                    </Popup>
                  </ButtonGroup>
                </>
              )) ||
                (currentIteration.state === offerStates.ACCEPTED && (
                  <>
                    <p>You have accepted this offer.</p>
                    {isSeller ? null : (
                      <>
                        <Spacer size="10px" />
                        <ProductCartButton
                          addButtonLabel="Pay now"
                          listingId={item.listing?.id}
                          offerId={currentIteration.id}
                          inViewerCart={item.listing?.inViewerCart}
                          removeButtonLabel="Remove from cart"
                        />
                      </>
                    )}
                  </>
                )) ||
                (currentIteration.state === offerStates.REJECTED && (
                  <p>You have rejected this offer.</p>
                )) ||
                (currentIteration.state === offerStates.COUNTERED && (
                  <p>You have responded to this offer with a counter offer.</p>
                ))}
            </>
          )}
        </Right>
      </Main>
    </Wrapper>
  )
}

Offer.propTypes = {
  offer: PropTypes.object.isRequired,
  includeReply: PropTypes.bool,
  item: PropTypes.object.isRequired,
  onStateChange: PropTypes.func,
  layout: PropTypes.oneOf(['standard', 'minimal']),
}
