import PropTypes from 'prop-types'
import { useQuery } from 'urql'
import Carousel from './Carousel'
import Loader from './Loader'

const SEARCH_LISTINGS = `
  query SearchListings($first: Int, $after: String, $query: SearchQuery, $sort: SearchSort) {
    listingSearch(first: $first, after: $after, query: $query, sort: $sort) {
      edges {
        node {
          id
          isAvailable
          askingPrice {
            amount
            currency {
              iso
            }
          }
          item {
            id
            slug
            images {
              id
              url(size: "productThumb")
            }
            title
            account {
              id
              username
              displayName
              isPrivateSeller
              isProfessionalSeller
            }
          }
        }
      }
    }
  }
`

export default function SectionCarousel({
  keywords,
  filters,
  filterListings,
  sort,
  skus,
  ...props
}) {
  const [{ data, fetching, error }] = useQuery({
    query: SEARCH_LISTINGS,
    variables: {
      first: 12,
      query: {
        keywords,
        filters,
        skus,
      },
      sort,
    },
  })

  if (fetching) return <Loader />

  if (error) return <p>{error.toString()}</p>

  let listings = data?.listingSearch?.edges?.map((edge) => edge.node) || []
  if (filterListings) listings = filterListings(listings)
  if (!listings.length) return null

  return <Carousel listings={listings} {...props} />
}

SectionCarousel.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.object),
  filterListings: PropTypes.func,
  header: PropTypes.string.isRequired,
  theme: PropTypes.oneOf(['light', 'dark', 'grey']),
  view: PropTypes.string,
  viewText: PropTypes.string,
  keywords: PropTypes.string,
  sort: PropTypes.string,
  skus: PropTypes.arrayOf(PropTypes.string),
}
