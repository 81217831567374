import { between } from 'polished'

// This fluidRange helper is based on https://polished.js.org/docs/#fluidrange, but uses clamping
// rather than media queries (see https://github.com/styled-components/polished/issues/563)
//
// max(min()) is used instead of clamp() for better browser support (see Safari at https://caniuse.com/css-math-functions)

export const fluidRange = (
  fromSize,
  toSize,
  fromScreen = '320px',
  toScreen = '1440px'
) =>
  `max(${fromSize}, min(${between(
    fromSize,
    toSize,
    fromScreen,
    toScreen
  )}, ${toSize}))`

export const getHeaderHeight = () => {
  if (typeof window !== 'undefined') {
    const header = document.getElementById('header')
    if (header) {
      const rect = header.getBoundingClientRect()
      if (rect.height) return rect.height
    }

    const mobileHeader = document.getElementById('mobile-header')
    if (mobileHeader) {
      const rect = mobileHeader.getBoundingClientRect()
      if (rect.height) return rect.height
    }
  }

  return 0
}

export const isReducedMotion = () => {
  if (typeof window !== 'undefined') {
    return window.matchMedia('(prefers-reduced-motion: reduce)').matches
  }

  return false
}

export const scrollToPosition = ({
  target,
  top,
  left,
  behavior,
  includeHeaderOffset,
}) => {
  target = target || window
  top = top || 0
  left = left || 0
  behavior = behavior || 'smooth'

  if (includeHeaderOffset !== false) {
    top -= getHeaderHeight()
  }

  if (behavior === 'smooth' && isReducedMotion()) {
    behavior = 'auto'
  }

  target.scrollTo({
    top,
    left,
    behavior,
  })
}

// Selector to find first focusable target in element
// Taken from https://github.com/focus-trap/tabbable
const defaultFocusableSelector = [
  'input',
  'select',
  'textarea',
  'a[href]',
  'button',
  '[tabindex]:not(slot)',
  'audio[controls]',
  'video[controls]',
  '[contenteditable]:not([contenteditable="false"])',
  'details>summary:first-of-type',
  'details',
].join(',')

export const scrollToElement = (
  element,
  options = {},
  focusableSelector = defaultFocusableSelector
) => {
  if (!element) {
    console.warn('scrollToElement: Element not found', element)
    return
  }

  const rect = element.getBoundingClientRect()
  const top = window.scrollY + rect.top
  const left = window.scrollX + rect.left

  if (focusableSelector) {
    // Move focus to first target within element
    element.querySelector(focusableSelector)?.focus({ preventScroll: true })
  }

  scrollToPosition({
    top,
    left,
    ...options,
  })
}
