import { hideVisually } from 'polished'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { BodyS, HeaderS, HeaderXS, HeaderL, HeaderM } from '../_abstracts/Type'
import Roundel from '../shared/Roundel'
import Transition from '../_abstracts/Animation'
import Button, { ButtonStyle } from '../_atoms/Button'
import Icon from '../blocks/Icon'
import Tooltip, { Wrapper as TooltipWrapper } from '../shared/Tooltip'

import { down } from 'styled-breakpoints'
import Radio, { Wrapper as RadioWrapper } from '../inputs/Radio'

export const Wrapper = styled.div`
  ${RadioWrapper} {
    position: static;
    display: flex;
    justify-content: center;
    padding: 20px;
  }
`

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -13px;
`

const Header = styled.h2`
  ${HeaderL}
  width: 100%;
  text-align: center;
  margin: 13px 13px 10px;
`

const Item = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 382px;
  min-width: 300px;
  margin: 13px;
  border: 1px solid ${(props) => props.theme.colors.midGrey};
  border-radius: ${(props) => props.theme.radii};

  > * {
    border: inherit;
    border-width: 1px 0 0;
  }

  > :first-child {
    border-top: 0;
  }

  ${(props) =>
    (props.variant === 'accent' || props.fieldChecked) &&
    css`
      border-color: ${(props) => props.theme.colors.accent};
    `}

  ${(props) =>
    props.fieldChecked &&
    css`
      box-shadow: 0 0 12px 0 rgba(6, 6, 45, 0.12);
    `}
`

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
  padding: 30px;

  ${down('medium')} {
    padding: 20px;
  }

  ${ButtonStyle} {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
`

const Top = styled.div`
  flex-grow: 1;
`

const Tag = styled.div`
  min-height: 50px;
`

const Title = styled.h3`
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  ${({ $size }) =>
    ($size === 'small' && HeaderM) || ($size === 'large' && HeaderL)}

  font-weight: 600;
`

const IconLabel = styled.span`
  ${hideVisually}
`

const Plus = styled.i`
  position: relative;
  width: 33px;
  min-width: 33px;
  height: 33px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.accent};
  color: ${(props) => props.theme.colors.white};
  vertical-align: middle;
  margin-left: 10px;
  line-height: 33px;
  font-family: ${({ theme }) => theme.fonts.sansSerif};
  font-size: 33px;
  font-weight: 400;
  font-style: normal;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
    content: '+';
  }

  ${ButtonStyle} & {
    ${Transition({ property: 'color, background-color' })}
    width: 20px;
    min-width: 20px;
    height: 20px;
    color: ${(props) => props.theme.colors.accent};
    background-color: ${(props) => props.theme.colors.white};
    line-height: 20px;
    font-size: 20px;
  }

  ${ButtonStyle}:hover & {
    color: ${(props) => props.theme.colors.white};
    background-color: ${(props) => props.theme.colors.accent};
  }
`

const Subtitle = styled.h4`
  margin-bottom: 15px;
  ${({ $size }) =>
    ($size === 'small' && HeaderXS) || ($size === 'large' && HeaderS)}
  color: inherit;

  &:last-child {
    margin-bottom: 0;
  }

  ${TooltipWrapper} {
    margin-left: 7px;
    vertical-align: middle;
    margin-top: -3px;
  }
`

const Text = styled.div`
  ${BodyS}

  > * + * {
    margin-top: 20px;
  }
`

const Footer = styled.div`
  ${BodyS}
  flex-grow: 1;
  width: 100%;
  padding: 30px;
  background-color: ${(props) => props.theme.colors.white};

  * + * {
    margin-top: 15px;
  }
`

const List = styled.ul`
  padding-left: 0;
  list-style-type: none;
`

const ListItem = styled.li`
  position: relative;
  padding-left: 35px;

  i {
    position: absolute;
    top: 0;
    left: 0;
    width: 25px;
    min-width: 25px;
    height: 25px;
  }
`

export default function Plans({ list, header, fieldName, fieldRequired }) {
  return (
    <Wrapper>
      <Container>
        {header && <Header>{header}</Header>}

        {list.map((item, index) => (
          <Item
            key={index}
            variant={item.variant}
            fieldChecked={item.fieldChecked}
          >
            <Content>
              <Top>
                {item.label && (
                  <Tag>
                    <Roundel variant={item.variant}>{item.label}</Roundel>
                  </Tag>
                )}

                <Title $size={item.titleSize}>
                  <span>{item.title}</span>
                  {item.icon === 'plus' && (
                    <>
                      <IconLabel>Plus</IconLabel>
                      <Plus aria-hidden="true" />
                    </>
                  )}
                </Title>
                <Subtitle $size={item.titleSize}>
                  <>
                    {item.subtitle}
                    {item.tooltip && <Tooltip>{item.tooltip}</Tooltip>}
                  </>
                </Subtitle>
                {item.text && <Text>{item.text}</Text>}
              </Top>

              {item.button && item.buttonText && (
                <Button
                  fullWidth
                  variant={
                    (item.variant === 'accent' && 'primaryLight') ||
                    'primaryDark'
                  }
                  to={item.button}
                  state={item.buttonState}
                >
                  <span>{item.buttonText}</span>
                  {item.icon === 'plus' && (
                    <>
                      <IconLabel>Plus</IconLabel>
                      <Plus aria-hidden="true" />
                    </>
                  )}
                </Button>
              )}
            </Content>

            <Footer>
              <List>
                {item.list.map((li) => (
                  <ListItem key={li.id}>
                    {li.icon && <Icon icon={li.icon} />}
                    {li.plansLabel}
                  </ListItem>
                ))}
              </List>

              {item.details}
            </Footer>

            {fieldName && item.fieldValue && (
              <Radio
                name={fieldName}
                value={item.fieldValue}
                required={fieldRequired}
              />
            )}
          </Item>
        ))}
      </Container>
    </Wrapper>
  )
}

Plans.defaultProps = {
  headerSize: 'large',
}

Plans.propTypes = {
  fieldName: PropTypes.string,
  fieldRequired: PropTypes.bool,
  header: PropTypes.string,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      titleSize: PropTypes.oneOf(['small', 'large']).isRequired,
      subtitle: PropTypes.string.isRequired,
      tooltip: PropTypes.string,
      text: PropTypes.node,
      button: PropTypes.string,
      buttonText: PropTypes.string,
      buttonState: PropTypes.object,
      details: PropTypes.node.isRequired,
      list: PropTypes.arrayOf(
        PropTypes.shape({
          icon: PropTypes.string,
          plansLabel: PropTypes.string,
        })
      ),
      icon: PropTypes.string,
      label: PropTypes.string,
      variant: PropTypes.string,
      fieldValue: PropTypes.string,
      fieldChecked: PropTypes.bool,
    })
  ).isRequired,
}
