import React, { createContext, useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from 'urql'
import Loader from '../shared/Loader'

const GET_VIEWER = `
  query GetViewer {
    user: viewer {
      id
      email
      username
      displayName
      firstName
      lastName
      phone
      bio
      video
      videoEmbed
      videoAspect
      canUseStripeTerminal
      canUseManualPayment
      canManageShippingMethods
      isPrivateSeller
      isProfessionalSeller
      isAdmin
      createdAt

      avatarImage {
        id
        url
      }

      bannerImage {
        id
        url
      }
    }

    impersonator {
      id
      username
      displayName
    }

    orders(first: 50, onlyOpen: true, filter: AS_BUYER) {
      edges {
        node {
          id
        }
      }
    }
  }
`

const UserContext = createContext(null)

export default UserContext

export function UserProvider({ refreshUrqlClient, children }) {
  const [{ data, fetching, error }] = useQuery({ query: GET_VIEWER })

  function reloadUser(returnTo) {
    refreshUrqlClient()
    localStorage.setItem('auth-reload-event', Date.now())
    if (returnTo) {
      window.location = returnTo
    } else {
      window.location.reload()
    }
  }

  useEffect(() => {
    function handleStorageEvent(event) {
      if (event.key === 'auth-reload-event') {
        refreshUrqlClient()
        window.location.reload()
      }
    }

    window.addEventListener('storage', handleStorageEvent)

    return () => {
      window.removeEventListener('storage', handleStorageEvent)
    }
  }, [refreshUrqlClient])

  if (data?.user?.createdAt && !(data.user.createdAt instanceof Date)) {
    data.user.createdAt = new Date(data.user.createdAt)
  }

  // setUserData can be used to update user data in currently rendered components, e.g. after editing bio or avatar
  const [userData, setUserData] = useState()

  if (fetching) return <Loader background="#fff" />

  if (error) return <p>Error loading viewer: {error.message}</p>

  if (data?.user && userData) {
    Object.assign(data.user, userData)
  }

  if (typeof window !== 'undefined' && window.klaviyo && data?.user) {
    if (window.localStorage.getItem('kidentify') !== data.user.id) {
      window.localStorage.setItem('kidentify', data.user.id)
      window.klaviyo.push([
        'identify',
        {
          $email: data.user.email,
          $first_name: data.user.firstName,
          $last_name: data.user.lastName,
        },
      ])
    }
  }

  return (
    <UserContext.Provider
      value={{
        user: data?.user,
        impersonator: data?.impersonator,
        reloadUser,
        setUserData,
        initialOpenOrders: data?.user?.isAdmin
          ? null
          : data?.orders?.edges?.map((e) => e.node.id),
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

UserProvider.propTypes = {
  refreshUrqlClient: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
}

export const UserConsumer = UserContext.Consumer

export const useUserContext = () => {
  return useContext(UserContext)
}
