import PropTypes from 'prop-types'
import ReactMasonry from 'react-masonry-css'
import styled from 'styled-components'

const Columns = {
  default: 3,
  700: 2,
  400: 1,
}

const Wrapper = styled.div`
  margin-right: -15px;
  margin-left: -15px;

  .masonry {
    display: flex;
    width: 100%;
    padding: 10px;
  }

  .masonry__column {
    padding-right: 5px;
    padding-left: 5px;
    background-clip: padding-box;
  }
`

const Item = styled.div`
  border: 1px solid ${(props) => props.theme.colors.midGrey};
  border-radius: ${(props) => props.theme.radii};
  margin-bottom: 10px;

  img {
    width: 100%;
    border-radius: ${(props) => props.theme.radii};
    position: relative;
    transition: all 0.3s ease;
    filter: brightness(80%);
  }
`

const Text = styled.p`
  padding: 10px 15px;
`

export default function Masonry({ photos }) {
  return (
    <Wrapper>
      <ReactMasonry
        breakpointCols={Columns}
        className="masonry"
        columnClassName="masonry__column"
      >
        {photos.map((item) => {
          return (
            <Item key={item.id}>
              <img src={item.image[0].url} alt="" />
              <Text>{item.text}</Text>
            </Item>
          )
        })}
      </ReactMasonry>
    </Wrapper>
  )
}

Masonry.propTypes = {
  photos: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      text: PropTypes.string,
      image: PropTypes.shape({
        url: PropTypes.string.isRequired,
      }),
    })
  ).isRequired,
}
