import { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useQuery } from 'urql'
import ScrollBar from '../_helpers/ScrollBar'
import Html from '../data/Html'
import Loader from '../shared/Loader'
import StandardMessage from './StandardMessage'
import { useCartContext } from '../cart/CartContext'
import Button from '../_atoms/Button'

const MESSAGES_PER_PAGE = 20

const GET_MESSAGES = `
  query GetMessages($threadId: ID!, $orderId: [ID!], $first: Int, $last: Int, $after: String, $before: String) {
    messages(threadId: $threadId, first: $first, last: $last, after: $after, before: $before) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }

      edges {
        node {
          id
          body
          createdAt
          author {
            id
            username
            avatarImage {
              id
              url
            }
          }
          offers {
            id
            state
            createdAt
            amountOffered {
              amount
              currency {
                iso
              }
            }
            details
            item {
              id
              title
              slug
              images {
                id
                url(size: "orders")
              }
              offerCount
              wishlistCount
              listing {
                id
                askingPrice {
                  amount
                  currency { iso }
                }
                isAvailable
                createdAt
                viewCount
                inViewerCart(orderId: $orderId)
              }
              account {
                id
                username
                isPrivateSeller
                isProfessionalSeller
                avatarImage {
                  id
                  url
                }
              }
            }
            fromAccount {
              id
              username
              avatarImage {
                id
                url
              }
            }
            toAccount {
              id
              username
              avatarImage {
                id
                url
              }
            }
            currentIteration {
              id
              details
              amountOffered {
                amount
                currency {
                  iso
                }
              }
              state
              createdAt

              fromAccount {
                id
                username
                avatarImage {
                  id
                  url
                }
              }
              toAccount {
                id
                username
                avatarImage {
                  id
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`

const MessageViewBox = styled.div`
  height: 50vh;
  min-height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 30px;
  padding-bottom: 30px;
  margin-bottom: 30px;
  position: relative;
  ${ScrollBar}

  > div + div {
    margin-top: 20px;
  }
`

const Message = styled.p`
  margin: 30px;
  text-align: center;
`

const LoadMore = styled.div`
  display: flex;
  justify-content: center;

  &:first-child {
    margin-bottom: 25px;
  }

  &:last-child {
    margin-top: 25px;
  }
`

export default function Messages({ threadId, userId, setRefetchMessages }) {
  const scrollContainer = useRef()
  const { openOrderIds } = useCartContext()
  const [currentPage, setCurrentPage] = useState(0)
  const [pagination, setPagination] = useState({
    first: MESSAGES_PER_PAGE,
  })
  const [{ data, fetching, error }, refetchMessages] = useQuery({
    query: GET_MESSAGES,
    variables: { threadId, orderId: openOrderIds, ...pagination },
    requestPolicy: 'network-only',
    pause: typeof window === 'undefined',
  })

  const pageInfo = data?.messages?.pageInfo

  useEffect(() => {
    if (!fetching && !pageInfo?.hasPreviousPage) {
      const id = setInterval(
        () => refetchMessages({ requestPolicy: 'cache-and-network' }),
        10000
      )
      return () => clearTimeout(id)
    }
  }, [fetching, refetchMessages, pageInfo?.hasPreviousPage])

  useEffect(() => {
    if (setRefetchMessages) {
      setRefetchMessages(() => () => {
        // Reset pagination to first page
        setPagination({
          first: MESSAGES_PER_PAGE,
        })
        setCurrentPage(0)
        refetchMessages({ requestPolicy: 'cache-and-network' })
      })
    }
  }, [setRefetchMessages, refetchMessages])

  let messages = data?.messages?.edges?.map(({ node }) => node) || []

  const offerIds = []
  messages = messages.filter((message) => {
    if (!message.offers?.length) return true

    for (const offer of message.offers) {
      if (offerIds.includes(offer.id)) {
        return false
      }

      offerIds.push(offer.id)
      return true
    }
  })

  // Scroll to bottom if first render, or if a new message has been posted
  const lastMessageId = messages[messages.length - 1]?.id
  useEffect(() => {
    if (scrollContainer.current) {
      scrollContainer.current.scrollTop = pagination.before
        ? 0 // If paginating forwards to later messages, keep scroll position at top
        : scrollContainer.current.scrollHeight
    }
  }, [lastMessageId, pagination.before])

  if (fetching) return <Loader background="#fff" height="250px" />
  if (error) return <Message>{error.message}</Message>

  async function loadEarlierMessages() {
    setPagination({
      after: pageInfo.startCursor,
      first: MESSAGES_PER_PAGE,
    })
    setCurrentPage(currentPage + 1)
  }

  async function loadLaterMessages() {
    setPagination({
      before: pageInfo.endCursor,
      last: MESSAGES_PER_PAGE,
    })
    setCurrentPage(currentPage - 1)
  }

  return (
    <MessageViewBox ref={scrollContainer}>
      {(pageInfo?.hasNextPage || !!pagination.before) && (
        <LoadMore>
          <Button onClick={loadEarlierMessages} variant="secondary">
            Load earlier messages
          </Button>
        </LoadMore>
      )}
      {messages.map((message) => (
        <StandardMessage
          key={message.id}
          date={new Date(message.createdAt)}
          message={message.body && <Html html={message.body} limited />}
          state={message.author.id === userId ? 'sent' : 'received'}
          user={message.author}
          offers={message.offers}
          refetchMessages={() =>
            refetchMessages({ requestPolicy: 'cache-and-network' })
          }
        />
      ))}
      {currentPage > 0 && (
        <LoadMore>
          <Button onClick={loadLaterMessages} variant="secondary">
            Load later messages
          </Button>
        </LoadMore>
      )}
    </MessageViewBox>
  )
}

Messages.propTypes = {
  threadId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  setRefetchMessages: PropTypes.func,
}
