import PropTypes from 'prop-types'
import styled from 'styled-components'
import { BodyS, HeaderL } from '../_abstracts/Type'
import Html from '../data/Html'

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: -12px;
`

const Item = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  min-width: min(500px, 90vw);
  padding: 12px;
  margin-right: auto;
  margin-left: auto;
`

const Content = styled.div`
  width: 100%;
  border: 1px solid ${(props) => props.theme.colors.midGrey};
  background-color: ${(props) => props.theme.colors.white};
  border-radius: ${(props) => props.theme.radii};
`

const Header = styled.div`
  padding: 26px 26px 23px;
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.colors.midGrey};
  background-color: ${(props) => props.theme.colors.lighterGrey};
`

const Title = styled.h3`
  ${HeaderL}
`

const Container = styled.div`
  padding: 13px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
`

const Text = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  min-width: 220px;
  font-weight: 300;
  padding: 13px;

  > * + * {
    margin-top: 20px;
  }
`

const Opening = styled.div`
  ${BodyS}
`

const Map = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  min-width: 220px;
  padding: 13px;

  iframe {
    width: 100%;
    height: 230px;
  }
`

export default function Stores({ items }) {
  return (
    <Wrapper>
      {items.map((item, index) => (
        <Item key={index}>
          <Content>
            <Header>
              <Title>{item.title}</Title>
            </Header>

            <Container>
              <Text>
                {item.text}
                {item.opening && <Opening>{item.opening}</Opening>}
              </Text>

              {item.mapEmbed && (
                <Map>
                  <Html html={item.mapEmbed.props.html} dangerousRaw />
                </Map>
              )}
            </Container>
          </Content>
        </Item>
      ))}
    </Wrapper>
  )
}

Stores.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.node.isRequired,
      text: PropTypes.node.isRequired,
      opening: PropTypes.node,
      mapEmbed: PropTypes.node,
    })
  ).isRequired,
}
