import { useCombobox } from 'downshift'
import { offset, useFloating } from '@floating-ui/react-dom'
import { Field, useFormikContext } from 'formik'
import { matchSorter } from 'match-sorter'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { useQuery } from 'urql'
import { useWindowSize } from '../hooks'
import ErrorMessage, { Error } from '../_atoms/ErrorMessage'
import { InputContainer, InputLabel, InputField } from '../_atoms/Input'
import { Icon, SearchIcon } from '../_base/Icon'
import ClientOnlyPortal from '../shared/ClientOnlyPortal'
import Warning from '../shared/Warning'

const Wrapper = styled(InputContainer)`
  ${InputLabel} {
    margin-bottom: 5px;
  }
`

const Box = styled.div`
  position: relative;
  width: 100%;

  ${Icon} {
    position: absolute;
    top: 50%;
    right: 10px;
    width: 18px;
    pointer-events: none;
    transform: translateY(-50%);
  }
`

const Menu = styled.ul`
  z-index: 1; // Layer above Modal, if a Combobox and Modal are open at the same time
  max-height: 250px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.darkBlue};
  border-radius: ${({ theme }) => theme.radii};
  box-shadow: ${({ theme }) => theme.shadows.mini};

  ${({ isShown }) =>
    !isShown &&
    css`
      visibility: hidden;
    `}
`

const Item = styled.li`
  padding: 5px 10px;
  font-size: 15px;
  cursor: pointer;

  ${({ highlight, theme }) =>
    highlight &&
    css`
      background-color: ${theme.colors.accentTint};
    `}
`

const Message = styled.p`
  padding: 5px 10px;
  color: ${(props) => props.theme.colors.xDarkGrey};
  opacity: 0.7;
`

const NotFound = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 8px;

  & + ${Error} {
    display: none;
  }
`

const NotFoundText = styled.div`
  display: flex;
  column-gap: 0.25em;
`

// If item is a Craft structure entry, set title based on ancestors (e.g. 'Acoustic Guitars > Dreadnoughts')
const structureItemLabel = (item, labelKey) => {
  // Return current label if ancestors have already been added
  if (item.ancestorsAddedToLabel) {
    return item[labelKey]
  }

  item.ancestorsAddedToLabel = true

  return [
    ...item.ancestors.map((entry) => entry[labelKey]),
    item[labelKey],
  ].join(' > ')
}

export default function ComboBox({
  autocomplete,
  itemLabelKey,
  itemValueKey,
  itemKey,
  itemsKey,
  itemQuery,
  itemsQuery,
  itemsQueryVariables,
  label,
  lazy,
  allowEmptyQuery,
  name,
  notFoundContent,
  onChange,
  onInput,
  placeholder,
  required,
  disabled,
  overrideWithTextField,
}) {
  const inputRef = useRef()
  const menuRef = useRef()
  const formik = useFormikContext()
  const [notFound, setNotFound] = useState()
  const [variables, setVariables] = useState(lazy ? null : itemsQueryVariables)
  const [itemsQueryPaused, setItemsQueryPaused] = useState(lazy)

  const [{ data, fetching, error }] = useQuery({
    query: itemsQuery,
    variables,
    pause: itemsQueryPaused,
  })

  const [shownItems, setShownItems] = useState([])

  const setItems = useCallback(
    (items, value = '') => {
      if (value.trim()) {
        const newItems = matchSorter(items, value.trim(), {
          keys: [itemLabelKey],
        })
        setShownItems(newItems)
      } else {
        setShownItems(items)
      }
    },
    [itemLabelKey]
  )

  const items = useMemo(() => {
    // Clone items to reset `ancestorsAddedToLabel` property
    let items = data?.[itemsKey]?.map((item) => ({ ...item })) || []

    if (items.length) {
      items = items.map((item) => {
        if (item.ancestors?.length) {
          item[itemLabelKey] = structureItemLabel(item, itemLabelKey)
        }

        return item
      })
    }

    setItems(items, inputRef.current?.value)

    return items
  }, [data, itemsKey, itemLabelKey, setItems])

  // Check if Formik values include a field with this name at the top level - if so, use that.
  // If not, expand field name to match a nested object (e.g. 'item.title' -> { item: { title }})
  // https://formik.org/docs/guides/arrays#nested-objects
  const formikValue =
    formik.values[name] ||
    name.split('.').reduce((r, k) => r?.[k], formik.values)

  const [hasInitialValue] = useState(!!formikValue)

  const [{ data: itemData }] = useQuery({
    query: itemQuery,
    variables: { [itemValueKey]: formikValue },
    pause: !hasInitialValue,
  })
  const item = itemData?.[itemKey]

  if (item?.ancestors?.length) {
    item[itemLabelKey] = structureItemLabel(item, itemLabelKey)
  }

  const [prevInputValue, setPrevInputValue] = useState(null)

  const {
    isOpen,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    openMenu,
    closeMenu,
    setInputValue,
    inputValue,
    selectedItem,
    selectItem,
  } = useCombobox({
    initialInputValue: item?.[itemLabelKey] || '',
    items: shownItems,
    itemToString: (item) => item?.[itemLabelKey] || '',
    onInputValueChange: ({ inputValue }) => {
      setPrevInputValue(inputValue)

      // Fetch options on input change if `lazy` is true
      if (lazy) {
        if (inputValue || allowEmptyQuery) {
          setVariables({
            ...itemsQueryVariables,

            // e.g. `title:*query*`, for searching Craft: https://craftcms.com/docs/3.x/searching.html#wildcard-syntax
            search: `${itemLabelKey}:*${inputValue}*`,
          })
          setItemsQueryPaused(false)
        }
      } else {
        setItems(items, inputValue)
      }

      onInput?.(inputValue)
    },
    onSelectedItemChange({ selectedItem, type }) {
      if (selectedItem) {
        onChange?.(selectedItem)
        formik.setFieldValue(name, selectedItem[itemValueKey])

        // Close combobox after a selection is made, unless triggered programmatically by typing an
        // input which matches an item (see useEffect below)
        if (type !== useCombobox.stateChangeTypes.FunctionSelectItem) {
          closeMenu()
        }

        setNotFound(false)
      } else {
        onChange?.(null)
        formik.setFieldValue(name, '')
      }
    },
    onIsOpenChange: (changes) => {
      if (changes.isOpen && lazy && allowEmptyQuery && !prevInputValue) {
        setVariables({
          ...itemsQueryVariables,
          search: `${itemLabelKey}:*`,
        })
        setItemsQueryPaused(false)
      }

      if (!changes.isOpen) {
        // Set field touched status when menu is closed (to e.g. validate field)
        formik.setFieldTouched(name)

        if (!inputValue) {
          // If input value has been cleared, also clear Downshift selection
          selectItem(null)
          setNotFound(false)
        } else {
          if (selectedItem) {
            if (inputValue !== selectedItem[itemLabelKey]) {
              // If an item has been selected, and the menu is closed after modifying the input value, clear the selection
              selectItem(null)
              setInputValue(inputValue) // Need to restore input value, as selecting a null item seems to clear it
              setNotFound(true)
            }
          } else {
            setNotFound(true)
          }
        }
      }
    },
  })

  const isMenuShown = isOpen && (inputValue || allowEmptyQuery || !lazy)

  // If an input value exactly matching an item is entered, select that item automatically
  useEffect(() => {
    if (inputValue && shownItems?.length) {
      const match = shownItems.find((item) => item[itemLabelKey] === inputValue)
      if (match) {
        selectItem(match)
      }
    }
  }, [shownItems, inputValue, itemLabelKey, selectItem])

  const hasSetInitialValues = useRef(false)
  useEffect(() => {
    if (!hasSetInitialValues.current && item?.[itemLabelKey]) {
      setInputValue(item?.[itemLabelKey])
      hasSetInitialValues.current = true
    }
  }, [item, itemLabelKey, setInputValue])

  const { x, y, reference, floating, strategy, update } = useFloating({
    placement: 'bottom',
    middleware: [offset(5)],
  })

  // Using memo so that including function in useLayoutEffect deps below doesn't cause it to run each render
  const updateFloatingPosition = useMemo(
    () => () => {
      if (inputRef.current && menuRef.current) {
        menuRef.current.style.width = `${inputRef.current.offsetWidth}px`
        update()
      }
    },
    [update]
  )

  // Update position on resize
  const windowSize = useWindowSize()
  useEffect(() => {
    if (isMenuShown && windowSize.width && windowSize.height) {
      updateFloatingPosition()
    }
  }, [updateFloatingPosition, isMenuShown, windowSize.width, windowSize.height])

  // Pass custom refs to floating-ui's callback refs
  // https://github.com/floating-ui/floating-ui/issues/1503#issuecomment-1011975726
  // https://floating-ui.com/docs/react-dom#external-reference
  useEffect(() => {
    reference(inputRef.current)
    floating(menuRef.current)
  }, [reference, floating, inputRef.current, menuRef.current]) // eslint-disable-line react-hooks/exhaustive-deps

  // Validate select field once after switching to text field. This fixes the select field's 'Required'
  // message still being shown when it becomes non-required after the field switch. Can't include `formik`
  // in useEffect deps easily as it changes each render https://github.com/jaredpalmer/formik/issues/1677
  useEffect(() => {
    if (overrideWithTextField) {
      formik.validateField(name)
    }
  }, [overrideWithTextField, name]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Wrapper>
      <InputLabel {...getLabelProps()}>
        {label}
        {required && '*'}
      </InputLabel>
      <Box {...getComboboxProps()}>
        <Field name={name}>
          {({ field, meta }) => (
            <>
              <InputField
                data-name={name}
                style={overrideWithTextField ? { display: 'none' } : undefined}
                {...getInputProps({
                  autocomplete,
                  $error: meta.touched && !!meta.error,
                  ref: inputRef,
                  onFocus: () => {
                    if (!selectedItem && !fetching) {
                      updateFloatingPosition()
                      openMenu()
                    }
                  },
                  placeholder,
                  required,
                  disabled: disabled || !!overrideWithTextField,
                })}
              />
              <input
                type="hidden"
                name={field.name}
                value={field.value}
                disabled={!!overrideWithTextField}
              />
            </>
          )}
        </Field>
        {overrideWithTextField ? (
          <Field name={overrideWithTextField}>
            {({ field, meta }) => (
              <InputField
                name={field.name}
                value={field.value}
                $error={meta.touched && !!meta.error}
                required={required}
                disabled={disabled}
                onChange={(e) => {
                  onChange?.(e.target.value)
                  formik.setFieldValue(field.name, e.target.value)
                }}
              />
            )}
          </Field>
        ) : (
          <SearchIcon />
        )}
      </Box>
      <ClientOnlyPortal>
        <Menu
          {...getMenuProps(
            {
              isShown: isMenuShown,
              ref: menuRef,
              style: {
                position: strategy,
                top: y ?? '',
                left: x ?? '',
              },
            },
            { suppressRefError: true } // Ref error seems like it may be due to rendering in a Portal
          )}
        >
          {isMenuShown &&
            (shownItems.length
              ? shownItems.map((item, index) => {
                  return (
                    <Item
                      key={`${item[itemValueKey]}${index}`}
                      {...getItemProps({
                        item,
                        index,
                        highlight: highlightedIndex === index,
                      })}
                    >
                      {item[itemLabelKey]}
                    </Item>
                  )
                })
              : (fetching && <Message>Searching…</Message>) ||
                (error && <Message>{error.message}</Message>) || (
                  <Message>No options found</Message>
                ))}
        </Menu>
      </ClientOnlyPortal>
      {notFoundContent && notFound && !overrideWithTextField ? (
        <NotFound>
          <Warning
            color="gold"
            text={
              <NotFoundText>{notFoundContent(prevInputValue)}</NotFoundText>
            }
          />
        </NotFound>
      ) : null}

      <ErrorMessage name={name} />
      {overrideWithTextField && <ErrorMessage name={overrideWithTextField} />}
    </Wrapper>
  )
}

ComboBox.defaultProps = {
  // Keys set for Craft entries queries by default:
  itemLabelKey: 'title',
  itemValueKey: 'id',
  itemKey: 'entry',
  itemsKey: 'entries',
  lazy: true,
}

ComboBox.propTypes = {
  autocomplete: PropTypes.string,
  itemLabelKey: PropTypes.string.isRequired,
  itemValueKey: PropTypes.string.isRequired,
  itemKey: PropTypes.string.isRequired,
  itemsKey: PropTypes.string.isRequired,
  itemQuery: PropTypes.string.isRequired,
  itemsQuery: PropTypes.string.isRequired,
  itemsQueryVariables: PropTypes.object,
  label: PropTypes.string.isRequired,
  lazy: PropTypes.bool,
  allowEmptyQuery: PropTypes.bool,
  name: PropTypes.string.isRequired,
  notFoundContent: PropTypes.func,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onInput: PropTypes.func,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  overrideWithTextField: PropTypes.string,
}
