import { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { HeaderS, Body } from '../_abstracts/Type'
import { DropdownIcon, Icon } from '../_base/Icon'
import { down } from 'styled-breakpoints'

export const Wrapper = styled.ul`
  background-color: ${(props) => props.theme.colors.white};
  list-style-type: none;
  counter-reset: accordion-counter 0;
  border: 1px solid ${(props) => props.theme.colors.midGrey};
  border-radius: 5px;
`

const Item = styled.li`
  &:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
  }

  &:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit;
  }

  & + & {
    border-top: 1px solid ${(props) => props.theme.colors.blueTint};
  }
`

const ItemInner = styled.div`
  border-radius: inherit;

  ${({ isOpen, theme }) =>
    isOpen &&
    css`
      background-color: ${theme.colors.lighterGrey};
    `}
`

const Header = styled.h2`
  ${HeaderS};
  border-radius: inherit;
`

const Toggle = styled.button.attrs({ type: 'button' })`
  width: 100%;
  padding: 35px;
  border-radius: inherit;

  ${down('small')} {
    padding: 20px;
  }

  ${({ includeExcerpt, isOpen }) =>
    (isOpen || includeExcerpt) &&
    css`
      padding-bottom: 0;
    `};
`

const ToggleInner = styled.span`
  display: flex;
  max-width: 800px;
  margin-right: auto;
  margin-left: auto;

  ${({ numbered }) =>
    numbered &&
    css`
      counter-increment: accordion-counter 1;

      &::before {
        margin-right: 0.5em;
        content: counter(accordion-counter) '. ';
        color: ${(props) => props.theme.colors.accent};
      }
    `}

  span {
    margin-right: auto;
  }

  ${Icon} {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    margin-left: 20px;

    ${({ isOpen }) =>
      isOpen &&
      css`
        transform: rotate(180deg);
      `};
  }
`

const Content = styled.div`
  max-width: 870px;
  padding: 20px 35px 35px 35px;
  margin-right: auto;
  margin-left: auto;

  ${({ includeExcerpt, isOpen }) =>
    !isOpen &&
    !includeExcerpt &&
    css`
      padding: 0;
    `};

  ${down('small')} {
    max-width: 840px;
    padding: 20px;
  }
`

const Text = styled.div`
  ${Body};
  overflow: hidden;
  display: block;

  ${({ includeExcerpt, isOpen }) =>
    !isOpen &&
    includeExcerpt &&
    css`
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    `};

  ${({ includeExcerpt, isOpen }) =>
    !isOpen &&
    !includeExcerpt &&
    css`
      display: none;
    `};
`

const Expand = styled.a`
  color: ${(props) => props.theme.colors.accent};
  text-decoration: underline;
  cursor: pointer;
  display: inline-block;
  margin-top: 10px;
`

const Cta = styled.div`
  margin-top: 20px;

  &:first-child {
    margin-top: 0;
  }
`

const AccordionItem = ({
  button,
  defaultOpen,
  includeExcerpt,
  numbered,
  title,
  text,
}) => {
  const textRef = useRef()
  const [showReadMore, setShowReadMore] = useState(false)
  const [isOpen, setIsOpen] = useState(defaultOpen)
  const closedLabel = includeExcerpt ? 'Read Less' : 'Close'
  const linkName = isOpen ? closedLabel : 'Read More'

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  // Show 'Read more' link if description text overflows
  useEffect(() => {
    const resize = () => {
      setShowReadMore(
        isOpen || textRef.current?.scrollHeight > textRef.current?.offsetHeight
      )
    }

    resize()
    window.addEventListener('resize', resize)

    return () => window.removeEventListener('resize', resize)
  }, [textRef, isOpen, setShowReadMore])

  return (
    <ItemInner isOpen={isOpen}>
      <Header>
        <Toggle
          includeExcerpt={includeExcerpt}
          isOpen={isOpen}
          onClick={toggle}
        >
          <ToggleInner isOpen={isOpen} numbered={numbered}>
            <span>{title}</span>
            <DropdownIcon />
          </ToggleInner>
        </Toggle>
      </Header>
      <Content includeExcerpt={includeExcerpt} isOpen={isOpen}>
        <Text includeExcerpt={includeExcerpt} isOpen={isOpen} ref={textRef}>
          {text}
          {button && <Cta>{button}</Cta>}
        </Text>
        {showReadMore && (
          <Expand
            onClick={() => {
              setIsOpen(!isOpen)
            }}
          >
            {linkName}
          </Expand>
        )}
      </Content>
    </ItemInner>
  )
}

AccordionItem.propTypes = {
  button: PropTypes.node,
  defaultOpen: PropTypes.bool,
  includeExcerpt: PropTypes.bool,
  numbered: PropTypes.bool,
  text: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
}

export default function Accordion({
  defaultFirstOpen,
  includeExcerpt,
  items,
  numbered,
}) {
  return (
    <Wrapper>
      {items.map((item, index) => (
        <Item key={index}>
          <AccordionItem
            defaultOpen={defaultFirstOpen && index === 0}
            includeExcerpt={includeExcerpt}
            numbered={numbered}
            {...item}
          />
        </Item>
      ))}
    </Wrapper>
  )
}

Accordion.propTypes = {
  defaultFirstOpen: PropTypes.bool,
  includeExcerpt: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      button: PropTypes.node,
      title: PropTypes.string.isRequired,
      text: PropTypes.node.isRequired,
    })
  ),
  numbered: PropTypes.bool,
}
