import { cloneElement } from 'react'
import PropTypes from 'prop-types'
import toast from 'react-hot-toast'
import { useMutation } from 'urql'
import { UserConsumer } from './UserContext'

const STOP_IMPERSONATING = `
  mutation StopImpersonating {
    sessionStopImpersonating {
      success
    }
  }
`

export default function StopImpersonatingButton({ children }) {
  const [, stopImpersonating] = useMutation(STOP_IMPERSONATING)

  return (
    <UserConsumer>
      {({ reloadUser }) => {
        return cloneElement(children, {
          onClick: async () => {
            const afterImpersonation =
              localStorage.getItem('afterImpersonation')
            await stopImpersonating()
            if (afterImpersonation) {
              localStorage.removeItem('afterImpersonation')
              reloadUser(afterImpersonation)
            } else {
              reloadUser('/')
              toast.success('Stopped impersonating')
            }
          },
        })
      }}
    </UserConsumer>
  )
}

StopImpersonatingButton.propTypes = {
  children: PropTypes.node.isRequired,
}
