import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { BodyS } from '../_abstracts/Type'
import { Icon } from '../_base/Icon'
import Modal from '../shared/Modal'
import ModalForm from '../shared/ModalForm'
import Link from '../shared/Link'

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${(props) =>
    props.$end &&
    css`
      justify-content: flex-end;
    `}
`

const Item = styled(Link)`
  ${BodyS}
  display: inline-flex;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
  cursor: pointer;

  &:not(:last-child) {
    position: relative;
    padding-right: 15px;
    margin-right: 15px;

    &::before {
      position: absolute;
      top: 50%;
      right: 0;
      height: 10px;
      transform: translateY(-50%);
      width: 1px;
      background-color: ${(props) => props.theme.colors.midGrey};
      content: '';
    }
  }

  ${Icon} {
    display: inline-block;
    width: 17px;
    height: 17px;
    margin-right: 10px;
  }

  &:hover {
    color: ${(props) => props.theme.colors.accent};
  }
`

export default function Meta({ list, end = false }) {
  return (
    <Wrapper $end={end}>
      {list.map((item) =>
        item.report ? (
          <Modal
            size="large"
            trigger={
              <Item as="button" type="button" key={item.text}>
                {item.icon}
                {item.text}
              </Item>
            }
          >
            <ModalForm
              successMessage="Message sent"
              info="Praesent eget penatibus posuere dignissim vel. Congue sem lectus nisl tellus. Eget consectetur amet peu duis turpis. Vitae aliquam do. Ongue sem lectus nisl tellus."
            />
          </Modal>
        ) : (
          <Item to={item.to} key={item.text}>
            {item.icon}
            {item.text}
          </Item>
        )
      )}
    </Wrapper>
  )
}

Meta.propTypes = {
  end: PropTypes.bool,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.node.isRequired,
      text: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
      report: PropTypes.bool,
    })
  ).isRequired,
}
