import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { Icon, DoIcon, WarningIcon } from '../_base/Icon'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0.75em;
  font-size: ${({ size }) =>
    (size === 'small' && '12px') ||
    (size === 'medium' && '14px') ||
    (size === 'large' && '16px')};
  text-align: left;
  border: 1px solid currentColor;
  border-radius: 4px;

  ${({ theme, type }) =>
    (type === 'warning' &&
      css`
        color: ${theme.colors.darkBlue};
        background-color: ${theme.colors.blueTint};
      `) ||
    (type === 'success' &&
      css`
        color: ${theme.colors.teal};
        background-color: ${theme.colors.aquaTint};
      `) ||
    (type === 'info' &&
      css`
        color: ${theme.colors.blue};
        background-color: ${theme.colors.lightBlue};
      `)}

  ${({ center }) =>
    center &&
    css`
      justify-content: center;
    `}

  ${Icon} {
    flex-shrink: 0;
    margin-right: 0.75em;

    ${({ iconWidth }) =>
      iconWidth &&
      css`
        width: ${iconWidth};
      `}
  }

  @media print {
    display: none;
  }
`

const Content = styled.div`
  line-height: 1.5;

  ${({ center }) =>
    !center &&
    css`
      flex-grow: 1;
    `}

  > * + * {
    margin-top: 7px;
  }

  a {
    text-decoration: underline;
  }
`

export default function Alert({
  center,
  children,
  icon,
  iconWidth,
  size,
  type,
}) {
  return (
    <Wrapper center={center} iconWidth={iconWidth} size={size} type={type}>
      {(icon && icon) ||
        (type === 'warning' && <WarningIcon />) ||
        (type === 'success' && <DoIcon />)}
      <Content center={center} size={size}>
        {children}
      </Content>
    </Wrapper>
  )
}

Alert.defaultProps = {
  center: false,
  size: 'small',
  type: 'warning',
}

Alert.propTypes = {
  center: PropTypes.bool,
  children: PropTypes.node.isRequired,
  icon: PropTypes.node,
  iconWidth: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  type: PropTypes.oneOf(['warning', 'success', 'info']),
}
