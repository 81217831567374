import PropTypes from 'prop-types'
import { up } from 'styled-breakpoints'

import styled from 'styled-components'

import { HeaderXL, HeaderL, Body } from '../_abstracts/Type'
import Button from '../_atoms/Button'
import { Media, MediaImage } from '../_base/Media'
import Link from '../shared/Link'

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1400px;
  padding: 13px;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  width: 100vw;
  left: 50%;
  transform: translateX(-50%);
`

const Heading = styled.h2`
  ${HeaderXL};
  padding: 13px;
  width: 100%;
  text-align: center;
  margin-bottom: 15px;
`

const Item = styled.div`
  padding: 13px;
  width: 100%;
  max-width: 540px;

  ${up('medium')} {
    width: 50%;
  }

  ${up('xlarge')} {
    width: 33.33%;
  }
`

const Inner = styled.div`
  position: relative;
  overflow: hidden;

  ${Media} {
    transition: 0.5s;
    position: absolute;
    top: calc(50% - 1px);
    left: calc(50% - 1px);
    transform: translate(-50%, -50%);
    width: calc(100% + 2px);
    height: calc(100% + 2px);
  }

  &:hover ${Media} {
    transform: translate(-50%, -50%) scale(1.1);
  }
`

const Content = styled(Link)`
  position: relative;
  z-index: 1;
  text-align: center;
  display: flex;
  width: 100%;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
  color: ${(props) => props.theme.colors.white};
  transition: 0.5s;

  &:empty {
    background-color: transparent;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.55);
    color: ${(props) => props.theme.colors.black};
  }
`

const ContentInner = styled.div`
  width: 100%;
  padding: 30px 20px;

  ${up('large')} {
    padding: 40px;
  }

  > * + * {
    margin-top: 20px;
  }
`

const Header = styled.h3`
  ${HeaderL};
  line-height: 1.3;
  margin-right: auto;
  margin-left: auto;

  ${up('large')} {
    font-size: 35px;
  }

  &::after {
    width: 35px;
    height: 5px;
    display: block;
    margin-right: auto;
    margin-left: auto;
    margin-top: 10px;
    content: '';
    background-color: currentColor;
  }
`

const Text = styled.div`
  ${Body};
  font-size: 18px;
  font-weight: 500;
`

const Footer = styled.div`
  text-align: center;
  margin-top: 25px;
  width: 100%;

  > * {
    max-width: 100%;
    min-width: 200px;
  }
`

export default function ButtonBlocks({ header, items, link }) {
  return (
    <Wrapper>
      {header && <Heading>{header}</Heading>}
      {items.map((item, index) => (
        <Item key={index}>
          <Inner>
            {item.image && (
              <Media>
                <MediaImage
                  srcSet={`${item.image.small} 450w, ${item.image.medium} 900w`}
                  sizes="(min-width: 1400px) 436px, (min-width: 1200px) calc((100vw - 104px) / 3), (min-width: 768px) calc((100vw - 78px) / 2), (min-width: 566px) 518px, calc(100vw - 52px)"
                  width={item.image.width}
                  height={item.image.height}
                  alt={item.title || ''}
                  loading="lazy"
                />
              </Media>
            )}
            <Content
              to={item.button?.url ? item.button.url : null}
              style={{
                aspectRatio: item.image
                  ? `${item.image.width} / ${item.image.height}`
                  : undefined,
              }}
            >
              {(item.header || item.text || item.button?.text) && (
                <ContentInner>
                  {item.header && <Header>{item.header}</Header>}
                  {item.text && <Text>{item.text}</Text>}
                  {item.button?.text && <Button>{item.button.text}</Button>}
                </ContentInner>
              )}
            </Content>
          </Inner>
        </Item>
      ))}

      {link.url && (
        <Footer>
          <Button to={link.url}>{link.text}</Button>
        </Footer>
      )}
    </Wrapper>
  )
}

ButtonBlocks.propTypes = {
  header: PropTypes.string,
  link: PropTypes.shape({
    text: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.string,
      text: PropTypes.node,
      button: PropTypes.shape({
        text: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      }),
      image: PropTypes.shape({
        small: PropTypes.string.isRequired,
        medium: PropTypes.string.isRequired,
        width: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired,
        title: PropTypes.string,
      }),
    })
  ).isRequired,
}
