import styled from 'styled-components'
// Media
//
// A general-purpose container to display media elements (e.g. images, video, maps) at a fixed
// aspect ratio.
export const Media = styled.figure`
  position: relative;
  overflow: hidden;
`

export const MediaImage = styled.img`
  position: absolute;
  border-radius: inherit;
  object-fit: cover;
  top: -1px;
  right: initial;
  bottom: initial;
  left: -1px;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  max-width: none;
  pointer-events: none;
`
