import { useMemo } from 'react'
import styled from 'styled-components'
import { useQuery } from 'urql'

import Subheader from '../shared/Subheader'
import Tabs from '../shared/Tabs'
import Back from '../shared/Back'
import { HeaderSansXS } from '../_abstracts/Type'

import Toggles from './Toggles'
import Cards from './Cards'

import Container from '../_helpers/Container'
import Loader from '../shared/Loader'

const Wrapper = styled.div`
  padding-top: 25px;
  padding-bottom: 25px;
  background-color: ${(props) => props.theme.colors.xLightGrey};
`

const Text = styled.div`
  ${HeaderSansXS}
  text-align: center;
  border-top: 1px solid ${(props) => props.theme.colors.midGrey};
  border-bottom: 1px solid ${(props) => props.theme.colors.midGrey};

  p {
    max-width: 500px;
    margin-right: auto;
    padding: 26px;
    margin-left: auto;
  }
`

const tabsArgs = {
  tabs: [
    { title: 'My Saved Searches', url: '/my-saved-searches' },
    { title: 'Wishlist', url: '/wishlist', end: true },
    { title: 'Recently Viewed', url: '/recently-viewed', end: true },
  ],
}

const wrappedArgs = {
  header: 'Back to my saved searches',
  url: '/my-saved-searches',
  wrapped: true,
  background: '#EAE3D3',
}

export const SAVED_SEARCHES = `
  query SavedSearches {
    preDefinedSavedSearches: entries(section: "preDefinedSavedSearches") {
      ... on preDefinedSavedSearches_default_Entry {
        title
        searchLink
      }
    }

    listingSavedSearches {
      id
      keywords
      filters {
        key
        label
        value
      }
      emailOnMatch
    }
  }
`

export default function MyFeedCustomizePage() {
  const context = useMemo(
    () => ({ additionalTypenames: ['ListingSavedSearch'] }),
    []
  )
  const [{ fetching, data, error }] = useQuery({
    query: SAVED_SEARCHES,
    context,
  })

  if (fetching) return <Loader background="#fff" />
  if (error) return 'Error loading saved searches'

  const savedSellers = data.listingSavedSearches.filter(
    (s) =>
      !s.keywords && s.filters.length === 1 && s.filters[0].key === 'seller'
  )

  const savedSearches = data.listingSavedSearches.filter(
    (s) => !savedSellers.find((s2) => s2.id === s.id)
  )

  const predefinedToggles = data.preDefinedSavedSearches.map(
    ({ title, searchLink }) => {
      const url = new URL(searchLink)
      const params = new URLSearchParams(url.search)
      const { keywords, ...filters } = Object.fromEntries(params.entries())

      return {
        label: title,
        query: {
          keywords,
          filters: Object.entries(filters).map(([key, value]) => ({
            key,
            value,
          })),
        },
        savedSearchId: data.listingSavedSearches.find((s) => {
          if (s.keywords !== (keywords || null)) return false
          if ((s.filters?.length || 0) !== Object.keys(filters).length)
            return false

          return s.filters.every((f) => filters[f.key] === f.value)
        })?.id,
      }
    }
  )

  return (
    <Wrapper>
      <Container>
        <Tabs {...tabsArgs}>
          <Subheader
            header="My Saved Searches: Customise my saved searches"
            border
          />
          <Back {...wrappedArgs} />
          <Text>
            <p>
              No longer searching for that specific guitar?
              <br />
              Customise your saved searches below
            </p>
          </Text>
          <Cards header="Saved searches" list={savedSearches} showAddForm />
          <Cards header="Saved sellers" list={savedSellers} />
          {predefinedToggles.length > 0 ? (
            <Toggles
              header="Choose the pre-defined searches you’d like to see in your saved searches:"
              list={predefinedToggles}
            />
          ) : null}
        </Tabs>
      </Container>
    </Wrapper>
  )
}

MyFeedCustomizePage.propTypes = {}
