import styled from 'styled-components'
import { useState, useRef } from 'react'
import { useQuery } from 'urql'
import { Formik } from 'formik'
import Input from '../_atoms/Input'
import Form from '../_atoms/Form'

import NextPrevious from '../shared/NextPrevious'
import Subheader from '../shared/Subheader'
import Purchase from '../buying/Purchase'
import Loader from '../shared/Loader'

const Wrapper = styled.div``

const Results = styled.div``

const Message = styled.p`
  padding: 30px;
  text-align: center;
`

const DEFAULT_ITEMS_PER_PAGE = 12

const GET_ORDERS = `
  query GetOrders($first: Int, $last: Int, $after: String, $before: String) {
    orders: ordersCompleted(first: $first, last: $last, after: $after, before: $before) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }

      edges {
        node {
          id
          number
          createdAt
          completedAt
          fullyPaidAt
          canMarkReceived

          lineItems {
            id
            state
            validStateToMarkReceived

            listing {
              id

              item {
                id
                title
                slug
                images {
                  id
                  url(size: "orders")
                }

                account {
                  id
                  username
                  isCarterVintage
                }
              }

              askingPrice {
                amount
                currency { iso }
              }
            }

            amount {
              amount
              currency { iso }
            }
          }

          shippingCost {
            amount
            currency { iso }
          }

          shipment {
            id
            status
            trackingNumber
            carrierName
          }
        }
      }
    }
  }
`

export default function BuyingPurchasesPage() {
  const panelRef = useRef()
  const [perPage, setPerPage] = useState(DEFAULT_ITEMS_PER_PAGE)
  const [pagination, setPagination] = useState({
    first: DEFAULT_ITEMS_PER_PAGE,
  })

  const [{ fetching, data, error }] = useQuery({
    query: GET_ORDERS,
    variables: {
      ...pagination,
    },
  })

  const scrollToTop = () =>
    panelRef.current?.scrollIntoView({ behavior: 'smooth' })

  if (fetching) return <Loader background="#fff" height="250px" />

  if (error) {
    console.error(error)
    return <Message>{error.message}</Message>
  }

  const {
    orders: { pageInfo, edges },
  } = data

  const orders = edges
    ?.map(({ node: { lineItems, ...order } }) =>
      lineItems.map((lineItem) => {
        return {
          lineItem,
          order,
        }
      })
    )
    .flat()

  return (
    <Wrapper ref={panelRef}>
      <Subheader header="Purchases" border>
        <>
          {orders.length ? (
            <Results>
              {orders.length} purchase{orders.length === 1 ? '' : 's'}
            </Results>
          ) : null}

          <Formik initialValues={{}}>
            <Form>
              <Input
                inline={true}
                label="Per page"
                name="pp"
                inputAs="select"
                value={perPage}
                onChange={(event) => {
                  const perPage = parseInt(event.target.value, 10)
                  setPerPage(perPage)
                  setPagination({ first: perPage })
                }}
              >
                <option value="12">12</option>
                <option value="24">24</option>
                <option value="48">48</option>
              </Input>
            </Form>
          </Formik>
        </>
      </Subheader>

      {edges.length ? (
        orders.map((order) => {
          return (
            <Purchase
              key={`${order.id} ${order.lineItem.listing.id}`}
              {...order}
            />
          )
        })
      ) : (
        <Message>No purchase history</Message>
      )}

      <NextPrevious
        includeNext={pageInfo?.hasNextPage || !!pagination.before}
        includePrevious={pageInfo?.hasPreviousPage || !!pagination.after}
        onNext={() => {
          scrollToTop()
          setPagination({
            after: pageInfo.endCursor,
            first: perPage,
          })
        }}
        onPrevious={() => {
          scrollToTop()
          setPagination({
            before: pageInfo.startCursor,
            last: perPage,
          })
        }}
      />
    </Wrapper>
  )
}

BuyingPurchasesPage.propTypes = {}
