import { Helmet } from 'react-helmet-async'
import styled, { css } from 'styled-components'
import { useQuery } from 'urql'
import { LoginReturnNavigate } from '../_atoms/ReturnLink'
import { UserConsumer } from '../auth/UserContext'
import Container from '../_helpers/Container'
import NotFound from '../404/NotFound'
import { SubHeader } from '../shared/Hero'
import Loader from '../shared/Loader'
import InterestsForm from './InterestsForm'

const GET_PAGE = `
  query GetPage {
    entry(section: "interests") {
      id
      title
      ... on interests_interests_Entry {
        heroTitle
        heroBackgroundImage {
          url(handle: "large")
        }
        seoTitle
      }
    }
  }
`

const Wrapper = styled.div`
  flex-grow: 1;
  padding-top: 60px;
  padding-bottom: 100px;
  background-color: ${({ theme }) => theme.colors.black};

  ${({ backgroundImageUrl }) =>
    backgroundImageUrl &&
    css`
      background-image: url('${backgroundImageUrl}');
      background-size: cover;
    `}

  ${SubHeader} {
    margin-bottom: 32px;
    opacity: 1;
    transform: none;
  }
`

export default function DetailsPage() {
  const [{ data, fetching, error }] = useQuery({ query: GET_PAGE })

  return (
    <UserConsumer>
      {({ user }) => {
        if (!user) return <LoginReturnNavigate />

        if (fetching) return <Loader background="#fff" />

        if (error) return <p>{error.message}</p>

        if (!data.entry) {
          return <NotFound />
        }

        const { entry } = data

        return (
          <>
            <Helmet>
              <title>{entry.seoTitle || entry.title}</title>
            </Helmet>
            <Wrapper backgroundImageUrl={entry.heroBackgroundImage[0]?.url}>
              <Container>
                {entry.heroTitle && <SubHeader>{entry.heroTitle}</SubHeader>}
                <InterestsForm />
              </Container>
            </Wrapper>
          </>
        )
      }}
    </UserConsumer>
  )
}
