import PropTypes from 'prop-types'
import { useRef, useEffect } from 'react'

import styled from 'styled-components'

const Wrapper = styled.div`
  width: 100%;

  iframe {
    width: 100% !important;
    height: 100% !important;
    border-radius: 5px;
  }
`

export default function Video({ embed, aspect }) {
  const elementRef = useRef(null)

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  function handleResize() {
    elementRef.current.style.height = `${Math.round(
      elementRef.current.offsetWidth * aspect
    )}px`
  }

  return (
    <Wrapper
      ref={elementRef}
      dangerouslySetInnerHTML={{ __html: embed }}
      style={{ aspectRatio: aspect }}
    />
  )
}

Video.propTypes = {
  embed: PropTypes.string.isRequired,
  aspect: PropTypes.number.isRequired,
}
