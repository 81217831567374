import { FieldArray, Formik } from 'formik'
import PropTypes from 'prop-types'
import { useState } from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { useQuery, useMutation } from 'urql'
import { MoneyPropTypeShape } from './MethodsList'
import { linkStates } from '../_abstracts/Type'
import Button from '../_atoms/Button'
import Input, { InputContainer, InputField, InputLabel } from '../_atoms/Input'
import Select from '../inputs/Select'
import Checkbox from '../inputs/Checkbox'
import Form from '../_atoms/Form'
import { Wrapper as HtmlWrapper } from '../data/Html'
import Field, { Wrapper as FieldWrapper } from '../forms/Field'
import { SimpleFieldset } from '../forms/Fieldset'
import Alert from '../shared/Alert'

const Intro = styled(HtmlWrapper)`
  margin-bottom: 25px;
`

const MatrixRow = styled.div`
  @media (max-width: 499px) {
    width: 100%;

    ${FieldWrapper} {
      width: auto;
    }
  }

  @media (min-width: 500px) {
    display: flex;
    width: 100%;
    align-items: flex-end;

    ${FieldWrapper} {
      &:last-child {
        width: max-content;
      }

      button {
        min-height: 2.71875rem;
      }
    }
  }
`

const Delete = styled.button.attrs({ type: 'button' })`
  ${linkStates};
  color: ${({ theme }) => theme.colors.red};
`

const GET_SHIPPING_CATEGORIES = `
query GetShippingCategories {
  orderShippingCategories
}
`

const PERSIST_SHIPPING_METHOD = `
mutation PersistShippingMethod($method: OrderShippingMethodInput!, $matrix: [OrderShippingMatrixInput!]!) {
  orderCreateShippingMethod(method: $method, matrix: $matrix) {
    success
  }
}
`

function CentsField({ label, value, handleChange, help }) {
  return (
    <Field>
      <InputContainer>
        <InputLabel>{label}</InputLabel>
        <InputField
          value={typeof value === 'number' ? value / 100 : ''}
          onChange={handleChange}
        />
      </InputContainer>
      {!!help && <Alert>{help}</Alert>}
    </Field>
  )
}

CentsField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.number,
  handleChange: PropTypes.func.isRequired,
  help: PropTypes.node,
}

export default function ShippingForm({
  values,
  editable = true,
  showDelete = true,
  onSuccess,
  onFailure,
}) {
  const readonly = editable ? undefined : true
  const [shippingCategories] = useQuery({ query: GET_SHIPPING_CATEGORIES })
  const [save, saveShippingMethod] = useMutation(PERSIST_SHIPPING_METHOD)
  const [enabled, setEnabled] = useState(true)
  const navigate = useNavigate()

  async function onSubmit(formData) {
    const persist = JSON.parse(JSON.stringify(formData))
    // I know this is crazy but if you just submit the form and don't edit the regions
    // it delivers all this stuff inconsistently
    let regions =
      typeof persist.regions === 'object'
        ? persist.regions.join(',')
        : persist.regions
    persist.regions = regions.split(',').filter((r) => !!r)
    const { data } = await saveShippingMethod({
      method: {
        ref: persist.ref,
        name: persist.name,
        ordinal: parseInt(persist.ordinal) || 0,
        country: persist.country,
        regions: persist.regions,
        localPickup: !!persist.localPickup,
        maxPriceCents: persist.maxPrice?.amountCents,
      },
      matrix: persist.matrix.map((m) => ({
        category: m.category,
        strategy: m.strategy,
        priceCents: m.price.amountCents,
        currency: persist.currency,
        enabled,
      })),
    })

    if (data?.orderCreateShippingMethod?.success) {
      toast.success('Shipping method saved')
      onSuccess && onSuccess()
    } else {
      toast.error('Could not update shipping method')
      onFailure && onFailure()
    }
  }

  return (
    <Formik initialValues={values} onSubmit={onSubmit}>
      {({ values, setValues, submitForm }) => {
        function handleCurrencyChange(
          event,
          target,
          attribute,
          zeroIsNull = false
        ) {
          if (!target[attribute]) {
            target[attribute] = { amountCents: 0 }
          }

          target[attribute].amountCents = Math.round(
            parseFloat(event.target.value, 10) * 100
          )

          if (isNaN(target[attribute].amountCents)) {
            target[attribute].amountCents = 0
          }

          if (zeroIsNull && target[attribute].amountCents === 0) {
            target[attribute] = null
          }

          setValues(values)
        }

        function handleRegionChange(event) {
          values.regions = event.target.value.split(',')
          setValues(values)
        }

        async function disableAndSave() {
          setEnabled(false)
          await submitForm()
          navigate('/profile/settings/shipping')
        }

        return (
          <Form justify="center">
            <Intro>
              <p>
                Enter shipping method details for a country. You will need to
                enter at least one method for each country you plan to support.
                You can target specific states/provinces in the USA and Canada.
              </p>
              <p>
                All shipping rates are charged per item, so if a customer orders
                two items from you, shipping will be charged twice.
              </p>
            </Intro>
            <div>
              <SimpleFieldset legend="Shipping method details" hideLegend>
                <Field>
                  <Input label="Sort Order" name="ordinal" />
                  <Alert>
                    Enter a numeric value for where you want this method to
                    appear in the list if more than one is shown to a customer.
                    Smaller numbers appear higher on the list.
                  </Alert>
                </Field>
                <Field>
                  <Input label="Name" name="name" />
                  <Alert>
                    This label will be shown in the Shipping Methods dropdown
                    for buyers, as well as the price.
                  </Alert>
                </Field>
                <Field>
                  <Input label="Country" name="country" readonly={readonly} />
                  <Alert>
                    Enter the ISO{' '}
                    <a
                      href="https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      2-letter country code
                    </a>{' '}
                    for this shipping method.
                  </Alert>
                </Field>
                <Field>
                  <Input
                    label="Regions"
                    name="regions"
                    onChange={(e) => handleRegionChange(e)}
                  />
                  <Alert>
                    For USA and Canada, enter the 2-letter state codes, upper
                    case, as a comma-separated list. For example: TN,KY.
                    Alternatively, leave blank, or enter ALL.
                  </Alert>
                </Field>
                <Field>
                  <Checkbox
                    label="Local pickup"
                    name="localPickup"
                    readOnly={readonly}
                  />
                </Field>
                <Field>
                  <Input label="Currency" name="currency" readOnly={readonly} />
                </Field>
                <CentsField
                  help={
                    <p>
                      The maximum order price that this method applies to. If an
                      order exceeds this amount, this method will not be shown.
                    </p>
                  }
                  label="Max Price"
                  value={values.maxPrice?.amountCents ?? null}
                  handleChange={(event) =>
                    handleCurrencyChange(event, values, 'maxPrice', true)
                  }
                />
                <FieldArray name="matrix">
                  {(arrayHelpers) => (
                    <>
                      {values.matrix.map((item, n) => (
                        <MatrixRow key={`matrix__${n}`}>
                          <Field>
                            <Select
                              label="Category"
                              name={`matrix.${n}.category`}
                              disabled={shippingCategories.fetching}
                              options={
                                shippingCategories.data?.orderShippingCategories
                                  ? shippingCategories.data.orderShippingCategories.map(
                                      (category) => ({
                                        label: category,
                                        value: category,
                                      })
                                    )
                                  : [{ label: 'Loading...', value: '' }]
                              }
                            />
                          </Field>
                          <CentsField
                            label="Price"
                            value={item.price.amountCents}
                            handleChange={(event) =>
                              handleCurrencyChange(
                                event,
                                values.matrix[n],
                                'price',
                                false
                              )
                            }
                          />
                          <Field>
                            <Button
                              variant="small"
                              onClick={() => arrayHelpers.remove(n)}
                            >
                              Remove
                            </Button>
                          </Field>
                        </MatrixRow>
                      ))}

                      <Field>
                        <Button
                          variant="small"
                          onClick={() => {
                            arrayHelpers.push({
                              category: 'GUITAR',
                              strategy: 'CHARGE_PER_ITEM',
                              price: {
                                amountCents: 1,
                                currency: { iso: 'USD' },
                              },
                            })
                          }}
                        >
                          Add matrix row
                        </Button>
                      </Field>
                    </>
                  )}
                </FieldArray>
                <Field justify="reverse">
                  <Button submit variant="primaryDark" disabled={save.fetching}>
                    Save
                  </Button>
                  {showDelete && (
                    <Delete
                      onClick={() => disableAndSave()}
                      disabled={save.fetching}
                    >
                      Delete
                    </Delete>
                  )}
                </Field>
              </SimpleFieldset>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

ShippingForm.propTypes = {
  values: PropTypes.shape({
    ref: PropTypes.string.isRequired,
    regions: PropTypes.arrayOf(PropTypes.string.isRequired),
    name: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
    localPickup: PropTypes.bool,
    maxPrice: PropTypes.shape({
      amountCents: PropTypes.number.isRequired,
    }),
    matrix: PropTypes.arrayOf(
      PropTypes.shape({
        category: PropTypes.string.isRequired,
        strategy: PropTypes.string.isRequired,
        price: MoneyPropTypeShape,
      })
    ),
  }).isRequired,
  editable: PropTypes.bool,
  showDelete: PropTypes.bool,
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
}
