import { Link } from 'react-router-dom'
import { up } from 'styled-breakpoints'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Transition from '../_abstracts/Animation'
import { HeaderS } from '../_abstracts/Type'
import { Icon } from '../_base/Icon'

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
`

const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const IconImage = styled.i`
  position: relative;
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
  background-color: ${({ theme }) => theme.colors.grey};
  border: 1px solid ${({ theme }) => theme.colors.midGrey};
  border-radius: 50%;

  ${up('large')} {
    width: 106px;
    height: 106px;
  }

  ${Icon} {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 65%;
    height: 65%;
    transform: translate(-50%, -50%);
  }
`

const Header = styled.h2`
  ${HeaderS};
`

const LinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const ListingsButton = styled(Link)`
  ${Transition({ property: 'border-color' })};
  display: flex;
  align-items: center;
  position: relative;
  background: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.blueTint};
  border-radius: 3px;
  padding: 10px;
  color: ${(props) => props.theme.colors.xDarkGrey};
  font-weight: 300;
  text-align: left;
  margin-top: 15px;
  cursor: pointer;
  text-transform: capitalize;

  &:after {
    content: '';
    position: absolute;
    right: 5%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 7px 0 7px 14px;
    border-color: transparent transparent transparent
      ${(props) => props.theme.colors.accent};
  }

  &:hover {
    border: 1px solid ${(props) => props.theme.colors.accent};
  }
`

export default function Listings({ icon, items, header }) {
  return (
    <Wrapper>
      <IconWrapper>
        {icon && <IconImage>{icon}</IconImage>}
        <Header>{header}</Header>
      </IconWrapper>
      <LinkWrapper>
        {items.map((item, index) => (
          <ListingsButton key={index} fullWidth to={item.url}>
            {item.buttonText}
          </ListingsButton>
        ))}
      </LinkWrapper>
    </Wrapper>
  )
}

Listings.propTypes = {
  header: PropTypes.string.isRequired,
  icon: PropTypes.element,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      buttonText: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ).isRequired,
}
