import styled from 'styled-components'
import { Formik } from 'formik'
import PropTypes from 'prop-types'
import { useMutation } from 'urql'
import toast from 'react-hot-toast'
import { useSearchParams } from 'react-router-dom'
import { useState } from 'react'
import * as Yup from 'yup'
import Button from '../_atoms/Button'
import Input, { InputContainer } from '../_atoms/Input'
import Form from '../_atoms/Form'
import Checkbox from '../inputs/Checkbox'
import Warning from '../shared/Warning'
import { useCartContext } from '../cart/CartContext'
import { useGlobals } from '../hooks'

const WarningWrapper = styled.div`
  padding-bottom: 20px;
`

const REGISTER_ACCOUNT = `
  mutation RegisterAccount($account: AccountCreateInput!) {
    accountCreate(account: $account) {
      success
      account {
        id
      }
    }
  }
`

const Schema = Yup.object().shape({
  firstName: Yup.string().max(100, 'Max 100 characters').required('Required'),
  lastName: Yup.string().max(100, 'Max 100 characters').required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  username: Yup.string()
    .min(3, 'Min 3 characters')
    .max(20, 'Max 20 characters')
    .matches(/^[a-zA-Z0-9]+$/, 'Can only include letters and numbers')
    .trim()
    .required('Required'),
  password: Yup.string().min(10, 'Min 10 characters').required('Required'),
  password2: Yup.string()
    .min(10, 'Min 10 characters')
    .oneOf([Yup.ref('password')], 'Passwords must match')
    .required('Required'),
  acceptTerms: Yup.boolean().required('Required'),
})

export default function RegisterForm({ reloadUser, returnTo }) {
  const [setCatchError] = useState({ errorInfo: '' })
  const [searchParams] = useSearchParams()
  const [{ data, fetching, error }, createAccount] =
    useMutation(REGISTER_ACCOUNT)
  const { clearOpenOrderIds } = useCartContext()
  const { loginRegister } = useGlobals('loginRegister')

  async function handleSubmit({
    email,
    username,
    password,
    firstName,
    lastName,
  }) {
    const { data } = await createAccount({
      account: {
        email,
        username,
        password,
        firstName,
        lastName,
      },
    })

    if (data?.accountCreate.success) {
      console.log('successfully created account')
      clearOpenOrderIds()
      reloadUser(
        returnTo ||
          searchParams.get('return_to') ||
          loginRegister?.registerRedirectUrl ||
          '/profile'
      )
      toast.success('Account created')
    }
  }

  if (fetching) return <p>Registering...</p>

  if (error) {
    setTimeout(() => {
      if (error.graphQLErrors[0]) {
        setCatchError({ errorInfo: error.graphQLErrors[0].message })
      } else {
        setCatchError({ errorInfo: error.toString() })
      }
    }, 0)
  }

  return (
    <>
      {error && (
        <WarningWrapper>
          <Warning
            color="red"
            text={'Error Registering: You are already have an account.'}
          ></Warning>
        </WarningWrapper>
      )}
      {data?.accountCreate && !data.accountCreate.success && (
        <WarningWrapper>
          <Warning color="red" text="Unable to create account"></Warning>
        </WarningWrapper>
      )}
      <Formik
        initialValues={{
          email: '',
          username: '',
          password: '',
          password2: '',
          firstName: '',
          lastName: '',
          acceptTerms: '',
        }}
        onSubmit={handleSubmit}
        validationSchema={Schema}
      >
        <Form id="register-form">
          <Input
            size="small"
            label="First name"
            type="text"
            name="firstName"
            required
          />
          <Input
            size="small"
            label="Last name"
            type="text"
            name="lastName"
            required
          />
          <Input
            size="small"
            label="Username"
            type="text"
            name="username"
            required
            autocomplete="off"
          />
          <Input
            size="small"
            label="Email"
            type="email"
            name="email"
            required
            autocomplete="username"
          />
          <Input
            label="Password"
            type="password"
            name="password"
            required
            autocomplete="new-password"
          />
          <Input
            label="Confirm password"
            type="password"
            name="password2"
            required
          />
          <InputContainer>
            <Checkbox
              label={
                <>
                  I accept the{' '}
                  <a href="/terms-and-conditions" target="_blank">
                    terms and conditions
                  </a>
                </>
              }
              name="acceptTerms"
              required
            />
          </InputContainer>
          <Button fullWidth submit variant="primaryDark">
            Register
          </Button>
        </Form>
      </Formik>
    </>
  )
}

RegisterForm.propTypes = {
  reloadUser: PropTypes.func.isRequired,
  returnTo: PropTypes.string,
}
