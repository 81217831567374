import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet-async'

export default function Meta({ title, description, image, url }) {
  return (
    <>
      {title && (
        <Helmet>
          <title>{title}</title>
          <meta property="og:title" content={title} />
          <meta name="twitter:title" content={title} />
        </Helmet>
      )}
      {description && (
        <Helmet>
          <meta name="description" content={description} />
          <meta property="og:description" content={description} />
          <meta name="twitter:description" content={description} />
        </Helmet>
      )}
      {image && (
        <Helmet>
          <meta property="og:image" content={image.url} />
          <meta name="twitter:image" content={image.url} />
          <meta name="twitter:card" content="summary_large_image" />
          {image.width && (
            <meta property="og:image:width" content={image.width} />
          )}
          {image.height && (
            <meta property="og:image:height" content={image.height} />
          )}
        </Helmet>
      )}
      {url && (
        <Helmet>
          <link rel="canonical" href={url} />
          <meta property="og:url" content={url} />
          <meta name="twitter:url" content={url} />
        </Helmet>
      )}
    </>
  )
}

Meta.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.shape({
    url: PropTypes.string.isRequired,
    width: PropTypes.number,
    height: PropTypes.number,
  }),
  url: PropTypes.string,
}
