import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { useQuery } from 'urql'
import Back from '../shared/Back'
import Loader from '../shared/Loader'
import Subheader from '../shared/Subheader'
import ShippingForm from './Form'

const Wrapper = styled.div`
  padding: 32px;
`

const GET_SHIPPING_METHOD = `
query GetShippingMethod($ref: String!) {
  orderShippingMethod(ref: $ref) {
    ref
    name
    country
    regions
    currency
    localPickup
    maxPrice {
      amountCents
      currency { iso }
    }
    ordinal

    matrix {
      category
      strategy
      price {
        amountCents
        currency { iso }
      }
    }
  }
}
`

export default function ShippingMethodsEditPage() {
  const { shippingMethodRef } = useParams()
  const [{ data, fetching, error }] = useQuery({
    query: GET_SHIPPING_METHOD,
    variables: { ref: shippingMethodRef },
  })

  if (fetching) return <Loader background="#fff" height="75px" />
  if (error) return <p>{error.message}</p>
  if (!data?.orderShippingMethod) return <p>Method not found</p>

  return (
    <>
      <Helmet>
        <title>Edit Shipping Method</title>
      </Helmet>
      <Subheader border header="Edit Shipping Method" />
      <Back
        header="Back to shipping methods"
        url="/profile/settings/shipping"
        size="small"
        wrapped
      />
      <Wrapper>
        <ShippingForm values={data.orderShippingMethod} editable={false} />
      </Wrapper>
    </>
  )
}
