import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { useMutation } from 'urql'
import toast from 'react-hot-toast'
import { HeaderS, Body } from '../_abstracts/Type'
import { Icon, MailIcon, CrossIcon, SearchIcon } from '../_base/Icon'
import SearchBar, { Form as SearchForm } from '../search/SearchBar'
import SavedSearchLink from './SavedSearchLink'

const Wrapper = styled.div`
  padding: 35px 13px;
  text-align: center;

  & + & {
    border-top: 1px solid ${(props) => props.theme.colors.blueTint};
  }
`

const Header = styled.h2`
  ${HeaderS}
  width: 100%;
  text-align: center;
  color: ${(props) => props.theme.colors.darkBlue};
  margin-bottom: 25px;
`

const List = styled.div`
  text-align: left;
  max-width: 900px;
  position: relative;
  overflow: hidden;
  margin-right: auto;
  margin-left: auto;
  border-radius: ${(props) => props.theme.radii};
  border: 1px solid ${(props) => props.theme.colors.olive};
`

const Item = styled.div`
  & + & {
    border-top: 1px solid ${(props) => props.theme.colors.olive};
  }
`

const Inner = styled.div`
  ${Body}
  position: relative;
  background: ${(props) => props.theme.colors.lighterGrey};
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  padding: 5px;
`

const Text = styled.p`
  padding: 7px;
`

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;

  > * {
    text-decoration: none !important;
  }
`

const Action = styled.button`
  position: relative;
  display: inline-flex;
  align-items: center;
  border: 1px solid ${(props) => props.theme.colors.lightAccent};
  background: ${(props) => props.theme.colors.white};
  margin: 7px;
  padding: 2px 9px;
  border-radius: 2px;
  font-size: 12px;

  ${Icon} {
    display: inline-block;
    width: 13px;
    height: 13px;
    margin-right: 5px;
  }

  &:last-child:not(:first-child) ${Icon} {
    width: 10px;
    height: 10px;
  }

  ${(props) =>
    props.active &&
    css`
      background: ${(props) => props.theme.colors.accentTint};
    `}

  &:hover {
    background: ${(props) => props.theme.colors.accentTint};
  }
`

const Search = styled.div`
  width: 100%;
  margin-top: 25px;
  max-width: 480px;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
  text-align: left;

  ${SearchForm} {
    max-width: 100%;
  }
`

const ADD_SAVED_SEARCH = `
  mutation AddSavedSearch($keywords: String!) {
    listingSearchSave(search:{
      query: { keywords: $keywords }
    }) {
      success
      savedSearch {
        id
        keywords
        filters {
          key
          label
          value
        }
        emailOnMatch
      }
    }
  }
`

const REMOVE_SAVED_SEARCH = `
  mutation RemoveSavedSearch($id: ID!) {
    listingSavedSearchRemove(id: $id) {
      success
    }
  }
`

const SET_EMAIL_ON_MATCH = `
  mutation SetSavedSearchEmailOnMatch($id: ID!, $emailOnMatch: Boolean!) {
    listingSavedSearchSetEmailOnMatch(id: $id, emailOnMatch: $emailOnMatch) {
      success
      savedSearch {
        id
        emailOnMatch
      }
    }
  }
`

const formatSavedSearchLabel = (label) => {
  if (label.includes('[')) {
    try {
      const parsed = JSON.parse(label)
      return parsed.join(', ')
      // eslint-disable-next-line no-empty
    } catch {}
  }

  return label
}

function Card({ item }) {
  const [, remove] = useMutation(REMOVE_SAVED_SEARCH)
  const [, setEmailOnMatch] = useMutation(SET_EMAIL_ON_MATCH)

  async function handleRemove() {
    const toastId = toast('Removing saved search…')

    const { data } = await remove(
      { id: item.id },
      { additionalTypenames: ['ListingSavedSearch'] }
    )

    if (data?.listingSavedSearchRemove.success) {
      toast.success('Removed saved search', { id: toastId })
    } else {
      toast.error('Unable to remove saved search', { id: toastId })
    }
  }

  async function handleSetEmailOnMatch() {
    const toastId = toast('Updating email settings…')

    const { data } = await setEmailOnMatch({
      id: item.id,
      emailOnMatch: !item.emailOnMatch,
    })

    if (data?.listingSavedSearchSetEmailOnMatch.success) {
      toast.success('Updated email settings', { id: toastId })
    } else {
      toast.error('Unable to update email settings', { id: toastId })
    }
  }

  return (
    <Item>
      <Inner>
        <Text>
          {item.keywords}
          {item.keywords && item.filters?.length > 0 && <br />}
          {item.filters?.length > 0 &&
            item.filters.map((filter) => (
              <React.Fragment key={filter.key}>
                <strong>{filter.label}:</strong>{' '}
                {formatSavedSearchLabel(filter.value)}
                <br />
              </React.Fragment>
            ))}
        </Text>

        <Footer>
          <SavedSearchLink search={item}>
            <Action as="span">
              <SearchIcon />
              <p>Go to search</p>
            </Action>
          </SavedSearchLink>
          <Action active={item.emailOnMatch} onClick={handleSetEmailOnMatch}>
            <MailIcon />
            <p>Add to my email</p>
          </Action>
          <Action onClick={handleRemove}>
            <CrossIcon />
            <p>Delete</p>
          </Action>
        </Footer>
      </Inner>
    </Item>
  )
}

export default function Cards({ header, list, showAddForm }) {
  const [, add] = useMutation(ADD_SAVED_SEARCH)

  async function handleAdd({ keywords }, { resetForm }) {
    const toastId = toast('Adding saved search…')

    const { data, error } = await add(
      { keywords },
      { additionalTypenames: ['ListingSavedSearch'] }
    )

    if (data?.listingSearchSave.success) {
      toast.success('Added saved search', { id: toastId })
    } else if (error.message.includes('Saved searches must be unique')) {
      toast.error('Unable to add duplicate saved search', { id: toastId })
    } else {
      toast.error('Unable to add saved search', { id: toastId })
    }

    resetForm()
  }

  return (
    <Wrapper>
      <Header>{header}</Header>
      {list.length > 0 ? (
        <List>
          {list.map((item) => (
            <Card key={item.id} item={item} />
          ))}
        </List>
      ) : (
        <p>No saved searches</p>
      )}

      {showAddForm && (
        <Search>
          <SearchBar
            name="keywords"
            label="Or add a specific search:"
            onSubmit={handleAdd}
          />
        </Search>
      )}
    </Wrapper>
  )
}

Card.propTypes = {
  item: {
    id: PropTypes.string.isRequired,
    keywords: PropTypes.string,
    filters: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      })
    ),
    emailOnMatch: PropTypes.bool.isRequired,
  },
}

Cards.propTypes = {
  header: PropTypes.string.isRequired,
  showAddForm: PropTypes.bool,
  list: PropTypes.arrayOf(PropTypes.shape(Card.propTypes.item)).isRequired,
}
