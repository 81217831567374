import PropTypes from 'prop-types'
import styled from 'styled-components'
import Button from '../_atoms/Button'
import Html from '../data/Html'
import { useGlobals } from '../hooks'
import ButtonGroup from '../shared/ButtonGroup'

const Wrapper = styled.div`
  border: 1px solid ${(props) => props.theme.colors.midGrey};
  border-radius: ${(props) => props.theme.radii};
  overflow: hidden;
`

const Header = styled.h2`
  background-color: ${(props) => props.theme.colors.white};
  padding: 10px 17px;
  text-align: center;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
`

const Content = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  padding: 20px 17px;
  border-top: 1px solid ${(props) => props.theme.colors.midGrey};
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;

  &:first-child {
    border-top: 0;
  }

  > * + * {
    margin-top: 15px;
  }
`

export default function ProductSidebarCta({ title, text, image, buttons }) {
  const hasContent = !!(title || text || image || buttons.length)

  // If no CTA content has been passed in, fetch fallback CTA content from Product global set
  const { product } = useGlobals('product', { pause: hasContent })
  if (!hasContent && product) {
    title = product.productSidebarCtaTitle
    text = product.productSidebarCtaText
    image = product.productSidebarCtaImage[0]
    buttons = product.buttons
  }

  // Return nothing if no CTA content is found
  if (!(title || image || buttons.length)) return null

  return (
    <Wrapper>
      {title && <Header>{title}</Header>}
      {image || buttons.length ? (
        <Content>
          {image &&
            (buttons?.length ? (
              <a href={buttons[0].itemLink.url}>
                <img
                  srcSet={`${image.small} 450w, ${image.medium} 900w`}
                  sizes="@media (min-width: 1298px) 383px, @media (min-width: 1024px) calc(((100vw - 50px) * 0.28) - 34px), calc(100vw - 76px)"
                  width={image.width}
                  height={image.height}
                  alt={image.title || ''}
                />
              </a>
            ) : (
              <img
                srcSet={`${image.small} 450w, ${image.medium} 900w`}
                sizes="@media (min-width: 1298px) 383px, @media (min-width: 1024px) calc(((100vw - 50px) * 0.28) - 34px), calc(100vw - 76px)"
                width={image.width}
                height={image.height}
                alt={image.title || ''}
              />
            ))}
          {text && <Html html={text} />}
          {buttons?.length ? (
            <ButtonGroup fullWidth gap={12}>
              {buttons.map(
                ({ buttonTheme, itemLink: { text, url } }, index) => (
                  <Button key={index} variant={buttonTheme} to={url}>
                    {text}
                  </Button>
                )
              )}
            </ButtonGroup>
          ) : null}
        </Content>
      ) : null}
    </Wrapper>
  )
}

ProductSidebarCta.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  image: PropTypes.shape({
    small: PropTypes.string.isRequired,
    medium: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    title: PropTypes.string,
  }),
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      variant: PropTypes.oneOf([
        'primaryLight',
        'primaryDark',
        'secondary',
        'tertiary',
      ]).isRequired,
      itemLink: PropTypes.shape({
        text: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      }),
    })
  ),
}
