import styled from 'styled-components'
import PropTypes from 'prop-types'
import { BodyS, HeaderSansM } from '../_abstracts/Type'
import Avatar from '../shared/Avatar'

const Wrapper = styled.div`
  border: 1px solid ${(props) => props.theme.colors.midGrey};
  padding: 15px;
  border-radius: ${(props) => props.theme.radii};
  display: flex;
  align-items: center;
`

const Content = styled.div`
  ${BodyS}
  padding-left: 15px;
  flex-grow: 1;
`

const Header = styled.div`
  ${HeaderSansM}
  font-weight: 600;
`

export default function MiniProfile({ greeting, user }) {
  return (
    <Wrapper>
      <Avatar size="medium" user={user} />

      <Content>
        {greeting}
        <Header>{user.username}</Header>
      </Content>
    </Wrapper>
  )
}

MiniProfile.propTypes = {
  greeting: PropTypes.string,
  user: PropTypes.shape({
    username: PropTypes.string.isRequired,
  }).isRequired,
}
