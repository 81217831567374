import { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import Flickity from 'react-flickity-component'
import './flickity.css'

import { HeaderL } from '../_abstracts/Type'
import { down } from 'styled-breakpoints'
import ListingCard from '../shared/ListingCard'
import { Icon, ArrowNextIcon, ArrowPrevIcon } from '../_base/Icon'
import { ContainerStyles } from '../_helpers/Container'
import Button, { ButtonStyle } from '../_atoms/Button'
import { colors } from '../theme'

export const themeColor = {
  light: colors.white,
  dark: colors.darkBlue,
  grey: colors.lightGrey,
  lightGrey: colors.xLightGrey,
}

export const Wrapper = styled.div`
  padding-top: 50px;
  padding-bottom: 50px;

  ${down('small')} {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  ${(props) =>
    props.layout == 'center'
      ? css`
          ${Inner} {
            max-width: 1100px;
            margin-left: auto;
            margin-right: auto;
          }
          ${Header} {
            width: fit-content;
            margin-right: auto;
            margin-left: auto;
            background-color: ${themeColor[props.color]};
          }

          ${Slider} {
            padding-left: 30px;
            padding-right: 30px;

            .flickity-prev-next-button.previous {
              left: -50px;
            }
            .flickity-prev-next-button.next {
              right: -50px;
            }

            ${down('medium')} {
              padding-left: 25px;
              padding-right: 25px;

              .flickity-prev-next-button.previous {
                left: -25px;
              }
              .flickity-prev-next-button.next {
                right: -25px;
              }
            }
          }

          ${Arrows} {
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            transform: translateY(-50%);
            display: flex;
            justify-content: space-between;
            align-content: center;
            background-color: ${themeColor[props.color]};
          }

          ${Slide} {
            width: 20%;
            padding-right: 30px;
            padding-left: 30px;
            min-height: 100%;

            ${down('large')} {
              width: 33.33%;
            }

            ${down('medium')} {
              width: 50%;
            }

            ${down('small')} {
              width: 100%;
            }
          }
        `
      : css`
          ${Header} {
            display: flex;
            justify-content: space-between;
            align-items: center;
            text-align: left;

            ${down('small')} {
              flex-wrap: wrap;
              justify-content: center;
            }
          }

          ${Arrows} {
            display: inline-block;
            text-align: right;
          }

          ${Slider} {
            margin-left: -10px;
          }

          ${Slide} {
            width: calc((100% + 10px) / 6);
            padding-right: 10px;
            padding-left: 10px;
            min-height: 100%;

            > * {
              min-width: unset;
            }

            @media (max-width: 1200px) {
              width: calc((100% + 10px) / 4);
            }

            @media (max-width: 950px) {
              width: calc((100% + 10px) / 3);
            }

            @media (max-width: 700px) {
              width: calc((100% + 10px) / 2);
            }

            @media (max-width: 425px) {
              width: calc((100% + 10px) / 1);
            }
          }
        `}

  @media print {
    display: none;
  }
`

const Inner = styled.div`
  ${ContainerStyles}
  position: relative;
  max-width: 1450px;

  ${(props) =>
    props.homepage &&
    css`
      border: 1px solid ${props.theme.colors.blueTint};
      padding-top: 30px;
      padding-bottom: 30px;

      ${Header} {
        padding-left: 30px;
        padding-right: 30px;
        position: absolute;
        left: 50%;
        top: 0;
        transform: translate(-50%, -50%);
      }

      ${Arrows} {
        left: -10px;
        right: -10px;
        width: unset;
        padding-top: 30px;
        padding-bottom: 30px;
      }

      ${Slider} {
        padding-bottom: 40px;
      }
    `};
`

const Header = styled.div`
  span {
    ${HeaderL}
  }

  ${down('small')} {
    padding-bottom: 10px;
    width: 100%;
    text-align: center;
  }
`

const Slider = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-top: 40px;

  ${down('small')} {
    padding-top: 20px;
  }

  .flickity-enabled {
    padding-bottom: 23px;
  }

  .flickity-page-dots {
    bottom: -2px;
    padding-left: 10px;

    .dot {
      background: currentColor;
      transition: opacity 150ms ease;
    }
  }
`

const SliderInner = styled.div`
  position: relative;
  z-index: 1;

  ${down('small')} {
    padding-left: 20px;
    padding-right: 20px;
  }

  ${(props) =>
    props.layout == 'center' &&
    css`
      ${ContainerStyles}
      max-width: 1450px;
    `}
`

const Slide = styled.div`
  display: flex;
  justify-content: center;
`

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  ${down('small')} {
    width: 100%;
    justify-content: center;
    margin-top: 15px;
  }

  ${ButtonStyle} {
    padding-top: 5px;
    padding-bottom: 5px;
    margin-right: 20px;
  }
`

const Arrows = styled.div``

const Arrow = styled.button`
  position: relative;
  z-index: 2;
  background-color: transparent;
  border: 0;
  color: currentColor;
  cursor: pointer;
  transition: 0.15s;

  &:hover {
    transform: scale(1.1);
  }

  &[disabled] {
    pointer-events: none;
    opacity: 0.3;
  }

  ${Icon} {
    width: 39px;
  }
`

const ButtonWrapper = styled.div`
  padding-left: 30px;
  padding-right: 30px;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 53%);
  background-color: ${(props) => themeColor[props.color]};
`

export default function Carousel({
  header,
  options,
  listings,
  view,
  viewText,
  theme,
  layout,
  homepage,
}) {
  const flick = useRef()
  const prevButton = useRef()
  const nextButton = useRef()
  const [prevDisabled, setPrevDisabled] = useState(true)
  const [nextDisabled, setNextDisabled] = useState(true)

  function next() {
    flick.current.flkty.next()
  }

  function previous() {
    flick.current.flkty.previous()
  }

  const availableListings = listings.filter(
    (listing) => listing.isAvailable || listing.listing?.isAvailable
  )

  useEffect(() => {
    if (flick.current?.flkty) {
      const instance = flick.current.flkty

      // This function is based on Flickity's `PrevNextButton#update`, which Flickity uses internally
      // to check if its built-in next/previous buttons (which aren't being used) should be enabled/disabled
      let onSelect = function () {
        if (this.options.wrapAround && this.slides.length > 1) {
          setPrevDisabled(false)
          setNextDisabled(false)
          return
        }

        const lastIndex = this.slides.length ? this.slides.length - 1 : 0
        setPrevDisabled(this.selectedIndex == 0)
        setNextDisabled(this.selectedIndex == lastIndex)
      }

      onSelect = onSelect.bind(instance)
      instance.on('select', onSelect)

      return () => {
        instance?.off('select', onSelect)
      }
    }
  }, [flick.current?.flkty])

  return (
    <Wrapper color={theme} layout={layout}>
      <Inner homepage={homepage}>
        <Header>
          <span>{header}</span>

          {layout == 'left' && (
            <Actions>
              {view && (
                <Button to={view} variant="secondary">
                  {viewText}
                </Button>
              )}

              {availableListings.length > 1 && (
                <Arrows>
                  <Arrow
                    ref={prevButton}
                    onClick={previous}
                    disabled={prevDisabled}
                    aria-label="Previous slides"
                  >
                    <ArrowPrevIcon />
                  </Arrow>
                  <Arrow
                    ref={nextButton}
                    onClick={next}
                    disabled={nextDisabled}
                    aria-label="Next slides"
                  >
                    <ArrowNextIcon />
                  </Arrow>
                </Arrows>
              )}
            </Actions>
          )}
        </Header>

        <Slider>
          {layout == 'center' && availableListings.length > 1 && (
            <Arrows>
              <Arrow onClick={previous}>
                <ArrowPrevIcon />
              </Arrow>
              <Arrow onClick={next}>
                <ArrowNextIcon />
              </Arrow>
            </Arrows>
          )}
          <SliderInner layout={layout}>
            <Flickity
              options={options}
              ref={flick}
              disableImagesLoaded={true}
              reloadOnUpdate
            >
              {availableListings.map((listing) => {
                return (
                  // This is a bit awkard, but should work when items with a `listing` property are passed to the carousel,
                  // instead of listings with an `item` property:
                  <Slide key={listing.id || listing.item.id}>
                    <ListingCard
                      item={listing.item || listing}
                      {...(listing.listing || listing)}
                    />
                  </Slide>
                )
              })}
            </Flickity>
          </SliderInner>
        </Slider>
        {layout == 'center' && view && (
          <ButtonWrapper color={theme}>
            <Button variant="primaryDark" to={view}>
              {viewText}
            </Button>
          </ButtonWrapper>
        )}
      </Inner>
    </Wrapper>
  )
}

Carousel.defaultProps = {
  layout: 'left',
  options: {
    cellAlign: 'left',
    prevNextButtons: false,
    groupCells: true,
    wrapAround: true,
  },
  theme: 'light',
  viewText: 'View All',
}

Carousel.propTypes = {
  theme: PropTypes.oneOf(['light', 'dark', 'grey', 'lightGrey']).isRequired,
  layout: PropTypes.oneOf(['center', 'left']).isRequired,
  view: PropTypes.string,
  viewText: PropTypes.string,
  header: PropTypes.string.isRequired,
  options: PropTypes.object,
  listings: PropTypes.array.isRequired,
  homepage: PropTypes.bool,
}
