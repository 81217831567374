import structuredClone from '@ungap/structured-clone'
import formatDate from 'date-fns/format'
import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'
import toast from 'react-hot-toast'
import styled from 'styled-components'
import { useMutation, useQuery } from 'urql'
import * as Yup from 'yup'
import Button from '../_atoms/Button'
import Input from '../_atoms/Input'
import Field from '../forms/Field'
import { Fields } from '../forms/Fieldset'
import Form from '../_atoms/Form'
import Select from '../inputs/Select'
import Upload, { uploadFiles } from '../inputs/Upload'
import Alert from '../shared/Alert'
import { Formik } from 'formik'
import Loader from './Loader'
import Html from '../data/Html'

const GET_FORM = `
query GetForm($handle: [String]) {
  form(handle: $handle) {
    id
    pages {
      id

      settings {
        pageConditions
      }

      rows {
        id
        fields {
          id
          name
          handle
          required
          placeholder
          typeName
          instructions

          ... on Field_Dropdown {
            options {
              label
              value
            }
          }
        }
      }
      settings {
        submitButtonLabel
      }
    }
  }
}
`

const Page = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`

const CREATE_SUBMISSION = (mutationName, fields) => {
  const params = Object.entries(fields)
    .map(([name, type]) => `$${name}: ${type}`)
    .join(', ')

  const variables = Object.keys(fields)
    .map((name) => `${name}: $${name}`)
    .join(', ')

  return `
    mutation SaveSubmission(${params}) {
      ${mutationName}(${variables}) {
        id
        ${Object.keys(fields).join('\r\n')}
      }
    }
  `
}

const fieldInputProps = ({ typeName }) => {
  const props = {}

  switch (typeName) {
    case 'Field_Email':
      props.type = 'email'
      break

    case 'Field_Phone':
      props.type = 'tel'
      break

    case 'Field_Date':
      props.type = 'date'

      // Assuming forms will only need future dates to be selected - otherwise, remove this and use
      // the Formie field's `minDate` setting instead
      props.min = formatDate(new Date(), 'yyyy-MM-dd')
      break

    case 'Field_MultiLineText':
      props.inputAs = 'textarea'
      break

    default:
      props.type = 'text'
      break
  }

  return props
}

const Formie = ({
  autofocusFirstField,
  disabled,
  formId,
  formie,
  handle,
  includeSubmit,
  initialValues = {},
  onSubmit,
  successMessage,
}) => {
  const uploadRef = useRef()
  const fields = formie.pages
    .map((page) => page.rows.map((row) => row.fields))
    .flat(3)

  const variables = {}
  const schema = {}

  for (const field of fields) {
    if (field.typeName === 'Field_Date') {
      variables[field.handle] = 'DateTime'
    } else {
      variables[field.handle] = 'String'
    }

    schema[field.handle] = Yup.string()

    if (!initialValues[field.handle]) {
      initialValues[field.handle] = ''
    }

    if (field.typeName === 'Field_Email') {
      schema[field.handle] = schema[field.handle].email('Invalid email')
    }

    if (field.required) {
      variables[field.handle] += '!'
      schema[field.handle] = schema[field.handle].required('Required')
    }
  }

  // See https://verbb.io/craft-plugins/formie/docs/developers/graphql#saving-a-submission
  const mutationName = `save_${handle}_Submission`
  const mutation = CREATE_SUBMISSION(mutationName, variables)
  const Schema = Yup.object().shape(schema)
  const [{ data, fetching, error }, createSubmission] = useMutation(mutation)

  async function handleSubmit(fields) {
    if (disabled) return

    const parsedFields = Schema.cast(structuredClone(fields), {
      strict: true,
    })

    if (uploadRef?.current) {
      const images = await uploadFiles({
        ref: uploadRef,
        returnImages: true,
      })

      // List of newline-separated image URLs
      parsedFields[uploadRef.current.name] = images
        ?.map((image) => image.url)
        .join('\r\n')
    }

    try {
      const { data } = await createSubmission(parsedFields)

      if (data?.[mutationName]) {
        console.log('Submitted successfully')
        onSubmit?.(fields, data)
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (fetching) {
      toast('Submitting…', { id: 'form' })
    } else if (data?.[mutationName]) {
      toast.success('Submitted successfully', { id: 'form' })
    } else if (error) {
      toast.error('Submission error', { id: 'form' })
    }
  }, [data, fetching, error, mutationName])

  const comparisonFunctions = {
    '=': (inputValue, compareValue) => inputValue == compareValue,
    '!=': (inputValue, compareValue) => inputValue != compareValue,
    '>': (inputValue, compareValue) => inputValue > compareValue,
    '<': (inputValue, compareValue) => inputValue < compareValue,
    contains: (inputValue, compareValue) => inputValue.includes(compareValue),
    startsWith: (inputValue, compareValue) =>
      !!inputValue.match(new RegExp(`^${compareValue}`)),
    endsWith: (inputValue, compareValue) =>
      !!inputValue.match(new RegExp(`${compareValue}$`)),
  }

  function shouldDisplayPage(page, initialValues) {
    const conditionsJson = page?.settings?.pageConditions
    if (!conditionsJson) {
      return true
    }

    const conditions = JSON.parse(conditionsJson)
    // can be show or hide. If the conditions are met we return returnValue - if they're not, we return !returnValue
    const returnValue = conditions.showRule === 'show'
    if (!conditions?.conditions) {
      // there are no conditions so we display
      return true
    }

    const metConditions = conditions.conditions.filter((condition) => {
      const fieldReference = condition.field.replace(
        new RegExp(/[^a-zA-Z0-9]+/, 'g'),
        ''
      )
      const initialFieldValue = initialValues[fieldReference]

      const compare = comparisonFunctions[condition.condition]
      if (!compare) return false

      return compare(initialFieldValue, condition.value)
    })

    return metConditions.length === conditions.conditions.length
      ? returnValue
      : !returnValue
  }

  return (
    <>
      {data?.[mutationName] && successMessage ? (
        <Alert size="large" type="success">
          <Html html={successMessage} />
        </Alert>
      ) : (
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={Schema}
        >
          <Form id={formId}>
            <Fields>
              {formie.pages.map((page, index) => (
                <Page
                  key={index}
                  style={{
                    display: shouldDisplayPage(page, initialValues)
                      ? undefined
                      : 'none',
                  }}
                >
                  {page.rows.map((row, rowIndex) =>
                    row.fields.map((field, fieldIndex) => (
                      <React.Fragment key={field.handle}>
                        {field.typeName === 'Field_Hidden' ? (
                          <input name={field.handle} type="hidden" />
                        ) : (
                          <Field
                            width={row.fields.length == 2 ? 'half' : 'full'}
                          >
                            {/* TODO: Use a better attribute to detect upload fields. Upload fields should be added as textareas
                            in Formie, as their value is set to a list of uploaded file URLs rather than native File objects */}
                            {(field.handle == 'photos' && (
                              <Upload
                                ref={uploadRef}
                                label={field.name}
                                name={field.handle}
                                required={field.required}
                              />
                            )) ||
                              (field.typeName == 'Field_Dropdown' && (
                                <Select
                                  autofocus={
                                    autofocusFirstField &&
                                    rowIndex === 0 &&
                                    fieldIndex === 0
                                  }
                                  label={field.name}
                                  name={field.handle}
                                  options={field.options}
                                  placeholder={field.placeholder}
                                  required={field.required}
                                  description={field.instructions}
                                />
                              )) || (
                                <Input
                                  autofocus={
                                    autofocusFirstField &&
                                    rowIndex === 0 &&
                                    fieldIndex === 0
                                  }
                                  label={field.name}
                                  name={field.handle}
                                  placeholder={field.placeholder}
                                  required={field.required}
                                  description={field.instructions}
                                  {...fieldInputProps(field)}
                                />
                              )}
                          </Field>
                        )}
                      </React.Fragment>
                    ))
                  )}
                  {includeSubmit && (
                    <Field justify="end">
                      <Button
                        disabled={disabled || fetching}
                        submit
                        variant="primaryDark"
                      >
                        {page.settings.submitButtonLabel}
                      </Button>
                    </Field>
                  )}
                </Page>
              ))}
            </Fields>
          </Form>
        </Formik>
      )}
    </>
  )
}

Formie.defaultProps = {
  includeSubmit: true,
}

Formie.propTypes = {
  autofocusFirstField: PropTypes.bool,
  disabled: PropTypes.bool,
  formId: PropTypes.string,
  formie: PropTypes.shape({
    pages: PropTypes.arrayOf(
      PropTypes.shape({
        rows: PropTypes.arrayOf(
          PropTypes.shape({
            fields: PropTypes.arrayOf(
              PropTypes.shape({
                handle: PropTypes.string.isRequired,
                name: PropTypes.string.isRequired,
                placeholder: PropTypes.string,
                required: PropTypes.bool,
                typeName: PropTypes.string.isRequired,
              })
            ).isRequired,
          })
        ).isRequired,
        settings: PropTypes.shape({
          submitButtonLabel: PropTypes.string.isRequired,
        }).isRequired,
      })
    ).isRequired,
  }).isRequired,
  handle: PropTypes.string.isRequired,
  includeSubmit: PropTypes.bool,
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  successMessage: PropTypes.node,
}

export default function CraftForm({
  autofocusFirstField,
  disabled,
  formId,
  handle,
  includeSubmit,
  initialValues,
  loaderHeight,
  onSubmit,
  successMessage,
}) {
  // See https://verbb.io/craft-plugins/formie/docs/developers/graphql#query-payload
  const [result] = useQuery({
    query: GET_FORM,
    variables: { handle },
  })

  const { data, fetching, error } = result

  if (fetching) return <Loader height={loaderHeight} background="#fff" />

  if (error) return <p>{error.message}</p>

  const formie = data?.form

  if (!formie) return <p>Form not found</p>

  return (
    <Formie
      autofocusFirstField={autofocusFirstField}
      disabled={disabled}
      formId={formId}
      formie={formie}
      handle={handle}
      includeSubmit={includeSubmit}
      initialValues={initialValues}
      onSubmit={onSubmit}
      successMessage={successMessage}
    />
  )
}

CraftForm.defaultProps = {
  includeSubmit: true,
  loaderHeight: '200px',
}

CraftForm.propTypes = {
  autofocusFirstField: PropTypes.bool,
  disabled: PropTypes.bool,
  formId: PropTypes.string,
  handle: PropTypes.string.isRequired,
  includeSubmit: PropTypes.bool,
  initialValues: PropTypes.object,
  loaderHeight: PropTypes.string,
  onSubmit: PropTypes.func,
  successMessage: PropTypes.node,
}
