import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { Body } from '../_abstracts/Type'
import { Icon, ArrowIcon } from '../_base/Icon'
import { colors } from '../theme'

const Wrapper = styled.div`
  ${({ wrapped, size }) =>
    (wrapped &&
      css`
        padding: ${size === 'small' ? '24px' : '32px'} 20px;
        text-align: center;
      `) ||
    css`
      display: inline-block;
    `}

  ${({ background }) =>
    background &&
    css`
      background: ${background};
    `}

  &:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
  }

  &:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit;
  }

  a,
  button {
    ${Body};
    display: inline-flex;
    align-items: center;
    cursor: pointer;

    ${Icon} {
      display: inline-block;
      width: ${({ size }) => (size === 'small' ? '16px' : '16px')};
      min-width: ${({ size }) => (size === 'small' ? '16px' : '16px')};
      margin-right: 10px;
    }

    &:hover span {
      border-bottom-color: transparent;
    }
  }

  @media print {
    display: none;
  }
`

const Text = styled.span`
  display: inline-block;
  font-size: ${({ size }) => (size === 'small' ? '14px' : '16px')};
  border-bottom: 1px solid;
  transition: 0.15s;
`

export default function Back({
  background,
  className,
  header,
  url,
  onClick,
  size,
  wrapped,
}) {
  const content = (
    <>
      <ArrowIcon size={size} />
      <Text size={size}>{header}</Text>
    </>
  )

  return (
    <Wrapper
      background={background}
      className={className}
      size={size}
      wrapped={wrapped}
    >
      {onClick ? (
        <button onClick={onClick} type="button">
          {content}
        </button>
      ) : (
        <Link to={url}>{content}</Link>
      )}
    </Wrapper>
  )
}

Back.defaultProps = {
  background: colors.blueTint,
  header: 'Back',
  size: 'large',
}

Back.propTypes = {
  background: PropTypes.string,
  className: PropTypes.string,
  header: PropTypes.string.isRequired,
  url: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['large', 'small']),
  wrapped: PropTypes.bool,
}
