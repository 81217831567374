import PropTypes from 'prop-types'
import { useState } from 'react'

import A11yElement from '../_base/Accessibility'
import { BodyS, linkStates } from '../_abstracts/Type'
import Transition from '../_abstracts/Animation'

import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  ${({ wrapper }) =>
    wrapper &&
    css`
      border: 1px solid ${({ theme }) => theme.colors.midGrey};
      border-radius: ${({ theme }) => theme.radii};
    `};
`

const Inner = styled.label`
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;

  ${({ wrapper }) =>
    wrapper &&
    css`
      padding: 10px 16px;
      background-color: ${({ theme }) => theme.colors.lightGrey};
      border-radius: inherit;

      &:not(:last-child) {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }
    `};
`

const CheckboxContainer = styled.div`
  min-height: 25px;
  font-size: 0;
`

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  ${A11yElement};
`

const CheckboxLabel = styled.span`
  ${BodyS};
  font-weight: inherit;
  display: block;
  font-weight: ${({ boldLabel }) => (boldLabel ? 700 : 400)};
  text-transform: capitalize;
  color: ${(props) =>
    props.blueLabel
      ? props.theme.colors.darkBlue
      : props.theme.colors.xDarkGrey};

  ${CheckboxContainer} + & {
    margin-left: 8px;
  }
  ${(props) =>
    props.resultCount != null
      ? css`
          display: flex;
          justify-content: space-between;
          width: 100%;
        `
      : null};

  a {
    ${linkStates}
  }
`

export const StyledCheckbox = styled.div`
  position: relative;
  display: inline-block;

  ${({ toggle }) =>
    (toggle &&
      css`
        width: 49px;
        height: 27px;
        border-radius: 20px;
        background-color: ${(props) =>
          props.checked
            ? props.theme.colors.accent
            : props.theme.colors.midGrey};

        &::after {
          content: '';
          width: 21px;
          height: 21px;
          display: inline-block;
          position: absolute;
          top: 50%;
          margin-top: -11px;
          left: 3px;
          background-color: #fff;
          border-radius: 50%;
          transition: 0.15s;
          transform: ${(props) =>
            props.checked ? 'translateX(100%)' : 'translateX(0%)'};
        }
      `) ||
    css`
      width: 24px;
      height: 24px;
      background: ${(props) =>
        props.checked ? props.theme.colors.darkBlue : props.theme.colors.white};
      border: 1px solid
        ${(props) =>
          props.checked
            ? props.theme.colors.darkBlue
            : props.theme.colors.darkGrey};
      border-radius: ${(props) => props.theme.radii};
      ${Transition({ property: 'background-color, border' })};

      &::before {
        position: absolute;
        left: 0;
        top: 44%;
        height: 30%;
        width: 1px;
        background-color: ${(props) =>
          props.checked ? props.theme.colors.white : 'transparent'};
        content: '';
        transform: translateX(9px) rotate(-45deg);
        transform-origin: left bottom;
      }

      &::after {
        position: absolute;
        right: 0;
        bottom: 24%;
        height: 1px;
        width: 60%;
        background-color: ${(props) =>
          props.checked ? props.theme.colors.white : 'transparent'};
        content: '';
        transform: translateX(0) rotate(-45deg);
        transform-origin: left bottom;
      }
    `}
`

const Content = styled.div`
  font-size: 15px;
  color: ${({ theme }) => theme.colors.darkBlue};

  ${({ wrapper }) =>
    wrapper &&
    css`
      padding: 16px;
      border-top: 1px solid ${({ theme }) => theme.colors.midGrey};
      border-radius: inherit;
      border-bottom-right-radius: inherit;
      border-bottom-left-radius: inherit;
    `};

  > * + * {
    margin-top: 8px;
  }
`

export default function Checkbox({
  checked,
  disabled = false,
  children,
  controlled,
  label,
  blueLabel,
  boldLabel,
  hideLabel,
  onChange,
  resultCount,
  required,
  wrapper,
  name,
  value,
  toggle,
}) {
  const [uncontrolledChecked, setUncontrolledChecked] = useState(checked)

  return (
    <Wrapper wrapper={wrapper} as={wrapper ? 'div' : ''}>
      <Inner aria-label={hideLabel && label} wrapper={wrapper}>
        <CheckboxContainer>
          {controlled}

          <HiddenCheckbox
            checked={controlled ? checked : uncontrolledChecked}
            disabled={disabled}
            value={value}
            name={name}
            onChange={(event) => {
              if (!controlled) {
                setUncontrolledChecked(event.target.checked)
              }

              onChange?.(event.target.checked, event)
            }}
            required={required}
          />
          <StyledCheckbox
            className="checkboxTrigger"
            checked={controlled ? checked : uncontrolledChecked}
            toggle={toggle}
          />
        </CheckboxContainer>

        {!hideLabel && (
          <CheckboxLabel
            blueLabel={blueLabel}
            boldLabel={boldLabel}
            resultCount={resultCount > 0}
          >
            <p>
              {label}
              {required && '*'}
            </p>
            {resultCount != null && <p>({resultCount})</p>}
          </CheckboxLabel>
        )}
      </Inner>
      {children && <Content wrapper={wrapper}>{children}</Content>}
    </Wrapper>
  )
}

Checkbox.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  controlled: PropTypes.bool,
  label: PropTypes.node.isRequired,
  blueLabel: PropTypes.bool,
  boldLabel: PropTypes.bool,
  hideLabel: PropTypes.bool,
  onChange: PropTypes.func,
  resultCount: PropTypes.number,
  required: PropTypes.bool,
  wrapper: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  toggle: PropTypes.bool,
}
