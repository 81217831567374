import { Helmet } from 'react-helmet-async'
import styled from 'styled-components'
import { useQuery } from 'urql'
import Subheader from '../../shared/Subheader'
import Split from '../Split'
import Button, { ButtonStyle } from '../../_atoms/Button'
import Loader from '../../shared/Loader'
import ManageCards from '../../payment/ManageCards'

const Footer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  > *:first-child:last-child {
    margin-left: auto;
  }

  ${ButtonStyle} {
    min-width: 150px;
  }
`

const ONBOARDING_STATUS = `
  query OnboardingStatus {
    stripe: paymentOnboardStatusStripe {
      completed
    }
  }
`

export default function DetailsPage() {
  const [{ fetching, data, error }] = useQuery({
    query: ONBOARDING_STATUS,
  })

  return (
    <>
      <Helmet>
        <title>Profile | Settings | Payment methods</title>
      </Helmet>
      <div>
        <Subheader border header="Payment methods" />

        <Split header="Saved cards for purchases">
          <ManageCards />
        </Split>

        {fetching ? (
          <Loader background="#fff" />
        ) : error ? (
          <p>{error.message}</p>
        ) : (
          <>
            <Split header="Payout details">
              {data.stripe.completed ? (
                <p>Your Stripe account is linked.</p>
              ) : (
                <>
                  <p>You haven&apos;t yet linked a Stripe account.</p>
                  <Footer>
                    <Button variant="primaryDark" to="/onboarding/stripe">
                      Link your Stripe account
                    </Button>
                  </Footer>
                </>
              )}
            </Split>

            {/* We don't use the marketplace features for now
            <Split header="PayPal">
              {data.paypal.completed ? (
                <p>Your PayPal account is linked.</p>
              ) : (
                <>
                  <p>You haven't yet linked a PayPal account.</p>
                  <Footer>
                    <Button variant="secondary" to="/onboarding/paypal">
                      Link your Paypal account
                    </Button>
                  </Footer>
                </>
              )}
            </Split>
            */}
          </>
        )}
      </div>
    </>
  )
}
