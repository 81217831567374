import { Helmet } from 'react-helmet-async'
import { useLocation } from 'react-router'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Header from '../shared/Header'
import HeaderMobile from '../shared/HeaderMobile'
import Footer from '../shared/Footer'
import { useGlobals } from '../hooks'
import Meta from '../_helpers/Meta'

const Main = styled.main`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`

export default function MainLayout({ children }) {
  const location = useLocation()
  const { seo } = useGlobals('seo')
  const noCanonicalPaths = ['/shop', '/profile']

  return (
    <>
      <Helmet
        defaultTitle={seo?.seoDefaultTitle}
        titleTemplate={seo?.seoTitlePattern}
      >
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Carter Vintage Exchange" />
        <meta property="og:locale" content="en-US" />
        <meta name="twitter:site" content="@cartervintage"></meta>
      </Helmet>
      <Meta
        title={seo?.seoDefaultTitle}
        description={seo?.seoDefaultDescription}
        image={seo?.image[0]}
        url={
          noCanonicalPaths.some((path) => location.pathname.startsWith(path))
            ? undefined
            : `${process.env.RAZZLE_BASE_FRONTEND_URL}${location.pathname}`
        }
      />
      <Header />
      <HeaderMobile />
      <Main>{children}</Main>
      <Footer />
    </>
  )
}

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
}
