import React, { useRef, useState, useEffect } from 'react'
import styled from 'styled-components'
import { useMutation, useQuery } from 'urql'
import { Formik, Form as FormikForm } from 'formik'
import { Navigate, useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'
import Button from '../_atoms/Button'
import { scrollToElement } from '../_abstracts/Layout'
import AddressList from '../shared/AddressList'
import Loader from '../shared/Loader'
import FormWrapper from './FormWrapper'
import Table from './Table'
import AccountForm from './AccountForm'
import { Footer } from './CheckoutLayout'
import { colors } from '../theme'
import { useCartContext } from '../cart/CartContext'
import { useUserContext } from '../auth/UserContext'
import { adminDetailsComplete } from './AdminControls'

const Inner = styled.div`
  padding: 0 25px;
`

const SHIPPING_QUERY = `
  query CheckoutShippingPage($orderId: [ID!]) {
    cart(orderId: $orderId) {
      orders {
        id
        taxExempt
        taxExemptReason
        taxExemptNo
        requiresShipping
        completedAt
        salesperson
        salesType
        shipDate
        localPickupOnly
        contact {
          id
          firstName
          lastName
          email
          phone
          taxExemptNo
        }

        subTotal {
          amount
          currency { iso }
        }

        tax {
          amount
          currency { iso }
        }

        shippingCost {
          amount
          currency { iso }
        }

        discountTotal {
          amount
          amountCents
          currency { iso }
        }

        total {
          amount
          currency { iso }
        }

        lineItems {
          id

          amount {
            amount
            currency { iso }
          }

          listing {
            id

            item {
              id
              title

              images {
                url(size: "cart")
              }

              account {
                id
                username
                displayName
              }
            }
          }
        }

        shipment {
          id
          address {
            id
          }
        }

        shippingMethod {
          ref
          overridePrice {
            amount
          }
        }
      }
    }
  }
`

const SET_SHIPPING_ADDRESS_MUTATION = `
  mutation UpdateShippingAddress($addressId: ID!, $orderId: [ID!], $contactId: ID) {
    cartSetShippingAddress(addressId: $addressId, orderId: $orderId, contactId: $contactId) {
      success
      message

      # Need to fetch this to update the cache properly
      cart {
        orders {
          id

          shipment {
            id

            address { id }
          }
        }
      }
    }
  }
`

const SET_SELECTED_ACCOUNT = `
mutation SetSelectedAccount ($id: ID!, $contactId: ID!) {
  orderSetContact(id: $id, contactId: $contactId) {
    id
    contact {
      id
      firstName
      lastName
      email
      phone
    }
  }
}
`

const SET_SHIPPING_METHOD_MUTATION = `
  mutation SetShippingMethod($orderId: ID!, $ref: String!, $overridePriceCents: Int) {
    orderSetShippingMethod(id: $orderId, shippingMethodRef: $ref, overridePriceCents: $overridePriceCents) {
      id
    }
  }
`

export default function CheckoutShippingPage() {
  const navigate = useNavigate()
  const shippingMethodRef = useRef()

  const [selectedAccount, setSelectedAccount] = useState(null)
  const [, mutateSelectedAccount] = useMutation(SET_SELECTED_ACCOUNT)
  const [errorMessage, setErrorMessage] = useState(null)
  const [processing, setProcessing] = useState(false)
  const { openOrderIds } = useCartContext()

  const [{ data, error, fetching }] = useQuery({
    query: SHIPPING_QUERY,
    variables: { orderId: openOrderIds },
  })

  const [, setShippingAddress] = useMutation(SET_SHIPPING_ADDRESS_MUTATION)
  const [, setShippingMethod] = useMutation(SET_SHIPPING_METHOD_MUTATION)

  const { user } = useUserContext()

  const [selectedAddress, setSelectedAddress] = useState(null)

  useEffect(() => {
    const order = data?.cart?.orders?.[0]
    if (order) {
      if (order.contact?.id) setSelectedAccount(order.contact.id)
    }
  }, [data?.cart?.orders])

  async function handleSubmit({ shippingMethod, shippingOverride }) {
    if (!adminDetailsComplete(user, data?.cart?.orders)) {
      toast.error('Admin fields are not complete')
      return false
    }

    if (processing) return

    setErrorMessage(null)
    setProcessing(true)

    try {
      const methodResults = await Promise.all(
        data.cart.orders.map((order) =>
          setShippingMethod({
            orderId: order.id,
            ref: shippingMethod[order.id],
            // TODO: Will need updating for multiple currencies
            overridePriceCents:
              shippingOverride[order.id] === ''
                ? null
                : parseInt(shippingOverride[order.id], 10) * 100,
          })
        )
      )

      for (const result of methodResults) {
        if (result.error) {
          setErrorMessage(result.error.message)
          setProcessing(false)

          return
        }
      }

      navigate('/checkout/payment')
    } catch (err) {
      setErrorMessage('An unknown error occurred.')
      setProcessing(false)

      throw err
    }
  }

  if (fetching) return <Loader background={colors.xLightGrey} height="250px" />
  if (error) return <p>{error.message}</p>

  // TODO: Decide where this should return to
  if (data.cart.orders.length === 0) return <Navigate to="/" />

  async function onAddressSelect(addressId) {
    const addressResult = await setShippingAddress({
      addressId,
      orderId: openOrderIds,
      contactId: selectedAccount,
    })

    if (addressResult.error) {
      setErrorMessage(addressResult.error.message)
      setProcessing(false)

      return false
    }

    if (!addressResult.data?.cartSetShippingAddress?.success) {
      toast.error(
        `${addressResult.data.cartSetShippingAddress.message}. Some changes could not be saved. You may need to refresh the page`
      )
      setProcessing(false)

      return false
    }

    setSelectedAddress(addressId)
    toast.success('Address updated', { id: 'address' })

    if (shippingMethodRef.current) {
      scrollToElement(shippingMethodRef.current)
    }
  }

  const initialShippingMethodValues = Object.fromEntries(
    data.cart.orders.map((order) => [
      order.id,
      order.shippingMethod ? order.shippingMethod.ref : '',
    ])
  )

  const initialShippingOverrideValues = Object.fromEntries(
    data.cart.orders.map((order) => [
      order.id,
      order.shippingMethod?.overridePrice
        ? order.shippingMethod.overridePrice.amount
        : '',
    ])
  )

  const persistSelectedAccount = (id) => {
    setSelectedAccount(id)
    data.cart.orders.forEach((order) => {
      mutateSelectedAccount({ id: order.id, contactId: id }).then(
        (response) => {
          if (response?.data?.orderSetContact) {
            toast.success('Account was set')
          } else {
            toast.error(
              "Couldn't set account for this order. Did you already take payment?"
            )
          }
        }
      )
    })
  }

  let showShipping = true
  let shippableOrders = data?.cart?.orders
  if (!user?.isAdmin) {
    shippableOrders = data?.cart?.orders?.filter((r) => r.requiresShipping)
    if (shippableOrders.length == 0) {
      showShipping = false
    }
  }

  return (
    <>
      {user?.isAdmin && (
        <FormWrapper header="Account" size="medium" placeholder="Name">
          <Inner>
            <AccountForm
              orders={data?.cart?.orders}
              setSelectedAccount={persistSelectedAccount}
            />
          </Inner>
        </FormWrapper>
      )}
      <Formik
        initialValues={{
          addressId: selectedAddress,
          shippingMethod: initialShippingMethodValues,
          shippingOverride: initialShippingOverrideValues,
        }}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        <FormikForm>
          {data.cart.orders.some((o) => !o.localPickupOnly) && (
            <FormWrapper
              header="Shipping Address"
              size="medium"
              placeholder="Name"
            >
              <Inner>
                {errorMessage && <p>{errorMessage}</p>}
                <AddressList
                  inputName="addressId"
                  accountId={selectedAccount}
                  onSelect={onAddressSelect}
                />
              </Inner>
            </FormWrapper>
          )}

          {showShipping && (
            <div ref={shippingMethodRef}>
              <FormWrapper
                header="Shipping Method"
                size="medium"
                placeholder="Name"
              >
                <Table orders={shippableOrders} shippingMethod />
              </FormWrapper>
            </div>
          )}

          <Footer size="medium">
            <Button type="submit" variant="primaryDark" disabled={processing}>
              {processing ? 'Processing...' : 'Continue'}
            </Button>
          </Footer>
        </FormikForm>
      </Formik>
    </>
  )
}

CheckoutShippingPage.propTypes = {}
