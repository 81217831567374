import {
  arrow,
  flip,
  offset,
  size,
  shift,
  useFloating,
} from '@floating-ui/react-dom'
import { cloneElement, useCallback, useState, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Transition from '../_abstracts/Animation'
import { linkStates } from '../_abstracts/Type'
import { ButtonStyle } from '../_atoms/Button'
import { useWindowSize } from '../hooks'
import ClientOnlyPortal from './ClientOnlyPortal'

const transitionDuration = 200

export const Container = styled.div`
  ${Transition({ property: 'opacity', duration: `${transitionDuration}ms` })};
  position: relative;
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: ${(props) => props.theme.radii};
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);
  border: 1px solid ${(props) => props.theme.colors.accent};
  text-align: center;
  font-size: 16px;
  opacity: ${({ state }) => (state === 'entered' ? 1 : 0)};

  ${ButtonStyle} {
    display: block;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    margin-top: 12px;
  }
`

export const Section = styled.div`
  padding-top: 12px;
  padding-bottom: 12px;
  border-top: 1px solid ${({ theme }) => theme.colors.blueTint};

  &:first-child {
    padding-top: 0;
    border-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }
`

const Inner = styled.div`
  width: 365px;
  max-height: ${({ maxHeight }) => maxHeight};
  padding: 20px 25px;
  font-size: 15px;
  border-radius: inherit;

  ${({ scrollable }) =>
    scrollable &&
    css`
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
    `}

  ${({ styles }) => styles}
`

const Close = styled.button`
  ${linkStates}
  display: inline-block;
  margin-top: 10px;
  font-size: 14px;
`

const Arrow = styled.div`
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: ${(props) => props.theme.colors.white};
  border-bottom: 1px solid ${(props) => props.theme.colors.accent};
  border-left: 1px solid ${(props) => props.theme.colors.accent};

  ${({ placement }) =>
    (placement.startsWith('top') &&
      css`
        top: calc(100% + 1px);
        transform: translateY(-50%) rotate(-45deg);
      `) ||
    (placement.startsWith('bottom') &&
      css`
        bottom: calc(100% + 1px);
        transform: translateY(50%) rotate(135deg);
      `)}
`

export default function Popup({
  children,
  includeClose,
  innerStyles,
  onToggle,
  placement,
  portal,
  scrollable,
  trigger,
}) {
  const [isActive, setIsActive] = useState(false)
  const [maxHeight, setMaxHeight] = useState('none')

  const toggle = useCallback(
    (active) => {
      setIsActive(active)
      onToggle?.(active)
    },
    [setIsActive, onToggle]
  )

  const arrowRef = useRef()
  const {
    x,
    y,
    reference,
    floating,
    strategy,
    update,
    refs,
    middlewareData,
    placement: currentPlacement,
  } = useFloating({
    placement,
    middleware: [
      offset(10),
      flip({
        padding: 10,
      }),
      shift({ padding: 10 }),
      size({
        padding: 12,
        apply({ height }) {
          if (scrollable) {
            setMaxHeight(`${height}px`)
          }
        },
      }),
      arrow({
        element: arrowRef,
        padding: 5,
      }),
    ],
  })

  // Close on escape key
  useEffect(() => {
    window.addEventListener('keyup', close)

    function close(event) {
      if (event.keyCode === 27 && isActive === true) {
        toggle(false)
      }
    }

    return () => {
      window.removeEventListener('keyup', close)
    }
  }, [isActive, toggle])

  // Close on click outside popup/trigger
  useEffect(() => {
    const onClick = (ev) => {
      if (
        !refs.reference.current?.contains(ev.target) &&
        !refs.floating.current?.contains(ev.target)
      ) {
        toggle(false)
      }
    }

    document.addEventListener('click', onClick)
    document.addEventListener('touchend', onClick)

    return () => {
      document.removeEventListener('click', onClick)
      document.removeEventListener('touchend', onClick)
    }
  }, [refs.reference, refs.floating, toggle])

  // Update position on resize
  const windowSize = useWindowSize({ includeScroll: scrollable })
  useEffect(() => {
    if (isActive && windowSize.width && windowSize.height) {
      update()
    }
  }, [
    update,
    isActive,
    windowSize.width,
    windowSize.height,
    windowSize.scrollY,
  ])

  // Close popup when page changes (e.g. if clicking a link inside popup):
  const location = useLocation()
  useEffect(() => {
    toggle(false)
  }, [location, toggle])

  const contents = (
    <CSSTransition
      in={isActive}
      timeout={{ exit: transitionDuration }}
      mountOnEnter
      unmountOnExit
    >
      {(state) => (
        <Container
          ref={floating}
          state={state}
          style={{
            position: strategy,
            top: y ?? '',
            left: x ?? '',
          }}
        >
          <Inner
            maxHeight={maxHeight}
            scrollable={scrollable}
            styles={innerStyles}
          >
            {children}
            {includeClose && <Close onClick={() => toggle(false)}>Close</Close>}
          </Inner>
          <Arrow
            placement={currentPlacement}
            ref={arrowRef}
            style={{
              left: middlewareData.arrow?.x ?? '',
              top: middlewareData.arrow?.y ?? '',
            }}
          />
        </Container>
      )}
    </CSSTransition>
  )

  return (
    <>
      {(portal && <ClientOnlyPortal>{contents}</ClientOnlyPortal>) || contents}

      {cloneElement(trigger, {
        ref: reference,
        onClick: () => toggle(!isActive) && update(),
      })}
    </>
  )
}

Popup.defaultProps = {
  placement: 'bottom',
  portal: true,
  includeClose: true,
}

Popup.propTypes = {
  children: PropTypes.node.isRequired,
  includeClose: PropTypes.bool,
  innerStyles: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onToggle: PropTypes.func,
  placement: PropTypes.string,
  portal: PropTypes.bool,
  scrollable: PropTypes.bool,
  trigger: PropTypes.node.isRequired,
}
