import { Wrapper as Checkbox } from '../shared/Checkbox'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

const Wrapper = styled.div``

const Inner = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: ${({ gapY }) => -gapY / 2}px ${({ gapX }) => -gapX / 2}px;

  ${({ direction, justify }) =>
    (direction === 'column' &&
      css`
        display: grid;
        grid-template-columns: auto 1fr;
      `) ||
    (direction === 'row' &&
      justify === 'center' &&
      css`
        justify-content: center;
      `)}

  ${Checkbox} {
    margin: ${({ gapY }) => gapY / 2}px ${({ gapX }) => gapX / 2}px;

    ${({ direction }) =>
      direction === 'column' &&
      css`
        width: 100%;
        grid-column: 1;
      `}
  }
`

export default function CheckboxGroup({
  children,
  direction,
  gapX,
  gapY,
  justify,
}) {
  return (
    <Wrapper>
      <Inner direction={direction} gapX={gapX} gapY={gapY} justify={justify}>
        {children}
      </Inner>
    </Wrapper>
  )
}

CheckboxGroup.defaultProps = {
  direction: 'column',
  gapX: 20,
  gapY: 20,
  justify: 'start',
}

CheckboxGroup.propTypes = {
  children: PropTypes.node,
  direction: PropTypes.oneOf(['row', 'column']).isRequired,
  gapX: PropTypes.number.isRequired,
  gapY: PropTypes.number.isRequired,
  justify: PropTypes.oneOf(['start', 'center']),
}
