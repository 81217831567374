import { useCallback, useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { down } from 'styled-breakpoints'
import styled from 'styled-components'

import Transition from '../_abstracts/Animation'
import { useGlobals } from '../hooks'
import ShareButton from '../shared/ShareButton'
import Html from '../data/Html'

const Padding = 20

//desktop
const Wrapper = styled.div`
  border: 1px solid ${(props) => props.theme.colors.midGrey};
  border-radius: 6px;
  overflow: hidden;
  background-color: ${(props) => props.theme.colors.white};

  ${down('medium')} {
    display: none;
  }
`

const Tabs = styled.div`
  padding: ${Padding}px ${Padding}px;
  display: flex;
  justify-content: center;
  position: relative;
`

const Tab = styled.div`
  padding: ${Padding / 2}px ${Padding}px;
  width: fit-content;
  z-index: 2;
  cursor: pointer;

  & + & {
    margin-left: 20px;
  }
`
const TabButton = styled.div`
  --transform: ${(props) => props.buttonLeft};
  border: 1px solid ${(props) => props.theme.colors.accent};
  border-radius: 5px;
  background-color: ${(props) => props.theme.colors.accentTint};
  left: 0;
  height: ${(props) => props.buttonHeight};
  width: ${(props) => props.buttonRight};
  transform: translateX(var(--transform));
  position: absolute;
  ${Transition({
    property: 'transform, width',
    duration: '300ms',
    ease: 'ease-out',
  })}
  will-change: transform, width;
  pointer-events: none;
`

const TabContent = styled.div`
  border-top: 1px solid ${(props) => props.theme.colors.midGrey};
  display: flex;
  max-height: ${(props) => (props.height ? props.height : 0)};
  overflow: hidden;
  ${Transition({ property: 'max-height', duration: '300ms', ease: 'ease-out' })}
  will-change: max-height;
`

const Content = styled.div`
  background-color: ${(props) => props.theme.colors.lightGrey};
  color: ${(props) => props.theme.colors.xDarkGrey};
  min-width: 100%;
  height: fit-content;
  transform: translateX(0);
  ${Transition({ property: 'transform', duration: '300ms', ease: 'ease-out' })}
  will-change: transform;

  > * {
    padding: 25px ${Padding}px;
  }

  b {
    font-weight: 600;
  }
`

const Bottom = styled.div`
  padding: ${Padding}px ${Padding}px;
  border-top: 1px solid ${(props) => props.theme.colors.midGrey};

  > * {
    width: 100%;
  }

  button {
    width: 50%;
    margin: 0 auto;
  }
`

export default function ListingTabs({ item }) {
  const { product } = useGlobals('product')
  const [button, setButton] = useState({
    left: '0px',
    width: '0px',
    height: '0px',
  })
  const [activeIndex, setActiveIndex] = useState(0)
  const [listHeight, setListHeight] = useState()
  const tabs = useRef()
  const tabsContent = useRef()

  // for window listener
  const [dimensions, setDimensions] = useState({
    height: 0,
    width: 0,
  })

  const setButtonDimensions = useCallback(() => {
    const el = tabs.current.children[activeIndex]

    if (!el) return

    if (el) {
      const boundingRect = el.getBoundingClientRect()
      const left = el.offsetLeft
      const { width, height } = boundingRect

      setButton({
        left: Math.round(left) + 'px',
        width: Math.round(width) + 'px',
        height: Math.round(height) + 'px',
      })
    }
  }, [activeIndex])

  const setTabContentHeight = useCallback(() => {
    const contentArray = Array.from(tabsContent.current.children)
    const { height } =
      contentArray[activeIndex].firstElementChild.getBoundingClientRect()

    setListHeight(Math.round(height) + 'px')
  }, [activeIndex])

  function tabClickHandler(event) {
    let newTab = event.currentTarget
    let newTabIndex = Array.from(tabs.current.children).indexOf(newTab)
    const contentArray = Array.from(tabsContent.current.children)

    if (activeIndex === newTabIndex) return

    setActiveIndex(newTabIndex)
    const boundingRect = newTab.getBoundingClientRect()
    let left = newTab.offsetLeft
    let { width, height } = boundingRect
    setButton({
      left: Math.round(left) + 'px',
      width: Math.round(width) + 'px',
      height: Math.round(height) + 'px',
    })
    setTabContentHeight()
    contentArray.forEach(
      (element) =>
        (element.style.transform = 'translateX(-' + 100 * newTabIndex + '%)')
    )
  }

  useEffect(setButtonDimensions, [activeIndex, dimensions, setButtonDimensions])

  useEffect(setTabContentHeight, [activeIndex, setTabContentHeight])

  useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      })

      setButtonDimensions()
      setTabContentHeight()
    }

    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [setButtonDimensions, setTabContentHeight])

  const shareButton = {
    text: item.title,
    url: item.url,
    img: item.imgUrl,
  }

  return (
    <Wrapper>
      <Tabs ref={tabs}>
        <Tab onClick={(e) => tabClickHandler(e)}>Spec</Tab>
        {item.description && (
          <Tab onClick={(e) => tabClickHandler(e)}>Description</Tab>
        )}
        {product?.productShippingReturns && (
          <Tab onClick={(e) => tabClickHandler(e)}>
            {product.productShippingReturnsTitle}
          </Tab>
        )}
        <TabButton
          buttonLeft={button.left}
          buttonRight={button.width}
          buttonHeight={button.height}
        />
      </Tabs>
      <TabContent ref={tabsContent} height={listHeight}>
        <Content>
          <ul>
            {Object.entries(item.spec)
              .filter(([, val]) => val)
              .map(([key, val]) => (
                <li key={key}>
                  <p>
                    <b>{key}:</b>{' '}
                    {typeof val == 'object' ? val[Object.keys(val)[0]] : val}
                  </p>
                </li>
              ))}
          </ul>
        </Content>
        {item.description && (
          <Content>
            <Html html={item.description} limited />
          </Content>
        )}
        {product?.productShippingReturns && (
          <Content>
            <Html html={product.productShippingReturns} />
          </Content>
        )}
      </TabContent>
      <Bottom>
        <ShareButton {...shareButton} />
      </Bottom>
    </Wrapper>
  )
}

ListingTabs.propTypes = {
  item: PropTypes.shape({
    description: PropTypes.string,
    spec: PropTypes.object,
    url: PropTypes.string.isRequired,
    imgUrl: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    shipping: PropTypes.shape({
      tnagPackaging: PropTypes.bool,
      localPickup: PropTypes.bool,
      location: PropTypes.string,
      countries: PropTypes.array,
    }),
  }).isRequired,
}
