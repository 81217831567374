import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Icon, PinIcon } from '../_base/Icon'

const Wrapper = styled.div`
  display: flex;
  color: ${(props) => props.theme.colors.darkBlue};
  font-size: 14px;
  cursor: default;

  ${Icon} {
    width: 14px;
    margin-right: 5px;
  }
`

export default function UserLocation({ locationCountry }) {
  return (
    <Wrapper>
      <PinIcon />
      <p>{locationCountry}</p>
    </Wrapper>
  )
}

UserLocation.propTypes = {
  locationCountry: PropTypes.string.isRequired,
}
