import PropTypes from 'prop-types'

import styled, { css } from 'styled-components'

import { Body } from '../_abstracts/Type'
import { Icon, DropdownIcon } from '../_base/Icon'
import Transition from '../_abstracts/Animation'
import { InputField } from '../_atoms/Input'
import { useToggle } from '../hooks'

const HorizontalPad = css`
  padding-left: 10px;
  padding-right: 10px;
`

export const FilterWrapper = styled.div`
  border: 1px solid ${(props) => props.theme.colors.midGrey};
  border-radius: ${(props) => props.theme.radii};
`

const Title = styled.button`
  ${Body};
  color: ${(props) => props.theme.colors.darkBlue};
  ${HorizontalPad};
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${Icon} {
    width: 16px;
    ${Transition({ property: 'transform' })};

    circle {
      ${Transition({ property: 'fill' })}
    }
    path {
      ${Transition({ property: 'stroke' })}
    }
  }

  ${(props) =>
    props.state === true &&
    css`
      ${Icon} {
        transform: rotate(180deg);

        circle {
          fill: transparent;
        }
        path {
          stroke: ${(props) => props.theme.colors.darkBlue};
        }
      }
    `}
`

const Content = styled.div`
  ${HorizontalPad};
  padding-bottom: 10px;
  font-size: 15px;
  max-height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  border-top: 1px solid ${({ theme }) => theme.colors.midGrey};

  ${({ state, theme }) =>
    (state &&
      css`
        padding-top: 10px;
        border-top: 1px solid ${theme.colors.midGrey};
      `) ||
    css`
      display: none;
    `}

  ${InputField} {
    padding-top: 5px;
    padding-bottom: 5px;
  }
`

export const Option = styled.li`
  list-style: none;
  padding-top: 8px;
  padding-bottom: 8px;
  border-top: 1px solid ${(props) => props.theme.colors.lightBtnGrey};

  &:first-child {
    padding-top: 0;
    border-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }
`

export default function Filter({ filter, children, accordion, openDefault }) {
  const [open, toggleOpen] = useToggle(openDefault)

  return (
    <>
      {accordion ? (
        <FilterWrapper>
          <Title onClick={toggleOpen} state={open}>
            {filter.label} <DropdownIcon />
          </Title>
          <Content state={open}>{children}</Content>
        </FilterWrapper>
      ) : (
        <FilterWrapper>
          <Title as="span">{filter.label}</Title>
          <Content state={true} noBorder>
            {children}
          </Content>
        </FilterWrapper>
      )}
    </>
  )
}

Filter.defaultProps = {
  openDefault: true,
}

Filter.propTypes = {
  filter: PropTypes.shape({
    label: PropTypes.string.isRequired,
  }),
  accordion: PropTypes.bool,
  openDefault: PropTypes.bool,
  children: PropTypes.node.isRequired,
}
