import PropTypes from 'prop-types'
import styled from 'styled-components'

const Currency = styled.span`
  margin-left: 0.3em;
  font-size: 0.85em;
`

export const formatMoney = (amount, currency, locale = 'en') => {
  let value = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  }).format(amount)

  // Remove .00 trailing cents from display prices
  value = value.replace(/[.,]00$/, '')

  return value
}

export default function Money({ amount, currency, includeCurrency, locale }) {
  return (
    <>
      {formatMoney(amount, currency, locale)}
      {includeCurrency && <Currency>{currency}</Currency>}
    </>
  )
}

Money.propTypes = {
  amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  currency: PropTypes.string.isRequired,
  includeCurrency: PropTypes.bool,
  locale: PropTypes.string,
}
