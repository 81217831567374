import PropTypes from 'prop-types'
import formatDate from 'date-fns/format'

export default function DateTime({ date, format, noTime }) {
  return formatDate(date, format || (noTime ? 'P' : 'P kk:mm'))
}

DateTime.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  format: PropTypes.string,
  noTime: PropTypes.bool,
}
