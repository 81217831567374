import Meta from '../_helpers/Meta'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { useQuery } from 'urql'
import Container from '../_helpers/Container'
import Spacer from '../_helpers/Spacer'
import NotFound from '../404/NotFound'
import Html from '../data/Html'
import Blocks, { blocksQueryFields } from '../shared/Blocks'
import Cta from '../shared/Cta'
import Hero from '../shared/Hero'
import Loader from '../shared/Loader'

const GET_PAGE = `
  query GetPage($slug: [String]!) {
    entry(section: "staticPages", slug: $slug) {
      id
      title
      url
      ... on staticPages_staticPages_Entry {
        ${blocksQueryFields}
        seoTitle
        seoDescription
        cta {
          title
          ... on ctas_ctas_Entry {
            ctaText
            ctaBackgroundColor
            ctaLinks {
              ... on ctaLinks_item_BlockType {
                buttonTheme
                itemLink {
                  text
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`

const Inner = styled.div``

const Content = styled.div`
  max-width: 1000px;
  padding: 32px 25px;
  margin-right: auto;
  margin-left: auto;
`

export default function Page() {
  const { slug } = useParams()
  const [{ data, fetching, error }] = useQuery({
    query: GET_PAGE,
    variables: { slug },
  })

  if (fetching) return <Loader background="#fff" />

  if (error) return <p>{error.message}</p>

  if (!data.entry) {
    return <NotFound />
  }

  const { entry } = data
  const cta = entry.cta[0]

  return (
    <>
      <Meta
        title={entry.seoTitle || entry.title}
        description={entry.seoDescription}
        url={entry.url}
      />
      <Hero
        hero={{
          header: entry.title,
          headerElement: 'h1',
        }}
      />
      <Spacer size="40px" />
      <Container>
        {entry.blocks.length ? (
          <Inner>
            {entry.blocks.length ? (
              <Content>
                <Blocks blocks={entry.blocks} />
              </Content>
            ) : null}
          </Inner>
        ) : null}
        {cta && (
          <>
            <Spacer fromSize="40px" toSize="70px" />
            <Cta
              header={cta.title}
              text={<Html html={cta.ctaText} />}
              variant={cta.ctaBackgroundColor}
              buttons={cta.ctaLinks.map((item) => ({
                text: item.itemLink.text,
                to: item.itemLink.url,
                variant: item.buttonTheme,
              }))}
            />
          </>
        )}
        <Spacer fromSize="40px" toSize="80px" />
      </Container>
    </>
  )
}
