import { ButtonStyle } from '../_atoms/Button'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  overflow: inherit;
`

export const Inner = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: ${({ gap }) => -gap / 2}px;

  ${({ direction, fullWidth }) =>
    direction === 'column' &&
    !fullWidth &&
    css`
      display: grid;
      grid-template-columns: auto 1fr;
    `}

  > * {
    margin: ${({ gap }) => gap / 2}px;

    & + ${ButtonStyle} {
      margin-left: ${({ gap }) => gap / 2}px;
    }

    ${({ direction, fullWidth }) =>
      (direction === 'column' &&
        css`
          width: 100%;
          grid-column: 1;
        `) ||
      (direction === 'row' &&
        fullWidth &&
        css`
          min-width: min-content;
          flex-basis: 0;
          flex-grow: 1;
        `)}
  }
`

export default function ButtonGroup({ children, direction, fullWidth, gap }) {
  return (
    <Wrapper>
      <Inner direction={direction} fullWidth={fullWidth} gap={gap}>
        {children}
      </Inner>
    </Wrapper>
  )
}

ButtonGroup.defaultProps = {
  direction: 'row',
  gap: 24,
}

ButtonGroup.propTypes = {
  children: PropTypes.node,
  direction: PropTypes.oneOf(['row', 'column']).isRequired,
  fullWidth: PropTypes.bool,
  gap: PropTypes.number.isRequired,
}
