import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom'
import { useQuery } from 'urql'
import Back from '../../shared/Back'
import Invoice from '../../orders/Invoice'
import Subheader from '../../shared/Subheader'
import Loader from '../../shared/Loader'
import NotFound from '../../404/NotFound'

const ORDER_STATEMENT = `
  query OrderStatement($orderId: ID!) {
    order(id: $orderId) {
      id
      number
      completedAt

      subTotal {
        amount
        currency { iso }
      }

      tax {
        amount
        currency { iso }
      }

      shippingCost {
        amount
        currency { iso }
      }

      discountTotal {
        amount
        amountCents
        currency { iso }
      }

      total {
        amount
        currency { iso }
      }
    }
  }
`

export default function DetailsPage() {
  const { orderId } = useParams()
  const [{ fetching, data, error }] = useQuery({
    query: ORDER_STATEMENT,
    variables: { orderId },
  })

  const breakdown =
    data?.order &&
    [
      {
        name: 'Subtotal',
        amount: data.order.subTotal.amount,
        currency: data.order.subTotal.currency.iso,
      },
      {
        name: 'Shipping',
        amount: data.order.shippingCost.amount,
        currency: data.order.shippingCost.currency.iso,
      },
      {
        name: 'Sales Tax',
        amount: data.order.tax.amount,
        currency: data.order.tax.currency.iso,
      },
      data.order.discountTotal.amountCents !== 0
        ? {
            name: 'Discount',
            amount: data.order.discountTotal.amount,
            currency: data.order.discountTotal.currency.iso,
          }
        : null,
      {
        name: 'Total',
        amount: data.order.total.amount,
        currency: data.order.total.currency.iso,
      },
    ].filter((b) => !!b)

  return (
    <>
      <Helmet>
        <title>Profile | Settings | Statement</title>
      </Helmet>
      <div>
        <Subheader border header="Statement" />

        <Back
          wrapped={true}
          url="/profile/settings/invoices"
          header="Back to invoices"
        />

        {fetching ? (
          <Loader background="#fff" />
        ) : error ? (
          <p>{error.message}</p>
        ) : !data?.order ? (
          <NotFound />
        ) : (
          <>
            <Invoice
              orderDate={new Date(data.order.completedAt)}
              orderNumber={data.order.number}
              breakdown={breakdown}
            />
          </>
        )}
      </div>
    </>
  )
}
