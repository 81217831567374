import PropTypes from 'prop-types'
import styled from 'styled-components'
import { fluidRange } from '../_abstracts/Layout'

// Component based on https://www.joshwcomeau.com/react/modern-spacer-gif/

export const Element = styled.span`
  width: ${({ axis, size }) => (axis === 'horizontal' ? size : '1px')};
  height: ${({ axis, size }) => (axis === 'vertical' ? size : '1px')};
  display: block;

  @media print {
    display: none;
  }
`

const Spacer = ({
  axis = 'vertical',
  size,
  fromSize,
  toSize,
  fromScreen,
  toScreen,
}) => {
  if (fromSize && toSize) {
    size = fluidRange(fromSize, toSize, fromScreen, toScreen)
  }

  return <Element axis={axis} size={size} />
}

Spacer.propTypes = {
  axis: PropTypes.oneOf(['horizontal', 'vertical']),
  size: PropTypes.string,
  fromSize: PropTypes.string,
  toSize: PropTypes.string,
  fromScreen: PropTypes.string,
  toScreen: PropTypes.string,
}

export default Spacer
