import { useRef } from 'react'
import styled from 'styled-components'
import { useGlobals } from '../hooks'
import { useMutation } from 'urql'
import * as Yup from 'yup'
import { useNavigate } from 'react-router-dom'
import { down } from 'styled-breakpoints'

import { Formik } from 'formik'
import Fieldset from '../forms/Fieldset'
import StepForm from '../forms/StepForm'
import CheckboxWrapped from '../inputs/CheckboxWrapped'
import Radio, { Wrapper as RadioWrapper } from '../inputs/Radio'
import RadioGroup, { Radios } from '../inputs/RadioGroup'

const Triggers = styled.div`
  width: 100%;
  position: relative;

  ${Radios} {
    margin: 0;
  }

  &::after {
    position: absolute;
    bottom: -10px;
    left: 50%;
    height: 25px;
    width: 1px;
    background: ${(props) => props.theme.colors.darkGrey};
  }

  &.active::after {
    content: '';
  }
`

const Trigger = styled.div`
  position: relative;

  ${RadioWrapper} {
    margin: 0;
    min-width: unset;
  }

  ${down('medium')} {
    min-width: 166px;
    img {
      width: 150px;
      height: 150px;
    }
  }

  &.active {
    padding-bottom: 10px;
  }
`

const Contents = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  border-top: 1px solid transparent;
  transition: all 0.4ms;

  &.active {
    border-top: 1px solid ${(props) => props.theme.colors.darkGrey};
    padding-top: 15px;
    margin-top: 10px;
  }

  &::before,
  &::after {
    position: absolute;
    top: 0;
    background: ${(props) => props.theme.colors.darkGrey};
    height: 25px;
    width: 1px;
    content: '';
  }

  &::before {
    left: 0;
  }

  &::after {
    right: 0;
  }

  ${Radios} {
    margin: 0;
  }

  ${down('medium')} {
    ${RadioWrapper} {
      min-width: 166px;
    }

    img {
      width: 150px;
      height: 150px;
    }
  }
`

const Content = styled.div`
  width: 100%;
  display: none;

  > *:first-child {
    margin-top: 15px;
  }

  &.active {
    display: block;
  }
`

const Schema = Yup.object().shape({
  buyer: Yup.boolean(),
  seller: Yup.boolean(),
  luthier: Yup.boolean(),
  instrument: Yup.string(),
})

const UPDATE_USAGE = `
  mutation SetUsageIntention(
    $buyer: Boolean
    $seller: Boolean
    $luthier: Boolean
  ) {
    accountSetUsageIntention(
      intention: { buyer: $buyer, seller: $seller, luthier: $luthier }
    ) {
      success
    }
  }
`

const ADD_SAVED_SEARCH = `
  mutation AddSavedSearch($keywords: String!) {
    listingSearchSave(search:{
      query: { keywords: $keywords }
    }) {
      success
      savedSearch {
        id
        keywords
        filters {
          key
          label
          value
        }
        emailOnMatch
      }
    }
  }
`

export default function InterestsForm() {
  const navigate = useNavigate()
  const [, update] = useMutation(UPDATE_USAGE)
  const [, add] = useMutation(ADD_SAVED_SEARCH)

  const triggersWrapper = useRef()
  const contentsWrapper = useRef()
  const contents = useRef(new Array())
  const triggers = useRef(new Array())
  const { interests } = useGlobals('interests')

  async function handleSubmit(fields) {
    const parsedFields = Schema.cast(fields, { strict: true })
    const requests = [update(parsedFields)]
    if (parsedFields.instrument) {
      requests.push(add({ keywords: parsedFields.instrument }))
    }
    await Promise.allSettled(requests)

    navigate('/welcome/saved-searches')
  }

  function clickHandler(event) {
    let current = event.currentTarget
    let currentIndex = triggers.current.indexOf(current)
    contentsWrapper.current.scrollIntoView()

    go(currentIndex)
  }

  function go(index) {
    contents.current.forEach((element) => element.classList.remove('active'))
    triggers.current.forEach((element) => element.classList.remove('active'))
    contents.current[index].classList.add('active')
    triggers.current[index].classList.add('active')
    contentsWrapper.current.classList.add('active')
    triggersWrapper.current.classList.add('active')
  }

  return (
    <>
      <Formik
        onSubmit={handleSubmit}
        validationSchema={Schema}
        initialValues={{
          buyer: false,
          seller: false,
          luthier: false,
          instrument: '',
        }}
      >
        <StepForm>
          <Fieldset
            highlight
            legend="What Type of Instruments Are You Interested In?"
          >
            <Triggers ref={triggersWrapper}>
              <RadioGroup
                fullWidth
                gap={50}
                images
                inline
                hideLabel
                label="Instrument type"
              >
                {interests?.interestsCategories.map((item, index) => {
                  return (
                    <Trigger
                      key={item.id}
                      onClick={(e) => clickHandler(e)}
                      ref={(element) => (triggers.current[index] = element)}
                    >
                      <Radio
                        font="serif"
                        imageUrl={item.image[0].url}
                        label={item.heading}
                        name="instrument"
                        value={item.heading}
                      />
                    </Trigger>
                  )
                })}
              </RadioGroup>
            </Triggers>

            <Contents ref={contentsWrapper}>
              {interests?.interestsCategories.map((group, index) => {
                return (
                  <Content
                    ref={(element) => (contents.current[index] = element)}
                    key={group.id}
                  >
                    <RadioGroup
                      fullWidth
                      gap={50}
                      images
                      inline
                      hideLabel
                      label="Instrument type"
                    >
                      {group.children.map((item) => {
                        return (
                          <Radio
                            key={item.id}
                            font="serif"
                            imageUrl={item.image[0].url}
                            label={item.heading}
                            name="instrument"
                            value={item.heading}
                          />
                        )
                      })}
                    </RadioGroup>
                  </Content>
                )
              })}
            </Contents>
          </Fieldset>

          <Fieldset highlight legend="How Do You Plan On Using The Platform?">
            <RadioGroup fullWidth hideLabel label="Account type">
              <CheckboxWrapped name="buyer" value="buyer" label="As a buyer" />
              <CheckboxWrapped
                name="seller"
                value="seller"
                label="As a seller"
              />
              <CheckboxWrapped
                name="luthier"
                value="luthier"
                label="As a luthier"
              />
            </RadioGroup>
          </Fieldset>
        </StepForm>
      </Formik>
    </>
  )
}
