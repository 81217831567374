import { css } from 'styled-components'
import Transition from './Animation'
import { down } from 'styled-breakpoints'

// Go to _base/type.js for styled-components

// --------SERIF FONTS "raleway"---------
export const HeaderXL = css`
  font-size: 60px;
  font-weight: 600;
  font-family: ${(props) => props.theme.fonts.serif};
  color: currentColor;
  line-height: 1.1;
  text-transform: capitalize;

  ${down('medium')} {
    font-size: 50px;
  }

  ${down('small')} {
    font-size: 40px;
  }
`

export const HeaderL = css`
  font-size: 34px;
  font-family: ${(props) => props.theme.fonts.serif};
  font-weight: 600;
  color: currentColor;
  line-height: 1.1;
  text-transform: capitalize;

  ${down('small')} {
    font-size: 28px;
  }
`
export const HeaderM = css`
  font-size: 22px;
  font-weight: 400;
  font-family: ${(props) => props.theme.fonts.serif};
  color: currentColor;
  line-height: 1.1;
  text-transform: capitalize;

  ${down('small')} {
    font-size: 20px;
  }
`
export const HeaderS = css`
  font-size: 20px;
  font-family: ${(props) => props.theme.fonts.serif};
  font-weight: 600;
  color: ${(props) => props.theme.colors.darkBlue};
  line-height: 1.1;
  text-transform: capitalize;
`
export const HeaderXS = css`
  font-size: 18px;
  font-weight: 600;
  font-family: ${(props) => props.theme.fonts.serif};
  color: ${(props) => props.theme.colors.accent};
  line-height: 1.1;
  text-transform: capitalize;
`

// --------SANSERIF FONTS "roboto"---------
// HEADERS
export const HeaderSansXL = css`
  font-size: 60px;
  font-weight: 600;
  font-family: ${(props) => props.theme.fonts.sansSerif};
  color: currentColor;
  text-transform: capitalize;

  ${down('small')} {
    font-size: 46px;
  }
`
export const HeaderSansL = css`
  font-size: 34px;
  font-family: ${(props) => props.theme.fonts.sansSerif};
  font-weight: 400;
  text-transform: capitalize;

  ${down('small')} {
    font-size: 28px;
  }
`
export const HeaderSansM = css`
  font-size: 22px;
  font-weight: 400;
  font-family: ${(props) => props.theme.fonts.sansSerif};
  text-transform: capitalize;

  ${down('small')} {
    font-size: 20px;
  }
`
export const HeaderSansXS = css`
  font-size: 18px;
  font-weight: 400;
  font-family: ${(props) => props.theme.fonts.sansSerif};
  line-height: 1.8;
  text-transform: capitalize;
`
export const HeaderSansXXS = css`
  font-size: 17px;
  font-weight: 400;
  font-family: ${(props) => props.theme.fonts.sansSerif};
  line-height: 1.8;
  text-transform: capitalize;
`
// BODY
export const Body = css`
  font-size: 16px;
  font-family: ${(props) => props.theme.fonts.sansSerif};
  font-weight: 400;
`
export const BodyS = css`
  font-size: 14px;
  font-family: ${(props) => props.theme.fonts.sansSerif};
  font-weight: 400;
`

// OTHER
export const SmallLetters = css`
  font-size: 12px;
  font-family: ${(props) => props.theme.fonts.sansSerif};
  font-weight: 400;
`

export const AllCaps = css`
  font-size: 12px;
  font-family: ${(props) => props.theme.fonts.sansSerif};
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 1.8px;
`

// Styling/interation

export const linkStates = css`
  text-decoration-skip-ink: auto;
  text-decoration: underline;
  ${Transition({ property: 'text-decoration-color, color' })};

  @supports (text-decoration-color: transparent) {
    text-decoration-color: ${(props) =>
      props.reverse || props.$reverse ? 'transparent' : 'currentColor'};
  }

  &:hover {
    color: ${(props) =>
      props.reverse || props.$reverse
        ? 'currentColor'
        : props.theme.colors.accent};
    text-decoration-color: currentColor;
  }

  &:active {
    color: ${(props) =>
      props.reverse || props.$reverse
        ? props.theme.colors.accent
        : 'currentColor'};
  }
`
