import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import styled, { css } from 'styled-components'

const Wrapper = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.white};

  ${({ $border }) =>
    $border &&
    css`
      border: 1px solid ${(props) => props.theme.colors.midGrey};
      border-radius: ${(props) => props.theme.radii};
    `}
`

const Panel = forwardRef(({ children, border }, ref) => {
  return (
    <Wrapper $border={border} ref={ref}>
      {children}
    </Wrapper>
  )
})

Panel.defaultProps = {
  border: true,
}

Panel.propTypes = {
  border: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

Panel.displayName = 'Panel'

export default Panel
