import { Helmet } from 'react-helmet-async'

import Subheader from '../../shared/Subheader'
import InvoiceTable from '../../orders/InvoiceTable'
import Split from '../Split'

export default function DetailsPage() {
  return (
    <>
      <Helmet>
        <title>Profile | Settings | Invoices</title>
      </Helmet>
      <div>
        <Subheader border header="Invoices" />

        <Split header="My invoices">
          <InvoiceTable />
        </Split>
      </div>
    </>
  )
}
