import { Helmet } from 'react-helmet-async'
import styled from 'styled-components'
import { useQuery } from 'urql'
import Loader from '../shared/Loader'
import Subheader from '../shared/Subheader'
import MethodsList from './MethodsList'
import Button from '../_atoms/Button'

const Wrapper = styled.div`
  padding: 32px;
`

const GET_SHIPPING_METHODS = `
query GetShippingMethods {
  orderMyShippingMethods {
    ref
    name
    country
    regions
    currency
    maxPrice {
      amountCents
      currency { iso }
    }
  }
}
`
export default function () {
  const [{ data, fetching, error }] = useQuery({ query: GET_SHIPPING_METHODS })

  if (fetching) return <Loader background="#fff" height="75px" />
  if (error) return <p>{error.message}</p>

  return (
    <>
      <Helmet>
        <title>Shipping Methods</title>
      </Helmet>
      <Subheader border header="Shipping Methods">
        <Button to="/profile/settings/shipping/new" variant="primaryDark">
          New shipping method
        </Button>
      </Subheader>
      <Wrapper>
        <MethodsList methods={data?.orderMyShippingMethods ?? []} />
      </Wrapper>
    </>
  )
}
