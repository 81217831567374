import Cookies from 'js-cookie'
import { createClient, dedupExchange, fetchExchange, ssrExchange } from 'urql'
import { cacheExchange } from '@urql/exchange-graphcache'
import { requestPolicyExchange } from '@urql/exchange-request-policy'
import { devtoolsExchange } from '@urql/devtools'
import { SAVED_SEARCHES } from './feed/MyFeedCustomizePage'

const ssr = ssrExchange({
  isClient: true,
  initialState: window.__URQL_DATA__,
})

function createUrqlClient() {
  return createClient({
    url: process.env.RAZZLE_GRAPHQL_URL,
    fetchOptions: () => {
      const csrfToken = Cookies.get('_csrf_token')

      return {
        credentials: 'include',
        headers: {
          'x-csrf-token': csrfToken,
        },
      }
    },
    exchanges: [
      devtoolsExchange,
      dedupExchange,
      requestPolicyExchange({}),
      cacheExchange({
        keys: {
          Cart: () => null,
          Country: () => null,
          CountryRegion: () => null,
          Currency: (c) => c.iso,
          EphemeralAttribute: () => null,
          linkField_Link: () => null,
          ListingSavedSearchFilter: () => null,
          MaterialSpec: () => null,
          Money: () => null,
          NotificationPreferences: () => null,
          SearchFilterSelect: () => null,
          SearchFilterSelectValue: () => null,
          SearchFilterNumberRange: () => null,
          ShippingMethod: () => null,
          KeyValueType: () => null,
          PageSettingsType: () => null,
          SaleData: () => null,
          PriceRange: () => null,
          PriceSuggestion: () => null,
          SellerReport: () => null,
          OrderHistoryPoint: () => null,
          PortfolioValue: () => null,
          ShippingMethodLine: ({ ref }) => ref,
          ShippingMethodDetails: ({ ref }) => ref,
          ShippingMethodMatrix: () => null,
          RetailItem: () => null,
          NodeCustomAttribute: () => null,
          Unfurl: () => null,
          UnfurlResponse: () => null,
          ItemUserEstimateHistory: () => null,
        },

        updates: {
          Mutation: {
            accountUpdate: (_, __, cache) => {
              cache.invalidate('Query', 'viewer')
            },

            notificationUpdatePreferences: (_, __, cache) => {
              cache.invalidate('Query', 'notificationPreferences')
            },

            messageThreadMarkRead: (_, __, cache) => {
              cache.invalidate('Query', 'messageCountUnread')
            },

            orderSetShippingMethod: (_, __, cache) => {
              cache
                .inspectFields({ __typename: 'Query' })
                .filter((f) => f.fieldName === 'cart')
                .forEach((f) =>
                  cache.invalidate('Query', f.fieldName, f.arguments)
                )
            },

            cartSetShippingAddress: ({ cartSetShippingAddress }, _, cache) => {
              if (!cartSetShippingAddress.success) return

              cache
                .inspectFields({ __typename: 'Query' })
                .filter((f) => f.fieldName === 'orderShippingMethods')
                .forEach((f) =>
                  cache.invalidate('Query', f.fieldName, f.arguments)
                )
            },

            cartAddListing: (
              { cartAddListing },
              { listingId },
              cache,
              { optimistic }
            ) => {
              if (!cartAddListing.success) return

              cache
                .inspectFields({ __typename: 'Listing', id: listingId })
                .filter((f) => f.fieldName === 'inViewerCart')
                .forEach((f) => {
                  cache.writeFragment(
                    `
                        fragment _ on Listing {
                          inViewerCart(orderId: $orderId)
                        }
                      `,
                    { id: listingId, inViewerCart: true },
                    f.arguments
                  )
                })

              if (!optimistic) {
                const orderId = cartAddListing?.cart?.orders?.map((o) => o.id)

                if (orderId?.length) {
                  cache.writeFragment(
                    `
                      fragment _ on Listing {
                        inViewerCart(orderId: $orderId)
                      }
                    `,
                    { id: listingId, inViewerCart: true },
                    { orderId }
                  )
                }

                cache
                  .inspectFields({ __typename: 'Query' })
                  .filter((f) => f.fieldName === 'cart')
                  .forEach((f) =>
                    cache.invalidate('Query', f.fieldName, f.arguments)
                  )
              }
            },

            cartRemoveListing: (
              { cartRemoveListing },
              { listingId },
              cache,
              { optimistic }
            ) => {
              if (!cartRemoveListing.success) return

              cache
                .inspectFields({ __typename: 'Listing', id: listingId })
                .filter((f) => f.fieldName === 'inViewerCart')
                .forEach((f) => {
                  cache.writeFragment(
                    `
                        fragment _ on Listing {
                          inViewerCart(orderId: $orderId)
                        }
                      `,
                    { id: listingId, inViewerCart: false },
                    f.arguments
                  )
                })

              if (!optimistic) {
                cache
                  .inspectFields({ __typename: 'Query' })
                  .filter((f) => f.fieldName === 'cart')
                  .forEach((f) =>
                    cache.invalidate('Query', f.fieldName, f.arguments)
                  )
              }
            },

            cartRemoveLineItems: ({ cartRemoveLineItems }, _, cache) => {
              if (!cartRemoveLineItems.success) return

              cache
                .inspectFields({ __typename: 'Query' })
                .filter((f) => f.fieldName === 'cart')
                .forEach((f) =>
                  cache.invalidate('Query', f.fieldName, f.arguments)
                )
            },

            cartUpdateLineItemPrice: (
              { cartUpdateLineItemPrice },
              _,
              cache
            ) => {
              if (!cartUpdateLineItemPrice.success) return

              cache
                .inspectFields({ __typename: 'Query' })
                .filter((f) => f.fieldName === 'cart')
                .forEach((f) =>
                  cache.invalidate('Query', f.fieldName, f.arguments)
                )
            },

            cartUpdateLineItemQuantity: (
              { cartUpdateLineItemQuantity },
              _,
              cache
            ) => {
              if (!cartUpdateLineItemQuantity.success) return

              cache
                .inspectFields({ __typename: 'Query' })
                .filter((f) => f.fieldName === 'cart')
                .forEach((f) =>
                  cache.invalidate('Query', f.fieldName, f.arguments)
                )
            },

            listingSavedSearchRemove: (
              { listingSavedSearchRemove },
              { id },
              cache,
              { optimistic }
            ) => {
              if (!listingSavedSearchRemove.success) return

              cache.updateQuery({ query: SAVED_SEARCHES }, (data) => {
                if (!data) return data

                data.listingSavedSearches = data.listingSavedSearches.filter(
                  (savedSearch) => savedSearch.id !== id
                )

                return data
              })

              if (!optimistic) {
                cache
                  .inspectFields({ __typename: 'Query' })
                  .filter((f) => f.fieldName === 'feed')
                  .forEach((f) =>
                    cache.invalidate('Query', f.fieldName, f.arguments)
                  )
              }
            },

            listingSearchSave: (
              { listingSearchSave },
              _,
              cache,
              { optimistic }
            ) => {
              if (!listingSearchSave.success) return
              if (!listingSearchSave.savedSearch) return

              cache.updateQuery({ query: SAVED_SEARCHES }, (data) => {
                if (!data) return data

                data.listingSavedSearches.unshift(listingSearchSave.savedSearch)

                return data
              })

              if (!optimistic) {
                cache
                  .inspectFields({ __typename: 'Query' })
                  .filter((f) => f.fieldName === 'feed')
                  .forEach((f) =>
                    cache.invalidate('Query', f.fieldName, f.arguments)
                  )
              }
            },

            wishlistAdd: (
              { wishlistAdd },
              { input },
              cache,
              { optimistic }
            ) => {
              if (!wishlistAdd.success) return

              cache.writeFragment(
                `
                    fragment _ on Item {
                      inViewerWishlist
                    }
                  `,
                { id: input.itemId, inViewerWishlist: true }
              )

              if (!optimistic) {
                cache
                  .inspectFields({ __typename: 'Query' })
                  .filter((f) => f.fieldName === 'wishlistItems')
                  .forEach((f) =>
                    cache.invalidate('Query', f.fieldName, f.arguments)
                  )
              }
            },

            wishlistRemove: (
              { wishlistRemove },
              { input },
              cache,
              { optimistic }
            ) => {
              if (!wishlistRemove.success) return

              cache.writeFragment(
                `
                    fragment _ on Item {
                      inViewerWishlist
                    }
                  `,
                { id: input.itemId, inViewerWishlist: false }
              )

              if (!optimistic) {
                cache
                  .inspectFields({ __typename: 'Query' })
                  .filter((f) => f.fieldName === 'wishlistItems')
                  .forEach((f) =>
                    cache.invalidate('Query', f.fieldName, f.arguments)
                  )
              }
            },

            paymentDeleteSavedStripeCard: (_, __, cache) => {
              cache.invalidate('Query', 'paymentSavedStripeCards')
            },

            itemCreate: (_, __, cache) => {
              cache
                .inspectFields({ __typename: 'Query' })
                .filter((f) => f.fieldName === 'itemPortfolioValue')
                .forEach((i) =>
                  cache.invalidate('Query', i.fieldName, i.arguments)
                )

              cache
                .inspectFields({ __typename: 'Query' })
                .filter((f) => f.fieldName === 'items')
                .forEach((f) =>
                  cache.invalidate('Query', f.fieldName, f.arguments)
                )
            },

            itemRemove: (_, __, cache) => {
              cache
                .inspectFields({ __typename: 'Query' })
                .filter((f) => f.fieldName === 'itemPortfolioValue')
                .forEach((i) =>
                  cache.invalidate('Query', i.fieldName, i.arguments)
                )

              cache
                .inspectFields({ __typename: 'Query' })
                .filter((f) => f.fieldName === 'items')
                .forEach((f) =>
                  cache.invalidate('Query', f.fieldName, f.arguments)
                )
            },

            orderCreateShippingMethod: (_, __, cache) => {
              cache
                .inspectFields({ __typename: 'Query' })
                .filter((f) =>
                  ['orderShippingMethod', 'orderMyShippingMethods'].includes(
                    f.fieldName
                  )
                )
                .forEach((i) =>
                  cache.invalidate('Query', i.fieldName, i.arguments)
                )
            },

            itemUpdate: (_, __, cache) => {
              cache
                .inspectFields({ __typename: 'Query' })
                .filter((f) => f.fieldName === 'itemPortfolioValue')
                .forEach((i) =>
                  cache.invalidate('Query', i.fieldName, i.arguments)
                )

              cache
                .inspectFields({ __typename: 'Query' })
                .filter((f) => f.fieldName === 'items')
                .forEach((f) =>
                  cache.invalidate('Query', f.fieldName, f.arguments)
                )
            },

            listingUpdate: (_, __, cache) => {
              cache
                .inspectFields({ __typename: 'Query' })
                .filter((f) => f.fieldName === 'itemPortfolioValue')
                .forEach((i) =>
                  cache.invalidate('Query', i.fieldName, i.arguments)
                )

              cache
                .inspectFields({ __typename: 'Query' })
                .filter((f) => f.fieldName === 'listings')
                .forEach((f) =>
                  cache.invalidate('Query', f.fieldName, f.arguments)
                )
            },
          },
        },

        optimistic: {
          cartAddListing: () => ({
            __typename: 'CartAddPayload',
            success: true,
          }),

          cartRemoveListing: () => ({
            __typename: 'CartRemovePayload',
            success: true,
          }),

          listingSavedSearchSetEmailOnMatch: ({ id, emailOnMatch }) => ({
            __typename: 'ListingSavedSearchSetEmailOnMatchPayload',
            success: true,
            savedSearch: {
              __typename: 'ListingSavedSearch',
              id,
              emailOnMatch,
            },
          }),

          listingSavedSearchRemove: () => ({
            __typename: 'ListingSavedSearchRemovePayload',
            success: true,
          }),

          listingSearchSave: () => ({
            __typename: 'ListingSearchSavePayload',
            success: true,
            savedSearch: null,
          }),

          wishlistAdd: () => ({
            __typename: 'WishlistAddPayload',
            success: true,
          }),

          wishlistRemove: () => ({
            __typename: 'WishlistRemovePayload',
            success: true,
          }),
        },
      }),
      ssr,
      fetchExchange,
    ],
  })
}

export default createUrqlClient
