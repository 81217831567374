import PropTypes from 'prop-types'
import Roundel from './Roundel'

export default function SellerRoundel({ user }) {
  if (user.isProfessionalSeller) {
    return (
      <Roundel size="small" variant="cream">
        Professional Seller
      </Roundel>
    )
  }

  if (user.isPrivateSeller) {
    return (
      <Roundel size="small" variant="cream">
        Private seller
      </Roundel>
    )
  }

  return (
    <Roundel size="small" variant="carterRed">
      {user.displayName ?? user.username}
    </Roundel>
  )
}

SellerRoundel.propTypes = {
  user: PropTypes.shape({
    isPrivateSeller: PropTypes.bool.isRequired,
    isProfessionalSeller: PropTypes.bool.isRequired,
    username: PropTypes.string.isRequired,
    displayName: PropTypes.string,
  }).isRequired,
}
