import Meta from '../_helpers/Meta'
import { useParams } from 'react-router-dom'
import { down } from 'styled-breakpoints'
import styled, { css } from 'styled-components'
import { useQuery } from 'urql'
import { HeaderL } from '../_abstracts/Type'
import Button, { ButtonStyle } from '../_atoms/Button'
import Container from '../_helpers/Container'
import linearGradient from '../_helpers/linearGradient'
import Spacer from '../_helpers/Spacer'
import NotFound from '../404/NotFound'
import Icon from '../blocks/Icon'
import Html from '../data/Html'
import SearchBar, { Form as SearchForm } from '../search/SearchBar'
import Accordion from '../shared/Accordion'
import Banner from '../shared/Banner'
import Hero from '../shared/Hero'
import Cta from '../shared/Cta'
import CtaFeatured from '../shared/CtaFeatured'
import CtaLegal from '../shared/CtaLegal'
import Listings from '../shared/Listings'
import Loader from '../shared/Loader'
import Tabs from '../shared/Tabs'

const GET_PAGE = `
  query GetPage {
    entry(section: "helpCenter") {
      id
      title
      url
      ... on helpCenter_helpCenter_Entry {
        heroTitle
        searchInputPlaceholder
        seoTitle
        seoDescription
        helpIntroTitle
        helpIntroSections {
          ... on helpIntroSections_item_BlockType {
            itemTitle
            text
            button {
              text
              url
            }
            buttonTheme
          }
        }
        linksTitle
        links {
          ... on links_item_BlockType {
            itemTitle
            subtitle
            itemLink {
              url
              text
            }
          }
        }
        cta {
          title
          ... on ctas_ctas_Entry {
            ctaText
            ctaBackgroundColor
            ctaLinks {
              ... on ctaLinks_item_BlockType {
                buttonTheme
                itemLink {
                  text
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`

const GET_ENTRIES = `
  query GetEntries {
    entries(section: "helpCenterPages", level: 1) {
      id
      slug
      title
      uri
      ... on helpCenterPages_sectionType_Entry {
        helpEntriesTitle
        linksTitle
        links {
          ... on links_item_BlockType {
            itemTitle
            subtitle
            itemLink {
              url
              text
            }
          }
        }
        faqsTitle
        faqs {
          ... on faqs_item_BlockType {
            question
            answer
            buttonTheme
            buttonOpenChat
            buttonLink {
              text
              url
            }
          }
        }
        children {
          id
          title
          ... on helpCenterPages_category_Entry {
            id
            icon
            children {
              id
              title
              uri
            }
          }
        }
      }
    }
  }
`

const Wrapper = styled.div`
  background-image: ${linearGradient(
    'to bottom',
    'white 1 0%',
    'lighterGrey 1 500px'
  )};
`

const Search = styled.div`
  display: flex;
  justify-content: center;

  ${SearchForm} {
    max-width: 600px;
  }
`

const GutterXtra = styled(Container)`
  max-width: 1080px;
`

const Background = styled.div`
  background-color: ${(props) => props.theme.colors.blueTint};
  border-top: 1px solid ${(props) => props.theme.colors.midGrey};
  border-bottom: 1px solid ${(props) => props.theme.colors.midGrey};

  &:first-child {
    border-top: 0;
  }

  &:last-child {
    border-bottom: 0;
  }
`

const Flex = styled.div`
  padding: 10px 20px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -20px;

  ${(props) =>
    props.size == 'small' &&
    css`
      max-width: 850px;
      margin-right: auto;
      margin-left: auto;
      margin-bottom: -40px;
    `}

  ${({ $center }) =>
    $center &&
    css`
      justify-content: center;
    `}
`

const Item = styled.div`
  display: flex;
  padding: 10px;
  width: 33.33%;

  ${down('medium')} {
    width: 50%;
  }

  ${down('small')} {
    width: 100%;
  }
`

const BigItem = styled.div`
  padding: 10px;
  width: 50%;

  ${down('small')} {
    width: 100%;
    max-width: none;
  }
`

const Title = styled.h2`
  ${HeaderL};
  margin-top: -0.2em;
  text-align: center;
  color: ${(props) => props.theme.colors.darkBlue};
`

const IntroTitle = styled.h2`
  ${HeaderL}
  margin-bottom: 28px;
  text-align: center;
`

const IntroSections = styled.ul`
  display: grid;
  grid-column-gap: 30px;
  grid-row-gap: 45px;
  grid-template-columns: repeat(auto-fit, minmax(425px, 1fr));

  ${down('small')} {
    display: block;
  }

  > li {
    padding: 30px;
    background-color: #fff;
    border-radius: 6px;
    border: 1px solid ${({ theme }) => theme.colors.midGrey};
  }

  h3 {
    margin-top: -0.2em;
    font-weight: 600;
    margin-bottom: 10px;
  }

  ${ButtonStyle} {
    margin-top: 20px;
  }
`

const Content = () => {
  const sectionSlug = useParams().section
  const [result] = useQuery({ query: GET_ENTRIES })

  const { data, fetching, error } = result

  if (fetching) return <Loader />

  if (error) return <p>{error.message}</p>

  const { entries } = data

  if (!entries.length) {
    return <p>No sections found</p>
  }

  let section = entries[0]
  const tabs = []

  for (const entry of entries) {
    tabs.push({
      title: entry.title,
      url: `/${entry.uri}`,
    })

    if (sectionSlug === entry.slug) {
      section = entry
    }
  }

  return (
    <Tabs tabs={tabs} activeUrl={`/${section.uri}`}>
      <Background>
        <Spacer fromSize="30px" toSize="50px" />
        {section.linksTitle && <Title>{section.linksTitle}</Title>}
        <Flex $center={section.links.length < 3}>
          {section.links.map((item, index) => (
            <Item key={index}>
              <CtaFeatured
                header={item.itemTitle}
                text={item.subtitle}
                buttonText={item.itemLink.text}
                buttonUrl={item.itemLink.url}
              />
            </Item>
          ))}
        </Flex>
        <Spacer fromSize="30px" toSize="50px" />
      </Background>

      <Spacer fromSize="30px" toSize="50px" />
      {section.helpEntriesTitle && <Title>{section.helpEntriesTitle}</Title>}
      <Flex size="small" $center={section.children.length < 2}>
        {section.children.map((category) => (
          <BigItem key={category.id}>
            <Listings
              header={category.title}
              icon={category.icon ? <Icon icon={category.icon} /> : undefined}
              items={category.children.map((entry) => ({
                buttonText: entry.title,
                url: `/${entry.uri}`,
              }))}
            />
          </BigItem>
        ))}
      </Flex>
      <Spacer fromSize="30px" toSize="50px" />
      <Background>
        <GutterXtra>
          <Spacer fromSize="30px" toSize="50px" />
          {section.faqsTitle && <Banner header={section.faqsTitle} />}
          <Accordion
            includeExcerpt
            items={section.faqs.map((faq) => ({
              title: faq.question,
              text: <Html html={faq.answer} />,
              button: faq.buttonLink.url && (
                // TODO: Use faq.buttonOpenChat (bool) button field to open chat (Zendesk?)
                <Button to={faq.buttonLink.url} variant={faq.buttonTheme}>
                  {faq.buttonLink.text}
                </Button>
              ),
            }))}
            numbered
          />
          <Spacer fromSize="30px" toSize="50px" />
        </GutterXtra>
      </Background>
    </Tabs>
  )
}

export default function HelpCenterIndexPage() {
  const [result] = useQuery({ query: GET_PAGE })

  const { data, fetching, error } = result

  if (fetching) return <Loader background="#fff" />

  if (error) return <p>{error.message}</p>

  if (!data.entry) {
    return <NotFound />
  }

  const { entry } = data
  const cta = entry.cta[0]

  return (
    <>
      <Meta
        title={entry.seoTitle || entry.title}
        description={entry.seoDescription}
        url={entry.url}
      />
      <Hero
        animate={false}
        hero={{
          header: entry.heroTitle,
          headerElement: 'p',
          subheader: entry.title,
          subheaderElement: 'h1',
        }}
      >
        <Search>
          <SearchBar
            action="/help-center/search"
            name="q"
            placeholder={entry.searchInputPlaceholder}
          />
        </Search>
      </Hero>
      <Wrapper>
        <Container>
          <Spacer fromSize="30px" toSize="50px" />
          {entry.helpIntroTitle || entry.helpIntroSections.length ? (
            <>
              {entry.helpIntroTitle && (
                <IntroTitle>{entry.helpIntroTitle}</IntroTitle>
              )}
              {entry.helpIntroSections.length ? (
                <IntroSections>
                  {entry.helpIntroSections.map((item, index) => (
                    <li key={index}>
                      <h3>{item.itemTitle}</h3>
                      <Html html={item.text} />
                      {item.button.url && (
                        <Button to={item.button.url} variant={item.buttonTheme}>
                          {item.button.text}
                        </Button>
                      )}
                    </li>
                  ))}
                </IntroSections>
              ) : null}
              <Spacer fromSize="26px" toSize="40px" />
            </>
          ) : null}
          <Content />
          {cta && (
            <>
              <Spacer fromSize="30px" toSize="50px" />
              <Cta
                header={cta.title}
                text={<Html html={cta.ctaText} />}
                variant={cta.ctaBackgroundColor}
                buttons={cta.ctaLinks.map((item) => ({
                  text: item.itemLink.text,
                  to: item.itemLink.url,
                  variant: item.buttonTheme,
                }))}
              />
            </>
          )}
          <Spacer fromSize="30px" toSize="60px" />
          {entry.linksTitle && <Title>{entry.linksTitle}</Title>}
          <Flex>
            {entry.links.map((item, index) => (
              <Item key={index}>
                <CtaLegal
                  header={item.itemTitle}
                  text={item.subtitle}
                  buttonText={item.itemLink.text}
                  buttonUrl={item.itemLink.url}
                />
              </Item>
            ))}
          </Flex>
          <Spacer fromSize="40px" toSize="80px" />
        </Container>
      </Wrapper>
    </>
  )
}

HelpCenterIndexPage.propTypes = {}
