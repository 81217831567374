import PropTypes from 'prop-types'
import { useUserContext } from '../auth/UserContext'
import { useClient, useMutation } from 'urql'
import { CREATE_ADDRESS } from '../forms/AddressForm'
import Button from '../_atoms/Button'
import toast from 'react-hot-toast'
import styled from 'styled-components'

const ButtonWrapper = styled.div`
  padding-bottom: 20px;
`

const GET_USER_INFO = `
query GetUserLocalPickupInfo($id: ID!) {
  account(id: $id) {
    id
    firstName
    lastName
  }
}
`

export default function LocalPickup({
  onAddressCreate,
  accountId,
  setSelected,
}) {
  const [, createAddress] = useMutation(CREATE_ADDRESS)
  const { user } = useUserContext()
  const client = useClient()

  if (!user?.isAdmin) return null
  if (!accountId) return null
  if (accountId == user?.id) return null

  const createLocalPickup = async () => {
    const accountQueryResponse = await client
      .query(GET_USER_INFO, {
        id: accountId,
      })
      .toPromise()

    const firstName = accountQueryResponse?.data?.account?.firstName || 'Carter'
    const lastName = accountQueryResponse?.data?.account?.lastName || 'Vintage'

    const response = await createAddress({
      accountId,
      address: {
        firstName,
        lastName,
        phone: '(615) 915-1851',
        line1: '606 8th Ave. S',
        line2: 'Suite 201',
        city: 'Nashville',
        region: 'Tennessee',
        countryIso: 'US',
        postalCode: '37203',
      },
    })

    if (
      response?.data?.addressCreate?.success &&
      response?.data?.addressCreate?.address
    ) {
      toast.success('Local pickup address created')
      onAddressCreate(response.data.addressCreate.address)
      setSelected(response.data.addressCreate.address.id)
    } else {
      toast.error('Could not create local pickup address')
    }
  }

  return (
    <ButtonWrapper>
      <Button onClick={createLocalPickup}>Local Pickup</Button>
    </ButtonWrapper>
  )
}

LocalPickup.propTypes = {
  onAddressCreate: PropTypes.func.isRequired,
  accountId: PropTypes.string.isRequired,
  setSelected: PropTypes.func.isRequired,
}
