import { Formik } from 'formik'
import { useEffect } from 'react'
import toast from 'react-hot-toast'
import { useMutation } from 'urql'
import * as Yup from 'yup'
import Form from '../../_atoms/Form'
import Button from '../../_atoms/Button'
import Input from '../../_atoms/Input'
import { useUserContext } from '../../auth/UserContext'
import Field from '../../forms/Field'
import { SimpleFieldset } from '../../forms/Fieldset'
import Phone from '../../inputs/Phone'
import Loader from '../../shared/Loader'

const UPDATE_USER = `
  mutation UpdateUser($account: AccountUpdateInput!) {
    accountUpdate(account: $account) {
      success
    }
  }
`

const Schema = Yup.object().shape({
  firstName: Yup.string().max(100, 'Max 100 characters').required('Required'),
  lastName: Yup.string().max(100, 'Max 100 characters').required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  phone: Yup.string()
    .max(100, 'Max 100 characters')
    .nullable()
    .transform((value) => value || null),
})

export default function DetailsForm() {
  const { user } = useUserContext()
  const [update, updateAccount] = useMutation(UPDATE_USER)

  useEffect(() => {
    if (update.fetching) {
      toast('Saving…', { id: 'form' })
    } else if (update.data?.accountUpdate.success) {
      toast.success('Changes saved', { id: 'form' })
    } else if (update.error || update.data?.accountUpdate.success === false) {
      toast.error('Unable to save changes', { id: 'form' })
    }
  }, [update])

  if (!user) return <Loader background="#fff" />

  async function handleSubmit(fields) {
    const parsedFields = Schema.cast(fields, { strict: true })
    const { data } = await updateAccount({
      account: parsedFields,
    })

    if (data?.accountUpdate.success) {
      console.log('Successfully updated account')
    }
  }

  return (
    <>
      <Formik
        onSubmit={handleSubmit}
        validationSchema={Schema}
        initialValues={{
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          phone: user.phone || '',
        }}
      >
        <Form $border justify="center">
          <SimpleFieldset legend="Basic details">
            <Field width="half">
              <Input
                autocomplete="given-name"
                label="First name"
                name="firstName"
                required
              />
            </Field>
            <Field width="half">
              <Input
                autocomplete="family-name"
                label="Last name"
                name="lastName"
                required
              />
            </Field>
            <Field>
              <Input
                autocomplete="email"
                label="Email address"
                name="email"
                type="email"
                required
              />
            </Field>
            <Field>
              <Phone />
            </Field>
          </SimpleFieldset>

          <Field justify="end">
            <Button disabled={update.fetching} submit variant="primaryDark">
              Save
            </Button>
          </Field>
        </Form>
      </Formik>
    </>
  )
}
