import { Link } from 'react-router-dom'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Body } from '../_abstracts/Type'
import { Icon, ArrowIcon } from '../_base/Icon'

const Topper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid ${(props) => props.theme.colors.midGrey};
  background: ${(props) => props.theme.colors.blueTint};

  @media print {
    display: none;
  }
`

const TopperHeader = styled.p`
  ${Body}
  padding: 10px;
`

const Go = styled(Link)`
  ${Body};
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  color: inherit;
  transition: 0.15s;

  ${Icon} {
    display: inline-block;
    width: 20px;
    min-width: 20px;
    margin-right: 10px;
  }

  &:hover {
    color: ${(props) => props.theme.colors.accent};
  }
`

const Text = styled.span`
  display: inline-block;
  border-bottom: 1px solid;
  margin-left: 5px;
`

const TopperActions = styled.div`
  padding: 5px 10px;
  flex-grow: 1;
  text-align: right;

  > * {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
  }

  > * * {
    display: inline-flex;
    margin: 5px 0 5px 15px;
  }

  select {
    min-width: 200px;
  }
`

export default function Subnav({ header, children, link }) {
  return (
    <Topper>
      <TopperHeader>
        <Go to={link}>
          <ArrowIcon />
          <Text>{header}</Text>
        </Go>
      </TopperHeader>

      <TopperActions>{children}</TopperActions>
    </Topper>
  )
}

Subnav.propTypes = {
  header: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}
