import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { Option } from './Filter'
import Checkbox from '../shared/Checkbox'
import { linkStates } from '../_abstracts/Type'

const Button = styled.button.attrs({ type: 'button' })`
  ${linkStates};
  display: flex;
  width: 100%;

  ${({ isSelected }) =>
    isSelected &&
    css`
      font-weight: 700;
    `}

  span:last-child {
    margin-left: auto;
  }
`

const translationMap = {
  seller: {
    CarterVintage: 'Carter Vintage',
  },
  handedness: {
    NOT_APPLICABLE: 'N/A',
    LEFT_HANDED: 'Left',
    RIGHT_HANDED: 'Right',
  },
}

export function translate(filter, value) {
  return translationMap?.[filter.key]?.[value.value] || value.value
}

export default function Select({ filter, setFilter, clearFilter }) {
  return (
    <ul>
      {filter.values.map((value) => {
        return (
          <Option key={`${filter.key}-${value.value}`}>
            {filter.multiple ? (
              <Checkbox
                controlled
                onChange={() => {
                  if (value.isSelected) {
                    clearFilter(filter.key, value.value, { multiple: true })
                  } else {
                    setFilter(filter.key, value.value, { multiple: true })
                  }
                }}
                label={translate(filter, value)}
                blueLabel
                resultCount={value.resultCount}
                checked={value.isSelected}
              />
            ) : (
              <Button
                $reverse
                isSelected={value.isSelected}
                onClick={() => {
                  if (value.isSelected) {
                    clearFilter(filter.key, value.value)
                  } else {
                    setFilter(filter.key, value.value)
                  }
                }}
              >
                <span>{translate(filter, value)}</span>
                <span>({value.resultCount})</span>
              </Button>
            )}
          </Option>
        )
      })}
    </ul>
  )
}

Select.propTypes = {
  filter: PropTypes.object,
  setFilter: PropTypes.func.isRequired,
  clearFilter: PropTypes.func.isRequired,
}
