import { useState } from 'react'
import toast from 'react-hot-toast'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useMutation } from 'urql'
import { Media, MediaImage } from '../_base/Media'
import { Icon, DeleteIcon, EditIcon } from '../_base/Icon'
import { HeaderSansXS, SmallLetters } from '../_abstracts/Type'
import { down } from 'styled-breakpoints'
import Money from '../data/Money'
import Checkbox from '../shared/Checkbox'
import DeleteListing from './DeleteListing'
import StyledLink from '../_atoms/Link'
import Warning from '../shared/Warning'

const UPDATE_ITEM = `
  mutation UpdateItemPrivacy($id: ID!, $item: ItemUpdateInput!) {
    itemUpdate(id: $id, item: $item) {
      success
      item {
        acceptingOffers
      }
    }
  }
`

const Wrapping = styled.div`
  padding: 13px;

  & + & {
    border-top: 1px solid ${(props) => props.theme.colors.darkGrey};
  }

  ${Media} {
    border: 1px solid ${(props) => props.theme.colors.midGrey};
    padding-bottom: 100%;
  }
`

const Inner = styled.div`
  display: flex;

  ${down('medium')} {
    flex-wrap: wrap;
  }
`

const Image = styled.div`
  padding: 13px;
  min-width: 150px;

  ${down('medium')} {
    order: 1;
    max-width: 150px;
    margin-left: auto;
    margin-right: auto;
  }
`

const Content = styled.div`
  padding: 7px;
  flex-grow: 1;

  ${down('medium')} {
    order: 0;
  }
`

const Top = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  > * {
    margin-bottom: 10px;
  }
`

const Bottom = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`

const Butts = styled.div`
  display: flex;
  margin-left: auto;
  padding-left: 20px;
`

const Action = styled.button`
  margin: 10px;

  &:hover {
    color: ${(props) => props.theme.colors.white};

    circle {
      fill: ${(props) => props.theme.colors.darkBlue};
    }
  }

  ${Icon} {
    width: 24px;
    height: 24px;
  }
`

const Mons = styled.div`
  margin-left: auto;
  padding-left: 20px;

  ${down('small')} {
    width: 100%;
    padding-left: 0;
  }
`

const Item = styled.p`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  ${down('small')} {
    display: inline-flex;
    justify-content: flex-start;

    &:not(:last-child) {
      margin-right: 15px;
    }
  }

  & + & {
    margin-top: 10px;
  }
`

const Label = styled.span`
  ${SmallLetters}
  font-weight: 300;
  display: inline-block;
  padding-right: 10px;
  color: ${(props) => props.theme.colors.darkGrey};
`

const Price = styled.span`
  ${HeaderSansXS}
  font-weight: 700;
`

const Header = styled.h2`
  ${HeaderSansXS}
  font-weight: 700;
  max-width: 379px;
`

export default function Summary({ listing, refetchListings }) {
  const item = listing.item
  const image = item.images?.[0]
  const [, updateItem] = useMutation(UPDATE_ITEM)
  const [acceptingOffers, setAcceptingOffers] = useState(
    listing.item.acceptingOffers
  )

  async function toggleOffersAccepted(acceptingOffers) {
    const updateItemPromise = updateItem({
      id: item.id,
      item: { acceptingOffers },
    })

    const { data, error } = await toast.promise(
      updateItemPromise,
      {
        loading: 'Updating listing…',
        success: `Listing offers ${
          acceptingOffers ? 'allowed' : 'not allowed'
        }`,
        error: 'Unable to update listing',
      },
      { id: 'listing' }
    )

    if (error) {
      console.error(error)
    }

    if (data?.itemUpdate?.item?.acceptingOffers != null) {
      setAcceptingOffers(data.itemUpdate.item.acceptingOffers)
    }
  }

  return (
    <Wrapping>
      <Inner>
        {image && (
          <Image>
            <Media>
              <MediaImage src={image.url} alt={item.title} />
            </Media>
          </Image>
        )}

        <Content>
          <Top>
            <Header>
              <StyledLink to={`/shop/${item.slug}/${item.id}`} $reverse>
                {item.title}
              </StyledLink>
            </Header>

            {listing.approvedAt == null && <Warning text="Pending approval" />}
          </Top>

          <Bottom>
            <Checkbox
              label="Allow offers"
              blueLabel
              checked={acceptingOffers}
              onChange={(checked) => {
                toggleOffersAccepted(checked)
              }}
            />

            <Butts>
              <Action as={Link} to={`/selling/list-item/${item.id}`}>
                <EditIcon />
              </Action>

              <DeleteListing listing={listing} onDelete={refetchListings}>
                <Action type="button">
                  <DeleteIcon />
                </Action>
              </DeleteListing>
            </Butts>

            <Mons>
              <Item>
                <Label>Listed price:</Label>
                <Price>
                  <Money
                    amount={listing.askingPrice.amount}
                    currency={listing.askingPrice.currency.iso}
                  />
                </Price>
              </Item>
            </Mons>
          </Bottom>
        </Content>
      </Inner>
    </Wrapping>
  )
}

Summary.propTypes = {
  listing: PropTypes.shape({
    id: PropTypes.string.isRequired,
    approvedAt: PropTypes.string.isRequired,
    item: PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      images: PropTypes.arrayOf(
        PropTypes.shape({ url: PropTypes.string.isRequired })
      ),
      acceptingOffers: PropTypes.bool.isRequired,
    }).isRequired,
    askingPrice: PropTypes.shape({
      amount: PropTypes.string,
      currency: PropTypes.shape({
        iso: PropTypes.string,
      }),
    }),
  }).isRequired,
  refetchListings: PropTypes.func,
}
