import PropTypes from 'prop-types'
import { useMutation } from 'urql'
import styled from 'styled-components'
import toast from 'react-hot-toast'
import StyledButton from '../_atoms/Button'
import ReturnLink from '../_atoms/ReturnLink'
import { Icon, HeartBareIcon } from '../_base/Icon'
import { UserConsumer } from '../auth/UserContext'
import Popup from '../shared/Popup'

const Wrapper = styled.div`
  border-top: 1px solid ${(props) => props.theme.colors.midGrey};
  margin-top: 15px;
  padding-top: 10px;
`

export const Button = styled.button`
  position: relative;
  cursor: pointer;
  display: block;
  border: 0;
  padding: 0;
  font-size: 14px;

  ${Icon} {
    position: relative;
    top: 2px;
    display: inline-block;
    margin-right: 10px;
    width: 15px;
    color: ${(props) =>
      props.active ? props.theme.colors.accent : props.theme.colors.white};
  }
`

const ADD_TO_WISHLIST = `
  mutation AddToWishist($itemId: ID!) {
    wishlistAdd(input: { itemId: $itemId }) {
      success
    }
  }
`

const REMOVE_FROM_WISHLIST = `
  mutation RemoveFromWishlist($itemId: ID!) {
    wishlistRemove(input: { itemId: $itemId }) {
      success
    }
  }
`

const LoggedInButton = ({ itemId, inViewerWishlist }) => {
  const [, addToWishlist] = useMutation(ADD_TO_WISHLIST)
  const [, removeFromWishlist] = useMutation(REMOVE_FROM_WISHLIST)

  async function handleAddToWishlist() {
    toast('Adding to wishlist…', { id: 'wishlist' })

    const { data } = await addToWishlist({ itemId })

    if (data?.wishlistAdd.success) {
      toast.success('Item added to wishlist', { id: 'wishlist' })
    } else {
      toast.error('Unable to add to wishlist', { id: 'wishlist' })
    }
  }

  async function handleRemoveFromWishlist() {
    toast('Removing from wishlist…', { id: 'wishlist' })

    const { data } = await removeFromWishlist({ itemId })

    if (data?.wishlistRemove.success) {
      toast.success('Item removed from wishlist', { id: 'wishlist' })
    } else {
      toast.error('Unable to remove from wishlist', { id: 'wishlist' })
    }
  }

  return (
    <Wrapper>
      <Button
        active={inViewerWishlist}
        onClick={
          inViewerWishlist ? handleRemoveFromWishlist : handleAddToWishlist
        }
      >
        <HeartBareIcon />
        {inViewerWishlist ? 'Remove from wishlist' : 'Add to wishlist'}
      </Button>
    </Wrapper>
  )
}

LoggedInButton.propTypes = {
  itemId: PropTypes.string.isRequired,
  inViewerWishlist: PropTypes.bool,
}

const LoggedOutButton = () => {
  return (
    <Popup
      trigger={
        <Button aria-label="Add to wishlist">
          <HeartBareIcon />
        </Button>
      }
    >
      <div>
        <p>Log in or register to save to your wishlist</p>
        <ReturnLink to="/login">
          <StyledButton variant="primaryLight">Log in</StyledButton>
        </ReturnLink>
      </div>
    </Popup>
  )
}

export default function WishlistButton(props) {
  return (
    <UserConsumer>
      {({ user }) =>
        user ? <LoggedInButton {...props} /> : <LoggedOutButton {...props} />
      }
    </UserConsumer>
  )
}

WishlistButton.propTypes = {
  itemId: PropTypes.string.isRequired,
  inViewerWishlist: PropTypes.bool,
}
