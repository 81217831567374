import PropTypes from 'prop-types'
import { useMutation } from 'urql'
import toast from 'react-hot-toast'
import Button from '../_atoms/Button'
import { PinIcon } from '../_base/Icon'
import Spacer from '../_helpers/Spacer'
import Alert from '../shared/Alert'
import { CartConsumer, useCartContext } from '../cart/CartContext'
import { REMOVE_FROM_CART, removeItemFromCart } from '../cart/utilities'

const ADD_TO_CART = `
  mutation AddToCart($listingId: ID!, $offerId: ID, $orderId: [ID!]) {
    cartAddListing(listingId: $listingId, offerId: $offerId, orderId: $orderId) {
      success
      message

      cart(orderId: $orderId) {
        orders {
          id
        }
      }
    }
  }
`

export default function ProductCartButton({
  addButtonLabel,
  listingId,
  localPickupOnly,
  offerId,
  inViewerCart,
  removeButtonLabel,
}) {
  const [, addToCart] = useMutation(ADD_TO_CART)
  const [, removeFromCart] = useMutation(REMOVE_FROM_CART)
  let openCartModal = null
  const { openOrderIds, updateOpenOrderIds } = useCartContext()

  async function handleAddToCart() {
    const addToCartPromise = addToCart({
      listingId,
      offerId,
      orderId: openOrderIds,
    })

    toast.promise(
      addToCartPromise,
      {
        loading: 'Adding to cart…',
        success: 'Item added to cart',
        error: 'Unable to add item to cart',
      },
      { id: 'cart' }
    )

    let response
    try {
      response = await addToCartPromise

      if (response.error || response.data?.cartAddListing?.success === false) {
        throw new Error(
          (response.error || response.data?.cartAddListing?.message).toString()
        )
      }
    } catch (error) {
      toast.error(error.message, { id: 'cart' })
      console.error(error)
      return
    }

    if (response?.data?.cartAddListing.success) {
      openCartModal()
    }

    if (response?.data?.cartAddListing?.cart?.orders) {
      const openOrders = response.data.cartAddListing.cart.orders.map(
        (o) => o.id
      )
      updateOpenOrderIds(openOrders)
    }
  }

  return (
    <CartConsumer>
      {({ openModal }) => {
        // Make cart provider's openModal function available in handleAddToCart above
        openCartModal = openModal

        return (
          <>
            {localPickupOnly && (
              <>
                <Alert
                  center
                  icon={<PinIcon />}
                  iconWidth="12px"
                  size="medium"
                  type="info"
                >
                  Local pickup only
                </Alert>
                <Spacer size="10px" />
              </>
            )}

            <Button
              fullWidth
              variant="primaryDark"
              onClick={() => {
                inViewerCart
                  ? removeItemFromCart({
                      listingId,
                      mutation: removeFromCart,
                      openOrderIds,
                      updateOpenOrderIds,
                    })
                  : handleAddToCart()
              }}
            >
              {inViewerCart ? removeButtonLabel : addButtonLabel}
            </Button>
          </>
        )
      }}
    </CartConsumer>
  )
}

ProductCartButton.propTypes = {
  addButtonLabel: PropTypes.node.isRequired,
  listingId: PropTypes.string.isRequired,
  localPickupOnly: PropTypes.bool.isRequired,
  offerId: PropTypes.string,
  inViewerCart: PropTypes.bool.isRequired,
  removeButtonLabel: PropTypes.node.isRequired,
}
