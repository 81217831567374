import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useMutation } from 'urql'
import * as Yup from 'yup'
import IconWrapper from '../shared/IconWrapper'
import Input from '../_atoms/Input'
import Button from '../_atoms/Button'
import Form from '../_atoms/Form'
import { Formik } from 'formik'
import { LockIcon } from '../_base/Icon'

const FORGOT_PASSWORD = `
  mutation ForgotPassword($email: String!) {
    accountForgotPassword(email: $email) {
      success
    }
  }
`

const Schema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
})

export default function ForgottenPasswordPage() {
  const [request, forgotPassword] = useMutation(FORGOT_PASSWORD)
  const [email, setEmail] = useState(null)

  async function handleSubmit(fields) {
    const parsedFields = Schema.cast(fields, { strict: true })
    setEmail(parsedFields.email)

    await forgotPassword({
      email: parsedFields.email,
    })
  }

  useEffect(() => {
    if (request.fetching) {
      toast('Submitting…', { id: 'form' })
    } else if (request.data?.accountForgotPassword.success) {
      toast.success('Password reset request sent', { id: 'form' })
    } else if (
      request.error ||
      request.data?.accountForgotPassword.success === false
    ) {
      toast.error('An error occurred', { id: 'form' })
    }
  }, [request])

  return (
    <IconWrapper header="Forgot password?" icon={<LockIcon />}>
      {(request.data?.accountForgotPassword.success && (
        <>
          {email && (
            <p>
              We sent an email to <strong>{email}</strong>
            </p>
          )}
          <p>
            If you don&apos;t get the email soon, check your spam folder. <br />
            Still need help? Visit our <a href="">Help Centre</a>.
          </p>
          <Button fullWidth submit variant="primaryDark" to="/login">
            Back to login
          </Button>
        </>
      )) || (
        <>
          <p>
            Just enter the email address you registered with <br /> and
            we&apos;ll send you a link to reset your password.
          </p>
          <Formik
            initialValues={{ email: '' }}
            onSubmit={handleSubmit}
            validationSchema={Schema}
          >
            <Form>
              <Input
                label="Email address"
                type="email"
                name="email"
                placeholder="Email address"
                autocomplete="email"
                required
              />
              <Button fullWidth submit variant="primaryDark">
                Submit
              </Button>
            </Form>
          </Formik>
        </>
      )}
    </IconWrapper>
  )
}
