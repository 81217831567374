import { Helmet } from 'react-helmet-async'
import { useLocation, useSearchParams } from 'react-router-dom'
import { useQuery } from 'urql'
import NotFound from '../404/NotFound'
import Loader from '../shared/Loader'
import ShopListingsLayout from './ShopListingsLayout'
import Html from '../data/Html'
import useSearchQuery, {
  DEFAULT_PER_PAGE,
  DEFAULT_SORT,
} from './useSearchQuery'

const GET_PAGE = `
  query GetPage($uri: [String]!) {
    entry(section: "categories", uri: $uri) {
      ancestors {
        id
        title
        url
      }
      id
      title
      url
      slug
      ... on categories_categories_Entry {
        heroTitle
        heroText
        seoTitle
        seoDescription
        sectionLinks {
          ... on sectionLinks_item_BlockType {
            image {
              id
              xs: url(transform: "xs")
              small: url(handle: "small")
              width
              height
              title
            }
            itemLink {
              text
              url
              element {
                ... on categories_categories_Entry {
                  sectionLinkImage {
                    id
                    xs: url(transform: "xs")
                    small: url(handle: "small")
                    width
                    height
                    title
                  }
                }
                ... on collections_default_Entry {
                  sectionLinkImage {
                    id
                    xs: url(transform: "xs")
                    small: url(handle: "small")
                    width
                    height
                    title
                  }
                }
                ... on brands_brands_Entry {
                  sectionLinkImage {
                    id
                    xs: url(transform: "xs")
                    small: url(handle: "small")
                    width
                    height
                    title
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default function ShopCategoryPage() {
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const [{ data, fetching, error }] = useQuery({
    query: GET_PAGE,
    variables: { uri: location.pathname.replace(/^\//, '') },
  })

  const {
    query: { keywords, filters },
    paginationVars: { perPage },
    sort,
  } = useSearchQuery()

  if (fetching) return <Loader background="#fff" />

  if (error) return <p>{error.message}</p>

  if (!data.entry) {
    return <NotFound />
  }

  const { entry } = data

  const brandFilter = filters.find((f) => f.key === 'brand')
  let singleBrand = null

  if (brandFilter) {
    const brands = JSON.parse(brandFilter.value)

    if (brands.length === 1) singleBrand = brands[0]
  }

  let noindex =
    !!keywords ||
    (sort && sort !== DEFAULT_SORT) ||
    (perPage && perPage !== DEFAULT_PER_PAGE) ||
    filters.length > 1 ||
    (filters.length === 1 &&
      (filters[0].key !== 'brand' || filters[0].value.includes('","'))) // Noindex if any filters are set, other than a single brand

  // Noindex the 'Other' category: https://3.basecamp.com/3495499/buckets/20711923/todos/5542968052
  // TODO: Move this to a CMS field to make it possible to manage which categories are noindexed
  if (entry.slug === 'other') {
    noindex = true
  }

  let breadcrumbs = [{ text: entry.title, url: entry.url }]
  if (entry.ancestors?.length) {
    breadcrumbs = [
      ...entry.ancestors.map(({ title, url }) => ({
        text: title,
        url,
      })),
      ...breadcrumbs,
    ]
  }

  let heroTitle = entry.heroTitle || entry.title
  let seoTitle = entry.seoTitle || entry.title

  if (singleBrand) {
    const params = new URLSearchParams({ brand: JSON.stringify([singleBrand]) })

    breadcrumbs.push({
      text: singleBrand,
      url: `${entry.url}?${params.toString()}`,
    })

    heroTitle = `${singleBrand} ${heroTitle}`
    seoTitle = `${singleBrand} ${seoTitle}`
  }

  return (
    <>
      <Helmet>
        {noindex ? (
          <meta name="robots" value="noindex" />
        ) : (
          <link
            rel="canonical"
            href={`${entry.url}${
              filters.length ? `?${searchParams.toString()}` : ''
            }`}
          />
        )}
      </Helmet>
      <ShopListingsLayout
        heroTitle={heroTitle}
        heroText={entry.heroText && <Html html={entry.heroText} />}
        seoTitle={seoTitle}
        seoDescription={entry.seoDescription}
        sectionLinks={entry.sectionLinks}
        hiddenFilters={[{ key: 'category', value: entry.title }]}
        breadcrumbs={breadcrumbs}
      />
    </>
  )
}
