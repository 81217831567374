const GET_GlOBAL_SET = `
  query GetTrade {
    globalSet(handle: "trade") {
      ... on trade_GlobalSet {
        id
        tradeModalTitle
        tradeModalText
        zendeskButtonLabel
        zendeskMessage
      }
    }
  }
`

export default GET_GlOBAL_SET
