import PropTypes from 'prop-types'
import styled from 'styled-components'
import Fieldset from '../forms/Fieldset'
import { down } from 'styled-breakpoints'

const Wrapper = styled.div`
  max-width: 1080px;
  margin-right: auto;
  margin-left: auto;
`

const Inner = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;

  ${down('small')} {
    flex-wrap: wrap;
  }
`

const Image = styled.img`
  height: 120px;
  width: 120px;
  margin-right: 32px;
  flex-shrink: 0;
  object-fit: cover;

  ${down('small')} {
    width: 25%;
    height: auto;
    margin-right: 0;
  }
`

const Content = styled.div`
  margin-right: auto;

  ${down('small')} {
    width: 75%;
    padding-left: 20px;
  }
`

const Title = styled.p`
  margin-bottom: 5px;
  font-size: 14px;
`

export default function SelectedItem({ image, title }) {
  return (
    <Wrapper>
      <Fieldset highlight={true} legend="You have selected" legendAlign="left">
        <Inner>
          {image && <Image src={image.url} alt="Instrument photo" />}
          <Content>
            <Title>{title}</Title>
          </Content>
        </Inner>
      </Fieldset>
    </Wrapper>
  )
}

SelectedItem.propTypes = {
  image: PropTypes.shape({
    url: PropTypes.string,
  }),
  title: PropTypes.string.isRequired,
}
