import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom'
import { useQuery } from 'urql'
import NotFound from '../404/NotFound'
import Html from '../data/Html'
import Loader from '../shared/Loader'
import ShopListingsLayout from './ShopListingsLayout'
import useSearchQuery, {
  DEFAULT_PER_PAGE,
  DEFAULT_SORT,
} from './useSearchQuery'

const GET_PAGE = `
  query GetPage($slug: [String]!) {
    entry(section: "brands", slug: $slug) {
      id
      title
      url
      ... on brands_brands_Entry {
        heroTitle
        heroText
        seoTitle
        seoDescription
        sectionLinks {
          ... on sectionLinks_item_BlockType {
            image {
              id
              xs: url(transform: "xs")
              small: url(handle: "small")
              width
              height
              title
            }
            itemLink {
              text
              url
              element {
                ... on categories_categories_Entry {
                  sectionLinkImage {
                    id
                    xs: url(transform: "xs")
                    small: url(handle: "small")
                    width
                    height
                    title
                  }
                }
                ... on collections_default_Entry {
                  sectionLinkImage {
                    id
                    xs: url(transform: "xs")
                    small: url(handle: "small")
                    width
                    height
                    title
                  }
                }
                ... on brands_brands_Entry {
                  sectionLinkImage {
                    id
                    xs: url(transform: "xs")
                    small: url(handle: "small")
                    width
                    height
                    title
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default function ShopBrandPage() {
  const { slug } = useParams()
  const [{ data, fetching, error }] = useQuery({
    query: GET_PAGE,
    variables: { slug },
  })

  const {
    query: { keywords, filters },
    paginationVars: { perPage },
    sort,
  } = useSearchQuery()

  if (fetching) return <Loader background="#fff" />

  if (error) return <p>{error.message}</p>

  if (!data.entry) {
    return <NotFound />
  }

  const { entry } = data

  const noindex =
    !!keywords ||
    (sort && sort !== DEFAULT_SORT) ||
    (perPage && perPage !== DEFAULT_PER_PAGE) ||
    filters.length > 0

  const breadcrumbs = [{ text: entry.title, url: entry.url }]

  return (
    <>
      <Helmet>
        {noindex ? (
          <meta name="robots" value="noindex" />
        ) : (
          <link rel="canonical" href={entry.url} />
        )}
      </Helmet>
      <ShopListingsLayout
        heroTitle={entry.heroTitle || entry.title}
        heroText={entry.heroText && <Html html={entry.heroText} />}
        seoTitle={entry.seoTitle || entry.title}
        seoDescription={entry.seoDescription}
        sectionLinks={entry.sectionLinks}
        hiddenFilters={[{ key: 'brand', value: JSON.stringify([entry.title]) }]}
        breadcrumbs={breadcrumbs}
      />
    </>
  )
}
