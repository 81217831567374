import { Formik, Form as FormikForm } from 'formik'
import PropTypes from 'prop-types'
import toast from 'react-hot-toast'
import { down, up } from 'styled-breakpoints'
import styled from 'styled-components'
import { useMutation } from 'urql'
import Button, { ButtonStyle } from '../_atoms/Button'
import Input, {
  InputContainer,
  Inner as InputInner,
  InputField,
} from '../_atoms/Input'

const Form = styled(FormikForm)`
  display: flex;
  margin-top: 20px;
  margin-bottom: 10px;

  &:first-child {
    margin-top: 0;
  }

  ${down('xsmall')} {
    flex-wrap: wrap;
  }

  ${InputContainer} {
    display: flex;
    margin: 0;
    flex-grow: 1;

    ${down('xsmall')} {
      margin-bottom: -1px;
    }

    ${up('small')} {
      margin-right: -1px;
    }
  }

  ${InputInner} {
    display: flex;
    flex-grow: 1;
  }

  ${InputField} {
    padding-top: 5px;
    padding-bottom: 5px;
    margin: 0;
    text-align: left;

    &:focus {
      z-index: 1;
    }
  }

  ${InputContainer}:first-child ${InputField} {
    border-bottom-right-radius: 0;

    ${down('xsmall')} {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }

    ${up('small')} {
      border-top-right-radius: 0;
    }
  }

  ${InputContainer}:nth-child(2) ${InputField} {
    border-radius: 0;
  }

  ${ButtonStyle} {
    padding-top: 5px;
    padding-bottom: 5px;
    overflow: visible;
    border-top-left-radius: 0;

    ${down('xsmall')} {
      width: 100%;
      border-top-right-radius: 0;
    }

    ${up('small')} {
      border-bottom-left-radius: 0;
    }
  }
`

const SET_TRACKING = `
mutation SetTracking($id: ID!, $tracking: ShipmentTrackingInput!) {
  shipmentSetTracking(id: $id, tracking: $tracking) {
    success
    shipment {
      id
      trackingNumber
      carrierName
      trackingUrl
    }
  }
}
`

export default function SellingTrackingForm({ shipmentId, values }) {
  const [, setTracking] = useMutation(SET_TRACKING)

  async function onSubmit(values) {
    await setTracking({
      id: shipmentId,
      tracking: values,
    })

    toast.success('Tracking saved')
  }

  return (
    <Formik initialValues={values} onSubmit={onSubmit}>
      {() => {
        return (
          <Form>
            <Input
              name="trackingNumber"
              placeholder="Tracking Number"
              label="Tracking Number"
              hideLabel
            />
            <Input
              name="carrierName"
              placeholder="Carrier Name"
              label="Carrier Name"
              hideLabel
            />
            <Button submit variant="secondary">
              Save
            </Button>
          </Form>
        )
      }}
    </Formik>
  )
}

SellingTrackingForm.propTypes = {
  shipmentId: PropTypes.string.isRequired,
  values: PropTypes.shape({
    trackingNumber: PropTypes.string,
    carrierName: PropTypes.string,
  }).isRequired,
}
