const GET_GlOBAL_SET = `
  query GetFooter {
    globalSet(handle: "footer") {
      ... on footer_GlobalSet {
        id
        footerTitle
        footerText
        footerLink {
          text
          url
        }
        newsletterHeading
        newsletterText
        heading
        textSimple
      }
    }
  }
`

export default GET_GlOBAL_SET
