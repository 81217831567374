import styled from 'styled-components'
import Meta from '../_helpers/Meta'
import { useParams } from 'react-router-dom'
import { useQuery } from 'urql'
import PropTypes from 'prop-types'

import HeaderDetail from '../portfolio/HeaderDetail'
import ProductCarousel from '../shared/ProductCarousel'
import Container from '../_helpers/Container'
import ListingTabs from '../shop/ListingTabs'
import Back from '../shared/Back'
import NotFound from '../404/NotFound'
import Loader from '../shared/Loader'
import RecentlyViewedCarousel from '../shared/RecentlyViewedCarousel'

import { useCartContext } from '../cart/CartContext'

const Wrapper = styled.div`
  padding-bottom: 50px;
  padding-top: 25px;
`

const Inner = styled.div`
  max-width: 800px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 50px;

  > * + * {
    margin-top: 25px;
  }
`

const Interstitial = styled.div`
  text-align: center;
`

const navOptions = {
  asNavFor: '.carousel-main',
  contain: true,
  pageDots: false,
  prevNextButtons: false,
  wrapAround: true,
  initialIndex: 1,
  groupCells: 5,
}

const ITEM_QUERY = `
  query ShopItem($itemId: ID!, $orderId: [ID!]) {
    item(id: $itemId) {
      id
      slug
      title
      madeInYear
      description

      images {
        id
        url(size: "product")
        thumb: url(size: "cart")
        dimensions {
          width
          height
        }
      }

      account {
        id
        username
        bio
        isPrivateSeller
        isProfessionalSeller
      }

      brand { id title }
      model { id title }
      handedness
      madeInCountry {
        name
      }
      materials {
        body { id title }
        neck { id title }
        fretboard { id title }
        top { id title }
        back { id title }
      }

      listing {
        id
        askingPrice {
          amount
          currency { iso }
        }
        inViewerCart(orderId: $orderId)
        isAvailable
      }

      acceptingOffers
      offerDeclineThreshold
    }
  }
`

const GET_TOTAL_ITEMS = `
  query GetTotalItems($accountId: ID!) {
    listings(accountId: $accountId) {
      pageInfo {
        hasNextPage
      }

      edges {
        node {
          id
        }
      }
    }
  }
`

export default function PublicProfilePortfolioItemPage() {
  const { itemId } = useParams()
  const { openOrderIds } = useCartContext()

  const [{ fetching, data, error }] = useQuery({
    query: ITEM_QUERY,
    variables: { itemId, orderId: openOrderIds },
  })

  if (fetching) return <Loader background="#fff" />
  if (error) return <p>{error.message}</p>

  const { item } = data
  if (!item) return <NotFound />

  let handedness
  switch (data.item.handedness) {
    case 'LEFT_HANDEDNESS':
      handedness = 'Left'
      break
    case 'RIGHT_HANDEDNESS':
      handedness = 'Right'
      break
  }

  const spec = {
    Brand: data.item.brand,
    Model: data.item.model,
    Handedness: handedness,
    'Made In Year': data.item.madeInYear,
    'Made In Country': data.item.madeInCountry,
    'Body Material': data.item.materials.body?.title || undefined,
    'Neck Material': data.item.materials.neck?.title || undefined,
    'Fretboard Material': data.item.materials.fretboard?.title || undefined,
    'Top Material': data.item.materials.top?.title || undefined,
    'Back Material': data.item.materials.back?.title || undefined,
  }

  const detail = {
    description: data.item.description,
    spec,
    url: `${process.env.RAZZLE_BASE_FRONTEND_URL}/shop/${item.slug}/${data.item.id}`,
    imgUrl: data.item.images[0]?.url,
    title: data.item.title,
    shipping: {
      countries: [],
    },
  }

  const TotalItems = ({ accountId }) => {
    const [{ data }] = useQuery({
      query: GET_TOTAL_ITEMS,
      variables: { accountId },
    })

    let total = data?.listings?.edges?.length || 0
    if (total && data?.listings?.pageInfo?.hasNextPage) {
      total += '+'
    }

    return total
  }

  TotalItems.propTypes = {
    accountId: PropTypes.string.isRequired,
  }

  return (
    <>
      <Meta title={data.item.title} />

      <Container>
        <Wrapper>
          <Interstitial>
            <Back
              header="Back to portfolio"
              url={`/profile/${data.item.account.username}/portfolio`}
              background="transparent"
            />
          </Interstitial>

          <HeaderDetail
            heading={data.item.title}
            user={{
              username: data.item.account.username,
              id: data.item.account.id,
            }}
            items={<TotalItems accountId={data.item.account.id} />}
            location={data.item.madeInCountry?.name}
            listing={data.item.listing}
            valuation={null} // Todo: Hook up
            item={data.item}
          />

          <Inner>
            {data.item.images.length ? (
              <ProductCarousel
                navOptions={navOptions}
                images={data.item.images}
              />
            ) : null}

            <ListingTabs item={detail} />

            <Interstitial>
              <Back
                header="Back to portfolio"
                url={`/profile/${data.item.account.username}/portfolio`}
                background="transparent"
              />
            </Interstitial>
          </Inner>
        </Wrapper>
      </Container>

      <RecentlyViewedCarousel theme="grey" />
    </>
  )
}
