import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useQuery } from 'urql'
import { BodyS } from '../_abstracts/Type'
import LineChart from '../data/LineChart'
import { formatMoney } from '../data/Money'
import Loader from '../shared/Loader'
import { format } from 'date-fns'

const GET_VALUE_HISTORY = `
  query GetValueHistory($currency: String) {
    itemPortfolioValue(currency: $currency) {
      userCurrentValue {
        amountCents
        currency { iso }
      }
      userEstimateHistory {
        date
        amount {
          amountCents
          currency { iso }
        }
      }
    }
  }
`

const Wrapper = styled.div`
  border: 1px solid ${(props) => props.theme.colors.midGrey};
  border-radius: ${(props) => props.theme.radii};
  color: ${(props) => props.theme.colors.black};
`

const Group = styled.div`
  position: relative;
`

const Top = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const FullWidthItem = styled.div`
  ${BodyS}
  flex-basis: 100%;
  padding: 15px 20px 10px;
`

const Mid = styled.div`
  padding: 15px;

  canvas {
    max-width: 100%;
  }
`

const Message = styled.p`
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
`

const decimalPlaces = (value) => {
  if (Math.floor(value) === value) return 0
  return value.toString().split('.')[1].length || 0
}

const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    tooltip: {
      backgroundColor: '#ECF9F9',
      bodyColor: '#066464',
      borderColor: '#066464',
      bodyAlign: 'center',
      displayColors: false,
      xAlign: 'center',
      yAlign: 'bottom',
      borderWidth: 1,
      padding: {
        x: 7,
        y: 3,
      },
      callbacks: {
        title: () => '',
        label: (ctx) =>
          formatMoney(
            ctx.raw.data.amount.amountCents / 100,
            ctx.raw.data.amount.currency.iso
          ),
      },
    },
  },
  scales: {
    x: {
      grid: {
        lineWidth: 0,
      },
    },
    y: {
      ticks: {
        callback: (val) => {
          let formatted = val / 1000
          if (decimalPlaces(formatted) > 2) formatted = formatted.toFixed(2)
          return formatted.toString().replace(/[.,]00$/, '') + 'K'
        },
      },
    },
  },
}

function parseISOLocal(s) {
  var b = s.split(/\D/)
  return new Date(b[0], b[1] - 1, b[2], b[3], b[4], b[5])
}

const GraphContent = ({ noDataMessage, explainerText, setCurrentValue }) => {
  const [{ data, fetching, error }] = useQuery({
    query: GET_VALUE_HISTORY,
    variables: {
      currency: 'USD',
    },
  })

  const currentValue = data?.itemPortfolioValue?.userCurrentValue
  const valueHistory = data?.itemPortfolioValue?.userEstimateHistory || []

  useEffect(() => {
    if (currentValue && setCurrentValue) {
      setCurrentValue(currentValue)
    }
  }, [currentValue, setCurrentValue])

  if (error) return <p>{error.message}</p>

  const chartData = {
    datasets: [
      {
        data: valueHistory.map((value) => ({
          y: value.amount.amountCents / 100,
          x: format(parseISOLocal(value.date), 'MMM yyyy'),
          data: value,
        })),
        borderColor: 'rgb(0, 0, 0)',
        backgroundColor: 'rgba(255, 255, 255)',
      },
    ],
  }

  return (
    <Wrapper>
      <Group>
        <Top>
          {valueHistory.length > 0 && explainerText && (
            <FullWidthItem>
              <p>{explainerText}</p>
            </FullWidthItem>
          )}
        </Top>

        <Mid>
          {(fetching && <Loader background="#fff" height="100px" />) ||
            (valueHistory.length ? (
              <LineChart data={chartData} options={options} />
            ) : (
              <Message>{noDataMessage || '-'}</Message>
            ))}
        </Mid>
      </Group>
    </Wrapper>
  )
}

GraphContent.propTypes = {
  noDataMessage: PropTypes.string,
  explainerText: PropTypes.string,
  setCurrentValue: PropTypes.func,
}

export default function Graph(props) {
  const [showContent, setShowContent] = useState(false)

  // Wait until after client-side hydration to show, as line-chart doesn't render when using SSR
  useEffect(() => {
    setShowContent(true)
  }, [setShowContent])

  if (!showContent) {
    return <Loader height="100px" background="#fff" />
  }

  return <GraphContent {...props} />
}

Graph.propTypes = {
  noDataMessage: PropTypes.string,
  explainerText: PropTypes.string,
  setCurrentValue: PropTypes.func,
}
