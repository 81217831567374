import PropTypes from 'prop-types'
// import { useState } from 'react'
import { Formik, Field } from 'formik'
import { Button } from './Search'

import Input, { InputContainer } from '../_atoms/Input'

import styled, { css } from 'styled-components'

const Inputs = styled.div`
  display: flex;

  p {
    line-height: 0.5em;
  }

  ${InputContainer} {
    margin-bottom: 0;
  }
`

const Separator = styled.span`
  align-self: center;
  margin-right: 5px;
  margin-left: 5px;
`

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  margin-top: 9px;
  height: 16px;
  display: flex;
  align-items: center;
`

const Slider = styled.div`
  width: 100%;
  position: relative;
`

const ThumbStyles = css`
  display: block;
  height: 16px;
  width: 16px;
  border: 2px solid ${(props) => props.theme.colors.darkBlue};
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.white};
  cursor: pointer;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
`

const Thumb = styled(Field)`
  pointer-events: none;
  position: absolute;
  height: 0;
  width: 100%;
  outline: none;

  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;

  //chrome
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
    ${ThumbStyles}
  }
  //safari
  &::-moz-range-thumb {
    ${ThumbStyles}
  }
  //IE
  &::-ms-thumb {
    ${ThumbStyles}
  }
`

const ThumbMin = styled(Thumb)`
  z-index: 3;
`

const ThumbMax = styled(Thumb)`
  z-index: 4;
`

const SliderTrack = styled.div`
  border-radius: 3px;
  height: 4px;
  position: absolute;
  background-color: ${(props) => props.theme.colors.darkBlue};
  width: 100%;
  z-index: 1;

  span {
    position: absolute;
    top: 0;
    height: 8px;
    width: 1px;
    background-color: ${(props) => props.theme.colors.darkBlue};
  }
  span:nth-child(1) {
    left: 25%;
  }
  span:nth-child(2) {
    left: 50%;
  }
  span:nth-child(3) {
    left: 75%;
  }
`
const SliderRange = styled.div`
  border-radius: 3px;
  height: 4px;
  position: absolute;
  background-color: ${(props) => props.theme.colors.darkBlue};
  z-index: 2;
`

export default function Range({ filter, setFilter }) {
  return (
    <Formik
      initialValues={{
        min:
          filter.key === 'price'
            ? filter.selectedMin / 100
            : filter.selectedMin,
        max:
          filter.key === 'price'
            ? filter.selectedMax / 100
            : filter.selectedMax,
      }}
    >
      {({ setFieldValue, values }) => (
        <>
          <Inputs>
            <Input
              type="number"
              name="min"
              min={filter.key === 'price' ? filter.min / 100 : filter.min}
              max={Number(values.max)}
              label={`Min ${filter.key}`}
              prefix={filter.key === 'price' ? '$' : null}
              hideLabel
              placeholder={(filter.key === 'price'
                ? filter.min / 100
                : filter.min
              ).toString()}
            />
            <Separator>-</Separator>
            <Input
              type="number"
              name="max"
              min={Number(values.min)}
              max={filter.key === 'price' ? filter.max / 100 : filter.max}
              label={`Max ${filter.key}`}
              prefix={filter.key === 'price' ? '$' : null}
              hideLabel
              placeholder={(filter.key === 'price'
                ? filter.max / 100
                : filter.max
              ).toString()}
            />
            <Button
              ariaLabel="Search years"
              onClick={() => {
                if (filter.key === 'price') {
                  setFilter(
                    filter.key,
                    JSON.stringify([values.min * 100, values.max * 100])
                  )
                } else {
                  setFilter(
                    filter.key,
                    JSON.stringify([values.min, values.max])
                  )
                }
              }}
            />
          </Inputs>
          <Wrapper>
            <ThumbMin
              type="range"
              min={filter.key === 'price' ? filter.min / 100 : filter.min}
              max={filter.key === 'price' ? filter.max / 100 : filter.max}
              value={values.min}
              onChange={(ev) => {
                const value = Number(ev.target.value)
                if (value > values.max) return false
                setFieldValue('min', Number(ev.target.value))
              }}
            />
            <ThumbMax
              type="range"
              min={filter.key === 'price' ? filter.min / 100 : filter.min}
              max={filter.key === 'price' ? filter.max / 100 : filter.max}
              value={values.max}
              onChange={(ev) => {
                const value = Number(ev.target.value)
                if (value < values.min) return false
                setFieldValue('max', Number(ev.target.value))
              }}
            />
            <Slider>
              <SliderTrack />
              <SliderRange />
            </Slider>
          </Wrapper>
        </>
      )}
    </Formik>
  )
}

Range.propTypes = {
  filter: PropTypes.shape({
    key: PropTypes.string.isRequired,
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    selectedMin: PropTypes.number,
    selectedMax: PropTypes.number,
  }),
  setFilter: PropTypes.func.isRequired,
}
