import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useMutation } from 'urql'
import { Formik } from 'formik'
import Input from '../_atoms/Input'
import Button from '../_atoms/Button'
import toast from 'react-hot-toast'

const CREATE_PAYMENT = `
  mutation CreateManualPayment($orderId: ID!, $amountCents: Int, $manualPaymentType: String!) {
    orderCreateManualPayment(id: $orderId, amountCents: $amountCents, manualPaymentType: $manualPaymentType) {
      success
      paymentId
    }
  }
`

const Select = styled.select`
  box-sizing: border-box;
  appearance: auto;
  border: 1px solid #efefef;
  border-radius: 3px;
  width: calc(100% - 32px);
  margin: 16px;
`

// Manual Payment Methods referenced at order/src/helpers/quickbooks.ts
const manualTypes = ['Wire', 'Cash', 'Check', 'Stripe Link', 'PayPal Link']

export default function ManualPaymentButton({
  orderId,
  remainingAmountCents,
  taxCents,
  setError,
  onSuccess,
}) {
  const [, createPayment] = useMutation(CREATE_PAYMENT)
  const [amountCents, setAmountCents] = useState(remainingAmountCents)
  const [remainingAfterCents, setRemainingAfterCents] = useState(0)
  const [processing, setProcessing] = useState(false)
  const [currentManualType, setCurrentManualType] = useState(false)
  const [useCustomManualType, setUseCustomManualType] = useState(null)

  useEffect(() => {
    setAmountCents(remainingAmountCents)
  }, [remainingAmountCents])

  async function handleClick(event) {
    event.preventDefault()

    if (!currentManualType) {
      toast.error('You must choose a type for manual payments')
      return
    }

    if (processing) return

    setProcessing(true)
    setError(null)

    const payment = await createPayment({
      orderId,
      amountCents,
      manualPaymentType: currentManualType,
    })

    if (!payment.data?.orderCreateManualPayment?.success) {
      setError('Unable to create manual payment.')
      setProcessing(false)

      return
    }

    setError(null)

    const result = await onSuccess(
      payment.data.orderCreateManualPayment.paymentId
    )

    if (result?.endProcessing) setProcessing(false)
  }

  return (
    <Formik initialValues={{}}>
      <>
        <Input
          type="number"
          min={0}
          max={remainingAmountCents / 100}
          label="Manual price override"
          prefix="$"
          /* TODO: This will need a slight change for some currencies */
          step="0.01"
          value={amountCents / 100}
          onChange={(event) => {
            const proposedAmountCents = Math.round(
              parseFloat(event.target.value) * 100
            )
            const remainingAfterCents =
              remainingAmountCents - proposedAmountCents
            setRemainingAfterCents(remainingAfterCents)
            setAmountCents(proposedAmountCents)
          }}
        />

        <Select
          required
          onChange={(event) => {
            const manualType = event.currentTarget.value

            if (!manualType) return

            setCurrentManualType(manualType)

            if (manualTypes.includes(manualType)) {
              setUseCustomManualType(false)
            } else {
              setUseCustomManualType(true)
            }
          }}
        >
          <option value="">Select manual payment type</option>
          {manualTypes.map((ty) => (
            <option key={ty} value={ty}>
              {ty}
            </option>
          ))}
          <option value="other">Other</option>
        </Select>

        {useCustomManualType && (
          <Input
            label="Custom manual payment type"
            onChange={(event) =>
              setCurrentManualType(event.currentTarget.value)
            }
          />
        )}

        {remainingAfterCents > 0 && remainingAfterCents < taxCents ? (
          <p>Cannot leave less than the tax amount in an order</p>
        ) : (
          <Button
            variant="primaryDark"
            disabled={processing}
            onClick={handleClick}
          >
            {processing ? 'Processing...' : 'Create manual payment'}
          </Button>
        )}
      </>
    </Formik>
  )
}

ManualPaymentButton.propTypes = {
  orderId: PropTypes.string.isRequired,
  remainingAmountCents: PropTypes.number,
  taxCents: PropTypes.number,
  setError: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
}
