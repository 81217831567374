import { useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Toaster } from 'react-hot-toast'
import { resetIdCounter } from 'downshift'
import { resetId } from 'react-id-generator'
import { Outlet, Navigate, Route, Routes } from 'react-router-dom'
import { Provider as UrqlProvider } from 'urql'
import TagManager from 'react-gtm-module'
import { ThemeProvider } from 'styled-components'

import FontLinks from './shared/FontLinks'
import ClientOnlyPortal from './shared/ClientOnlyPortal'
import GlobalStyles from './_abstracts/GlobalStyles'
import theme from './theme'
import { UserProvider, UserConsumer } from './auth/UserContext'
import { CartProvider } from './cart/CartContext'
import MainLayout from './layouts/MainLayout'
import Home from './Home'
import LegalPage from './legal/Page'
import Pages from './pages/Page'
import BlogPage from './blog/BlogPage'
import BlogIndexPage from './blog/BlogIndexPage'

import ShopIndexPage from './shop/ShopIndexPage'
import ShopCategoryPage from './shop/ShopCategoryPage'
import ShopBrandPage from './shop/ShopBrandPage'
import ShopCollectionPage from './shop/ShopCollectionPage'
import ProductPage from './shop/ProductPage'
import LoginRegisterPage from './auth/LoginRegisterPage'

import CheckoutLayout from './checkout/CheckoutLayout'
import CheckoutShippingPage from './checkout/CheckoutShippingPage'
import CheckoutPaymentPage from './checkout/CheckoutPaymentPage'
import CheckoutConfirmationPage from './checkout/CheckoutConfirmationPage'
import StripeOnboarding from './onboarding/StripeOnboarding'
import PayPalOnboarding from './onboarding/PayPalOnboarding'

import ProfileLayout from './profile/ProfileLayout'
import ProfileOverviewPage from './profile/ProfileOverviewPage'
import ProfilePortfolioIndexPage from './profile/ProfilePortfolioIndexPage'
import ProfilePortfolioItemPage from './profile/ProfilePortfolioItemPage'
import ProfilePortfolioAddItemPage from './portfolio/AddItemPage'
import ProfilePortfolioEditItemPage from './portfolio/EditItemPage'
import ProfileSettingsDetailsPage from './account/settings/DetailsPage'
import ProfileSettingsAddressesPage from './account/settings/AddressesPage'

import ProfileSettingPaymentPage from './account/settings/PaymentPage'
import ProfileSettingBillingPage from './account/settings/BillingPage'
import ProfileSettingBillingStatementPage from './account/settings/BillingStatementPage'
import ProfileSettingRefundStatementPage from './account/settings/RefundStatementPage'
import ProfileSettingNotificationsPage from './account/settings/NotificationsPage'

import ShippingMethodsIndexPage from './shipping/MethodsIndexPage'
import ShippingMethodsNewPage from './shipping/MethodsNewPage'
import ShippingMethodsEditPage from './shipping/MethodsEditPage'

import PublicProfileLayout from './profile/PublicProfileLayout'
import PublicProfilePortfolioIndexPage from './profile/PublicProfilePortfolioIndexPage'
import PublicProfilePortfolioItemPage from './profile/PublicProfilePortfolioItemPage'
import PublicProfileListingsPage from './profile/PublicProfileListingsPage'

import AccountInterestsPage from './account/InterestsPage'
import WelcomePage from './account/WelcomePage'

import SellingListItemPage from './selling/ListItemPage'
import CartPage from './cart/CartPage'

import ForgottenPasswordPage from './auth/ForgottenPasswordPage'
import PasswordResetPage from './auth/PasswordResetPage'
import HelpCenterIndexPage from './help/HelpCenterIndexPage'
import HelpCenterEntryPage from './help/HelpCenterEntryPage'
import HelpCenterSearchPage from './help/HelpCenterSearchPage'

import ContactPage from './contact/ContactPage'

import NotFound from './404/NotFound'

import ProfileSellingListingsPage from './selling/SellingListingsPage'
import SellingOffersPage from './selling/SellingOfferPage'
import SellingOrdersPage from './selling/SellingOrdersPage'
import SellingNewItemPage from './selling/SellingNewItemPage'
import ProfileBuyingPurchasesPage from './buying/BuyingPurchasesPage'
import ProfileBuyingOffersPage from './buying/BuyingOffersPage'
import OrderDetailsPage from './buying/OrderDetailsPage'

import MyFeedStartPage from './feed/MyFeedStartPage'
import MyFeedIndexPage from './feed/MyFeedIndexPage'
import MyFeedCustomizePage from './feed/MyFeedCustomizePage'
import WishlistFeedPage from './feed/WishlistFeedPage'
import RecentlyViewedPage from './feed/RecentlyViewedPage'

import MessagesLayout from './messaging/MessagesLayout'
import MessagesInboxPage from './messaging/MessagesInboxPage'
// import MessagesUnreadPage from './messaging/MessagesUnreadPage'
// import MessagesSavedPage from './messaging/MessagesSavedPage'
// import MessagesOffersPage from './messaging/MessagesOffersPage'
import MessagesComposePage from './messaging/MessagesComposePage'
import MessagesViewPage from './messaging/MessagesViewPage'
import { LoginReturnNavigate } from './_atoms/ReturnLink'
import ZendeskConfig from './shared/ZendeskConfig'
import { Helmet } from 'react-helmet-async'

export default function App({ createUrqlClient }) {
  const [urqlClientVersion, setUrqlClientVersion] = useState(Date.now())

  function refreshUrqlClient() {
    setUrqlClientVersion(Date.now())
  }

  const urqlClient = useMemo(() => {
    urqlClientVersion // little no-op hack to stop eslint moaning about it not being used
    return createUrqlClient(refreshUrqlClient)
  }, [urqlClientVersion, createUrqlClient])

  // Fix generated element IDs not matching between server + client: https://github.com/Tomekmularczyk/react-id-generator#resetid
  // This can probably be replaced with the built-in useId hook in React 18
  resetId()

  // Also reset Downshift's ID counter for SSR compatibility
  resetIdCounter()

  useEffect(() => {
    TagManager.initialize({
      gtmId: 'GTM-PV7Q4WQ',
      auth: 'EKLii8TrDnrml5Y1_Ewy9A',
      preview: 'env-1',
    })
  }, [])

  return (
    <>
      <Helmet>
        <script
          async
          type="text/javascript"
          src="https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=Un5QYE"
        />
      </Helmet>
      <UrqlProvider value={urqlClient}>
        <UserProvider refreshUrqlClient={refreshUrqlClient}>
          <ThemeProvider theme={theme}>
            <CartProvider>
              <FontLinks />
              <GlobalStyles />
              <MainLayout>
                <Routes>
                  <Route index element={<Home />} />

                  <Route element={<LoggedInRoute />}>
                    <Route path="checkout" element={<CheckoutLayout />}>
                      <Route index element={<Navigate to="shipping" />} />
                      <Route
                        path="shipping"
                        element={<CheckoutShippingPage />}
                      />
                      <Route path="payment" element={<CheckoutPaymentPage />} />
                      <Route
                        path="confirmation"
                        element={<CheckoutConfirmationPage />}
                      />
                    </Route>

                    <Route
                      path="my-saved-searches"
                      element={<MyFeedIndexPage />}
                    />
                    <Route
                      path="my-saved-searches/customize"
                      element={<MyFeedCustomizePage />}
                    />
                    <Route
                      path="my-saved-searches/start"
                      element={<MyFeedStartPage />}
                    />

                    <Route
                      path="onboarding/stripe"
                      element={<StripeOnboarding />}
                    />
                    <Route
                      path="onboarding/paypal"
                      element={<PayPalOnboarding />}
                    />

                    <Route path="messages" element={<MessagesLayout />}>
                      <Route index element={<MessagesInboxPage />} />
                      {/* <Route path="unread" element={<MessagesUnreadPage />} />
                      <Route path="saved" element={<MessagesSavedPage />} />
                      <Route path="offers" element={<MessagesOffersPage />} /> */}
                      <Route path="compose" element={<MessagesComposePage />} />
                      <Route
                        path=":messageThreadId"
                        element={<MessagesViewPage />}
                      />
                    </Route>
                  </Route>

                  <Route path="privacy" element={<LegalPage />} />
                  <Route path="terms-and-conditions" element={<LegalPage />} />
                  <Route
                    path="selling-terms-and-conditions"
                    element={<LegalPage />}
                  />
                  <Route path="cookies" element={<LegalPage />} />
                  <Route element={<LoggedInRoute />}>
                    <Route path="wishlist" element={<WishlistFeedPage />} />
                    <Route
                      path="recently-viewed"
                      element={<RecentlyViewedPage />}
                    />
                  </Route>

                  <Route path="shop" element={<ShopIndexPage />} />
                  <Route path="shop/:slug/:itemId" element={<ProductPage />} />
                  <Route
                    path="shop/category/:parentCategory"
                    element={<ShopCategoryPage />}
                  />
                  <Route
                    path="shop/category/:parentCategory/:childCategory"
                    element={<ShopCategoryPage />}
                  />
                  <Route path="shop/brand/:slug" element={<ShopBrandPage />} />
                  <Route
                    path="shop/collection/:slug"
                    element={<ShopCollectionPage />}
                  />
                  <Route path="login" element={<LoginRegisterPage />} />
                  <Route path="contact" element={<ContactPage />} />
                  <Route
                    path="forgotten-password"
                    element={<ForgottenPasswordPage />}
                  />
                  <Route
                    path="reset-password/:passwordResetCode/:passwordResetToken"
                    element={<PasswordResetPage />}
                  />
                  <Route path="selling" element={<SellingNewItemPage />} />

                  <Route path=":slug" element={<Pages />} />

                  <Route path="blog" element={<BlogIndexPage />} />
                  <Route
                    path="blog/page/:currentPage"
                    element={<BlogIndexPage />}
                  />
                  <Route path="blog/:slug" element={<BlogPage />} />

                  <Route
                    path="profile/portfolio/new"
                    element={<ProfilePortfolioAddItemPage />}
                  />
                  <Route
                    path="profile/portfolio/:itemId/edit"
                    element={<ProfilePortfolioEditItemPage />}
                  />
                  <Route path="profile" element={<ProfileLayout />}>
                    <Route index element={<ProfileOverviewPage />} />
                    <Route
                      path="portfolio"
                      element={<ProfilePortfolioIndexPage />}
                    />
                    <Route
                      path="portfolio/:itemId"
                      element={<ProfilePortfolioItemPage />}
                    />
                    <Route
                      path="selling"
                      element={<Navigate to="listings" />}
                    />
                    <Route
                      path="selling/listings"
                      element={<ProfileSellingListingsPage />}
                    />
                    <Route
                      path="selling/offers"
                      element={<SellingOffersPage />}
                    />
                    <Route
                      path="selling/orders"
                      element={<SellingOrdersPage />}
                    />
                    <Route
                      path="buying"
                      element={<Navigate to="purchases" />}
                    />
                    <Route
                      path="buying/purchases"
                      element={<ProfileBuyingPurchasesPage />}
                    />
                    <Route
                      path="buying/purchases/page/:currentPage"
                      element={<ProfileBuyingPurchasesPage />}
                    />
                    <Route
                      path="buying/offers"
                      element={<ProfileBuyingOffersPage />}
                    />
                    <Route
                      path="settings/details"
                      element={<ProfileSettingsDetailsPage />}
                    />
                    <Route
                      path="settings/addresses"
                      element={<ProfileSettingsAddressesPage />}
                    />

                    <Route
                      path="settings/invoices"
                      element={<ProfileSettingBillingPage />}
                    />
                    <Route
                      path="settings/invoices/:orderId"
                      element={<ProfileSettingBillingStatementPage />}
                    />
                    <Route
                      path="settings/refunds/:refundId"
                      element={<ProfileSettingRefundStatementPage />}
                    />
                    <Route
                      exact
                      path="settings/payment"
                      element={<ProfileSettingPaymentPage />}
                    />
                    <Route
                      path="settings/notifications"
                      element={<ProfileSettingNotificationsPage />}
                    />
                    <Route
                      path="settings/shipping"
                      element={<ShippingMethodsIndexPage />}
                    />
                    <Route
                      path="settings/shipping/new"
                      element={<ShippingMethodsNewPage />}
                    />
                    <Route
                      path="settings/shipping/:shippingMethodRef"
                      element={<ShippingMethodsEditPage />}
                    />
                  </Route>

                  <Route element={<LoggedInRoute />}>
                    <Route
                      path="orders/:orderId"
                      element={<OrderDetailsPage />}
                    />
                  </Route>

                  <Route
                    path="profile/:username"
                    element={<PublicProfileLayout />}
                  >
                    <Route index element={<Navigate to="listings" />} />
                    <Route
                      path="portfolio"
                      element={<PublicProfilePortfolioIndexPage />}
                    />
                    <Route
                      path="listings"
                      element={<PublicProfileListingsPage />}
                    />
                  </Route>
                  <Route
                    path="/item/:itemId"
                    element={<PublicProfilePortfolioItemPage />}
                  />

                  <Route
                    path="welcome/interests"
                    element={<AccountInterestsPage />}
                  />

                  <Route
                    path="welcome/saved-searches"
                    element={<WelcomePage />}
                  />

                  <Route
                    path="selling/list-item/resell/:itemId"
                    element={<SellingListItemPage source="resell" />}
                  />
                  <Route
                    path="selling/list-item/new"
                    element={<SellingListItemPage source="new" />}
                  />
                  <Route
                    path="selling/list-item/:itemId"
                    element={<SellingListItemPage source="portfolio" />}
                  />
                  <Route
                    path="selling/list-item"
                    element={<Navigate to="new" replace />}
                  />
                  <Route path="help-center" element={<HelpCenterIndexPage />} />
                  <Route
                    path="help-center/:section"
                    element={<HelpCenterIndexPage />}
                  />
                  <Route
                    path="help-center/:section/:category"
                    element={<HelpCenterEntryPage />}
                  />
                  <Route
                    path="help-center/:section/:category/:slug"
                    element={<HelpCenterEntryPage />}
                  />
                  <Route
                    path="help-center/search"
                    element={<HelpCenterSearchPage />}
                  />
                  <Route path="cart" element={<CartPage />} />
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </MainLayout>
              <ClientOnlyPortal>
                <Toaster />
              </ClientOnlyPortal>
            </CartProvider>
          </ThemeProvider>
          <ZendeskConfig />
        </UserProvider>
      </UrqlProvider>
    </>
  )
}

function LoggedInRoute() {
  return (
    <UserConsumer>
      {({ user }) => {
        return user ? <Outlet /> : <LoginReturnNavigate />
      }}
    </UserConsumer>
  )
}

App.propTypes = {
  createUrqlClient: PropTypes.func.isRequired,
}
