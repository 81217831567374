import { useEffect, useRef, useState } from 'react'
import { formatPhoneNumberIntl } from 'react-phone-number-input'
import PropTypes from 'prop-types'
import { up } from 'styled-breakpoints'
import styled from 'styled-components'
import { useQuery } from 'urql'
import { HeaderM, linkStates } from '../_abstracts/Type'
import Button, { ButtonStyle } from '../_atoms/Button'
import AddressForm from '../forms/AddressForm'
import Radio, { RadioLabel } from '../inputs/Radio'
import ModalBase from '../shared/Modal'
import Loader from './Loader'
import LocalPickup from '../checkout/LocalPickup'

const GET_ADDRESSES = `
  query GetAddresses($after: String, $accountId: ID) {
    addresses(first: 10, after: $after, accountId: $accountId) {
      pageInfo {
        hasNextPage
        endCursor
      }

      edges {
        node {
          id
          firstName
          lastName
          country {
            name
            iso
          }
          line1
          line2
          city
          region {
            name
          }
          postalCode
          phone
        }
      }
    }
  }
`

export const Wrapper = styled.div`
  max-width: 900px;
  padding-top: 30px;
  padding-bottom: 30px;
  margin-right: auto;
  margin-left: auto;
`

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: -10px;
`

const Item = styled.li`
  width: calc(100% - 20px);
  margin: 10px;
  display: flex;
  flex-direction: column;
  font-size: 15px;
  border: 1px solid ${({ theme }) => theme.colors.midGrey};
  border-radius: ${({ theme }) => theme.radii};

  ${up('medium')} {
    width: calc(50% - 20px);
  }
`

const Address = styled.address`
  flex-grow: 1;
  padding: 10px 16px;
`

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 16px;
  background-color: ${({ theme }) => theme.colors.lightGrey};
  border-top: 1px solid ${({ theme }) => theme.colors.midGrey};
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;

  ${RadioLabel} {
    font-size: inherit;
  }
`

const Edit = styled.button.attrs({ type: 'button' })`
  ${linkStates};
`

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px -10px -10px;

  &:first-child {
    margin-top: -10px;
  }

  ${ButtonStyle} {
    margin: 10px;
  }

  ${List}:empty + & {
    margin-top: 10px;
  }
`

const ModalHeading = styled.h3`
  ${HeaderM};
  margin-bottom: 20px;
  font-weight: 600;
`

const ModalInner = styled.div`
  padding: 24px;
`

const Message = styled.p`
  text-align: center;
`

const Items = ({
  inputName,
  items,
  onSelect,
  accountId,
  onAddressesChange,
}) => {
  const [addresses, setAddresses] = useState(items)
  const modalRef = useRef({})

  // Update an address's details on this page
  const updateAddress = (address) => {
    const index = addresses.findIndex((addr) => addr.id === address.id)
    if (index > -1) {
      const addressesClone = addresses.slice()
      addressesClone.splice(index, 1, address)
      setAddresses(addressesClone)
    }

    // Close edit modal for this address
    modalRef?.current?.[address.id].close()

    // Notify parent component that the user's list of addresses has changed
    onAddressesChange?.()
  }

  // Remove an address from this page
  const deleteAddress = (addressId) => {
    const index = addresses.findIndex((addr) => addr.id === addressId)
    if (index > -1) {
      const addressesClone = addresses.slice()
      addressesClone.splice(index, 1)
      setAddresses(addressesClone)

      // Notify parent component that the user's list of addresses has changed
      onAddressesChange?.()
    }
  }

  return (
    <>
      {addresses.map((address) => (
        <Item key={address.id}>
          <Address>
            {address.firstName} {address.lastName}
            <br />
            <strong>{address.line1}</strong>
            <br />
            {address.line2 && (
              <>
                {address.line2}
                <br />
              </>
            )}
            {address.city}
            <br />
            {address.region.name}
            <br />
            {address.postalCode}
            <br />
            {address.country.name}
            <br />
            {address.phone && formatPhoneNumberIntl(address.phone)}
          </Address>
          <Actions>
            {inputName && (
              <Radio
                blueLabel
                boldLabel
                label="Select"
                checkedLabel="Selected"
                name={inputName}
                value={address.id}
                onCheck={onSelect}
                required
              />
            )}

            <ModalBase
              ref={(element) => {
                modalRef.current[address.id] = element
              }}
              trigger={<Edit $reverse>Edit</Edit>}
            >
              <ModalInner>
                <ModalHeading>Edit address</ModalHeading>
                <AddressForm
                  addressId={address.id}
                  accountId={accountId}
                  onDelete={deleteAddress}
                  onSave={updateAddress}
                  initialValues={{
                    firstName: address.firstName,
                    lastName: address.lastName,
                    countryIso: address.country.iso,
                    line1: address.line1,
                    line2: address.line2 || '',
                    city: address.city,
                    region: address.region.name,
                    postalCode: address.postalCode,
                    phone: address.phone || '',
                  }}
                />
              </ModalInner>
            </ModalBase>
          </Actions>
        </Item>
      ))}
    </>
  )
}

Items.propTypes = {
  inputName: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      country: PropTypes.shape({
        name: PropTypes.string.isRequired,
        iso: PropTypes.string.isRequired,
      }).isRequired,
      line1: PropTypes.string.isRequired,
      line2: PropTypes.string,
      city: PropTypes.string.isRequired,
      region: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }).isRequired,
      postalCode: PropTypes.string.isRequired,
      phone: PropTypes.string,
    })
  ).isRequired,
  onSelect: PropTypes.func,
  accountId: PropTypes.string,
  onAddressesChange: PropTypes.func,
}

const Page = ({
  cursor,
  inputName,
  isLastPage,
  onLoaded,
  onSelect,
  accountId,
  onAddressesChange,
}) => {
  const [result] = useQuery({
    query: GET_ADDRESSES,
    variables: { after: cursor, accountId },
    requestPolicy: 'network-only',
  })

  const { data, fetching, error } = result

  useEffect(() => {
    if (isLastPage && data?.addresses) {
      onLoaded(
        data.addresses.pageInfo.hasNextPage
          ? data.addresses.pageInfo.endCursor
          : null
      )
    }
  }, [isLastPage, data, onLoaded])

  if (fetching) return <Loader background="#fff" />
  if (error) return <Message>An error occurred</Message>

  const addresses = data.addresses.edges.map((address) => address.node)

  return (
    <Items
      inputName={inputName}
      items={addresses}
      onSelect={onSelect}
      accountId={accountId}
      onAddressesChange={onAddressesChange}
    />
  )
}

Page.propTypes = {
  cursor: PropTypes.string.isRequired,
  inputName: PropTypes.string,
  isLastPage: PropTypes.bool.isRequired,
  onLoaded: PropTypes.func.isRequired,
  onSelect: PropTypes.func,
  accountId: PropTypes.string,
  onAddressesChange: PropTypes.func,
}

export default function AddressList({
  inputName,
  onSelect,
  accountId,
  onAddressesChange,
}) {
  const [nextPageCursor, setNextPageCursor] = useState(null)
  const [cursors, setCursors] = useState([''])
  const [newAddresses, setNewAddresses] = useState([])
  const modalRef = useRef()

  const addAddress = (address) => {
    setNewAddresses([address].concat(newAddresses))

    // Close address add modal
    modalRef?.current.close()

    // Notify parent component that the user's list of addresses has changed
    onAddressesChange?.()
  }

  const [firstRender, setFirstRender] = useState(true)

  // Fix Suspense error related to network-only requestPolicy when rendering on server
  useEffect(() => {
    setFirstRender(false)
  }, [firstRender])

  if (firstRender) return <Loader background="#fff" height="125px" />

  return (
    <Wrapper>
      <LocalPickup
        accountId={accountId}
        onAddressCreate={addAddress}
        setSelected={onSelect}
      />
      {newAddresses.length || cursors.length ? (
        <List>
          {/* Output newly added addresses */}
          {newAddresses.map((address) => (
            <Items
              key={address.id}
              inputName={inputName}
              items={[address]}
              onSelect={onSelect}
              onAddressesChange={onAddressesChange}
            />
          ))}

          {/* Output pages of results */}
          {cursors.map((cursor, i) => (
            <Page
              key={cursor}
              cursor={cursor}
              inputName={inputName}
              isLastPage={i === cursors.length - 1}
              onLoaded={(nextPageCursor) => {
                setNextPageCursor(nextPageCursor)
              }}
              onSelect={onSelect}
              accountId={accountId}
              onAddressesChange={onAddressesChange}
            />
          ))}
        </List>
      ) : null}

      <Buttons>
        <ModalBase
          ref={modalRef}
          trigger={<Button variant="primaryDark">Add a new address</Button>}
        >
          <ModalInner>
            <ModalHeading>Add a new address</ModalHeading>
            <AddressForm
              onSave={addAddress}
              onDelete={onAddressesChange}
              accountId={accountId}
            />
          </ModalInner>
        </ModalBase>

        {nextPageCursor && (
          <Button
            variant="secondary"
            onClick={() => setCursors([...cursors, nextPageCursor])}
          >
            Load more
          </Button>
        )}
      </Buttons>
    </Wrapper>
  )
}

AddressList.propTypes = {
  inputName: PropTypes.string,
  onSelect: PropTypes.func,
  accountId: PropTypes.string,
  onAddressesChange: PropTypes.func,
}
