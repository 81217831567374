import { Helmet } from 'react-helmet-async'
import styled from 'styled-components'
import { useQuery } from 'urql'
import NotFound from '../404/NotFound'
import Icon from '../blocks/Icon'
import Html from '../data/Html'
import Hero from '../shared/Hero'
import Plans, { Wrapper as PlansWrapper } from '../selling/Plans'
import Cards from '../selling/Cards'
import Loader from '../shared/Loader'

const GET_PAGE = `
  query GetPage {
    entry(section: "selling") {
      id
      title
      url
      ... on selling_selling_Entry {
        id
        heroBackgroundImage {
          id
          url(handle: "large")
        }
        sellingGetStartedTitle
        sellingGetStartedSteps {
          ... on sellingGetStartedSteps_item_BlockType {
            id
            heading
            icon
            textSimple
          }
        }
        planSelect {
          ... on plans_default_Entry {
            id
            title
            plansHandle
            plansDescription
            plansDetails
            plansIcon
            plansLabel
            plansLink {
              url
              text
            }
            plansSubtitle
            plansTheme
            plansList {
              ... on plansList_item_BlockType {
                icon
                plansLabel
                id
              }
            }
          }
        }
        sellingPlansTitle
        seoDescription
        seoTitle
      }
    }
  }
`

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.colors.xLightGrey};
  padding-top: 20px;
  padding-bottom: 50px;

  ${PlansWrapper} {
    padding: 46px;
  }
`

const Inner = styled.div`
  max-width: 1218px;
  margin-left: auto;
  margin-right: auto;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: ${(props) => props.theme.radii};
  overflow: hidden;
  position: relative;
  border: 1px solid ${(props) => props.theme.colors.midGrey};
`

export default function SellingNewItemPage() {
  const [{ data, fetching, error }] = useQuery({ query: GET_PAGE })

  if (fetching) return <Loader background="#fff" />

  if (error) return <p>{error.message}</p>

  if (!data.entry) {
    return <NotFound />
  }

  const { entry } = data

  return (
    <>
      <Helmet>
        <title>{entry.seoTitle || entry.title}</title>
        {entry.seoDescription && (
          <meta name="description" content={entry.seoDescription} />
        )}
        <link rel="canonical" href={entry.url} />
      </Helmet>
      <Wrapper>
        <Inner>
          <Hero
            hero={{
              header: entry.title,
              flex: true,
              image: entry.heroBackgroundImage[0]?.url,
            }}
          />
          <Plans
            header={entry.sellingPlansTitle}
            list={entry.planSelect.map((plan) => ({
              title: plan.title,
              titleSize: 'large',
              subtitle: plan.plansSubtitle,
              button: plan.plansLink?.url,
              buttonText: plan.plansLink?.text,
              buttonState: { type: plan.plansHandle },
              text: plan.plansDescription && (
                <Html html={plan.plansDescription} />
              ),
              details: plan.plansDetails && <Html html={plan.plansDetails} />,
              list: plan.plansList,
              variant: plan.plansTheme,
              icon: plan.plansIcon,
              label: plan.plansLabel,
            }))}
          />
          <Cards
            header={entry.sellingGetStartedTitle}
            list={entry.sellingGetStartedSteps.map((step) => ({
              title: step.heading,
              icon: step.icon && <Icon icon={step.icon} />,
              text: step.textSimple && <Html html={step.textSimple} />,
            }))}
          />
        </Inner>
      </Wrapper>
    </>
  )
}

SellingNewItemPage.propTypes = {}
