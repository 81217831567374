import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Media, MediaImage } from '../_base/Media'
import { up } from 'styled-breakpoints'
import DateTime from '../data/DateTime'
import { Body, BodyS, HeaderM } from '../_abstracts/Type'

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-right: -13px;
  margin-left: -13px;
`

const Item = styled.div`
  width: 100%;
  padding: 13px;

  ${up('small')} {
    width: 50%;
  }

  ${up('medium')} {
    width: 33.33%;
  }
`

const Heading = styled.h2`
  ${HeaderM}
  font-weight: 700;
  line-height: 1.5;
  transition: 0.15s;
  margin-bottom: 10px;
`

const Inner = styled.a`
  display: block;
  width: 100%;

  &:hover ${Heading} {
    color: ${(props) => props.theme.colors.accent};
  }
`

const Meta = styled.p`
  ${BodyS}
  color: ${(props) => props.theme.colors.darkGrey};
`

const Excerpt = styled.p`
  ${Body}
  color: ${(props) => props.theme.colors.black};
`

const Image = styled.div`
  margin-bottom: 10px;

  ${Media} {
    border: 1px solid ${(props) => props.theme.colors.midGrey};
    padding-bottom: 50%;
  }
`

export default function Items({ blogPosts }) {
  return (
    <Wrapper>
      {blogPosts.map((item) => {
        return (
          <Item key={item.id}>
            <Inner href={item.url}>
              {item.heroBackgroundImage.length > 0 && (
                <Image>
                  <Media>
                    <MediaImage
                      srcSet={item.heroBackgroundImage[0].url}
                      alt=""
                    />
                  </Media>
                </Image>
              )}
              <Heading>{item.title}</Heading>
              <Meta>
                <DateTime date={new Date(item.postDate)} noTime />
              </Meta>
              <Excerpt>{item.excerpt}</Excerpt>
            </Inner>
          </Item>
        )
      })}
    </Wrapper>
  )
}

Items.propTypes = {
  blogPosts: PropTypes.array.isRequired,
}
