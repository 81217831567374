import PropTypes from 'prop-types'
import Avatar from '../shared/Avatar'
import CardList from '../shared/CardList'
import ListingCard from '../shared/ListingCard'

export default function SearchResults({
  results,
  onlyAvailable,
  filtersShown,
}) {
  let availableResults = results
  if (onlyAvailable !== false) onlyAvailable = true

  if (onlyAvailable) {
    availableResults = results.filter(
      (result) =>
        result.isAvailable ||
        result.listing?.isAvailable ||
        result.isAvailableSearch ||
        result.listing?.isAvailableSearch
    )
  }

  return (
    <CardList filtersShown={filtersShown}>
      {availableResults.map((result) => (
        <ListingCard {...result} key={result.id || result.item.id} />
      ))}
    </CardList>
  )
}

SearchResults.propTypes = {
  filtersShown: PropTypes.bool,
  onlyAvailable: PropTypes.bool,
  results: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      askingPrice: PropTypes.shape({
        amount: PropTypes.string,
        currency: PropTypes.shape({
          iso: PropTypes.string,
        }),
      }),
      item: PropTypes.shape({
        id: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        images: PropTypes.arrayOf(
          PropTypes.shape({ url: PropTypes.string.isRequired })
        ),
        userData: PropTypes.shape(Avatar.propTypes),
        saved: PropTypes.bool,
        inViewerWishlist: PropTypes.bool,
      }).isRequired,
      linkType: PropTypes.oneOf(['listing', 'item', 'none']),
    })
  ),
}
