import { Outlet, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { useQuery } from 'urql'
import Container from '../_helpers/Container'
import Spacer from '../_helpers/Spacer'
import NotFound from '../404/NotFound'
import Html from '../data/Html'
import { useGlobals } from '../hooks'
import Cta from '../shared/Cta'
import Loader from '../shared/Loader'
import Profile from '../shared/Profile'
import Tabs from '../shared/Tabs'
import RecentlyViewedCarousel from '../shared/RecentlyViewedCarousel'

const GET_USER = `
  query GetUser($username: String!) {
    accountByUsername(username: $username) {
      id
      username
      displayName
      bio
      videoEmbed
      videoAspect

      avatarImage {
        id
        url
      }

      bannerImage {
        id
        url
      }
    }
  }
`

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.colors.xLightGrey};
`

const Inner = styled.div`
  max-width: 1360px;
  margin-left: auto;
  margin-right: auto;
  padding: 25px;
`

const headerArgs = {
  dark: true,
  viewing: true,
}

export default function PublicProfileLayout() {
  const { username } = useParams()
  const [{ data, fetching, error }] = useQuery({
    query: GET_USER,
    variables: { username },
  })
  const { profile } = useGlobals('profile')
  const cta = profile?.publicProfileCta[0]

  if (fetching) return <Loader background="#fff" />
  if (error) return <p>{error.message}</p>

  const { accountByUsername: user } = data
  if (!user) return <NotFound />

  return (
    <Wrapper>
      <Inner>
        <Profile user={user} {...headerArgs} />
        <Tabs
          tabs={[
            { title: 'Portfolio', url: `/profile/${user.username}/portfolio` },
            {
              title: 'Items for sale',
              url: `/profile/${user.username}/listings`,
            },
          ]}
        >
          <Outlet context={{ user }} />
        </Tabs>
      </Inner>

      <RecentlyViewedCarousel theme="lightGrey" />

      {cta && (
        <>
          <Container>
            <Cta
              header={cta.title}
              text={<Html html={cta.ctaText} />}
              variant={cta.ctaBackgroundColor}
              buttons={cta.ctaLinks.map((item) => ({
                text: item.itemLink.text,
                to: item.itemLink.url,
                variant: item.buttonTheme,
              }))}
            />
          </Container>
          <Spacer size="50px" />
        </>
      )}
    </Wrapper>
  )
}
