import PropTypes from 'prop-types'
import styled from 'styled-components'

const Wrapper = styled.div`
  width: 100%;
  overflow-x: auto;
`

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    padding: 0.5em 0.8em;
    vertical-align: middle;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  > *:first-child tr:first-child {
    th,
    td {
      padding-top: 0;
    }
  }

  > *:last-child tr:last-child {
    th,
    td {
      padding-bottom: 0;
    }
  }

  th {
    font-weight: 700;
  }

  tbody {
    td {
      border-top: 1px solid ${({ theme }) => theme.colors.blueTint};
    }

    tr:first-child td {
      border-color: ${({ theme }) => theme.colors.midGrey};
    }
  }
`

export default function Table({ children }) {
  return (
    <Wrapper>
      <StyledTable>{children}</StyledTable>
    </Wrapper>
  )
}

Table.propTypes = {
  children: PropTypes.node.isRequired,
}
