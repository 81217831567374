import styled from 'styled-components'
import PropTypes from 'prop-types'
import { SmallLetters } from '../_abstracts/Type'

const Wrapper = styled.div`
  ${SmallLetters}
  position: relative;
  background: ${(props) => props.theme.colors.blueTint};
  border: 1px solid;
  border-radius: 5px;
  padding: 10px 10px 10px 35px;
  display: inline-block;
  color: ${(props) => props.theme.colors.darkBlue};
`

const Ex = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  line-height: 14px;
  font-size: 12px;
  border: 1px solid;
  background-color: ${(props) => props.theme.colors.xLightGrey};
  text-align: center;
  display: inline-block;
  user-select: none;
`

export default function Notification({ text }) {
  return (
    <Wrapper>
      <Ex>!</Ex>
      {text}
    </Wrapper>
  )
}

Notification.propTypes = {
  text: PropTypes.string.isRequired,
}
