import Meta from '../_helpers/Meta'
import { useQuery } from 'urql'
import Container from '../_helpers/Container'
import Spacer from '../_helpers/Spacer'
import NotFound from '../404/NotFound'
import Html from '../data/Html'
import Cta from '../shared/Cta'
import Loader from '../shared/Loader'
import { useParams } from 'react-router-dom'
import Items from '../blog/Items'
import Pagination from '../shared/Pagination'
import styled from 'styled-components'
import { HeaderXL } from '../_abstracts/Type'

const GET_PAGE = `
  query GetPage($perPage: Int!, $offset: Int) {
    entry(section: "blogIndex") {
      id
      title
      url
      ... on blogIndex_blogIndex_Entry {
        seoTitle
        seoDescription
        cta {
          title
          ... on ctas_ctas_Entry {
            ctaText
            ctaBackgroundColor
            ctaLinks {
              ... on ctaLinks_item_BlockType {
                buttonTheme
                itemLink {
                  text
                  url
                }
              }
            }
          }
        }
      }
    }

    blogPostCount: entryCount(section: "blog")

    blogPosts: entries(
      section: "blog"
      limit: $perPage
      offset: $offset
      orderBy: "postDate desc"
    ) {
      id
      title
      postDate
      url
      ... on blog_default_Entry {
        heroBackgroundImage {
          id
          url
        }
        excerpt
      }
    }
  }
`

const Header = styled.h1`
  ${HeaderXL}
  text-align: center;
  margin-top: 40px;
  margin-bottom: 20px;
`

const Pag = styled.div`
  background: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.midGrey};
  border-radius: ${(props) => props.theme.radii};
  margin-top: 20px;

  > * {
    border: 0;
  }
`

export default function Page() {
  let { currentPage = 1 } = useParams()
  currentPage = Number(currentPage)
  const perPage = 12
  const offset = (currentPage - 1) * perPage

  const [{ data, fetching, error }] = useQuery({
    query: GET_PAGE,
    variables: { currentPage, perPage, offset },
  })

  if (fetching) return <Loader background="#fff" />

  if (error) return <p>{error.message}</p>

  if (!data.entry) {
    return <NotFound />
  }

  const { entry, blogPostCount, blogPosts } = data
  const totalPages = Math.ceil(blogPostCount / perPage)

  const cta = entry.cta[0]

  return (
    <>
      <Meta
        title={entry.seoTitle || entry.title}
        description={entry.seoDescription}
        url={entry.url}
      />

      <Container>
        <Header>Blog</Header>

        <Items blogPosts={blogPosts} />

        <Pag>
          <Pagination
            prefix="/blog/page/"
            index="/blog"
            first={currentPage == 1}
            last={currentPage == totalPages}
            current={currentPage}
            pages={totalPages}
          />
        </Pag>

        {cta && (
          <>
            <Spacer fromSize="40px" toSize="70px" />
            <Cta
              header={cta.title}
              text={<Html html={cta.ctaText} />}
              variant={cta.ctaBackgroundColor}
              buttons={cta.ctaLinks.map((item) => ({
                text: item.itemLink.text,
                to: item.itemLink.url,
                variant: item.buttonTheme,
              }))}
            />
          </>
        )}
        <Spacer fromSize="40px" toSize="80px" />
      </Container>
    </>
  )
}
