import styled from 'styled-components'
import { useQuery } from 'urql'
import { useState } from 'react'
import Button from '../_atoms/Button'
import { useCartContext } from './CartContext'

const GET_OPEN_ORDERS = `
  query GetOpenOrders($after: String, $before: String, $reference: String) {
    orders(first: 20, after: $after, before: $before, onlyOpen: true, filter: AS_BUYER, filterReference: $reference) {
      pageInfo {
        hasNextPage
        startCursor
        endCursor
      }

      edges {
        node {
          id
          reference
          total {
            amount
            currency {
              iso
            }
          }

          paymentBalance {
            amount
            currency {
              iso
            }
          }
        }
      }
    }
  }
`

const OpenOrderListWrapper = styled.div`
  padding: 20px;

  h2 {
    text-align: center;
    font-weight: bold;
    font-family: ${(props) => props.theme.fonts.sansSerif};
    font-size: 26px;
  }
`

const SearchForm = styled.div`
  text-align: center;
  margin-top: 10px;

  input {
    border: 1px solid #e6e7ea;
    border-radius: 3px;
    text-align: left;
    padding: 7px 5px;
    margin-right: 5px;
  }
`

const Table = styled.table`
  width: 100%;
  border-spacing: 0;
  margin-top: 20px;

  th,
  td {
    padding: 4px;
    vertical-align: middle;
  }

  th {
    font-weight: bold;
    background: #f5f5f5;
  }

  tr:hover td {
    background: #f5f5f5;
    cursor: pointer;
  }
`

const Pagination = styled.div`
  margin-top: 20px;
`

export default function OpenOrderList() {
  const { openOrderIds, updateOpenOrderIds } = useCartContext()
  const [after, setAfter] = useState(null)
  const [before, setBefore] = useState(null)
  const [reference, setReference] = useState(null)
  const [pauseQuery, setPauseQuery] = useState(false)

  const [{ data, fetching, error }] = useQuery({
    query: GET_OPEN_ORDERS,
    variables: { after, before, reference },
    pause: pauseQuery,
  })

  if (error) {
    console.error(error)
  }

  if (fetching) {
    return <div>Loading...</div>
  }

  function pageBack(cursor) {
    setBefore(cursor)
    setAfter(null)
  }

  function pageForward(cursor) {
    setBefore(null)
    setAfter(cursor)
  }

  function reopen(id) {
    const ids = [...openOrderIds]
    ids.push(id)
    updateOpenOrderIds(ids)
  }

  return (
    <OpenOrderListWrapper>
      <h2>All incomplete orders</h2>
      <SearchForm>
        <input
          type="text"
          onFocus={() => setPauseQuery(true)}
          onBlur={() => setPauseQuery(false)}
          onChange={(e) => setReference(e.target.value)}
          value={reference || ''}
          placeholder="Reference"
        />
        <Button>Search</Button>
      </SearchForm>
      <Table>
        <thead>
          <tr>
            <th>id</th>
            <th>ref</th>
            <th>total</th>
            <th>paid</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {data.orders.edges.map((o) => (
            <tr key={o.node.id}>
              <td>{o.node.id}</td>
              <td>{o.node.reference || 'No reference'}</td>
              <td>{`${o.node.total?.amount} ${o.node.total?.currency?.iso}`}</td>
              <td>{`${o.node.paymentBalance?.amount} ${o.node.paymentBalance?.currency?.iso}`}</td>
              <td>
                {openOrderIds.includes(o.node.id) ? (
                  <span>Open</span>
                ) : (
                  <Button onClick={() => reopen(o.node.id)}>Open</Button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination>
        {!!after && (
          <Button onClick={() => pageBack(data.orders.pageInfo.startCursor)}>
            Previous
          </Button>
        )}
        {data.orders.pageInfo.hasNextPage && (
          <Button onClick={() => pageForward(data.orders.pageInfo.endCursor)}>
            Next
          </Button>
        )}
      </Pagination>
    </OpenOrderListWrapper>
  )
}
