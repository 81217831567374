import PropTypes from 'prop-types'
import Html from '../data/Html'
import styled from 'styled-components'
import { HeaderXL, HeaderL, HeaderM, HeaderS } from '../_abstracts/Type'

export const Element = styled.div`
  p,
  ul,
  ol {
    color: ${(props) => props.theme.colors.xDarkGrey};
  }

  ol ol {
    list-style: lower-alpha;
    padding-left: 20px;
  }

  ol ol ol {
    list-style: lower-roman;
  }

  h1 {
    ${HeaderXL}
  }

  h2 {
    ${HeaderL}
  }

  h3 {
    ${HeaderM}
    font-weight: 600;
  }

  h4,
  h5,
  h6 {
    ${HeaderS}
  }
`

export default function Text({ text, textFormat }) {
  return (
    <Element format={textFormat}>
      <Html html={text} />
    </Element>
  )
}

Text.propTypes = {
  text: PropTypes.node,
  textFormat: PropTypes.oneOf(['indented']),
}
