import { useEffect } from 'react'
import { LoginReturnNavigate } from '../_atoms/ReturnLink'
import { useMutation } from 'urql'
import { UserConsumer } from '../auth/UserContext'
import Loader from '../shared/Loader'

const ONBOARD_MUTATION = `
  mutation OnboardPayPalAccount {
    paymentOnboardPayPal {
      redirectUrl
    }
  }
`

export default function PayPalOnboarding() {
  const [{ error }, onboardAccount] = useMutation(ONBOARD_MUTATION)

  useEffect(() => {
    onboardAccount().then(({ data }) => {
      if (data?.paymentOnboardPayPal?.redirectUrl)
        window.location.href = data.paymentOnboardPayPal.redirectUrl
    })
  }, [onboardAccount])

  return (
    <UserConsumer>
      {({ user }) => {
        if (!user) return <LoginReturnNavigate />
        if (error) return <p>Something went wrong.</p>

        return <Loader background="#fff" />
      }}
    </UserConsumer>
  )
}
