import styled from 'styled-components'
import { ErrorMessage as FormikError } from 'formik'
import PropTypes from 'prop-types'
import { Icon, WarningIcon } from '../_base/Icon'

export const Error = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${(props) => props.theme.colors.red};
  background: ${(props) => props.theme.colors.redTint};
  color: ${(props) => props.theme.colors.red};
  border-radius: ${(props) => props.theme.radii};
  font-size: 12px;
  line-height: 1.5;
  padding: 8px;
  width: 100%;
  align-items: center;
  margin-top: 8px;
  overflow: hidden;
  width: 100%;

  ${Icon} {
    flex-shrink: 0;
    width: 18px;
    height: 18px;

    + span {
      margin-left: 8px;
    }
  }
`

export default function ErrorMessage({ name }) {
  return (
    <FormikError name={name}>
      {(msg) => (
        <Error>
          <WarningIcon />
          <span>{msg}</span>
        </Error>
      )}
    </FormikError>
  )
}

ErrorMessage.propTypes = {
  name: PropTypes.node.isRequired,
}
