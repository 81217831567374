import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import Link from '../_atoms/Link'
import Transition from '../_abstracts/Animation'
import {
  Icon,
  YoutubeIcon,
  TwitterIcon,
  InstagramIcon,
  FacebookIcon,
} from '../_base/Icon'

const SocialLinks = styled.ul`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 250px;

  &:not(:first-child) {
    margin-left: 10px;
  }
`
const SocialLink = styled(Link)`
  ${Icon} {
    width: 28px;
  }
  svg path {
    ${Transition({ property: 'fill', duration: '250ms' })};
    fill: ${(props) => props.theme.colors.xDarkGrey};
  }

  &:hover {
    ${(props) =>
      (props.social === 'youtube' &&
        css`
          svg path {
            fill: #ff0000;
          }
        `) ||
      (props.social === 'twitter' &&
        css`
          svg path {
            fill: #1da1f2;
          }
        `) ||
      (props.social === 'instagram' &&
        css`
          svg path {
            fill: #833ab4;
          }
        `) ||
      (props.social === 'facebook' &&
        css`
          svg path {
            fill: #4267b2;
          }
        `)}
  }
`

export default function Socials({ facebook, instagram, twitter, youtube }) {
  return (
    <SocialLinks>
      {youtube ? (
        <li>
          <SocialLink social="youtube" to={youtube} aria-label="YouTube">
            <YoutubeIcon aria-hidden="true" />
          </SocialLink>
        </li>
      ) : null}
      {twitter ? (
        <li>
          <SocialLink social="twitter" to={twitter} aria-label="Twitter">
            <TwitterIcon aria-hidden="true" />
          </SocialLink>
        </li>
      ) : null}
      {instagram ? (
        <li>
          <SocialLink social="instagram" to={instagram} aria-label="Instagram">
            <InstagramIcon aria-hidden="true" />
          </SocialLink>
        </li>
      ) : null}
      {facebook ? (
        <li>
          <SocialLink social="facebook" to={facebook} aria-label="Facebook">
            <FacebookIcon aria-hidden="true" />
          </SocialLink>
        </li>
      ) : null}
    </SocialLinks>
  )
}

Socials.propTypes = {
  youtube: PropTypes.string,
  twitter: PropTypes.string,
  instagram: PropTypes.string,
  facebook: PropTypes.string,
}
