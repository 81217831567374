const GET_GlOBAL_SET = `
  query GetConfirmation {
    globalSet(handle: "checkoutConfirmation") {
      ... on checkoutConfirmation_GlobalSet {
        id
        heading
        shortTitle
        linkField {
          url
          text
        }
        backgroundImage {
          url
        }
      }
    }
  }
`

export default GET_GlOBAL_SET
