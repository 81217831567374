import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { useMutation, useQuery } from 'urql'
import Container from '../_helpers/Container'
import Spacer from '../_helpers/Spacer'
import { UserConsumer } from '../auth/UserContext'
import { useGlobals } from '../hooks'
import Alert from '../shared/Alert'
import Back from '../shared/Back'
import Loader from '../shared/Loader'
import Translation from '../shared/Translation'
import Editor from './Editor'
import MessageBubble from './MessageBubble'
import Messages from './Messages'
import Summary from './Summary'

const GET_MESSAGE_THREAD = `
  query GetMessageThread($messageThreadId: ID!) {
    messageThread(id: $messageThreadId) {
      id
      participants {
        id
        username
        avatarImage {
          id
          url
        }
      }
      item {
        id
        title
        images {
          id
          url(size: "productThumb")
        }
        account {
          isPrivateSeller
          isProfessionalSeller
          username
          avatarImage {
            id
            url
          }
        }
        listing {
          askingPrice {
            amount
            currency { iso }
          }
        }
      }
    }
  }
`

const MARK_THREAD_AS_READ = `
  mutation MarkThreadAsRead($threadId: ID!) {
    messageThreadMarkRead(threadId: $threadId) {
      success
    }
  }
`

const Inner = styled(Container)`
  padding-top: 30px;
  padding-bottom: 30px;
  border-top: 1px solid ${({ theme }) => theme.colors.midGrey};
`

const Content = styled.div`
  max-width: 1035px;
  margin-right: auto;
  margin-left: auto;
`

const Footer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 30px;
  border-top: 1px solid ${({ theme }) => theme.colors.midGrey};
`

const Message = styled.p`
  margin: 30px;
  text-align: center;
`

export default function MessagesViewPage() {
  const { messageThreadId } = useParams()
  const [{ data, fetching, error }] = useQuery({
    query: GET_MESSAGE_THREAD,
    variables: { messageThreadId },
    pause: !messageThreadId,
  })
  const [refetchMessages, setRefetchMessages] = useState()

  const { messages } = useGlobals('messages')

  const [, markThreadAsRead] = useMutation(MARK_THREAD_AS_READ)

  useEffect(
    () =>
      markThreadAsRead({
        threadId: messageThreadId,
      }),
    [markThreadAsRead, messageThreadId]
  )

  if (fetching) return <Loader background="#fff" height="250px" />
  if (error) return <Message>{error.message}</Message>
  if (!data?.messageThread) return <Message>Message not found</Message>

  const { messageThread } = data

  return (
    <UserConsumer>
      {({ user }) => {
        const correspondent =
          messageThread.participants.find(
            (participant) => participant.id !== user.id
          ) || messageThread.participants[0]

        return (
          <>
            <Helmet>
              <title>{correspondent.username} | Message</title>
              <meta name="robots" content="noindex" />
            </Helmet>
            <Back url="/messages" header="Back to Inbox" size="small" wrapped />
            <Inner>
              <Summary
                anchor="#reply"
                user={correspondent}
                item={messageThread.item}
              />
              <Content>
                <Messages
                  threadId={messageThreadId}
                  userId={user.id}
                  setRefetchMessages={setRefetchMessages}
                />
                <MessageBubble id="reply" state="sent" user={user} fullWidth>
                  <Editor
                    messageThreadId={messageThread.id}
                    participants={messageThread.participants.map(
                      ({ id }) => id
                    )}
                    onMessageCreate={refetchMessages}
                  />
                </MessageBubble>
              </Content>
              <Spacer fromSize="30px" toSize="50px" />
              {messages?.messagesTnagDirectMessageWarning && (
                <Footer>
                  <Alert>
                    <Translation>
                      {messages?.messagesTnagDirectMessageWarning}
                    </Translation>
                  </Alert>
                </Footer>
              )}
            </Inner>
          </>
        )
      }}
    </UserConsumer>
  )
}

MessagesViewPage.propTypes = {}
