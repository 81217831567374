import toast from 'react-hot-toast'
import PropTypes from 'prop-types'
import { useMutation } from 'urql'
import Button from '../_atoms/Button'
import Popup from '../shared/Popup'

const DELETE_LISTING = `
  mutation DeleteListing($id: ID!) {
    listingRemove(id: $id) {
      success
    }
  }
`

export default function DeleteListing({ children, listing, onDelete }) {
  const [, submitDeleteListing] = useMutation(DELETE_LISTING)

  async function deleteListing() {
    const deleteListingPromise = submitDeleteListing({
      id: listing.id,
    })

    toast.promise(
      deleteListingPromise,
      {
        loading: 'Deleting listing…',
        success: 'Listing deleted',
        error: 'Unable to delete listing',
      },
      { id: 'listing' }
    )

    let response
    try {
      response = await deleteListingPromise

      if (response.error || response.data?.listingRemove?.success === false) {
        throw new Error(response.error?.toString())
      }
    } catch (error) {
      toast.error('Unable to delete listing', { id: 'listing' })
      console.error(error)
      return
    }

    if (response.data?.listingRemove?.success) {
      onDelete?.(listing.id)
      console.log('Successfully deleted listing')
    }
  }

  return (
    <Popup trigger={children}>
      <div>
        <p>Permanently delete &lsquo;{listing.item.title}&rsquo;?</p>
        <Button onClick={deleteListing} variant="primaryDark">
          Delete
        </Button>
      </div>
    </Popup>
  )
}

DeleteListing.propTypes = {
  children: PropTypes.node.isRequired,
  listing: PropTypes.shape({
    id: PropTypes.string.isRequired,
    item: PropTypes.shape({
      title: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  onDelete: PropTypes.func,
}
