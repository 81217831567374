import styled from 'styled-components'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { useId } from 'react-id-generator'
import { Field as FormikField, useFormikContext } from 'formik'
import PropTypes from 'prop-types'
import Label, { StyledLabel } from '../forms/Label'
import ErrorMessage from '../_atoms/ErrorMessage'

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`

export const InputLabel = styled.span`
  margin-bottom: 5px;
  font-size: 14px;
  text-transform: capitalize;

  ${StyledLabel} {
    margin-bottom: 0;
  }
`

const Inner = styled.div`
  padding-left: 15px;
  border-radius: ${(props) => props.theme.radii};
  border: 1px solid ${(props) => props.theme.colors.midGrey};
  background: ${(props) => props.theme.colors.white};
  width: 100%;
  color: ${(props) => props.theme.colors.darkBlue};

  input {
    border: 0;
    padding: 8px 12px 8px 8px;
    font-size: 16px;
  }

  select:focus-visible ~ * {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
  }
`

export default function Phone({
  defaultCountry,
  label,
  name,
  placeholder,
  required,
}) {
  const fieldId = useId()[0]
  const formik = useFormikContext()

  return (
    <Wrapper>
      <InputLabel>
        <Label for={fieldId}>{label}</Label>
        {required && '*'}
      </InputLabel>
      <Inner>
        <FormikField name={name}>
          {({ field }) => (
            <PhoneInput
              autoComplete="tel"
              defaultCountry={defaultCountry}
              international
              onChange={(value) =>
                formik.setFieldValue(field.name, value || '')
              }
              onBlur={formik.handleBlur(field.name)}
              id={fieldId}
              placeholder={placeholder}
              required={required}
              name={field.name}
              value={field.value}
            />
          )}
        </FormikField>
      </Inner>
      <ErrorMessage name={name} />
    </Wrapper>
  )
}

Phone.defaultProps = {
  label: 'Phone number',
  name: 'phone',
}

Phone.propTypes = {
  defaultCountry: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
}
