import PropTypes from 'prop-types'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { useQuery } from 'urql'
import { HeaderL, HeaderS } from '../_abstracts/Type'
import Loader from '../shared/Loader'
import NextPrevious from '../shared/NextPrevious'

const ITEMS_PER_PAGE = 12

const SEARCH_ENTRIES = `
  query SearchEntries($search: String!, $offset: Int, $limit: Int) {
    entries(
      section: "helpCenterPages"
      type: "default"
      orderBy: "score"
      search: $search
      offset: $offset
      limit: $limit
    ) {
      id
      title
      uri
    }

    entryCount(
      section: "helpCenterPages"
      type: "default"
      search: $search
    )
  }
`

const Wrapper = styled.div`
  &:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
  }

  &:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit;
  }
`

const Top = styled.div`
  padding: 20px 35px;
`

const Header = styled.h2`
  ${HeaderL}
  max-width: 800px;
  margin-right: auto;
  margin-left: auto;

  span {
    color: ${(props) => props.theme.colors.accent};
  }
`

const Item = styled(Link)`
  display: block;
  padding: 25px 35px;
  border-top: 1px solid ${(props) => props.theme.colors.blueTint};
  transition: 0.15s;

  &:hover {
    color: ${(props) => props.theme.colors.accent};
  }
`

const Inner = styled.div`
  max-width: 800px;
  margin-right: auto;
  margin-left: auto;
`

const Title = styled.h3`
  ${HeaderS}
  color: currentColor;
`

const Text = styled.div`
  margin-top: 5px;

  span {
    color: ${(props) => props.theme.colors.accent};
  }
`

export default function Results({ query }) {
  const [currentPage, setCurrentPage] = useState(0)
  const [pagination, setPagination] = useState({ limit: ITEMS_PER_PAGE })
  const [{ data, fetching, error }] = useQuery({
    query: SEARCH_ENTRIES,
    variables: { ...pagination, search: query },
  })

  if (fetching) return <Loader background="#fff" height="150px" />

  if (error) return <p>{error.message}</p>

  const { entries, entryCount } = data

  const totalPages = Math.ceil(entryCount / ITEMS_PER_PAGE)
  const hasNextPage = totalPages > currentPage + 1
  const hasPreviousPage = currentPage > 0

  return (
    <Wrapper>
      {query && (
        <Top>
          <Header>
            {entryCount} result{entryCount !== 1 && 's'} for{' '}
            <span>&quot;{query}&quot;</span>
          </Header>
        </Top>
      )}

      {entries.map((item) => (
        <Item key={item.id} to={`/${item.uri}`} title={item.title}>
          <Inner>
            <Title>{item.title}</Title>
            {item.text && <Text>{item.text}</Text>}
          </Inner>
        </Item>
      ))}

      {(hasNextPage || hasPreviousPage) && (
        <NextPrevious
          includeNext={hasNextPage}
          includePrevious={hasPreviousPage}
          onNext={() => {
            setPagination({
              offset: ITEMS_PER_PAGE * (currentPage + 1),
              limit: ITEMS_PER_PAGE,
            })
            setCurrentPage(currentPage + 1)
          }}
          onPrevious={() => {
            setPagination({
              offset: ITEMS_PER_PAGE * (currentPage - 1),
              limit: ITEMS_PER_PAGE,
            })
            setCurrentPage(currentPage - 1)
          }}
        />
      )}
    </Wrapper>
  )
}

Results.propTypes = {
  query: PropTypes.string,
}
