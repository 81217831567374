import { useState } from 'react'
import { useMutation } from 'urql'
import { resetDatalayer } from './useAnalyticsViewItem'

const ORDER_SET_ANALYTICS_SHOWN = `
mutation SetAnalyticsShown($orderId: [ID!]!) {
  orderSetAnalyticsShown(orderId: $orderId) {
    success
    orders {
      id
      analyticsShown
    }
  }
}
`

export default function useAnalyticsPurchase(orders, user) {
  const [shown, setShown] = useState(false)
  const [, setAnalyticsShown] = useMutation(ORDER_SET_ANALYTICS_SHOWN)
  if (shown) return
  if (!orders) return
  if (typeof window === 'undefined') return
  window.dataLayer = window.dataLayer || []

  const eligibleOrders = orders.edges.filter(
    (e) => !e.node.analyticsShown && e.node.fullyPaidAt
  )

  if (eligibleOrders.length == 0) return
  eligibleOrders.forEach((order) => {
    if (!user?.isAdmin) {
      purchaseGa(order)
      purchaseFb(order)
    }
    purchaseKlaviyo(order, user)
  })

  const orderId = eligibleOrders.map((e) => e.node.id)
  setAnalyticsShown({ orderId })
  setShown(true)
}

function purchaseKlaviyo(order, user) {
  if (!window.klaviyo) return

  if (order.node.contact?.id) {
    window.klaviyo.push([
      'identify',
      {
        $email: order.node.contact.email,
        $first_name: order.node.contact.firstName,
        $last_name: order.node.contact.lastName,
      },
    ])
  }

  window.klaviyo.push([
    'track',
    'Order Placed',
    {
      currency: order.node.total.currency.iso,
      OrderId: order.node.number,
      value: order.node.total.amount,
      Items: order.node.lineItems.map((li) => ({
        ProductName: li.listing.item.title,
        ProductID: li.listing.item.id,
        SKU: li.listing.item.sku,
        ItemPrice: li.unitCost.amount,
        RowTotal: li.amount.amount,
        Quantity: li.quantity,
      })),
    },
  ])

  window.klaviyo.push([
    'identify',
    {
      $email: user.email,
      $first_name: user.firstName,
      $last_name: user.lastName,
    },
  ])
}

function purchaseGa(order) {
  resetDatalayer()
  window.dataLayer.push({
    event: 'purchase',
    eventDestination: 'ga',
    ecommerce: {
      currency: order.node.total.currency.iso,
      transaction_id: order.node.number,
      value: order.node.total.amount,
      items: order.node.lineItems.map((li) => ({
        item_id: li.listing.item.id,
        item_name: li.listing.item.title,
        quantity: li.quantity,
        price: li.unitCost.amount,
      })),
    },
  })
}

function purchaseFb(order) {
  resetDatalayer()
  window.dataLayer.push({
    event: 'Purchase',
    eventDestination: 'fb',
    fbEventData: {
      currency: order.node.total.currency.iso,
      value: order.node.total.amount,
      items: order.node.lineItems.map((li) => ({
        id: li.listing.item.id,
        quantity: li.quantity,
      })),
    },
  })
}
