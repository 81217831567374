const GET_GlOBAL_SET = `
  query GetProfile {
    globalSet(handle: "profile") {
      ... on profile_GlobalSet {
        id
        profileOverviewTitle
        profileOverviewGreeting
        profileOverviewQuickLinksTitle
        profileOverviewQuickLinks {
          ... on profileOverviewQuickLinks_item_BlockType {
            id
            itemLink {
              text
              url
            }
          }
        }
        profileSalesGraphHeading
        profileValueGraphHeading
        profileValueGraphExplainerText

        profilePortfolioTradeButton
        portfolioGraphNoDataMessage
        valueGraphTitle
        valueGraphEstimateText
        valueGraphText
        valueGraphNoDataMessage
        appraisalRequestButtonLabel
        appraisalRequestFormTitle
        appraisalRequestFormText
        appraisalRequestSuccessMessage
        appraisalRequestListingId
        feedInstructions
        cta {
          ... on ctas_ctas_Entry {
            id
            title
            ctaText
            ctaBackgroundColor
            ctaLinks {
              ... on ctaLinks_item_BlockType {
                id
                buttonTheme
                itemLink {
                  text
                  url
                }
              }
            }
          }
        }
        publicProfileCta {
          ... on ctas_ctas_Entry {
            id
            title
            ctaText
            ctaBackgroundColor
            ctaLinks {
              ... on ctaLinks_item_BlockType {
                id
                buttonTheme
                itemLink {
                  text
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`

export default GET_GlOBAL_SET
