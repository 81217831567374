import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { HeaderSansXS, Body, SmallLetters } from '../_abstracts/Type'
import { down } from 'styled-breakpoints'

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 7px;
  margin: -7px;

  ${down('medium')} {
    justify-content: space-between;
  }

  ${(props) =>
    props.grow &&
    css`
      ${Item} {
        max-width: none;
      }
    `}
`

const Item = styled.div`
  border: 1px solid ${(props) => props.theme.colors.midGrey};
  padding: 13px;
  flex-grow: 1;
  margin: 5px;
  border-radius: ${(props) => props.theme.radii};

  ${down('medium')} {
    max-width: initial;
    flex-grow: initial;
    width: calc(50% - 10px);
  }
`

const Up = styled.p`
  ${SmallLetters}
`

const Down = styled.p`
  ${Body}
  word-wrap: break-word;
`

const Header = styled.h2`
  ${HeaderSansXS}
  width: 100%;
  padding: 7px;
  margin-bottom: -5px;
`

export default function BlockList({ header, list, grow = false }) {
  return (
    <List grow={grow}>
      {header && <Header>{header}</Header>}
      {list.map((item, index) => (
        <Item key={index}>
          <Up>{item.title}</Up>
          <Down>{item.value}</Down>
        </Item>
      ))}
    </List>
  )
}

BlockList.propTypes = {
  header: PropTypes.string,
  grow: PropTypes.bool,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      value: PropTypes.node.isRequired,
    })
  ).isRequired,
}
