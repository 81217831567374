import PropTypes from 'prop-types'
import { PayPalButtons } from '@paypal/react-paypal-js'
import styled from 'styled-components'
import { useMutation } from 'urql'
import { useRef } from 'react'

const Wrapper = styled.div`
  margin: 15px;
  margin-top: 10px;
`

const CREATE_PAYMENT = `
  mutation CreatePaypalPayment($orderId: ID!) {
    orderCreatePayPalPayment(id: $orderId) {
      success
      payment { id orderId }
    }
  }
`

export default function PayPalPaymentButton({ orderId, setError, onSuccess }) {
  const data = useRef()
  const [, createPayment] = useMutation(CREATE_PAYMENT)

  async function createOrder() {
    setError(null)

    const payment = await createPayment({ orderId })

    if (!payment.data?.orderCreatePayPalPayment?.success) {
      const message = 'Unable to create payment'

      setError(message)
      throw new Error(message)
    }

    data.current = payment.data

    return payment.data.orderCreatePayPalPayment.payment.orderId
  }

  return (
    <Wrapper>
      <PayPalButtons
        fundingSource="paypal"
        style={{
          label: 'pay',
        }}
        createOrder={createOrder}
        onError={() => setError('Unable to process payment.')}
        onApprove={() => {
          onSuccess(data.current.orderCreatePayPalPayment.payment.id)
        }}
      />
    </Wrapper>
  )
}

PayPalPaymentButton.propTypes = {
  orderId: PropTypes.string.isRequired,
  setError: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
}
