import PropTypes from 'prop-types'
import { cloneElement } from 'react'
import { Navigate, useLocation } from 'react-router'

export function useReturnUrl() {
  const location = useLocation()

  return location.pathname + location.search
}

export default function ReturnLink({ children }) {
  const returnUrl = useReturnUrl()

  return cloneElement(children, {
    to: `/login?return_to=${encodeURIComponent(returnUrl)}`,
  })
}

ReturnLink.propTypes = {
  children: PropTypes.element,
}

export function LoginReturnNavigate() {
  const returnUrl = useReturnUrl()

  return (
    <Navigate
      replace
      to={`/login?return_to=${encodeURIComponent(returnUrl)}`}
    />
  )
}
