import PropTypes from 'prop-types'
import styled from 'styled-components'
import Button from '../_atoms/Button'

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  background: ${(props) => props.theme.colors.accentTint};
  border-top: 1px solid ${(props) => props.theme.colors.midGrey};
  padding: 14px 32px;

  &:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
  }

  &:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit;
  }
`

const Previous = styled(Button)`
  margin-right: auto;
`

export default function NextPrevious({
  includeNext,
  includePrevious,
  onNext,
  onPrevious,
}) {
  return includePrevious || includeNext ? (
    <Wrapper>
      {includePrevious && (
        <Previous variant="secondary" onClick={onPrevious} type="button">
          Previous
        </Previous>
      )}
      {includeNext && (
        <Button variant="secondary" onClick={onNext} type="button">
          Next
        </Button>
      )}
    </Wrapper>
  ) : null
}

NextPrevious.propTypes = {
  includeNext: PropTypes.bool,
  includePrevious: PropTypes.bool,
  onNext: PropTypes.func,
  onPrevious: PropTypes.func,
}
