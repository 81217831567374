import { hideVisually } from 'polished'
import PropTypes from 'prop-types'
import { useId } from 'react-id-generator'
import { down, up } from 'styled-breakpoints'
import styled, { css } from 'styled-components'
import { HeaderM, HeaderS } from '../_abstracts/Type'
import { Icon, DropdownIcon } from '../_base/Icon'
import { Wrapper as Field } from './Field'
import { useToggle } from '../hooks'
import Hr from '../shared/Hr'
import Tooltip, { Wrapper as TooltipWrapper } from '../shared/Tooltip'

export const ICON_OFFSET = 122

export const Wrapper = styled.fieldset`
  position: relative;
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 27px;

  ${({ $width }) =>
    $width === 'small' &&
    css`
      max-width: 820px;
      margin-right: auto;
      margin-left: auto;
    `}

  ${({ icon }) =>
    icon &&
    css`
      ${up('large')} {
        padding-right: ${ICON_OFFSET}px;
      }
    `}

  ${up('large')} {
    &::before {
      position: absolute;
      top: 80px;
      left: 40px;
      bottom: -27px;
      width: 1px;
      background-color: ${({ theme }) => theme.colors.midGrey};
      content: '';

      ${({ icon }) =>
        !icon &&
        css`
          display: none;
        `}
    }
  }

  &:last-child {
    margin-bottom: 0;

    &::before {
      display: none;
    }
  }
`

const Legend = styled.legend`
  ${hideVisually()};
`

const IconWrapper = styled.div`
  position: relative;
  width: 80px;
  height: 80px;
  flex-shrink: 0;
  margin-right: 42px;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.midGrey};
  border-radius: 50%;

  ${down('medium')} {
    display: none;
  }

  ${Icon} {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 65%;
    height: 65%;
    transform: translate(-50%, -50%);
  }
`

const Inner = styled.div`
  flex-grow: 1;
  border-radius: 4px;

  ${({ icon }) =>
    icon &&
    css`
      margin-top: 9px;
    `}

  ${({ highlight }) =>
    highlight &&
    css`
      box-shadow: ${({ theme }) => theme.shadows.small};
    `}

  ${Wrapper}:first-of-type & {
    ${({ icon }) =>
      !icon &&
      css`
        margin-top: 0;
      `}
  }
`

const Header = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 12px 24px 11px;
  background-color: ${({ theme }) => theme.colors.lightGrey};
  border: 1px solid ${({ theme }) => theme.colors.midGrey};
  border-radius: inherit;

  ${({ align }) =>
    align === 'center' &&
    css`
      justify-content: center;
    `}

  ${({ accordion, isOpen }) =>
    (!accordion || isOpen) &&
    css`
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    `}

  ${({ highlight }) =>
    highlight &&
    css`
      background-color: ${({ theme }) => theme.colors.lightGrey};
      border-color: ${({ theme }) => theme.colors.accent};
    `}

    ${({ accordion }) =>
    accordion &&
    css`
      padding-right: 50px;
      padding-left: 50px;
    `}

  ${TooltipWrapper} {
    margin-left: 10px;
  }
`

const Heading = styled.p`
  ${HeaderM};
  font-weight: 600;
  text-align: ${({ align }) => align};
`

const Toggle = styled.button`
  position: absolute;
  top: 50%;
  right: 16px;
  width: 24px;
  height: 24px;
  transform: translateY(-50%);

  ${Icon} {
    ${({ isOpen }) =>
      isOpen &&
      css`
        transform: rotate(180deg);
      `}
  }
`

export const Content = styled.div`
  padding: 24px;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.midGrey};
  border-top: 0;

  ${up('medium')} {
    padding: 36px 42px;
  }

  &:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit;
  }
`

export const Fields = styled.div`
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  margin: -13px;
  width: calc(100% + 26px);

  ${Field} {
    margin: 13px;
  }

  ${Hr} {
    width: 100%;
    margin: 22px 13px;
  }
`

export const Footer = styled.div`
  padding: 14px 24px;
  background-color: ${({ theme }) => theme.colors.lightGrey};
  border: 1px solid ${({ theme }) => theme.colors.midGrey};
  border-top: 0;
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
`

const Text = styled.div`
  display: flex;
  justify-content: center;
  align-items: baseline;
  margin-top: -0.4em;
  margin-bottom: 1.5em;
  font-size: 15px;

  ${TooltipWrapper} {
    bottom: 1px;
    margin-left: 8px;
  }
`

export default function Fieldset({
  accordion,
  children,
  element,
  footer,
  helpText,
  hideLegend,
  highlight,
  icon,
  isOpen,
  legend,
  legendAlign,
  text,
  width,
}) {
  const [open, toggleOpen] = useToggle(isOpen)
  const contentId = useId()[0]
  const toggleId = useId()[0]

  return (
    <Wrapper $width={width} icon={icon} as={element}>
      {!element && <Legend>{legend}</Legend>}

      {icon && <IconWrapper>{icon}</IconWrapper>}

      <Inner highlight={highlight} icon={icon}>
        <Header
          align={legendAlign}
          highlight={highlight}
          isOpen={open}
          accordion={accordion}
        >
          {!hideLegend && (
            <Heading
              align={legendAlign}
              aria-hidden="true"
              accordion={accordion}
            >
              {legend}
            </Heading>
          )}
          {helpText && !text && (
            <Tooltip maxWidth={'300px'}>{helpText}</Tooltip>
          )}
          {accordion && (
            <Toggle
              id={toggleId}
              type="button"
              aria-label="Toggle fields"
              aria-expanded={open}
              aria-controls={contentId}
              isOpen={open}
              onClick={toggleOpen}
            >
              <DropdownIcon />
            </Toggle>
          )}
        </Header>
        <Content
          id={accordion ? contentId : undefined}
          hidden={accordion ? !open : undefined}
          aria-labelledby={accordion ? toggleId : undefined}
          role={accordion ? 'region' : undefined}
        >
          {text && (
            <Text>
              {text}
              {helpText && <Tooltip maxWidth={'300px'}>{helpText}</Tooltip>}
            </Text>
          )}
          <Fields>{children}</Fields>
        </Content>
        {footer}
      </Inner>
    </Wrapper>
  )
}

Fieldset.defaultProps = {
  isOpen: true,
  legendAlign: 'center',
  width: 'full',
}

Fieldset.propTypes = {
  accordion: PropTypes.bool,
  children: PropTypes.node,
  element: PropTypes.string,
  footer: PropTypes.element,
  helpText: PropTypes.node,
  hideLegend: PropTypes.bool,
  highlight: PropTypes.bool,
  icon: PropTypes.element,
  isOpen: PropTypes.bool,
  legend: PropTypes.string.isRequired,
  legendAlign: PropTypes.oneOf(['left', 'center']).isRequired,
  text: PropTypes.node,
  width: PropTypes.oneOf(['small', 'full']),
}

export const SimpleWrapper = styled.fieldset`
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  border-top: 1px solid ${({ theme }) => theme.colors.blueTint};

  &:first-child {
    padding-top: 0;
    border-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }

  + ${Field} {
    margin-top: -15px;
  }
`

const SimpleHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 25px;

  ${TooltipWrapper} {
    margin-left: 7px;
  }
`

const SimpleHeading = styled.p`
  ${HeaderS};
  font-weight: 600;
`

export const SimpleFieldset = ({ children, helpText, hideLegend, legend }) => (
  <SimpleWrapper>
    <Legend>{legend}</Legend>

    <div>
      {(!hideLegend || helpText) && (
        <SimpleHeader>
          {!hideLegend && (
            <SimpleHeading aria-hidden="true">{legend}</SimpleHeading>
          )}
          {helpText && <Tooltip maxWidth={'300px'}>{helpText}</Tooltip>}
        </SimpleHeader>
      )}
      <Fields>{children}</Fields>
    </div>
  </SimpleWrapper>
)

SimpleFieldset.propTypes = {
  children: PropTypes.node,
  helpText: PropTypes.node,
  hideLegend: PropTypes.bool,
  legend: PropTypes.string.isRequired,
}
