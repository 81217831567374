import { useState } from 'react'
import styled from 'styled-components'
import { UserConsumer } from '../auth/UserContext'
import NavigationMobile from './NavigationMobile'

const StyledHeader = styled.header`
  background-color: ${(props) => props.theme.colors.white};
  position: sticky;
  top: 0;
  z-index: 1000;
  left: 0;
  width: 100%;
  padding-right: var(--scrollbar-gap);

  @media (min-width: 990px) {
    display: none;
  }

  @media print {
    display: none;
  }
`

//dark background when opening utility menu
const DarkBackground = styled.div`
  pointer-events: ${(props) => (props.state ? 'all' : 'none')};
  background-color: ${(props) =>
    props.state ? 'rgba(6, 6, 45, 0.45)' : 'transparent'};
  transition: background-color 150ms ease-in-out;
  position: fixed;
  height: 100%;
  width: 100%;
`

export default function HeaderMobile() {
  const [isPopupOpen, setIsPopupOpen] = useState(false)

  return (
    <UserConsumer>
      {({ user }) => (
        <StyledHeader id="mobile-header">
          <DarkBackground state={isPopupOpen} />

          <NavigationMobile setIsPopupOpen={setIsPopupOpen} user={user} />
        </StyledHeader>
      )}
    </UserConsumer>
  )
}
