import { Helmet } from 'react-helmet-async'
import Bar from './Bar'
import MessageThreads from './MessageThreads'

export default function MessagesInboxPage() {
  return (
    <>
      <Helmet>
        <title>Inbox</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Bar heading="Inbox" />
      <MessageThreads />
    </>
  )
}
