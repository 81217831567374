import styled from 'styled-components'
import PropTypes from 'prop-types'
import { CardWrapper, minCardWidth } from './ListingCard'
import { HeaderL } from '../_abstracts/Type'
import { Icon, ArrowIcon } from '../_base/Icon'
import SaveSearchButton from '../shared/SaveSearchButton'

export const Wrapper = styled.div`
  --card-gap-x: 20px;
  --card-gap-y: 26px;
  --gap--postcard: 10px;
  --gap--dropdown: 16px;
  --card-min-width: ${minCardWidth};
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(var(--card-min-width), 1fr));
  gap: var(--card-gap-y) var(--card-gap-x);

  ${CardWrapper} {
    width: 100%;
  }
`

const Pdiv = styled.div``

export const None = styled.div`
  --card-gap-x: 20px;
  --card-gap-y: 26px;
  --gap--postcard: 10px;
  --gap--dropdown: 16px;
  --card-min-width: ${minCardWidth};
  padding: 20px;

  h2 {
    ${HeaderL}
    padding: 20px;
    background-color: ${(props) => props.theme.colors.lighterGrey};
  }

  p,
  ${Pdiv} {
    font-size: 18px;
    line-height: 1.5;
    padding: 20px 20px 0;
    max-width: 600px;
  }

  a {
    display: inline-flex;
    align-items: center;
  }

  ${Wrapper} ${Icon},
  a ${Icon} {
    min-width: 17px;
    width: 17px;
    display: inline-block;
    margin-right: 10px;
  }

  span {
    display: inline-block;
    font-weight: 700;
    border-bottom: 1px solid;
    transition: 0.15s;
  }

  a:hover span {
    border-bottom-color: transparent;
  }
`

export default function CardList({ children, filtersShown = null }) {
  let none
  if (filtersShown) {
    none = (
      <>
        <p>
          There are no results matching your search. Save this search using the
          button to the left to get notified as soon as items are listed
          matching your search.
        </p>
        <Pdiv>
          <SaveSearchButton />
        </Pdiv>
      </>
    )
  } else {
    none = (
      <>
        <p>
          There are no results matching your search. Check the spelling or
          search for something else.
        </p>
      </>
    )
  }
  return (
    <>
      {children.length ? (
        <Wrapper filtersShown={filtersShown}>{children}</Wrapper>
      ) : (
        <None>
          <h2>We&apos;re sorry…</h2>
          {none}
          <p>
            <a href="/shop">
              <ArrowIcon />
              <span>Browse all listings</span>
            </a>
          </p>
        </None>
      )}
    </>
  )
}

CardList.propTypes = {
  filtersShown: PropTypes.bool,
  children: PropTypes.node.isRequired,
}
