import styled from 'styled-components'

import Subheader from '../shared/Subheader'
import Tabs from '../shared/Tabs'

import FeedSelect from './FeedSelect'

import GreyTextBlockBorder from '../text/GreyTextBlockBorder'

import Container from '../_helpers/Container'

const Wrapper = styled.div`
  padding-top: 25px;
  padding-bottom: 25px;
  background-color: ${(props) => props.theme.colors.xLightGrey};
`

const tabsArgs = {
  tabs: [
    { title: 'My Saved Searches', url: '' },
    { title: 'Wishlist', url: '', end: true },
    { title: 'Recently Viewed', url: '', end: true },
  ],
}

const feedArgs = {
  link: '#',
  items: [
    {
      imageSrc: 'https://via.placeholder.com/290',
      alt: 'image alt',
      name: 'Guitar',
    },
    {
      imageSrc: 'https://via.placeholder.com/290',
      alt: 'image alt',
      name: 'Guitar',
    },
    {
      imageSrc: 'https://via.placeholder.com/290',
      alt: 'image alt',
      name: 'Guitar',
    },
    {
      imageSrc: 'https://via.placeholder.com/290',
      alt: 'image alt',
      name: 'Guitar',
    },
  ],
}

const text = {
  heading: 'Set up your saved searches',
  text: 'Usu et nostrud alterum. Vim in labitur virtute, at vix meis viris maiorum. Ne congue pericula posidonium sed, possim tacimates moderatius in usu, vix iudico nonumy temporibus ea. Eam  albucius recusab',
}

export default function MyFeedStartPage() {
  return (
    <Wrapper>
      <Container>
        <Tabs {...tabsArgs}>
          <Subheader header="My saved searches" border />
          <GreyTextBlockBorder {...text} />
          <FeedSelect {...feedArgs} />
        </Tabs>
      </Container>
    </Wrapper>
  )
}

MyFeedStartPage.propTypes = {}
