import { useQuery } from 'urql'
import { useUserContext } from '../auth/UserContext'

export const GET_UNREAD_MESSAGE_COUNT = `
  query GetUnreadMessageCount {
    messageCountUnread
  }
`

export default function useUnreadMessageCount() {
  const { user } = useUserContext()

  const [{ data, fetching, error }] = useQuery({
    query: GET_UNREAD_MESSAGE_COUNT,
    pause: !user,
  })

  if (error) {
    console.error(error)
  }

  const unreadMessageCount = data?.messageCountUnread || 0

  return { unreadMessageCount, fetching, error }
}
