import { useEffect, useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useMutation, useClient, useQuery } from 'urql'
import { PayPalScriptProvider } from '@paypal/react-paypal-js'
import { Media } from '../_base/Media'
import { HeaderL, BodyS, SmallLetters } from '../_abstracts/Type'
import Button, { ButtonStyle } from '../_atoms/Button'
import Notification from '../shared/Notification'
import Success from '../shared/Success'
import { down } from 'styled-breakpoints'
import Input, { InputContainer } from '../_atoms/Input'
import OrderSummaryTable from '../orders/OrderSummaryTable'
import PaymentMethods from './PaymentMethods'
import StripeWrapper from '../payment/StripeWrapper'
import StripePaymentButton from '../payment/StripePaymentButton'
import PayPalPaymentButton from '../payment/PayPalPaymentButton'
import ManualPaymentButton from '../payment/ManualPaymentButton'
import ZendeskButton from '../shared/ZendeskButton'
import { useUserContext } from '../auth/UserContext'

import { Formik } from 'formik'
import Form from '../_atoms/Form'

import Field from '../forms/Field'
import * as Yup from 'yup'

import toast from 'react-hot-toast'
import ShippingMethodSelect from './ShippingMethodSelect'
import AdminControls from './AdminControls'
import Loader from '../shared/Loader'
import ViewerPrepaidCodes from './ViewerPrepaidCodes'

const Wrapper = styled.div`
  ${down('medium')} {
    & + & {
      border-top: 2px solid ${(props) => props.theme.colors.midDarkGrey};
    }
  }
`

const Inner = styled.table`
  width: 100%;
  border-collapse: collapse;
`

const Header = styled.tr`
  ${down('medium')} {
    display: none;
  }
`

const Heading = styled.th`
  ${BodyS}
  text-align: left;
  padding: 10px 15px;
  border-bottom: 1px solid ${(props) => props.theme.colors.blueTint};
  color: ${(props) => props.theme.colors.darkBlue};
  font-weight: 600;

  &:not(:last-child) {
    border-right: 1px solid ${(props) => props.theme.colors.blueTint};
  }
`

const Item = styled.tr`
  ${down('medium')} {
    display: flex;
    flex-wrap: wrap;
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${(props) => props.theme.colors.midDarkGrey};
  }
`

const OrderIndex = styled.td`
  ${HeaderL}
  padding: 15px;
  text-align: center;
  color: ${(props) => props.theme.colors.accent};
  vertical-align: top;
  border-right: 1px solid ${(props) => props.theme.colors.blueTint};

  ${down('medium')} {
    display: block;
    width: 50px;
    min-width: 50px;
    font-size: 20px;
    border-bottom: 1px solid ${(props) => props.theme.colors.blueTint};
  }
`

const Details = styled.td`
  position: relative;
  overflow: hidden;
  padding: 15px;

  &:not(:last-child) {
    border-right: 1px solid ${(props) => props.theme.colors.blueTint};
  }

  ${down('medium')} {
    display: block;
    width: calc(100% - 50px);
    border-bottom: 1px solid ${(props) => props.theme.colors.blueTint};
  }
`

const Amount = styled.td`
  padding-top: 10px;
  vertical-align: bottom;
  min-width: 300px;

  ${down('medium')} {
    display: block;
    width: 100%;
  }
`

const ItemInner = styled.div`
  display: flex;

  ${Media} {
    width: 120px;
    min-width: 120px;

    img {
      width: 100%;
    }
  }
`

const Content = styled.div`
  padding-left: 25px;

  &:first-child {
    padding-left: 0;
  }
`

const Title = styled.div`
  ${BodyS}
  font-weight: 700;
  margin-bottom: 20px;
`

const Sold = styled.div`
  ${SmallLetters}
  color: ${(props) => props.theme.colors.xDarkGrey};
`

const Seller = styled.a`
  display: inline-block;
  text-decoration: none;
  color: ${(props) => props.theme.colors.accent};
`

const Sub = styled.div`
  display: block;
`

// const Subtitle = styled.div`
//   ${BodyS}
//   position: relative;
//   margin-top: 10px;
//   margin-bottom: 10px;
//   color: ${(props) => props.theme.colors.darkBlue};
//   font-weight: 600;

//   ${(props) =>
//     props.border &&
//     css`
//       padding-top: 15px;
//       margin-top: 20px;

//       &::after {
//         content: '';
//       }
//     `};

//   &::after {
//     position: absolute;
//     top: 0;
//     left: 50%;
//     height: 1px;
//     transform: translateX(-50%);
//     width: calc(100% + 30px);
//     background: ${(props) => props.theme.colors.blueTint};
//   }
// `

const SubContent = styled.div`
  margin-top: 10px;
  padding-top: 10px;
  margin-bottom: 20px;
`

const Summary = styled.div`
  table {
    ${BodyS}
    color: ${(props) => props.theme.colors.darkBlue};
    width: 100%;
    border-collapse: collapse;
  }

  th {
    font-weight: inherit;
    padding: 5px 15px;
    text-align: left;
  }

  td {
    padding: 5px 15px;
    text-align: right;
  }

  tr:nth-last-child(2) th,
  tr:nth-last-child(2) td {
    padding-bottom: 15px;
  }

  tr:last-child th,
  tr:last-child td {
    font-weight: 900;
    padding-top: 15px;
    border-top: 1px solid ${(props) => props.theme.colors.blueTint};
  }

  > ${ButtonStyle}, > span ${ButtonStyle}, > ${InputContainer} {
    width: calc(100% - 30px);
    margin-bottom: 15px;
    margin-left: 15px;
    margin-top: 10px;
  }
`

const Gift = styled.div`
  position: relative;
  width: 100%;
  margin-top: 15px;

  form {
    flex-wrap: nowrap;
    align-items: flex-end;
    justify-content: flex-start;

    > *:first-child {
      flex-grow: 1;
      max-width: 250px;
      margin-left: 0;
    }

    > * {
      margin-right: 0;
      width: auto;
      margin-bottom: 0;
    }
  }
`

const COMPLETE_ORDER = `
  mutation CompleteOrder($orderId: ID!, $paymentId: ID!) {
    orderComplete(id: $orderId, paymentId: $paymentId) {
      success
      order {
        id

        paymentState

        paymentBalance {
          amount
          amountCents
          currency { iso }
        }

        amountRemaining {
          amount
          amountCents
          currency { iso }
        }
      }
    }
  }
`

const ORDER_CODE = `
  mutation OrderWithCode($id: ID!, $code: String!) {
    orderCreatePrepaidCodePayment(id: $id, code: $code) {
      success
      paymentId
    }
  }
`

const CHECK_CODE = `
  query CheckCode($code: String!) {
    paymentPrepaidCode(code: $code) {
      code
      value {
        amountCents
        currency {
          name
          iso
          digits
        }
        amount
      }
      amountRemaining {
        amountCents
        currency {
          name
          iso
          digits
        }
        amount
      }

      trade
    }
  }
`

const TAX_CALC = `
  mutation RecalculateOrderTax($id: ID!) {
    orderCalculateTax(id: $id) {
      id
      fullyPaidAt

      tax {
        amount
        amountCents
        currency { iso }
      }

      shippingCost {
        amount
        currency { iso }
      }

      discountTotal {
        amount
        amountCents
        currency { iso }
      }

      total {
        amount
        amountCents
        currency { iso }
      }

      amountRemaining {
        amount
        amountCents
        currency { iso }
      }

      paymentBalance {
        amount
        amountCents
        currency { iso }
      }
    }
  }
`

const VIEWER_PREPAID_CODES = `
  query ViewerPrepaidCodes {
    viewer {
      id
      prepaidCodes {
        id
        code
        value {
          amount
          currency { iso }
        }

        amountRemaining {
          amount
          amountCents
          currency { iso }
        }
      }
    }
  }
`

const Schema = Yup.object().shape({
  code: Yup.string(),
})

function Order({
  index,
  order,
  payment,
  shippingMethod,
  onOrderCompleted,
  syncOpenOrders,
}) {
  const urqlClient = useClient()

  const { user } = useUserContext()
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('newCard')
  const [paymentError, setPaymentError] = useState(null)
  const [completed, setCompleted] = useState(false)
  const [savePaymentMethod, setSavePaymentMethod] = useState(false)
  const [selectedSavedCardId, setSelectedSavedCardId] = useState(null)
  const [shouldShowFullPaymentMethods, setShouldShowFullPaymentMethods] =
    useState(!order.canAcceptPrepaidCodes)

  const [, completeOrder] = useMutation(COMPLETE_ORDER)
  const [, recalculateTax] = useMutation(TAX_CALC)
  const [{ data, fetching, error }, refetchViewerCodes] = useQuery({
    query: VIEWER_PREPAID_CODES,
    pause: !order.canAcceptPrepaidCodes,
  })

  useEffect(() => {
    if (data?.viewer) {
      const availableCodes = data.viewer.prepaidCodes.filter(
        (code) => code.amountRemaining.amountCents > 0
      )
      if (availableCodes.length == 0) setShouldShowFullPaymentMethods(true)
    }
  }, [data?.viewer, data?.viewer?.prepaidCodes, shouldShowFullPaymentMethods])

  if (fetching) return <Loader />
  if (error) return <p>There was an error loading your prepaid codes</p>

  async function handleSubmit(fields) {
    const parsedFields = Schema.cast(fields, { strict: true })
    const { data, fetching, error } = await urqlClient
      .query(CHECK_CODE, parsedFields)
      .toPromise()

    if (fetching) {
      toast('Fetching', { id: 'codeFetch' })
    }

    if (error) {
      toast.error('There has been an error', { id: 'codeFetch' })
      return
    }

    if (data.paymentPrepaidCode == null) {
      toast.error('No matches for your code', { id: 'codeFetch' })
      return
    }

    if (
      order.total.currency.iso != data.paymentPrepaidCode.value.currency.iso
    ) {
      toast.error('Code currency does not match order currency', {
        id: 'codeFetch',
      })
      return
    }

    if (data.paymentPrepaidCode.amountRemaining.amountCents <= 0) {
      toast.error('Your code does not have any remaining balance', {
        id: 'codeFetch',
      })
      return
    }

    orderCode(order.id, parsedFields.code)
  }

  async function orderCode(id, code) {
    const { data, error } = await urqlClient
      .mutation(ORDER_CODE, { id, code })
      .toPromise()

    if (!data?.orderCreatePrepaidCodePayment?.success || error) {
      toast.error('An error has ocurred', { id: 'codeFetch' })
      return
    }

    await handlePaymentSuccess(
      data.orderCreatePrepaidCodePayment.paymentId,
      false
    )

    const recalc = await recalculateTax({ id })
    syncOpenOrders()
    refetchViewerCodes({ requestPolicy: 'network-only' })

    if (recalc?.data?.orderCalculateTax?.fullyPaidAt) {
      setCompleted(true)
      onOrderCompleted(id)
    }
  }

  async function handlePaymentSuccess(paymentId, syncOrders = true) {
    const completeResult = await completeOrder({ orderId: order.id, paymentId })

    if (!completeResult?.data?.orderComplete?.success) {
      // FIXME: Once proper error mapping is implemented on the backend, we will
      // be able to show a more useful 'Some items are no longer available'
      // error where when it is appropriate to do so.
      setPaymentError('Something went wrong whilst completing the order')
      return
    }

    if (
      completeResult?.data?.orderComplete?.order?.paymentState ===
      'PARTIALLY_PAID'
    ) {
      toast.success('Partial payment was successful')
      if (syncOrders) syncOpenOrders()
      return { endProcessing: true }
    }

    setCompleted(true)
    onOrderCompleted(order.id)
  }

  return (
    <Item>
      <StripeWrapper>
        <PayPalScriptProvider
          options={{
            'client-id': process.env.RAZZLE_PAYPAL_CLIENT_ID,
          }}
        >
          <OrderIndex>{index + 1}</OrderIndex>

          <Details>
            {order.lineItems.map((lineItem) => {
              const image = lineItem.listing.item.images?.[0]
              return (
                <ItemInner key={lineItem.id}>
                  {image && (
                    <Media>
                      <img src={image.url} alt={lineItem.listing.item.title} />
                    </Media>
                  )}

                  <Content>
                    <Title>{lineItem.listing.item.title}</Title>
                    <Sold>
                      Sold by:{' '}
                      <Seller href={lineItem.listing.item.account.id}>
                        {lineItem.listing.item.account.displayName}
                      </Seller>
                    </Sold>
                  </Content>
                </ItemInner>
              )
            })}

            {shippingMethod && (
              <Sub>
                <SubContent>
                  <ShippingMethodSelect
                    orderId={order.id}
                    currentCost={
                      order.shippingMethod ? order?.shippingCost?.amount : null
                    }
                  />
                  <AdminControls
                    id={order.id}
                    taxExempt={order.taxExempt}
                    taxExemptReason={order.taxExemptReason}
                    taxExemptNo={
                      order.taxExemptNo ?? order?.contact?.taxExemptNo
                    }
                    salesperson={order.salesperson}
                    salesType={order.salesType}
                    shipDate={order.shipDate}
                    contactId={order.contact?.id}
                  />
                </SubContent>
              </Sub>
            )}

            {payment && (
              <Sub>
                {/* Hide for now while US only */}
                {/* <Subtitle border>Payment details</Subtitle> */}
                {/* <Notification text="This item is listed in USD. This may incur charges with your bank." /> */}

                {order.canAcceptPrepaidCodes && (
                  <Gift>
                    <Formik
                      onSubmit={handleSubmit}
                      validationSchema={Schema}
                      initialValues={{
                        code: '',
                      }}
                    >
                      <Form>
                        <Field>
                          <Input
                            label="Prepaid Code"
                            name="code"
                            id="code"
                            type="text"
                            placeholder="Enter Your Code"
                          />
                        </Field>

                        <Field justify="end">
                          <Button submit variant="primaryLight">
                            Apply code
                          </Button>
                        </Field>
                      </Form>
                    </Formik>
                  </Gift>
                )}

                {shouldShowFullPaymentMethods ? (
                  <SubContent>
                    <PaymentMethods
                      orderId={order.id}
                      remainingAmountCents={order.amountRemaining.amountCents}
                      taxCents={order.tax.amountCents}
                      includeStripeTerminal={
                        // Explicitly check for false - null doesn't count here
                        order.lineItems[0].listing.item.account
                          .isPrivateSeller === false &&
                        user.canUseStripeTerminal
                      }
                      includeManualPayment={user.canUseManualPayment}
                      onSuccess={handlePaymentSuccess}
                      selected={selectedPaymentMethod}
                      setSelected={(selected) => {
                        setSelectedPaymentMethod(selected)
                        setPaymentError(null)
                      }}
                      savePaymentMethod={savePaymentMethod}
                      setSavePaymentMethod={setSavePaymentMethod}
                      setSelectedSavedCardId={setSelectedSavedCardId}
                    />
                  </SubContent>
                ) : (
                  <ViewerPrepaidCodes
                    orderId={order.id}
                    showPaymentMethods={setShouldShowFullPaymentMethods}
                    prepaidCodes={data?.viewer?.prepaidCodes?.filter(
                      (code) => code.amountRemaining.amountCents > 0
                    )}
                    processCode={orderCode}
                  />
                )}

                {paymentError && <Notification text={paymentError} />}

                {completed && (
                  <Success text="Payment has been processed. Please complete all payments to proceed." />
                )}
              </Sub>
            )}
          </Details>

          {payment && (
            <Amount
              style={{
                paddingBottom: shouldShowFullPaymentMethods ? '0' : '8px',
              }}
            >
              <Summary>
                <OrderSummaryTable order={order} />

                {shouldShowFullPaymentMethods && (
                  <>
                    {selectedPaymentMethod === 'paypal' && (
                      <PayPalPaymentButton
                        orderId={order.id}
                        onSuccess={handlePaymentSuccess}
                        setError={setPaymentError}
                      />
                    )}

                    {(selectedPaymentMethod === 'newCard' ||
                      selectedPaymentMethod === 'savedCard') && (
                      <StripePaymentButton
                        orderId={order.id}
                        onSuccess={handlePaymentSuccess}
                        setError={setPaymentError}
                        savePaymentMethod={savePaymentMethod}
                        savedCardId={
                          selectedPaymentMethod === 'savedCard'
                            ? selectedSavedCardId
                            : null
                        }
                        remainingAmountCents={order.amountRemaining.amountCents}
                        taxCents={order.tax.amountCents}
                        allowAmountOverride={user.canUseManualPayment}
                      />
                    )}

                    {selectedPaymentMethod === 'manualPayment' && (
                      <ManualPaymentButton
                        orderId={order.id}
                        remainingAmountCents={order.amountRemaining.amountCents}
                        taxCents={order.tax.amountCents}
                        onSuccess={handlePaymentSuccess}
                        setError={setPaymentError}
                      />
                    )}

                    {selectedPaymentMethod === 'wire' && (
                      <ZendeskButton>
                        <Button variant="primaryDark">Contact Us</Button>
                      </ZendeskButton>
                    )}
                  </>
                )}
              </Summary>
            </Amount>
          )}
        </PayPalScriptProvider>
      </StripeWrapper>
    </Item>
  )
}

Order.propTypes = {
  index: PropTypes.number.isRequired,
  order: PropTypes.object.isRequired,
  payment: PropTypes.bool,
  shippingMethod: PropTypes.bool,
  onOrderCompleted: PropTypes.func,
  syncOpenOrders: PropTypes.func,
}

export default function Table({
  payment,
  shippingMethod,
  orders,
  onOrderCompleted,
  syncOpenOrders,
}) {
  return (
    <Wrapper>
      <Inner>
        <thead>
          <Header>
            <Heading>Order</Heading>
            <Heading>Details</Heading>
            {payment && <Heading>Amount</Heading>}
          </Header>
        </thead>

        <tbody>
          {orders.map((order, i) => (
            <Order
              key={order.id}
              index={i}
              order={order}
              payment={payment}
              shippingMethod={shippingMethod}
              onOrderCompleted={onOrderCompleted}
              syncOpenOrders={syncOpenOrders}
            />
          ))}
        </tbody>
      </Inner>
    </Wrapper>
  )
}

const moneyPropTypes = PropTypes.shape({
  amount: PropTypes.string.isRequired,
  currency: PropTypes.shape({
    iso: PropTypes.string.isRequired,
  }).isRequired,
}).isRequired

Table.propTypes = {
  payment: PropTypes.bool,
  shippingMethod: PropTypes.bool,
  onOrderCompleted: PropTypes.func,
  syncOpenOrders: PropTypes.func,
  orders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      canAcceptPrepaidCodes: PropTypes.boolean,
      subTotal: moneyPropTypes,
      shippingCost: moneyPropTypes,
      discountTotal: moneyPropTypes,
      total: moneyPropTypes,
      paymentBalance: moneyPropTypes,
      shipDate: PropTypes.string,
      taxExempt: PropTypes.bool,
      taxExemptReason: PropTypes.string,
      lineItems: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          listing: PropTypes.shape({
            item: PropTypes.shape({
              title: PropTypes.string.isRequired,
              account: PropTypes.shape({
                id: PropTypes.string.isRequired,
                username: PropTypes.string.isRequired,
              }).isRequired,
            }).isRequired,
          }).isRequired,
          amount: moneyPropTypes,
        })
      ).isRequired,
    })
  ).isRequired,
}
