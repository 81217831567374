import { useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { CardElement } from '@stripe/react-stripe-js'
import { BodyS } from '../_abstracts/Type'
import Transition from '../_abstracts/Animation'
import { Field as SelectWrapper } from '../inputs/Select'
import StripeTerminalPayment from '../payment/StripeTerminalPayment'
import Checkbox from '../shared/Checkbox'
import StripeCardSelect from './StripeCardSelect'

const PaymentMethod = styled.div`
  display: block;
  width: 100%;

  &:not(:first-child) {
    padding-top: 12px;
  }

  &:not(:last-child) {
    padding-bottom: 12px;
  }

  ${(props) =>
    props.containsSelect &&
    css`
      display: flex;
      flex-wrap: wrap;

      ${SelectWrapper} {
        width: auto;
      }
    `}

  ${(props) =>
    props.hide &&
    css`
      display: none;
    `}
`

const Trigger = styled.button`
  position: relative;
  padding-left: 25px;
  display: flex;
  align-items: center;
  width: 100%;
  border: 0;
  background-color: transparent;

  ${(props) =>
    props.containsSelect &&
    css`
      width: auto;
      margin-right: 20px;
    `}

  &:hover {
    cursor: pointer;
  }
`

const RadioContainer = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  display: inline-block;
  font-size: 0;
`

const RadioLabel = styled.span`
  ${BodyS};
  display: block;
  white-space: nowrap;

  ${RadioContainer} + & {
    margin-left: 8px;
  }
`

const StyledRadio = styled.div`
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
  background: transparent;
  border: 1px solid ${(props) => props.theme.colors.darkGrey};
  border-radius: 50%;
  ${Transition({ property: 'background-color, border' })};

  .active & {
    border: 1px solid ${(props) => props.theme.colors.darkBlue};
    background: ${(props) => props.theme.colors.darkBlue};
  }

  &::before {
    position: absolute;
    left: 50%;
    top: 50%;
    height: 60%;
    border-radius: 50%;
    width: 60%;
    background-color: ${(props) => props.theme.colors.white};
    content: '';
    transform: translate(-50%, -50%);
    transform-origin: left bottom;
  }
`

const Content = styled.div`
  display: none;

  > *:first-child {
    margin-top: 15px;
    margin-bottom: 15px;
    border: 1px solid ${(props) => props.theme.colors.midGrey};
    border-radius: 5px;
    padding: 5px;
    text-transform: capitalize;
  }

  .active & {
    display: block;
  }
`

export default function PaymentMethods({
  orderId,
  remainingAmountCents,
  taxCents,
  includeStripeTerminal,
  includeManualPayment,
  selected,
  setSelected,
  onSuccess,
  savePaymentMethod,
  setSavePaymentMethod,
  setSelectedSavedCardId,
}) {
  const [savedCardsAvailable, setSavedCardsAvailable] = useState(false)
  const [stripeCardCount, setStripeCardCount] = useState(0)

  return (
    <>
      {includeStripeTerminal && (
        <PaymentMethod className={selected === 'stripeTerminal' && 'active'}>
          <Trigger onClick={() => setSelected('stripeTerminal')}>
            <RadioContainer>
              <StyledRadio />
            </RadioContainer>

            <RadioLabel>Stripe Terminal</RadioLabel>
          </Trigger>

          <Content>
            {selected === 'stripeTerminal' && (
              <StripeTerminalPayment
                orderId={orderId}
                remainingAmountCents={remainingAmountCents}
                taxCents={taxCents}
                onSuccess={onSuccess}
              />
            )}
          </Content>
        </PaymentMethod>
      )}

      {includeManualPayment && (
        <PaymentMethod className={selected === 'manualPayment' && 'active'}>
          <Trigger onClick={() => setSelected('manualPayment')}>
            <RadioContainer>
              <StyledRadio />
            </RadioContainer>

            <RadioLabel>Manual Payment</RadioLabel>
          </Trigger>

          <Content>
            <p>Manual payments do not capture anything through the website.</p>
          </Content>
        </PaymentMethod>
      )}

      <PaymentMethod
        className={selected === 'savedCard' && 'active'}
        containsSelect
        hide={!savedCardsAvailable}
      >
        <Trigger
          containsSelect
          onClick={() => setSelected('savedCard')}
          disabled={!savedCardsAvailable}
        >
          <RadioContainer>
            <StyledRadio />
          </RadioContainer>
          <RadioLabel>Saved card</RadioLabel>
        </Trigger>

        <StripeCardSelect
          selected={selected}
          setAvailable={setSavedCardsAvailable}
          setSelectedCardId={setSelectedSavedCardId}
          setStripeCardCount={setStripeCardCount}
        />
      </PaymentMethod>

      <PaymentMethod className={selected === 'newCard' && 'active'}>
        <Trigger onClick={() => setSelected('newCard')}>
          <RadioContainer>
            <StyledRadio />
          </RadioContainer>

          <RadioLabel>New Card</RadioLabel>
        </Trigger>

        <Content>
          <CardElement />
          <Checkbox
            label="Save card for future payments"
            checked={savePaymentMethod}
            onChange={setSavePaymentMethod}
            disabled={stripeCardCount >= 30}
          />
          {stripeCardCount >= 30 && (
            <p>You are unable to save more than 30 cards.</p>
          )}
        </Content>
      </PaymentMethod>

      <PaymentMethod className={selected === 'paypal' && 'active'}>
        <Trigger onClick={() => setSelected('paypal')}>
          <RadioContainer>
            <StyledRadio />
          </RadioContainer>

          <RadioLabel>PayPal</RadioLabel>
        </Trigger>
      </PaymentMethod>

      <PaymentMethod className={selected === 'wire' && 'active'}>
        <Trigger onClick={() => setSelected('wire')}>
          <RadioContainer>
            <StyledRadio />
          </RadioContainer>

          <RadioLabel>Wire Transfer</RadioLabel>
        </Trigger>
      </PaymentMethod>
    </>
  )
}

PaymentMethods.propTypes = {
  orderId: PropTypes.string,
  remainingAmountCents: PropTypes.number,
  taxCents: PropTypes.number,
  includeStripeTerminal: PropTypes.bool,
  includeManualPayment: PropTypes.bool,
  selected: PropTypes.oneOf([
    'stripeTerminal',
    'manualPayment',
    'savedCard',
    'newCard',
    'paypal',
    'wire',
  ]).isRequired,
  setSelected: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  savePaymentMethod: PropTypes.bool,
  setSavePaymentMethod: PropTypes.func,
  setSelectedSavedCardId: PropTypes.func,
}
