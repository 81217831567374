import PropTypes from 'prop-types'
import { up } from 'styled-breakpoints'
import styled from 'styled-components'

import Transition from '../_abstracts/Animation'
import ShareButton from '../shared/ShareButton'
import Html from '../data/Html'
import { Icon, CrossIcon } from '../_base/Icon'
import { useGlobals } from '../hooks'

const Padding = 20

const Wrapper = styled.div`
  border-top: 1px solid ${(props) => props.theme.colors.midGrey};
  border-bottom: 1px solid ${(props) => props.theme.colors.midGrey};

  ${up('large')} {
    display: none;
  }
`

const Accordion = styled.ul`
  > *:not(:first-child) {
    border-top: 1px solid ${(props) => props.theme.colors.midGrey};
  }
`
const AccordionItem = styled.details`
  summary {
    padding: 8px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }
  ${Icon} {
    width: 15px;
    transform: rotate(45deg);
    will-change: transform;
    ${Transition({
      property: 'transform',
      duration: '250ms',
      ease: 'ease-out',
    })}
  }

  &[open] {
    ${Icon} {
      transform: rotate(180deg);
    }
  }
`

const Bottom = styled.div`
  padding: ${Padding}px ${Padding}px;
  > * {
    width: 100%;
  }
  button {
    width: 50%;
    margin: 0 auto;
  }
`

const AccordionContent = styled.div`
  padding: 25px ${Padding}px;
  background-color: ${(props) => props.theme.colors.lightGrey};
  color: ${(props) => props.theme.colors.xDarkGrey};
  font-weight: 300;
  min-width: 100%;
  height: fit-content;
  transform: translateX(0);
  ${Transition({ property: 'transform', duration: '300ms', ease: 'ease-out' })}
  will-change: transform;

  b {
    font-weight: 600;
  }
`

export default function ListingAccordion({ item }) {
  const { product } = useGlobals('product')

  const shareButton = {
    text: item.title,
    url: item.url,
    img: item.imgUrl,
  }

  return (
    <Wrapper>
      <Accordion>
        {item.description && (
          <AccordionItem>
            <summary>
              Description <CrossIcon />
            </summary>
            <AccordionContent>
              <Html html={item.description} limited />
            </AccordionContent>
          </AccordionItem>
        )}
        <AccordionItem>
          <summary>
            Spec <CrossIcon />
          </summary>
          <AccordionContent>
            <ul>
              {Object.entries(item.spec)
                .filter(([, val]) => val)
                .map(([key, val]) => (
                  <li key={key}>
                    <p>
                      <b>{key}:</b>{' '}
                      {typeof val == 'object' ? val[Object.keys(val)[0]] : val}
                    </p>
                  </li>
                ))}
            </ul>
          </AccordionContent>
        </AccordionItem>
        {product?.productShippingReturns && (
          <AccordionItem>
            <summary>
              {product.productShippingReturnsTitle} <CrossIcon />
            </summary>
            <AccordionContent>
              <div>
                <Html html={product.productShippingReturns} />
              </div>
            </AccordionContent>
          </AccordionItem>
        )}
      </Accordion>
      <Bottom>
        <ShareButton {...shareButton} />
      </Bottom>
    </Wrapper>
  )
}

ListingAccordion.propTypes = {
  item: PropTypes.shape({
    description: PropTypes.string,
    spec: PropTypes.object,
    url: PropTypes.string.isRequired,
    imgUrl: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    shipping: PropTypes.shape({
      tnagPackaging: PropTypes.bool,
      localPickup: PropTypes.bool,
      location: PropTypes.string,
      countries: PropTypes.array,
    }),
  }).isRequired,
}
