import PropTypes from 'prop-types'
import { up } from 'styled-breakpoints'
import styled, { css } from 'styled-components'
import { StyledLabel as Label } from '../forms/Label'
import { BodyS } from '../_abstracts/Type'
import { InputContainer, InputLabel } from '../_atoms/Input'
import { InputLabel as PhoneInputLabel } from '../inputs/Phone'

export const Wrapper = styled.div`
  width: 100%;
  margin: 13px;
  display: flex;
  flex-direction: column;

  ${({ $align }) =>
    $align &&
    css`
      text-align: ${$align};
    `}

  ${({ justify }) =>
    (justify === 'end' &&
      css`
        display: flex;
        justify-content: flex-end;
      `) ||
    (justify === 'reverse' &&
      css`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      `) ||
    (justify === 'reverse-space-between' &&
      css`
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
      `)}

  ${up('medium')} {
    ${({ $width }) =>
      $width === 'half' &&
      css`
        width: calc(50% - 26px);
      `}
  }

  ${Label} {
    margin-top: -5px;
    margin-bottom: 5px;
  }

  ${PhoneInputLabel},
  ${InputLabel} {
    margin-top: -5px;
    margin-bottom: 5px;

    ${Label} {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  ${InputContainer} {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`

const Description = styled.div`
  ${BodyS}
  margin-bottom: 24px;
`

export default function Field({
  className,
  children,
  description,
  align,
  justify,
  width,
}) {
  return (
    <Wrapper
      className={className}
      $width={width}
      $align={align}
      justify={justify}
    >
      {description && <Description>{description}</Description>}
      {children}
    </Wrapper>
  )
}

Field.defaultProps = {
  width: 'full',
}

Field.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  description: PropTypes.node,
  align: PropTypes.string,
  justify: PropTypes.oneOf(['end', 'reverse', 'reverse-space-between']),
  width: PropTypes.oneOf(['half', 'full']).isRequired,
}
