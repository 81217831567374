import PropTypes from 'prop-types'
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  TimeScale,
} from 'chart.js'
import 'chartjs-adapter-date-fns'
import { Scatter } from 'react-chartjs-2'

ChartJS.register(
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  TimeScale
)

// Plugin to shift a point's x-axis over slightly if it intersects with another point
// This only works for a single dataset right now - it won't check for intersections with other datasets
const jitterPlugin = {
  id: 'jitter',
  beforeDatasetDraw: (_chart, args) => {
    for (const point of args.meta.data) {
      for (const p of args.meta.data) {
        if (point === p) continue

        // Size of largest point, plus a small gap to add whitespace between points
        const size = Math.max(p.options.radius, point.options.radius) * 2 + 2

        const intersects =
          Math.abs(p.x - point.x) <= size && Math.abs(p.y - point.y) <= size

        if (intersects) {
          if (point.x > p.x) {
            point.x += size - (point.x - p.x)
          } else {
            point.x -= size - (p.x - point.x)
          }
        }
      }
    }
  },
}

ChartJS.register(jitterPlugin)

export default function ScatterChart({ data, options }) {
  return <Scatter data={data} options={options} />
}

ScatterChart.propTypes = {
  data: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired,
}
