import { Outlet } from 'react-router-dom'
import styled from 'styled-components'
import Container from '../_helpers/Container'
import Spacer from '../_helpers/Spacer'
import Tabs from '../shared/Tabs'

const Wrapper = styled.div`
  flex-grow: 1;
  background-image: ${({ theme }) =>
    `linear-gradient(${theme.colors.white}, ${theme.colors.lighterGrey})`};
`

export default function MessagesLayout() {
  return (
    <Wrapper>
      <Spacer fromSize="30px" toSize="60px" />
      <Container>
        <Tabs
          tabs={[
            { title: 'Inbox', url: '/messages', end: true },
            // { title: 'Unread', url: '/messages/unread' },
            // { title: 'Saved', url: '/messages/saved' },
            // { title: 'Offers', url: '/messages/offers' },
          ]}
        >
          <Outlet />
        </Tabs>
      </Container>
      <Spacer fromSize="40px" toSize="80px" />
    </Wrapper>
  )
}

MessagesLayout.propTypes = {}
