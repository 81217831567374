import styled from 'styled-components'
import { useGlobals } from '../hooks'
import { HeaderL } from '../_abstracts/Type'
import Button from '../_atoms/Button'
import Html from '../data/Html'
import Loader from '../shared/Loader'
import ZendeskButton from '../shared/ZendeskButton'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px calc(10px + 3%);
`

const Title = styled.h2`
  ${HeaderL}
  margin-bottom: calc(10px + 0.2em);
  text-align: center;
`

const Content = styled.div`
  margin-bottom: 1.5em;
`

export default function Process() {
  const { trade } = useGlobals('trade')

  return (
    <Wrapper>
      {(trade && (
        <>
          <Title>{trade.tradeModalTitle}</Title>
          <Content>
            <Html html={trade.tradeModalText} />
          </Content>
          <ZendeskButton message={trade.zendeskMessage}>
            <Button variant="primaryDark">{trade.zendeskButtonLabel}</Button>
          </ZendeskButton>
        </>
      )) || <Loader background="#fff" />}
    </Wrapper>
  )
}
