import PropTypes from 'prop-types'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'

const publishableKey =
  process.env.RAZZLE_STRIPE_PK || process.env.STORYBOOK_STRIPE_PK
const stripe = loadStripe(publishableKey)

export default function StripeWrapper({ children }) {
  return <Elements stripe={stripe}>{children}</Elements>
}

StripeWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}
