import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useMutation } from 'urql'
import toast from 'react-hot-toast'
import { useSearchParams } from 'react-router-dom'
import * as Yup from 'yup'
import Input, { InputContainer } from '../_atoms/Input'
import Button from '../_atoms/Button'
import Form from '../_atoms/Form'
import { Formik } from 'formik'
import StyledLink from '../_atoms/Link'
import Warning from '../shared/Warning'
import { useGlobals } from '../hooks'

const WarningWrapper = styled.div`
  padding-bottom: 20px;
`

const CREATE_SESSION = `
  mutation CreateSession($email: String!, $password: String!) {
    sessionCreate(email: $email, password: $password) {
      success
      account {
        id
      }
    }
  }
`

const Schema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string().required('Required'),
})

export default function LoginForm({ reloadUser, returnTo }) {
  const [catchError, setCatchError] = useState({ errorInfo: '' })
  const [searchParams] = useSearchParams()
  const [{ data, fetching, error }, createSession] = useMutation(CREATE_SESSION)
  const { loginRegister } = useGlobals('loginRegister')

  async function handleSubmit({ email, password }) {
    const { data } = await createSession({ email, password })

    if (data?.sessionCreate.success) {
      console.log('successfully logged in')
      reloadUser(
        returnTo ||
          searchParams.get('return_to') ||
          loginRegister?.loginRedirectUrl ||
          '/'
      )
      toast.success('Logged in')
    }
  }

  if (fetching) return <p>Logging in...</p>

  if (error) {
    setTimeout(() => {
      setCatchError({ errorInfo: error.toString() })
    }, 0)
  }

  return (
    <>
      {error && (
        <WarningWrapper>
          <Warning
            color="red"
            text={'Error logging in: ' + catchError.errorInfo}
          ></Warning>
        </WarningWrapper>
      )}
      {data?.sessionCreate && !data.sessionCreate.success && (
        <WarningWrapper>
          <Warning
            color="red"
            text="Incorrect login details, please try again"
          ></Warning>
        </WarningWrapper>
      )}
      <Formik
        initialValues={{ email: '', password: '' }}
        onSubmit={handleSubmit}
        validationSchema={Schema}
      >
        <Form>
          <Input label="Email" type="email" name="email" required />
          <Input label="Password" type="password" name="password" required />
          <InputContainer>
            <StyledLink to="/forgotten-password">Forgot password?</StyledLink>
          </InputContainer>
          <Button fullWidth submit variant="primaryDark">
            Sign in
          </Button>
        </Form>
      </Formik>
    </>
  )
}

LoginForm.propTypes = {
  reloadUser: PropTypes.func.isRequired,
  returnTo: PropTypes.string,
}
