import { hideVisually } from 'polished'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import PaymentIcon, { icons } from './PaymentIcon'

const Wrapper = styled.div``

const Title = styled.p`
  ${hideVisually};
`

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: ${({ gap }) => `${-gap / 2}px`};
`

const Item = styled.li`
  flex-shrink: 0;
  margin: ${({ gap }) => `${gap / 2}px`};
`

export default function PaymentIconList({ gap }) {
  return (
    <Wrapper>
      <Title>Accepted payment methods:</Title>
      <List gap={gap}>
        {Object.keys(icons).map((icon, index) => (
          <Item gap={gap} key={index}>
            <PaymentIcon icon={icon} />
          </Item>
        ))}
      </List>
    </Wrapper>
  )
}

PaymentIconList.defaultProps = {
  gap: 6,
}

PaymentIconList.propTypes = {
  gap: PropTypes.number,
}
