import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Media, MediaImage } from '../_base/Media'
import { HeaderSansXS } from '../_abstracts/Type'
import { down } from 'styled-breakpoints'
import DateTime from '../data/DateTime'
import Money from '../data/Money'
import OfferState, { offerStates } from '../data/OfferState'
import BlockList from './BlockList'
import Warning from './Warning'
import Offer from './Offer'
import Link from './Link'

const Wrapping = styled.div`
  padding: 26px;

  ${down('large')} {
    padding: 20px;
  }

  & + & {
    border-top: 1px solid ${(props) => props.theme.colors.darkGrey};
  }
`

const Inner = styled.div`
  display: flex;
  padding-bottom: 10px;
  margin-top: -7px;
  border-bottom: 1px solid ${(props) => props.theme.colors.blueTint};

  &:last-child {
    padding-bottom: 0;
    border-bottom: 0;
  }

  ${down('medium')} {
    flex-wrap: wrap;
  }
`

const List = styled.div`
  margin-right: -5px;
  margin-left: -5px;
`

const Image = styled.div`
  padding: 7px 0;
  min-width: 150px;

  ${down('medium')} {
    order: 1;
    max-width: 150px;
    padding: 0 0 7px;
    margin-left: auto;
    margin-right: auto;
  }

  ${down('small')} {
    max-width: 100%;
    width: 100%;
  }

  ${Media} {
    border: 1px solid ${(props) => props.theme.colors.midGrey};
    border-radius: ${(props) => props.theme.radii};
    padding-bottom: 100%;
    overflow: hidden;
  }
`

const Content = styled.div`
  padding-left: 7px;
  flex-grow: 1;

  ${down('medium')} {
    order: 0;
    padding-left: 0;
  }
`

const Top = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10px;
`

const Footer = styled.div`
  padding: 7px;
  width: 100%;

  ${down('medium')} {
    padding-right: 0;
    padding-left: 0;
  }
`

const Left = styled.div`
  padding: 7px;

  ${down('medium')} {
    padding-bottom: 0;
    padding-left: 0;
  }
`

const Right = styled.div`
  padding: 7px;
  text-align: right;

  ${down('medium')} {
    padding-left: 0;
    padding-right: 0;
    text-align: left;
  }
`

const Header = styled(Link)`
  ${HeaderSansXS}
  font-weight: 700;
`

export default function OfferWrapper({
  item,
  offer,
  onStateChange,
  includeListingStats = true,
}) {
  const { listing } = item
  const image = item.images?.[0]

  const blockListItems = [
    {
      title: 'Watchers',
      value: item.wishlistCount,
    },
    {
      title: 'Offers',
      value: item.offerCount,
    },
  ]

  if (listing) {
    blockListItems.unshift({
      title: 'Views',
      value: listing.viewCount,
    })

    blockListItems.unshift({
      title: 'Listing date',
      value: <DateTime date={listing.createdAt} noTime />,
    })

    blockListItems.push({
      title: 'Listed for',
      value: (
        <Money
          amount={listing.askingPrice.amount}
          currency={listing.askingPrice.currency.iso}
        />
      ),
    })
  }

  const currentIteration = offer?.currentIteration || offer

  return (
    <Wrapping>
      <Inner>
        {image && (
          <Image>
            <Media>
              <MediaImage src={image.url} alt={item.title} />
            </Media>
          </Image>
        )}

        <Content>
          <Top>
            <Left>
              <Header to={`/shop/${item.slug}/${item.id}`}>{item.title}</Header>
            </Left>

            <Right>
              <Warning
                color={
                  (currentIteration.state === offerStates.ACCEPTED &&
                    'green') ||
                  (currentIteration.state === offerStates.REJECTED && 'red') ||
                  'gold'
                }
                text={
                  <>
                    Status: <OfferState state={currentIteration.state} />
                  </>
                }
              />
            </Right>

            <Footer>
              <Offer offer={offer} item={item} onStateChange={onStateChange} />
            </Footer>
          </Top>
        </Content>
      </Inner>

      {includeListingStats && (
        <List>
          <BlockList header="Listing stats" list={blockListItems} />
        </List>
      )}
    </Wrapping>
  )
}

OfferWrapper.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    images: PropTypes.arrayOf(
      PropTypes.shape({ url: PropTypes.string.isRequired })
    ),
    offerCount: PropTypes.number.isRequired,
    wishlistCount: PropTypes.number.isRequired,
    listing: PropTypes.shape({
      askingPrice: PropTypes.shape({
        amount: PropTypes.string.isRequired,
        currency: PropTypes.shape({
          iso: PropTypes.string.isRequired,
        }).isRequired,
      }),
      createdAt: PropTypes.instanceOf(Date).isRequired,
      viewCount: PropTypes.number.isRequired,
    }),
  }),
  offer: PropTypes.object.isRequired,
  onStateChange: PropTypes.func,
  includeListingStats: PropTypes.bool,
}
