import { useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useOutletContext } from 'react-router-dom'
import styled from 'styled-components'
import { useQuery } from 'urql'
import NextPrevious from '../shared/NextPrevious'
import Loader from '../shared/Loader'
import Subheader from '../shared/Subheader'
import SearchResults from '../shop/SearchResults'

const ITEMS_PER_PAGE = 12

const GET_ITEMS = `
  query GetItems($accountId: ID!, $first: Int, $last: Int, $after: String, $before: String) {
    items(accountId: $accountId, first: $first, last: $last, after: $after, before: $before) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }

      edges {
        cursor
        node {
          id
          slug
          images {
            id
            url(size: "productThumb")
          }
          listing {
            id
            isAvailable
            isAvailableSearch
          }
          madeInYear
          title
          inViewerWishlist
          account {
            id
            username
            displayName
            isPrivateSeller
            isProfessionalSeller
          }
        }
      }
    }
  }
`

const Content = styled.div`
  padding: 22px;
`

const Message = styled.p`
  text-align: center;
`

export default function PublicProfilePortfolioIndex() {
  const { user } = useOutletContext()
  const contentRef = useRef()
  const [pagination, setPagination] = useState({ first: ITEMS_PER_PAGE })

  const [{ data, fetching, error }] = useQuery({
    query: GET_ITEMS,
    variables: { accountId: user.id, ...pagination },
  })

  const items = data?.items?.edges?.map(({ node }) => ({
    item: node,
    linkType: 'item',
  }))
  const pageInfo = data?.items?.pageInfo

  const scrollToTop = () =>
    contentRef.current?.scrollIntoView({ behavior: 'smooth' })

  return (
    <>
      <Helmet>
        <title>{user.username} | Portfolio</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Subheader header="Portfolio" border />
      <Content ref={contentRef}>
        {(fetching && <Loader background="#fff" height="250px" />) ||
          (error && <Message>{error.message}</Message>) ||
          ((!items || !items.length) && <Message>No items found</Message>) || (
            <SearchResults results={items} onlyAvailable={false} />
          )}
      </Content>
      <NextPrevious
        includeNext={pageInfo?.hasNextPage || !!pagination.before}
        includePrevious={pageInfo?.hasPreviousPage || !!pagination.after}
        onNext={() => {
          scrollToTop()
          setPagination({ after: pageInfo.endCursor, first: ITEMS_PER_PAGE })
        }}
        onPrevious={() => {
          scrollToTop()
          setPagination({
            before: pageInfo.startCursor,
            last: ITEMS_PER_PAGE,
          })
        }}
      />
    </>
  )
}
