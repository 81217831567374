import PropTypes from 'prop-types'
import { useRef } from 'react'
import styled from 'styled-components'
import Input, { InputContainer } from '../_atoms/Input'
import Transition from '../_abstracts/Animation'
import { Icon, SearchIcon } from '../_base/Icon'

const Wrapper = styled.div`
  display: flex;

  ${InputContainer} {
    margin-bottom: 0;
  }
`

const StyledButton = styled.button.attrs({ type: 'button' })`
  ${Transition({ property: 'background-color, color' })};
  flex-shrink: 0;
  width: 40px;
  margin-left: 5px;
  display: flex;
  background-color: ${({ theme }) => theme.colors.blueTint};
  border-radius: ${(props) => props.theme.radii};

  &:hover {
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.darkBlue};
  }

  ${Icon} {
    width: 18px;
    margin: auto;
  }
`

export const Button = ({ ariaLabel, onClick }) => (
  <StyledButton aria-label={ariaLabel} onClick={onClick}>
    <SearchIcon />
  </StyledButton>
)

Button.propTypes = {
  ariaLabel: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default function Search({ setFilter }) {
  const keywordsRef = useRef()

  return (
    <Wrapper>
      <Input
        label="Keywords"
        hideLabel
        name="keywords"
        type="search"
        ref={keywordsRef}
        onChange={(ev) => {
          // Unset keywords query if input has been cleared
          if (!ev.target.value.trim()) {
            setFilter('keywords', '')
          }
        }}
        onKeyDown={(ev) => {
          if (ev.keyCode == 13) {
            setFilter('keywords', ev.target.value.trim())
          }
        }}
      />
      <Button
        ariaLabel="Search keywords"
        onClick={() => {
          if (keywordsRef.current) {
            setFilter('keywords', keywordsRef.current.value.trim())
          }
        }}
      />
    </Wrapper>
  )
}

Search.propTypes = {
  setFilter: PropTypes.func.isRequired,
}
