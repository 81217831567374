import PropTypes from 'prop-types'
import { useState } from 'react'
import styled from 'styled-components'

import Money from '../data/Money'
import { HeaderSansXS } from '../_abstracts/Type'
import Button from '../_atoms/Button'
import ReturnLink from '../_atoms/ReturnLink'
import {
  AppraisalIcon,
  Icon,
  PaypalIcon,
  ShipmentIcon,
  CreditCardIcon,
  AuthenticatedIcon,
} from '../_base/Icon'
import { useUserContext } from '../auth/UserContext'
import { useGlobals } from '../hooks'
import TradeProcess from '../trade/Process'
import Translation from '../shared/Translation'
import MakeOfferModal from '../offers/MakeOfferModal'
import Modal from '../shared/Modal'
import ProductCartButton from './ProductCartButton'
import Warning from '../shared/Warning'
import WishlistButtonInline from '../shop/WishlistButtonInline'
import StyledLink from '../_atoms/Link'
import ButtonOpenAdmin from '../shared/ButtonOpenAdmin'
import Tooltip, { Wrapper as TooltipWrapper } from '../shared/Tooltip'
import Html from '../data/Html'
import ModalForm from '../shared/ModalForm'
import ZendeskButton from '../shared/ZendeskButton'

const Wrapper = styled.div`
  border: 1px solid ${(props) => props.theme.colors.midGrey};
  border-radius: ${(props) => props.theme.radii};
  background-color: ${(props) => props.theme.colors.white};
`

const InnerWrapper = styled.div`
  padding: 15px 17px;
  border-top: 1px solid ${(props) => props.theme.colors.midGrey};
`

const Stats = styled.div`
  padding: 15px 17px;
`

const Title = styled.h1`
  ${HeaderSansXS}
`

const Price = styled.div`
  ${HeaderSansXS}
  margin-top: 15px;
  font-weight: 600;
`

const Buttons = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  gap: 10px;

  > * {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;
    white-space: nowrap;
    min-width: calc(50% - 10px);
  }

  span {
    white-space: nowrap;
  }
`

const Payments = styled.div`
  margin-top: 10px;
  margin-right: 10px;
  width: fit-content;
  display: flex;
  align-items: center;
  padding: 3px 16px;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 20px;
  border: 1px solid ${(props) => props.theme.colors.grey};
  width: 60px;
`

const PaymentsSecond = styled.div`
  margin-top: 10px;
  margin-right: 10px;
  width: fit-content;
  display: flex;
  align-items: center;
  padding: 3px 16px;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 20px;
  border: 1px solid ${(props) => props.theme.colors.grey};
  width: 100px;
`

const PaymentsThird = styled.div`
  margin-top: 10px;
  width: fit-content;
  display: flex;
  align-items: center;
  padding: 3px 16px;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 20px;
  border: 1px solid ${(props) => props.theme.colors.grey};
  font-size: 14px;
`

const PaymentsWrapper = styled.div`
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #c4c6c8;
  padding-left: 17px;
  padding-right: 17px;
  padding-bottom: 10px;
`

const Info = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;

  > div {
    display: flex;
    font-size: 12px;
    align-items: center;
    margin-top: 10px;
  }

  p {
    flex-grow: 2;
  }

  ${Icon} {
    width: 20px;
    min-width: 20px;
    margin-right: 5px;
  }

  ${TooltipWrapper} {
    margin-left: 5px;
  }
`

const InfoWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.white};
`

const OfferMade = styled.div`
  margin-top: 10px;

  > * {
    width: 100%;
  }
`

const WishlistWrapper = styled.div``

export default function ProductPurchaseCard({ item, seller }) {
  const { product } = useGlobals('product')
  const [currentViewerOffer, setCurrentViewerOffer] = useState(
    !!item.currentViewerOffer
  )
  const { user } = useUserContext()

  return (
    <Wrapper>
      <Stats>
        <Title>{item.title}</Title>
        {(item.listing.isAvailable || user?.isAdmin) && (
          <Price>
            <Money
              amount={item.listing.askingPrice.amount}
              currency={item.listing.askingPrice.currency.iso}
            />
          </Price>
        )}
      </Stats>
      <InnerWrapper>
        {(item.listing.isAvailable && item.listing.forSale && (
          <>
            <ButtonOpenAdmin item={item} />
            <ProductCartButton
              addButtonLabel={
                <Translation>{product?.productAddToCartButton}</Translation>
              }
              listingId={item.listing.id}
              localPickupOnly={item.localPickupOnly}
              inViewerCart={item.listing.inViewerCart}
              removeButtonLabel={
                <Translation>
                  {product?.productRemoveFromCartButton}
                </Translation>
              }
            />
            {(item.acceptingOffers || seller.isCarterVintage) && (
              <Buttons>
                {item.acceptingOffers && (
                  <>
                    {(user && (
                      <>
                        {currentViewerOffer ? (
                          <Button
                            variant="secondary"
                            to="/profile/buying/offers"
                          >
                            View offer
                          </Button>
                        ) : (
                          <MakeOfferModal
                            buttonLabel={
                              <Translation>
                                {product?.productMakeOfferButton}
                              </Translation>
                            }
                            item={item}
                            onStateChange={() => setCurrentViewerOffer(true)}
                          />
                        )}
                      </>
                    )) || (
                      <>
                        <ReturnLink>
                          <Button variant="secondary">
                            <Translation>
                              {product?.productMakeOfferButton}
                            </Translation>
                          </Button>
                        </ReturnLink>
                      </>
                    )}
                    <Modal
                      trigger={
                        <Button variant="secondary">
                          <Translation>
                            {product?.productTradeButton}
                          </Translation>
                        </Button>
                      }
                      size="standard"
                    >
                      <TradeProcess />
                    </Modal>
                  </>
                )}
                {seller.isCarterVintage && (
                  <Modal
                    trigger={
                      <Button variant="tertiary">
                        <Translation>
                          {product?.productDemoRequestButton}
                        </Translation>
                      </Button>
                    }
                    size="large"
                  >
                    <ModalForm
                      handle="demoRequest"
                      header={product?.demoRequestTitle}
                      info={product?.demoRequestText}
                      successMessage={product?.demoRequestSuccessMessage}
                      content={null}
                      requireUser={false}
                      fields={{
                        senderName: user
                          ? `${user.firstName} ${user.lastName}`
                          : '',
                        senderEmail: user ? user.email : '',
                        senderUsername: user ? user.username : '',
                        itemUrl: `${process.env.RAZZLE_BASE_FRONTEND_URL}/shop/${item.slug}/${item.id}`,
                      }}
                    />
                  </Modal>
                )}
              </Buttons>
            )}

            {currentViewerOffer && (
              <OfferMade>
                <Warning
                  text={
                    <>
                      You have made an offer on this item.{' '}
                      <StyledLink to="/profile/buying/offers">
                        View your offers
                      </StyledLink>
                    </>
                  }
                ></Warning>
              </OfferMade>
            )}
          </>
        )) || (
          <>
            <ButtonOpenAdmin item={item} />
            {item.listing.isAvailable ? ( // if the listing is available but we're still here, it's marked as not forSale
              item.account.isCarterVintage ? (
                <ZendeskButton>
                  <Button variant="secondary" fullWidth>
                    Message Carter Vintage
                  </Button>
                </ZendeskButton>
              ) : (
                <Button
                  fullWidth
                  to={`/messages/compose?itemId=${item.id}`}
                  variant="secondary"
                >
                  Message Seller
                </Button>
              )
            ) : (
              <Button disabled fullWidth>
                {item.listingOnHold
                  ? 'This instrument is currently on hold'
                  : 'Sold'}
              </Button>
            )}
          </>
        )}
        <PaymentsWrapper>
          <Payments>
            <CreditCardIcon />
          </Payments>
          <PaymentsSecond>
            <PaypalIcon />
          </PaymentsSecond>
          <PaymentsThird>
            <p>Wire Transfer</p>
          </PaymentsThird>
        </PaymentsWrapper>
        <InfoWrapper>
          <Info>
            <div>
              <AuthenticatedIcon />
              <p>Authenticated by Vintage Experts</p>
            </div>
            <div>
              <ShipmentIcon />
              <p>Shipments Protected at Full Value</p>
            </div>
            <div>
              <AppraisalIcon />
              {product?.productInvoiceTerms && (
                <>
                  <p>{product.productInvoiceTerms}</p>
                  {product?.htmlTooltip && (
                    <Tooltip>
                      <Html html={product.htmlTooltip} />
                    </Tooltip>
                  )}
                </>
              )}
            </div>
          </Info>
        </InfoWrapper>
        <WishlistWrapper>
          <WishlistButtonInline
            itemId={item.id}
            inViewerWishlist={item.inViewerWishlist} // item.inViewerWishlist
          />
        </WishlistWrapper>
      </InnerWrapper>
    </Wrapper>
  )
}

ProductPurchaseCard.propTypes = {
  item: PropTypes.object.isRequired,
  seller: PropTypes.shape({
    isCarterVintage: PropTypes.bool.isRequired,
  }).isRequired,
}
