import PropTypes from 'prop-types'
import styled from 'styled-components'
import SavedSearchLink from './SavedSearchLink'

const Item = styled.li`
  margin-bottom: 10px;
  line-height: 1.3;
  border-bottom: 1px solid ${(props) => props.theme.colors.midGrey};
  padding-bottom: 10px;

  &:last-child {
    margin-bottom: 12px;
  }
`

const Bold = styled.span`
  font-weight: 700;
`

const formatSavedSearchLabel = (label) => {
  if (label.includes('[')) {
    try {
      const parsed = JSON.parse(label)
      return parsed.join(', ')
      // eslint-disable-next-line no-empty
    } catch {}
  }

  return label
}

export default function SavedSearchList({ savedSearches }) {
  return (
    <div>
      <ul>
        {savedSearches.map((search) => (
          <Item key={search.id}>
            <SavedSearchLink search={search}>
              {search.keywords && (
                <>
                  <Bold>Keywords:</Bold> {search.keywords}
                </>
              )}
              {search.filters &&
                search.filters.map((filter, index) => (
                  <p key={index}>
                    <Bold>{filter.label}:</Bold>{' '}
                    {formatSavedSearchLabel(filter.value)}
                  </p>
                ))}
            </SavedSearchLink>
          </Item>
        ))}
      </ul>
    </div>
  )
}

SavedSearchList.propTypes = {
  savedSearches: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      keywords: PropTypes.string,
      filters: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.string.isRequired,
          label: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired,
        })
      ),
    })
  ).isRequired,
}
