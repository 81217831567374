import { useFormikContext, Field as FormikField } from 'formik'
import { forwardRef } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { useId } from 'react-id-generator'
import Transition from '../_abstracts/Animation'
import Label, { StyledLabel } from '../forms/Label'
import ErrorMessage from './ErrorMessage'
import { up } from 'styled-breakpoints'
import { Wrapper as CheckboxWrapper } from '../shared/Checkbox'
import Tooltip, { Wrapper as TooltipWrapper } from '../shared/Tooltip'

// Elements
// ~~~~~~~~~~~~~~~~~~~~~~~ //

export const InputContainer = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  font-family: ${(props) => props.theme.fonts.sansSerif};
  width: 100%;
  border: 0;
  margin-bottom: 20px;

  ${(props) =>
    props.inline &&
    css`
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      margin-bottom: -5px;

      &:last-child {
        margin-bottom: -5px !important;
      }

      ${InputLabel} {
        margin-right: 26px;
        white-space: nowrap;
      }
    `}

  &:last-child {
    margin-bottom: 0;
  }

  ${(props) =>
    props.size === 'small'
      ? css`
          ${up('medium')} {
            width: 47%;
          }
        `
      : css``}

  textarea {
    min-height: 125px;
    resize: vertical;
  }
`

export const InputLabel = styled.span`
  font-size: 14px;
  text-transform: capitalize;

  ${StyledLabel} {
    margin-top: -5px;
    margin-bottom: 5px;
  }

  ${(props) =>
    props.color === 'grey' &&
    css`
      color: ${(props) => props.theme.colors.xDarkGrey};
      font-weight: 300;
    `}

  ${({ readonly }) =>
    readonly &&
    css`
      color: ${({ theme }) => theme.colors.darkGrey};
    `}

  ${TooltipWrapper} {
    position: relative;
    top: -2px;
    display: inline-block;
    margin-left: 7px;
  }

  ${CheckboxWrapper} {
    margin-top: 5px;
    margin-bottom: 5px;
  }
`

export const Inner = styled.div`
  position: relative;

  ${({ inline }) =>
    (inline &&
      css`
        flex-grow: 1;
        margin-bottom: 5px;
      `) ||
    css`
      width: 100%;
    `}
`

const Prefix = styled.span`
  position: absolute;
  top: 50%;
  left: 6px;
  font-size: 15px;
  line-height: 1;
  transform: translateY(-50%);
  opacity: 0.5;
  pointer-events: none;
`

const Suffix = styled.span`
  position: absolute;
  top: 50%;
  right: 15px;
  font-size: 15px;
  line-height: 1;
  transform: translateY(-50%);
  opacity: 0.5;
  pointer-events: none;
`

export const InputField = styled.input`
  ${Transition({ property: 'border-color, box-shadow' })};
  padding: 8px 0 8px 12px;
  border-radius: ${(props) => props.theme.radii};
  border: 1px solid ${(props) => props.theme.colors.midGrey};
  background: ${(props) => props.theme.colors.white};
  width: 100%;
  color: ${(props) => props.theme.colors.darkBlue};
  font-size: 15px;

  @media (hover: none) {
    font-size: 16px;
  }

  ${(props) =>
    props.as === 'select' &&
    css`
      background: linear-gradient(45deg, transparent 50%, currentColor 50%),
        linear-gradient(135deg, currentColor 50%, transparent 50%);
      background-position: calc(100% - 18px) 18px, calc(100% - 13px) 18px;
      background-size: 5px 5px;
      background-repeat: no-repeat;
      appearance: none;
      padding-right: 35px;
    `}

  ${({ numberSpinner }) =>
    !numberSpinner &&
    css`
      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    `}

  &::placeholder {
    color: ${(props) => props.theme.colors.xDarkGrey};
    font-family: ${(props) => props.theme.fonts.sansSerif};
    opacity: 0.7;
  }

  &:focus {
    border-color: ${({ theme }) => theme.colors.darkBlue};
    box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.darkBlue};
    outline: none;
  }

  &[disabled] {
    color: ${({ theme }) => theme.colors.darkGrey};
    opacity: 0.6;
    pointer-events: none;
  }

  ${Transition({ property: 'color, border-color, opacity' })}

  ${(props) =>
    props.$error &&
    css`
      border-color: ${(props) => props.theme.colors.red};
    `}

  ${({ readOnly }) =>
    readOnly &&
    css`
      color: ${({ theme }) => theme.colors.darkGrey};
    `}

  i + span {
    margin-left: 10px;
  }

  ${Prefix} + & {
    padding-left: 18px;
  }
`

const Description = styled.div`
  margin-bottom: 2px;
  line-height: 1.4;
  text-transform: none;
  color: ${({ theme }) => theme.colors.xDarkGrey};
  opacity: 0.75;
`

const Input = forwardRef(
  (
    {
      autocomplete,
      autofocus,
      className,
      disabled,
      error,
      label,
      color,
      size,
      type,
      max,
      min,
      name,
      placeholder,
      prefix,
      suffix,
      numberSpinner,
      readonly,
      required,
      step,
      inputAs,
      children,
      note,
      inline = false,
      hideLabel,
      description,
      parse,
      onChange,
      onKeyDown,
      defaultValue,
      maxLength,
      value,
    },
    ref
  ) => {
    const formik = useFormikContext()
    const fieldId = useId()[0]
    const canFieldIncludeChildren = inputAs === 'select'

    return (
      <InputContainer className={className} size={size} inline={inline}>
        <InputLabel color={color} readonly={readonly}>
          <Label for={fieldId} hide={hideLabel}>
            {label}
          </Label>
          {required && !hideLabel && '*'}
          {note && <Tooltip size="small">{note}</Tooltip>}
          {description && <Description>{description}</Description>}
        </InputLabel>
        <Inner inline={inline}>
          {prefix && <Prefix>{prefix}</Prefix>}
          {(name && (
            <FormikField name={name}>
              {({ field, meta }) => (
                <InputField
                  as={inputAs}
                  $error={error || (meta.touched && !!meta.error)}
                  autoComplete={autocomplete}
                  autoFocus={autofocus}
                  id={fieldId}
                  max={max}
                  min={min}
                  disabled={disabled}
                  placeholder={placeholder}
                  readOnly={readonly}
                  ref={ref}
                  required={required}
                  step={step}
                  type={type}
                  name={field.name}
                  maxLength={maxLength}
                  value={field.value}
                  numberSpinner={numberSpinner}
                  onBlur={field.onBlur}
                  onChange={(ev) => {
                    onChange && onChange(ev)
                    formik.setFieldValue(
                      name,
                      parse ? parse(ev.target.value) : ev.target.value
                    )
                  }}
                  onWheel={(e) => type === 'number' && e.currentTarget.blur()}
                  onKeyDown={(ev) => {
                    onKeyDown && onKeyDown(ev)
                    formik.setFieldValue(
                      name,
                      parse ? parse(ev.target.value) : ev.target.value
                    )
                  }}
                >
                  {canFieldIncludeChildren ? children : undefined}
                </InputField>
              )}
            </FormikField>
          )) || (
            <InputField
              as={inputAs}
              autoComplete={autocomplete}
              autoFocus={autofocus}
              $error={error}
              id={fieldId}
              max={max}
              min={min}
              disabled={disabled}
              placeholder={placeholder}
              readOnly={readonly}
              maxLength={maxLength}
              ref={ref}
              required={required}
              step={step}
              type={type}
              onChange={onChange}
              onWheel={(e) => type === 'number' && e.currentTarget.blur()}
              onKeyDown={onKeyDown}
              defaultValue={defaultValue}
              value={value}
            >
              {canFieldIncludeChildren ? children : undefined}
            </InputField>
          )}
          {!canFieldIncludeChildren && children}
          {suffix && <Suffix>{suffix}</Suffix>}
        </Inner>
        {name && <ErrorMessage name={name} />}
      </InputContainer>
    )
  }
)

Input.defaultProps = {
  numberSpinner: true,
}

Input.propTypes = {
  autocomplete: PropTypes.string,
  autofocus: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  label: PropTypes.string.isRequired,
  color: PropTypes.string,
  hideLabel: PropTypes.bool,
  inline: PropTypes.bool,
  size: PropTypes.oneOf(['small']),
  type: PropTypes.string,
  max: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  min: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  name: PropTypes.string,
  placeholder: PropTypes.string,
  maxLength: PropTypes.string,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  numberSpinner: PropTypes.bool,
  readonly: PropTypes.bool,
  required: PropTypes.bool,
  step: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  inputAs: PropTypes.string,
  children: PropTypes.node,
  note: PropTypes.node,
  description: PropTypes.node,
  parse: PropTypes.func,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
}

Input.displayName = 'Input'

export default Input
