import styled from 'styled-components'
import PropTypes from 'prop-types'
import { HeaderS, Body } from '../_abstracts/Type'
import { Icon, CarterCIcon } from '../_base/Icon'
import Button, { ButtonStyle } from '../_atoms/Button'

// TODO: Merge this component with CtaFeatured to reduce duplication

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  background: ${(props) => props.theme.colors.accentTint};
  border: 1px solid ${(props) => props.theme.colors.darkBlue};
  border-radius: ${(props) => props.theme.radii};
  padding: 25px;
  text-align: center;
  margin-top: 25px;

  ${ButtonStyle} {
    margin-top: 15px;
  }
`

const Header = styled.h2`
  ${HeaderS};
  font-size: 25px;
  padding-top: 20px;
  margin-bottom: 8px;
`

const Text = styled.p`
  ${Body};
  color: ${(props) => props.theme.colors.xDarkGrey};
`

const WrapperMedia = styled.div`
  position: absolute;
  display: flex;
  border: 1px solid ${(props) => props.theme.colors.blueTint};
  border-radius: 50%;
  top: 0;
  padding: 5px;
  width: 62px;
  height: 62px;
  left: 50%;
  background-color: ${(props) => props.theme.colors.white};
  transform: translate(-50%, -50%);

  ${Icon} {
    width: 32px;
    margin: auto;
  }
`

export default function CtaLegal({ header, text, buttonText, buttonUrl }) {
  return (
    <Wrapper>
      <WrapperMedia>
        <CarterCIcon />
      </WrapperMedia>
      <Header>{header}</Header>
      {text && <Text>{text}</Text>}
      <Button fullWidth variant="secondary" to={buttonUrl}>
        {buttonText}
      </Button>
    </Wrapper>
  )
}

CtaLegal.propTypes = {
  header: PropTypes.string.isRequired,
  text: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  buttonUrl: PropTypes.string.isRequired,
}
