import styled from 'styled-components'
import PropTypes from 'prop-types'
import { HeaderL, Body } from '../_abstracts/Type'
import { Icon, CarterCIcon } from '../_base/Icon'
import { MediaImage } from '../_base/Media'
import Button from '../_atoms/Button'
import { ContainerStyles } from '../_helpers/Container'
import { down } from 'styled-breakpoints'
import IconDrop from '../blocks/Icon'

const Wrapper = styled.div`
  ${ContainerStyles};
  position: relative;
  padding-top: 90px;
  padding-bottom: 90px;
  max-width: 100%;
  padding-left: 20px !important;
  padding-right: 20px !important;
  overflow: hidden;
  background-color: ${(props) => props.theme.colors.darkBlue};

  ${down('small')} {
    padding-top: 25px;
    padding-bottom: 25px;
  }
`

const InnerWrapper = styled.div`
  position: relative;
  background: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.accent};
  border-radius: 5px;
  padding: 25px;
  text-align: center;
  margin-top: 25px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 550px;

  a,
  button {
    margin-top: 25px;
  }
`

const Header = styled.h2`
  ${HeaderL};
  color: ${(props) => props.theme.colors.darkBlue};
  margin-top: 25px;
`

const Text = styled.div`
  ${Body};
  margin-top: 19px;
  text-align: left;

  p {
    padding-left: 32px;
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 13 9' fill='none' xmlns='http://www.w3.org/2000/svg' aria-hidden='true' focusable='false'%3E%3Cpath d='M.5 4.5L4 8l8-7.5' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: 0 8px;
    background-size: 18px auto;
  }
`

const Media = styled.div`
  position: absolute;
  border: 1px solid ${(props) => props.theme.colors.accent};
  border-radius: 50%;
  top: 0;
  padding: 5px;
  width: 62px;
  height: 62px;
  left: 50%;
  background-color: ${(props) => props.theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(-50%, -50%);

  ${Icon} {
    width: 36px;
  }
`

const List = styled.ul`
  padding-left: 0;
  list-style-type: none;
  margin-top: 19px;

  > * + * {
    margin-top: 15px;
  }
`

const ListItem = styled.li`
  position: relative;
  padding-left: 35px;
  text-align: left;

  i {
    position: absolute;
    top: 0;
    left: 0;
    width: 25px;
    min-width: 25px;
    height: 25px;
  }
`

export default function CtaImg({ header, text, items, button, image }) {
  return (
    <Wrapper>
      {image && (
        <MediaImage
          srcSet={`${image.medium} 900w, ${image.large} 1680w, ${image.xl} 2400w, ${image.xxl} 3000w`}
          sizes="100vw"
          alt={image.title || ''}
          width={image.width}
          height={image.height}
          loading="lazy"
        />
      )}
      <InnerWrapper>
        <Media>
          <CarterCIcon />
        </Media>
        <Header>{header}</Header>
        {text && <Text>{text}</Text>}
        {items && (
          <List>
            {items.map((li) => (
              <ListItem key={li.id}>
                {li.icon && <IconDrop icon={li.icon} />}
                {li.text}
              </ListItem>
            ))}
          </List>
        )}
        {button && (
          <Button variant="primaryDark" to={button.url}>
            {button.text}
          </Button>
        )}
      </InnerWrapper>
    </Wrapper>
  )
}

CtaImg.propTypes = {
  header: PropTypes.string.isRequired,
  text: PropTypes.node,
  image: PropTypes.shape({
    medium: PropTypes.string.isRequired,
    large: PropTypes.string.isRequired,
    xl: PropTypes.string.isRequired,
    xxl: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    title: PropTypes.string,
  }),
  button: PropTypes.shape({
    text: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      icon: PropTypes.string,
    })
  ),
}
