import { useQuery } from 'urql'
import GET_FOOTER from '../globals/footer'
import GET_HEADER from '../globals/header'
import GET_LISTING_FORM from '../globals/listingForm'
import GET_NOT_FOUND from '../globals/notFound'
import GET_MESSAGES from '../globals/messages'
import GET_PRODUCT from '../globals/product'
import GET_PROFILE from '../globals/profile'
import GET_SEO from '../globals/seo'
import GET_SOCIAL from '../globals/social'
import GET_TRADE from '../globals/trade'
import GET_INTERESTS from '../globals/interests'
import GET_CHECKOUT_CONFIRMATION from '../globals/checkoutConfirmation'
import GET_CONSIGNMENT from '../globals/consignment'
import GET_LOGIN_REGISTER from '../globals/loginRegister'

export default function useGlobals(handle, { pause = false } = {}) {
  let query

  if (handle === 'footer') {
    query = GET_FOOTER
  } else if (handle === 'header') {
    query = GET_HEADER
  } else if (handle === 'checkoutConfirmation') {
    query = GET_CHECKOUT_CONFIRMATION
  } else if (handle === 'notFound') {
    query = GET_NOT_FOUND
  } else if (handle === 'interests') {
    query = GET_INTERESTS
  } else if (handle === 'listingForm') {
    query = GET_LISTING_FORM
  } else if (handle === 'messages') {
    query = GET_MESSAGES
  } else if (handle === 'product') {
    query = GET_PRODUCT
  } else if (handle === 'profile') {
    query = GET_PROFILE
  } else if (handle === 'seo') {
    query = GET_SEO
  } else if (handle === 'social') {
    query = GET_SOCIAL
  } else if (handle === 'trade') {
    query = GET_TRADE
  } else if (handle === 'consignment') {
    query = GET_CONSIGNMENT
  } else if (handle === 'loginRegister') {
    query = GET_LOGIN_REGISTER
  } else {
    console.error('Unrecognised global set handle', handle)
  }

  const [{ data, fetching, error }] = useQuery({
    query,
    variables: { handle },
    pause,
  })

  if (error) {
    console.error(error)
  }

  return { [handle]: data?.globalSet, fetching, error }
}
