const GET_GlOBAL_SET = `
  query GetMessages {
    globalSet(handle: "messages") {
      ... on messages_GlobalSet {
        id
        messagesExchangePlusModalTitle
        messagesExchangePlusModalText
        messagesExchangePlusModalSuccessText
        messagesTnagDirectMessageWarning
        messagesOffsiteWarning
        alertText
        keywords
        zendeskMessage
        zendeskButtonLabel
      }
    }
  }
`

export default GET_GlOBAL_SET
