import { useEffect, useState } from 'react'

export default function useWindowSize({ includeScroll = false } = {}) {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
    scrollY: undefined,
  })

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        scrollY: includeScroll ? window.scrollY : undefined,
      })
    }

    // Add event listener
    window.addEventListener('resize', handleResize)

    if (includeScroll) {
      window.addEventListener('scroll', handleResize)
    }

    // Call handler right away so state gets updated with initial window size
    handleResize()

    // Remove event listener on cleanup
    return () => {
      window.removeEventListener('resize', handleResize)

      if (includeScroll) {
        window.removeEventListener('scroll', handleResize)
      }
    }
  }, [includeScroll])

  return windowSize
}
