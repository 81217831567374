import PropTypes from 'prop-types'
import { up } from 'styled-breakpoints'

import A11yElement from '../_base/Accessibility'
import Transition from '../_abstracts/Animation'

import styled from 'styled-components'

const Wrapper = styled.label`
  display: flex;
  align-items: center;
`

const Label = styled.span`
  font-size: 14px;
  margin-right: 15px;
  white-space: nowrap;

  ${up('medium')} {
    font-size: 16px;
  }
`

const ToggleWrapper = styled.span`
  position: relative;
  display: inline-block;
  flex-shrink: 0;
  width: 40px;
  height: 14px;
`

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  ${A11yElement};
`

const StyledCheckbox = styled.div`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) =>
    props.checked ? props.theme.colors.teal : props.theme.colors.lightBtnGrey};
  border-radius: 30px;
  border: 1px solid
    ${(props) =>
      props.checked ? props.theme.colors.teal : props.theme.colors.midGrey};
  ${Transition({ property: 'transform, background-color, border' })}

  &:before {
    position: absolute;
    content: '';
    height: 20px;
    width: 20px;
    left: 0;
    top: 50%;
    background-color: white;
    border-radius: 50%;
    transform: ${(props) =>
      props.checked ? 'translate(100%, -50%)' : 'translate(-1px, -50%)'};
    border: 1px solid ${(props) => props.theme.colors.darkGrey};
    ${Transition({ property: 'transform, background-color, border' })}
  }
`

export default function Toggle({ label, toggleState, onChange }) {
  return (
    <Wrapper>
      {label && <Label>{label}</Label>}
      <ToggleWrapper>
        <HiddenCheckbox checked={toggleState} onChange={() => onChange()} />
        <StyledCheckbox checked={toggleState} />
      </ToggleWrapper>
    </Wrapper>
  )
}

Toggle.propTypes = {
  label: PropTypes.string,
  toggleState: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
}
