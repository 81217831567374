import styled from 'styled-components'
import PropTypes from 'prop-types'
import {
  Icon,
  TwitterIcon,
  FacebookIcon,
  EmailIcon,
  LinkIcon,
} from '../_base/Icon'
import A11yElement from '../_base/Accessibility'
import Transition from '../_abstracts/Animation'
import { useLocation } from 'react-router'

const Wrapper = styled.ul`
  display: flex;
  width: fit-content;

  > *:not(:first-child) {
    margin-left: 10px;
  }

  > *:nth-child(odd) path {
    fill: ${(props) => props.theme.colors.accent};
  }

  > *:nth-child(even) path {
    stroke: ${(props) => props.theme.colors.accent};
  }

  ${Icon} {
    width: 30px;
    ${Transition({ property: 'transform' })}
  }

  ${Icon}:hover {
    transform: scale(1.2);
  }
`

const Accessibility = styled.div`
  ${A11yElement}
`

export default function Share({ url, text, img }) {
  const location = useLocation()

  if (!url) {
    url = process.env.RAZZLE_BASE_FRONTEND_URL + location.pathname
  }

  function popupWindow(url, title, w, h) {
    var left = window.outerWidth / 2 - w / 2 + window.screenLeft,
      top = window.outerHeight / 2 - h / 2 + window.screenTop

    return window.open(
      url,
      title,
      'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, copyhistory=no, width=' +
        w +
        ', height=' +
        h +
        ', top=' +
        top +
        ', left=' +
        left
    )
  }

  let popup = {
    facebook: function (opt) {
      let url =
        'http://www.facebook.com/sharer/sharer.php?u=' +
        encodeURIComponent(opt.url)
      popupWindow(url, 'Facebook', 900, 500)
    },
    twitter: function (opt) {
      let url =
        'https://twitter.com/intent/tweet?' +
        (opt.text ? 'text=' + encodeURIComponent(opt.text) : '') +
        '&url=' +
        encodeURIComponent(opt.url) +
        '&via=cartervintage'
      popupWindow(url, 'Twitter', 650, 360)
    },
    email: function (opt) {
      let url =
        'mailto:?' +
        (opt.text ? 'subject=' + encodeURIComponent(opt.text) : '') +
        '&body=' +
        encodeURIComponent(`Check out ${opt.url} on Carter Vintage Exchange`)
      popupWindow(url, 'Email', 800, 500)
    },
  }

  function clickHandler(el) {
    el.preventDefault()
    let current = el.currentTarget
    popup[current.getAttribute('data-share')]({
      url: current.getAttribute('data-url'),
      text: current.getAttribute('data-text'),
      img: current.getAttribute('data-img'),
    })
  }

  function copyToClipboard(el) {
    el.preventDefault()
    let current = el.currentTarget
    navigator.clipboard.writeText(current.getAttribute('data-url'))
  }

  return (
    <Wrapper>
      <li>
        <a
          href="#"
          aria-label="Share on Twitter"
          rel="noopener nofollow"
          onClick={(e) => clickHandler(e)}
          data-share="twitter"
          data-url={url}
          data-text={text}
          data-img={img}
        >
          <Accessibility>Twitter</Accessibility>
          <TwitterIcon />
        </a>
      </li>
      <li>
        <a
          href="#"
          aria-label="Share by Email"
          rel="noopener nofollow"
          onClick={(e) => clickHandler(e)}
          data-share="email"
          data-url={url}
          data-text={text}
          data-img={img}
        >
          <Accessibility>Email</Accessibility>
          <EmailIcon />
        </a>
      </li>
      <li>
        <a
          href="#"
          aria-label="Share on Facebook"
          rel="noopener nofollow"
          onClick={(e) => clickHandler(e)}
          data-share="facebook"
          data-url={url}
          data-text={text}
          data-img={img}
        >
          <Accessibility>Facebook</Accessibility>
          <FacebookIcon />
        </a>
      </li>
      <li>
        <a
          href="#"
          aria-label="Copy url"
          rel="noopener nofollow"
          data-url={url}
          onClick={(e) => copyToClipboard(e)}
        >
          <Accessibility>Copy url</Accessibility>
          <LinkIcon />
        </a>
      </li>
    </Wrapper>
  )
}

Share.propTypes = {
  url: PropTypes.string,
  img: PropTypes.string,
  text: PropTypes.string,
}
