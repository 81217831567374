import Meta from '../_helpers/Meta'
import styled from 'styled-components'
import { useQuery } from 'urql'
import Container from '../_helpers/Container'
import linearGradient from '../_helpers/linearGradient'
import Spacer from '../_helpers/Spacer'
import NotFound from '../404/NotFound'
import ContactForm from '../contact/ContactForm'
import Html from '../data/Html'
import Stores from '../shared/Stores'
import Hero from '../shared/Hero'
import Cta from '../shared/Cta'
import Loader from '../shared/Loader'

const GET_PAGE = `
  query GetPage {
    entry(section: "contact") {
      id
      title
      url
      ... on contact_contact_Entry {
        contactLocations {
          ... on contactLocations_item_BlockType {
            itemTitle
            address
            openingHours
            mapEmbed
          }
        }
        formTitle
        formText
        form {
          handle
        }
        formSuccessMessage
        seoTitle
        seoDescription
        cta {
          title
          ... on ctas_ctas_Entry {
            ctaText
            ctaBackgroundColor
            ctaLinks {
              ... on ctaLinks_item_BlockType {
                buttonTheme
                itemLink {
                  text
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`

const Wrapper = styled.div`
  background-image: ${linearGradient(
    'to bottom',
    'white 1 0%',
    'lighterGrey 1 500px'
  )};
`

export default function ContactPage() {
  const [{ data, fetching, error }] = useQuery({ query: GET_PAGE })

  if (fetching) return <Loader background="#fff" />

  if (error) return <p>{error.message}</p>

  if (!data.entry) {
    return <NotFound />
  }

  const { entry } = data
  const cta = entry.cta[0]
  const form = entry.form[0]

  return (
    <>
      <Meta
        title={entry.seoTitle || entry.title}
        description={entry.seoDescription}
        url={entry.url}
      />
      <Hero
        hero={{
          header: entry.title,
          headerElement: 'h1',
        }}
      />
      <Wrapper>
        <Container>
          {entry.contactLocations.length ? (
            <>
              <Spacer fromSize="30px" toSize="50px" />
              <Stores
                items={entry.contactLocations.map((item) => ({
                  title: <Html html={item.itemTitle} />,
                  text: <Html html={item.address} />,
                  opening: <Html html={item.openingHours} />,
                  mapEmbed: <Html html={item.mapEmbed} />,
                }))}
              />
            </>
          ) : undefined}
          {cta && (
            <>
              <Spacer fromSize="20px" toSize="40px" />
              <Cta
                header={cta.title}
                text={<Html html={cta.ctaText} />}
                variant={cta.ctaBackgroundColor}
                buttons={cta.ctaLinks.map((item) => ({
                  text: item.itemLink.text,
                  to: item.itemLink.url,
                  variant: item.buttonTheme,
                }))}
              />
            </>
          )}
          {form && (
            <>
              <Spacer fromSize="20px" toSize="40px" />
              <ContactForm
                form={form}
                heading={entry.formTitle}
                text={<Html html={entry.formText} />}
                successMessage={<Html html={entry.formSuccessMessage} />}
              />
            </>
          )}
          <Spacer fromSize="40px" toSize="80px" />
        </Container>
      </Wrapper>
    </>
  )
}

ContactPage.propTypes = {}
