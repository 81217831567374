import PropTypes from 'prop-types'
import Html from '../data/Html'
import SharedAccordion, { Wrapper } from '../shared/Accordion'

export { Wrapper }

export default function Accordion({ accordion }) {
  return (
    <SharedAccordion
      defaultFirstOpen
      items={accordion.map((item) => ({
        title: item.itemTitle,
        text: item.text && <Html html={item.text} />,
      }))}
    />
  )
}

Accordion.propTypes = {
  accordion: PropTypes.arrayOf(
    PropTypes.shape({
      itemTitle: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    })
  ).isRequired,
}
