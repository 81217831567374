import Meta from '../_helpers/Meta'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { useQuery } from 'urql'
import { HeaderM } from '../_abstracts/Type'
import Link from '../_atoms/Link'
import Container from '../_helpers/Container'
import linearGradient from '../_helpers/linearGradient'
import Spacer from '../_helpers/Spacer'
import NotFound from '../404/NotFound'
import Html from '../data/Html'
import Back from '../shared/Back'
import Blocks, { blocksQueryFields } from '../shared/Blocks'
import Cta from '../shared/Cta'
import Hero from '../shared/Hero'
import Loader from '../shared/Loader'
import Sidebar from './Sidebar'
import { down } from 'styled-breakpoints'

import { useEffect, useState } from 'react'
import Toggle from '../shared/Toggle'
import { useToggle } from '../hooks'

const GET_PAGE = `
  query GetPage($uri: [String]!) {
    entry(section: "helpCenterPages", uri: $uri) {
      id
      title
      url
      typeHandle
      ... on helpCenterPages_default_Entry {
        seoTitle
        seoDescription
        ${blocksQueryFields}
        cta {
          title
          ... on ctas_ctas_Entry {
            ctaText
            ctaBackgroundColor
            ctaLinks {
              ... on ctaLinks_item_BlockType {
                buttonTheme
                itemLink {
                  text
                  url
                }
              }
            }
          }
        }
        simpleLinks {
          ... on simpleLinks_item_BlockType {
            itemLink {
              text
              url
            }
          }
        }
      }
    }
  }
`

const Wrapper = styled.div`
  background-image: ${linearGradient(
    'to bottom',
    'white 1 0%',
    'lighterGrey 1 500px'
  )};
`

const Inner = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.midGrey};
  border-radius: 6px;
`

const Section = styled.div`
  display: flex;
  padding: 32px 24px 50px;
  border-top: 1px solid ${({ theme }) => theme.colors.midGrey};

  ${down('small')} {
    flex-direction: column;

    > * + * {
      margin-top: 25px;
    }
  }
`

const Content = styled.div`
  flex-grow: 1;
  padding-left: 24px;

  ${down('small')} {
    padding-left: 0;
  }
`

const RelatedTitle = styled.h4`
  ${HeaderM}
  margin-top: 40px;
  margin-bottom: 10px;
  font-weight: 600;
`

const RelatedLinks = styled.ul`
  padding-left: 1em;
  list-style-type: '> ';
  text-transform: capitalize;

  li {
    margin-top: 0.2em;

    &:first-child {
      margin-top: 0;
    }
  }
`

const Switch = styled.div`
  padding: 20px;
  justify-content: center;
  align-items: center;
  display: none;

  ${down('xsmall')} {
    display: flex;
  }
`

// TODO: Category page:

// const CategoryEntryType = () => {}

// const DefaultEntryType = () => {}

export default function HelpCenterEntry() {
  const [, setDefaultFilterState] = useState(false)
  const [filtersShown, toggleFiltersShown] = useToggle(false)
  useEffect(() => {
    if (window.innerWidth >= 480) {
      toggleFiltersShown(true)
    }
    setDefaultFilterState(true)
  }, [toggleFiltersShown])

  const { section, category, slug } = useParams()
  const uri = [section, category, slug].filter(Boolean).join('/')
  const [result] = useQuery({
    query: GET_PAGE,
    variables: { uri: `help-center/${uri}` },
  })

  const { data, fetching, error } = result

  if (fetching) return <Loader background="#fff" />

  if (error) return <p>{error.message}</p>

  if (!data.entry) {
    return <NotFound />
  }

  const { entry } = data
  const cta = entry.cta[0]

  return (
    <>
      <Meta
        title={entry.seoTitle || entry.title}
        description={entry.seoDescription}
        url={entry.url}
      />
      <Hero
        animate={false}
        hero={{
          header: entry.title,
          headerElement: 'h1',
          headerWeight: 600,
          subheader: 'Help center',
        }}
      />
      <Wrapper>
        <Container>
          <Spacer fromSize="30px" toSize="50px" />
          <Inner>
            <Back
              header="Back to index"
              size="small"
              url={`/help-center/${section}`}
              wrapped
            />

            <Switch>
              <Toggle
                label="Glossary"
                toggleState={filtersShown}
                onChange={toggleFiltersShown}
              />
            </Switch>

            <Section>
              {filtersShown && <Sidebar />}

              <Content>
                <Blocks blocks={entry.blocks} />
                {entry.simpleLinks.length ? (
                  <>
                    <RelatedTitle>Related links</RelatedTitle>
                    <RelatedLinks>
                      {entry.simpleLinks.map(({ itemLink: link }, index) => (
                        <li key={index}>
                          <Link to={link.url}>{link.text}</Link>
                        </li>
                      ))}
                    </RelatedLinks>
                  </>
                ) : null}
              </Content>
            </Section>
          </Inner>
          <Spacer fromSize="10px" toSize="20px" />
          {cta && (
            <>
              <Spacer fromSize="10px" toSize="20px" />
              <Cta
                header={cta.title}
                text={<Html html={cta.ctaText} />}
                variant={cta.ctaBackgroundColor}
                buttons={cta.ctaLinks.map((item) => ({
                  text: item.itemLink.text,
                  to: item.itemLink.url,
                  variant: item.buttonTheme,
                }))}
              />
              <Spacer fromSize="10px" toSize="20px" />
            </>
          )}
          <Back
            header="Back to index"
            size="small"
            url={`/help-center/${section}`}
            wrapped
            background="transparent"
          />
          <Spacer fromSize="20px" toSize="40px" />
        </Container>
      </Wrapper>
    </>
  )
}

HelpCenterEntry.propTypes = {}
