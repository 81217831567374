import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from 'urql'
import { useFormikContext } from 'formik'
import Select from '../inputs/Select'
import Notification from '../shared/Notification'
import { formatMoney } from '../data/Money'
import Currency from '../inputs/Currency'
import { useUserContext } from '../auth/UserContext'

const SHIPPING_METHODS = `
  query OrderShippingMethods($orderId: ID!) {
    orderShippingMethods(id: $orderId) {
      ref
      name
      price {
        amount
        currency { iso }
      }
    }
  }
`

export default function ShippingMethodSelect({ orderId, currentCost }) {
  const { user } = useUserContext()
  const formik = useFormikContext()
  const [{ data, fetching, error }] = useQuery({
    query: SHIPPING_METHODS,
    variables: { orderId },
  })

  const shippingOverrideName = `shippingOverride.${orderId}`

  useEffect(() => {
    const name = `shippingMethod.${orderId}`
    const refs = (data?.orderShippingMethods || []).map((m) => m.ref)

    if (
      formik.values.shippingMethod[orderId] ==
        formik.initialValues.shippingMethod[orderId] &&
      refs.includes(formik.values.shippingMethod[orderId])
    )
      return

    formik.setFieldValue(
      name,
      refs.length > 0 ? refs[0] : formik.initialValues.shippingMethod[orderId]
    )

    if (currentCost) {
      formik.setFieldValue(shippingOverrideName, currentCost)
    }
    // TODO: If we include formik in the dependency array here then we get an
    // infinite loop. There's probably a better way to deal with this, but it
    // seems to work just fine if we do this.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, orderId, currentCost, shippingOverrideName])

  if (error) {
    return (
      <Notification
        text={`Unable to load shipping methods: ${error.message}`}
      />
    )
  }

  const options = (data?.orderShippingMethods || []).map((shippingMethod) => ({
    label: `${shippingMethod.name} - ${formatMoney(
      shippingMethod.price.amount,
      shippingMethod.price.currency.iso
    )}`,
    value: shippingMethod.ref,
  }))

  if (fetching) {
    options.push({
      label: 'Loading shipping methods',
      value: '',
    })
  } else if (options.length === 0) {
    options.push({
      label: 'No shipping methods available',
      value: '',
    })
  }

  const select = (
    <Select
      label="Shipping method"
      name={`shippingMethod.${orderId}`}
      disabled={fetching}
      required
      options={options}
    />
  )

  if (!user.isAdmin) return select

  return (
    <>
      {select}
      <Currency
        label="Override price"
        name={shippingOverrideName}
        value={currentCost}
      />
    </>
  )
}

ShippingMethodSelect.propTypes = {
  orderId: PropTypes.string.isRequired,
  currentCost: PropTypes.number,
}
