import { Navigate, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { useQuery } from 'urql'
import { useGlobals } from '../hooks'

import { ORDERS_QUERY } from '../buying/OrderDetailsPage'
import Button from '../_atoms/Button'
import FormWrapper from '../checkout/FormWrapper'
import Orders from '../checkout/Orders'
import Hero, { Header as HeroHeader, HeroMedia } from '../shared/Hero'
import Loader from '../shared/Loader'
import { colors } from '../theme'
import useAnalyticsPurchase from '../analytics/useAnalyticsPurchase'
import { useUserContext } from '../auth/UserContext'

const Wrapper = styled.div`
  @media print {
    ${HeroHeader},
    ${HeroMedia} {
      display: none;
    }
  }
`

export default function CheckoutConfirmationPage() {
  const { checkoutConfirmation, fetching: globalsFetching } = useGlobals(
    'checkoutConfirmation'
  )
  const location = useLocation()
  const [{ fetching, error, data }] = useQuery({
    query: ORDERS_QUERY,
    variables: {
      orderIds: location.state?.orderIds || [],
      filter: 'AS_BUYER',
    },
  })
  const { user } = useUserContext()
  useAnalyticsPurchase(data?.orders, user)

  if (fetching || globalsFetching)
    return <Loader background={colors.xLightGrey} height="250px" />
  if (error) return <p>{error.message}</p>

  if (!data?.orders?.edges?.length) return <Navigate to="/" />

  return (
    <Wrapper>
      <FormWrapper>
        <Hero
          {...{
            hero: {
              header: checkoutConfirmation.heading,
              text: checkoutConfirmation.shortTitle,
              image: checkoutConfirmation.backgroundImage[0]?.url,
              alt: '',
              icon: true,
            },
          }}
        >
          <Button onClick={() => window.print()}>Print Order</Button>
        </Hero>
        <Orders orders={data.orders} />
      </FormWrapper>
    </Wrapper>
  )
}

CheckoutConfirmationPage.propTypes = {}
