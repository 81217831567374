import styled from 'styled-components'
import PropTypes from 'prop-types'
// import { Icon, SearchIcon } from '../_base/Icon'
// import { Formik, Form as FormikForm } from 'formik'
// import { InputField } from '../_atoms/Input'
// import { useNavigate } from 'react-router-dom'
import { HeaderL } from '../_abstracts/Type'

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
`

const Title = styled.h1`
  ${HeaderL}
  padding: 10px 20px;
`

// const Form = styled(FormikForm)`
//   display: flex;
//   flex-wrap: wrap;
//   align-items: center;
//   justify-content: space-between;
//   padding: 10px;
//   border-top: 1px solid ${(props) => props.theme.colors.midGrey};
//   border-bottom: 1px solid ${(props) => props.theme.colors.midGrey};
//   background-color: ${(props) => props.theme.colors.lightGrey};
// `

// const Search = styled.div`
//   padding: 10px;
//   width: 100%;
//   max-width: 500px;

//   ${InputField} {
//     position: relative;
//     z-index: 2;
//     padding-right: 30px;
//   }
// `

// const Field = styled.div`
//   position: relative;

//   &::before {
//     position: absolute;
//     top: 45%;
//     right: 10px;
//     width: 0;
//     height: 0;
//     content: '';
//     border-top: 5px solid;
//     border-bottom: 5px solid transparent;
//     border-left: 5px solid transparent;
//     border-right: 5px solid transparent;
//   }
// `

// const Button = styled.div`
//   position: absolute;
//   top: 50%;
//   right: 10px;
//   transform: translateY(-50%);
//   z-index: 3;
//   cursor: pointer;

//   ${Icon} {
//     width: 20px;
//   }
// `

export default function Bar({ heading }) {
  // const navigate = useNavigate()

  // function submitSearch({ query }) {
  //   navigate(`/search?query=${encodeURIComponent(query)}`)
  // }

  return (
    <Wrapper>
      <Title>{heading}</Title>
      {/* High Prioirty hide */}
      {/* <Formik initialValues={{ query: '' }} onSubmit={submitSearch}>
        <Form>
          <Search>
            <Field>
              <InputField placeholder="Search messages" name="query" required />
              <Button submit>
                <SearchIcon />
              </Button>
            </Field>
          </Search>
        </Form>
      </Formik> */}
    </Wrapper>
  )
}

Bar.propTypes = {
  heading: PropTypes.string.isRequired,
}
