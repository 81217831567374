import { up } from 'styled-breakpoints'
import PropTypes from 'prop-types'

import styled from 'styled-components'

import Circle from './Circle'
import { fluidRange } from '../_abstracts/Layout'
import Link from '../_atoms/Link'
import { HiddenScrollBar } from '../_helpers/ScrollBar'
import { HeaderXS } from '../_abstracts/Type'

const Wrapper = styled.ul`
  display: flex;
  justify-content: flex-start;
  position: relative;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  padding-top: 5px;
  padding-right: 0;
  ${HiddenScrollBar}
`

const Item = styled.li`
  position: relative;
  z-index: 1;
  flex-shrink: 0;
  text-align: center;
  width: 20vw;
  min-width: 125px;
  max-width: 200px;
  margin: 0 ${fluidRange('10px', '24px')};

  a {
    width: 100%;
    text-decoration: none;
    transition-property: color, transform;
    transition-duration: 0.2s;
    transition-timing-function: ease-out;

    @media (hover: hover) {
      &:hover {
        transform: translateY(-5px);
      }
    }

    > * {
      width: 100%;
    }
  }
`

const CircleTitle = styled.h2`
  ${HeaderXS}
  font-size: 16px;
  font-weight: 600;
  margin-top: 10px;
  line-height: 1.3em;
  color: inherit;

  ${up('medium')} {
    font-size: 20px;
    font-weight: 600;
  }
  ${up('large')} {
    font-size: 22px;
    font-weight: 600;
  }
`

export default function Circles({ circles }) {
  return (
    <Wrapper>
      {circles.map((circle, index) => (
        <Item key={index}>
          <Link to={circle.url}>
            <Circle image={circle.image} />
            <CircleTitle>{circle.title}</CircleTitle>
          </Link>
        </Item>
      ))}
    </Wrapper>
  )
}

Circles.propTypes = {
  circles: PropTypes.array.isRequired,
}
