import { Helmet } from 'react-helmet-async'
import { useState, useRef } from 'react'
import { useQuery } from 'urql'
import styled from 'styled-components'

import Subheader from '../shared/Subheader'
import NextPrevious from '../shared/NextPrevious'
import Loader from '../shared/Loader'

import { Formik } from 'formik'
import Input from '../_atoms/Input'
import Form from '../_atoms/Form'

import { useCartContext } from '../cart/CartContext'
import OfferWrapper from '../shared/OfferWrapper'

const DEFAULT_ITEMS_PER_PAGE = 12

const OFFER_DATA = `
  id
  state
  createdAt
  amountOffered {
    amount
    currency {
      iso
    }
  }
  details
  currentIteration {
    id
    details
    amountOffered {
      amount
      currency {
        iso
      }
    }
    state
    createdAt

    fromAccount {
      id
      username
      avatarImage {
        id
        url
      }
    }
    toAccount {
      id
      username
      avatarImage {
        id
        url
      }
    }
  }
  item {
    id
    title
    slug
    images {
      id
      url(size: "orders")
    }
    offerCount
    wishlistCount
    offerDeclineThreshold
    listing {
      id
      askingPrice {
        amount
        currency { iso }
      }
      isAvailable
      createdAt
      viewCount
      inViewerCart(orderId: $orderId)
    }
    account {
      id
      username
      isPrivateSeller
      isProfessionalSeller
    }
  }
  fromAccount {
    id
    username
    avatarImage {
      id
      url
    }
  }
  toAccount {
    id
    username
    avatarImage {
      id
      url
    }
  }
`

const GET_OFFERS = `
  query GetOffers($first: Int, $last: Int, $after: String, $before: String, $direction: OfferDirection, $orderId: [ID!]) {
    offers(first: $first, last: $last, after: $after, before: $before, direction: $direction) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }

      edges {
        cursor
        node {
          ${OFFER_DATA}
        }
      }
    }
  }
`

const Message = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
  text-align: center;
`

export default function BuyingOffersPage() {
  const { openOrderIds } = useCartContext()
  const panelRef = useRef()
  const [perPage, setPerPage] = useState(DEFAULT_ITEMS_PER_PAGE)
  const [pagination, setPagination] = useState({
    first: DEFAULT_ITEMS_PER_PAGE,
  })

  const [{ fetching, data, error }, reexecuteQuery] = useQuery({
    query: GET_OFFERS,
    variables: {
      direction: 'SENT_BY_VIEWER',
      ...pagination,
      orderId: openOrderIds,
    },
  })

  function refetchOffers() {
    // Re-fetch offers after accepting/declining, avoiding cache
    reexecuteQuery({ requestPolicy: 'cache-and-network' })
  }

  const scrollToTop = () =>
    panelRef.current?.scrollIntoView({ behavior: 'smooth' })

  if (fetching) return <Loader background="#fff" height="250px" />

  if (error) {
    console.error(error)
    return <Message>{error.message}</Message>
  }

  const {
    offers: { pageInfo, edges },
  } = data

  const offers = edges

  if (!offers.length) {
    return <Message>No new offers</Message>
  }

  return (
    <>
      <Helmet>
        <title>Profile | Buying | Offers</title>
      </Helmet>

      <Subheader header="Offers" border>
        <p>
          {offers.length} offer{offers.length === 1 ? '' : 's'}
        </p>

        <Formik initialValues={{}}>
          <Form>
            <Input
              inline={true}
              label="Per page"
              name="pp"
              inputAs="select"
              value={perPage}
              onChange={(event) => {
                const perPage = parseInt(event.target.value, 10)
                setPerPage(perPage)
                setPagination({ first: perPage })
              }}
            >
              <option value="12">12</option>
              <option value="24">24</option>
              <option value="48">48</option>
            </Input>
          </Form>
        </Formik>
      </Subheader>

      {offers.map(({ node: offer }) => {
        const { item } = offer

        if (item.listing) {
          item.listing.createdAt = new Date(item.listing.createdAt)
        }

        return (
          <OfferWrapper
            key={offer.id}
            item={item}
            offer={offer}
            onStateChange={refetchOffers}
            includeListingStats={false}
          />
        )
      })}

      <NextPrevious
        includeNext={pageInfo?.hasNextPage || !!pagination.before}
        includePrevious={pageInfo?.hasPreviousPage || !!pagination.after}
        onNext={() => {
          scrollToTop()
          setPagination({
            after: pageInfo.endCursor,
            first: perPage,
          })
        }}
        onPrevious={() => {
          scrollToTop()
          setPagination({
            before: pageInfo.startCursor,
            last: perPage,
          })
        }}
      />
    </>
  )
}

BuyingOffersPage.propTypes = {}
