import { useEffect, useRef } from 'react'
import toast from 'react-hot-toast'
import PropTypes from 'prop-types'
import { useMutation } from 'urql'
import styled from 'styled-components'
import Button from '../_atoms/Button'

export const UPDATE_LINE_ITEM_PRICE = `
  mutation UpdateLineItemPrice($lineItemId: ID!, $orderId: [ID!], $amountCents: Int) {
    cartUpdateLineItemPrice(lineItemId: $lineItemId, orderId: $orderId, amountCents: $amountCents) {
      success
      cart(orderId: $orderId) {
        orders {
          id
        }
      }
    }
  }
`

const Wrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  margin-right: 20px;
  text-align: left;
`

const StyledInput = styled.input`
  border: 1px solid #e6e7ea;
  border-radius: 3px;
  padding: 3px 5px;
  width: 160px;
`

export default function UpdatePriceButton({
  openOrderIds,
  lineItemId,
  amount,
}) {
  const inputRef = useRef()
  const [{ fetching }, updatePrice] = useMutation(UPDATE_LINE_ITEM_PRICE)

  useEffect(() => {
    inputRef.current.value = amount
  }, [inputRef, amount])

  async function performPriceUpdate(variables) {
    const result = await updatePrice(variables)

    if (result.data?.cartUpdateLineItemPrice?.success) {
      toast.success('Updated price', { id: 'price' })
    } else {
      const error = result.error?.graphQLErrors?.find(
        (err) => err.path[0] === 'cartUpdateLineItemPrice'
      )

      if (error) {
        toast.error(error.message, { id: 'price' })
      } else {
        toast.error('Failed to update price', { id: 'price' })
      }
    }
  }

  return (
    <Wrapper>
      <StyledInput
        type="number"
        min={0}
        disabled={fetching}
        ref={inputRef}
        defaultValue={amount}
      />
      <Button
        disabled={fetching}
        variant="small"
        onClick={() => {
          const amountCents = Math.round(
            parseFloat(inputRef.current.value) * 100
          )

          performPriceUpdate({ orderId: openOrderIds, lineItemId, amountCents })
        }}
      >
        {fetching ? 'Updating...' : 'Update Price'}
      </Button>
      <Button
        disabled={fetching}
        variant="small"
        onClick={() =>
          performPriceUpdate({ orderId: openOrderIds, lineItemId })
        }
      >
        {fetching ? 'Updating...' : 'Reset Price'}
      </Button>
    </Wrapper>
  )
}

UpdatePriceButton.propTypes = {
  openOrderIds: PropTypes.arrayOf(PropTypes.string),
  lineItemId: PropTypes.string.isRequired,
  amount: PropTypes.string.isRequired,
}
