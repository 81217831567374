import {
  createContext,
  useContext,
  useRef,
  useMemo,
  useState,
  useEffect,
} from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import { useQuery } from 'urql'
import { useUserContext } from '../auth/UserContext'
import CartModal from '../cart/Modal'

const GET_CART = `
  query GetCart($orderId: [ID!]) {
    cart(orderId: $orderId) {
      orders {
        id
        reference
        completedAt
        fullyPaidAt
        localPickupOnly
        paymentBalance {
          amountCents
        }
        total {
          amount
          amountCents
          currency {
            iso
          }
        }
        lineItems {
          id
          amount {
            amount
            currency {
              iso
            }
          }
          quantity
          allowCartNotes
          cartNotes
          listing {
            id
            item {
              id
              slug

              retail {
                stockLevel
              }

              images {
                id
                url(size: "cart")
              }
              title
              account { id username displayName }
            }
          }
        }
      }
    }
  }
`

const CartContext = createContext(null)

export default CartContext

export function CartProvider({ children }) {
  const modalRef = useRef()
  const location = useLocation()
  const locationRef = useRef(location.pathname)
  const [useInitialOrders, setUseInitialOrders] = useState(true)

  const [openOrderIds, setOpenOrderIds] = useState(() => {
    if (typeof localStorage === 'undefined') return []

    return JSON.parse(localStorage.getItem('openOrders') || '[]')
  })

  const [{ data, fetching, error }] = useQuery({
    query: GET_CART,
    variables: { orderId: openOrderIds },
  })

  if (error) {
    console.error(error)
  }

  useEffect(() => {
    function handleStorageChange(event) {
      if (event.key === 'openOrders') {
        setOpenOrderIds(JSON.parse(event.newValue || '[]'))
      }
    }

    window.addEventListener('storage', handleStorageChange)

    return () => {
      window.removeEventListener('storage', handleStorageChange)
    }
  }, [])

  function updateOpenOrderIds(ids) {
    setUseInitialOrders(false)
    if (typeof localStorage !== 'undefined') {
      localStorage.setItem(
        'openOrders',
        JSON.stringify(ids.filter((k, v) => ids.indexOf(k) == v))
      )

      setOpenOrderIds(ids)
    }
  }

  function clearOpenOrderIds() {
    typeof localStorage !== 'undefined' && localStorage.removeItem('openOrders')
    setOpenOrderIds([])
  }

  const { user, initialOpenOrders } = useUserContext()
  useEffect(() => {
    // if the user is not an admin (they can manually load and unload orders), and
    // if the orders we get from the server are different to the browser, populate from the server
    if (
      !user?.isAdmin &&
      useInitialOrders &&
      JSON.stringify(initialOpenOrders || []) !=
        JSON.stringify(openOrderIds || [])
    ) {
      updateOpenOrderIds(initialOpenOrders)
    }
  }, [user, initialOpenOrders, openOrderIds, useInitialOrders])

  // Filter out orders that have been completed during this session
  if (data?.cart?.orders?.length) {
    data.cart.orders = data.cart.orders.filter((order) => {
      return user?.isAdmin
        ? order.paymentBalance.amountCents !== order.total.amountCents
        : !order.completedAt || !order.fullyPaidAt
    })
  }

  useEffect(() => {
    if (data?.cart?.orders?.length)
      updateOpenOrderIds(data.cart.orders.map((o) => o.id))
  }, [data])

  const totalItems =
    data?.cart?.orders?.map((order) => order.lineItems).flat().length || 0

  const openModal = () => {
    modalRef?.current?.open()
  }

  const invalidLineItems = useMemo(
    () =>
      (data?.cart?.orders || []).flatMap((order) =>
        order.lineItems.filter((lineItem) => !lineItem.listing?.item)
      ),
    [data]
  )

  const hasInvalidItems = invalidLineItems.length > 0

  useEffect(() => {
    if (hasInvalidItems && locationRef.current !== '/cart')
      modalRef?.current?.open()
  }, [hasInvalidItems, locationRef])

  return (
    <CartContext.Provider
      value={{
        cart: data?.cart,
        totalItems,
        hasInvalidItems,
        invalidLineItems,
        openOrderIds,
        updateOpenOrderIds,
        clearOpenOrderIds,
        openModal,
        fetching,
        error,
      }}
    >
      {children}
      <CartModal ref={modalRef} />
    </CartContext.Provider>
  )
}

CartProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export const CartConsumer = CartContext.Consumer

export const useCartContext = () => {
  return useContext(CartContext)
}
