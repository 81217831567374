import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { useQuery } from 'urql'
import Transition from '../_abstracts/Animation'
import { HeaderL } from '../_abstracts/Type'
import Button from '../_atoms/Button'
import StyledLink from '../_atoms/Link'
import { Icon, HeartIcon, BasketIcon, SearchIcon, TelIcon } from '../_base/Icon'
import LogoutButton from '../auth/LogoutButton'
import { CartConsumer } from '../cart/CartContext'
import SearchBar from '../search/SearchBar'
import SlideOut from '../shared/SlideOut'
import Modal from '../shared/Modal'
import Badge from './Badge'
import Link from './Link'
import Loader from './Loader'
import { Notifications, GET_NAVIGATION, robots } from './Navigation'

const GET_UTILITY_MENU = (handle) => `
  query GetNavigation($handle: String!) {
    nodes (navHandle: $handle, level: 1) {
      id
      title
      url
      ... on ${handle}_Node {
        navUtilityMenuPosition
        navMobile
      }
    }
  }
`

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  border-bottom: 1px solid ${(props) => props.theme.colors.blueTint};
`

const WrapperInner = styled.div`
  position: relative;
  display: flex;
`

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  border-right: 1px solid ${(props) => props.theme.colors.blueTint};
  padding: 7px;

  &:nth-child(3) {
    text-align: center;
    flex-grow: 1;
  }

  &:last-child {
    border-right: 0;
  }

  > a,
  > button {
    ${Transition({ property: 'color' })};
    position: relative;
    display: block;

    &:hover {
      color: ${(props) => props.theme.colors.accent};
      text-decoration: none;
    }

    > ${Icon} {
      display: block;
      width: 30px;

      ${(props) =>
        props.search &&
        css`
          width: 25px;

          circle,
          path {
            stroke-width: 0.8;
          }
        `}
    }

    > ${Badge} {
      position: absolute;
      top: -10%;
      right: -10%;
      height: 15px;
      width: 15px;
      line-height: 1.2;
      font-size: 10px;
    }
  }
`

const Search = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px;
`

const SearchTitle = styled.h2`
  ${HeaderL}
  margin-top: -0.25em;
  margin-bottom: 15px;
  text-align: center;
`

const Trigger = styled.div`
  height: 32px;
  width: 36px;
  border: 1px solid ${(props) => props.theme.colors.blueTint};
  border-radius: ${(props) => props.theme.radii};
  cursor: pointer;
  display: block;
  position: relative;
  overflow: hidden;
  ${Transition({ property: 'background-color, border-color' })};

  &:hover {
    border-color: ${(props) => props.theme.colors.darkBlue};
  }

  div {
    position: absolute;
    height: 1px;
    width: 18px;
    background-color: ${(props) => props.theme.colors.darkBlue};
    ${Transition({ property: 'background-color, transform, opacity' })};
    top: 50%;
    left: 50%;

    &:first-child {
      transform: translateY(-7px) translateX(-50%);
    }

    &:nth-child(2) {
      transform: translateX(-50%);
    }

    &:last-child {
      transform: translateY(+7px) translateX(-50%);
    }
  }

  ${(props) =>
    props.state &&
    css`
      background-color: ${(props) => props.theme.colors.accent};
      border-color: ${(props) => props.theme.colors.accent};

      &:hover {
        border-color: ${(props) => props.theme.colors.accent};
      }

      div {
        background-color: ${(props) => props.theme.colors.white};

        &:first-child {
          transform: translateY(0) translateX(-50%) rotate(45deg);
        }

        &:nth-child(2) {
          opacity: 0;
          transform: translateX(100%);
        }

        &:last-child {
          transform: translateY(0) translateX(-50%) rotate(-45deg);
        }
      }
    `}
`

const UtilityPadding = '10px'

const UtilityMenuWrapper = styled.div`
  margin-top: auto;
  text-align: left;

  a {
    display: block;
    padding: 5px 10px;
    border-radius: ${(props) => props.theme.radii};
    transition-property: text-decoration-color, color, background-color;

    &:hover {
      background-color: ${(props) => props.theme.colors.lightGrey};
    }
  }
`

const UtilityLinks = styled.ul`
  padding-top: 15px;
  padding-bottom: 10px;
  margin-left: ${UtilityPadding};
  margin-right: ${UtilityPadding};
`

const HelperLinks = styled.ul`
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: ${UtilityPadding};
  margin-right: ${UtilityPadding};
  font-size: 14px;
  border-top: 1px solid ${(props) => props.theme.colors.lightGrey};
  color: ${(props) => props.theme.colors.xDarkGrey};
`

const UtilityBottom = styled.div`
  background-color: ${(props) => props.theme.colors.lightGrey};
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  text-align: center;

  a,
  button {
    margin-top: 7px;
  }

  a {
    font-size: 14px;
  }
`

const LinksNav = styled.nav`
  position: relative;
  min-width: 0;
  font-size: 15px;
`

const LinksList = styled.ul`
  flex-grow: 1;
  text-align: left;

  ${Item} {
    &:first-child {
      a {
        padding-left: 0;
      }
    }
  }
`

const LinksItem = styled.li`
  a,
  button {
    display: block;
    border-bottom: 1px solid ${(props) => props.theme.colors.blueTint};
    width: 100%;
    font-size: 16px;
    padding: 10px 10px 10px 20px;

    ${(props) =>
      props.highlight &&
      css`
        color: ${props.theme.colors.accent};
      `}
  }
`

const Message = styled.p`
  align-self: center;
`

const Dropdown = styled.div``

const DropdownTrigger = styled.button`
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translateY(-30%) rotate(45deg) scale(-1);
    width: 10px;
    height: 10px;
    display: block;
    border-bottom: 1px solid;
    border-right: 1px solid;
  }

  ${(props) =>
    !props.state &&
    css`
      & + ${DropdownList} {
        display: none;
      }

      &::before {
        transform: translateY(-60%) rotate(45deg);
      }
    `}
`

const DropdownList = styled.ul`
  text-align: left;

  li {
    font-weight: 700;
    padding: 10px 10px 0 20px;
    display: block;
    border-radius: ${(props) => props.theme.radii};
    transition-property: text-decoration-color, color, background-color;
    background-color: ${(props) => props.theme.colors.xLightGrey};
  }

  a {
    font-weight: 400;
    padding-bottom: 10px;

    &:hover {
      background-color: ${(props) => props.theme.colors.lightGrey};
    }
  }
`

const Logo = styled(Link)`
  position: relative;
  width: auto;
  height: 34px;
  display: inline-block;
  flex-grow: 0 !important;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: left;
  }
`

const TopContentNumberWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.darkBlue};
`

const TopContentNumber = styled.div``

const Num = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.accent};
  color: ${(props) => props.theme.colors.white};
  width: 100%;
  min-height: 24px;
  padding: 0 10px;
  text-align: center;
  font-size: 14px;

  a {
    text-decoration: underline;
    margin-left: 5px;
  }

  i {
    width: 17px;
    margin-right: 5px;
  }
`

const ConsignButton = styled.div`
  width: 100%;
`

const UtilityMenu = ({ user }) => {
  const handle = user
    ? 'headerUtilityMenuLoggedIn'
    : 'headerUtilityMenuLoggedOut'

  const [{ data, fetching, error }] = useQuery({
    query: GET_UTILITY_MENU(handle),
    variables: {
      handle,
    },
  })

  if (fetching) return <Loader background="#fff" height="150px" />

  if (error) return <Message>{error.toString()}</Message>

  // Only include links with `navMobile` field enabled
  const links = data?.nodes?.filter((link) => link.navMobile) || []

  const topLinks = links.filter((link) => link.navUtilityMenuPosition === 'top')
  const middleLinks = links.filter(
    (link) => link.navUtilityMenuPosition === 'middle'
  )
  const bottomLinks = links.filter(
    (link) => link.navUtilityMenuPosition === 'bottom'
  )

  return (
    <UtilityMenuWrapper>
      {topLinks.length ? (
        <UtilityLinks>
          {topLinks.map((link, index) => (
            <li key={index}>
              <StyledLink to={link.url} $reverse>
                {link.title}
              </StyledLink>
            </li>
          ))}
        </UtilityLinks>
      ) : null}
      {middleLinks.length ? (
        <HelperLinks>
          {middleLinks.map((link, index) => (
            <li key={index}>
              <StyledLink to={link.url} $reverse>
                {link.title}
              </StyledLink>
            </li>
          ))}
        </HelperLinks>
      ) : null}
      <UtilityBottom>
        <ConsignButton>
          <Button fullWidth={true} to="/selling" variant="primaryLight">
            Consign Your Guitar
          </Button>
        </ConsignButton>
        {(user && (
          <LogoutButton>
            <Button variant="primaryDark" fullWidth>
              Logout
            </Button>
          </LogoutButton>
        )) || (
          <Button to="/login" variant="primaryDark" fullWidth>
            Login
          </Button>
        )}
        {bottomLinks.map((link, index) => (
          <StyledLink key={index} to={link.url} $reverse>
            {link.title}
          </StyledLink>
        ))}
      </UtilityBottom>
    </UtilityMenuWrapper>
  )
}

UtilityMenu.propTypes = {
  user: PropTypes.object,
}

const Stl = styled(Link)``

const DropdownMenu = ({ nodes, title }) => {
  const [check, setCheck] = useState(false)

  return (
    <Dropdown>
      <DropdownTrigger
        type="button"
        state={check}
        onClick={() => {
          setCheck(!check)
        }}
      >
        <span>{title}</span>
      </DropdownTrigger>

      <DropdownList>
        {nodes.map((item) => (
          <li key={item.id} as="li">
            {item.url ? (
              <Stl to={item.url} rel={robots(item.attributes)}>
                {item.title}
              </Stl>
            ) : (
              item.title
            )}
          </li>
        ))}
      </DropdownList>
    </Dropdown>
  )
}

DropdownMenu.propTypes = {
  nodes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      url: PropTypes.string,
    })
  ),
  title: PropTypes.string.isRequired,
}

const Links = () => {
  const [{ data, fetching, error }] = useQuery({
    query: GET_NAVIGATION,
    variables: {
      handle: 'headerNavigation',
    },
  })

  return (
    <LinksNav>
      {(fetching && <Loader background="#fff" height="150px" />) ||
        (error && <Message>{error.toString()}</Message>) || (
          <>
            <LinksList>
              {data?.nodes?.map((item) => (
                <LinksItem
                  key={item.id}
                  highlight={item.navHighlightItem}
                  as="li"
                >
                  {(item.url && (
                    <Link to={item.url} rel={robots(item.attributes)}>
                      {item.title}
                    </Link>
                  )) ||
                    (item.children.length && (
                      <DropdownMenu nodes={item.children} title={item.title}>
                        {item.title}
                      </DropdownMenu>
                    )) ||
                    item.title}
                </LinksItem>
              ))}
            </LinksList>
          </>
        )}
    </LinksNav>
  )
}
export default function NavigationMobile({ setIsPopupOpen, user, logo }) {
  const slideOutRef = useRef()
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false)

  useEffect(() => {
    setIsPopupOpen(isNotificationsOpen)

    // Close mobile nav when notifications PopOut is opened (else it appears behind nav)
    if (isNotificationsOpen) {
      slideOutRef.current?.close()
    }
  }, [isNotificationsOpen, setIsPopupOpen])

  return (
    <Wrapper>
      <TopContentNumberWrapper>
        <TopContentNumber>
          <Num>
            <TelIcon />
            Call <a href="tel:(615) 915-1851"> (615) 915-1851</a>
          </Num>
        </TopContentNumber>
      </TopContentNumberWrapper>
      <WrapperInner>
        <Item>
          <SlideOut
            ref={slideOutRef}
            trigger={
              <Trigger>
                <div></div>
                <div></div>
                <div></div>
              </Trigger>
            }
          >
            <Links />
            <UtilityMenu user={user} />
          </SlideOut>
        </Item>

        <Item search>
          <Modal
            trigger={
              <button type="button" aria-label="Search">
                <SearchIcon />
              </button>
            }
          >
            <Search>
              <SearchTitle>Search</SearchTitle>
              <SearchBar action="/shop" autoFocus />
            </Search>
          </Modal>
        </Item>

        <Item>
          <Logo to="/">
            <img
              src={logo}
              alt="Carter Vintage Guitars logo"
              width="398"
              height="50"
            />
          </Logo>
        </Item>

        {user && (
          <Item>
            <Notifications setIsPopupOpen={setIsNotificationsOpen} />
          </Item>
        )}

        <Item>
          <Link to="/wishlist">
            <HeartIcon />
          </Link>
        </Item>

        <Item>
          <CartConsumer>
            {({ openModal, totalItems }) => (
              // Show modal cart on click, but support middle-clicking to open cart page in new tab
              <a
                href="/cart"
                onClick={(ev) => {
                  ev.preventDefault()
                  openModal()
                }}
              >
                <BasketIcon />
                {totalItems ? <Badge>{totalItems}</Badge> : null}
              </a>
            )}
          </CartConsumer>
        </Item>
      </WrapperInner>
    </Wrapper>
  )
}

NavigationMobile.defaultProps = {
  logo: '/images/logo.svg?v2',
}

NavigationMobile.propTypes = {
  logo: PropTypes.string,
  setIsPopupOpen: PropTypes.func,
  user: PropTypes.object,
}
