import { Helmet } from 'react-helmet-async'
import { useSearchParams } from 'react-router-dom'
import { down } from 'styled-breakpoints'
import styled, { css } from 'styled-components'
import { useQuery } from 'urql'
import { HeaderL } from '../_abstracts/Type'
import Container from '../_helpers/Container'
import linearGradient from '../_helpers/linearGradient'
import Meta from '../_helpers/Meta'
import Spacer from '../_helpers/Spacer'
import NotFound from '../404/NotFound'
import Html from '../data/Html'
import SearchBar, { Form as SearchForm } from '../search/SearchBar'
import Back from '../shared/Back'
import Hero from '../shared/Hero'
import Cta from '../shared/Cta'
import CtaLegal from '../shared/CtaLegal'
import Loader from '../shared/Loader'
import Results from './Results'

const GET_PAGE = `
  query GetPage {
    entry(section: "helpCenter") {
      title
      url
      ... on helpCenter_helpCenter_Entry {
        heroTitle
        searchInputPlaceholder
        seoTitle
        seoDescription
        linksTitle
        links {
          ... on links_item_BlockType {
            itemTitle
            subtitle
            itemLink {
              url
              text
            }
          }
        }
        cta {
          title
          ... on ctas_ctas_Entry {
            ctaText
            ctaBackgroundColor
            ctaLinks {
              ... on ctaLinks_item_BlockType {
                buttonTheme
                itemLink {
                  text
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`

const Wrapper = styled.div`
  background-image: ${linearGradient(
    'to bottom',
    'white 1 0%',
    'lighterGrey 1 500px'
  )};
`

const Search = styled.div`
  display: flex;
  justify-content: center;

  ${SearchForm} {
    max-width: 600px;
  }
`

const Inner = styled.div`
  border: 1px solid ${(props) => props.theme.colors.midGrey};
  border-radius: 6px;
  background-color: ${(props) => props.theme.colors.white};
  max-width: 1000px;
  margin-right: auto;
  margin-left: auto;
`

const Flex = styled.div`
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  ${(props) =>
    props.size == 'small' &&
    css`
      max-width: 850px;
      margin-right: auto;
      margin-left: auto;
    `}

  ${(props) =>
    props.border &&
    css`
      border-bottom: 1px solid ${(props) => props.theme.colors.midGrey};
    `}
`

const Item = styled.div`
  padding: 10px;
  width: 33.33%;

  ${down('medium')} {
    width: 50%;
  }

  ${down('small')} {
    width: 100%;
  }
`

const Title = styled.h2`
  ${HeaderL};
  text-align: center;
  color: ${(props) => props.theme.colors.darkBlue};
`

export default function HelpCenterSearchPage() {
  const [{ data, fetching, error }] = useQuery({ query: GET_PAGE })
  const [searchParams] = useSearchParams()

  if (fetching) return <Loader background="#fff" />

  if (error) return <p>{error.message}</p>

  if (!data.entry) {
    return <NotFound />
  }

  const { entry } = data
  const cta = entry.cta[0]

  const query = searchParams.get('q')

  return (
    <>
      <Meta title={`Search | ${entry.seoTitle || entry.title}`} />
      <Helmet>{query && <meta name="robots" content="noindex" />}</Helmet>
      <Hero
        animate={false}
        hero={{
          header: entry.heroTitle,
          headerElement: 'p',
          subheader: entry.title,
          subheaderElement: 'h1',
        }}
      >
        <Search>
          <SearchBar
            action="/help-center/search"
            name="q"
            placeholder={entry.searchInputPlaceholder}
          />
        </Search>
      </Hero>
      <Wrapper>
        <Container>
          <Spacer fromSize="30px" toSize="50px" />
          <Inner>
            <Back
              header="Back to index"
              size="small"
              url="/help-center"
              wrapped
            />
            <Results query={query} />
          </Inner>
          {cta && (
            <>
              <Spacer fromSize="30px" toSize="50px" />
              <Cta
                header={cta.title}
                text={<Html html={cta.ctaText} />}
                variant={cta.ctaBackgroundColor}
                buttons={cta.ctaLinks.map((item) => ({
                  text: item.itemLink.text,
                  to: item.itemLink.url,
                  variant: item.buttonTheme,
                }))}
              />
            </>
          )}
          <Spacer fromSize="30px" toSize="60px" />
          {entry.linksTitle && <Title>{entry.linksTitle}</Title>}
          <Flex>
            {entry.links.map((item, index) => (
              <Item key={index}>
                <CtaLegal
                  header={item.itemTitle}
                  text={item.subtitle}
                  buttonText={item.itemLink.text}
                  buttonUrl={item.itemLink.url}
                />
              </Item>
            ))}
          </Flex>
          <Spacer fromSize="40px" toSize="80px" />
        </Container>
      </Wrapper>
    </>
  )
}

HelpCenterSearchPage.propTypes = {}
