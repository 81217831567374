import { useEditor, EditorContent } from '@tiptap/react'
import Bold from '@tiptap/extension-bold'
import BulletList from '@tiptap/extension-bullet-list'
import Document from '@tiptap/extension-document'
import History from '@tiptap/extension-history'
import Italic from '@tiptap/extension-italic'
import ListItem from '@tiptap/extension-list-item'
import OrderedList from '@tiptap/extension-ordered-list'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import Underline from '@tiptap/extension-underline'
import { useFormikContext } from 'formik'
import PropTypes from 'prop-types'
import { forwardRef, useImperativeHandle } from 'react'
import { useId } from 'react-id-generator'
import styled, { css } from 'styled-components'
import ErrorMessage from '../_atoms/ErrorMessage'
import {
  Icon,
  BoldIcon,
  ItalicIcon,
  UnderlineIcon,
  BulletListIcon,
  OrderedListIcon,
} from '../_base/Icon'
import Label, { StyledLabel } from '../forms/Label'
import { down } from 'styled-breakpoints'

export const Inner = styled.div`
  border-radius: ${(props) => props.theme.radii};
  border: 1px solid ${(props) => props.theme.colors.midGrey};
  background: ${(props) => props.theme.colors.white};

  .ProseMirror {
    min-height: 100px;
    padding: 0 12px 10px;

    &:focus {
      outline: 0;
    }

    > * + * {
      margin-top: 0.75em;
    }

    ul,
    ol {
      padding-left: 2em;
    }

    ul {
      list-style-type: disc;
    }

    ol {
      list-style-type: decimal;
    }
  }
`

const InputLabel = styled.span`
  font-size: 14px;
  text-transform: capitalize;

  ${StyledLabel} {
    margin-bottom: 5px;
  }
`

const Toolbar = styled.div`
  display: flex;
  padding: 6px;

  ${down('small')} {
    flex-wrap: wrap;
  }
`

const Button = styled.button.attrs({ type: 'button' })`
  width: 32px;
  height: 32px;
  display: flex;
  margin: 6px;
  border: 1px solid ${({ theme }) => theme.colors.midGrey};
  border-radius: ${({ theme }) => theme.radii};

  ${({ $active }) =>
    $active &&
    css`
      border-color: ${({ theme }) => theme.colors.black};
      box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.black};
    `}

  ${Icon} {
    margin: auto;
  }
`

const Wysiwyg = forwardRef(
  ({ autofocus, hideLabel, label, name, required, onUpdate }, ref) => {
    const labelId = useId()[0]
    const formik = useFormikContext()

    // Check if Formik values include a field with this name at the top level - if so, use that.
    // If not, expand field name to match a nested object (e.g. 'item.title' -> { item: { title }})
    // https://formik.org/docs/guides/arrays#nested-objects
    const value =
      formik.values[name] ||
      name.split('.').reduce((r, k) => r?.[k], formik.values)

    const editor = useEditor({
      autofocus: !!autofocus,
      content: value,
      editorProps: {
        attributes: {
          'aria-labelledby': labelId,
        },
      },
      extensions: [
        Bold,
        BulletList,
        Document,
        History,
        Italic,
        ListItem,
        OrderedList,
        Paragraph,
        Text,
        Underline,
      ],

      // Triggered on every change
      onUpdate: ({ editor }) => {
        const html = editor.getHTML()

        // isEmpty check avoids setting content to e.g. `<p></p>` (which passes Formik required validation)
        formik.setFieldValue(name, editor.isEmpty ? '' : html)

        // Run custom update handler, if provided
        onUpdate?.({ editor })
      },
    })

    useImperativeHandle(ref, () => ({
      clear: () => {
        editor.commands.clearContent()
      },
    }))

    return (
      <div>
        <InputLabel>
          <Label as="span" id={labelId} hide={hideLabel}>
            {label}
          </Label>
          {required && !hideLabel && '*'}
        </InputLabel>
        <Inner>
          {editor && (
            <Toolbar>
              <Button
                aria-label="Bold"
                onClick={() => editor.chain().focus().toggleBold().run()}
                $active={editor.isActive('bold')}
              >
                <BoldIcon />
              </Button>
              <Button
                aria-label="Italic"
                onClick={() => editor.chain().focus().toggleItalic().run()}
                $active={editor.isActive('italic')}
              >
                <ItalicIcon />
              </Button>
              <Button
                aria-label="Underline"
                onClick={() => editor.chain().focus().toggleUnderline().run()}
                $active={editor.isActive('underline')}
              >
                <UnderlineIcon />
              </Button>
              <Button
                aria-label="Bullet list"
                onClick={() => editor.chain().focus().toggleBulletList().run()}
                $active={editor.isActive('bulletList')}
              >
                <BulletListIcon />
              </Button>
              <Button
                aria-label="Numbered list"
                onClick={() => editor.chain().focus().toggleOrderedList().run()}
                $active={editor.isActive('orderedList')}
              >
                <OrderedListIcon />
              </Button>
            </Toolbar>
          )}
          <EditorContent editor={editor} />
          <ErrorMessage name={name} />
        </Inner>
      </div>
    )
  }
)

Wysiwyg.displayName = 'Wysiwyg'

Wysiwyg.propTypes = {
  autofocus: PropTypes.bool,
  hideLabel: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onUpdate: PropTypes.func,
  required: PropTypes.bool,
}

export default Wysiwyg
