import { Helmet } from 'react-helmet-async'
import { useOutletContext } from 'react-router-dom'
import Meta from '../_helpers/Meta'
import Subheader from '../shared/Subheader'
import Listings from '../shop/Listings'

export default function PublicProfileListingsPage() {
  const { user } = useOutletContext()

  return (
    <>
      <Meta title={`${user.displayName} | Items for sale`} />
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Subheader header="Items for sale" border />
      <Listings
        border={false}
        loaderBackground="#fff"
        hiddenFilters={[{ key: 'seller', value: `["${user.username}"]` }]}
      />
    </>
  )
}
