import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  defaultAnimateLayoutChanges,
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  rectSortingStrategy,
  useSortable,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { Field, useFormikContext } from 'formik'
import PropTypes from 'prop-types'
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import { useDropzone } from 'react-dropzone'
import toast from 'react-hot-toast'
import { useId } from 'react-id-generator'
import styled, { css } from 'styled-components'
import { useMutation } from 'urql'
import Transition from '../_abstracts/Animation'
import { Icon, DeleteIcon, UploadIcon } from '../_base/Icon'
import Button from '../_atoms/Button'
import ErrorMessage from '../_atoms/ErrorMessage'
import { InputLabel } from '../_atoms/Input'
import Label from '../forms/Label'

const START_UPLOAD = `
  mutation StartUpload($upload: ImageStartUploadInput!) {
    imageStartUpload(upload: $upload) {
      success
      upload {
        id
        uploadUrl
      }
    }
  }
`

const FINALIZE_IMAGES = `
  mutation FinalizeImages($uploadIds: [String!]!) {
    finalizeImages(uploadIds: $uploadIds) {
      success
      images {
        id
        url
      }
    }
  }
`

export const Wrapper = styled.div`
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border: 1px solid ${(props) => props.theme.colors.midGrey};
  padding: 10px 10px 25px;
  border-radius: 5px;
`

const FileInput = styled.input.attrs({ type: 'file' })`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 1;
`

const Drag = styled.div`
  ${Transition({ property: 'border-color' })};
  flex-grow: 1;
  width: 100%;
  padding: 35px 30px 30px;
  background-color: ${(props) => props.theme.colors.lightGrey};
  border: 2px dashed
    ${({ theme, isDragActive }) =>
      isDragActive ? theme.colors.black : '#d4d4dd'};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${Icon} {
    display: inline-block;
    width: 80px;
  }
`

const DragInner = styled.div`
  display: block;
  width: 100%;
`

const Note = styled.div`
  display: block;
  margin-top: 5px;

  ${({ hasItems }) =>
    hasItems &&
    css`
      font-size: 14px;
      opacity: 0.5;
    `};
`

const Or = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`

const FileList = styled.ul`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: -15px -20px 5px;
`

const File = styled.li`
  position: relative;
  width: 170px;
  margin: 15px 20px;

  img {
    width: 100%;
    height: 170px;
    margin-bottom: 6px;
    object-fit: cover;
    background-color: ${({ theme }) => theme.colors.lightGrey};
    border-radius: ${({ theme }) => theme.radii};
    box-shadow: ${({ theme }) => theme.shadows.mini};
  }

  button {
    ${Transition({ property: 'color' })};
    position: absolute;
    top: -8px;
    right: -8px;

    circle {
      ${Transition({ property: 'fill' })};
    }

    &:hover {
      color: ${({ theme }) => theme.colors.white};

      circle {
        fill: ${({ theme }) => theme.colors.darkBlue};
      }
    }

    ${Icon} {
      width: 24px;
      height: 24px;
      border-radius: 50%;
    }
  }

  &[aria-roledescription='sortable'] {
    cursor: grab;
  }

  &[aria-pressed='true'] {
    cursor: grabbing;
    z-index: 1;
  }
`

export const UploadStates = Object.freeze({
  NotStarted: Symbol('NOT_STARTED'),
  Started: Symbol('STARTED'),
  Complete: Symbol('COMPLETE'),
  Error: Symbol('ERROR'),
})

export const uploadFiles = async ({ returnImages, ref }) => {
  const instance = ref?.current

  if (instance?.items.length) {
    const uploadsPromise = ref.current.uploadFiles({ returnImages })

    if (instance.uploadState !== UploadStates.Complete) {
      toast.promise(
        uploadsPromise,
        {
          loading: 'Uploading images…',
          success: 'Images uploaded',
          error: 'Unable to upload images',
        },
        { id: 'uploads' }
      )
    }

    try {
      return await uploadsPromise
    } catch (error) {
      toast.error('Unable to upload images', { id: 'uploads' })
      console.error(error)
    }
  }
}

export const UploadFile = forwardRef(
  ({ item, onDelete, uploadState, ...props }, ref) => {
    const [, startUpload] = useMutation(START_UPLOAD)

    useEffect(() => {
      async function upload() {
        if (!item.isUploaded && uploadState === UploadStates.Started) {
          const { data, error } = await startUpload({
            upload: {
              mimeType: item.file.type,
              size: item.file.size,
            },
          })

          if (data?.imageStartUpload?.success) {
            const response = await fetch(
              data.imageStartUpload.upload.uploadUrl,
              {
                method: 'PUT',
                body: item.file,
              }
            )

            if (response.ok && response.status === 200) {
              item.uploadComplete({ uploadId: data.imageStartUpload.upload.id })
            } else {
              item.uploadError(response.statusText)
            }
          } else {
            item.uploadError(error)
          }
        }
      }
      upload()
    }, [item, startUpload, uploadState])

    return (
      <File ref={ref} {...props}>
        <img
          src={item.preview}
          alt={item.file.name} // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(item.preview)
          }}
        />
        <button aria-label="Remove file" onClick={onDelete} type="button">
          <DeleteIcon />
        </button>
      </File>
    )
  }
)

UploadFile.displayName = 'UploadFile'

UploadFile.propTypes = {
  item: PropTypes.shape({
    file: PropTypes.object.isRequired,
    isUploaded: PropTypes.bool,
    preview: PropTypes.string.isRequired,
    upload: PropTypes.instanceOf(Promise).isRequired,
    uploadComplete: PropTypes.func.isRequired,
    uploadError: PropTypes.func.isRequired,
  }).isRequired,
  onDelete: PropTypes.func.isRequired,
  uploadState: PropTypes.oneOf([
    UploadStates.NotStarted,
    UploadStates.Started,
    UploadStates.Complete,
    UploadStates.Error,
  ]).isRequired,
}

const FormikField = ({ hasItems, name }) => {
  const { setFieldValue } = useFormikContext()

  // A hidden input with a simple value is used to pass Formik `required` validation
  // Components using Upload can override this value in their submit handler if needed
  useEffect(() => {
    setFieldValue(name, hasItems ? '1' : '')
  }, [hasItems, name, setFieldValue])

  return (
    <>
      <Field name={name} type="hidden" />
      <ErrorMessage name={name} />
    </>
  )
}

FormikField.propTypes = {
  hasItems: PropTypes.bool,
  name: PropTypes.string.isRequired,
}

const UploadsSortItem = ({ id, url, onDelete, item, uploadState }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id, animateLayoutChanges: defaultAnimateLayoutChanges })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return item ? (
    <UploadFile
      ref={setNodeRef}
      item={item}
      onDelete={onDelete}
      uploadState={uploadState}
      style={style}
      {...attributes}
      {...listeners}
    />
  ) : (
    <File ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <img src={url} alt="" />
      {onDelete && (
        <button aria-label="Remove file" onClick={onDelete} type="button">
          <DeleteIcon />
        </button>
      )}
    </File>
  )
}

UploadsSortItem.propTypes = {
  id: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  onDelete: PropTypes.func,
  item: PropTypes.shape({
    file: PropTypes.object.isRequired,
    isUploaded: PropTypes.bool,
    preview: PropTypes.string.isRequired,
    upload: PropTypes.instanceOf(Promise).isRequired,
    uploadComplete: PropTypes.func.isRequired,
    uploadError: PropTypes.func.isRequired,
  }),
  uploadState: PropTypes.oneOf([
    UploadStates.NotStarted,
    UploadStates.Started,
    UploadStates.Complete,
    UploadStates.Error,
  ]),
}

function UploadsSort({ items, setItems, uploadState }) {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: { distance: 10 },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )

  function handleDragEnd(event) {
    const { active, over } = event

    if (active.id !== over.id) {
      const oldIndex = items.findIndex(({ id }) => id === active.id)
      const newIndex = items.findIndex(({ id }) => id === over.id)
      const newOrder = arrayMove(items, oldIndex, newIndex)
      setItems(newOrder)
    }
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={items} strategy={rectSortingStrategy}>
        <FileList>
          {items.map((item) => (
            <UploadsSortItem
              key={item.id}
              {...item}
              uploadState={uploadState}
            />
          ))}
        </FileList>
      </SortableContext>
    </DndContext>
  )
}

UploadsSort.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape(UploadsSortItem.propTypes)),
  setItems: PropTypes.func.isRequired,
  uploadState: UploadFile.propTypes.uploadState,
}

const Upload = forwardRef(
  (
    {
      accept,
      hideLabel,
      images,
      label,
      name,
      multiple,
      onDeleteImage,
      required,
      sortable,
    },
    ref
  ) => {
    const [, finalizeImages] = useMutation(FINALIZE_IMAGES)
    const [items, setItems] = useState([])
    const [uploadState, setUploadState] = useState(UploadStates.NotStarted)

    const onDropAccepted = useCallback(
      async (files) => {
        const newItemResults = await toast.promise(
          Promise.allSettled(
            files
              .filter((file) => !items.find((item) => item.path === file.path))
              .map(async (file) => {
                const image = await resizeImage(file)

                const item = {
                  path: file.path,
                  file: image,
                  preview: URL.createObjectURL(image),
                }

                let uploadComplete, uploadError
                const upload = new Promise((resolve, reject) => {
                  uploadComplete = (upload) => {
                    item.isUploaded = true

                    if (items.every((item) => item.isUploaded)) {
                      setUploadState(UploadStates.Complete)
                    }

                    resolve(upload)
                  }
                  uploadError = reject
                }).catch((error) => {
                  console.error(error)
                  toast.error('Unable to upload images', { id: 'uploads' })
                  setUploadState(UploadStates.Error)
                })

                Object.assign(item, { upload, uploadComplete, uploadError })

                return item
              })
          ),
          {
            loading: 'Preparing images...',
            success: 'Images ready',
            error: 'Unable to prepare images',
          }
        )

        const newItems = newItemResults
          .filter((r) => r.status === 'fulfilled')
          .map((r) => r.value)

        if (multiple) {
          setItems(items.concat(newItems))
        } else {
          setItems(newItems)
        }

        setUploadState(UploadStates.Started)
      },
      [items, multiple]
    )

    // Remove an item that hasn't yet been uploaded
    const onRemoveItem = useCallback(
      (item) => {
        setItems(items.filter((itm) => itm.path !== item.path))
      },
      [items]
    )

    useEffect(() => {
      // Make sure to revoke the data uris to avoid memory leaks, will run on unmount (from https://react-dropzone.js.org/#section-previews)
      // See https://github.com/react-dropzone/react-dropzone/pull/1172
      return () => items.forEach((item) => URL.revokeObjectURL(item.preview))
    }, [items])

    const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
      accept,
      onDropAccepted,
      onDropRejected: (rejections) => {
        console.error(rejections)

        rejections.forEach((rejection) => {
          rejection.errors.forEach((error) => {
            toast.error(error.message)
          })
        })
      },
      noClick: true,
      multiple,
      maxFiles: multiple ? 0 : 1, // 0 = no limit
    })

    const fieldId = useId()[0]

    // Find images which haven't been marked as deleted (will be deleted when the form is submitted)
    const nonDeletedImages = useMemo(
      () => images.filter((image) => !image.isDeleted),
      [images]
    )
    const hasItems = nonDeletedImages.length > 0 || items.length > 0

    // Convert files to sortable item config (if `sortable` is set)
    const [sorted, setSorted] = useState(sortable ? images : [])
    useEffect(() => {
      if (sortable) {
        setSorted((sortedFiles) =>
          nonDeletedImages
            .map((image) => ({
              id: image.id,
              url: image.url,
              onDelete: onDeleteImage?.bind(this, image),
            }))
            .concat(
              items.map((item) => ({
                item,
                id: item.preview,
                url: item.preview,
                onDelete: onRemoveItem.bind(this, item),
              }))
            )
            .sort((a, b) => {
              const aIndex = sortedFiles.findIndex(({ id }) => id === a.id)
              if (aIndex < 0) return 1

              const bIndex = sortedFiles.findIndex(({ id }) => id === b.id)
              if (bIndex < 0) return -1

              return aIndex - bIndex
            })
        )
      }
    }, [images, items, sortable, nonDeletedImages, onDeleteImage, onRemoveItem])

    useImperativeHandle(ref, () => ({
      name,
      items,
      uploadState,
      sorted,
      uploadFiles: async ({ returnImages }) => {
        if (uploadState !== UploadStates.Complete) {
          setUploadState(UploadStates.Started)
        }

        try {
          const uploads = await Promise.all(items.map((item) => item.upload))

          // Return a list of { uploadId: '…' } objects for finalizing images externally
          if (!returnImages) {
            setUploadState(UploadStates.Complete)
            return uploads
          }

          // Or finalize images now and return image objects (e.g. for including image URLs in Craft forms)
          const { data } = await finalizeImages({
            uploadIds: uploads.map((upload) => upload.uploadId),
          })

          if (data?.finalizeImages?.success) {
            setUploadState(UploadStates.Complete)
            return data.finalizeImages.images
          } else {
            throw new Error('Finalizing images failed')
          }
        } catch (error) {
          console.error(error)
          setUploadState(UploadStates.Error)
        }
      },
    }))

    return (
      <>
        <InputLabel>
          <Label htmlFor={fieldId} hide={hideLabel}>
            {label}
          </Label>
          {required && !hideLabel && '*'}
        </InputLabel>
        <Wrapper {...getRootProps()}>
          <FileInput id={fieldId} {...getInputProps()} />
          <Drag isDragActive={isDragActive}>
            {hasItems &&
              (sortable ? (
                <UploadsSort
                  items={sorted}
                  setItems={setSorted}
                  uploadState={uploadState}
                />
              ) : (
                <FileList>
                  {nonDeletedImages.map((image) => (
                    <File key={image.id}>
                      <img src={image.url} alt="" />
                      <button
                        aria-label="Remove file"
                        onClick={
                          onDeleteImage && onDeleteImage.bind(this, image)
                        }
                        type="button"
                      >
                        <DeleteIcon />
                      </button>
                    </File>
                  ))}
                  {items.map((item) => (
                    <UploadFile
                      item={item}
                      key={item.path}
                      onDelete={onRemoveItem.bind(this, item)}
                      uploadState={uploadState}
                    />
                  ))}
                </FileList>
              ))}
            <DragInner>
              {hasItems ? null : <UploadIcon />}
              <Note hasItems={hasItems}>
                Drag and drop your files in this box
              </Note>
            </DragInner>
          </Drag>

          <Or>Or</Or>

          <Button variant="secondary" onClick={open} type="button">
            Browse files
          </Button>

          <FormikField hasItems={hasItems} name={name} />
        </Wrapper>
      </>
    )
  }
)

Upload.displayName = 'Upload'

Upload.defaultProps = {
  accept: 'image/jpeg,image/png',
  images: [],
  multiple: true,
}

Upload.propTypes = {
  accept: PropTypes.string,
  hideLabel: PropTypes.bool,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      isDeleted: PropTypes.bool,
      url: PropTypes.string.isRequired,
    })
  ),
  label: PropTypes.string.isRequired,
  onDeleteImage: PropTypes.func,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  multiple: PropTypes.bool,
  sortable: PropTypes.bool,
}

export default Upload

function resizeImage(file) {
  const maxSize = 4096
  const img = document.createElement('img')

  return new Promise((resolve) => {
    img.onload = () => {
      URL.revokeObjectURL(img.src)

      if (img.width > maxSize || img.height > maxSize) {
        const width =
          img.width > img.height ? maxSize : img.width * (maxSize / img.height)
        const height =
          img.width > img.height ? img.height * (maxSize / img.width) : maxSize

        const canvas = document.createElement('canvas')

        canvas.width = width
        canvas.height = height

        let ctx = canvas.getContext('2d')

        ctx.imageSmoothingEnabled = true
        ctx.imageSmoothingQuality = 'high'

        ctx.drawImage(img, 0, 0, width, height)
        canvas.toBlob((blob) => resolve(blob), file.type)
      } else {
        // No need to resize here
        resolve(file)
      }
    }

    img.src = URL.createObjectURL(file)
  })
}
