import styled from 'styled-components'
import PropTypes from 'prop-types'
import { HeaderL } from '../_abstracts/Type'

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) => props.theme.colors.darkBlue};
  border-radius: ${(props) => props.theme.radii};
  padding: 12px;
`

const WrapperInner = styled.div`
  border: 1px solid ${(props) => props.theme.colors.accent};
  border-radius: ${(props) => props.theme.radii};
  padding: 35px 0;
  text-align: center;
`

const Header = styled.h2`
  ${HeaderL};
`

export default function Banner({ header }) {
  return (
    <Wrapper>
      <WrapperInner>
        <Header>{header}</Header>
      </WrapperInner>
    </Wrapper>
  )
}

Banner.propTypes = {
  header: PropTypes.string.isRequired,
}
