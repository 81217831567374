import { Wrapper as Radio } from './Radio'
import { Wrapper as Checkbox } from './CheckboxWrapped'
import PropTypes from 'prop-types'
import { useId } from 'react-id-generator'
import styled, { css } from 'styled-components'
import { StyledLabel } from '../forms/Label'

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}
`

const Inner = styled.div`
  display: flex;
  flex-direction: column;

  ${({ inline }) =>
    inline &&
    css`
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      margin-bottom: -5px;
    `}
`

const Label = styled(StyledLabel)`
  margin-bottom: 5px;

  ${({ inline }) =>
    inline &&
    css`
      padding-right: 26px;
      margin-right: auto;
    `}
`

export const Radios = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: ${({ gap }) => -gap / 2}px;

  ${({ images }) =>
    images &&
    css`
      width: 100%;
      justify-content: space-evenly;
    `}

  ${({ inline }) =>
    inline &&
    css`
      padding-bottom: 5px;
    `}

  ${({ direction, fullWidth }) =>
    direction === 'column' &&
    !fullWidth &&
    css`
      display: grid;
      grid-template-columns: auto 1fr;
    `}

  ${Radio},
  ${Checkbox},
  > * {
    margin: ${({ gap }) => gap / 2}px;

    ${({ direction, fullWidth }) =>
      (direction === 'column' &&
        css`
          width: 100%;
          grid-column: 1;
        `) ||
      (direction === 'row' &&
        fullWidth &&
        css`
          min-width: 240px;
          flex-basis: 0;
          flex-grow: 1;
        `)}
  }
`

export default function RadioGroup({
  children,
  direction,
  fullWidth,
  gap,
  images,
  inline,
  label,
  hideLabel,
  required,
}) {
  const labelId = useId()[0]

  return (
    <Wrapper fullWidth={fullWidth} images={images}>
      <Inner inline={inline}>
        <Label as="p" gap={gap} hide={hideLabel} inline={inline}>
          <span id={labelId}>{label}</span>
          {required && '*'}
        </Label>
        <Radios
          role="radiogroup"
          aria-labelledby={labelId}
          direction={direction}
          fullWidth={fullWidth}
          gap={gap}
          images={images}
          inline={inline}
        >
          {children}
        </Radios>
      </Inner>
    </Wrapper>
  )
}

RadioGroup.defaultProps = {
  direction: 'row',
  gap: 26,
}

RadioGroup.propTypes = {
  children: PropTypes.node,
  direction: PropTypes.oneOf(['row', 'column']).isRequired,
  fullWidth: PropTypes.bool,
  gap: PropTypes.number.isRequired,
  images: PropTypes.bool,
  inline: PropTypes.bool,
  label: PropTypes.string.isRequired,
  hideLabel: PropTypes.bool,
  required: PropTypes.bool,
}
