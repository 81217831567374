import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useMutation } from 'urql'
import Transition from '../_abstracts/Animation'
import { AllCaps } from '../_abstracts/Type'
import { Icon, TrashIcon } from '../_base/Icon'
import PaymentIcon, {
  Wrapper as PaymentIconWrapper,
} from '../shared/PaymentIcon'

const Wrapper = styled.div`
  color: ${({ theme }) => theme.colors.darkBlue};
  border: 1px solid ${({ theme }) => theme.colors.midGrey};
  border-radius: ${({ theme }) => theme.radii};
`

const Header = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 16px;
  background-color: ${({ theme }) => theme.colors.lightGrey};
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;

  ${PaymentIconWrapper} {
    margin-right: 10px;
  }
`

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 8px 4px;
  font-size: 14px;
  border-top: 1px solid ${({ theme }) => theme.colors.midGrey};
  border-radius: inherit;
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
`

const Info = styled.dl`
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;

  dt {
    font-weight: 700;
  }
`

const InfoItem = styled.div`
  flex-grow: 1;
  margin: 8px 12px;
`

const Actions = styled.div`
  display: flex;
  align-items: center;
  margin: 8px 12px;
`

const Remove = styled.button.attrs({ type: 'button' })`
  ${Transition({ property: 'color, background-color' })};
  position: relative;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  color: ${({ theme }) => theme.colors.darkBlue};
  background-color: ${({ theme }) => theme.colors.midGrey};
  border-radius: 50%;

  &:hover {
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.darkBlue};
  }

  ${Icon} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 15px;
  }
`

const Edit = styled.button.attrs({ type: 'button' })`
  ${AllCaps};
  ${Transition({ property: 'color' })};
  margin-left: 30px;
  font-size: 14px;
  text-decoration: underline;

  &:hover {
    color: ${({ theme }) => theme.colors.accent};
  }
`

const DELETE_SAVED_CARD = `
  mutation DeleteSavedCard($id: ID!) {
    paymentDeleteSavedStripeCard(id: $id) {
      success
    }
  }
`

export default function Card({
  editable,
  id,
  expires,
  icon,
  lastDigits,
  name,
  title,
}) {
  const [, deleteCard] = useMutation(DELETE_SAVED_CARD)

  return (
    <Wrapper>
      <Header>
        {icon && <PaymentIcon icon={icon} />}
        {title}
        {lastDigits && ` ending with ${lastDigits}`}
      </Header>
      <Content>
        <Info>
          <InfoItem>
            {name && (
              <>
                <dt>Name on card</dt>
                <dd>{name}</dd>
              </>
            )}
          </InfoItem>
          <InfoItem>
            <dt>Expires</dt>
            <dd>{expires}</dd>
          </InfoItem>
        </Info>
        {editable && (
          <Actions>
            <Remove aria-label="Remove card" onClick={() => deleteCard({ id })}>
              <TrashIcon />
            </Remove>
            <Edit aria-label="Edit card">Edit</Edit>
          </Actions>
        )}
      </Content>
    </Wrapper>
  )
}

Card.propTypes = {
  editable: PropTypes.bool,
  id: PropTypes.string.isRequired,
  expires: PropTypes.string.isRequired,
  lastDigits: PropTypes.string,
  name: PropTypes.string,
  title: PropTypes.string.isRequired,

  // Available icons should match those defined in shared/Payment icon (exporting the available icons
  // to avoid duplicating the options here doesn't seem to work with PropTypes.oneOf)
  icon: PropTypes.oneOf(['amex', 'visa', 'mastercard', 'discover']),
}
