import structuredClone from '@ungap/structured-clone'
import { Formik } from 'formik'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useUserContext } from '../auth/UserContext'
import toast from 'react-hot-toast'
import { up } from 'styled-breakpoints'
import styled from 'styled-components'
import { useMutation } from 'urql'
import * as Yup from 'yup'
import Transition from '../_abstracts/Animation'
import Button from '../_atoms/Button'
import ButtonGroup, {
  Wrapper as ButtonGroupWrapper,
  Inner as ButtonGroupInner,
} from '../shared/ButtonGroup'
import Link from '../_atoms/Link'
import Input, { InputContainer, InputLabel } from '../_atoms/Input'
import {
  ChecklistIcon,
  DescriptionIcon,
  DetailsIcon,
  PackingIcon,
  PhotographyIcon,
  PriceIcon,
} from '../_base/Icon'
import { useGlobals } from '../hooks'
import Currency from '../inputs/Currency'
import Upload, { uploadFiles } from '../inputs/Upload'
import Field from '../forms/Field'
import Radio from '../inputs/Radio'
import RadioGroup from '../inputs/RadioGroup'
import Select from '../inputs/Select'
import Wysiwyg from '../inputs/Wysiwyg'
import AccordionForm from '../forms/AccordionForm'
import Fieldset from '../forms/Fieldset'
import Submit from '../forms/Submit'
import ComboBox from '../inputs/ComboBox'
import Loader from '../shared/Loader'
import PriceSuggestion from './PriceSuggestion'
import Money from '../data/Money'
import Html from '../data/Html'
import Plans from '../selling/Plans'
import AddressCheck from '../portfolio/AddressCheck'
import EstimateField from './EstimateField'

const CREATE_ITEM = `
  mutation CreateItem($item: ItemCreateInput!) {
    itemCreate(item: $item) {
      success
      item {
        id
        images {
          id
        }
      }
    }
  }
`

const UPDATE_ITEM = `
  mutation UpdateItem($id: ID!, $item: ItemUpdateInput!) {
    itemUpdate(id: $id, item: $item) {
      success
      item {
        id
        privacy
        title
        category {
          id
        }
        brand {
          id
          title
        }
        model {
          id
          title
        }
        madeInYear
        madeInCountry {
          iso
        }
        handedness
        finish {
          id
          title
        }
        materials {
          top {
            id
          }
          back {
            id
          }
          neck {
            id
          }
          fretboard {
            id
          }
          body {
            id
          }
        }
        description
        acceptingOffers
        offerDeclineThreshold
        originalParts
        originalFinish
        originalCase

        userEstimate {
          amount
          currency { iso }
        }

        images {
          id
          url(size: "productThumb")
        }

        listing {
          id
          askingPrice {
            amount
            currency {
              iso
            }
          }
          securePackaging
          type
        }
      }
    }
  }
`

const DELETE_IMAGE = `
  mutation DeleteImage($id: ID!) {
    itemImageDelete(id: $id) {
      success
    }
  }
`

const CREATE_LISTING = `
  mutation CreateListing($itemId: ID!, $listing: ListingCreateInput!) {
    listingCreate(itemId: $itemId, listing: $listing) {
      success
      listing {
        id
      }
    }
  }
`

const UPDATE_LISTING = `
  mutation UpdateListing($id: ID!, $listing: ListingUpdateInput!) {
    listingUpdate(id: $id, listing: $listing) {
      success
      listing {
        id
      }
    }
  }
`

const GET_OPTIONS = (fields) => {
  fields.search = 'String'

  const params = Object.entries(fields)
    .map(([name, type]) => `$${name}: ${type}`)
    .join(', ')

  const variables = Object.keys(fields)
    .map((name) => `${name}: $${name}`)
    .join(', ')

  return `
    query GetOptions(${params}) {
      entries(${variables}) {
        id
        title
        ancestors {
          id
          title
        }
        ... on categories_categories_Entry {
          itemAdditionalFields
        }
      }
    }
  `
}

const GET_OPTION = `
  query GetOption($id: [QueryArgument]!) {
    entry(id: $id) {
      id
      title
      ancestors {
        id
        title
      }
      ... on categories_categories_Entry {
        itemAdditionalFields
      }
    }
  }
`

const FILTER_MODELS = `
  query FilterModels($filter: SearchFilterModelsInput!) {
    entries: searchFilterModels(filter: $filter) {
      id
      title
    }
  }
`

const FILTER_FINISHES = `
  query FilterFinishes($filter: SearchFilterFinishesInput!) {
    entries: searchFilterFinishes(filter: $filter) {
      id
      title
    }
  }
`

const NO_FILTER_FINISHES = `
  query NoFilterFinishes($search: String) {
    entries(section: "finishes", search: $search) {
      id
      title
    }
  }
`

const FILTER_TOP_MATERIALS = `
  query FilterTopMaterials($filter: SearchFilterMaterialsInput!) {
    entries: searchFilterTopMaterials(filter: $filter) {
      id
      title
    }
  }
`

const FILTER_BACK_MATERIALS = `
  query FilterBackMaterials($filter: SearchFilterMaterialsInput!) {
    entries: searchFilterBackMaterials(filter: $filter) {
      id
      title
    }
  }
`

const FILTER_BODY_MATERIALS = `
  query FilterBodyMaterials($filter: SearchFilterMaterialsInput!) {
    entries: searchFilterBodyMaterials(filter: $filter) {
      id
      title
    }
  }
`

const FILTER_NECK_MATERIALS = `
  query FilterNeckMaterials($filter: SearchFilterMaterialsInput!) {
    entries: searchFilterNeckMaterials(filter: $filter) {
      id
      title
    }
  }
`

const FILTER_FRETBOARD_MATERIALS = `
  query FilterFretboardMaterials($filter: SearchFilterMaterialsInput!) {
    entries: searchFilterFretboardMaterials(filter: $filter) {
      id
      title
    }
  }
`

const UPDATE_IMAGE_ORDER = `
  mutation UpdateImageOrder($id: ID!, $imageIds: [ID!]!) {
    itemUpdateImageOrder(id: $id, imageIds: $imageIds) {
      success
    }
  }
`

const currentYear = new Date().getFullYear()

const itemSchema = Yup.object({
  privacy: Yup.string()
    .oneOf(['PRIVATE', 'PUBLIC'], 'Must be a valid option')
    .required('Required'),
  title: Yup.string()
    .max(250, 'Max 250 characters')
    .trim()
    .required('Required'),
  categoryId: Yup.string().max(250, 'Max 250 characters').required('Required'),
  brandId: Yup.string()
    .max(250, 'Max 250 characters')
    .test({
      message: 'Required',
      test: function (brandId) {
        if (!this.parent.brandName) return brandId != null && brandId.length > 0
        return true
      },
    }),
  brandName: Yup.string()
    .max(250, 'Max 250 characters')
    .test({
      message: 'Required',
      test: function (brandName) {
        if (!this.parent.brandId)
          return brandName != null && brandName.length > 0
        return true
      },
    }),
  modelId: Yup.string()
    .max(250, 'Max 250 characters')
    .test({
      message: 'Required',
      test: function (modelId) {
        if (!this.parent.modelName) return modelId != null && modelId.length > 0
        return true
      },
    }),
  modelName: Yup.string()
    .max(250, 'Max 250 characters')
    .test({
      message: 'Required',
      test: function (modelName) {
        if (!this.parent.modelId)
          return modelName != null && modelName.length > 0
        return true
      },
    }),
  madeInYear: Yup.number()
    .integer('Must be a valid date')
    .min(1000, 'Must be a valid date')
    .max(currentYear, 'Must be a past date')
    .required('Required'),
  madeInCountry: Yup.string()
    .max(2, 'Max 2 characters')
    .nullable()
    .transform((value) => value || null),
  handedness: Yup.string()
    .oneOf(
      ['RIGHT_HANDED', 'LEFT_HANDED', 'NOT_APPLICABLE'],
      'Must be a valid option'
    )
    .required('Required'),
  finishId: Yup.string()
    .max(250, 'Max 250 characters')
    .test({
      message: 'Required',
      test: function (finishId) {
        if (!this.parent.finishName)
          return finishId != null && finishId.length > 0
        return true
      },
    }),
  finishName: Yup.string()
    .max(250, 'Max 250 characters')
    .test({
      message: 'Required',
      test: function (finishName) {
        if (!this.parent.finishId)
          return finishName != null && finishName.length > 0
        return true
      },
    }),
  materials: Yup.object({
    topId: Yup.string().max(250, 'Max 250 characters'),
    backId: Yup.string().max(250, 'Max 250 characters'),
    neckId: Yup.string().max(250, 'Max 250 characters'),
    fretboardId: Yup.string().max(250, 'Max 250 characters'),
    bodyId: Yup.string().max(250, 'Max 250 characters'),
  }),
  description: Yup.string()
    .max(20000, 'Max 20000 characters')
    .trim()
    .nullable()
    .transform((value) => value || null),
  acceptingOffers: Yup.boolean().required('Required'),
  offerDeclineThreshold: Yup.number()
    .min(0, 'Must be 0% or higher')
    .max(100, 'Must be 100% or lower')
    .nullable()
    .transform((value) => value || null),
  uploads: Yup.string()
    .nullable()
    .transform((value) => value || null),
  userEstimate: Yup.object({
    amountCents: Yup.number().optional().nullable(),
    currencyIso: Yup.string().optional().nullable(),
  }).nullable(),
})

const newListingSchema = Yup.object({
  askingPrice: Yup.object({
    amountCents: Yup.number()
      .min(0, 'Must be 0 or higher')
      .required('Required'),
    currencyIso: Yup.string().required('Required'),
  }),
  securePackaging: Yup.boolean().required('Required'),
  type: Yup.string()
    .oneOf(['CARTERS', 'HOME'], 'Must be a valid option')
    .required('Required'),
})

const editListingSchema = Yup.object({
  askingPrice: Yup.object({
    amountCents: Yup.number()
      .min(0, 'Must be 0 or higher')
      .required('Required'),
    currencyIso: Yup.string().required('Required'),
  }),
  securePackaging: Yup.boolean().required('Required'),
})

const AdvancedToggle = styled.button.attrs({ type: 'button' })`
  ${Transition({ property: 'color' })};
  font-size: 14px;
  color: ${({ theme }) => theme.colors.accent};
  text-decoration: underline;

  &:hover {
    color: ${({ theme }) => theme.colors.darkBlue};
  }
`

const OfferThreshold = styled(Field)`
  flex-wrap: wrap;
  flex-direction: row;
  gap: 13px 26px;

  ${InputContainer} {
    width: auto;
    flex-grow: 1;
    margin-bottom: 0;
  }

  ${InputLabel} {
    padding-right: 10px;
  }
`

const QuickOfferAmounts = styled.div`
  width: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  ${up('small')} {
    min-width: 330px;
  }

  ${ButtonGroupWrapper} {
    flex-grow: 1;
    display: flex;
    align-items: center;
  }

  ${ButtonGroupInner} {
    flex-grow: 1;
  }
`

const Message = styled.p`
  text-align: center;
`

export default function ItemForm({
  heading,
  headingAlign,
  headingElement,
  headingSize,
  images,
  existingTitleParts,
  item,
  itemId,
  includeListingFields,
  listing,
  listingId,
  onDeleteImage,
  onSave,
  photographPrice,
}) {
  // Save mutation is either update/create depending on whether an item ID was passed in props
  const saveItemMutation = itemId ? UPDATE_ITEM : CREATE_ITEM
  const [saveItemState, submitSaveItem] = useMutation(saveItemMutation)
  const saveListingMutation = listingId ? UPDATE_LISTING : CREATE_LISTING
  const [saveListingState, submitSaveListing] = useMutation(saveListingMutation)
  const [, submitDeleteImage] = useMutation(DELETE_IMAGE)
  const uploadRef = useRef()
  const { user } = useUserContext()
  const [titleParts, setTitleParts] = useState(existingTitleParts || {})
  const [isTitleGenerated, setIsTitleGenerated] = useState(false)
  const [additionalFields, setAdditionalFields] = useState([])
  const [requiresAddress, setRequiresAddress] = useState(includeListingFields)

  const [textInputOverrides, setTextInputOverrides] = useState(
    [
      item.brandId === 'ephemeral' ? 'brand' : null,
      item.modelId === 'ephemeral' ? 'model' : null,
      item.finishId === 'ephemeral' ? 'finish' : null,
    ].filter(Boolean)
  )

  const [showAdvanced, setShowAdvanced] = useState(
    [
      item?.materials?.bodyId,
      item?.materials?.topId,
      item?.materials?.backId,
      item?.materials?.neckId,
      item?.materials?.fretboardId,
    ].some(Boolean)
  )

  const handednessOptions = [
    { label: 'Right', value: 'RIGHT_HANDED' },
    { label: 'Left', value: 'LEFT_HANDED' },
  ]

  if (user?.isAdmin) {
    handednessOptions.push({ label: 'N/A', value: 'NOT_APPLICABLE' })
  }

  // This can be removed when restoring advanced fields (feature currently disabled)
  const disableAdvancedFields = true

  useEffect(() => {
    if (saveItemState.fetching) {
      toast('Saving portfolio item…', { id: 'item' })
    } else if (saveItemState.data?.itemCreate?.success) {
      toast.success('Item added to portfolio', { id: 'item' })
    } else if (saveItemState.data?.itemUpdate?.success) {
      toast.success('Portfolio item updated', { id: 'item' })
    } else if (
      saveItemState.error ||
      saveItemState.data?.itemCreate?.success === false ||
      saveItemState.data?.itemUpdate?.success === false
    ) {
      toast.error('Unable to save item', { id: 'item' })
    }
  }, [saveItemState])

  useEffect(() => {
    if (saveListingState.fetching) {
      toast('Saving listing…', { id: 'listing' })
    } else if (saveListingState.data?.listingCreate?.success) {
      toast.success('Listing created', { id: 'listing' })
    } else if (saveListingState.data?.listingUpdate?.success) {
      toast.success('Listing updated', { id: 'listing' })
    } else if (
      saveListingState.error ||
      saveListingState.data?.listingCreate?.success === false ||
      saveListingState.data?.listingUpdate?.success === false
    ) {
      toast.error('Unable to save listing', { id: 'listing' })
    }
  }, [saveListingState])

  const Schema = useMemo(() => {
    // If editing an existing listing, use schema with `type` field (Carters/Home)
    const listingSchema = itemId ? editListingSchema : newListingSchema

    return Yup.object({
      item: itemSchema,
      // Only validate listing fields if this is a listing form:
      listing: includeListingFields ? listingSchema : undefined,
    })
  }, [includeListingFields, itemId])

  const [, updateImageOrder] = useMutation(UPDATE_IMAGE_ORDER)

  const { listingForm, fetching, error } = useGlobals('listingForm')
  if (fetching) return <Loader background="#f9f9f9" />

  if (error) {
    console.error(error)
    return <Message>An error occurred</Message>
  }

  function deleteImages(images) {
    return Promise.all(
      images.map((image) =>
        submitDeleteImage({
          id: image.id,
        }).then(({ error }) => {
          if (error) {
            throw new Error(error.message)
          }
        })
      )
    ).catch((error) => {
      throw new Error(error)
    })
  }

  async function saveItem(fields) {
    // Find images marked as deleted (using trash button in Upload component)
    const imagesToDelete = images.filter((image) => image.isDeleted)
    if (imagesToDelete.length) {
      const deleteImagesPromise = deleteImages(imagesToDelete)

      toast.promise(
        deleteImagesPromise,
        {
          loading: 'Deleting images…',
          success: 'Images deleted',
          error: 'Unable to delete images',
        },
        { id: 'uploads' }
      )

      let response
      try {
        response = await deleteImagesPromise

        if (
          response.error ||
          response.data?.deleteImageState?.success === false
        ) {
          throw new Error(response.error?.toString())
        }
      } catch (error) {
        toast.error('Unable to delete images', { id: 'uploads' })
        console.error(error)
      }
    }

    // Assign uploads to field data, as wasn't included in Formik field data at time of form submit
    fields.uploads = await uploadFiles({ ref: uploadRef })

    // Only submit uploads field if filled out (fixes 'The query is empty' response)
    if (fields.uploads?.length === 0) {
      delete fields.uploads
    }

    if (fields.brandName) delete fields.brandId
    if (fields.brandId) delete fields.brandName
    if (fields.modelName) delete fields.modelId
    if (fields.modelId) delete fields.modelName
    if (fields.finishName) delete fields.finishId
    if (fields.finishId) delete fields.finishName
    if (fields.privacy === 'PRIVATE') fields.acceptingOffers = false

    // Remove blank materials from submitted fields (empty string not accepted by Joi)
    fields.materials = Object.fromEntries(
      Object.entries(fields.materials).filter(([_key, value]) => value)
    )

    const { data } = await submitSaveItem({
      id: itemId, // Used for UPDATE_ITEM
      item: fields,
    })

    if (data?.itemCreate?.success) {
      console.log('Successfully created item')
      return data.itemCreate.item
    } else if (data?.itemUpdate?.success) {
      console.log('Successfully updated item')
      return data.itemUpdate.item
    }
  }

  async function saveListing(itemId, fields) {
    // Convert asking price input value to cents
    fields.askingPrice.amountCents = Math.round(
      fields.askingPrice.amountCents * 100
    )

    const { data } = await submitSaveListing({
      id: listingId, // Used for UPDATE_LISTING
      itemId: itemId, // Used for CREATE_LISTING
      listing: fields,
    })

    if (data?.listingCreate?.success) {
      console.log('Successfully created listing')
      return data.listingCreate.listing
    } else if (data?.listingUpdate?.success) {
      console.log('Successfully updated listing')
      return data.listingUpdate.listing
    }
  }

  async function sortImages(itemId, images) {
    // TODO: Match newly added images using an upload ID (needs to be returned by the server), rather
    // than assuming new images are in order at the end of the saved item's `images` array
    const newImageStartIndex = images.length - uploadRef.current.items.length
    const imageIds = uploadRef.current.sorted.map((file) => {
      // If this is a newly added image - get its ID from the end of the saved item's `images` array
      if (file.item) {
        const itemIndex = uploadRef.current.items.indexOf(file.item)
        return images[newImageStartIndex + itemIndex].id
      }

      // If this is a previously uploaded image - use its ID directly
      return file.id
    })

    return toast.promise(
      updateImageOrder({
        id: itemId,
        imageIds,
      }),
      {
        loading: 'Updating image order…',
        success: 'Image order updated',
        error: 'Unable to update image order',
      },
      { id: 'uploads' }
    )
  }

  async function handleSubmit(fields) {
    // User must save address to their account if creating a listing (rather than a portfolio item)
    if (requiresAddress) return

    const clonedFields = structuredClone(fields)

    // The user enters a percentage between 0-100, so we need to covert it to 0-1 for API compatibility
    if (typeof clonedFields.item.offerDeclineThreshold === 'number') {
      clonedFields.item.offerDeclineThreshold /= 100
    }

    if (clonedFields?.item?.userEstimate?.amountCents) {
      clonedFields.item.userEstimate.amountCents = Math.round(
        clonedFields.item.userEstimate.amountCents * 100
      )
    } else {
      clonedFields.item.userEstimate = null
    }

    // Deep clone fields using structuredClone (native or polyfilled), otherwise modifying field values
    // for submission will affect Formik field references too
    const parsedFields = Schema.cast(clonedFields, {
      strict: true,
    })

    const savedItem = await saveItem(parsedFields.item)

    if (savedItem?.id) {
      let savedListing

      if (includeListingFields) {
        savedListing = await saveListing(savedItem.id, parsedFields.listing)
      }

      // Only run onSave callback if listing save succeeded / form is item-only
      if (!includeListingFields || savedListing) {
        // TODO: Could skip image sorting query unless images have been reordered
        if (uploadRef.current.sorted.length) {
          await sortImages(savedItem.id, savedItem.images)
        }

        onSave?.({ item: savedItem, listing: savedListing })
      }
    }
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      validationSchema={Schema}
      initialValues={{
        item: {
          ...item,
          privacy: includeListingFields ? 'PUBLIC' : item.privacy,
        },
        listing,
      }}
    >
      {(formik) => {
        function updateTitle(updatedParts) {
          if (generateTitle(existingTitleParts) !== item.title) return

          const parts = Object.assign({}, titleParts, updatedParts)
          setTitleParts(parts)

          if (parts.brand && parts.model && parts.year && parts.finish) {
            formik.setFieldValue('item.title', generateTitle(parts))
            setIsTitleGenerated(true)
          } else {
            setIsTitleGenerated(false)
          }
        }

        return (
          <AccordionForm
            id="item-form"
            heading={heading}
            headingAlign={headingAlign}
            headingElement={headingElement}
            headingSize={headingSize}
          >
            <Fieldset
              accordion
              legend={listingForm.listingFormDetailsTitle}
              helpText={listingForm.listingFormDetailsHelp}
              icon={<DetailsIcon />}
              text={
                listingForm.listingFormDetailsText && (
                  <Html html={listingForm.listingFormDetailsText} />
                )
              }
            >
              <Field>
                <ComboBox
                  label="Instrument"
                  lazy={false}
                  name="item.categoryId"
                  itemQuery={GET_OPTION}
                  itemsQuery={GET_OPTIONS({
                    section: '[String]',
                    availableToPublic: 'Boolean',
                  })}
                  itemsQueryVariables={{
                    section: 'categories',
                    availableToPublic: user?.isAdmin ? null : true,
                  }}
                  placeholder="Search Options…"
                  required
                  onChange={(option) => {
                    // Craft `itemAdditionalFields` field is used to determine which fields should be shown
                    // for a category - for instance showing top + back material fields for acoustic guitars
                    setAdditionalFields(option?.itemAdditionalFields || [])
                  }}
                />
              </Field>
              <Field width="half">
                <ComboBox
                  label="Brand"
                  name="item.brandId"
                  overrideWithTextField={
                    textInputOverrides.includes('brand')
                      ? 'item.brandName'
                      : null
                  }
                  itemQuery={GET_OPTION}
                  itemsQuery={GET_OPTIONS({ section: '[String]' })}
                  itemsQueryVariables={{ section: 'brands' }}
                  placeholder="Search Options…"
                  required
                  disabled={!formik.values.item.categoryId}
                  onChange={(option) => {
                    updateTitle({
                      brand:
                        typeof option === 'string' ? option : option?.title,
                    })
                  }}
                  notFoundContent={(prevValue) => (
                    <>
                      {listingForm.listingFormBrandRequestMessageText}{' '}
                      <Link
                        as="button"
                        onClick={async () => {
                          formik.setFieldValue('item.brandName', prevValue)
                          updateTitle({ brand: prevValue })
                          setTextInputOverrides(['brand', 'model', 'finish'])
                        }}
                        type="button"
                      >
                        {listingForm.listingFormBrandRequestMessageLink}
                      </Link>
                    </>
                  )}
                />
              </Field>
              <Field width="half">
                <ComboBox
                  label="Model"
                  name="item.modelId"
                  overrideWithTextField={
                    textInputOverrides.includes('model')
                      ? 'item.modelName'
                      : null
                  }
                  itemQuery={GET_OPTION}
                  itemsQuery={FILTER_MODELS}
                  itemsQueryVariables={{
                    filter: {
                      brandId: formik.values.item.brandId,
                    },
                  }}
                  placeholder="Search Options…"
                  required
                  disabled={
                    !formik.values.item.brandId && !formik.values.item.brandName
                  }
                  onChange={(option) => {
                    updateTitle({
                      model:
                        typeof option === 'string' ? option : option?.title,
                    })
                  }}
                  notFoundContent={(prevValue) => (
                    <>
                      {listingForm.listingFormModelRequestMessageText}{' '}
                      <Link
                        as="button"
                        onClick={async () => {
                          formik.setFieldValue('item.modelName', prevValue)
                          updateTitle({ model: prevValue })
                          setTextInputOverrides(['model', 'finish'])
                        }}
                        type="button"
                      >
                        {listingForm.listingFormModelRequestMessageLink}
                      </Link>
                    </>
                  )}
                />
              </Field>
              <Field width="half">
                <Input
                  label="Year of build"
                  name="item.madeInYear"
                  parse={(value) => {
                    const parsed = parseInt(value, 10)
                    return isNaN(parsed) ? '' : parsed
                  }}
                  required
                  type="number"
                  min={1000}
                  max={currentYear}
                  onChange={(ev) =>
                    updateTitle({
                      year: ev.target.value,
                    })
                  }
                />
              </Field>
              <Field width="half">
                <Select
                  label="Handedness"
                  name="item.handedness"
                  options={handednessOptions}
                  placeholder="Select Option"
                  required
                />
              </Field>
              <Field width="half">
                <ComboBox
                  label="Finish"
                  name="item.finishId"
                  overrideWithTextField={
                    textInputOverrides.includes('finish')
                      ? 'item.finishName'
                      : null
                  }
                  itemQuery={GET_OPTION}
                  itemsQuery={
                    formik.values.item.originalFinish
                      ? FILTER_FINISHES
                      : NO_FILTER_FINISHES
                  }
                  itemsQueryVariables={
                    formik.values.item.originalFinish
                      ? {
                          filter: {
                            brandId: formik.values.item.brandId,
                            modelId: formik.values.item.modelId,
                          },
                        }
                      : {}
                  }
                  allowEmptyQuery
                  placeholder="Search Options…"
                  required
                  disabled={
                    !formik.values.item.modelId && !formik.values.item.modelName
                  }
                  onChange={(option) => {
                    updateTitle({
                      finish:
                        typeof option === 'string' ? option : option?.title,
                    })
                  }}
                  notFoundContent={(prevValue) => (
                    <>
                      {listingForm.listingFormFinishRequestMessageText}{' '}
                      <Link
                        as="button"
                        onClick={async () => {
                          formik.setFieldValue('item.finishName', prevValue)
                          updateTitle({ finish: prevValue })
                          setTextInputOverrides(['finish'])
                        }}
                        type="button"
                      >
                        {listingForm.listingFormFinishRequestMessageLink}
                      </Link>
                    </>
                  )}
                />
              </Field>
              <Field width="half">
                <RadioGroup inline label="Original Case" required>
                  <Radio
                    name="item.originalCase"
                    value={true}
                    parse={() => true}
                    label="Yes"
                    required
                    wrapper
                    center
                    blueLabel
                    large
                  />
                  <Radio
                    name="item.originalCase"
                    value={false}
                    parse={() => false}
                    label="No"
                    required
                    wrapper
                    center
                    blueLabel
                    large
                  />
                </RadioGroup>
              </Field>
              <Field width="half">
                <RadioGroup inline label="All Original Parts" required>
                  <Radio
                    name="item.originalParts"
                    value={true}
                    parse={() => true}
                    label="Yes"
                    required
                    wrapper
                    center
                    blueLabel
                    large
                  />
                  <Radio
                    name="item.originalParts"
                    value={false}
                    parse={() => false}
                    label="No"
                    required
                    wrapper
                    center
                    blueLabel
                    large
                  />
                </RadioGroup>
              </Field>
              <Field width="half">
                <RadioGroup inline label="Original Finish" required>
                  <Radio
                    name="item.originalFinish"
                    value={true}
                    parse={() => true}
                    label="Yes"
                    required
                    wrapper
                    center
                    blueLabel
                    large
                  />
                  <Radio
                    name="item.originalFinish"
                    value={false}
                    parse={() => false}
                    label="No"
                    required
                    wrapper
                    center
                    blueLabel
                    large
                    onClick={(opt) => {
                      console.log(opt)
                    }}
                  />
                </RadioGroup>
              </Field>
              {(!disableAdvancedFields && showAdvanced && (
                <>
                  {additionalFields.includes('item.materials.bodyId') && (
                    <Field width="half">
                      <ComboBox
                        label="Body material"
                        name="item.materials.bodyId"
                        itemQuery={GET_OPTION}
                        itemsQuery={FILTER_BODY_MATERIALS}
                        itemsQueryVariables={{
                          filter: {
                            brandId: formik.values.item.brandId,
                            modelId: formik.values.item.modelId,
                          },
                        }}
                        placeholder="Search Options…"
                      />
                    </Field>
                  )}
                  {additionalFields.includes('item.materials.topId') && (
                    <Field width="half">
                      <ComboBox
                        label="Top material"
                        name="item.materials.topId"
                        itemQuery={GET_OPTION}
                        itemsQuery={FILTER_TOP_MATERIALS}
                        itemsQueryVariables={{
                          filter: {
                            brandId: formik.values.item.brandId,
                            modelId: formik.values.item.modelId,
                          },
                        }}
                        placeholder="Search Options…"
                      />
                    </Field>
                  )}
                  {additionalFields.includes('item.materials.backId') && (
                    <Field width="half">
                      <ComboBox
                        label="Back material"
                        name="item.materials.backId"
                        itemQuery={GET_OPTION}
                        itemsQuery={FILTER_BACK_MATERIALS}
                        itemsQueryVariables={{
                          filter: {
                            brandId: formik.values.item.brandId,
                            modelId: formik.values.item.modelId,
                          },
                        }}
                        placeholder="Search Options…"
                      />
                    </Field>
                  )}
                  {additionalFields.includes('item.materials.neckId') && (
                    <Field width="half">
                      <ComboBox
                        label="Neck material"
                        name="item.materials.neckId"
                        itemQuery={GET_OPTION}
                        itemsQuery={FILTER_NECK_MATERIALS}
                        itemsQueryVariables={{
                          filter: {
                            brandId: formik.values.item.brandId,
                            modelId: formik.values.item.modelId,
                          },
                        }}
                        placeholder="Search Options…"
                      />
                    </Field>
                  )}
                  {additionalFields.includes('item.materials.fretboardId') && (
                    <Field width="half">
                      <ComboBox
                        label="Fretboard material"
                        name="item.materials.fretboardId"
                        itemQuery={GET_OPTION}
                        itemsQuery={FILTER_FRETBOARD_MATERIALS}
                        itemsQueryVariables={{
                          filter: {
                            brandId: formik.values.item.brandId,
                            modelId: formik.values.item.modelId,
                          },
                        }}
                        placeholder="Search Options…"
                      />
                    </Field>
                  )}
                </>
              )) ||
                (!disableAdvancedFields &&
                  (additionalFields.length ? (
                    <Field hidden={showAdvanced}>
                      <AdvancedToggle onClick={() => setShowAdvanced(true)}>
                        Advanced &gt;
                      </AdvancedToggle>
                    </Field>
                  ) : null))}
            </Fieldset>
            <Fieldset
              accordion
              legend={listingForm.listingFormDescriptionTitle}
              helpText={listingForm.listingFormDescriptionHelp}
              icon={<DescriptionIcon />}
            >
              <Field>
                <Input
                  description={
                    isTitleGenerated
                      ? 'We have pre-filled this field based on the specs you have provided. You may also edit or create your own title.'
                      : undefined
                  }
                  label="Listing title"
                  name="item.title"
                  required
                />
              </Field>
              <Field>
                <Wysiwyg label="Item description" name="item.description" />
              </Field>
              <EstimateField
                description={listingForm.yourPriceEstimateHelpText ?? undefined}
              />
            </Fieldset>
            <Fieldset
              accordion
              legend={listingForm.listingFormPhotosTitle}
              helpText={listingForm.listingFormPhotosHelp}
              icon={<PhotographyIcon />}
            >
              <Field>
                <Upload
                  images={images}
                  label="Instrument photos"
                  name="item.uploads"
                  onDeleteImage={onDeleteImage}
                  sortable
                  ref={uploadRef}
                />
              </Field>
            </Fieldset>
            <Fieldset
              accordion
              legend={
                includeListingFields
                  ? listingForm.listingFormPriceTitle
                  : listingForm.listingFormPrivacyTitle
              }
              helpText={
                includeListingFields
                  ? listingForm.listingFormPriceHelp
                  : listingForm.listingFormPrivacyHelp
              }
              icon={<ChecklistIcon />}
            >
              {includeListingFields && (
                <Field>
                  <PriceSuggestion
                    categoryId={formik.values.item.categoryId}
                    brandId={formik.values.item.brandId}
                    modelId={formik.values.item.modelId}
                    year={
                      formik.values.item.madeInYear
                        ? Number(formik.values.item.madeInYear)
                        : undefined
                    }
                    handedness={formik.values.item.handedness || undefined}
                  >
                    <Currency
                      label="Asking price"
                      name="listing.askingPrice.amountCents"
                      currencyFieldName="listing.askingPrice.currencyIso"
                      required
                    />
                  </PriceSuggestion>
                </Field>
              )}
              {!includeListingFields && (
                <Field>
                  <RadioGroup
                    inline
                    label="Would you like this item to be private?"
                    required
                  >
                    <Radio
                      name="item.privacy"
                      value="PUBLIC"
                      label="Public"
                      copy="Others will be able to see and like collection items that are public on your profile."
                      required
                      wrapper
                      center
                      blueLabel
                      large
                    />
                    <Radio
                      name="item.privacy"
                      value="PRIVATE"
                      label="Private"
                      required
                      wrapper
                      center
                      blueLabel
                      large
                      onCheck={() =>
                        formik.setFieldValue('item.acceptingOffers', false)
                      }
                    />
                  </RadioGroup>
                </Field>
              )}
              {(includeListingFields ||
                formik.values.item.privacy === 'PUBLIC') && (
                <>
                  <Field>
                    <RadioGroup
                      inline
                      label="Would you like to accept offers?"
                      required
                    >
                      <Radio
                        name="item.acceptingOffers"
                        value={true}
                        parse={(value) => value === 'true'}
                        label="Yes"
                        required
                        wrapper
                        center
                        blueLabel
                        large
                      />
                      <Radio
                        name="item.acceptingOffers"
                        value={false}
                        parse={(value) => value === 'true'}
                        label="No"
                        required
                        wrapper
                        center
                        blueLabel
                        large
                      />
                    </RadioGroup>
                  </Field>
                  {includeListingFields && formik.values.item.acceptingOffers && (
                    <OfferThreshold>
                      <Input
                        label="Automatically decline offers below this amount"
                        name="item.offerDeclineThreshold"
                        parse={(value) => {
                          const parsed = parseFloat(value)
                          return isNaN(parsed) ? '' : parsed
                        }}
                        placeholder="Percent"
                        suffix="%"
                        type="number"
                        min={0}
                        max={100}
                        numberSpinner={false}
                      />
                      <QuickOfferAmounts>
                        <InputLabel as="span">Quick amounts</InputLabel>
                        <ButtonGroup fullWidth gap={12}>
                          <Button
                            onClick={() =>
                              formik.setFieldValue(
                                'item.offerDeclineThreshold',
                                70
                              )
                            }
                            variant="small"
                          >
                            70%
                          </Button>
                          <Button
                            onClick={() =>
                              formik.setFieldValue(
                                'item.offerDeclineThreshold',
                                80
                              )
                            }
                            variant="small"
                          >
                            80%
                          </Button>
                          <Button
                            onClick={() =>
                              formik.setFieldValue(
                                'item.offerDeclineThreshold',
                                90
                              )
                            }
                            variant="small"
                          >
                            90%
                          </Button>
                        </ButtonGroup>
                      </QuickOfferAmounts>
                    </OfferThreshold>
                  )}
                </>
              )}
            </Fieldset>
            {includeListingFields && (
              <>
                {listingId ? null : (
                  <Fieldset
                    accordion
                    legend={listingForm.listingFormTypeTitle}
                    helpText={listingForm.listingFormTypeHelp}
                    icon={<PriceIcon />}
                  >
                    <Field
                      description={
                        listingForm.listingFormTypeText && (
                          <Html html={listingForm.listingFormTypeText} />
                        )
                      }
                    >
                      <Plans
                        fieldName="listing.type"
                        fieldRequired
                        list={listingForm.planSelect.map((plan) => ({
                          title: plan.plansLongTitle,
                          titleSize: 'small',
                          subtitle: plan.plansSubtitle,
                          tooltip: plan.tooltip,
                          details: plan.plansDetails && (
                            <Html html={plan.plansDetails} />
                          ),
                          list: plan.plansList,
                          variant: plan.plansTheme,
                          icon: plan.plansIcon,
                          label: plan.plansLabel,
                          fieldValue: plan.plansHandle,
                          fieldChecked:
                            formik.values.listing.type === plan.plansHandle,
                        }))}
                      />
                    </Field>
                  </Fieldset>
                )}
                <Fieldset
                  accordion
                  legend={listingForm.listingFormShippingTitle}
                  helpText={listingForm.listingFormShippingHelp}
                  icon={<PackingIcon />}
                >
                  <Field
                    description={
                      listingForm.listingFormShippingText && (
                        <Html html={listingForm.listingFormShippingText} />
                      )
                    }
                  >
                    <RadioGroup
                      label={listingForm.listingFormShippingTitle}
                      hideLabel
                      fullWidth
                    >
                      <Radio
                        name="listing.securePackaging"
                        value={true}
                        parse={(value) => value === 'true'}
                        label={
                          <>
                            {listingForm.listingFormSecurePackingYesLabel} -{' '}
                            {listingForm.listingFormSecurePackingPrice > 0 ? (
                              <Money
                                amount={
                                  listingForm.listingFormSecurePackingPrice
                                }
                                currency="USD"
                              />
                            ) : (
                              'Free'
                            )}
                            {listingForm.listingFormShippingCosts &&
                              ` ${listingForm.listingFormShippingCosts}`}
                          </>
                        }
                        required
                        wrapper
                        center
                        blueLabel
                        boldLabel
                        large
                        font="medium"
                        layout="vertical"
                      />
                      <Radio
                        name="listing.securePackaging"
                        value={false}
                        parse={(value) => value === 'true'}
                        label={listingForm.listingFormSecurePackingNoLabel}
                        required
                        wrapper
                        center
                        blueLabel
                        boldLabel
                        large
                        font="medium"
                        layout="vertical"
                      />
                    </RadioGroup>
                  </Field>
                </Fieldset>
                <AddressCheck
                  accountId={user.id}
                  hasAddress={(hasAddress) => setRequiresAddress(!hasAddress)}
                />
              </>
            )}
            <Submit arrow heading={listingForm.listingFormCompleteTitle}>
              {includeListingFields && (
                <SellerCutDetails
                  listingForm={listingForm}
                  photographPrice={photographPrice}
                  values={formik.values}
                />
              )}

              <ButtonGroup fullWidth={true}>
                {/* // TODO Medium Priority: Add preview function */}
                {/* {includeListingFields && (
                  <Button
                    disabled={saveItemState.fetching}
                    fullWidth
                    variant="secondary"
                  >
                    Preview listing
                  </Button>
                )} */}
                <Button
                  disabled={saveItemState.fetching || requiresAddress}
                  fullWidth
                  submit
                  variant="primaryDark"
                >
                  Submit
                </Button>
              </ButtonGroup>
            </Submit>
          </AccordionForm>
        )
      }}
    </Formik>
  )
}

ItemForm.defaultProps = {
  images: [],
  item: {
    privacy: '',
    title: '',
    categoryId: '',
    brandId: '',
    modelId: '',
    madeInYear: '',
    madeInCountry: '',
    handedness: '',
    finishId: '',
    materials: {
      topId: '',
      backId: '',
      neckId: '',
      fretboardId: '',
      bodyId: '',
    },
    description: '',
    acceptingOffers: false,
    offerDeclineThreshold: '',
    uploads: [],
    originalParts: true,
    originalFinish: true,
    originalCase: true,
    userEstimate: {
      amountCents: '',
      currencyIso: 'USD',
    },
  },
  listing: {
    askingPrice: {
      amountCents: '',
      currencyIso: 'USD',
    },
    securePackaging: '',
    type: 'CARTERS',
  },
}

ItemForm.propTypes = {
  heading: PropTypes.string,
  headingAlign: PropTypes.oneOf(['left', 'center']),
  headingElement: PropTypes.string,
  headingSize: PropTypes.oneOf(['large', 'small']),
  images: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      isDeleted: PropTypes.bool,
      url: PropTypes.string.isRequired,
    })
  ),
  includeListingFields: PropTypes.bool,
  existingTitleParts: PropTypes.shape({
    brand: PropTypes.string,
    model: PropTypes.string,
    year: PropTypes.number,
    finish: PropTypes.string,
  }),
  item: PropTypes.shape({
    privacy: PropTypes.string,
    title: PropTypes.string,
    categoryId: PropTypes.string,
    brandId: PropTypes.string,
    modelId: PropTypes.string,
    madeInYear: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    madeInCountry: PropTypes.string,
    handedness: PropTypes.string,
    finishId: PropTypes.string,
    materials: PropTypes.shape({
      topId: PropTypes.string,
      backId: PropTypes.string,
      neckId: PropTypes.string,
      fretboardId: PropTypes.string,
      bodyId: PropTypes.string,
    }),
    description: PropTypes.string,
    acceptingOffers: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    offerDeclineThreshold: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    uploads: PropTypes.arrayOf(
      PropTypes.shape({ uploadId: PropTypes.string.isRequired })
    ),
  }),
  itemId: PropTypes.string,
  userEstimate: PropTypes.shape({
    amountCents: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    currencyIso: PropTypes.string,
  }),
  listing: PropTypes.shape({
    askingPrice: PropTypes.shape({
      amountCents: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      currencyIso: PropTypes.string,
    }),
    securePackaging: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  }),
  listingId: PropTypes.string,
  onDeleteImage: PropTypes.func,
  onSave: PropTypes.func,
  photographPrice: PropTypes.number,
}

function generateTitle(parts) {
  if (!parts) return ''

  return `${parts.brand} ${parts.model} ${parts.year}, ${parts.finish}`
}

function SellerCutDetails({ listingForm, photographPrice, values }) {
  // Not actually cents so no need to divide there
  const askingPrice = parseFloat(values.listing.askingPrice?.amountCents) || 0

  const sellerCutPercentage = parseFloat(
    process.env.RAZZLE_SELLER_CUT_PERCENTAGE
  )
  const feePercentage = 1 - sellerCutPercentage
  const photographyAmount =
    values.listing.type === 'CARTERS' && photographPrice ? photographPrice : 0
  const shippingAmount = values.listing.securePackaging
    ? listingForm.listingFormSecurePackingPrice
    : 0

  return (
    <div style={{ flexDirection: 'column', marginBottom: 42 }}>
      <p
        style={{
          paddingBottom: 20,
          marginBottom: 10,
          borderBottom: '1px solid #C4C6C8',
        }}
      >
        (<Money amount={askingPrice} currency="USD" /> -{' '}
        {(feePercentage * 100).toFixed(2)}%)
        {values.listing.type === 'CARTERS' && photographPrice && (
          <>
            {' '}
            - <Money amount={photographyAmount} currency="USD" /> for
            photography
          </>
        )}
        {values.listing.securePackaging && (
          <>
            {' '}
            - <Money amount={shippingAmount} currency="USD" /> for shipping
            materials and protection
          </>
        )}{' '}
        ={' '}
        <Money
          amount={Math.max(
            0,
            askingPrice * sellerCutPercentage -
              photographyAmount -
              shippingAmount
          ).toFixed(2)}
          currency="USD"
        />
      </p>

      <Html html={listingForm.listingFormRepairTerms} />
    </div>
  )
}

SellerCutDetails.propTypes = {
  listingForm: PropTypes.object,
  photographPrice: PropTypes.number,
  values: PropTypes.object,
}
