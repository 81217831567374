import { useQuery, useMutation } from 'urql'
import { useParams } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { useGlobals } from '../hooks'
import Html from '../data/Html'
import { setRecentlyViewedItemId } from '../hooks/useRecentlyViewed'
import { down, up } from 'styled-breakpoints'
import styled from 'styled-components'

import Container, { ContainerStyles } from '../_helpers/Container'

import ProductCarousel, {
  Wrapper as ProductCarouselWrapper,
  MainSlider as ProductCarouselMainSlider,
} from '../shared/ProductCarousel'
import ListingTabs from './ListingTabs'
import ProductPurchaseCard from './ProductPurchaseCard'
import ProductSidebarCta from './ProductSidebarCta'
import SellerInfoCard from './SellerInfoCard'
import ListingAccordion from './ListingAccordion'
import Hr from '../shared/Hr'
import RecentlyViewedCarousel from '../shared/RecentlyViewedCarousel'
import Cta from '../shared/Cta'
import Spacer from '../_helpers/Spacer'
import NotFound from '../404/NotFound'
import Loader from '../shared/Loader'
import SectionCarousel from '../shared/SectionCarousel'
import Video from '../shared/Video'
import { Error } from '../_atoms/ErrorMessage'

import { useCartContext } from '../cart/CartContext'
import Meta from '../_helpers/Meta'
import useAnalyticsViewItem from '../analytics/useAnalyticsViewItem'
import { useUserContext } from '../auth/UserContext'

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.colors.white};
`

const InnerWrapper = styled.div`
  ${ContainerStyles}
  position: relative;
  display: flex;

  ${down('medium')} {
    flex-direction: column;
    padding-left: 20px;
    padding-right: 20px;

    > *:not(:first-child) {
      margin-top: 20px;
    }
  }

  ${up('large')} {
    justify-content: space-between;
    align-items: flex-start;
  }
`

const LeftPanel = styled.div`
  position: relative;

  ${up('large')} {
    flex: 2 0 50%;
    min-width: 0;
  }

  > * {
    margin-top: 20px;
  }

  ${ProductCarouselWrapper} {
    margin-top: 0;
  }
`

const RightPanel = styled.div`
  ${up('large')} {
    flex: 1 0 28%;
    min-width: 400px;
    max-width: 40%;
    position: sticky;
    top: 20px;
    margin-left: 40px;
  }

  > *:not(:first-child) {
    margin-top: 20px;
  }
`

const Callout = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 5px;
  background-color: ${({ theme }) => theme.colors.darkBlue};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  color: ${(props) => props.theme.colors.white};
  font-size: 11px;
  margin-top: 0;

  @media (min-width: 350px) {
    font-size: 12px;
  }

  ${up('small')} {
    font-size: 13px;
  }

  hr {
    margin-right: 0.8em;
    margin-left: 0.8em;
    border: 0;
    width: 1px;
    height: 0.9em;
    background-color: currentColor;
    opacity: 0.5;
  }

  span {
    font-size: 12px;
    font-weight: 700;

    @media (min-width: 350px) {
      font-size: 13px;
    }

    ${up('small')} {
      font-size: 14px;
    }
  }

  + ${ProductCarouselWrapper} ${ProductCarouselMainSlider} {
    border-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
`

const ITEM_QUERY = `
  query ShopItem($itemId: ID!, $orderId: [ID!]) {
    item(id: $itemId) {
      id
      title
      madeInYear
      madeInYearRange
      description
      inViewerWishlist
      slug
      sku

      localPickupOnly

      removedAt

      currentViewerOffer {
        id
      }

      images {
        id
        url(size: "product")
        thumb: url(size: "cart")
        dimensions {
          width
          height
        }
      }

      account {
        id
        username
        displayName
        bio
        isPrivateSeller
        isProfessionalSeller
        isCarterVintage

        avatarImage {
          id
          url
        }

        bannerImage {
          id
          url
        }
      }

      brand {
        id
        title
        ... on brands_brands_Entry {
          uri
          productSidebarCtaTitle
          productSidebarCtaText
          productSidebarCtaImage {
            id
            small: url(transform: "small")
            medium: url(transform: "medium")
            width
            height
            title
          }
          buttons {
            ... on buttons_item_BlockType {
              buttonTheme
              itemLink {
                url
                text
              }
              openChat
            }
          }
        }
      }

      model { id title }
      handedness
      madeInCountry {
        name
      }
      materials {
        body { id title }
        neck { id title }
        fretboard { id title }
        top { id title }
        back { id title }
      }

      notesAttributes

      listing {
        id
        removedAt
        askingPrice {
          amount
          currency { iso }
        }
        inViewerCart(orderId: $orderId)
        isAvailable
        forSale
      }

      listingOnHold

      acceptingOffers
      offerDeclineThreshold

      catalogProduct {
        id
        tpn
      }

      # Attributes for itemHistoryAttributes query
      category {
        id
        title
      }
      madeInYear
      originalParts
      originalFinish

      finish {
        id
        title
      }

      youtubeUrls
    }
  }
`

const RECORD_VIEW = `
  mutation RecordListingView($id: ID!) {
    listingRecordView(id: $id) {
      success
    }
  }
`

export default function ProductPage() {
  const [recordedView, setRecordedView] = useState(false)
  const { itemId } = useParams()
  const { product } = useGlobals('product')
  const { openOrderIds } = useCartContext()
  const cta = product?.cta[0]
  const { user } = useUserContext()

  const [{ fetching, data, error }] = useQuery({
    query: ITEM_QUERY,
    variables: { itemId, orderId: openOrderIds },
  })

  const [, recordView] = useMutation(RECORD_VIEW)

  useEffect(() => {
    if (!recordedView && data?.item?.listing?.id) {
      recordView({ id: data.item.listing.id })
      setRecordedView(true)
    }
  }, [data, recordView, recordedView, setRecordedView])

  useEffect(() => {
    if (data?.item?.id) {
      setRecentlyViewedItemId(data.item.id)
    }
  }, [data?.item?.id])

  useAnalyticsViewItem(data?.item, user)

  if (fetching) return <Loader background="#fff" />
  if (error) return `Error: ${error.message}`

  if (!data?.item?.listing) return <NotFound />

  let handedness
  switch (data.item.handedness) {
    case 'LEFT_HANDED':
      handedness = 'Left'
      break
    case 'RIGHT_HANDED':
      handedness = 'Right'
      break
  }

  const notesAttributes = data.item.notesAttributes
    ? JSON.parse(data.item.notesAttributes)
    : {}

  const spec = {
    Instrument: data.item.category.title,
    Brand: data.item.brand.title,
    Model: data.item.model.title,
    Finish: data.item.finish.title,
    SKU: data.item.sku || undefined,
    Handedness: handedness,
    'Made In Year':
      data.item.madeInYear > 0 ? data.item.madeInYearRange : undefined,
    'Made In Country': data.item.madeInCountry,
    'Body Material': data.item.materials.body?.title || undefined,
    'Neck Material': data.item.materials.neck?.title || undefined,
    'Fretboard Material': data.item.materials.fretboard?.title || undefined,
    'Top Material': data.item.materials.top?.title || undefined,
    'Back Material': data.item.materials.back?.title || undefined,
    ...notesAttributes,
  }

  const detail = {
    description: data.item.description,
    spec,
    url: `${process.env.RAZZLE_BASE_FRONTEND_URL}/shop/${data.item.slug}/${data.item.id}`,
    imgUrl: data.item.images[0]?.url,
    title: data.item.title,
    shipping: {
      countries: [],
    },
  }

  const seller = {
    ...data.item.account,
    avatar: {},
  }

  // TODO: Get Exchange/Exchange+ status from listing
  const isExchangePlus = false

  function itemMetaTitle(item) {
    const title = item?.title
      ?.toString()
      .split(' ')
      .slice(0, 10)
      .join(' ')
      .replace(/[^A-Za-z0-9]+$/, '')

    return title
  }

  function itemMetaDescription(item) {
    return `View the listing for ${item.title} at Carter Vintage Guitar Exchange`
  }

  return (
    <>
      <Meta
        title={itemMetaTitle(data.item)}
        description={itemMetaDescription(data.item)}
        image={
          data.item.images.length
            ? {
                url: data.item.images[0].url,
                width: data.item.images[0].dimensions.width,
                height: data.item.images[0].dimensions.height,
              }
            : undefined
        }
        url={`${process.env.RAZZLE_BASE_FRONTEND_URL}/shop/${data.item.slug}/${data.item.id}`}
      />
      <Wrapper>
        <Spacer fromSize="30px" toSize="47px" />
        <InnerWrapper>
          <LeftPanel>
            {data.item.removedAt && (
              <Error>
                Item removed at {new Date(data.item.removedAt).toLocaleString()}
              </Error>
            )}
            {data.item.listing.removedAt && (
              <Error>
                Listing removed at{' '}
                {new Date(data.item.listing.removedAt).toLocaleString()}
              </Error>
            )}
            {data.item.account.isPrivateSeller ? null : (
              <Callout>
                <p>
                  <span>FASTER DELIVERY</span>
                </p>
                <hr />
                <p>Directly from Carter Vintage</p>
              </Callout>
            )}
            {data.item.images.length ? (
              <ProductCarousel images={data.item.images} />
            ) : null}
            <ListingTabs item={detail} />
          </LeftPanel>
          <RightPanel>
            <ProductPurchaseCard item={data.item} seller={seller} />
            <ListingAccordion item={detail} />
            <SellerInfoCard
              item={data.item}
              isExchangePlus={isExchangePlus}
              user={seller}
            />
            <ProductSidebarCta
              title={data.item.brand?.productSidebarCtaTitle}
              text={data.item.brand.productSidebarCtaText}
              image={data.item.brand?.productSidebarCtaImage?.[0]}
              buttons={data.item.brand?.buttons || []}
            />
          </RightPanel>
        </InnerWrapper>
        {data.item.youtubeUrls.length > 0 && (
          <>
            <Spacer size="50px" />
            <Video videos={data.item.youtubeUrls} />
          </>
        )}
        <Spacer size="50px" />
        <Hr />
        <RecentlyViewedCarousel theme="light" />
        {data.item.brand && (
          <SectionCarousel
            filters={[
              { key: 'brand', value: JSON.stringify([data.item.brand.title]) },
            ]}
            header={`More From ${data.item.brand.title}`}
            view={`/${data.item.brand.uri}`}
            viewText="View All"
            filterListings={(listings) =>
              listings.filter((listing) => listing.id !== data.item.listing.id)
            }
          />
        )}
        <Container>
          {cta && (
            <Cta
              header={cta.title}
              text={<Html html={cta.ctaText} />}
              variant={cta.ctaBackgroundColor}
              buttons={cta.ctaLinks.map((item) => ({
                text: item.itemLink.text,
                to: item.itemLink.url,
                variant: item.buttonTheme,
              }))}
            />
          )}
        </Container>
        <Spacer size="50px" />
      </Wrapper>
    </>
  )
}
