import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { useQuery } from 'urql'
import { useSearchParams } from 'react-router-dom'
import { scrollToElement } from '../_abstracts/Layout'
import { InputContainer } from '../_atoms/Input'
import FilterList from '../filter/FilterList'
import { useToggle } from '../hooks'
import ActiveFilters from '../shared/ActiveFilters'
import { Wrapper as CardListWrapper } from '../shared/CardList'
import Loader from '../shared/Loader'
import PaginationOptions from '../shared/PaginationOptions'
import Panel from '../shared/Panel'
import Toggle from '../shared/Toggle'
import SearchResults from './SearchResults'
import useSearchQuery, { DEFAULT_SORT } from './useSearchQuery'
import { flattenFilters } from './utilities'
import { colors } from '../theme'
import { down, up } from 'styled-breakpoints'
import Pagination from '../shared/Pagination'

const SEARCH_LISTINGS = `
  query SearchListings($first: Int, $after: String, $query: SearchQuery, $sort: SearchSort) {
    listingSearch(first: $first, after: $after, query: $query, sort: $sort) {
      pageInfo {
        hasNextPage
        endCursor
      }
      totalCount
      filters {
        __typename
        key
        label
        ... on SearchFilterSelect {
          multiple
          values {
            value
            resultCount
          }
        }
        ... on SearchFilterNumberRange {
          min
          max
          selectedMin
          selectedMax
        }
      }
      edges {
        node {
          id
          isAvailable
          isAvailableSearch
          askingPrice {
            amount
            currency {
              iso
            }
          }
          item {
            id
            slug
            images {
              id
              url(size: "productThumb")
            }
            title
            inViewerWishlist
            account {
              id
              username
              displayName
              isPrivateSeller
              isProfessionalSeller
            }
          }
        }
      }
    }
  }
`

export const cardGap = '48px'

const FilterAndPagination = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 18px;
  align-items: center;

  > * {
    flex-shrink: 0;
    width: fit-content;
    white-space: nowrap;
  }

  > *:not(:first-child) {
    margin-left: 15px;
  }

  ${down('small')} {
    flex-wrap: wrap;
    justify-content: center;

    ${InputContainer} {
      margin-top: 10px;
    }
  }

  ${down('xsmall')} {
    > *:last-child {
      flex-shrink: 1;
    }
  }

  ${InputContainer} {
    margin-bottom: 0;
  }
`

const filtersLayout = css`
  flex: 0 0 100%;
  padding: calc(${cardGap} / 2);

  ${down('medium')} {
    padding-bottom: 0;
  }

  ${up('large')} {
    flex-basis: 275px;
    padding-right: 0;
  }
`

const FiltersWrapper = styled.div`
  ${filtersLayout}
`

const FiltersLoader = styled.div`
  ${filtersLayout}

  ${down('medium')} {
    display: none;
  }
`

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid ${(props) => props.theme.colors.midGrey};

  ${CardListWrapper} {
    flex: 1 1;
    align-self: flex-start;
    padding: calc(${cardGap} / 2);
  }
`

const Filters = ({
  filters,
  hiddenFilters,
  hideKeywords,
  flatQueryFilters,
  windowWidth,
}) => {
  // Remove hidden filters from visible list, e.g. to skip showing Brands filter on a Brand page
  let visibleFilters = filters.filter(
    (f) => !hiddenFilters || !hiddenFilters.find((hf) => f.key === hf.key)
  )

  visibleFilters = visibleFilters.filter((f) => {
    if (f.__typename === 'SearchFilterSelect') {
      f.values = f.values.filter((v) => {
        // Check if this filter value is selected (for styling in filter/Select)
        v.isSelected = !!flatQueryFilters.find(
          (filter) => filter.key === f.key && v.value === filter.value
        )

        // Show selected values and values with > 0 results
        return v.isSelected || v.resultCount > 0
      })

      // Don't show filter groups with only 0-result filter values
      return f.values.length > 0
    }

    // Hide range filters (e.g. price, year) when min and max are the same (so no filtering is possible)
    if (f.__typename === 'SearchFilterNumberRange') {
      return f.min !== f.max
    }

    // Show filters by default
    return true
  })

  return (
    <FilterList
      hideKeywords={hideKeywords}
      filters={visibleFilters}
      windowWidth={windowWidth}
    />
  )
}

Filters.propTypes = {
  filters: PropTypes.array,
  hiddenFilters: PropTypes.array,
  hideKeywords: PropTypes.bool,
  flatQueryFilters: PropTypes.array,
  windowWidth: PropTypes.number,
}

export default function Listings({
  border,
  hiddenKeywords,
  hiddenSkus,
  hiddenFilters,
  loaderBackground,
}) {
  const panelRef = useRef()
  const { query, paginationVars, setFilter, clearFilter, clearAllFilters } =
    useSearchQuery()
  let hideKeywords = false
  if (hiddenKeywords) {
    query.keywords = hiddenKeywords
    query.narrowKeywords = true
    hideKeywords = true
  }

  if (hiddenSkus && hiddenSkus.length > 0) {
    query.skus = hiddenSkus
  }

  const [searchParams] = useSearchParams()
  const after = searchParams.get('after') || 0
  const currentPage = Math.floor(after / paginationVars.perPage) + 1
  const pagination = {
    first: paginationVars.perPage,
    after: after.toString(),
  }

  const queryFilters = hiddenFilters?.length
    ? [...hiddenFilters, ...query.filters]
    : query.filters

  // Sort may be included as a `sort` param in the URL, or may be included in hidden filters (e.g. by a collection page)
  let selectedSort =
    searchParams.get('sort') ||
    queryFilters.find(({ key }) => key === 'sort')?.value

  if (!selectedSort) {
    // Use Newest - Oldest sorting by default, or Relevancy on keyword search listing pages
    selectedSort = query.keywords ? 'DEFAULT' : DEFAULT_SORT
  }

  const [{ fetching, data, error }] = useQuery({
    query: SEARCH_LISTINGS,
    variables: {
      ...pagination,
      query: {
        ...query,
        filters: queryFilters,
        includeOnHold: true,
      },
      sort: selectedSort,
    },
  })

  // Only render filters on client, not on server - as their default toggled state is different across
  // desktop (shown) and mobile (hidden), so we need to delay rendering until checking screen size
  const [defaultFilterState, setDefaultFilterState] = useState(false)
  const [filtersShown, toggleFiltersShown] = useToggle(false)
  const [windowWidth, setWindowWidth] = useState()
  useEffect(() => {
    setWindowWidth(window.innerWidth)
    if (window.innerWidth >= 1024) {
      toggleFiltersShown(true)
    }
    setDefaultFilterState(true)
  }, [toggleFiltersShown])

  // Scroll back to top of results when filtering/paging changes:
  const queryString = searchParams.toString()
  const originalQueryString = useRef(queryString)
  useEffect(() => {
    if (panelRef.current && queryString !== originalQueryString.current) {
      scrollToElement(panelRef.current)
    }
  }, [currentPage, queryString])

  if (fetching && !data)
    return <Loader background={loaderBackground} height="250px" />

  if (error) {
    console.error(error)
    return <p>{error.message}</p>
  }

  let {
    listingSearch: { totalCount, edges, filters },
  } = data

  // Copy of `query.filters` with multiple selection filters flattened
  const flatQueryFilters = flattenFilters(query?.filters)

  const totalPages = Math.ceil(totalCount / paginationVars.perPage)

  return (
    <>
      <Panel border={border} ref={panelRef}>
        {flatQueryFilters.length ? (
          <ActiveFilters
            filters={flatQueryFilters}
            clearFilter={clearFilter}
            clearAllFilters={clearAllFilters}
          />
        ) : undefined}
        <FilterAndPagination>
          <Toggle
            label="Filters"
            toggleState={filtersShown}
            onChange={toggleFiltersShown}
          />
          <PaginationOptions
            perPage={paginationVars.perPage}
            keywords={query.keywords}
            setFilter={setFilter}
            sort={selectedSort}
          />
        </FilterAndPagination>
        <Content>
          {(filtersShown && (
            <FiltersWrapper>
              <Filters
                filters={filters}
                hiddenFilters={hiddenFilters}
                hideKeywords={hideKeywords}
                flatQueryFilters={flatQueryFilters}
                windowWidth={windowWidth}
              />
            </FiltersWrapper>
          )) ||
            (defaultFilterState ? null : (
              <FiltersLoader>
                <Loader height="360px" />
              </FiltersLoader>
            ))}
          <SearchResults
            filtersShown={filtersShown}
            results={edges.map((edge) => edge.node)}
          />
        </Content>

        <Pagination
          first={currentPage == 1}
          last={currentPage == totalPages}
          current={currentPage}
          pages={totalPages}
          buildLink={(page) => {
            const params = new URLSearchParams(searchParams)

            params.set(
              'after',
              ((page - 1) * paginationVars.perPage).toString()
            )

            return `?${params.toString()}`
          }}
          scrollToTop={false}
        />
      </Panel>
    </>
  )
}

Listings.defaultProps = {
  border: true,
  loaderBackground: colors.xLightGrey,
}

Listings.propTypes = {
  border: PropTypes.bool,
  hiddenFilters: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  hiddenKeywords: PropTypes.string,
  hiddenSkus: PropTypes.arrayOf(PropTypes.string),
  loaderBackground: PropTypes.string,
}
