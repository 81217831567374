import PropTypes from 'prop-types'
import Html from '../data/Html'
import Container from '../_helpers/Container'
import Spacer from '../_helpers/Spacer'
import Theme from '../_helpers/Theme'
import Visibility from '../_helpers/Visibility'
import SectionCarousel from './SectionCarousel'
import CtaImg from './CtaImg'
import IconList from './IconList'
import ButtonBlocks from './ButtonBlocks'
import RecentlyViewedCarousel from './RecentlyViewedCarousel'
import PopularListingsCarousel from './PopularListingsCarousel'
import RecommendedListingsCarousel from './RecommendedListingsCarousel'

export const query = `
  sections {
    ... on sections_imageCta_BlockType {
      id
      typeHandle
      visibility
      heading
      backgroundImage {
        id
        medium: url(transform: "medium")
        large: url(transform: "large")
        xl: url(transform: "xl")
        xxl: url(transform: "xxl")
        width
        height
      }
      textSimple
      children {
        ... on sections_imageCtaListItem_BlockType {
          id
          icon
          plansLabel
        }
      }
      linkField {
        text
        url
      }
    }
    ... on sections_iconList_BlockType {
      id
      typeHandle
      visibility
      theme
      heading
      textSimple
      children {
        ... on sections_iconListItem_BlockType {
          id
          textSimple
          icon
          htmlTooltip
        }
      }
    }
    ... on sections_splitBlocks_BlockType {
      id
      theme
      visibility
      heading
      typeHandle
      linkField {
        text
        url
      }
      children {
        ... on sections_splitBlockChild_BlockType {
          id
          textSimple
          heading
          linkField {
            customText
            url
          }
          image {
            id
            small: url(handle: "small")
            medium: url(handle: "medium")
            width
            height
          }
        }
      }
    }
    ... on sections_listings_BlockType {
      id
      typeHandle
      visibility
      theme
      heading
      listingsSource {
        ... on categories_categories_Entry {
          id
          sectionHandle
          uri
          title
          heroTitle
        }
        ... on brands_brands_Entry {
          id
          sectionHandle
          uri
          title
          heroTitle
        }
        ... on collections_default_Entry {
          id
          sectionHandle
          uri
          title
          heroTitle
          filterBrands {
            id
            title
          }
          filterCategory {
            id
            title
          }
          filterModels {
            id
            title
          }
          filterSellers
          filterPriceMin
          filterPriceMax
          filterFromYear
          filterToYear
          filterKeywords
          handedness
          sort
          skus
        }
      }
      listingsLink {
        text
        url
      }
    }
    ... on sections_popularListings_BlockType {
      id
      typeHandle
      visibility
      theme
    }
    ... on sections_recommendedListings_BlockType {
      id
      typeHandle
      visibility
      theme
    }
    ... on sections_recentlyViewed_BlockType {
      id
      typeHandle
      visibility
      theme
    }
  }
`

export default function Sections({ sections }) {
  return (
    <>
      {sections.map((section, index) => {
        const key = `${index}-${section.typeHandle}`
        let content

        switch (section.typeHandle) {
          case 'iconList':
            content = (
              <Container>
                <Spacer size="50px" />
                <IconList
                  header={section.heading}
                  ctaText={<Html html={section.textSimple} />}
                  items={section.children.map((item) => ({
                    icon: item.icon,
                    text: <Html html={item.textSimple} />,
                    tooltip: item.htmlTooltip && (
                      <Html html={item.htmlTooltip} />
                    ),
                  }))}
                />
                <Spacer size="50px" />
              </Container>
            )
            break

          case 'imageCta':
            content = (
              <CtaImg
                header={section.heading}
                text={<Html html={section.textSimple} />}
                button={section.linkField}
                image={section.backgroundImage[0]}
                items={section.children.map((item) => ({
                  icon: item.icon,
                  text: <Html html={item.plansLabel} />,
                }))}
              />
            )
            break

          case 'listings': {
            const source = section.listingsSource[0]
            if (!source) {
              console.error('No listing source provided', section)
              return
            }

            const filters = []
            let keywords
            let sort

            switch (source.sectionHandle) {
              case 'categories':
                filters.push({ key: 'category', value: source.title })
                break

              case 'brands':
                filters.push({
                  key: 'brand',
                  value: JSON.stringify([source.title]),
                })
                break

              case 'collections':
                if (source.filterBrands.length) {
                  filters.push({
                    key: 'brand',
                    value: JSON.stringify(
                      source.filterBrands.map((brand) => brand.title)
                    ),
                  })
                }

                if (source.filterModels.length) {
                  filters.push({
                    key: 'model',
                    value: JSON.stringify(
                      source.filterModels.map((model) => model.title)
                    ),
                  })
                }

                if (source.filterSellers.length) {
                  filters.push({
                    key: 'seller',
                    value: JSON.stringify(source.filterSellers),
                  })
                }

                if (source.filterCategory.length) {
                  filters.push({
                    key: 'category',
                    value: JSON.stringify(
                      source.filterCategory.map((category) => category.title)
                    ),
                  })
                }

                if (source.filterFromYear || source.filterToYear) {
                  filters.push({
                    key: 'year',
                    value: JSON.stringify([
                      source.filterFromYear,
                      source.filterToYear,
                    ]),
                  })
                }

                if (source.filterPriceMin || source.filterPriceMax) {
                  filters.push({
                    key: 'price',
                    value: JSON.stringify([
                      parseInt(source.filterPriceMin, 10) * 100,
                      parseInt(source.filterPriceMax, 10) * 100,
                    ]),
                  })
                }

                if (source.filterKeywords) {
                  keywords = source.filterKeywords
                }

                if (source.handedness) {
                  filters.push({
                    key: 'handedness',
                    value: source.handedness,
                  })
                }

                if (source.sort) {
                  sort = source.sort
                }
                break

              default:
                console.error('Unrecognised carousel source type', source)
                return
            }

            const link = section.listingsLink?.url

            content = (
              <SectionCarousel
                filters={filters}
                keywords={keywords}
                skus={source.skus?.split(',')}
                sort={sort}
                header={section.heading || source.heroTitle || source.title}
                theme={section.theme}
                view={link || `/${source.uri}`}
                viewText={link ? section.listingsLink.text : undefined}
              />
            )
            break
          }

          case 'recentlyViewed':
            content = <RecentlyViewedCarousel theme={section.theme} />
            break

          case 'popularListings':
            content = <PopularListingsCarousel theme={section.theme} />
            break

          case 'recommendedListings':
            content = <RecommendedListingsCarousel theme={section.theme} />
            break

          case 'splitBlocks': {
            const data = { items: [] }

            section.children.map((item) => {
              const image = item.image?.[0]
              const obj = {
                header: item.heading,
                text: item.textSimple && <Html html={item.textSimple} />,
                button: {
                  ...item.linkField,
                  text: item.linkField.customText,
                },
                image,
              }

              data.items.push(obj)
            })

            content = (
              <Container>
                <Spacer size="50px" />
                <ButtonBlocks
                  header={section.heading}
                  link={section.linkField}
                  {...data}
                />
                <Spacer size="50px" />
              </Container>
            )

            break
          }

          default:
            console.error('Unrecognised section type', section)
            return <p key={key}>Section type missing: {section.typeHandle}</p>
        }

        return (
          <Visibility key={key} userStatus={section.visibility}>
            <Theme theme={section.theme}>{content}</Theme>
          </Visibility>
        )
      })}
    </>
  )
}

Sections.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.object),
}
