import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { linkStates } from '../_abstracts/Type'

const StyledLink = styled(Link)`
  ${linkStates}
`

export default function SavedSearchLink({ search, children }) {
  const path = useMemo(() => {
    const params = new URLSearchParams()

    if (search.keywords) {
      params.append('keywords', search.keywords)
    }

    if (search.filters) {
      for (const filter of search.filters) {
        params.append(filter.key, filter.value)
      }
    }

    params.sort()

    return `/shop?${params.toString()}`
  }, [search])

  return (
    <StyledLink to={path} state={{ savedSearch: search }} $reverse>
      {children}
    </StyledLink>
  )
}

SavedSearchLink.propTypes = {
  search: PropTypes.shape({
    id: PropTypes.string.isRequired,
    keywords: PropTypes.string,
    filters: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      })
    ),
  }).isRequired,
  children: PropTypes.node,
}
