import { Formik } from 'formik'
import { useEffect } from 'react'
import toast from 'react-hot-toast'
import { useNavigate, useParams } from 'react-router-dom'
import { useMutation } from 'urql'
import * as Yup from 'yup'
import Input from '../_atoms/Input'
import Button from '../_atoms/Button'
import Form from '../_atoms/Form'
import { LockIcon, WarningTriIcon } from '../_base/Icon'
import FormWrapper from '../shared/FormWrapper'
import IconWrapper from '../shared/IconWrapper'

const RESET_PASSWORD = `
  mutation ResetPassword($input: AccountResetPasswordInput!) {
    accountResetPassword(input: $input) {
      success
    }
  }
`

const Schema = Yup.object().shape({
  newPassword: Yup.string().min(10, 'Min 10 characters').required('Required'),
  newPasswordConfirm: Yup.string()
    .min(10, 'Min 10 characters')
    .oneOf([Yup.ref('newPassword')], 'Passwords must match')
    .required('Required'),
})

export default function PasswordResetPage() {
  const navigate = useNavigate()
  const { passwordResetCode, passwordResetToken } = useParams()
  const [request, resetPassword] = useMutation(RESET_PASSWORD)

  async function handleSubmit(fields) {
    const parsedFields = Schema.cast(fields, { strict: true })

    // Add reset code and token from URL segment params
    parsedFields.code = passwordResetCode
    parsedFields.token = passwordResetToken

    // Password confirmation field is only for client-side match check, it shouldn't be submitted
    delete parsedFields.newPasswordConfirm

    const { data } = await resetPassword({
      input: parsedFields,
    })

    if (data?.accountResetPassword.success) {
      navigate('/login')
    }
  }

  useEffect(() => {
    if (request.fetching) {
      toast('Submitting…', { id: 'form' })
    } else if (request.data?.accountResetPassword.success) {
      toast.success('Password successfully reset', { id: 'form' })
    } else if (
      request.error ||
      request.data?.accountResetPassword.success === false
    ) {
      toast.error('An error occurred', { id: 'form' })
    }
  }, [request])

  return (
    <>
      {(request.data?.accountResetPassword.success === false && (
        <IconWrapper header="Invalid link" icon={<WarningTriIcon />}>
          <p>
            The reset link you&apos;ve used is invalid <br /> Need help? Visit
            our <a href="">Help Centre</a>
          </p>
          <Button fullWidth submit variant="primaryDark" to="/login">
            Back to login
          </Button>
        </IconWrapper>
      )) || (
        <FormWrapper header="Password reset" icon={<LockIcon />}>
          <Formik
            initialValues={{
              newPassword: '',
              newPasswordConfirm: '',
            }}
            onSubmit={handleSubmit}
            validationSchema={Schema}
          >
            <Form>
              <Input
                label="New password"
                type="password"
                name="newPassword"
                placeholder="Enter new password"
                autocomplete="new-password"
                required
              />
              <Input
                label="Confirm new password"
                type="password"
                name="newPasswordConfirm"
                placeholder="Confirm new password"
                autocomplete="new-password"
                required
              />
              <Button fullWidth submit variant="primaryDark">
                Submit
              </Button>
            </Form>
          </Formik>
        </FormWrapper>
      )}
    </>
  )
}
