import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { up } from 'styled-breakpoints'

// grid
export const containerPadding = () => css`
  ${(props) =>
    Object.entries(props.theme.breakpoints).map(([key, value]) =>
      value == '0'
        ? css`
            ${up(key)} {
              padding-right: 10px;
              padding-left: 10px;
            }
          `
        : css`
            ${up(key)} {
              padding-right: calc(
                (${value} / ${props.theme.gridMarginGutters.xsmall}) / 2
              );
              padding-left: calc(
                (${value} / ${props.theme.gridMarginGutters.xsmall}) / 2
              );
            }
          `
    )}
`

export const ContainerStyles = css`
  ${containerPadding}
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  max-width: ${(props) => props.theme.globalWidth};
`

const Wrapper = styled.div`
  ${ContainerStyles}
`

export default function Container({ children, className }) {
  return <Wrapper className={className}>{children}</Wrapper>
}

Container.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}
