import { useState, useEffect, useRef } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import Table from '../checkout/Table'
import FormWrapper from '../checkout/FormWrapper'
import Loader from '../shared/Loader'
import { useMutation } from 'urql'
import { colors } from '../theme'
import { useCartContext } from '../cart/CartContext'

const CART_MUTATION = `
  mutation GetCartForPayment($orderId: [ID!]!) {
    cart: orderEnsureTaxConsidered(orderId: $orderId) {
      success
      message

      orders {
        id
        canAcceptPrepaidCodes
        requiresShipping

        subTotal {
          amount
          currency { iso }
        }

        tax {
          amount
          amountCents
          currency { iso }
        }

        shippingCost {
          amount
          currency { iso }
        }

        discountTotal {
          amount
          amountCents
          currency { iso }
        }

        total {
          amount
          amountCents
          currency { iso }
        }

        amountRemaining {
          amount
          amountCents
          currency { iso }
        }

        amountRemainingLessRefunds {
          amount
          currency { iso }
        }

        paymentBalance {
          amount
          amountCents
          currency { iso }
        }

        lineItems {
          id

          amount {
            amount
            currency { iso }
          }

          listing {
            item {
              title
              slug
              images {
                url(size: "cart")
              }

              account {
                id
                username
                displayName
                isPrivateSeller
              }
            }
          }
        }

        shipment {
          address { id }
        }
      }
    }
  }
`

export default function CheckoutPaymentPage() {
  const navigate = useNavigate()
  const [completedOrders, setCompletedOrders] = useState([])
  const cartContext = useRef(useCartContext())
  const [, getTaxConsideredOrders] = useMutation(CART_MUTATION)
  const [openOrders, setOpenOrders] = useState(null)
  const [error, setError] = useState(null)

  async function getOpenOrders() {
    const orders = await getTaxConsideredOrders({
      orderId: cartContext.current.openOrderIds,
    })
    if (orders?.data?.cart?.success) {
      setOpenOrders(orders?.data?.cart?.orders ?? null)
    } else {
      setError(
        orders?.data?.cart?.message ||
          'There was a problem loading your order. Please contact us'
      )
    }
  }

  const refGetOpenOrders = useRef(getOpenOrders)

  useEffect(() => {
    refGetOpenOrders.current()
  }, [getTaxConsideredOrders, cartContext, refGetOpenOrders])

  if (!openOrders)
    return <Loader background={colors.xLightGrey} height="250px" />
  if (error) return <p>{error}</p>
  if (openOrders.length === 0) return <Navigate to="/" />
  if (openOrders.some((o) => o.requiresShipping && !o.shipment?.address))
    return <Navigate to="/checkout/shipping" />

  function handleOrderCompleted(orderId) {
    if (completedOrders.includes(orderId)) return

    const updatedCompletedOrders = [...completedOrders, orderId]

    if (updatedCompletedOrders.length === openOrders.length) {
      navigate('/checkout/confirmation', {
        state: {
          orderIds: updatedCompletedOrders,
        },
      })
    } else {
      setCompletedOrders(updatedCompletedOrders)
    }
  }

  return (
    <>
      <FormWrapper header="Order payment" size="large">
        <Table
          payment
          orders={openOrders}
          syncOpenOrders={getOpenOrders}
          onOrderCompleted={handleOrderCompleted}
        />
      </FormWrapper>
    </>
  )
}

CheckoutPaymentPage.propTypes = {}
