import { Helmet } from 'react-helmet-async'
import styled from 'styled-components'
import Container from '../../_helpers/Container'
import AddressList from '../../shared/AddressList'
import Subheader from '../../shared/Subheader'

const Wrapper = styled.div``

export default function DetailsPage() {
  return (
    <>
      <Helmet>
        <title>Profile | Settings | Addresses</title>
      </Helmet>
      <Wrapper>
        <Subheader border header="Address book" />
        <Container>
          <AddressList />
        </Container>
      </Wrapper>
    </>
  )
}
