import styled from 'styled-components'
import PropTypes from 'prop-types'

const Wrapper = styled.div`
  position: relative;
  background: ${(props) => props.theme.colors.aquaTint};
  border: 1px solid ${(props) => props.theme.colors.aqua};
  border-radius: 5px;
  padding: 10px 10px 10px 45px;
  display: inline-block;
  font-size: 12px;
`

const Check = styled.div`
  position: absolute;
  top: 9px;
  left: 10px;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  border: 1px solid ${(props) => props.theme.colors.aqua};

  &::after {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    content: '';
    width: 55%;
    height: 25%;
    border-bottom: 1px solid ${(props) => props.theme.colors.aqua};
    border-left: 1px solid ${(props) => props.theme.colors.aqua};
  }
`

export default function Success({ text }) {
  return (
    <Wrapper>
      <Check />
      {text}
    </Wrapper>
  )
}

Success.propTypes = {
  text: PropTypes.string.isRequired,
}
