import PropTypes from 'prop-types'
import Html from '../data/Html'
import Blocks from '../shared/Blocks'
import NumberedBlocks from '../shared/NumberedBlocks'
import Icon from './Icon'

export default function Sections({ children, numbered }) {
  const items = children.map((item) => {
    const image = item.image[0]

    const icon = item.icon ? <Icon icon={item.icon} /> : undefined

    // TODO: Use item.openChat (bool) button field to open chat (Zendesk?)
    const buttons = item.buttons.map((item) => ({
      text: item.itemLink.text,
      to: item.itemLink.url,
      variant: item.buttonTheme,
    }))

    const children = item.children.length ? (
      <Blocks blocks={item.children} />
    ) : undefined

    return {
      // Map Craft Neo fields to props expected by NumberedBlocks
      header: item.heading,
      text: <Html html={item.text} />,
      image: image?.url,
      imageAlt: image?.title,
      buttons,
      icon,
      children,
    }
  })

  return <NumberedBlocks items={items} numbered={numbered} />
}

Sections.propTypes = {
  children: PropTypes.arrayOf(PropTypes.object),
  numbered: PropTypes.bool,
}
