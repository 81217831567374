import PropTypes from 'prop-types'
import { down, up } from 'styled-breakpoints'
import styled, { css } from 'styled-components'
import { HeaderL } from '../_abstracts/Type'
import { ICON_OFFSET } from './Fieldset'

const Wrapper = styled.div`
  display: flex;

  ${({ arrow }) =>
    arrow &&
    css`
      ${up('large')} {
        padding-right: ${ICON_OFFSET}px;
      }
    `}
`

const Arrow = styled.div`
  position: relative;
  width: ${ICON_OFFSET}px;
  flex-shrink: 0;

  ${down('medium')} {
    display: none;
  }

  &::before,
  &::after {
    position: absolute;
    bottom: 50%;
    background-color: ${({ theme }) => theme.colors.midGrey};
    content: '';
  }

  &::before {
    right: 20px;
    left: 40px;
    height: 1px;
  }

  &::after {
    top: 0;
    left: 40px;
    width: 1px;
  }
`

const ArrowHead = styled.svg`
  position: absolute;
  top: 50%;
  right: 15px;
  width: 14px;
  height: 13px;
  transform: translateY(-50%);

  polygon {
    fill: ${({ theme }) => theme.colors.accent};
  }
`

const Inner = styled.div`
  flex-grow: 1;
  padding: 42px;
  margin-top: 9px;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.darkBlue};
  border-radius: 6px;
`

const Heading = styled.p`
  ${HeaderL};
  margin-top: -0.4em;
  margin-bottom: 8px;
  text-align: center;
`

export default function Submit({ arrow, children, heading }) {
  return (
    <Wrapper arrow={arrow}>
      {arrow && (
        <Arrow>
          <ArrowHead viewBox="0 0 14 13" xmlns="http://www.w3.org/2000/svg">
            <polygon points="0,0 14,6.5 0,13" />
          </ArrowHead>
        </Arrow>
      )}
      <Inner>
        {heading && <Heading>{heading}</Heading>}
        {children}
      </Inner>
    </Wrapper>
  )
}

Submit.propTypes = {
  arrow: PropTypes.bool,
  children: PropTypes.node,
  heading: PropTypes.string,
}
