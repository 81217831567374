import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { Media, MediaImage } from '../_base/Media'
import { colors } from '../theme'

export const Wrapper = styled.div`
  position: relative;
  flex-shrink: 0;
  background: 1px ${({ background }) => background};
  border: 1px solid ${({ border }) => border};
  border-radius: 50%;

  ${({ size }) =>
    (size === 'large' &&
      css`
        width: 104px;
      `) ||
    (size === 'medium' &&
      css`
        width: 80px;
      `) ||
    (size === 'small' &&
      css`
        width: 50px;
      `) ||
    (size === 'minute' &&
      css`
        width: 30px;
      `) ||
    (size &&
      css`
        width: ${size};
      `)}
`

const Inner = styled.div`
  position: relative;
  margin: 4%;
  overflow: hidden;

  ${Media} {
    border-radius: 50%;

    &::before {
      display: block;
      padding-top: 100%;
      content: '';
    }

    ${({ blendMode }) =>
      blendMode &&
      css`
        mix-blend-mode: luminosity;
      `}
  }
`

export default function Circle({
  background,
  blendMode,
  border,
  children,
  image,
  size,
}) {
  return (
    <Wrapper
      background={background}
      blendMode={blendMode}
      border={border}
      size={size}
    >
      <Inner>
        <Media>
          {image && (
            <MediaImage
              srcSet={image.url || `${image.xs} 225w, ${image.small} 450w`}
              sizes={
                (size === 'large' && '104px') ||
                (size === 'medium' && '80px') ||
                (size === 'small' && '50px') ||
                (size === 'minute' && '30px') ||
                (size && `${size}px`)
              }
              alt={image.title || ''}
              width={image.width}
              height={image.height}
              loading="lazy"
            />
          )}
        </Media>
      </Inner>
      {children}
    </Wrapper>
  )
}

Circle.defaultProps = {
  background: colors.white,
  border: colors.midGrey,
}

Circle.propTypes = {
  alt: PropTypes.string,
  background: PropTypes.string,
  blendMode: PropTypes.string,
  border: PropTypes.string,
  children: PropTypes.node,
  image: PropTypes.shape({
    url: PropTypes.string,
    xs: PropTypes.string,
    small: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    title: PropTypes.string,
  }),
  size: PropTypes.string,
}
