import styled from 'styled-components'
import PropTypes from 'prop-types'
import { HeaderS, Body } from '../_abstracts/Type'
import { Icon, CarterCIcon } from '../_base/Icon'
import Button, { ButtonStyle } from '../_atoms/Button'

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  background: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.midGrey};
  border-radius: ${(props) => props.theme.radii};
  padding: 35px 25px 25px;
  margin-top: 31px;
  text-align: center;

  ${ButtonStyle} {
    margin-top: 15px;
  }
`

const Header = styled.h2`
  ${HeaderS};
  margin-bottom: 8px;
  color: ${(props) => props.theme.colors.darkBlue};
`

const Text = styled.p`
  ${Body};
  color: ${(props) => props.theme.colors.xDarkGrey};
`

const WrapperMedia = styled.div`
  position: absolute;
  border: 1px solid ${(props) => props.theme.colors.midGrey};
  border-radius: 50%;
  top: -20%;
  padding: 5px;
  width: 62px;
  height: 62px;
  left: 50%;
  background-color: ${(props) => props.theme.colors.white};
  transform: translateX(-50%);
`

const IconImage = styled.i`
  ${Icon} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 32px;
  }
`

export default function CtaFeatured({ header, text, buttonText, buttonUrl }) {
  return (
    <Wrapper>
      <WrapperMedia>
        <IconImage>
          <CarterCIcon />
        </IconImage>
      </WrapperMedia>
      <Header>{header}</Header>
      {text && <Text>{text}</Text>}
      <Button fullWidth variant="secondary" to={buttonUrl}>
        {buttonText}
      </Button>
    </Wrapper>
  )
}

CtaFeatured.propTypes = {
  header: PropTypes.string.isRequired,
  text: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  buttonUrl: PropTypes.string.isRequired,
}
