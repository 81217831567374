import PropTypes from 'prop-types'
import { useQuery } from 'urql'
import DateTime from '../data/DateTime'

const GET_HISTORY = `
  query GetHistory($offerId: ID!) {
    offerHistory(offerId: $offerId) {
      id
      createdAt
      state
    }
  }
`

export default function History({ id }) {
  const [{ fetching, data, error }] = useQuery({
    query: GET_HISTORY,
    variables: { offerId: id },
  })

  if (fetching) return <p>Loading</p>

  if (error) {
    console.error(error)
    return <p>{error.message}</p>
  }

  return (
    <>
      {data.offerHistory.map((item, index) => {
        return (
          <p key={`${index} ${item.id}`}>
            Offer created: <DateTime date={new Date(item.createdAt)} noTime />,
            status: {item.state}
          </p>
        )
      })}
    </>
  )
}

History.propTypes = {
  id: PropTypes.string.isRequired,
}
