import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import ModalBase from '../shared/Modal'
import Cart from './Cart'

const CartModal = forwardRef(({ trigger, onTriggerClick }, ref) => {
  return (
    <ModalBase
      ref={ref}
      size="large"
      trigger={trigger}
      onTriggerClick={onTriggerClick}
    >
      <Cart />
    </ModalBase>
  )
})

CartModal.displayName = 'CartModal'

CartModal.propTypes = {
  trigger: PropTypes.node,
  onTriggerClick: PropTypes.func,
}

export default CartModal
