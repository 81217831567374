import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Body } from '../_abstracts/Type'
import { InputLabel } from '../_atoms/Input'
import { StyledLabel as Label } from '../forms/Label'
import { down } from 'styled-breakpoints'

const Topper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px;
  border-top: 1px solid ${(props) => props.theme.colors.midGrey};
  background: ${(props) => props.theme.colors.lightGrey};
`

const TopperHeader = styled.p`
  ${Body}
  padding: 10px;
  margin-right: auto;

  ${down('small')} {
    padding: 10px 0;
  }
`

const TopperActions = styled.div`
  padding: 10px;
  display: flex;
  align-items: baseline;

  ${InputLabel}, ${Label} {
    font-size: inherit;
  }

  ${InputLabel} {
    margin-right: 15px;
  }

  ${down('small')} {
    flex-wrap: wrap;
    padding: 0;
  }
`

export default function Subnav({ header, children }) {
  return (
    <Topper>
      <TopperHeader>{header}</TopperHeader>

      <TopperActions>{children}</TopperActions>
    </Topper>
  )
}

Subnav.propTypes = {
  header: PropTypes.string,
  children: PropTypes.node.isRequired,
}
