import { Form } from 'formik'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import Button from '../_atoms/Button'
import {
  Footer as FieldsetFooter,
  Content as FieldsetContent,
  Wrapper as Fieldset,
  Fields,
} from '../forms/Fieldset'
import { linkStates } from '../_abstracts/Type'
import Back from '../shared/Back'

const Wrapper = styled.div`
  max-width: 1080px;
  margin-right: auto;
  margin-left: auto;
`

const Step = styled.div`
  ${({ current }) =>
    !current &&
    css`
      display: none;
    `}

  ${Fieldset} {
    margin: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  ${FieldsetContent} {
    border-radius: 0;
  }

  ${Fields} {
    margin: 0;
  }
`

const Footer = styled(FieldsetFooter)`
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  clip-path: inset(-1px -20px -20px -20px);
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  box-shadow: ${({ theme }) => theme.shadows.small};
`

const StyledBack = styled(Back)`
  margin-right: auto;
  font-size: 14px;
  background-color: transparent;
`

const Skip = styled.button.attrs({ type: 'button' })`
  ${linkStates};
  margin-right: 30px;
  font-size: 14px;
`

export default function StepForm({ children }) {
  const [currentStep, setCurrentStep] = useState(0)
  const stepCount = React.Children.count(children)

  async function onBack() {
    setCurrentStep(currentStep - 1)
  }

  async function onNext() {
    setCurrentStep(currentStep + 1)
  }

  return (
    <Wrapper>
      <Form>
        {React.Children.map(children, (fieldset, index) => (
          <Step current={index === currentStep} key={index}>
            {fieldset}
          </Step>
        ))}
        <Footer>
          {currentStep > 0 && <StyledBack onClick={onBack} size="small" />}
          {(currentStep < stepCount - 1 && (
            <>
              <Skip onClick={onNext}>Skip</Skip>
              <Button onClick={onNext} variant="primaryDark">
                Continue
              </Button>
            </>
          )) || (
            <Button variant="primaryDark" submit>
              Submit
            </Button>
          )}
        </Footer>
      </Form>
    </Wrapper>
  )
}

StepForm.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]),
}
