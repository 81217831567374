import PropTypes from 'prop-types'
import styled from 'styled-components'
import Transition from '../_abstracts/Animation'

const placeholder = '\u00A0' // Non-breaking space

// Fade in translation text when loaded (assuming translation is loaded from a Craft global set)
const Text = styled.span`
  ${Transition({ property: 'opacity' })};
  opacity: ${({ $loading }) => ($loading ? 0 : 1)};
`

export default function Translation({ children }) {
  return <Text $loading={children === placeholder}>{children}</Text>
}

Translation.defaultProps = {
  children: placeholder,
}

Translation.propTypes = {
  children: PropTypes.node,
}
