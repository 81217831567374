import { Formik } from 'formik'
import PropTypes from 'prop-types'
import Select from '../inputs/Select'

export default function PaginationOptions({
  perPage,
  keywords,
  setFilter,
  sort,
}) {
  const sortOptions = [
    { value: 'LISTING_TIME_DESC', label: 'Newest - Oldest' },
    { value: 'LISTING_TIME_ASC', label: 'Oldest - Newest' },
    { value: 'LISTING_TITLE_ASC', label: 'Alphabetical A - Z' },
    { value: 'LISTING_TITLE_DESC', label: 'Alphabetical Z - A' },
    { value: 'ASKING_PRICE_DESC', label: 'Highest Price - Lowest Price' },
    { value: 'ASKING_PRICE_ASC', label: 'Lowest Price - Highest Price' },
  ]

  if (keywords) sortOptions.unshift({ value: '', label: 'Relevancy' })

  return (
    <Formik
      initialValues={{
        perPage,
        sort,
      }}
    >
      <>
        <Select
          options={[
            { value: 12, label: '12' },
            { value: 30, label: '30' },
          ]}
          name="perPage"
          label="Per page"
          hideLabel
          defaultValue={perPage}
          onChange={(ev) => {
            setFilter('perPage', parseInt(ev.target.value, 10))
          }}
        />

        <Select
          options={sortOptions}
          name="sort"
          label="Sort by"
          hideLabel
          onChange={(ev) => {
            setFilter('sort', ev.target.value)
          }}
        />
      </>
    </Formik>
  )
}

PaginationOptions.propTypes = {
  perPage: PropTypes.number.isRequired,
  keywords: PropTypes.string,
  setFilter: PropTypes.func.isRequired,
  sort: PropTypes.string,
}
