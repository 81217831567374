import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Media, MediaImage } from '../_base/Media'
import { HeaderSansXS, SmallLetters, BodyS } from '../_abstracts/Type'
import { down } from 'styled-breakpoints'
import DateTime from '../data/DateTime'
import Money from '../data/Money'
import Link from '../shared/Link'
import Warning from '../shared/Warning'
import { BoxIcon, MailIcon } from '../_base/Icon'
import Meta from '../selling/Meta'
import BlockList from '../shared/BlockList'
import Popup from '../shared/Popup'
import { useUserContext } from '../auth/UserContext'
import BroughtToShippingButton from '../checkout/BroughtToShippingButton'
import SellingTrackingForm from './TrackingForm'

const Wrapping = styled.div`
  padding: 20px;

  & + & {
    border-top: 1px solid ${(props) => props.theme.colors.midGrey};
  }

  ${Media} {
    border: 1px solid ${(props) => props.theme.colors.midGrey};
    padding-bottom: 100%;
  }
`

const Inner = styled.div`
  display: flex;
  border-bottom: 1px solid ${(props) => props.theme.colors.blueTint};
  margin-bottom: 5px;

  ${down('medium')} {
    flex-wrap: wrap;
  }
`

const List = styled.div`
  margin-left: -5px;
  margin-right: -5px;
`

const Image = styled.div`
  min-width: 200px;
  width: 200px;
  padding-bottom: 20px;

  ${down('medium')} {
    order: 1;
    max-width: 200px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 15px;
  }
`

const Content = styled.div`
  flex-grow: 1;

  ${down('medium')} {
    order: 0;
  }
`

const Top = styled.div`
  display: flex;
  column-gap: 20px;
  justify-content: space-between;
  padding-bottom: 20px;

  ${down('medium')} {
    flex-wrap: wrap;
  }
`

const Left = styled.div`
  padding-left: 20px;

  ${down('medium')} {
    width: 100%;
    padding-left: 0;
  }
`

const Right = styled.div`
  text-align: right;
  flex-grow: 1;
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: flex-end;

  ${down('medium')} {
    width: 100%;
    padding-top: 0;
    margin-top: 20px;
    align-items: flex-start;
    text-align: left;
  }
`

const Header = styled(Link)`
  ${HeaderSansXS}
  font-weight: 700;
  max-width: 379px;
  margin-bottom: 10px;
`

const Copy = styled.p`
  ${BodyS}
`

const AltCopy = styled.p`
  ${SmallLetters}
`

const CopyLabel = styled.span`
  ${SmallLetters}
  display: inline-block;
  padding-right: 10px;
  color: ${(props) => props.theme.colors.accent};
`

const Buyer = styled(Link)`
  ${SmallLetters}
  cursor: pointer;
  color: ${(props) => props.theme.colors.accent};

  &:hover {
    text-decoration: underline;
  }
`

const Update = styled.button.attrs({ type: 'button' })`
  ${SmallLetters}
  display: inline-flex;
  align-items: center;
  padding: 10px;
  line-height: 1;
  border-radius: ${(props) => props.theme.radii};
  border: 1px solid ${(props) => props.theme.colors.midGrey};
`

const Bar = styled.div`
  display: inline-block;
  border: 1px solid ${(props) => props.theme.colors.midGrey};
  width: 46px;
  min-width: 46px;
  margin-left: 5px;
  height: 6px;
  border-radius: 5px;
  position: relative;
`

const Progress = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 5px;
  background-color: ${(props) => props.theme.colors.teal};
  height: 100%;
`

const IconList = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 10px;
  justify-content: flex-end;

  > * {
    display: inline-block;
  }

  > *:not(:last-child) {
    position: relative;
    padding-right: 15px;
    margin-right: 15px;

    &::before {
      position: absolute;
      top: 50%;
      right: 0;
      height: 10px;
      transform: translateY(-50%);
      width: 1px;
      background-color: ${(props) => props.theme.colors.midGrey};
      content: '';
    }
  }
`

export default function Order({ order, lineItem }) {
  const { user } = useUserContext()
  const { listing } = lineItem
  const { item } = listing
  const image = item.images?.[0]
  const buyer = order.account
  const { seller } = order
  const paymentPercent = Math.min(
    1,
    order.paymentBalance.amountCents / order.total.amountCents
  )

  return (
    <Wrapping>
      <Inner>
        {image && (
          <Image>
            <Media>
              <MediaImage src={image.url} alt={item.title} />
            </Media>
          </Image>
        )}

        <Content>
          <Top>
            <Left>
              <Header to={`/shop/${item.slug}/${item.id}`}>{item.title}</Header>

              <Copy>
                <CopyLabel>Listed Price</CopyLabel>
                <Money
                  amount={listing.askingPrice.amount}
                  currency={listing.askingPrice.currency.iso}
                />
              </Copy>

              <Copy>
                <CopyLabel>Sold For</CopyLabel>
                <Money
                  amount={lineItem.amount.amount}
                  currency={lineItem.amount.currency.iso}
                />
              </Copy>

              <Copy>
                <CopyLabel>Status</CopyLabel>
                {order.shipment.status}
              </Copy>

              <AltCopy>
                Sold To{' '}
                <Buyer to={`/profile/${buyer.username}/listings`}>
                  {buyer.username}
                </Buyer>
              </AltCopy>

              {user?.isAdmin && (
                <AltCopy>
                  Seller{' '}
                  <Buyer to={`/profile/${seller.username}/listings`}>
                    {seller.username}
                  </Buyer>
                </AltCopy>
              )}
            </Left>

            <Right>
              {order.canSetTracking && (
                <SellingTrackingForm
                  shipmentId={order.shipment.id}
                  values={{
                    trackingNumber: order.shipment.trackingNumber ?? '',
                    carrierName: order.shipment.carrierName ?? '',
                  }}
                />
              )}

              <Popup
                trigger={
                  <Update>
                    View Payment Status
                    <Bar>
                      <Progress style={{ width: `${paymentPercent * 100}%` }} />
                    </Bar>
                  </Update>
                }
              >
                {order.paymentState && (
                  <div>{translatePaymentState(order.paymentState)}</div>
                )}
                <div>
                  <strong>
                    <Money
                      amount={order.paymentBalance.amount}
                      currency={order.paymentBalance.currency.iso}
                    />
                  </strong>{' '}
                  Paid Of{' '}
                  <strong>
                    <Money
                      amount={order.total.amount}
                      currency={order.total.currency.iso}
                    />
                  </strong>
                </div>
              </Popup>

              {order.paymentState === 'PARTIALLY_PAID' && (
                <Warning text="Order only partially paid" color="red" />
              )}

              {order.refundedAmount.amountCents > 0 && (
                <Warning
                  text="This order has had refunds applied"
                  color="red"
                />
              )}

              {user.isAdmin && user.id !== buyer.id && (
                <>
                  <Warning text="Placed directly by user" color="gold" />
                  <BroughtToShippingButton lineItem={lineItem} />
                </>
              )}

              {listing.type === 'HOME' && (
                <Warning text="Consign From Home" color="red" />
              )}

              <IconList>
                <Meta
                  end
                  list={[
                    {
                      to: `/orders/${order.id}?view=seller`,
                      icon: <BoxIcon />,
                      text: 'Order Details',
                    },
                    {
                      to: `/messages/compose?accountId=${buyer.id}&itemId=${listing.item.id}`,
                      icon: <MailIcon />,
                      text: 'Message Buyer',
                    },
                  ]}
                />
              </IconList>
            </Right>
          </Top>
        </Content>
      </Inner>

      <List>
        <BlockList
          grow
          header="Listing Stats"
          list={[
            {
              title: 'Listing Date',
              value: <DateTime date={listing.createdAt} noTime />,
            },
            {
              title: 'Views',
              value: listing.viewCount,
            },
            {
              title: 'Watchers',
              value: item.wishlistCount,
            },
            {
              title: 'Offers',
              value: item.offerCount,
            },
            {
              title: 'Estimated Value',
              value: '-',
            },
            {
              title: 'Sold Date',
              value: (
                <DateTime
                  date={order.fullyPaidAt ?? order.completedAt}
                  noTime
                />
              ),
            },
          ]}
        />
      </List>
    </Wrapping>
  )
}

Order.propTypes = {
  lineItem: PropTypes.shape({
    id: PropTypes.string.isRequired,
    listing: PropTypes.shape({
      createdAt: PropTypes.instanceOf(Date).isRequired,
      viewCount: PropTypes.number.isRequired,
      type: PropTypes.string,
      item: PropTypes.shape({
        id: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
        images: PropTypes.arrayOf(
          PropTypes.shape({ url: PropTypes.string.isRequired })
        ),
        title: PropTypes.string.isRequired,
        offerCount: PropTypes.number.isRequired,
        wishlistCount: PropTypes.number.isRequired,
      }).isRequired,
      askingPrice: PropTypes.shape({
        amount: PropTypes.string.isRequired,
        currency: PropTypes.shape({
          iso: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired,
    amount: PropTypes.shape({
      amount: PropTypes.string.isRequired,
      currency: PropTypes.shape({
        iso: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  order: PropTypes.shape({
    id: PropTypes.string.isRequired,
    createdAt: PropTypes.instanceOf(Date).isRequired,
    completedAt: PropTypes.instanceOf(Date).isRequired,
    fullyPaidAt: PropTypes.instanceOf(Date),
    canSetTracking: PropTypes.bool.isRequired,
    shipment: PropTypes.shape({
      id: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      trackingNumber: PropTypes.string,
      trackingUrl: PropTypes.string,
      carrierName: PropTypes.string,
    }).isRequired,
    seller: PropTypes.shape({
      username: PropTypes.string,
    }),
    account: PropTypes.shape({
      id: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired,
    }).isRequired,
    total: PropTypes.shape({
      amount: PropTypes.string.isRequired,
      amountCents: PropTypes.number.isRequired,
      currency: PropTypes.shape({
        iso: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    paymentBalance: PropTypes.shape({
      amount: PropTypes.string.isRequired,
      amountCents: PropTypes.number.isRequired,
      currency: PropTypes.shape({
        iso: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    refundedAmount: PropTypes.shape({
      amount: PropTypes.string.isRequired,
      amountCents: PropTypes.number.isRequired,
      currency: PropTypes.shape({
        iso: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    paymentState: PropTypes.string,
  }).isRequired,
}

function translatePaymentState(state) {
  switch (state) {
    case 'UNPAID':
      return 'Unpaid'
    case 'PARTIALLY_PAID':
      return 'Partially Paid'
    case 'BUYER_PAID_PAYOUT_PENDING':
      return 'Buyer Paid, Seller Payout Pending'
    case 'BUYER_PAID':
      return 'Buyer Paid'
    case 'SELLER_PAID_OUT':
      return 'Seller Paid Out'
  }

  return null
}
