import styled from 'styled-components'

const Badge = styled.span`
  height: 20px;
  width: 20px;
  line-height: 1.5;
  border-radius: 50%;
  border: 1px solid ${(props) => props.theme.colors.accent};
  font-size: 12px;
  text-align: center;
  background-color: ${(props) => props.theme.colors.white};
  background-color: ${(props) => props.theme.colors.accent};
  color: ${(props) => props.theme.colors.white};
`

export default Badge
