const GET_GlOBAL_SET = `
  query GetInterests {
    globalSet(handle: "interests") {
      id
      name
      ... on interests_GlobalSet {
        interestsCategories {
          ... on interestsCategories_item_BlockType {
            id
            heading
            image {
              url
            }
            children {
              ... on interestsCategories_subItem_BlockType {
                id
                heading
                image {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`

export default GET_GlOBAL_SET
