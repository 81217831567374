import { useEffect } from 'react'
import { useUserContext } from '../auth/UserContext'

export default function ZendeskConfig() {
  const { user } = useUserContext()

  useEffect(() => {
    const script = document.createElement('script')
    let scriptDidLoad = false
    let userIdentified = false

    if (!user?.isAdmin) {
      script.src = '//fw-cdn.com/11430042/4099772.js'
      script.setAttribute('chat', true)
      script.onload = function () {
        if (user && window.fcWidget && !userIdentified) {
          window.fcWidget.user.setProperties({
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
          })
          userIdentified = true
        }
      }

      document.head.appendChild(script)
      scriptDidLoad = true
    }

    return () => {
      if (scriptDidLoad) document.head.removeChild(script)
    }
  }, [user])

  return null
}
