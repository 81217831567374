import { Formik, Form } from 'formik'
import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import toast from 'react-hot-toast'
import { up } from 'styled-breakpoints'
import styled from 'styled-components'
import { useQuery, useMutation } from 'urql'
import * as Yup from 'yup'
import Field from '../forms/Field'
import { Fields } from '../forms/Fieldset'
import Hr from '../shared/Hr'
import Warning from '../shared/Warning'
import Button from '../_atoms/Button'
import Input from '../_atoms/Input'
import Spacer from '../_helpers/Spacer'

const contactArg = PropTypes.shape({
  id: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
})

const setSelectedAccountArg = {
  setSelectedAccount: PropTypes.func,
}

const arg = {
  orders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      contact: contactArg,
    })
  ),
  ...setSelectedAccountArg,
}

const Section = styled.section``

const SelectableAccountWrapper = styled.div`
  padding: 16px;
  margin-top: 10px;
  font-size: 15px;
  border: 1px solid
    ${({ theme, selected }) =>
      selected ? theme.colors.darkBlue : theme.colors.midGrey};
  border-radius: ${({ theme }) => theme.radii};

  &:first-child {
    margin-top: 0;
  }

  p {
    margin-bottom: 10px;
    margin-top: 10px;

    &:first-child {
      margin-top: -0.3em;
    }

    &:last-child {
      margin-bottom: -0.3em;
    }
  }
`

const Message = styled.p`
  margin-top: 1em;

  &:first-child {
    margin-top: -0.5em;
  }
`

class SelectedAccountController {
  constructor(orders) {
    this.orders = orders
    this.contacts = this.orders.map((order) => order?.contact?.id)
    this.contacts = this.contacts.filter(
      (v, k) => this.contacts.indexOf(v) === k
    )
  }

  multiple() {
    return this.contacts.length > 1
  }

  empty() {
    return this.contacts.some((contact) => !contact)
  }

  contact() {
    return this.orders[0].contact
  }
}

function SelectedAccount({ multiple, empty, contact }) {
  if (multiple) {
    return (
      <Section>
        <Message>
          There is a mixture of accounts with this order. You need to park one
          first to continue.
        </Message>
      </Section>
    )
  }

  if (empty) {
    return (
      <Section>
        <Message>No account selected</Message>
      </Section>
    )
  }

  return <SelectableAccount account={contact} selected />
}

SelectedAccount.propTypes = {
  multiple: PropTypes.bool,
  empty: PropTypes.bool,
  contact: contactArg,
}

const SEARCH_ACCOUNT = `
query SearchAccount ($filter: AccountsFilter) {
  accounts(first: 20, filter: $filter) {
    edges {
      node {
        id
        firstName
        lastName
        email
        username
        phone
      }
    }
  }
}
`

function SelectableAccount({
  account,
  setSelectedAccount,
  stopSearching,
  selected,
}) {
  if (!account) {
    return null
  }

  function onSelect(event, account) {
    event.preventDefault()
    event.stopPropagation()

    setSelectedAccount(account.id)
    stopSearching()

    return false
  }

  return (
    <SelectableAccountWrapper key={account.id} selected={selected}>
      <p>
        Name: {account.firstName} {account.lastName}
        <br />
        {account.email && (
          <>
            Email: {account.email}
            <br />
          </>
        )}
        {account.phone && (
          <>
            Phone: {account.phone}
            <br />
          </>
        )}
        {account.username && (
          <>
            Username: {account.username}
            <br />
          </>
        )}
      </p>
      {setSelectedAccount && (
        <Button variant="small" onClick={(event) => onSelect(event, account)}>
          Select
        </Button>
      )}
    </SelectableAccountWrapper>
  )
}

SelectableAccount.propTypes = {
  account: contactArg,
  ...setSelectedAccountArg,
  stopSearching: PropTypes.func,
  selected: PropTypes.bool,
}

const SelectableAccountList = styled.div``

const SearchWrapper = styled.div`
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
`

function AccountSearch({ setSelectedAccount }) {
  const [search, setSearch] = useState('')
  const [accounts, setAccounts] = useState([])
  const [{ data, fetching, error }] = useQuery({
    query: SEARCH_ACCOUNT,
    variables: {
      filter: {
        usernamePrefix: search.trim(),
      },
    },
    pause: search.trim() === '',
  })

  useEffect(() => {
    if (data?.accounts?.edges?.length) {
      setAccounts(data.accounts.edges)
    } else {
      setAccounts([])
    }
  }, [data?.accounts?.edges])

  function stopSearching() {
    setAccounts([])
    setSearch('')
  }

  return (
    <>
      <SearchWrapper>
        <Input
          type="search"
          onChange={(event) => setSearch(event.target.value.trim())}
          value={search}
          placeholder="Search accounts"
          label="Search for an account"
          hideLabel
        />
      </SearchWrapper>

      {fetching && <Message>Loading…</Message>}
      {error && <Warning color="red" text={error} />}

      {accounts.length > 0 && (
        <SelectableAccountList>
          {accounts.map((account) => (
            <SelectableAccount
              key={account.id}
              account={account.node}
              stopSearching={stopSearching}
              setSelectedAccount={setSelectedAccount}
            />
          ))}
        </SelectableAccountList>
      )}

      {search && !fetching && !accounts.length && <Message>No results</Message>}
      {search && (
        <>
          <Hr />
          <AccountCreate setSelectedAccount={setSelectedAccount} />
        </>
      )}
    </>
  )
}

AccountSearch.propTypes = {
  ...setSelectedAccountArg,
}

const CREATE_ACCOUNT = `
mutation CreateAccount($account: AccountCreateManualInput!) {
  accountCreateManual(account: $account) {
    success
    account {
      id
    }
  }
}
`

const accountCreateSchema = Yup.object({
  firstName: Yup.string().trim().required('Required'),
  lastName: Yup.string().trim().required('Required'),
  username: Yup.string()
    .min(3, 'Min 3 characters')
    .max(20, 'Max 20 characters')
    .matches(/^[a-zA-Z0-9]+$/, 'Can only include letters and numbers')
    .trim()
    .nullable()
    .transform((value) => value || null),
  email: Yup.string()
    .email('Invalid email')
    .trim()
    .nullable()
    .transform((value) => value || null),
  phone: Yup.string()
    .trim()
    .nullable()
    .transform((value) => value || null),
})

const AccountCreateWrapper = styled.div`
  button {
    ${up('large')} {
      margin-top: 23px;
    }
  }
`

function AccountCreate({ setSelectedAccount }) {
  const [, createContact] = useMutation(CREATE_ACCOUNT)

  async function handleSubmit(account, { resetForm }) {
    toast.loading('Creating account…', { id: 'account' })

    const { data, error } = await createContact({ account })

    if (error) {
      toast.error(error, { id: 'account' })
      console.error(error)
      return false
    }

    if (!data?.accountCreateManual.success) {
      toast.error(
        'Could not create the account - check that the email address is not taken',
        { id: 'account' }
      )
      return false
    }

    toast.success('Account created', { id: 'account' })
    const accountId = data?.accountCreateManual?.account?.id
    if (accountId) {
      setSelectedAccount(accountId)
      resetForm()
    } else {
      toast.error('Did not receive ID from account creation', { id: 'account' })
    }
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      validationSchema={accountCreateSchema}
      initialValues={{
        firstName: '',
        lastName: '',
        username: '',
        email: '',
        phone: '',
      }}
    >
      <Form>
        <AccountCreateWrapper>
          <Message>
            <strong>
              Can&lsquo;t find the account above? Create them using the form
              below. If you include an email address and a username, it will
              also create an Exchange account.
            </strong>
          </Message>
          <Spacer size="20px" />
          <Fields>
            <Field width="half">
              <Input label="First Name" name="firstName" required />
            </Field>
            <Field width="half">
              <Input label="Last Name" name="lastName" required />
            </Field>
            <Field width="half">
              <Input label="Username" name="username" />
            </Field>
            <Field width="half">
              <Input type="email" label="Email" name="email" />
            </Field>
            <Field width="half">
              <Input type="tel" label="Phone" name="phone" />
            </Field>
            <Field width="half">
              <Button submit fullWidth>
                Create
              </Button>
            </Field>
          </Fields>
        </AccountCreateWrapper>
      </Form>
    </Formik>
  )
}

AccountCreate.propTypes = {
  setSelectedAccount: PropTypes.func,
}

const Wrapper = styled.div`
  padding: 30px 0px;
`

export default function AccountForm({ orders, setSelectedAccount }) {
  const controller = new SelectedAccountController(orders)
  const anyComplete = orders.filter((o) => !!o.completedAt).length > 0
  return (
    <Wrapper>
      <SelectedAccount
        multiple={controller.multiple()}
        empty={controller.empty()}
        contact={controller.contact()}
      />
      {!anyComplete && (
        <>
          <Spacer size="10px" />
          <AccountSearch setSelectedAccount={setSelectedAccount} />
        </>
      )}
    </Wrapper>
  )
}

AccountForm.propTypes = arg
