import { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { useMutation } from 'urql'
import { Formik } from 'formik'
import Input from '../_atoms/Input'
import Button from '../_atoms/Button'
import Select from '../inputs/Select'

const CREATE_TOKEN = `
  mutation CreateStripeTerminalConnectionToken {
    token: paymentStripeTerminalConnectionTokenCreate {
      secret
    }
  }
`

const CREATE_PAYMENT = `
  mutation CreateStripeTerminalPayment($orderId: ID!, $amountCents: Int) {
    orderCreateStripePayment(id: $orderId, terminal: true, amountCents: $amountCents) {
      success
      payment { id clientSecret }
    }
  }
`

export default function StripeTerminalPayment({
  orderId,
  remainingAmountCents,
  taxCents,
  onSuccess,
}) {
  const [terminal, setTerminal] = useState(null)
  const [discoverResult, setDiscoverResult] = useState(null)
  const [selectedReader, setSelectedReader] = useState(null)
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(null)
  const [completed, setCompleted] = useState(false)

  const [, createConnectionToken] = useMutation(CREATE_TOKEN)
  const [, createPayment] = useMutation(CREATE_PAYMENT)

  const inputRef = useRef()
  const [amountCents, setAmountCents] = useState(remainingAmountCents)
  const [remainingAfterCents, setRemainingAfterCents] = useState(0)

  useEffect(() => {
    setAmountCents(remainingAmountCents)
  }, [remainingAmountCents])

  useEffect(() => {
    async function setupTerminal() {
      const { loadStripeTerminal } = await import('@stripe/terminal-js')
      const StripeTerminal = await loadStripeTerminal()

      const terminal = StripeTerminal.create({
        onFetchConnectionToken: async () => {
          const { error, data } = await createConnectionToken()

          if (error) {
            alert(error.message)
            throw error
          }

          return data.token.secret
        },

        onUnexpectedReaderDisconnect: () => {
          setError('Card reader disconnected unexpectedly')
        },
      })

      setTerminal(terminal)

      const discoverResult = await terminal.discoverReaders({
        simulated: process.env.RAZZLE_STRIPE_TERMINAL_SIMULATED === 'true',
      })

      setDiscoverResult(discoverResult)

      if (discoverResult.discoveredReaders?.length > 0)
        setSelectedReader(discoverResult.discoveredReaders[0].id)
    }

    setupTerminal()
  }, [createConnectionToken])

  async function takePayment() {
    setProcessing(true)
    setError(null)

    const reader = discoverResult.discoveredReaders.find(
      (r) => r.id === selectedReader
    )
    const connectResult = await terminal.connectReader(reader)

    if (connectResult.error) {
      setError(`Failed to connect to reader: ${connectResult.error}`)
      setProcessing(false)
      return
    }

    const payment = await createPayment({ orderId, amountCents })

    if (!payment.data?.orderCreateStripePayment?.success) {
      setError(`Unable to create payment: ${payment.error.message}`)
      setProcessing(false)
      return
    }

    const clientSecret =
      payment.data.orderCreateStripePayment.payment.clientSecret

    const collectResult = await terminal.collectPaymentMethod(clientSecret)

    if (collectResult.error) {
      setError(`Failed to collect payment method: ${collectResult.error}`)
      setProcessing(false)
      return
    }

    const processResult = await terminal.processPayment(
      collectResult.paymentIntent
    )

    if (processResult.error) {
      setError(`Failed to process payment: ${processResult.error}`)
      setProcessing(false)
      return
    } else {
      setError(null)

      const result = await onSuccess(
        payment.data.orderCreateStripePayment.payment.id
      )

      if (result?.endProcessing) {
        // I don't love this, but we force a refresh so that we can easily
        // reconnect to the card reader.
        //
        // TODO: In the future we can probably use disconnectReader or, even
        // better, prevent changing the reader and keep the same connection open
        window.location.reload()
        return
      }

      setCompleted(true)
    }
  }

  if (completed) return <p>Completed</p>

  if (!terminal) return <p>Loading terminal...</p>

  if (!discoverResult) return <p>Discovering readers...</p>

  if (discoverResult.error) return <p>Error: {discoverResult.error}</p>

  if (discoverResult.discoveredReaders.length === 0)
    return <p>No readers found</p>

  return (
    <>
      <Select
        label="Card Readers"
        options={discoverResult.discoveredReaders.map((reader) => ({
          label: reader.label,
          value: reader.id,
        }))}
        onChange={(event) => setSelectedReader(event.target.value)}
        disabled={processing}
      />

      <Formik
        initialValues={{
          amount: amountCents / 100,
        }}
      >
        <>
          <Input
            ref={inputRef}
            type="number"
            name="amount"
            min={0}
            max={remainingAmountCents / 100}
            label="Manual price override"
            prefix="$"
            /* TODO: This will need a slight change for some currencies */
            step="0.01"
            value={amountCents / 100}
            onChange={(event) => {
              const proposedAmountCents = parseFloat(event.target.value) * 100
              const remainingAfterCents =
                remainingAmountCents - proposedAmountCents
              setRemainingAfterCents(remainingAfterCents)
              setAmountCents(proposedAmountCents)
            }}
          />
          {remainingAfterCents > 0 && remainingAfterCents < taxCents ? (
            <p>Cannot leave less than the tax amount in an order</p>
          ) : (
            <Button onClick={takePayment} disabled={processing}>
              {processing ? 'Processing...' : 'Take payment'}
            </Button>
          )}
        </>
      </Formik>

      {error && <p>{error}</p>}
    </>
  )
}

StripeTerminalPayment.propTypes = {
  orderId: PropTypes.string,
  remainingAmountCents: PropTypes.number,
  taxCents: PropTypes.number,
  onSuccess: PropTypes.func.isRequired,
}
