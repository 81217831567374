import styled from 'styled-components'
import PropTypes from 'prop-types'
import { down } from 'styled-breakpoints'

import { HeaderS } from '../_abstracts/Type'

import Tooltip, { Wrapper as TooltipWrapper } from '../shared/Tooltip'

const Wrapper = styled.div`
  & + & {
    border-top: 1px solid ${(props) => props.theme.colors.blueTint};
  }
`

const Header = styled.div`
  ${HeaderS}
  margin-bottom: 15px;

  ${TooltipWrapper} {
    position: relative;
    display: inline-block;
  }
`

const Inner = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding: 35px 13px;
  max-width: 970px;
  margin-right: auto;
  margin-left: auto;
`

const Right = styled.div`
  width: 60%;
  padding: 13px;

  > * + * {
    margin-top: 20px;
  }

  ${down('medium')} {
    width: 100%;
  }
`

export default function Split({ header, note, children }) {
  return (
    <Wrapper>
      <Inner>
        <Right>
          <Header>
            {header} {note && <Tooltip size="small">{note}</Tooltip>}
          </Header>
          {children}
        </Right>
      </Inner>
    </Wrapper>
  )
}

Split.propTypes = {
  header: PropTypes.string.isRequired,
  note: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}
