import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { useQuery } from 'urql'
import Select from '../inputs/Select'

const STRIPE_CARD_SELECT = `
  query StripeCardSelect {
    cards: paymentSavedStripeCards {
      id
      brand
      last4
      expMonth
      expYear
    }
  }
`

export default function StripeCardSelect({
  selected,
  setAvailable,
  setSelectedCardId,
  setStripeCardCount,
}) {
  const [initialSelectionDone, setInitialSelectionDone] = useState(false)

  const [{ fetching, data, error }] = useQuery({
    query: STRIPE_CARD_SELECT,
  })

  useEffect(() => {
    setAvailable?.(data && data.cards.length > 0)
    setStripeCardCount?.(data ? data.cards.length : 0)

    if (!initialSelectionDone && data && data.cards.length > 0) {
      setSelectedCardId?.(data.cards[0].id)
      setInitialSelectionDone(true)
    }
  }, [
    initialSelectionDone,
    data,
    setAvailable,
    setSelectedCardId,
    setStripeCardCount,
  ])

  if (fetching) return <p>Loading saved cards...</p>

  if (error) {
    console.error(error)
    return <p>Unable to load saved cards</p>
  }

  if (!data || data.cards.length === 0) return null

  return (
    <Formik initialValues={{}}>
      <Select
        name="cards"
        label="Select saved card"
        hideLabel
        disabled={selected !== 'savedCard'}
        onChange={(event) => setSelectedCardId?.(event.currentTarget.value)}
        options={data.cards.map((card) => ({
          value: card.id,
          label: `${card.brand} **** ${card.last4} Exp ${card.expMonth}/${card.expYear}`,
        }))}
      />
    </Formik>
  )
}

StripeCardSelect.propTypes = {
  selected: PropTypes.string,
  setAvailable: PropTypes.func,
  setSelectedCardId: PropTypes.func,
  setStripeCardCount: PropTypes.func,
}
