import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { HeaderM, Body } from '../_abstracts/Type'
import Html from '../data/Html'

const Wrapper = styled.div`
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
`

const Title = styled.h3`
  ${HeaderM}
  margin-top: -0.2em;
  margin-bottom: 15px;
  font-weight: 600;

  &:last-child {
    margin-bottom: 0;
  }

  ${({ numbered }) =>
    numbered &&
    css`
      counter-increment: numbered-block-counter 1;

      &:before {
        content: counter(numbered-block-counter) '. ';
        color: ${(props) => props.theme.colors.accent};
      }
    `}
`

const List = styled.ol`
  ${Body}
  color: ${(props) => props.theme.colors.xDarkGrey};

  li {
    display: flex;
    align-items: baseline;
    margin-top: 0.85em;

    &:first-child {
      margin-top: 0;
    }

    svg {
      flex-shrink: 0;
      margin-top: 1px;
      margin-right: 0.5em;
    }
  }
`

export default function ProsAndCons({ children }) {
  return (
    <Wrapper>
      {children.map((section, index) => (
        <div key={index}>
          <Title>{section.heading}</Title>
          <List key={index}>
            {section.listTextSimple.map((item, index) => (
              <li key={index}>
                {(section.typeHandle === 'pros' && (
                  <svg
                    width="20"
                    height="14"
                    viewBox="0 0 20 14"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    focusable="false"
                  >
                    <path
                      d="M1.091 8.143l4.823 4.823M17.652 1.229L5.914 12.966"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="square"
                    ></path>
                  </svg>
                )) ||
                  (section.typeHandle === 'cons' && (
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      focusable="false"
                    >
                      <path
                        d="M1 .97l12 12m0-12l-12 12"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="square"
                      ></path>
                    </svg>
                  ))}
                <Html html={item.text} />
              </li>
            ))}
          </List>
        </div>
      ))}
    </Wrapper>
  )
}

ProsAndCons.propTypes = {
  children: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string.isRequired,
      listTextSimple: PropTypes.shape({
        text: PropTypes.string.isRequired,
      }).isRequired,
      typeHandle: PropTypes.string.isRequired,
    })
  ),
}
