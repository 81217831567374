import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { fluidRange } from '../_abstracts/Layout'
import { HeaderM, BodyS } from '../_abstracts/Type'

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  border: 1px solid ${(props) => props.theme.colors.midGrey};
  border-radius: ${(props) => props.theme.radii};
  margin-left: auto;
  margin-right: auto;
  margin-top: ${fluidRange('30px', '50px')};
  color: ${(props) => props.theme.colors.darkBlue};

  ${(props) =>
    (props.size === 'large' &&
      css`
        max-width: 1115px;
      `) ||
    (props.size === 'medium' &&
      css`
        max-width: 805px;
      `) ||
    (props.size === 'small' &&
      css`
        max-width: 600px;
      `) ||
    css`
      max-width: 100%;
    `}
`

const Header = styled.div`
  text-align: center;
  padding: 11px 25px;
  border-bottom: 1px solid ${(props) => props.theme.colors.midGrey};
  background: ${(props) => props.theme.colors.lightGrey};
`

const Heading = styled.h2`
  ${HeaderM}
  font-weight: 600;
`

const Text = styled.p`
  ${BodyS}
  max-width: 458px;
  margin-right: auto;
  margin-left: auto;

  * + & {
    margin-top: 10px;
  }
`

const Main = styled.div`
  background: ${(props) => props.theme.colors.white};
`

export default function FormWrapper({
  header,
  text,
  size = 'large',
  children,
}) {
  return (
    <Wrapper size={size}>
      {header && (
        <Header>
          {header && <Heading>{header}</Heading>}
          {text && <Text>{text}</Text>}
        </Header>
      )}

      <Main>{children}</Main>
    </Wrapper>
  )
}

FormWrapper.propTypes = {
  header: PropTypes.string,
  text: PropTypes.string,
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(['large', 'medium', 'small']),
}
