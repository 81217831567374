import Lost from '../404/404'
import Meta from '../_helpers/Meta'
import { useGlobals } from '../hooks'
import Html from '../data/Html'
import Loader from '../shared/Loader'
import { useHttpStatusContext } from '../shared/HttpStatusContext'

export default function NotFound() {
  const { notFound, fetching } = useGlobals('notFound')
  const httpStatus = useHttpStatusContext()

  if (fetching) return <Loader background="#fff" height="200px" />

  const args = {
    header: notFound.notFoundTitle,
    image: notFound.notFoundImage[0]?.url,
    children: notFound.notFoundText && <Html html={notFound.notFoundText} />,
  }

  httpStatus.code = 404

  return (
    <>
      <Meta title="404" />
      <Lost {...args} />
    </>
  )
}
