import { fromUnixTime } from 'date-fns'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useQuery } from 'urql'
import ScatterChart from '../data/ScatterChart'
import Money, { formatMoney } from '../data/Money'
import { HeaderSansL, Body } from '../_abstracts/Type'
import Loader from '../shared/Loader'

const GET_SALE_DATA_TPN = `
  query GetSaleDataUsingTpn($tpn: String!, $limit: Int) {
    itemHistory(tpn: $tpn, limit: $limit) {
      date
      amount {
        amount
        amountCents
        currency { iso }
      }
      case
      year
      instrumentDetails
    }
  }
`

const GET_SALE_DATA_ATTRIBUTES = `
  query GetSaleDataUsingAttributes($input: CatalogProductSearchAttributes!, $limit: Int) {
    itemHistoryAttributes(input: $input, limit: $limit) {
      sale {
        date
        amount {
          amount
          amountCents
          currency { iso }
        }
        case
        year
        instrumentDetails
      }

      product {
        id
        originalParts
        originalFinish
      }
    }
  }
`

const GET_APPRAISAL_DATA = `
  query GetAppraisalData($id: [QueryArgument]!) {
    entries(itemId: $id) {
      ... on appraisals_default_Entry {
        id
        appraisalValue
        itemId
        dateCreated
        appraisalCase
        appraisalOriginalParts
        appraisalOriginalFinish
        appraisalNotes
      }
    }
  }
`

export const Wrapper = styled.div`
  max-width: 1080px;
  margin-right: auto;
  margin-left: auto;
  border-radius: 6px;
  overflow: hidden;
  background: ${(props) => props.theme.colors.white};
`

const Copy = styled.div`
  ${Body}
  padding: 20px;
  text-align: center;
  background: ${(props) => props.theme.colors.lightGrey};
`

const Scatta = styled.div`
  padding: 15px;
`

const Footer = styled.p`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 20px;
  background: ${(props) => props.theme.colors.darkBlue};
  color: ${(props) => props.theme.colors.white};
`

const Label = styled.span`
  ${Body}
  margin-right: 15px;
`

export const Value = styled.span`
  ${HeaderSansL}
  margin-right: 15px;
`

const Message = styled.p`
  padding: 30px 20px;
  text-align: center;
`

const decimalPlaces = (value) => {
  if (Math.floor(value) === value) return 0
  return value.toString().split('.')[1].length || 0
}

const caseTextMap = {
  ORIGINAL: 'Original',
  AFTERMARKET: 'Aftermarket',
}

const splitLines = (input, cutOffChars = 25) => {
  let index = 0
  let length = 0
  let output = input.split(' ') // Split words

  output = output.reduce((lines, word) => {
    lines[index] = lines[index] || []
    lines[index].push(word)
    length += word.length

    // Start new line when current line over 25 characters
    if (length > cutOffChars) {
      index += 1
      length = 0
    }
    return lines
  }, [])

  return output.map((line) => line.join(' ')) // Join words
}

const chartOptions = {
  responsive: true,
  animation: false,
  plugins: {
    jitter: true,
    legend: {
      labels: {
        padding: 25,
        usePointStyle: true,
      },
    },
    title: {
      display: false,
    },
    tooltip: {
      backgroundColor: '#ffffff',
      bodyColor: '#06062D',
      borderColor: '#066464',
      bodyAlign: 'left',
      displayColors: false,
      borderWidth: 1,
      mode: 'nearest',
      position: 'nearest',
      callbacks: {
        label: (ctx) => {
          let label = []

          if (ctx.raw.data.amount) {
            // Sale
            label.push(
              'Sold for: ' +
                formatMoney(
                  Number(ctx.parsed.y),
                  ctx.raw.data.amount.currency.iso
                )
            )
            label.push(
              'Sold on: ' +
                fromUnixTime(ctx.parsed.x / 1000).toLocaleDateString()
            )
          } else {
            // Appraisal
            label.push('Valued at: ' + formatMoney(Number(ctx.parsed.y), 'USD'))
            label.push(
              'Valued on: ' +
                fromUnixTime(ctx.parsed.x / 1000).toLocaleDateString()
            )
          }
          if (ctx.raw.data.year) {
            label.push('Made in year: ' + ctx.raw.data.year)
          }
          if (ctx.raw.data.case && caseTextMap[ctx.raw.data.case]) {
            label.push('Case: ' + caseTextMap[ctx.raw.data.case])
          }
          if (ctx.raw.data.originalParts != null) {
            label.push(
              'All Original Parts: ' +
                (ctx.raw.data.originalParts ? 'Yes' : 'No')
            )
          }
          if (ctx.raw.data.originalFinish != null) {
            label.push(
              'Original Finish: ' + (ctx.raw.data.originalFinish ? 'Yes' : 'No')
            )
          }
          if (ctx.raw.data.instrumentDetails) {
            const lines = splitLines(
              'Details: ' + ctx.raw.data.instrumentDetails
            )
            label = label.concat(lines)
          }
          return label
        },
      },
    },
  },
  scales: {
    x: {
      grid: {
        lineWidth: 0,
      },
      type: 'time',
      bounds: 'ticks',
      time: {
        unit: 'year',
      },
    },
    y: {
      ticks: {
        callback: (val) => {
          let formatted = val / 1000
          if (decimalPlaces(formatted) > 2) formatted = formatted.toFixed(2)
          return formatted.toString().replace(/[.,]00$/, '') + 'K'
        },
      },
    },
  },
}

const ScatterContent = ({
  id,
  text,
  noDataContent,
  tpn,
  attributes,
  children,
  onPriceHistoryAvailable,
  madeInYear,
  showAverage,
}) => {
  let query
  let variables

  if (tpn) {
    query = GET_SALE_DATA_TPN
    variables = { tpn }
  } else {
    query = GET_SALE_DATA_ATTRIBUTES
    variables = {
      input: Object.fromEntries(
        // Filter out blank input values:
        Object.entries(attributes).filter(([_, value]) => {
          if (typeof value === 'boolean') return true

          return value
        })
      ),
    }
  }

  let [{ data, fetching, error }] = useQuery({
    query,
    variables: {
      limit: 100,
      ...variables,
    },
  })

  const [appraisals] = useQuery({
    query: GET_APPRAISAL_DATA,
    variables: { id },
  })

  fetching = fetching || appraisals.fetching
  error = error || appraisals.error

  let itemData = []

  if (data?.itemHistory) {
    itemData = data.itemHistory
  } else if (data?.itemHistoryAttributes) {
    itemData = data.itemHistoryAttributes.map(({ sale, product }) =>
      Object.assign(sale, {
        originalParts: product.originalParts,
        originalFinish: product.originalParts,
      })
    )
  }

  let chartData = { datasets: [] }
  let average

  // Filter out sales data with null amounts
  itemData = itemData.filter((sale) => sale.amount)

  if (itemData?.length) {
    chartData.datasets.push({
      label: 'Sales',
      data: itemData.map((sale) => ({
        y: sale.amount.amountCents / 100,
        x: new Date(sale.date),
        data: sale,
      })),
      borderColor: '#066464',
      backgroundColor: 'rgba(255, 255, 255)',
      hoverBackgroundColor: '#066464',
      pointRadius: 7,
      pointHoverRadius: 7,
    })

    average = itemData.reduce(
      (cumulative, sale) => cumulative + sale.amount.amountCents,
      0
    )
    average /= itemData.length
    average /= 100 // Convert from cents
  }

  if (appraisals?.data?.entries.length) {
    chartData.datasets.push({
      label: 'Appraisals',
      data: appraisals.data.entries.map((appraisal) => ({
        y: appraisal.appraisalValue,
        x: new Date(appraisal.dateCreated),
        data: Object.assign(appraisal, {
          year: madeInYear,
          case: appraisal.appraisalCase,
          originalParts:
            appraisal.appraisalOriginalParts === '1'
              ? true
              : appraisal.appraisalOriginalParts === '0'
              ? false
              : null,
          originalFinish:
            appraisal.appraisalOriginalFinish === '1'
              ? true
              : appraisal.appraisalOriginalFinish === '0'
              ? false
              : null,
          instrumentDetails: appraisal.appraisalNotes,
        }),
      })),
      borderColor: '#891919',
      backgroundColor: 'rgba(255, 255, 255)',
      hoverBackgroundColor: '#891919',
      pointRadius: 8,
      pointHoverRadius: 8,
      pointStyle: 'triangle',
    })
  }

  const hasData = chartData.datasets.length > 0

  let priceHistory
  if (hasData && onPriceHistoryAvailable) {
    // Get combined sale and appraisal amounts, sorted by date
    const amounts = [
      ...chartData.datasets.map((set) =>
        set.data.map((point) => ({
          amount: point.y,
          date: point.x,
        }))
      ),
    ]
      .flat()
      .sort((a, b) => a.date - b.date)
      .map((point) => point.amount)

    priceHistory = {
      latest: amounts[amounts.length - 1],
      min: Math.min(...amounts),
      max: Math.max(...amounts),
    }
  }

  useEffect(() => {
    if (
      priceHistory?.latest &&
      priceHistory?.min &&
      priceHistory?.max &&
      onPriceHistoryAvailable
    ) {
      onPriceHistoryAvailable({
        latest: priceHistory.latest,
        min: priceHistory.min,
        max: priceHistory.max,
      })
    }
  }, [
    priceHistory?.latest,
    priceHistory?.min,
    priceHistory?.max,
    onPriceHistoryAvailable,
  ])

  if (fetching)
    return children(
      <Wrapper>
        <Loader background="#fff" height="100px" />
      </Wrapper>
    )

  if (error) {
    console.error(error)
    return children(<Message>{error.message}</Message>)
  }

  if (!hasData && !noDataContent) return null

  // Pad Y axis with prices below the min chart value and above the max chart value
  // This can add context when charts have few data points (as Y axis may show no or little range)
  let priceVariation = 1000 // 1000 = Price padding shown below min chart value and above max chart value
  if (priceHistory?.min) {
    chartOptions.scales.y.suggestedMin = priceHistory.min - priceVariation
  } else if (chartOptions.scales.y.suggestedMin) {
    delete chartOptions.scales.y.suggestedMin
  }
  if (priceHistory?.max) {
    chartOptions.scales.y.suggestedMax = priceHistory.max + priceVariation
  } else if (chartOptions.scales.y.suggestedMax) {
    delete chartOptions.scales.y.suggestedMax
  }

  return children(
    <Wrapper>
      {hasData && text && <Copy>{text}</Copy>}
      <Scatta>
        {(hasData && (
          <ScatterChart data={chartData} options={chartOptions} />
        )) ||
          noDataContent}
      </Scatta>
      {showAverage && average && (
        <Footer>
          <Label>Average sold price</Label>{' '}
          <Value>
            <Money amount={average} currency="USD" includeCurrency />
          </Value>
        </Footer>
      )}
    </Wrapper>
  )
}

ScatterContent.propTypes = {
  text: PropTypes.node,
  noDataContent: PropTypes.node,
  tpn: PropTypes.string,
  attributes: PropTypes.object.isRequired,
  children: PropTypes.func.isRequired,
}

export default function Scatter(props) {
  const [showContent, setShowContent] = useState(false)
  const showAverage = props.showAverage ?? true

  // Wait until after client-side hydration to show, otherwise the date timezone passed to the itemHistory
  // query may mismatch on the server/client and cause the resulting HTML to be different
  useEffect(() => {
    setShowContent(true)
  }, [setShowContent])

  if (!showContent) {
    return null
  }

  return <ScatterContent {...props} showAverage={showAverage} />
}

Scatter.propTypes = {
  id: PropTypes.string.isRequired,
  text: PropTypes.node,
  tpn: PropTypes.string,
  attributes: PropTypes.object.isRequired,
  children: PropTypes.func.isRequired,
  onPriceHistoryAvailable: PropTypes.func,
  madeInYear: PropTypes.number.isRequired,
  showAverage: PropTypes.boolean,
}
