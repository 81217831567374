import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Body, HeaderSansL } from '../_abstracts/Type'

import Money from '../data/Money'

const Wrapper = styled.div`
  border-radius: ${(props) => props.theme.radii};
  border: 1px solid ${(props) => props.theme.colors.darkBlue};
`

const Top = styled.div`
  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) => props.theme.colors.darkBlue};
  text-align: center;
  padding: 15px;
`

const Tip = styled.div`
  ${Body}
  color: ${(props) => props.theme.colors.white};
`

const Mip = styled.div`
  ${HeaderSansL}
`

export default function Stats({ header, amount, currency }) {
  return (
    <Wrapper>
      <Top>
        <Tip>{header}</Tip>
        <Mip>
          <Money amount={amount} currency={currency} />
        </Mip>
      </Top>
    </Wrapper>
  )
}

Stats.propTypes = {
  header: PropTypes.string.isRequired,
  amount: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
}
