import styled from 'styled-components'
import PropTypes from 'prop-types'
import { BodyS, SmallLetters, HeaderSansXS } from '../_abstracts/Type'
import Button, { ButtonStyle } from '../_atoms/Button'
import { Media, MediaImage } from '../_base/Media'
import { up } from 'styled-breakpoints'
import Spacer from '../_helpers/Spacer'
import Money from '../data/Money'
import MakeOfferModal from '../offers/MakeOfferModal'
import ProductCartButton from '../shop/ProductCartButton'
import WishlistButton from '../shop/WishlistButton'
import SellerRoundel from './SellerRoundel'

const Wrapper = styled.div`
  position: relative;
  background: ${(props) => props.theme.colors.white};
  border-radius: ${(props) => props.theme.radii};
  transition: 0.15s;
  border: 1px solid ${(props) => props.theme.colors.midGrey};
  overflow: hidden;

  & + & {
    margin-top: 20px;
  }

  &:hover {
    z-index: 1;
    border-color: ${(props) => props.theme.colors.accent};
    box-shadow: ${(props) => props.theme.shadows.small};
  }
`

const WrapperInner = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const Thumb = styled.div`
  position: relative;
  width: 100%;

  ${up('medium')} {
    width: 50%;
    max-width: 200px;
  }

  ${Media} {
    padding-top: 100%;
  }
`

const Actions = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 10px 10px 0;
`

const Interact = styled.div``

const View = styled.div`
  width: 100%;
  align-self: flex-end;
  margin-bottom: 2px;
  transition: 0.15s;

  ${up('medium')} {
    ${Wrapper}:not(:hover) & {
      opacity: 0;
      visibility: hidden;
    }
  }

  ${ButtonStyle} {
    width: 100%;
    margin: 0;
  }
`

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 13px;

  ${up('medium')} {
    width: 50%;
    flex-grow: 1;
  }
`

const Header = styled.div`
  width: 100%;

  ${up('small')} {
    padding-right: 13px;
    width: 50%;
    border-right: 1px solid ${(props) => props.theme.colors.blueTint};
  }

  ${up('large')} {
    width: 33.33%;
  }
`

const Title = styled.a`
  ${BodyS}
  font-weight: 600;
  margin-bottom: 10px;
`

const Meta = styled.div`
  width: 100%;

  ${up('small')} {
    padding-left: 25px;
    width: 50%;
  }

  ${up('large')} {
    width: 33.33%;
  }
`

const SoldBy = styled.div``

const SoldByLabel = styled.p`
  ${SmallLetters}
  display: inline-block;
  margin-right: 10px;
  color: ${(props) => props.theme.colors.accent};

  &:last-child {
    margin-top: 10px;
  }
`

const Price = styled.div``

const PriceLabel = styled.p`
  ${SmallLetters}
  color: ${(props) => props.theme.colors.accent};
`

const Amount = styled.p`
  ${HeaderSansXS}
  font-weight: 700;
`

const ButtonList = styled.div`
  width: 100%;
  padding: 13px;

  ${up('medium')} {
    width: 33.33%;
  }

  ${ButtonStyle} {
    width: 100%;
    margin-top: 12px;
    margin-left: 0;
    margin-right: 0;

    &:first-child {
      margin-top: 0;
    }
  }
`

export default function HorizontalProductCard({ item, linkType }) {
  return (
    <Wrapper>
      <WrapperInner>
        <Thumb>
          {item.images[0] && (
            <Media>
              <MediaImage srcSet={item.images[0].url} alt={item.title} />
            </Media>
          )}

          <Actions>
            <Interact>
              <WishlistButton
                itemId={item.id}
                inViewerWishlist={item.inViewerWishlist}
              />
            </Interact>

            {linkType !== 'none' && (
              <View>
                <Button
                  to={`/${linkType === 'listing' ? 'shop' : 'item'}/${item.id}`}
                  variant="secondary"
                >
                  View
                </Button>
              </View>
            )}
          </Actions>
        </Thumb>

        <Content>
          <Header>
            <Title href={`/shop/${item.slug}/${item.id}`}>{item.title}</Title>

            {item.listing && (
              <Price>
                <PriceLabel>Price</PriceLabel>
                <Amount>
                  <Money
                    amount={item.listing.askingPrice.amount}
                    currency={item.listing.askingPrice.currency.iso}
                  />
                </Amount>
              </Price>
            )}
          </Header>

          <Meta>
            <SoldBy>
              <SoldByLabel>Listed by</SoldByLabel>
              <SellerRoundel user={item.account} />
              <Spacer size="10px" />
            </SoldBy>
          </Meta>

          <ButtonList>
            {item.listing &&
              (item.listing.isAvailable ? (
                <ProductCartButton
                  addButtonLabel="Add to cart"
                  listingId={item.listing.id}
                  inViewerCart={item.listing.inViewerCart}
                  removeButtonLabel="Remove from cart"
                />
              ) : (
                <Button disabled fullWidth>
                  Sold
                </Button>
              ))}

            {item.acceptingOffers && item.listing?.isAvailable && (
              <MakeOfferModal buttonLabel="Make an offer" item={item} />
            )}
          </ButtonList>
        </Content>
      </WrapperInner>
    </Wrapper>
  )
}

HorizontalProductCard.defaultProps = {
  linkType: 'listing',
}

HorizontalProductCard.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    images: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string.isRequired,
      })
    ).isRequired,
    acceptingOffers: PropTypes.bool,
    offerDeclineThreshold: PropTypes.number,
    inViewerWishlist: PropTypes.bool.isRequired,

    account: PropTypes.shape({
      id: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired,
    }).isRequired,

    listing: PropTypes.shape({
      id: PropTypes.string.isRequired,

      inViewerCart: PropTypes.bool.isRequired,
      isAvailable: PropTypes.bool.isRequired,

      askingPrice: PropTypes.shape({
        amount: PropTypes.string.isRequired,
        currency: PropTypes.shape({
          iso: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    }),
  }).isRequired,
  linkType: PropTypes.oneOf(['listing', 'item', 'none']),
}
