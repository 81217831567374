import { Formik } from 'formik'
import { useEffect } from 'react'
import toast from 'react-hot-toast'
import { useMutation } from 'urql'
import * as Yup from 'yup'
import Form from '../../_atoms/Form'
import Button from '../../_atoms/Button'
import Input from '../../_atoms/Input'
import Field from '../../forms/Field'
import { SimpleFieldset } from '../../forms/Fieldset'

const UPDATE_PASSWORD = `
  mutation UpdateUser($passwords: AccountChangePasswordInput!) {
    accountChangePassword(passwords: $passwords) {
      success
    }
  }
`

const Schema = Yup.object().shape({
  currentPassword: Yup.string().required('Required'),
  newPassword: Yup.string().min(10, 'Min 10 characters').required('Required'),
  confirmNewPassword: Yup.string()
    .min(10, 'Min 10 characters')
    .oneOf([Yup.ref('newPassword')], 'Passwords must match')
    .required('Required'),
})

export default function DetailsForm() {
  const [{ data, error, fetching }, updatePassword] =
    useMutation(UPDATE_PASSWORD)

  useEffect(() => {
    if (fetching) {
      toast('Saving…', { id: 'form' })
    } else if (data?.accountChangePassword.success) {
      toast.success('Changes saved', { id: 'form' })
    } else if (error || data?.accountChangePassword.success === false) {
      toast.error('Unable to save changes', { id: 'form' })
    }
  }, [data, error, fetching])

  async function handleSubmit({ currentPassword, newPassword }) {
    const { data } = await updatePassword({
      passwords: {
        currentPassword,
        newPassword,
      },
    })

    if (data?.accountChangePassword.success) {
      console.log('Successfully updated password')
    }
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      validationSchema={Schema}
      initialValues={{
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: '',
      }}
    >
      <Form $border justify="center">
        <SimpleFieldset legend="Change password">
          <Field>
            <Input
              autocomplete="current-password"
              label="Old password"
              name="currentPassword"
              type="password"
            />
          </Field>
          <Field>
            <Input
              autocomplete="new-password"
              label="New password"
              name="newPassword"
              type="password"
            />
          </Field>
          <Field>
            <Input
              autocomplete="new-password"
              label="Confirm new password"
              name="confirmNewPassword"
              type="password"
            />
          </Field>
        </SimpleFieldset>

        <Field justify="end">
          <Button disabled={fetching} submit variant="primaryDark">
            Save
          </Button>
        </Field>
      </Form>
    </Formik>
  )
}
