import { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

import { HeaderXL, AllCaps, Body } from '../_abstracts/Type'
import { Icon, CarterCIcon } from '../_base/Icon'
import { Media, MediaImage } from '../_base/Media'
import Button, { ButtonStyle } from '../_atoms/Button'
import Container from '../_helpers/Container'
import Share from './Share'

import styled, { css } from 'styled-components'

export const Header = styled.h2`
  ${HeaderXL};
  font-weight: ${({ weight }) => weight || 600};
  position: relative;
  color: ${(props) => props.theme.colors.white};
  line-height: 1;

  ${({ animate }) =>
    animate &&
    css`
      opacity: 0;
      transform: translateX(50%);
      transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
      transition-delay: ${(props) => (props.delay ? '0.5s' : 0)};
    `}
`

export const HeroMedia = styled(Media)``

export const SubHeader = styled.p`
  ${AllCaps}
  font-size: 14px;
  width: 80%;
  max-width: 402px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  border-bottom: 1px solid ${(props) => props.theme.colors.accent};
  position: relative;
  color: ${(props) => props.theme.colors.white};

  &:last-child {
    margin-bottom: 12px;
  }

  ${({ animate }) =>
    animate &&
    css`
      opacity: 0;
      transform: translateX(50%);
      transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
    `}
`
const Text = styled.p`
  ${Body};
  margin-top: 10px;
  color: ${(props) => props.theme.colors.white};

  &:first-child {
    margin-top: 0;
  }

  ${({ animate }) =>
    animate &&
    css`
      opacity: 0;
      transform: translateX(50%);
      transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
      transition-delay: ${(props) => (props.delay ? '0.5s' : 0)};
    `}
`
const Wrapper = styled.div`
  background: ${(props) => props.theme.colors.darkBlue};
  padding: ${(props) => (props.flex ? '12px' : '40px')};
  min-height: 250px;
  display: ${(props) => (props.flex ? 'flex' : 'block')};
  flex-wrap: wrap;
  align-items: stretch;

  ${({ animate }) =>
    animate &&
    css`
      overflow: hidden;
    `}
`
const WrapperInner = styled.div`
  border: 1px solid ${(props) => props.theme.colors.accent};
  border-radius: ${(props) => props.theme.radii};
  padding: 35px 0;
  text-align: center;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > ${Icon} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: calc(100% - 60px);
    max-height: 175px;
    opacity: 0.1;

    [data-shape='c'] {
      fill: ${({ theme }) => theme.colors.white};
    }

    [data-shape='dots'] {
      fill: ${({ theme }) => theme.colors.darkBlue};
    }

    [data-shape='shadow'] {
      display: none;
    }
  }

  ${Media} & {
    border: 0px;
  }

  ${SubHeader} + ${Header} {
    margin-top: 15px;
  }

  ${ButtonStyle} {
    border-color: white;
  }

  ${Text} + ${ButtonStyle},
  ${Header} + ${ButtonStyle} {
    margin-top: 20px;
  }
`
const ShareWrapper = styled.div`
  position: relative;
  background-color: ${(props) => props.theme.colors.white};
  padding: 24px 30px;
  margin-top: 20px;
  width: fit-content;
  text-align: center;
  margin-left: auto;
  margin-right: auto;

  ul {
    border: 1px solid ${(props) => props.theme.colors.grey};
    padding: 10px 15px;
    border-radius: 50px;
    margin-top: 15px;
    margin-left: auto;
    margin-right: auto;
  }

  ${(props) =>
    props.flex &&
    css`
      ${Wrapper} {
        display: block;
      }
    `}
`
const Buttons = styled.div`
  margin-top: 35px;

  > * {
    margin: 10px;
  }
`

const Content = styled.div`
  position: relative;
  margin-top: 15px;

  ${Header} + & ${ButtonStyle}:first-child {
    margin-top: 5px;
  }
`

// TODO: LINK POST
export default function Hero({ animate, children, hero, post }) {
  const first = useRef()
  const second = useRef()

  useEffect(() => {
    if (animate) {
      if (first.current) {
        first.current.style.transform = 'translateX(0)'
        first.current.style.opacity = '1'
      }
      if (second.current) {
        second.current.style.transform = 'translateX(0)'
        second.current.style.opacity = '1'
      }
    }
  }, [animate, first, second])

  return (
    <>
      {!hero.image ? (
        <Wrapper animate={animate} flex>
          <WrapperInner>
            <CarterCIcon />
            <Container>
              {hero.subheader ? (
                <SubHeader animate={animate} ref={first}>
                  {hero.subheader}
                </SubHeader>
              ) : null}
              {hero.header ? (
                <Header
                  animate={animate}
                  as={hero.headerElement || 'h2'}
                  delay={!!hero.subheader}
                  ref={second}
                  weight={hero.headerWeight}
                >
                  {hero.header}
                </Header>
              ) : null}
              {children && <Content>{children}</Content>}
            </Container>
          </WrapperInner>
        </Wrapper>
      ) : (
        <HeroMedia>
          <MediaImage srcSet={hero.image} alt={hero.alt || ''} />
          {/* flex={!hero.button ? true : !hero.share ? true : false} */}
          <Wrapper flex>
            <WrapperInner>
              {hero.icon ? <CarterCIcon /> : null}
              <Container>
                {hero.header ? (
                  <Header
                    animate={animate}
                    ref={first}
                    weight={hero.headerWeight}
                  >
                    {hero.header}
                  </Header>
                ) : null}
                {hero.text ? (
                  <Text animate={animate} delay ref={second}>
                    {hero.text}
                  </Text>
                ) : null}
                {children && <Content>{children}</Content>}
                {hero.button ? (
                  <Button variant="primaryLight" to={hero.button.link}>
                    {hero.button.text}
                  </Button>
                ) : null}
              </Container>
            </WrapperInner>
            {hero.share ? (
              <ShareWrapper flex>
                Share your listing:
                <Share url={post.url} img={post.img} text={post.text} />
                <Buttons>
                  {/* TODO: LINK */}
                  <Button variant="secondary" to="/">
                    View listed item
                  </Button>
                  <Button variant="primaryLight" to="/">
                    List another item
                  </Button>
                </Buttons>
              </ShareWrapper>
            ) : null}
          </Wrapper>
        </HeroMedia>
      )}
    </>
  )
}

Hero.defaultProps = {
  animate: true,
}

Hero.propTypes = {
  animate: PropTypes.bool,
  children: PropTypes.node,
  hero: PropTypes.shape({
    header: PropTypes.string,
    headerElement: PropTypes.string,
    headerWeight: PropTypes.number,
    subheader: PropTypes.node,
    subheaderElement: PropTypes.string,
    text: PropTypes.string,
    image: PropTypes.string,
    alt: PropTypes.string,
    button: PropTypes.shape({
      text: PropTypes.string,
      link: PropTypes.string,
    }),
    share: PropTypes.bool,
    icon: PropTypes.bool,
  }),
  post: PropTypes.shape({
    url: PropTypes.string,
    img: PropTypes.string,
    text: PropTypes.string,
  }),
}
