import { Helmet } from 'react-helmet-async'
import Button from '../_atoms/Button'
import Subheader from '../shared/Subheader'
import PortfolioGrid from './PortfolioGrid'

export default function ProfilePortfolioIndex() {
  return (
    <>
      <Helmet>
        <title>Profile | Portfolio</title>
      </Helmet>
      <Subheader header="Portfolio">
        <Button to="/profile/portfolio/new" variant="primaryDark">
          Add new instrument
        </Button>
      </Subheader>
      <PortfolioGrid />
    </>
  )
}

ProfilePortfolioIndex.propTypes = {}
