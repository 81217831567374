import styled from 'styled-components'
import Container from '../_helpers/Container'
import Spacer from '../_helpers/Spacer'
import Cart from './Cart'

const Inner = styled.div`
  max-width: 1002px;
  margin-right: auto;
  margin-left: auto;
  border: 1px solid ${({ theme }) => theme.colors.blueTint};
`

export default function CartPage() {
  return (
    <div>
      <Spacer fromSize="30px" toSize="50px" />
      <Container>
        <Inner>
          <Cart />
        </Inner>
      </Container>
      <Spacer fromSize="40px" toSize="80px" />
    </div>
  )
}
