import { useLocation, useSearchParams } from 'react-router-dom'

export const DEFAULT_SORT = 'LISTING_TIME_DESC'
export const DEFAULT_PER_PAGE = 30

// Handles query string such as `?keywords=whatever`
export default function useSearchQuery() {
  const linkState = useLocation()?.state
  const [searchParams, setSearchParams] = useSearchParams()
  const { keywords, perPage, sort, ...others } = Object.fromEntries(
    searchParams.entries()
  )

  // Filter out unrecognized entries, e.g. `utm_` params - mainly to avoid outputting these in canonical shop URLs
  // `keywords` is left out as we probably don't want search URLs to be indexed (though this may change)
  const filterAllowList = [
    'brand',
    'category',
    'year',
    'price',
    'seller',
    'handedness',
  ]
  for (const [key] of Object.entries(others)) {
    if (!filterAllowList.includes(key)) {
      delete others[key]
    }
  }

  const filters = Object.entries(others).map(([key, value]) => ({
    key,
    value,
  }))

  function updateQuery(params) {
    const newQuery = {
      keywords,
      perPage,
      sort,
      ...others,
      ...params,
    }

    const newParams = new URLSearchParams()

    Object.entries(newQuery).forEach(([key, value]) => {
      if (value) {
        newParams.append(
          key,
          Array.isArray(value) ? JSON.stringify(value) : value
        )
      }
    })

    newParams.sort()

    setSearchParams(newParams, {
      state: {
        scrollToTop: false,
        ...linkState,
      },
    })
  }

  function setFilter(key, value, { multiple = false } = {}) {
    if (key === 'perPage' && value === DEFAULT_PER_PAGE) {
      value = undefined
    }

    if (multiple) {
      value = [value]
    }

    if (multiple && others[key]) {
      let values = []

      try {
        const otherValues = JSON.parse(others[key])
        if (Array.isArray(otherValues)) {
          values = otherValues
        }
      } catch (error) {
        console.warn('Invalid multiple filter values', others, error)
      }

      value = values.concat(value)
      value.sort()
    }

    updateQuery({ [key]: value })
  }

  // Unset a single filter
  function clearFilter(key, value, { multiple = false } = {}) {
    if (multiple) {
      let values

      if (others[key]) {
        try {
          const otherValues = JSON.parse(others[key])

          if (Array.isArray(otherValues)) {
            const index = otherValues.indexOf(value)
            if (index > -1) {
              otherValues.splice(index, 1)
            }

            if (otherValues.length > 0) {
              values = JSON.stringify(otherValues)
            }
          }
        } catch (error) {
          console.warn('Invalid multiple filter values', others, error)
        }
      }

      setFilter(key, values, true)
    } else {
      setFilter(key, undefined)
    }
  }

  function clearAllFilters() {
    setSearchParams({})
  }

  return {
    // Search query
    query: { keywords, filters },

    // Pagination options
    paginationVars: {
      perPage: parseInt(perPage, 10) || DEFAULT_PER_PAGE,
    },

    sort: sort ? sort : keywords ? undefined : DEFAULT_SORT,

    // Update callback
    setFilter,

    clearFilter,

    clearAllFilters,
  }
}
