import PropTypes from 'prop-types'
import { useQuery } from 'urql'
import { useUserContext } from '../auth/UserContext'
import Carousel, { themeColor } from './Carousel'
import Loader from './Loader'

const FEED_QUERY = `
  query HomepageFeed($first: Int, $after: String, $last: Int, $before: String) {
    feed(first: $first, after: $after, last: $last, before: $before) {
      pageInfo {
        hasNextPage
        startCursor
        endCursor
      }

      edges {
        node {
          id
          isAvailable

          askingPrice {
            amount
            currency { iso }
          }

          item {
            id
            slug
            title

            images {
              id
              url(size: "productThumb")
            }

            account {
              id
              username
              isPrivateSeller
              isProfessionalSeller
            }
          }
        }
      }
    }
  }
`

export default function RecommendedListingsCarousel({ theme, ...props }) {
  const { user } = useUserContext()
  const [{ fetching, data, error }] = useQuery({
    query: FEED_QUERY,
    variables: {
      first: 12,
    },
    pause: !user,
  })
  if (!user) {
    return null
  }

  if (error) {
    console.error(error)
  }

  if (fetching) {
    return <Loader background={themeColor[theme]} height="200px" />
  }

  if (data?.feed?.edges?.length > 0) {
    const listings = []

    data.feed.edges.map((item) => listings.push(item.node))

    return <Carousel listings={listings} theme={theme} {...props} />
  }

  return null
}

RecommendedListingsCarousel.defaultProps = {
  header: 'Recommended for you',
  theme: 'light',
  view: '/my-saved-searches',
}

RecommendedListingsCarousel.propTypes = {
  header: PropTypes.string.isRequired,
  theme: PropTypes.oneOf(['light', 'dark', 'grey', 'lightGrey']).isRequired,
  view: PropTypes.string,
  viewText: PropTypes.string,
}
