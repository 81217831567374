import PropTypes from 'prop-types'
import { useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import { Formik } from 'formik'
import Search from './Search'
import Select from './Select'
import Filter, { FilterWrapper } from './Filter'
import Range from './Range'
import useSearchQuery from '../shop/useSearchQuery'
import SavedSearchButton from '../shared/SaveSearchButton'
import { useQuery } from 'urql'
import Loader from '../shared/Loader'
import { colors } from '../theme'

export const Sidebar = styled.div`
  ${FilterWrapper} + ${FilterWrapper} {
    margin-top: 14px;
  }
`

const HIDDEN_CATEGORIES = `
query HiddenCategories {
  entries(section: "categories", availableToPublic: false) {
    id
    title
    __typename
  }
}
`
function removeUnavailableFilters(filters, unavailable) {
  const map = {
    category: 'categories_categories_Entry',
  }

  return filters.map((filter) => {
    const type = map[filter.key]
    if (!type) {
      return filter
    }

    filter.values = filter.values.filter(
      (f) =>
        !unavailable
          .filter((un) => un.__typename === type)
          .map((un) => un.title)
          .includes(f.value)
    )

    return filter
  })
}

export default function FilterList({ filters, hideKeywords, windowWidth }) {
  const { setFilter, clearFilter } = useSearchQuery()
  const [hidden] = useQuery({ query: HIDDEN_CATEGORIES })
  const [searchParams] = useSearchParams()

  if (hidden.fetching) {
    return <Loader background={colors.xLightGrey} height="250px" />
  }

  let unavailable = []
  if (hidden.data) {
    unavailable = hidden?.data?.entries || []
  }

  const handleSubmit = async ({ keywords }) => {
    setFilter('keywords', keywords)
  }

  filters = removeUnavailableFilters(filters, unavailable)

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{ keywords: searchParams.get('keywords') || '' }}
    >
      <Sidebar>
        <SavedSearchButton />

        {!hideKeywords && (
          <Filter filter={{ label: 'Keyword search' }}>
            <Search setFilter={setFilter} />
          </Filter>
        )}

        {filters.map((filter) => {
          switch (filter.__typename) {
            case 'SearchFilterSelect': {
              return (
                <Filter
                  accordion
                  key={filter.key}
                  filter={filter}
                  openDefault={
                    filter.key === 'brand'
                      ? windowWidth && windowWidth >= 1024
                      : undefined
                  }
                >
                  <Select
                    filter={filter}
                    setFilter={setFilter}
                    clearFilter={clearFilter}
                  />
                </Filter>
              )
            }

            case 'SearchFilterNumberRange':
              return (
                <Filter key={filter.key} filter={filter}>
                  <Range
                    filter={filter}
                    setFilter={setFilter}
                    clearFilter={clearFilter}
                  />
                </Filter>
              )
          }
        })}
      </Sidebar>
    </Formik>
  )
}

FilterList.propTypes = {
  filters: PropTypes.array,
  hideKeywords: PropTypes.bool,
  windowWidth: PropTypes.number,
}
