import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Media, MediaImage } from '../_base/Media'
import { HeaderM } from '../_abstracts/Type'
import Button from '../_atoms/Button'
import { down } from 'styled-breakpoints'

const Wrapper = styled.div`
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
`

const FeedItems = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding: 13px;
`

const FeedItem = styled.div`
  width: 25%;
  padding: 13px;

  ${down('medium')} {
    width: 33.33%;
  }

  ${down('small')} {
    width: 50%;
  }
`

const FeedImage = styled.div``

const Image = styled(Media)`
  padding-bottom: 100%;
`

const Name = styled.p`
  ${HeaderM};
  font-weight: 700;
  margin: 20px 0;
`

const FeedButton = styled.div`
  margin-top: 40px;
`

export default function FeedSelect({ link, items }) {
  return (
    <Wrapper>
      <FeedItems>
        {items.map((item) => (
          <FeedItem key={item}>
            <FeedImage>
              <Image hasImage={!!item.imageSrc}>
                {item.imageSrc && (
                  <MediaImage src={item.imageSrc} alt={item.alt} />
                )}
              </Image>
            </FeedImage>

            <Name>{item.name}</Name>

            <Button variant="primaryDark">Add to saved searches</Button>
          </FeedItem>
        ))}
      </FeedItems>

      <FeedButton>
        <Button to={link} variant="primaryLight">
          {' '}
          See your new saved searches{' '}
        </Button>
      </FeedButton>
    </Wrapper>
  )
}

FeedSelect.propTypes = {
  link: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      imageSrc: PropTypes.string.isRequired,
      alt: PropTypes.bool.isRequired,
      name: PropTypes.bool.isRequired,
    })
  ),
}
