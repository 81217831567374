import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useMutation } from 'urql'
import toast from 'react-hot-toast'
import { HeaderSansXS } from '../_abstracts/Type'
import { down } from 'styled-breakpoints'

import Checkbox from '../inputs/Checkbox'
import { Formik } from 'formik'

export const Wrapper = styled.div`
  max-width: 640px;
  margin-right: auto;
  margin-left: auto;
  padding: 50px 13px;
  border-top: 1px solid ${(props) => props.theme.colors.blueTint};

  ${down('medium')} {
    padding: 35px 13px;
  }
`

const Header = styled.h2`
  ${HeaderSansXS}
  width: 100%;
  text-align: center;
  color: ${(props) => props.theme.colors.darkBlue};
  margin-bottom: 15px;
`

const List = styled.div``

const Item = styled.div`
  display: block;
  padding: 13px;
`

const ADD_CATEGORY_SEARCH = `
  mutation AddSavedCategorySearch($query: SearchQuery!) {
    listingSearchSave(search: { query: $query }) {
      success
      savedSearch {
        id
        keywords
        filters {
          key
          label
          value
        }
        emailOnMatch
      }
    }
  }
`

const REMOVE_CATEGORY_SEARCH = `
  mutation RemoveSavedCategorySearch($id: ID!) {
    listingSavedSearchRemove(id: $id) {
      success
    }
  }
`

export default function Toggles({ header, list }) {
  const [, add] = useMutation(ADD_CATEGORY_SEARCH)
  const [, remove] = useMutation(REMOVE_CATEGORY_SEARCH)

  async function handleAdd(query) {
    const toastId = toast('Adding category search…')

    const { data } = await add(
      { query },
      { additionalTypenames: ['ListingSavedSearch'] }
    )

    if (data?.listingSearchSave.success) {
      toast.success('Added category search', { id: toastId })
    } else {
      toast.error('Unable to add category search', { id: toastId })
    }
  }

  async function handleRemove(savedSearchId) {
    const toastId = toast('Removing category search…')

    const { data } = await remove(
      { id: savedSearchId },
      { additionalTypenames: ['ListingSavedSearch'] }
    )

    if (data?.listingSavedSearchRemove.success) {
      toast.success('Removed category search', { id: toastId })
    } else {
      toast.error('Unable to remove category search', { id: toastId })
    }
  }

  return (
    <Wrapper>
      <Header>{header}</Header>

      <Formik initialValues={{ field: '' }}>
        <List>
          {list.map((item) => {
            return (
              <Item key={item.label}>
                <Checkbox
                  name={item.label}
                  label={item.label}
                  checked={!!item.savedSearchId}
                  onChange={(value) => {
                    if (value) {
                      handleAdd(item.query)
                    } else {
                      handleRemove(item.savedSearchId)
                    }
                  }}
                  blueLabel
                />
              </Item>
            )
          })}
        </List>
      </Formik>
    </Wrapper>
  )
}

Toggles.propTypes = {
  header: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      query: PropTypes.array.isRequired,
      savedSearchId: PropTypes.string,
    })
  ).isRequired,
}
