import PropTypes from 'prop-types'
import { down, up } from 'styled-breakpoints'
import styled, { css } from 'styled-components'
import { HeaderL, Body } from '../_abstracts/Type'
import Button from '../_atoms/Button'
import { Icon } from '../_base/Icon'
import ButtonGroup from './ButtonGroup'

const Wrapper = styled.ul`
  counter-reset: numbered-block-counter 0;
`

const Item = styled.li`
  padding: 40px 32px;
  margin-top: 24px;
  background-color: ${(props) => props.theme.colors.accentTint};
  border-radius: 5px;

  ${down('small')} {
    padding: 20px;
  }

  &:first-child {
    margin-top: 0;
  }

  &:nth-child(2n) {
    background-color: ${(props) => props.theme.colors.blueTint};
  }

  &:nth-child(3n) {
    background-color: ${(props) => props.theme.colors.lightGrey};
  }

  p,
  ul,
  ol {
    color: ${(props) => props.theme.colors.xDarkGrey};
  }
`

const Inner = styled.div`
  display: flex;
  justify-content: space-around;
  list-style-type: none;

  ${down('medium')} {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
`

const ChildBlocks = styled.div`
  margin-top: 30px;
`

const ItemIcon = styled.div`
  position: relative;
  flex-shrink: 0;
  margin-left: -10px;

  ${down('medium')} {
    display: none;
  }

  ${up('large')} {
    margin-right: 24px;
  }

  &::before {
    content: '';
    position: absolute;
    background-color: ${(props) => props.theme.colors.blue20};
    width: 1px;
    top: 65px;
    bottom: 0;
    left: 50%;
    opacity: 0.8;
  }

  ${down('medium')} {
    margin: 10px;

    &::before {
      content: none;
    }
  }

  ${Icon} {
    width: 54px;
  }
`

const IconWrapper = styled.div`
  position: relative;
  margin-top: -12px;
  width: 54px;
  height: 54px;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.midGrey};
  border-radius: 50%;

  ${Icon} {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 65%;
    height: 65%;
    transform: translate(-50%, -50%);
  }
`

const Content = styled.div`
  flex-grow: 1;

  ${down('medium')} {
    width: 100%;
  }
`

const Image = styled.figure`
  max-width: 400px;

  ${down('medium')} {
    margin: 36px auto 0;
  }

  ${up('large')} {
    flex-shrink: 0;
    width: 42%;
    margin-left: 32px;
  }
`

const Header = styled.h3`
  ${HeaderL};
  padding-bottom: 30px;

  ${({ numbered }) =>
    numbered &&
    css`
      counter-increment: numbered-block-counter 1;

      &:before {
        content: counter(numbered-block-counter) '. ';
        color: ${(props) => props.theme.colors.accent};
      }
    `}
`

const Text = styled.div`
  ${Body};
  margin-bottom: 1em;
  color: ${(props) => props.theme.colors.xDarkGrey};

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${({ theme }) => theme.colors.darkBlue};
  }

  &:last-child {
    margin-bottom: 0;
  }
`

export default function NumberedBlocks({ items, numbered }) {
  return (
    <Wrapper>
      {items.map((item, index) => (
        <Item key={index}>
          <Inner>
            {item.icon && (
              <ItemIcon>
                <IconWrapper>{item.icon}</IconWrapper>
              </ItemIcon>
            )}
            <Content>
              <Header numbered={numbered}>{item.header}</Header>
              <Text>{item.text}</Text>
              {item.buttons && item.buttons.length ? (
                <ButtonGroup>
                  {item.buttons.map((item, index) => {
                    return (
                      <Button key={index} variant={item.variant} to={item.to}>
                        {item.text}
                      </Button>
                    )
                  })}
                </ButtonGroup>
              ) : undefined}
            </Content>
            {item.image && (
              <Image>
                <img src={item.image} alt={item.imageAlt || ''} />
              </Image>
            )}
          </Inner>
          {item.children && <ChildBlocks>{item.children}</ChildBlocks>}
        </Item>
      ))}
    </Wrapper>
  )
}

NumberedBlocks.propTypes = {
  numbered: true,
}

NumberedBlocks.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      children: PropTypes.node,
      header: PropTypes.string.isRequired,
      text: PropTypes.node,
      icon: PropTypes.element,
      image: PropTypes.string,
      imageAlt: PropTypes.string,
      buttons: PropTypes.arrayOf(
        PropTypes.shape({
          variant: PropTypes.oneOf([
            'primaryLight',
            'primaryDark',
            'secondary',
            'tertiary',
          ]).isRequired,
          text: PropTypes.string.isRequired,
          to: PropTypes.string.isRequired,
        })
      ),
    })
  ).isRequired,
  numbered: PropTypes.bool,
}
