import { Outlet } from 'react-router-dom'
import styled from 'styled-components'
import { LoginReturnNavigate } from '../_atoms/ReturnLink'
import Container from '../_helpers/Container'
import Spacer from '../_helpers/Spacer'
import { useUserContext } from '../auth/UserContext'
import Html from '../data/Html'
import { useGlobals } from '../hooks'
import Cta from '../shared/Cta'
import Profile from '../shared/Profile'
import Tabs from '../shared/Tabs'
import RecentlyViewedCarousel from '../shared/RecentlyViewedCarousel'

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.colors.xLightGrey};
`

const Inner = styled.div`
  max-width: 1360px;
  margin-left: auto;
  margin-right: auto;
  padding: 25px;
`

export default function ProfileLayout() {
  const { profile } = useGlobals('profile')
  const cta = profile?.cta[0]
  const { user } = useUserContext()

  if (!user) return <LoginReturnNavigate />

  const settingsSubnav = [
    { title: 'Details', url: '/details' },
    { title: 'Addresses', url: '/addresses' },
    { title: 'Invoices', url: '/invoices' },
    { title: 'Payment methods', url: '/payment' },
    { title: 'Notifications', url: '/notifications' },
  ]

  if (user.canManageShippingMethods) {
    settingsSubnav.push({ title: 'Shipping', url: '/shipping' })
  }

  return (
    <Wrapper>
      <Inner>
        <Profile user={user} />
        <Tabs
          tabs={[
            { title: 'Dashboard', url: '/profile', end: true },
            { title: 'Portfolio', url: '/profile/portfolio' },
            {
              title: 'Selling',
              url: '/profile/selling',
              subnav: [
                { title: 'Listings', url: '/listings' },
                { title: 'Orders', url: '/orders' },
                { title: 'Offers', url: '/offers' },
              ],
            },
            {
              title: 'Buying',
              url: '/profile/buying',
              subnav: [
                { title: 'Purchases', url: '/purchases' },
                { title: 'Offers', url: '/offers' },
              ],
            },
            {
              title: 'Settings',
              url: '/profile/settings',
              subnav: settingsSubnav,
            },
          ]}
        >
          <Outlet context={{ user }} />
        </Tabs>
      </Inner>

      <RecentlyViewedCarousel theme="lightGrey" />

      {cta && (
        <>
          <Container>
            <Cta
              header={cta.title}
              text={<Html html={cta.ctaText} />}
              variant={cta.ctaBackgroundColor}
              buttons={cta.ctaLinks.map((item) => ({
                text: item.itemLink.text,
                to: item.itemLink.url,
                variant: item.buttonTheme,
              }))}
            />
          </Container>
          <Spacer size="50px" />
        </>
      )}
    </Wrapper>
  )
}

ProfileLayout.propTypes = {}
