import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { Form as FormikForm } from 'formik'
import { SimpleWrapper as SimpleFieldset } from '../forms/Fieldset'
import {
  Topper as Subheader,
  TopperHeader as SubheaderHeading,
} from '../shared/Subheader'

const Form = styled(FormikForm)`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-between;

  ${({ justify }) =>
    justify === 'center' &&
    css`
      flex-direction: column;
      align-items: center;

      > * {
        width: 550px;
        max-width: 100%;
      }

      ${SimpleFieldset} {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        > * {
          width: 550px;
          max-width: 100%;
        }
      }
    `}

  ${({ $border }) =>
    $border &&
    css`
      padding-top: 50px;
      padding-bottom: 50px;
      border-top: 1px solid ${({ theme }) => theme.colors.blueTint};

      &:first-child {
        border-top: 0;
      }
    `}

  ${Subheader} {
    padding: 9px 0;
  }

  ${SubheaderHeading} {
    padding-right: 0;
    padding-left: 0;
  }
`

Form.defaultProps = {
  justify: 'start',
}

Form.propTypes = {
  $border: PropTypes.bool,
  justify: PropTypes.oneOf(['start', 'center']),
}

export default Form
