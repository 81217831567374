import styled from 'styled-components'
import PropTypes from 'prop-types'
import { HeaderM } from '../_abstracts/Type'

const Wrapper = styled.div`
  ${HeaderM};
  font-weight: 600;
  color: ${(props) => props.theme.colors.darkBlue};
  text-align: center;
  text-transform: none;
`

const Inner = styled.div`
  max-width: 800px;
  margin-right: auto;
  margin-left: auto;
`

export default function CenteredTextBlock({ children }) {
  return (
    <Wrapper>
      <Inner>{children}</Inner>
    </Wrapper>
  )
}

CenteredTextBlock.propTypes = {
  children: PropTypes.node.isRequired,
}
