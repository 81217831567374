const GET_GlOBAL_SET = `
  query GetConsignment {
    globalSet(handle: "consignment") {
      ... on consignment_GlobalSet {
        id
        consignmentInfo
      }
    }
  }
`

export default GET_GlOBAL_SET
