import styled from 'styled-components'
import PropTypes from 'prop-types'
import { HeaderL, HeaderSansXS } from '../_abstracts/Type'
import { Icon } from '../_base/Icon'

const Wrapper = styled.div`
  flex-grow: 1;
  padding: 75px 20px 50px;
  background: ${(props) => props.theme.colors.lighterGrey};
`

const Inner = styled.div`
  position: relative;
  max-width: 600px;
  margin-right: auto;
  margin-left: auto;
  background: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.blueTint};
  border-radius: ${(props) => props.theme.radii};
  padding: 75px 25px 25px;
  text-align: center;
`

const Header = styled.h2`
  ${HeaderL};
  color: ${(props) => props.theme.colors.darkBlue};
`

const Children = styled.div`
  ${HeaderSansXS};

  > p:first-child {
    font-weight: 300;
  }

  > * {
    margin-top: 20px;
  }

  a[class*='Button__ButtonStyle'] {
    display: block;
  }

  a:not([class*='Button__ButtonStyle']) {
    color: ${(props) => props.theme.colors.accent};
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  p {
    max-width: 550px;
    margin-right: auto;
    margin-left: auto;
  }

  strong {
    font-weight: normal;
  }

  input {
    text-transform: initial;
    text-align: left;
  }
`

const WrapperMedia = styled.div`
  position: absolute;
  border: 1px solid ${(props) => props.theme.colors.midGrey};
  border-radius: 50%;
  width: 100px;
  height: 100px;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${(props) => props.theme.colors.lightGrey};

  ${Icon} {
    position: absolute;
    width: 65%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

export default function IconWrapper({ icon, header, children }) {
  return (
    <Wrapper>
      <Inner>
        <WrapperMedia>{icon}</WrapperMedia>
        <Header>{header}</Header>
        <Children>{children}</Children>
      </Inner>
    </Wrapper>
  )
}

IconWrapper.propTypes = {
  icon: PropTypes.node.isRequired,
  header: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}
