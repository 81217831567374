import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import styled, { css } from 'styled-components'
import Link from '../_atoms/Link'
import { down } from 'styled-breakpoints'

const Wrapper = styled.div`
  ${({ variant }) =>
    (variant === 'standard' &&
      css`
        border: 1px solid ${(props) => props.theme.colors.midGrey};
        border-radius: 6px;
        background-color: ${(props) => props.theme.colors.white};
      `) ||
    (variant === 'slim' &&
      css`
        display: flex;
        justify-content: center;
      `)}

  > *:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit;
  }
`

const MainNav = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  text-align: center;

  ${({ variant }) =>
    (variant === 'standard' &&
      css`
        padding: 20px;
        border-bottom: 1px solid ${(props) => props.theme.colors.midGrey};

        ${down('small')} {
          padding: 10px;

          a {
            width: 100% !important;
          }
        }

        &:last-child {
          border-bottom: 0;
          border-bottom-right-radius: inherit;
          border-bottom-left-radius: inherit;
        }
      `) ||
    (variant === 'slim' &&
      css`
        overflow: hidden;
        border: 1px solid ${(props) => props.theme.colors.midGrey};
        border-radius: ${(props) => props.theme.radii};
      `)}

  @media print {
    display: none;
  }
`

const SubNav = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors.midGrey};
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.lightGrey};
  padding: 8px 20px 10px;

  @media print {
    display: none;
  }
`

const standardTabStyled = css`
  padding: ${(props) => (props.$mainNav ? '15px 20px' : '8px 20px')};
  width: fit-content;
  line-height: 1;

  ${down('small')} {
    padding: 10px;
  }

  ${({ $mainNav }) =>
    $mainNav &&
    css`
      margin-left: 10px;
      margin-right: 10px;
      border: 1px solid transparent;
    `};

  ${({ $mainNav, $isActive }) =>
    $mainNav &&
    $isActive &&
    css`
      border-color: ${(props) => props.theme.colors.accent};
      border-radius: 5px;
      background-color: ${(props) => props.theme.colors.accentTint};
    `};

  ${({ $subNav }) =>
    $subNav &&
    css`
      font-size: 14px;
    `};

  ${({ $subNav, $isActive }) =>
    $subNav &&
    $isActive &&
    css`
      text-decoration: underline;
    `};
`

const slimTabStyles = css`
  padding: 11px 20px;
  min-width: 186px;
  flex-grow: 1;
  box-shadow: -0.5px -0.5px 0 0.5px ${(props) => props.theme.colors.midGrey};

  ${({ $isActive }) =>
    $isActive &&
    css`
      background-color: ${(props) => props.theme.colors.white};
      font-weight: 600;
      text-decoration: underline;
    `};
`

const DisabledTab = styled.span`
  color: ${({ theme }) => theme.colors.darkGrey};
  cursor: default;

  ${({ variant }) =>
    (variant === 'standard' && standardTabStyled) ||
    (variant === 'slim' && slimTabStyles)}
`

const Tab = styled(Link)`
  text-decoration: none;

  ${({ variant }) =>
    (variant === 'standard' && standardTabStyled) ||
    (variant === 'slim' && slimTabStyles)}
`

export default function Tabs({
  activeUrl,
  children,
  disableNextTabs,
  tabs,
  variant,
}) {
  let location = useLocation()

  // Active check based on react-router NavLink component:
  const activeIndex = tabs.findIndex(
    ({ end, url }) =>
      activeUrl === url ||
      location.pathname === url ||
      (!end && location.pathname.startsWith(url))
  )

  return (
    <Wrapper variant={variant}>
      <MainNav variant={variant}>
        {tabs.map(({ subnav, title, url }, index) => {
          if (disableNextTabs && index > activeIndex) {
            return (
              <DisabledTab $mainNav key={index} variant={variant}>
                {title}
              </DisabledTab>
            )
          }

          return (
            <Tab
              $isActive={index === activeIndex}
              $mainNav
              key={index}
              to={subnav ? url + subnav[0].url : url}
              variant={variant}
              state={{ scrollToTop: false }}
            >
              {title}
            </Tab>
          )
        })}
      </MainNav>
      {tabs.map(({ subnav, url }, index) => {
        if (subnav && location.pathname.startsWith(url)) {
          return (
            <SubNav key={index}>
              {subnav.map(({ title, url: subnavUrl }, index) => {
                const isActive =
                  location.pathname === url + subnavUrl ||
                  location.pathname.startsWith(url + subnavUrl)

                return (
                  <Tab
                    $isActive={isActive}
                    $subNav
                    key={index}
                    to={url + subnavUrl}
                    variant={variant}
                    state={{ scrollToTop: false }}
                  >
                    {title}
                  </Tab>
                )
              })}
            </SubNav>
          )
        }
      })}
      <div>{children}</div>
    </Wrapper>
  )
}

Tabs.defaultProps = {
  variant: 'standard',
}

Tabs.propTypes = {
  activeUrl: PropTypes.string,
  children: PropTypes.node,
  disableNextTabs: PropTypes.bool,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      end: PropTypes.bool,
      subnav: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string.isRequired,
          url: PropTypes.string.isRequired,
        })
      ),
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ),
  variant: PropTypes.oneOf(['standard', 'slim']),
}
