import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Media, MediaImage } from '../_base/Media'
import Money from '../data/Money'
import { HeaderSansXS } from '../_abstracts/Type'

const Wrapper = styled.div`
  display: flex;
`

const Stats = styled.div`
  margin-left: 15px;

  &:first-child {
    margin-left: 0;
  }
`

const Title = styled.h2`
  ${HeaderSansXS};
  font-weight: 600;
`

const Price = styled.p`
  margin-top: 5px;
  font-weight: 600;
  span {
    font-size: 14px;
    color: ${(props) => props.theme.colors.accent};
    margin-right: 5px;
  }
`

const Img = styled(Media)`
  position: relative;
  height: 96px;
  width: 96px;
  flex-shrink: 0;
  border: 1px solid ${({ theme }) => theme.colors.midGrey};
  border-radius: ${(props) => props.theme.radii};
`

export default function ProductStatCard({ title, price, img, alt }) {
  return (
    <Wrapper>
      {img && (
        <Img>
          <MediaImage src={img} alt={alt || ''} />
        </Img>
      )}
      <Stats>
        <Title>{title}</Title>
        {price && (
          <Price>
            <span>Listed Price:</span>{' '}
            <Money amount={price.amount} currency={price.currency} />
          </Price>
        )}
      </Stats>
    </Wrapper>
  )
}

ProductStatCard.propTypes = {
  title: PropTypes.string.isRequired,
  price: PropTypes.shape({
    amount: PropTypes.number.isRequried,
    currency: PropTypes.string.isRequired,
  }),
  img: PropTypes.string,
  alt: PropTypes.string,
}
