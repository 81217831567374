import styled from 'styled-components'
import Tabs from '../shared/Tabs'
import Subheader from '../shared/Subheader'
import HorizontalProductCard from '../shared/HorizontalProductCard'
import { useRecentlyViewed } from '../hooks'
import Loader from '../shared/Loader'

const Wrapper = styled.div`
  flex-grow: 1;
  background-color: ${(props) => props.theme.colors.xLightGrey};
`

const Inner = styled.div`
  max-width: 1310px;
  margin-left: auto;
  margin-right: auto;
  padding: 25px;
`

const List = styled.div`
  padding: 20px;
`

const Message = styled.p`
  text-align: center;
`

const tabsArgs = {
  tabs: [
    { title: 'My Saved Searches', url: '/my-saved-searches', end: true },
    { title: 'Wishlist', url: '/wishlist' },
    { title: 'Recently Viewed', url: '/recently-viewed', end: true },
  ],
}

export default function RecentlyViewedPage() {
  const { items, fetching, error } = useRecentlyViewed()

  return (
    <Wrapper>
      <Inner>
        <Tabs {...tabsArgs}>
          <Subheader header="Recently Viewed" border />

          <List>
            {(fetching && <Loader background="transparent" height="200px" />) ||
              (error && <Message>An error occurred</Message>) ||
              (items.length ? (
                items.map((item) => (
                  <HorizontalProductCard
                    key={item.id}
                    item={item}
                    linkType={'listing'}
                  />
                ))
              ) : (
                <Message>No items found</Message>
              ))}
          </List>
        </Tabs>
      </Inner>
    </Wrapper>
  )
}

RecentlyViewedPage.propTypes = {}
