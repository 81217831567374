import { useState } from 'react'

export default function useAnalyticsViewItem(item, _user) {
  const [shown, setShown] = useState(false)

  if (shown) return
  if (!item?.listing?.askingPrice) return
  if (typeof window === 'undefined') return

  window.dataLayer = window.dataLayer || []
  viewItemGa(item)
  viewItemKlaviyo(item)
  viewItemFb(item)
  setShown(true)
}

export function resetDatalayer() {
  window.dataLayer.push({
    ecommerce: null,
    fbEventData: null,
    eventDestination: null,
  })
}

function viewItemGa(item) {
  resetDatalayer()
  window.dataLayer.push({
    event: 'view_item',
    eventDestination: 'ga',
    ecommerce: {
      currency: item.listing.askingPrice.currency.iso,
      value: item.listing.askingPrice.amount,
      items: [
        {
          item_id: item.id,
          item_name: item.title,
        },
      ],
    },
  })
}

function viewItemKlaviyo(item) {
  if (!window.klaviyo) return

  const data = {
    ProductName: item.title,
    ProductID: item.id,
    SKU: item.sku,
    ImageURL: item.images?.[0]?.url,
    URL: `https://cartervintage.com/shop/${item.slug}/${item.id}`,
    Brand: item?.brand?.title,
    Price: item.listing.askingPrice.amount,
  }

  window.klaviyo.push(['track', 'Viewed Product', data])
}

function viewItemFb(item) {
  resetDatalayer()
  window.dataLayer.push({
    event: 'ViewContent',
    eventDestination: 'fb',
    fbEventData: {
      content_ids: [item.id],
      content_name: item.title,
      content_type: 'product',
      currency: item.listing.askingPrice.currency.iso,
      value: item.listing.askingPrice.amount,
    },
  })
}
