import { Formik } from 'formik'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import toast from 'react-hot-toast'
import styled from 'styled-components'
import { useMutation, useQuery } from 'urql'
import * as Yup from 'yup'
import Form from '../_atoms/Form'
import Button from '../_atoms/Button'
import Input from '../_atoms/Input'
import Country from '../inputs/Country'
import Phone from '../inputs/Phone'
import Select from '../inputs/Select'
import Field from './Field'
import { SimpleFieldset } from './Fieldset'
import { linkStates } from '../_abstracts/Type'

export const CREATE_ADDRESS = `
  mutation CreateAddress($address: AddressCreateInput!, $accountId: String) {
    addressCreate(address: $address, accountId: $accountId) {
      success
      address {
        id
        firstName
        lastName
        country {
          name
          iso
        }
        line1
        line2
        city
        region {
          name
        }
        postalCode
        phone
      }
    }
  }
`

const UPDATE_ADDRESS = `
  mutation UpdateAddress($id: ID!, $address: AddressUpdateInput!, $accountId: String) {
    addressUpdate(id: $id, address: $address, accountId: $accountId) {
      success
      address {
        id
        firstName
        lastName
        country {
          name
          iso
        }
        line1
        line2
        city
        region {
          name
        }
        postalCode
        phone
      }
    }
  }
`

const DELETE_ADDRESS = `
  mutation DeleteAddress($id: ID!, $accountId: String) {
    addressDelete(id: $id, accountId: $accountId) {
      success
    }
  }
`

const GET_COUNTRY_REGIONS = `
  query GetCountries {
    countries {
      iso
      regions {
        name
      }
    }
  }
`

const Schema = Yup.object().shape({
  firstName: Yup.string()
    .max(250, 'Max 250 characters')
    .trim()
    .required('Required'),
  lastName: Yup.string()
    .max(250, 'Max 250 characters')
    .trim()
    .required('Required'),
  company: Yup.string().max(250, 'Max 250 characters').trim(),
  phone: Yup.string().max(250, 'Max 250 characters').trim().required(),
  line1: Yup.string()
    .max(250, 'Max 250 characters')
    .trim()
    .required('Required'),
  line2: Yup.string()
    .max(250, 'Max 250 characters')
    .trim()
    .nullable()
    .transform((value) => value || null),
  city: Yup.string().max(250, 'Max 250 characters').trim().required('Required'),
  region: Yup.string()
    .max(250, 'Max 250 characters')
    .trim()
    .required('Required'),
  countryIso: Yup.string().max(2).uppercase().required('Required'),
  postalCode: Yup.string()
    .max(250, 'Max 250 characters')
    .trim()
    .required('Required'),
})

const Delete = styled.button.attrs({ type: 'button' })`
  ${linkStates};
  color: ${({ theme }) => theme.colors.red};
`

export default function AddressForm({
  addressId,
  initialValues,
  onDelete,
  onSave,
  accountId,
}) {
  // Fetch country region data to use in state/region dropdown
  const [{ data, fetching }] = useQuery({ query: GET_COUNTRY_REGIONS })

  // Save mutation is either update/create depending on whether an address ID was passed in props
  const saveMutation = addressId ? UPDATE_ADDRESS : CREATE_ADDRESS
  const [saveState, submitSave] = useMutation(saveMutation)

  const [deleteState, submitDelete] = useMutation(DELETE_ADDRESS)

  async function handleSubmit(fields) {
    const parsedFields = Schema.cast(fields, { strict: true })
    const { data } = await submitSave({
      id: addressId,
      address: parsedFields,
      accountId,
    })

    if (data?.addressCreate?.success) {
      onSave && onSave(data?.addressCreate.address)
      console.log('Successfully created address')
    } else if (data?.addressUpdate?.success) {
      onSave && onSave(data?.addressUpdate.address)
      console.log('Successfully updated address')
    }
  }

  async function handleDelete() {
    const { data } = await submitDelete({
      id: addressId,
      accountId,
    })

    if (data?.addressDelete.success) {
      onDelete && onDelete(addressId)
      console.log('Successfully deleted address')
    }
  }

  useEffect(() => {
    if (saveState.fetching) {
      toast('Saving address…', { id: 'form' })
    } else if (deleteState.fetching) {
      toast('Deleting address…', { id: 'form' })
    } else if (saveState.data?.addressCreate?.success) {
      toast.success('Address added', { id: 'form' })
    } else if (saveState.data?.addressUpdate?.success) {
      toast.success('Address updated', { id: 'form' })
    } else if (deleteState.data?.addressDelete.success) {
      toast.success('Address deleted', { id: 'form' })
    } else if (
      saveState.error ||
      saveState.data?.addressCreate?.success === false ||
      saveState.data?.addressUpdate?.success === false ||
      deleteState.data?.addressDelete.success === false
    ) {
      toast.error('Unable to save changes', { id: 'form' })
    }
  }, [saveState, deleteState])

  const countryRegions = (countryIso) => {
    const country = data?.countries.find(
      (country) => country.iso === countryIso
    )
    return country?.regions
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      validationSchema={Schema}
      initialValues={initialValues}
    >
      {({ values }) => (
        <Form>
          <SimpleFieldset hideLegend legend="Address">
            <Field width="half">
              <Input
                autocomplete="given-name"
                autofocus
                label="First name"
                name="firstName"
                required
              />
            </Field>
            <Field width="half">
              <Input
                autocomplete="family-name"
                label="Last name"
                name="lastName"
                required
              />
            </Field>
            <Field>
              <Country
                autocomplete="country"
                label="Country"
                name="countryIso"
                required
              />
            </Field>
            <Field>
              <Input
                autocomplete="address-line1"
                label="Address"
                name="line1"
                placeholder="Address line 1"
                required
              />
              <Input
                autocomplete="address-line2"
                label="Address line 2"
                hideLabel
                name="line2"
                placeholder="Address line 2 (optional)"
              />
            </Field>
            <Field width="half">
              <Input
                autocomplete="address-level2"
                label="City"
                name="city"
                required
              />
            </Field>
            <Field width="half">
              {(values.countryIso && countryRegions(values.countryIso) && (
                <Select
                  autocomplete="address-level1"
                  label="State/region"
                  name="region"
                  placeholder="Select state/region"
                  options={countryRegions(values.countryIso).map((region) => ({
                    label: region.name,
                    value: region.name,
                  }))}
                  required
                />
              )) || (
                <Input
                  autocomplete="address-level1"
                  label="State/region"
                  name="region"
                  required
                />
              )}
            </Field>
            <Field width="half">
              <Input
                autocomplete="postal-code"
                label="Zip/postal code"
                name="postalCode"
                required
              />
            </Field>
            <Field width="half">
              <Phone defaultCountry={values.countryIso} required />
            </Field>
          </SimpleFieldset>

          <Field justify="reverse-space-between">
            <Button
              disabled={fetching || saveState.fetching || deleteState.fetching}
              submit
              variant="primaryDark"
            >
              Save
            </Button>
            {addressId && (
              <Delete
                disabled={
                  fetching || saveState.fetching || deleteState.fetching
                }
                onClick={handleDelete}
              >
                Delete
              </Delete>
            )}
          </Field>
        </Form>
      )}
    </Formik>
  )
}

AddressForm.defaultProps = {
  initialValues: {
    firstName: '',
    lastName: '',
    countryIso: '',
    line1: '',
    line2: '',
    city: '',
    region: '',
    postalCode: '',
    phone: '',
  },
}

AddressForm.propTypes = {
  addressId: PropTypes.string,
  initialValues: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    countryIso: PropTypes.string,
    line1: PropTypes.string,
    line2: PropTypes.string,
    city: PropTypes.string,
    region: PropTypes.string,
    postalCode: PropTypes.string,
    phone: PropTypes.string,
  }),
  onDelete: PropTypes.func,
  onSave: PropTypes.func,
  accountId: PropTypes.string,
}
