import PropTypes from 'prop-types'

import styled, { css } from 'styled-components'

import { HeaderL, BodyS } from '../_abstracts/Type'
import { up } from 'styled-breakpoints'
import Icon from '../blocks/Icon'
import Button from '../_atoms/Button'
import { Wrapper as HtmlWrapper } from '../data/Html'
import Tooltip, { Wrapper as TooltipWrapper } from './Tooltip'

const Wrapper = styled.div`
  overflow: hidden;
  padding: 40px 20px 25px 20px;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.blueTint};
  border-radius: ${(props) => props.theme.radii};
`

const Header = styled.h2`
  ${HeaderL};
  color: ${(props) => props.theme.colors.darkBlue};
  margin-top: 25px;
  text-align: center;

  &:first-child {
    margin-top: -0.2em;
  }
`

const List = styled.ul`
  display: flex;
  margin-top: 25px;
  justify-content: center;
  flex-wrap: wrap;

  ${up('small')} {
    justify-content: space-between;
    flex-wrap: nowrap;

    > *:not(:first-child) {
      margin-left: 30px;
    }
  }
`

const Item = styled.li`
  ${BodyS}
  width: 100%;
  max-width: 340px;
  flex-shrink: 1;
  flex-grow: 1;
  text-align: center;
  color: ${(props) => props.theme.colors.xDarkGrey};
  margin-top: 20px;

  i {
    width: 70px;
    margin: 0 auto 10px;
  }

  ${up('small')} {
    margin-top: 0;
  }

  ${({ hasTooltip }) =>
    hasTooltip &&
    css`
      ${HtmlWrapper} {
        display: inline;

        > p:last-child {
          display: inline;
        }
      }

      ${TooltipWrapper} {
        top: -1px;
        margin-left: 0.3em;
      }
    `}
`

const Cta = styled.div`
  margin-top: 25px;
  text-align: center;
`

export default function IconList({ header, items }) {
  return (
    <Wrapper>
      <Header>{header}</Header>
      <List>
        {items.map((item, index) => (
          <Item key={index} hasTooltip={!!item.tooltip}>
            {item.icon && <Icon icon={item.icon} />}
            {item.text}
            {item.tooltip && <Tooltip>{item.tooltip}</Tooltip>}
          </Item>
        ))}
      </List>

      <Cta>
        <Button to="/help-center" variant="primaryDark">
          Visit Our Help Center
        </Button>
      </Cta>
    </Wrapper>
  )
}

IconList.propTypes = {
  header: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.node.isRequired,
      icon: PropTypes.string,
    })
  ).isRequired,
}
