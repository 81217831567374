import { Helmet } from 'react-helmet-async'
import styled, { css } from 'styled-components'
import { useQuery } from 'urql'
import { LoginReturnNavigate } from '../_atoms/ReturnLink'
import { UserConsumer } from '../auth/UserContext'
import Container from '../_helpers/Container'
import NotFound from '../404/NotFound'
import { SubHeader } from '../shared/Hero'
import Loader from '../shared/Loader'
import Tags from './Tags'
import { HeaderXL } from '../_abstracts/Type'

const GET_PAGE = `
  query GetPage {
    entry(section: "welcome") {
      id
      title
      ... on welcome_welcome_Entry {
        backgroundImage {
          url(handle: "large")
        }
      }
    }
  }
`

const Wrapper = styled.div`
  padding-top: 60px;
  padding-bottom: 100px;
  background-color: ${({ theme }) => theme.colors.black};

  ${({ backgroundImageUrl }) =>
    backgroundImageUrl &&
    css`
      background-image: url('${backgroundImageUrl}');
      background-size: cover;
    `}

  ${SubHeader} {
    margin-bottom: 32px;
    opacity: 1;
    transform: none;
    max-width: 250px;
  }
`

const Header = styled.h2`
  ${HeaderXL}
  text-align: center;
  margin-bottom: 30px;
  color: ${({ theme }) => theme.colors.white};
`

export default function RegisterFeedPage() {
  const [{ data, fetching, error }] = useQuery({ query: GET_PAGE })

  return (
    <UserConsumer>
      {({ user }) => {
        if (!user) return <LoginReturnNavigate />

        if (fetching) return <Loader background="#fff" />

        if (error) return <p>{error.message}</p>

        if (!data.entry) {
          return <NotFound />
        }

        const { entry } = data

        return (
          <>
            <Helmet>
              <title>{entry.seoTitle || entry.title}</title>
            </Helmet>
            <Wrapper backgroundImageUrl={entry.backgroundImage[0]?.url}>
              <Container>
                <SubHeader>Thank you, {user.firstName}</SubHeader>
                <Header>Welcome to CVG!</Header>

                <Tags header="Here are some popular searches you can add to your saved searches:" />
              </Container>
            </Wrapper>
          </>
        )
      }}
    </UserConsumer>
  )
}
