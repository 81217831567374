import {
  cloneElement,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { useLocation } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Transition from '../_abstracts/Animation'
import ReactModal from 'react-modal'
import { useScrollBlock } from '../hooks'

const transitionDuration = 200

const Wrapper = styled.div`
  display: inline-block;
`

const customStyles = {
  overlay: {
    background: 'rgba(6, 6, 45, 0.45)',
  },
}

const Overlay = styled.section`
  ${Transition({ property: 'opacity', duration: `${transitionDuration}ms` })};
  display: flex;
  overflow: hidden;
  opacity: ${({ state }) => (state === 'entered' ? 1 : 0)};
  inset: unset !important;
  bottom: 0 !important;
  left: 0 !important;
  width: 100%;
  height: calc(100% - 73px);
`

const Container = styled(ReactModal)``

const Content = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  height: 100%;
  width: 100%;
  max-width: 300px;
  background-color: ${(props) => props.theme.colors.white};

  &:focus {
    outline: 0;
  }
`

const SlideOut = forwardRef(({ children, trigger }, ref) => {
  const overlayRef = useRef(null)
  const [modalIsOpen, setIsOpen] = useState(false)
  const { blockScroll, allowScroll } = useScrollBlock()

  useImperativeHandle(ref, () => ({
    close: () => {
      setIsOpen(false)
    },
    open: () => {
      setIsOpen(true)
    },
  }))

  // Close modal when page changes (e.g. if clicking 'Checkout' link in cart modal):
  const location = useLocation()
  useEffect(() => {
    setIsOpen(false)
  }, [location, setIsOpen])

  return (
    <Wrapper>
      <CSSTransition
        nodeRef={overlayRef}
        in={modalIsOpen}
        timeout={{ exit: transitionDuration }}
        mountOnEnter
        unmountOnExit
      >
        {(state) => (
          <Container
            isOpen={modalIsOpen}
            onRequestClose={() => setIsOpen(false)}
            style={customStyles}
            overlayElement={(props, contentElement) => (
              <Overlay {...props} state={state} ref={overlayRef}>
                {contentElement}
              </Overlay>
            )}
            contentElement={(props) => <Content {...props}>{children}</Content>}
            onAfterOpen={blockScroll}
            onAfterClose={allowScroll}
            closeTimeoutMS={transitionDuration}
          ></Container>
        )}
      </CSSTransition>

      {cloneElement(trigger, {
        onClick: () => {
          setIsOpen(!modalIsOpen)
        },
      })}
    </Wrapper>
  )
})

SlideOut.displayName = 'SlideOut'

SlideOut.propTypes = {
  trigger: PropTypes.node.isRequired,
  children: PropTypes.node,
}

export default SlideOut
