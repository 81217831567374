import structuredClone from '@ungap/structured-clone'
import { Formik, Form as FormikForm } from 'formik'
import PropTypes from 'prop-types'
import { useEffect, useRef } from 'react'
import toast from 'react-hot-toast'
import { down } from 'styled-breakpoints'
import styled from 'styled-components'
import { useMutation } from 'urql'
import * as Yup from 'yup'
import { HeaderL, linkStates, Body, SmallLetters } from '../_abstracts/Type'
import Button from '../_atoms/Button'
import Input, { InputLabel } from '../_atoms/Input'
import Container, { containerPadding } from '../_helpers/Container'
import { useUserContext } from '../auth/UserContext'
import Money, { formatMoney } from '../data/Money'
import Field from '../forms/Field'
import {
  SimpleFieldset,
  SimpleWrapper as FieldsetWrapper,
} from '../forms/Fieldset'
import Currency from '../inputs/Currency'
import { Icon, CarterCIcon } from '../_base/Icon'
import ButtonGroup from '../shared/ButtonGroup'
import ModalBase, { Close } from '../shared/Modal'
import Avatar from '../shared/Avatar'
import ProductStatCard from '../shared/ProductStatCard'

const CREATE_OFFER = `
  mutation CreateOffer($offer: OfferCreateInput!) {
    offerCreate(offer: $offer) {
      success
      offer {
        id
      }
    }
  }
`

const Modal = styled(ModalBase)`
  ${Close} {
    color: ${({ theme }) => theme.colors.white};
  }

  ${FieldsetWrapper} {
    padding-top: 22px;
    padding-bottom: 22px;
  }
`

const Title = styled.h1`
  ${HeaderL};
  position: relative;
  z-index: 2;
`

const Header = styled.div`
  padding: 35px;
  background-color: ${(props) => props.theme.colors.darkBlue};
  position: relative;
  color: ${(props) => props.theme.colors.white};
  text-align: center;
  overflow: hidden;

  &::before {
    content: '';
    height: calc(100% - 14px);
    width: calc(100% - 14px);
    border: 1px solid ${(props) => props.theme.colors.accent};
    border-radius: ${(props) => props.theme.radii};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }

  ${Icon} {
    width: 100px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 1;
    opacity: 0.3;
  }
`

const Wrapper = styled.div`
  padding: 22px 0;

  &:last-child {
    ${containerPadding}
    background-color: ${(props) => props.theme.colors.lightGrey};
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
`

const QuickOffers = styled.div`
  ${InputLabel} {
    display: block;
    margin-bottom: 10px;
  }
`

const Cancel = styled.button.attrs({ type: 'button' })`
  ${linkStates};
  margin-right: auto;
  font-size: 15px;
`

const Offer = styled.div`
  display: flex;
  background-color: ${(props) => props.theme.colors.goldTint};
  border: 1px solid ${(props) => props.theme.colors.darkBlue};
  align-items: center;
  padding: 13px;

  > *:not(:last-child) {
    margin-right: 13px;
  }

  *:nth-child(3) {
    margin-left: auto;
  }

  > *:first-child {
    height: 50px;
  }

  ${down('small')} {
    margin-left: 10px;
    margin-right: 10px;
    flex-wrap: wrap;
  }
`

const Item = styled.div`
  flex-grow: 1;
  max-width: 100px;

  ${down('small')} {
    text-align: end;
  }
`

const Up = styled.p`
  ${SmallLetters}
`

const Down = styled.p`
  ${Body}
  font-weight: 600;
`

const baseSchema = Yup.object().shape({
  toAccountId: Yup.string().required('Required'),
  itemId: Yup.string().required('Required'),
  amountOffered: Yup.object({
    amountCents: Yup.number()
      .min(1, 'Offer amount is required')
      .required('Required'),
    currencyIso: Yup.string().required('Required'),
  }),
  details: Yup.string()
    .nullable()
    .transform((value) => value || null),
  counterToOfferId: Yup.string()
    .nullable()
    .transform((value) => value || null),
})

export default function MakeOfferModal({
  buttonLabel,
  buttonVariant = 'secondary',
  buttonFullWidth = false,
  item,
  offer,
  onStateChange,
}) {
  const modalRef = useRef()
  const [state, createOffer] = useMutation(CREATE_OFFER)
  const { user } = useUserContext()
  const isSeller = user?.id === item.account.id
  const isCounterOffer = !!offer
  const price = item.listing?.askingPrice
    ? {
        amount: item.listing.askingPrice.amount,
        currency: item.listing.askingPrice.currency.iso,
      }
    : undefined

  const closeModal = () => {
    modalRef?.current?.close()
  }

  let Schema = baseSchema
  if (item.offerDeclineThreshold && price && !isSeller) {
    const minimum = price.amount * item.offerDeclineThreshold
    const message = `Seller does not accept offers below ${
      item.offerDeclineThreshold * 100
    }%, please enter a minimum of ${formatMoney(minimum, price.currency)}`

    Schema = Schema.shape({
      amountOffered: Yup.object({
        amountCents: Yup.number().min(minimum, message).required('Required'),
        currencyIso: Yup.string().required('Required'),
      }),
    })
  }

  async function handleSubmit(fields) {
    // Deep clone fields using structuredClone (native or polyfilled), otherwise modifying field values
    // for submission will affect Formik field references too
    const parsedFields = Schema.cast(structuredClone(fields), {
      strict: true,
    })

    // Convert offer amount price input value to cents
    parsedFields.amountOffered.amountCents *= 100

    const response = await createOffer({ offer: parsedFields })

    if (response?.data?.offerCreate?.success) {
      toast.success('Offer submitted', { id: 'offer' })
      onStateChange?.(response.data.offerCreate.offer)
      closeModal()
    }
  }

  useEffect(() => {
    if (state.fetching) {
      toast.loading('Submitting offer…', { id: 'offer' })
    } else if (state.error || state.data?.offerCreate?.success === false) {
      toast.error('Unable to submit offer', { id: 'offer' })
      console.error(state.error, state.data)
    }
  }, [state, onStateChange])

  // TODO: Set default currency using user settings
  const currency = price?.currency || 'USD'

  return (
    <Modal
      ref={modalRef}
      trigger={
        <Button variant={buttonVariant} fullWidth={buttonFullWidth}>
          {buttonLabel}
        </Button>
      }
    >
      <Header>
        <CarterCIcon />
        <Title>{isCounterOffer ? 'Counter Offer' : 'Make An Offer'}</Title>
      </Header>
      <Formik
        onSubmit={handleSubmit}
        validationSchema={Schema}
        initialValues={{
          itemId: item.id,
          toAccountId: isCounterOffer ? offer.fromAccount.id : item.account.id,
          amountOffered: { amountCents: '', currencyIso: currency },
          counterToOfferId: offer?.id,
          details: '',
        }}
      >
        {({ setFieldValue }) => {
          const setAmount = (amount) => {
            setFieldValue('amountOffered.currencyIso', currency)
            setFieldValue('amountOffered.amountCents', amount)
          }

          return (
            <FormikForm>
              <Container>
                <Wrapper>
                  <ProductStatCard
                    title={item.title}
                    price={price}
                    img={item.images[0]?.url}
                  />
                </Wrapper>
                {isCounterOffer && (
                  <Offer>
                    <Avatar size="small" user={offer.fromAccount} />
                    <b>{offer.fromAccount.username}’s offer:</b>
                    <Item>
                      <Up>Offer amount</Up>
                      <Down>
                        <Money
                          amount={offer.amountOffered.amount}
                          currency={offer.amountOffered.currency.iso}
                        />
                      </Down>
                    </Item>
                  </Offer>
                )}
                <SimpleFieldset legend="Shipping" hideLegend>
                  <Field width={price && 'half'}>
                    <Currency
                      name="amountOffered.amountCents"
                      currencyFieldName="amountOffered.currencyIso"
                      placeholder="Enter amount"
                      label={
                        isCounterOffer
                          ? 'Your counter offer'
                          : 'Your best offer'
                      }
                      required
                    />
                  </Field>
                  {price && (
                    <Field width="half">
                      <QuickOffers>
                        <InputLabel as="span">Quick offers</InputLabel>
                        <ButtonGroup fullWidth gap={12}>
                          <Button
                            onClick={() =>
                              setAmount(Math.ceil(price.amount * 0.95))
                            }
                            variant="small"
                          >
                            5% off
                          </Button>
                          <Button
                            onClick={() =>
                              setAmount(Math.ceil(price.amount * 0.9))
                            }
                            variant="small"
                          >
                            10% off
                          </Button>
                        </ButtonGroup>
                      </QuickOffers>
                    </Field>
                  )}
                  <Field>
                    <Input
                      inputAs="textarea"
                      name="details"
                      placeholder="Your message"
                      label="Would you like to attach a message?"
                    />
                  </Field>
                </SimpleFieldset>
              </Container>
              <Wrapper>
                <Cancel onClick={closeModal}>Cancel</Cancel>
                <Button disabled={state.fetching} variant="primaryDark" submit>
                  Send offer
                </Button>
              </Wrapper>
            </FormikForm>
          )
        }}
      </Formik>
    </Modal>
  )
}

MakeOfferModal.propTypes = {
  buttonLabel: PropTypes.node.isRequired,
  buttonVariant: PropTypes.string,
  buttonFullWidth: PropTypes.bool,
  item: PropTypes.object.isRequired,
  offer: PropTypes.object,
  onStateChange: PropTypes.func,
}
