import PropTypes from 'prop-types'
import styled from 'styled-components'
import createDOMPurify from 'dompurify'
import { linkStates } from '../_abstracts/Type'

export const Wrapper = styled.div`
  > * + * {
    margin-top: 1em;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 600;

    + * {
      margin-top: 0.5em;
    }
  }

  hr {
    margin-top: 32px;
    margin-bottom: 32px;
    color: ${(props) => props.theme.colors.blue20};
    opacity: 0.8;
  }

  ul,
  ol {
    padding-left: 1em;
  }

  ul {
    list-style-type: disc;

    ul {
      list-style-type: circle;
    }
  }

  ol {
    list-style-type: decimal;
  }

  li {
    + li {
      margin-top: 0.5em;
      margin-bottom: 0.5em;
    }
  }

  a {
    ${linkStates}
  }
`

let purifyWindow

if (typeof window === 'undefined') {
  const { JSDOM } = require('jsdom')
  purifyWindow = new JSDOM('').window
} else {
  purifyWindow = window
}

const DOMPurify = createDOMPurify(purifyWindow)

export const stripAllTags = (html) =>
  DOMPurify.sanitize(html, {
    ALLOWED_TAGS: [],
  })

export default function Html({ html, limited, stripAllTags, dangerousRaw }) {
  if (dangerousRaw) {
    // This setting should only be used for trusted content - for instance embedded content fields
    // in the CMS, where tags like scripts and iframes need to be supported
  } else if (stripAllTags) {
    html = DOMPurify.sanitize(html, {
      ALLOWED_TAGS: [],
    })
  } else if (limited) {
    html = DOMPurify.sanitize(html, {
      ALLOWED_TAGS: ['p', 'b', 'strong', 'ul', 'ol', 'li', 'i', 'em', 'u'],
    })
  } else {
    html = DOMPurify.sanitize(html, { USE_PROFILES: { html: true } })
  }

  return <Wrapper dangerouslySetInnerHTML={{ __html: html }}></Wrapper>
}

Html.propTypes = {
  html: PropTypes.string,
  limited: PropTypes.bool,
  stripAllTags: PropTypes.bool,
  dangerousRaw: PropTypes.bool,
}
