import { useState } from 'react'
import toast from 'react-hot-toast'
import { Link, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useMutation } from 'urql'
import { HeaderSansM, BodyS, Body, SmallLetters } from '../_abstracts/Type'
import { down } from 'styled-breakpoints'

import DateTime from '../data/DateTime'
import Money from '../data/Money'

import { Icon, EditIcon, DeleteIcon } from '../_base/Icon'

import Checkbox from '../shared/Checkbox'
import { InputContainer } from '../_atoms/Input'
import { Media, MediaImage } from '../_base/Media'
import DeleteItem from './DeleteItem'
import ButtonOpenAdmin from '../shared/ButtonOpenAdmin'

const UPDATE_ITEM = `
  mutation UpdateItemPrivacy($id: ID!, $item: ItemUpdateInput!) {
    itemUpdate(id: $id, item: $item) {
      success
      item {
        id
        privacy
        acceptingOffers
      }
    }
  }
`

const Wrapper = styled.div`
  padding: 26px 39px 26px 26px;

  ${down('small')} {
    padding: 13px;
  }
`

const Inner = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`

const Content = styled.div`
  flex-grow: 1;
  padding: 13px;

  ${down('small')} {
    order: 1;
  }
`

const Header = styled.h2`
  ${HeaderSansM}
  margin-bottom: 20px;
`

const Details = styled.div`
  background: ${(props) => props.theme.colors.lightGrey};
  padding: 10px;
  margin-top: 15px;
  max-width: 390px;
  border-radius: ${(props) => props.theme.radii};

  > * + * {
    padding-top: 5px;
  }
`

const Sub = styled.p`
  ${SmallLetters}
`

const Both = styled.p`
  ${Body}
`

const List = styled.ul`
  margin-top: 10px;
`

const Item = styled.li`
  border: 1px solid ${(props) => props.theme.colors.midGrey};
  padding: 10px;
  display: inline-block;
  margin-top: 10px;
  margin-right: 10px;
  min-width: 123px;
  border-radius: ${(props) => props.theme.radii};
`

const Up = styled.p`
  ${SmallLetters}
`

const Down = styled.p`
  ${Body}
  line-height: 1;
  margin-top: 5px;
`

const Image = styled.div`
  width: 230px;
  min-width: 230px;
  padding: 13px;
  border: 1px solid ${(props) => props.theme.colors.midGrey};
  border-radius: ${(props) => props.theme.radii};

  ${down('small')} {
    order: 0;
    width: 100%;
    max-width: 231px;
    margin-left: auto;
    margin-right: auto;
  }
`

const Fig = styled.div`
  margin-bottom: 15px;

  ${Media} {
    padding-bottom: 100%;
  }
`

const Checkers = styled.div`
  ${BodyS}
  color: ${(props) => props.theme.colors.xDarkGrey};
`

const Actions = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`

const Action = styled.button`
  border: 0;
  background: transparent;
  cursor: pointer;
  margin: 5px;

  &:hover {
    color: ${(props) => props.theme.colors.white};

    circle {
      fill: ${(props) => props.theme.colors.darkBlue};
    }
  }

  ${Icon} {
    width: 24px;
    height: 24px;
  }
`

export default function Summary({ item, priceHistory }) {
  const navigate = useNavigate()
  const image = item.images?.[0]
  const [, submitUpdateItem] = useMutation(UPDATE_ITEM)
  const [acceptingOffers, setAcceptingOffers] = useState(item.acceptingOffers)
  const [privacy, setPrivacy] = useState(item.privacy)

  async function updateItem(itemData, { successMessage }) {
    const updateItemPromise = submitUpdateItem({
      id: item.id,
      item: itemData,
    })

    const { data, error } = await toast.promise(
      updateItemPromise,
      {
        loading: 'Updating portfolio item…',
        success: successMessage,
        error: 'Unable to update portfolio item',
      },
      { id: 'item' }
    )

    if (error) {
      console.error(error)
    }

    if (data?.itemUpdate?.success) {
      setAcceptingOffers(data.itemUpdate.item.acceptingOffers)
      setPrivacy(data.itemUpdate.item.privacy)
    }
  }

  function toggleOffersAccepted(acceptingOffers) {
    updateItem(
      { acceptingOffers },
      {
        successMessage: `Portfolio item offers ${
          acceptingOffers ? 'allowed' : 'not allowed'
        }`,
      }
    )
  }

  function toggleItemPrivacy(isPrivate) {
    const privacy = isPrivate ? 'PRIVATE' : 'PUBLIC'
    const isAcceptingOffers = isPrivate ? false : acceptingOffers
    updateItem(
      { privacy, acceptingOffers: isAcceptingOffers },
      {
        successMessage: `Portfolio item is now ${privacy.toLocaleLowerCase()}`,
      }
    )
  }

  return (
    <Wrapper>
      <Inner>
        <Content>
          <Header>{item.title}</Header>

          {priceHistory && (
            <Details>
              <Sub>Estimated Value Range</Sub>
              <Both>
                <Money
                  amount={priceHistory.min}
                  currency="USD"
                  includeCurrency
                />{' '}
                -{' '}
                <Money
                  amount={priceHistory.max}
                  currency="USD"
                  includeCurrency
                />
              </Both>
            </Details>
          )}

          <List>
            <Item>
              <Up>Listing Date</Up>
              <Down>
                {(item.listing && (
                  <DateTime date={item.listing.createdAt} noTime />
                )) || <>-</>}
              </Down>
            </Item>
            <Item>
              <Up>Views</Up>
              <Down>{item.listing?.viewCount || <>-</>}</Down>
            </Item>
            <Item>
              <Up>Watchers</Up>
              <Down>{item.wishlistCount || <>-</>}</Down>
            </Item>
            <Item>
              <Up>Offers</Up>
              <Down>{item.offerCount || <>-</>}</Down>
            </Item>
          </List>
        </Content>

        <Image>
          {image && (
            <Fig>
              <Media>
                <MediaImage src={image.url} alt="" />
              </Media>
            </Fig>
          )}

          <Checkers>
            <InputContainer>
              <Checkbox
                label="Private"
                checked={privacy === 'PRIVATE'}
                onChange={(checked) => {
                  toggleItemPrivacy(checked)
                }}
              />
            </InputContainer>

            {privacy === 'PUBLIC' && (
              <InputContainer>
                <Checkbox
                  label="Allow Offers"
                  checked={acceptingOffers}
                  onChange={(checked) => {
                    toggleOffersAccepted(checked)
                  }}
                />
              </InputContainer>
            )}
          </Checkers>

          <Actions>
            <Action as={Link} to={`/profile/portfolio/${item.id}/edit`}>
              <EditIcon />
            </Action>

            <DeleteItem
              item={item}
              onDelete={() => navigate('/profile/portfolio')}
            >
              <Action as="button" type="button">
                <DeleteIcon />
              </Action>
            </DeleteItem>
          </Actions>
          <ButtonOpenAdmin item={item} />
        </Image>
      </Inner>
    </Wrapper>
  )
}

Summary.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    images: PropTypes.arrayOf(
      PropTypes.shape({ url: PropTypes.string.isRequired })
    ).isRequired,
    acceptingOffers: PropTypes.bool.isRequired,
    privacy: PropTypes.oneOf(['PUBLIC', 'PRIVATE']).isRequired,
    offerCount: PropTypes.number.isRequired,
    wishlistCount: PropTypes.number.isRequired,
    listing: PropTypes.shape({
      createdAt: PropTypes.instanceOf(Date).isRequired,
      viewCount: PropTypes.number.isRequired,
    }),
  }).isRequired,
  priceHistory: PropTypes.shape({
    latest: PropTypes.number.isRequired,
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
  }),
}
