import Meta from '../_helpers/Meta'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { useQuery } from 'urql'
import Container from '../_helpers/Container'
import Spacer from '../_helpers/Spacer'
import NotFound from '../404/NotFound'
import Html from '../data/Html'
import Blocks, { blocksQueryFields } from '../shared/Blocks'
import Cta from '../shared/Cta'
import Loader from '../shared/Loader'
import DateTime from '../data/DateTime'
import Share from '../shared/Share'
import Link from '../shared/Link'
import { Body, HeaderXL } from '../_abstracts/Type'

const GET_PAGE = `
  query GetPage($slug: [String]!) {
    entry(section: "blog", slug: $slug) {
      id
      title
      postDate
      url
      ... on blog_default_Entry {
        ${blocksQueryFields}
        seoTitle
        seoDescription
        heroBackgroundImage {
          id
          url(handle: "large")
        }
        cta {
          title
          ... on ctas_ctas_Entry {
            ctaText
            ctaBackgroundColor
            ctaLinks {
              ... on ctaLinks_item_BlockType {
                buttonTheme
                itemLink {
                  text
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`

const Header = styled.div`
  text-align: center;

  img {
    margin-right: auto;
    margin-left: auto;
  }

  h1 {
    ${HeaderXL};
    margin-top: 25px;
    padding-left: 25px;
    padding-right: 25px;
  }

  p {
    ${Body}
    margin-top: 5px;
    padding-left: 25px;
    padding-right: 25px;
  }
`

const ShareWrapper = styled.div`
  text-align: center;

  ul {
    margin-right: auto;
    margin-left: auto;
  }

  a {
    display: inline-block;
    margin-top: 20px;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
`

const Inner = styled.div``

const Content = styled.div`
  max-width: 1000px;
  padding: 32px 25px;
  margin-right: auto;
  margin-left: auto;
`

export default function Page() {
  const { slug } = useParams()
  const [{ data, fetching, error }] = useQuery({
    query: GET_PAGE,
    variables: { slug },
  })

  if (fetching) return <Loader background="#fff" />

  if (error) return <p>{error.message}</p>

  if (!data.entry) {
    return <NotFound />
  }

  const { entry } = data
  const cta = entry.cta[0]

  return (
    <>
      <Meta
        title={entry.seoTitle || entry.title}
        description={entry.seoDescription}
        url={entry.url}
      />

      <Header>
        <img src={entry.heroBackgroundImage[0]?.url} />
        <h1>{entry.title}</h1>
        <p>
          <DateTime date={new Date(entry.postDate)} noTime />
        </p>
      </Header>

      <Spacer size="40px" />

      <Container>
        {entry.blocks.length ? (
          <Inner>
            {entry.blocks.length ? (
              <Content>
                <Blocks blocks={entry.blocks} />
              </Content>
            ) : null}
          </Inner>
        ) : null}

        <Spacer size="40px" />

        <ShareWrapper>
          <Share
            url={entry.url}
            img={entry.heroBackgroundImage[0]?.url}
            text={entry.title}
          />
          <Link to="/blog">Back to blog</Link>
        </ShareWrapper>

        {cta && (
          <>
            <Spacer fromSize="40px" toSize="70px" />
            <Cta
              header={cta.title}
              text={<Html html={cta.ctaText} />}
              variant={cta.ctaBackgroundColor}
              buttons={cta.ctaLinks.map((item) => ({
                text: item.itemLink.text,
                to: item.itemLink.url,
                variant: item.buttonTheme,
              }))}
            />
          </>
        )}
        <Spacer fromSize="40px" toSize="80px" />
      </Container>
    </>
  )
}
