import toast from 'react-hot-toast'
import PropTypes from 'prop-types'
import { useMutation } from 'urql'
import Button from '../_atoms/Button'
import Popup from '../shared/Popup'

const DELETE_ITEM = `
  mutation DeleteItem($id: ID!) {
    itemRemove(id: $id) {
      success
    }
  }
`

export default function DeleteItem({ children, item, onDelete }) {
  const [, submitDeleteItem] = useMutation(DELETE_ITEM)

  async function deleteItem() {
    const deleteItemPromise = submitDeleteItem({
      id: item.id,
    })

    toast.promise(
      deleteItemPromise,
      {
        loading: 'Deleting portfolio item…',
        success: 'Portfolio item deleted',
        error: 'Unable to delete portfolio item',
      },
      { id: 'item' }
    )

    let response
    try {
      response = await deleteItemPromise

      if (response.error || response.data?.itemRemove?.success === false) {
        throw new Error(response.error?.toString())
      }
    } catch (error) {
      toast.error('Unable to delete portfolio item', { id: 'item' })
      console.error(error)
      return
    }

    if (response.data?.itemRemove?.success) {
      onDelete?.(item.id)
      console.log('Successfully deleted item')
    }
  }

  return (
    <Popup trigger={children}>
      <div>
        <p>Permanently delete &lsquo;{item.title}&rsquo;?</p>
        <Button onClick={deleteItem} variant="primaryDark">
          Delete
        </Button>
      </div>
    </Popup>
  )
}

DeleteItem.propTypes = {
  children: PropTypes.node.isRequired,
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  onDelete: PropTypes.func,
}
