import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { useQuery } from 'urql'
import Transition from '../_abstracts/Animation'
// import { useToggle } from '../hooks'
import Loader from '../shared/Loader'

const GET_ENTRIES = `
  query GetEntries {
    entries(section: "helpCenterPages", level: 1) {
      id
      title
      ... on helpCenterPages_sectionType_Entry {
        children {
          id
          title
          ... on helpCenterPages_category_Entry {
            id
            children {
              id
              title
              uri
            }
          }
        }
      }
    }
  }
`

const Wrapper = styled.ul`
  flex-shrink: 0;
  width: 290px;
`

const SectionWrapper = styled.li`
  margin-top: 12px;
  border: 1px solid ${({ theme }) => theme.colors.midGrey};
  border-radius: ${({ theme }) => theme.radii};

  &:first-child {
    margin-top: 0;
  }
`

const SectionHeader = styled.div`
  padding: 7px 18px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.midGrey};
  text-transform: capitalize;

  &:last-child {
    border-bottom: 0;
  }
`

const SectionItems = styled.ul`
  text-transform: capitalize;
`

const CategoryWrapper = styled.li`
  padding: 0 18px;
`

const CategoryHeader = styled.div`
  padding: 7px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.blueTint};
`

const CategoryItems = styled.ul`
  padding: 5px 0;
`

const Item = styled.li`
  padding: 7px 15px;
  font-size: 14px;

  a {
    ${Transition({ property: 'text-decoration-color, color' })};

    &:hover {
      color: ${({ theme }) => theme.colors.accent};
    }
  }
`

// TODO: Add sidebar section/subsection toggling

const Section = ({ title, children }) => {
  return (
    <SectionWrapper>
      <SectionHeader>{title}</SectionHeader>
      {children.length ? (
        <SectionItems>
          {children.map((entry) => (
            <Category key={entry.id} {...entry} />
          ))}
        </SectionItems>
      ) : undefined}
    </SectionWrapper>
  )
}

Section.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.arrayOf(PropTypes.object).isRequired,
}

const Category = ({ title, children }) => {
  return (
    <CategoryWrapper>
      <CategoryHeader>{title}</CategoryHeader>
      {children.length ? (
        <CategoryItems>
          {children.map((entry) => (
            <Item key={entry.id}>
              <Link to={`/${entry.uri}`}>{entry.title}</Link>
            </Item>
          ))}
        </CategoryItems>
      ) : undefined}
    </CategoryWrapper>
  )
}

Category.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default function () {
  const [result] = useQuery({ query: GET_ENTRIES })

  const { data, fetching, error } = result

  return (
    <Wrapper>
      {(fetching && <Loader />) ||
        (error && <p>{error.message}</p>) ||
        data.entries.map((entry) => <Section key={entry.id} {...entry} />)}
    </Wrapper>
  )
}
