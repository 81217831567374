import PropTypes from 'prop-types'
import { useState } from 'react'
import { Field, useFormikContext } from 'formik'

import A11yElement from '../_base/Accessibility'
import { HeaderS, linkStates } from '../_abstracts/Type'
import Transition from '../_abstracts/Animation'

import styled from 'styled-components'

export const Wrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  width: auto;
  border: 1px solid ${(props) => props.theme.colors.midGrey};
  border-radius: ${(props) => props.theme.radii};
`

const Inner = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  cursor: pointer;
  padding: 20px 25px;
  border-radius: inherit;
`

const CheckboxContainer = styled.div`
  min-height: 25px;
  font-size: 0;
`

const HiddenCheckbox = styled(Field).attrs({ type: 'checkbox' })`
  ${A11yElement};
`

const CheckboxLabel = styled.span`
  ${HeaderS};
  display: block;
  font-weight: 700;
  line-height: 1.3;
  color: ${(props) => props.theme.colors.darkBlue};

  ${CheckboxContainer} + & {
    margin-left: 8px;
  }

  a {
    ${linkStates}
  }
`

export const StyledCheckbox = styled.div`
  position: relative;
  display: inline-block;
  width: 24px;
  height: 24px;
  background: ${(props) =>
    props.checked ? props.theme.colors.darkBlue : props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.darkBlue};
  border-radius: ${(props) => props.theme.radii};
  ${Transition({ property: 'background-color, border' })};

  &::before {
    position: absolute;
    left: 0;
    top: 44%;
    height: 30%;
    width: 1px;
    background-color: ${(props) =>
      props.checked ? props.theme.colors.white : 'transparent'};
    content: '';
    transform: translateX(9px) rotate(-45deg);
    transform-origin: left bottom;
  }

  &::after {
    position: absolute;
    right: 0;
    bottom: 24%;
    height: 1px;
    width: 60%;
    background-color: ${(props) =>
      props.checked ? props.theme.colors.white : 'transparent'};
    content: '';
    transform: translateX(0) rotate(-45deg);
    transform-origin: left bottom;
  }
`

const Content = styled.div`
  font-size: 15px;
  color: ${({ theme }) => theme.colors.darkBlue};
  padding: 16px;
  border-top: 1px solid ${({ theme }) => theme.colors.midGrey};
  border-radius: inherit;
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;

  > * + * {
    margin-top: 8px;
  }
`

export default function CheckboxWrapped({
  children,
  label,
  hideLabel,
  resultCount,
  required,
  name,
  value,
}) {
  const [checked, setChecked] = useState(false)
  const formik = useFormikContext()

  return (
    <Wrapper>
      <Inner aria-label={label}>
        <CheckboxContainer>
          <HiddenCheckbox
            checked={checked}
            value={value}
            name={name}
            onChange={(event) => {
              setChecked(event.target.checked)

              formik.setFieldValue(name, event.target.checked)
            }}
            required={required}
          />
          <StyledCheckbox checked={checked} />
        </CheckboxContainer>

        {!hideLabel && (
          <CheckboxLabel resultCount={resultCount > 0}>
            <p>
              {label}
              {required && '*'}
            </p>
            {resultCount != null && <p>({resultCount})</p>}
          </CheckboxLabel>
        )}
      </Inner>
      {children && <Content>{children}</Content>}
    </Wrapper>
  )
}

CheckboxWrapped.propTypes = {
  children: PropTypes.node,
  label: PropTypes.node.isRequired,
  hideLabel: PropTypes.bool,
  resultCount: PropTypes.number,
  required: PropTypes.bool,
  wrapper: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
}
