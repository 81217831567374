import styled from 'styled-components'
import PropTypes from 'prop-types'
import { BodyS } from '../_abstracts/Type'

const Wrapper = styled.div`
  ${BodyS}
`

export default function Address({ heading, address }) {
  return (
    <Wrapper>
      <p>
        {heading && (
          <>
            <strong>{heading}</strong>
            <br />
          </>
        )}
        {address.firstName} {address.lastName}
        <br />
        {address.company && (
          <>
            {address.company}
            <br />
          </>
        )}
        {address.line1}
        <br />
        {address.line2 && (
          <>
            {address.line2}
            <br />
          </>
        )}
        {address.city}
        <br />
        {address.postalCode}
        <br />
        {address.region.name}
        <br />
        {address.country.name}
        {address.phone && (
          <>
            <br />
            {address.phone}
          </>
        )}
      </p>
    </Wrapper>
  )
}

Address.propTypes = {
  heading: PropTypes.string,
  address: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    company: PropTypes.string,
    phone: PropTypes.string,
    line1: PropTypes.string.isRequired,
    line2: PropTypes.string,
    city: PropTypes.string.isRequired,
    region: PropTypes.shape({ name: PropTypes.string.isRequired }).isRequired,
    country: PropTypes.shape({ name: PropTypes.string.isRequired }).isRequired,
    postalCode: PropTypes.string.isRequired,
  }).isRequired,
}
