import { css } from 'styled-components'

const ScrollBar = css`
  &::-webkit-scrollbar {
    width: 24px;
  }
  &::-webkit-scrollbar-thumb {
    border: 8px solid white;
    background-color: ${(props) => props.theme.colors.midGrey};
    border-radius: 20px;
  }
`

export const HiddenScrollBar = css`
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  //Chrome, Safari and Opera
  &::-webkit-scrollbar {
    display: none;
  }
`

export default ScrollBar
