import { useState } from 'react'
import styled from 'styled-components'
import { useQuery } from 'urql'
import { UserConsumer } from '../auth/UserContext'
import Html from '../data/Html'
import Loader from '../shared/Loader'
import NextPrevious from '../shared/NextPrevious'
import Preview from './Preview'

const THREADS_PER_PAGE = 20

const GET_MESSAGE_THREADS = `
  query GetMessageThreads($first: Int, $last: Int, $after: String, $before: String) {
    messageThreads(first: $first, last: $last, after: $after, before: $before) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }

      edges {
        node {
          id
          participants {
            id
            username
            avatarImage {
              id
              url
            }
          }
          latestMessage {
            id
            body
            createdAt
          }
          item {
            id
            title
          }
        }
      }
    }
  }
`

const Message = styled.p`
  padding: 30px;
  text-align: center;
`

const List = styled.ul`
  li {
    border-top: 1px solid ${(props) => props.theme.colors.grey};
  }
`

export default function MessageThreads() {
  const [pagination, setPagination] = useState({
    first: THREADS_PER_PAGE,
  })
  const [{ data, fetching, error }] = useQuery({
    query: GET_MESSAGE_THREADS,
    variables: pagination,
  })

  if (fetching) return <Loader height="200px" background="#fff" />
  const threads = data?.messageThreads?.edges?.map(({ node }) => node)
  if (error && !threads) return <Message>{error.message}</Message>

  if (!threads?.length) return <Message>No messages found</Message>

  const { pageInfo } = data.messageThreads

  return (
    <UserConsumer>
      {({ user }) => (
        <>
          <List>
            {threads.map((thread) => {
              const correspondent =
                thread.participants.find(
                  (participant) => participant.id !== user.id
                ) || thread.participants[0]

              return (
                <li key={thread.id}>
                  <Preview
                    message={{
                      id: thread.id,
                      summary: thread.latestMessage?.body && (
                        <Html html={thread.latestMessage.body} stripAllTags />
                      ),
                      offer: thread.offers?.length > 0,
                      saved: thread.starred,
                      date:
                        thread.latestMessage &&
                        new Date(thread.latestMessage.createdAt),
                      user: correspondent,
                    }}
                    item={thread.item}
                  />
                </li>
              )
            })}
          </List>
          <NextPrevious
            includeNext={pageInfo.hasNextPage || !!pagination.before}
            includePrevious={pageInfo.hasPreviousPage || !!pagination.after}
            onNext={() => {
              setPagination({
                after: pageInfo.endCursor,
                first: THREADS_PER_PAGE,
              })
            }}
            onPrevious={() => {
              setPagination({
                before: pageInfo.startCursor,
                last: THREADS_PER_PAGE,
              })
            }}
          />
        </>
      )}
    </UserConsumer>
  )
}
