const GET_GlOBAL_SET = `
  query GetSeo {
    globalSet(handle: "seo") {
      ... on seo_GlobalSet {
        id
        seoTitlePattern
        seoDefaultTitle
        seoDefaultDescription
        image {
          id
          url(transform: "large")
          width(transform: "large")
          height(transform: "large")
        }
      }
    }
  }
`

export default GET_GlOBAL_SET
