import PropTypes from 'prop-types'
import { UserConsumer } from '../auth/UserContext'

export default function Visibility({ children, userStatus }) {
  if (!userStatus) return children

  return (
    <UserConsumer>
      {({ user }) =>
        ((user && userStatus === 'loggedIn') ||
          (!user && userStatus === 'loggedOut')) &&
        children
      }
    </UserConsumer>
  )
}

Visibility.propTypes = {
  children: PropTypes.node.isRequired,
  userStatus: PropTypes.oneOf(['loggedIn', 'loggedOut', '']), // '' = either
}
