import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { BodyS, HeaderM } from '../_abstracts/Type'
import { Icon, CheckIcon, ArrowIcon } from '../_base/Icon'
import { down } from 'styled-breakpoints'

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  border: 1px solid ${(props) => props.theme.colors.midGrey};
  border-radius: ${(props) => props.theme.radii};
  margin-left: auto;
  margin-right: auto;
  color: ${(props) => props.theme.colors.darkBlue};
`

const Header = styled.div`
  text-align: center;
  padding: 11px 25px;
  border-bottom: 1px solid ${(props) => props.theme.colors.midGrey};
  background: ${(props) => props.theme.colors.lightGrey};
`

const Heading = styled.h2`
  ${HeaderM}
`

const Text = styled.p`
  ${BodyS}
  max-width: 508px;
  margin-right: auto;
  margin-left: auto;

  * + & {
    margin-top: 5px;
  }
`

const Item = styled.a`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 19px 35px;
  text-decoration: none;
  color: inherit;

  ${down('small')} {
    padding: 15px;
  }

  &:hover {
    box-shadow: ${(props) => props.theme.shadows.small};
  }

  & + & {
    border-top: 1px solid ${(props) => props.theme.colors.midGrey};
  }

  ${(props) =>
    props.checked
      ? css`
          pointer-events: none;
          color: ${(props) => props.theme.colors.teal};

          ${Check} {
            border-color: ${(props) => props.theme.colors.aqua};
            background-color: ${(props) => props.theme.colors.aquaTint};
          }

          ${Icon} {
            &:last-child {
              opacity: 0;
            }
          }
        `
      : css`
          cursor: pointer;

          ${Check} {
            border-color: transparent;
          }

          ${Icon} {
            &:first-child {
              opacity: 0;
            }
          }
        `}
`

const Check = styled.span`
  position: relative;
  display: inline-block;
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid;
  margin-left: 10px;

  ${down('small')} {
    width: 30px;
    min-width: 30px;
    height: 30px;
  }

  ${Icon} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 16px;

    &:last-child {
      transform: translate(-50%, -50%) scaleX(-1);
    }
  }
`

export default function Todos({ header, text, list }) {
  return (
    <Wrapper>
      {header && (
        <Header>
          {header && <Heading>{header}</Heading>}
          {text && <Text>{text}</Text>}
        </Header>
      )}

      {list.map((item, index) => {
        return (
          <Item key={index} link={item.link} checked={item.checked}>
            {item.text}

            <Check>
              <CheckIcon />
              <ArrowIcon />
            </Check>
          </Item>
        )
      })}
    </Wrapper>
  )
}

Todos.propTypes = {
  header: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      checked: PropTypes.bool.isRequired,
    })
  ).isRequired,
}
