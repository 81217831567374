import PropTypes from 'prop-types'
import styled from 'styled-components'
import { HeaderM, HeaderL, HeaderS } from '../_abstracts/Type'

export const Element = styled.div`
  h2 {
    ${HeaderL}
  }

  h3 {
    ${HeaderM}
  }

  h4 {
    ${HeaderS}
  }
`

const Inner = styled.h2``

const sizes = {
  large: 'h2',
  medium: 'h3',
  small: 'h4',
}

export default function Heading({ heading, headingSize }) {
  return (
    <Element>
      <Inner as={sizes[headingSize]} size={headingSize}>
        {heading}
      </Inner>
    </Element>
  )
}

Heading.propTypes = {
  heading: PropTypes.string,
  headingSize: PropTypes.oneOf(['large', 'medium', 'small']),
}
