import PropTypes from 'prop-types'
import { down } from 'styled-breakpoints'
import styled from 'styled-components'
import { Body, HeaderL } from '../_abstracts/Type'
import { useUserContext } from '../auth/UserContext'
import CraftForm from '../shared/CraftForm'

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.midGrey};
  border-radius: 5px;
  overflow: hidden;
  display: flex;

  ${down('medium')} {
    flex-wrap: wrap;
  }
`

const Content = styled.div`
  flex-shrink: 0;
  width: 40%;
  padding: 75px 50px;
  background-color: ${(props) => props.theme.colors.lightGrey};
  border-width: 0 1px 0 0;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.midGrey};

  ${down('medium')} {
    width: 100%;
    padding: 26px;
    border-width: 0 0 1px 0;
  }
`

const Heading = styled.h3`
  ${HeaderL};
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
`

const Text = styled.div`
  ${Body};
  color: ${({ theme }) => theme.colors.xDarkGrey};
`

const FormWrapper = styled.div`
  padding: 75px;

  ${down('medium')} {
    padding: 26px;
  }
`

export default function ContactForm({ form, text, heading, successMessage }) {
  const { user } = useUserContext()

  return (
    <Wrapper>
      {(heading || text) && (
        <Content>
          {heading && <Heading>{heading}</Heading>}
          {text && <Text>{text}</Text>}
        </Content>
      )}
      <FormWrapper>
        <CraftForm
          handle={form.handle}
          successMessage={successMessage}
          initialValues={{
            email: user ? user.email : '',
            contactName: user ? `${user.firstName} ${user.lastName}` : '',
            senderUsername: user ? user.username : '',
          }}
        />
      </FormWrapper>
    </Wrapper>
  )
}

ContactForm.propTypes = {
  form: PropTypes.shape({
    handle: PropTypes.string.isRequired,
  }).isRequired,
  heading: PropTypes.string,
  text: PropTypes.node,
  successMessage: PropTypes.node.isRequired,
}
