import { useState } from 'react'
import toast from 'react-hot-toast'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { useMutation } from 'urql'
import { Media, MediaImage } from '../_base/Media'
import { BodyS, HeaderSansXS, SmallLetters } from '../_abstracts/Type'
import {
  Icon,
  HeartInactiveIcon,
  DeleteIcon,
  NoViewIcon,
  ViewIcon,
  SettingsIcon,
} from '../_base/Icon'
import Money from '../data/Money'
import Transition from '../_abstracts/Animation'
import { useGlobals } from '../hooks'
import Modal from '../shared/Modal'
import Translation from '../shared/Translation'
import Button, { ButtonStyle } from '../_atoms/Button'
import TradeProcess from '../trade/Process'
import DeleteItem from './DeleteItem'
import { down } from 'styled-breakpoints'

const UPDATE_ITEM = `
  mutation UpdateItemPrivacy($id: ID!, $item: ItemUpdateInput!) {
    itemUpdate(id: $id, item: $item) {
      success
      item {
        id
        privacy
      }
    }
  }
`

export const minCardWidth = '250px'

const ViewButton = styled.div`
  margin-top: auto;
  opacity: 0;
  width: 100%;
  ${Transition({ property: 'opacity' })}

  ${ButtonStyle} {
    display: block;
    margin-top: 9px;
    margin-left: 0;
    margin-right: 0;
  }
`

export const CardWrapper = styled.div`
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border: 1px solid ${(props) => props.theme.colors.lightBtnGrey};
  border-radius: ${(props) => props.theme.radii};
  ${Transition({ property: 'border' })}
  text-decoration: none;

  &:hover {
    border: 1px solid ${(props) => props.theme.colors.accent};
    color: currentColor;

    ${ViewButton} {
      opacity: 1;
    }
  }
`

const ListingInfo = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 12px;
`

const Title = styled.h2`
  ${BodyS};
`

const Stats = styled.div`
  margin-top: 15px;
  display: none; /* hide until needed */

  > * + * {
    margin-top: 15px;
  }
`

const Price = styled.div`
  ${HeaderSansXS};
  font-weight: 600;
  line-height: 1;
`

const Label = styled.div`
  ${SmallLetters}
  color: ${(props) => props.theme.colors.accent};
  margin-bottom: 5px;
`

const ImageWrapper = styled(Media)`
  flex-shrink: 0;
  background-color: ${({ theme }) => theme.colors.grey};

  ${({ hasImage }) =>
    (hasImage &&
      css`
        &::before {
          display: block;
          padding-top: 100%;
          content: '';
        }
      `) ||
    css`
      // Tall enough that overlaid buttons are fully visible
      height: 215px;
    `}
`

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`

const Actions = styled.div`
  padding-top: 15px;
  margin-top: auto;

  ${down('small')} {
    padding-top: 0;
    margin-top: 10px;
  }

  ${ButtonStyle} {
    display: block;
    margin-top: 9px;
    margin-left: 0;
    margin-right: 0;
  }
`

const IconsList = styled.div`
  &:nth-child(1) {
    left: 0;

    p {
      left: 120%;
      transform: translate(0, -50%);
    }
  }

  &:nth-child(2) {
    right: 0;

    p {
      right: 120%;
      transform: translate(0, -50%);
    }
  }

  > * + * {
    margin-top: 5px;
  }
`

const IconItem = styled.button`
  border: 0;
  background-color: transparent;
  display: block;
  position: relative;

  ${(props) =>
    props.as !== 'span' &&
    css`
      &:hover {
        color: ${(props) => props.theme.colors.white};

        circle {
          fill: ${(props) => props.theme.colors.darkBlue};
        }
      }
    `}

  &:not(:hover) p {
    opacity: 0;
  }

  ${(props) =>
    props.partial &&
    css`
      span {
        display: none;
        padding-left: 2px;
      }

      &:not(:hover) p {
        padding-left: 2px;
        padding-right: 2px;
        opacity: 1;
      }

      &:hover span {
        display: inline-block;
      }
    `}

  ${Icon} {
    opacity: 1;
    display: block;
    width: 24px;
    height: 24px;
  }
`

const Hover = styled.p`
  font-size: 10px;
  position: absolute;
  top: 50%;
  background-color: ${(props) => props.theme.colors.white};
  display: inline-flex;
  border-radius: 20px;
  z-index: 1;
  padding: 2px 5px;
  white-space: nowrap;
  transition: 0.15s;
  color: ${(props) => props.theme.colors.black};
  pointer-events: none;
`

export default function Card({ item, refetchItems }) {
  const { profile } = useGlobals('profile')
  const image = item.images?.[0]
  const [, updateItem] = useMutation(UPDATE_ITEM)
  const [privacy, setPrivacy] = useState(item.privacy)

  async function toggleItemPrivacy() {
    const privacy = item.privacy === 'PUBLIC' ? 'PRIVATE' : 'PUBLIC'
    const updateItemPromise = updateItem({
      id: item.id,
      item: { privacy },
    })

    const { data, error } = await toast.promise(
      updateItemPromise,
      {
        loading: 'Updating portfolio item…',
        success: `Portfolio item is now ${privacy.toLocaleLowerCase()}`,
        error: 'Unable to update portfolio item',
      },
      { id: 'item' }
    )

    if (error) {
      console.error(error)
    }

    if (data?.itemUpdate?.success) {
      setPrivacy(data.itemUpdate.item.privacy)
    }
  }

  return (
    <CardWrapper>
      <ImageWrapper hasImage={!!image}>
        {image && <MediaImage src={image.url} alt={item.title} />}

        <Overlay>
          <IconsList>
            <IconItem as="span" partial={true} type="button">
              <HeartInactiveIcon />
              <Hover>
                ({item.wishlistCount})<span> admirers</span>
              </Hover>
            </IconItem>
          </IconsList>

          <IconsList>
            <IconItem type="button" onClick={toggleItemPrivacy}>
              {(privacy === 'PUBLIC' && (
                <>
                  <Hover>Hide</Hover>
                  <NoViewIcon />
                </>
              )) ||
                (privacy === 'PRIVATE' && (
                  <>
                    <Hover>Reveal</Hover>
                    <ViewIcon />
                  </>
                ))}
            </IconItem>
            <IconItem as={Link} to={`/profile/portfolio/${item.id}/edit`}>
              <Hover>Settings</Hover>
              <SettingsIcon />
            </IconItem>

            <DeleteItem item={item} onDelete={refetchItems}>
              <IconItem type="button">
                <Hover>Delete</Hover>
                <DeleteIcon />
              </IconItem>
            </DeleteItem>
          </IconsList>

          <ViewButton>
            <Button
              to={`/profile/portfolio/${item.id}`}
              variant="primaryDark"
              fullWidth
            >
              View
            </Button>

            <Button
              variant="secondary"
              fullWidth
              to={`/shop?brand=%5B"${item.brand.title}"%5D`}
            >
              Shop similar
            </Button>
          </ViewButton>
        </Overlay>
      </ImageWrapper>

      <ListingInfo>
        <Title>{item.title}</Title>
        {/* TODO: Hook up */}
        <Stats>
          <div>
            <Label>Estimated value</Label>
            <Price>
              <Money amount={8000} currency="USD" /> -{' '}
              <Money amount={9000} currency="USD" />
            </Price>
          </div>
        </Stats>

        <Actions>
          <Button
            to={`/selling/list-item/${item.id}`}
            variant="primaryDark"
            fullWidth
          >
            {item.listing ? 'Edit listing' : 'List for sale'}
          </Button>

          <Modal
            trigger={
              <Button variant="tertiary" fullWidth>
                <Translation>
                  {profile?.profilePortfolioTradeButton}
                </Translation>
              </Button>
            }
            size="standard"
          >
            <TradeProcess />
          </Modal>
        </Actions>
      </ListingInfo>
    </CardWrapper>
  )
}

Card.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    brand: PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
    title: PropTypes.string.isRequired,
    images: PropTypes.arrayOf(
      PropTypes.shape({ url: PropTypes.string.isRequired })
    ).isRequired,
    listing: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
    privacy: PropTypes.oneOf(['PUBLIC', 'PRIVATE']).isRequired,
    wishlistCount: PropTypes.number.isRequired,
  }).isRequired,
  refetchItems: PropTypes.func,
}
