import PropTypes from 'prop-types'
import Loader from '../shared/Loader'
import { useQuery } from 'urql'
import Video from './Video'

const UNFURL_QUERY = `
  query Unfurl($url: String!) {
    unfurlUrl(url: $url) {
      success
      data {
        embed
        aspect
      }
    }
  }
`

export default function Oembed({ url }) {
  const [{ data, fetching, error }] = useQuery({
    query: UNFURL_QUERY,
    variables: { url },
  })
  const failure = (
    <p>Unsupported URL. Please use YouTube or Vimeo for video hosting</p>
  )

  if (fetching) return <Loader />
  if (error) {
    console.error(error)
    return failure
  }
  if (!data?.unfurlUrl?.success) return failure

  return (
    <Video
      embed={data.unfurlUrl.data.embed}
      aspect={data.unfurlUrl.data.aspect}
    />
  )
}

Oembed.propTypes = {
  url: PropTypes.string.isRequired,
}
