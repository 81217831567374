import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { Icon } from '../_base/Icon'

const SquareStyle = css`
  padding: 3px 13px;
  border-radius: ${(props) => props.theme.radii};
  text-transform: uppercase;
  font-size: 12px;

  ${({ size }) =>
    size === 'small' &&
    css`
      padding: 5px;
      line-height: 1.2;
    `}
`

const RoundStyle = css`
  padding: 1px 10px;
  border-radius: 18px;
  font-size: 13px;

  ${({ size }) =>
    size === 'small' &&
    css`
      padding: 5px 8px;
      line-height: 1.2;
    `}
`

export const Wrapper = styled.span`
  color: white;
  width: fit-content;
  display: flex;
  align-items: center;
  text-align: center;

  ${(props) =>
    (props.variant === 'gold' &&
      css`
        background-color: ${(props) => props.theme.colors.gold};
        ${RoundStyle}
      `) ||
    (props.variant === 'blue' &&
      css`
        background-color: ${(props) => props.theme.colors.darkBlue};
        ${SquareStyle}
      `) ||
    (props.variant === 'accent' &&
      css`
        background-color: ${(props) => props.theme.colors.accent};
        ${RoundStyle}
      `) ||
    (props.variant === 'green' &&
      css`
        background-color: ${(props) => props.theme.colors.teal};
        ${RoundStyle}
      `) ||
    (props.variant === 'red' &&
      css`
        background-color: ${(props) => props.theme.colors.red};
        ${RoundStyle}
      `) ||
    (props.variant === 'carterRed' &&
      css`
        background-color: ${(props) => props.theme.colors.red};
        ${RoundStyle}
        font-weight: 700;
      `) ||
    (props.variant === 'cream' &&
      css`
        background-color: ${(props) => props.theme.colors.darkGoldTint};
        ${RoundStyle}
        font-weight: 700;
        color: ${(props) => props.theme.colors.red};
      `)}
`

const IconWrapper = styled.span`
  position: relative;
  width: 15px;
  flex-shrink: 0;
  display: block;
  margin-left: 3px;

  ${Icon} {
    min-width: 24px;
    position: absolute;
    transform: translate(-50%, -50%);
    margin-left: 3px;
  }
`

export default function Roundel({ variant, icon, children, size }) {
  return (
    <Wrapper variant={variant} size={size}>
      {icon && <IconWrapper>{icon}</IconWrapper>}
      {children}
    </Wrapper>
  )
}

Roundel.defaultProps = {
  size: 'medium',
}

Roundel.propTypes = {
  variant: PropTypes.string.isRequired,
  icon: PropTypes.node,
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(['small', 'medium']),
}
