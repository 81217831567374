import PropTypes from 'prop-types'
import styled from 'styled-components'
import Button, { ButtonStyle } from '../_atoms/Button'
import Money from '../data/Money'
import Table from '../shared/Table'

const Message = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  ${ButtonStyle} {
    margin-top: 10px;
  }
`

function NoResults() {
  return (
    <Message>
      <p>No shipping methods</p>
      <Button to="/profile/settings/shipping/new" variant="primaryDark">
        New shipping method
      </Button>
    </Message>
  )
}

export const MoneyPropTypeShape = PropTypes.shape({
  amountCents: PropTypes.number.isRequired,
  currency: PropTypes.shape({
    iso: PropTypes.string.isRequired,
  }).isRequired,
})

function MoneyProxy({ amount, fallback }) {
  if (!amount) {
    return <p>{fallback}</p>
  }

  return (
    <Money amount={amount.amountCents / 100} currency={amount.currency.iso} />
  )
}

MoneyProxy.propTypes = {
  amount: MoneyPropTypeShape,
  fallback: PropTypes.string.isRequired,
}

function Actions({ id }) {
  return (
    <Button
      to={`/profile/settings/shipping/${id}`}
      variant="small"
      style={{ verticalAlign: 'middle' }}
    >
      Edit
    </Button>
  )
}

Actions.propTypes = {
  id: PropTypes.string.isRequired,
}

export default function MethodsList({ methods }) {
  if (!methods.length) return <NoResults />

  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>Ref</th>
            <th>Name</th>
            <th>Currency</th>
            <th>Country</th>
            <th>Regions</th>
            <th>Max Price</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {methods.map((method, key) => (
            <tr key={`method__${key}`}>
              <td>{method.ref}</td>
              <td>{method.name}</td>
              <td>{method.currency}</td>
              <td>{method.country}</td>
              <td>{method.regions.join(', ')}</td>
              <td>
                <MoneyProxy amount={method.maxPrice} fallback="n/a" />
              </td>
              <td>
                <Actions id={method.ref} />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  )
}

MethodsList.propTypes = {
  methods: PropTypes.arrayOf(
    PropTypes.shape({
      ref: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      currency: PropTypes.string.isRequired,
      country: PropTypes.string.isRequired,
      regions: PropTypes.arrayOf(PropTypes.string.isRequired),
      maxPrice: MoneyPropTypeShape,
    })
  ),
}
