import { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import styled from 'styled-components'
import { useQuery } from 'urql'

import NextPrevious from '../shared/NextPrevious'
import Tabs from '../shared/Tabs'
import Subheader from '../shared/Subheader'
import HorizontalProductCard from '../shared/HorizontalProductCard'
import RecentlyViewedCarousel from '../shared/RecentlyViewedCarousel'
import { Formik } from 'formik'
import Input from '../_atoms/Input'
import Form from '../_atoms/Form'
import Button, { ButtonStyle } from '../_atoms/Button'

import { useCartContext } from '../cart/CartContext'

const Wrapper = styled.div`
  flex-grow: 1;
  background-color: ${(props) => props.theme.colors.xLightGrey};
`

const Inner = styled.div`
  max-width: 1310px;
  margin-left: auto;
  margin-right: auto;
  padding: 25px;
`

const List = styled.div`
  padding: 20px;
`

const tabsArgs = {
  tabs: [
    { title: 'My Saved Searches', url: '/my-saved-searches', end: true },
    { title: 'Wishlist', url: '/wishlist' },
    { title: 'Recently Viewed', url: '/recently-viewed', end: true },
  ],
}

const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${ButtonStyle} {
    margin-top: 10px;
  }
`

const Message = styled.p`
  text-align: center;
`

const WISHLIST_QUERY = `
  query Wishlist($first: Int, $last: Int, $after: String, $before: String, $orderId: [ID!]) {
    wishlistItems(first: $first, last: $last, after: $after, before: $before) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          item {
            id
            title
            acceptingOffers
            offerDeclineThreshold
            inViewerWishlist

            images {
              url(size: "orders")
            }

            account {
              id
              username
              isPrivateSeller
              isProfessionalSeller
            }

            listing {
              id

              inViewerCart(orderId: $orderId)
              isAvailable

              askingPrice {
                amount
                currency { iso }
              }
            }
          }
        }
      }
    }
  }
`

const DEFAULT_ITEMS_PER_PAGE = 12

export default function WishlistFeedPage() {
  const [perPage, setPerPage] = useState(DEFAULT_ITEMS_PER_PAGE)
  const [pagination, setPagination] = useState({
    first: DEFAULT_ITEMS_PER_PAGE,
  })
  const { openOrderIds } = useCartContext()

  const [{ data, fetching, error }, reexecuteQuery] = useQuery({
    query: WISHLIST_QUERY,
    variables: { ...pagination, orderId: openOrderIds },
  })

  return (
    <>
      <Helmet>
        <title>Wishlist</title>
      </Helmet>
      <Wrapper>
        <Inner>
          <Tabs {...tabsArgs}>
            <Subheader header="My Wishlist" border>
              <>
                <Formik initialValues={{}}>
                  <Form>
                    <Input
                      inline={true}
                      label="Per page"
                      name="pp"
                      inputAs="select"
                      value={perPage}
                      onChange={(event) => {
                        const perPage = parseInt(event.target.value, 10)

                        setPerPage(perPage)
                        setPagination({ first: perPage })
                      }}
                    >
                      <option value="12">12</option>
                      <option value="24">24</option>
                      <option value="48">48</option>
                    </Input>
                  </Form>
                </Formik>

                {/* TODO
              <Formik initialValues={{}}>
                <Form>
                  <Input
                    inline={true}
                    label="Date"
                    name="date"
                    inputAs="select"
                  >
                    <option value="Newest-oldest">Newest-oldest</option>
                  </Input>
                </Form>
              </Formik>
              */}
              </>
            </Subheader>

            <List>
              {(fetching && <Message>Loading…</Message>) ||
                (error && (
                  <MessageWrapper>
                    <Message>An error occurred.</Message>
                    <Button onClick={() => reexecuteQuery()} type="button">
                      Retry?
                    </Button>
                  </MessageWrapper>
                )) ||
                ((!data?.wishlistItems?.edges ||
                  !data.wishlistItems.edges.length) && (
                  <Message>No items found</Message>
                )) ||
                data.wishlistItems.edges.map(({ node }) => (
                  <HorizontalProductCard
                    key={node.id}
                    item={node.item}
                    linkType={node.item.listing ? 'listing' : 'item'}
                  />
                ))}
            </List>

            <NextPrevious
              includeNext={
                data?.wishlistItems?.pageInfo?.hasNextPage ||
                !!pagination.before
              }
              includePrevious={
                data?.wishlistItems?.pageInfo?.hasPreviousPage ||
                !!pagination.after
              }
              onNext={() => {
                setPagination({
                  after: data.wishlistItems.pageInfo.endCursor,
                  first: perPage,
                })
              }}
              onPrevious={() => {
                setPagination({
                  before: data.wishlistItems.pageInfo.startCursor,
                  last: perPage,
                })
              }}
            />
          </Tabs>
        </Inner>

        <RecentlyViewedCarousel theme="grey" />
      </Wrapper>
    </>
  )
}

WishlistFeedPage.propTypes = {}
