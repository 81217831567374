import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { HeaderL, Body } from '../_abstracts/Type'
import { Icon, CarterCIcon } from '../_base/Icon'
import Button, { ButtonStyle } from '../_atoms/Button'

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  color: ${(props) => props.theme.colors.white};
  border-radius: ${(props) => props.theme.radii};
  padding: 12px;

  ${Icon} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: calc(100% - 80px);
    max-height: 175px;
    opacity: 0.1;

    [data-shape='shadow'] {
      display: none;
    }
  }

  ${ButtonStyle} {
    display: inline-block;
    margin: 5px;
    max-width: 100%;
    min-width: 250px;
  }

  ${(props) =>
    (props.variant === 'blue' &&
      css`
        background: ${(props) => props.theme.colors.darkBlue};

        ${Icon} {
          [data-shape='c'] {
            fill: ${({ theme }) => theme.colors.white};
          }

          [data-shape='dots'] {
            fill: ${({ theme }) => theme.colors.darkBlue};
          }
        }
      `) ||
    (props.variant === 'accent' &&
      css`
        background: ${(props) => props.theme.colors.accent};

        ${WrapperInner} {
          border-color: ${(props) => props.theme.colors.white};
        }

        ${Icon} {
          [data-shape='dots'] {
            fill: ${({ theme }) => theme.colors.accent};
          }
        }
      `) ||
    (props.variant === 'gold' &&
      css`
        background: ${(props) => props.theme.colors.gold};

        ${WrapperInner} {
          border-color: #d6a476;
        }

        ${Icon} {
          opacity: 0.07;

          [data-shape='dots'] {
            fill: ${({ theme }) => theme.colors.gold};
          }
        }
      `)}
`

const WrapperInner = styled.div`
  position: relative;
  border: 1px solid ${(props) => props.theme.colors.accent};
  border-radius: ${(props) => props.theme.radii};
  padding: 35px 0;
  text-align: center;
`

const Header = styled.h2`
  ${HeaderL};
  margin-bottom: 15px;
`

const Text = styled.div`
  ${Body};
  margin-bottom: 15px;
  max-width: 750px;
  margin-left: auto;
  margin-right: auto;
`

export default function Cta({ header, text, buttons, variant = 'blue' }) {
  return (
    <Wrapper variant={variant}>
      <CarterCIcon />
      <WrapperInner>
        <Header>{header}</Header>
        {text && <Text>{text}</Text>}
        {buttons.map((item, index) => {
          return (
            <Button key={index} variant={item.variant} to={item.to}>
              {item.text}
            </Button>
          )
        })}
      </WrapperInner>
    </Wrapper>
  )
}

Cta.propTypes = {
  header: PropTypes.string.isRequired,
  text: PropTypes.node,
  variant: PropTypes.oneOf(['blue', 'gold', 'accent']),
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      variant: PropTypes.oneOf([
        'primaryLight',
        'primaryDark',
        'secondary',
        'tertiary',
      ]).isRequired,
      text: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
    })
  ).isRequired,
}
