import PropTypes from 'prop-types'
import toast from 'react-hot-toast'
//import { useUserContext } from '../auth/UserContext'

export default function ZendeskButton({ children }) {
  // const { user } = useUserContext()

  function handleClick(event) {
    event.preventDefault()
    event.stopPropagation()

    if (window.fcWidget) {
      window.fcWidget.open()
    } else {
      toast.error(
        'Cannot load chat. Please use the chat button in the bottom left to contact us'
      )
    }
    return false
  }

  return <span onClick={handleClick}>{children}</span>
}

ZendeskButton.propTypes = {
  children: PropTypes.node.isRequired,
  message: PropTypes.string,
}
