import PropTypes from 'prop-types'
import { useMemo } from 'react'
import styled from 'styled-components'
import { useQuery } from 'urql'
import { HeaderS } from '../_abstracts/Type'
import Button, { ButtonStyle } from '../_atoms/Button'
import Toggles, { Wrapper as TogglesWrapper } from '../feed/Toggles'
import { SAVED_SEARCHES } from '../feed/MyFeedCustomizePage'
import Loader from '../shared/Loader'

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: ${(props) => props.theme.radii};
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.midGrey};
  max-width: 700px;
  margin-right: auto;
  margin-left: auto;

  ${TogglesWrapper} {
    border-top: 0;
  }
`

const Header = styled.h2`
  ${HeaderS}
  border-bottom: 1px solid ${(props) => props.theme.colors.midGrey};
  background-color: ${({ theme }) => theme.colors.lightGrey};
  width: 100%;
  padding: 5px;
  text-align: center;

  ${ButtonStyle} {
    margin: 10px;
    width: 100%;
    max-width: 180px;
  }
`

const PredefinedSearches = ({ header }) => {
  const context = useMemo(
    () => ({ additionalTypenames: ['ListingSavedSearch'] }),
    []
  )

  const [{ fetching, data, error }] = useQuery({
    query: SAVED_SEARCHES,
    context,
  })

  if (fetching) return <Loader background="#fff" />
  if (error) return <p>Error loading saved searches</p>

  const predefinedToggles = data.preDefinedSavedSearches.map(
    ({ title, searchLink }) => {
      const url = new URL(searchLink)
      const params = new URLSearchParams(url.search)
      const { keywords, ...filters } = Object.fromEntries(params.entries())

      return {
        label: title,
        query: {
          keywords,
          filters: Object.entries(filters).map(([key, value]) => ({
            key,
            value,
          })),
        },
        savedSearchId: data.listingSavedSearches.find((s) => {
          if (s.keywords !== (keywords || null)) return false
          if ((s.filters?.length || 0) !== Object.keys(filters).length)
            return false

          return s.filters.every((f) => filters[f.key] === f.value)
        })?.id,
      }
    }
  )

  return <Toggles header={header} list={predefinedToggles} />
}

PredefinedSearches.propTypes = {
  header: PropTypes.string.isRequired,
}

export default function Tags({ header }) {
  return (
    <Wrapper>
      <Header>
        <Button to="/profile" variant="secondary">
          My Profile
        </Button>
        <Button to="/my-saved-searches" variant="primaryLight">
          My saved searches
        </Button>
      </Header>

      <PredefinedSearches header={header} />
    </Wrapper>
  )
}

Tags.propTypes = {
  header: PropTypes.string.isRequired,
}
