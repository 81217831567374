import styled from 'styled-components'
import PoweredByTnag from '../icons/powered-by-tnag.svg'
import CarterC from '../icons/carter-c.svg'
import Graph from '../icons/graph.svg'
import Check from '../icons/check.svg'
import Close from '../icons/close.svg'
import Cross from '../icons/cross.svg'
import Delete from '../icons/delete.svg'
import Do from '../icons/do.svg'
import Dont from '../icons/dont.svg'
import Stock from '../icons/stock.svg'
import Download from '../icons/download.svg'
import Edit from '../icons/edit.svg'
import HeartInactive from '../icons/heartInactive.svg'
import Reply from '../icons/reply.svg'
import Star from '../icons/star.svg'
import View from '../icons/view.svg'
import Settings from '../icons/settings.svg'
import TimeMoney from '../icons/time-money.svg'
import Shield from '../icons/shield.svg'
import NoMoney from '../icons/no-money.svg'
import Warning from '../icons/warning.svg'
import Public from '../icons/public.svg'
import Private from '../icons/private.svg'
import OfferUnread from '../icons/offerUnread.svg'
import TradeUnread from '../icons/tradeUnread.svg'
import NoView from '../icons/notVisible.svg'
import ArrowNext from '../icons/arrowNext.svg'
import ArrowPrev from '../icons/arrowPrev.svg'
import Arrow from '../icons/arrow.svg'
import Search from '../icons/search.svg'
import Share from '../icons/32px/share.svg'
import Basket from '../icons/32px/basket.svg'
import Notifications from '../icons/32px/notifications.svg'
import Mail from '../icons/32px/mail.svg'
import Profile from '../icons/32px/profile.svg'
import Delivery from '../icons/32px/delivery.svg'
import Heart from '../icons/32px/heart.svg'
import Tel from '../icons/32px/telephone.svg'
import Camera from '../icons/32px/camera.svg'
import Portfolio from '../icons/32px/portfolio.svg'
import SavedSearches from '../icons/32px/saved-searches.svg'
import ArrowDown from '../icons/arrowDropdown.svg'
import Youtube from '../icons/socials/youtube.svg'
import Twitter from '../icons/socials/twitter.svg'
import Instagram from '../icons/socials/instagram.svg'
import Facebook from '../icons/socials/facebook.svg'
import Pin from '../icons/pin.svg'
import Trade from '../icons/trade.svg'
import Offer from '../icons/offer.svg'
import Dropdown from '../icons/dropdownArrow.svg'
import Listing from '../icons/listingsIcon.svg'
import Email from '../icons/socials/email.svg'
import Link from '../icons/socials/link.svg'
import Spiky from '../icons/spiky.svg'
import ReplyBare from '../icons/replyBare.svg'
import Trash from '../icons/trash.svg'
import HeartBare from '../icons/heartBare.svg'
import StarBare from '../icons/starBare.svg'
import Marker from '../icons/marker.svg'
import Stripe from '../icons/payment/stripe.svg'
import CreditCard from '../icons/payment/creditCard.svg'
import Paypal from '../icons/payment/paypal.svg'
import Appraisal from '../icons/appraisal.svg'
import Shipment from '../icons/shipment.svg'
import Authenticated from '../icons/authenticated.svg'
import Truck from '../icons/truck.svg'
import Box from '../icons/box.svg'
import Upload from '../icons/upload.svg'
import Return from '../icons/return.svg'
import Cart from '../icons/cart.svg'
import Flag from '../icons/flag.svg'
import Flag2 from '../icons/flag2.svg'
import Landscape from '../icons/landscape.svg'
import Clipboard from '../icons/clipboard.svg'
import Verified from '../icons/verified.svg'
import Plus from '../icons/plus.svg'

// 80px icons:
import Checklist from '../icons/80px/checklist.svg'
import Communicate from '../icons/80px/communicate.svg'
import Covered1 from '../icons/80px/covered-1.svg'
import Covered2 from '../icons/80px/covered-2.svg'
import Description from '../icons/80px/description.svg'
import Details from '../icons/80px/details.svg'
import NetWorth from '../icons/80px/net-worth.svg'
import Offer1 from '../icons/80px/offer-1.svg'
import Offer2 from '../icons/80px/offer-2.svg'
import Origin from '../icons/80px/origin.svg'
import Packing from '../icons/80px/packing.svg'
import Payment from '../icons/80px/payment.svg'
import Photography from '../icons/80px/photography.svg'
import Popularity from '../icons/80px/popularity.svg'
import Price from '../icons/80px/price.svg'
import Returns from '../icons/80px/returns.svg'
import SeeWithEase from '../icons/80px/see-with-ease.svg'
import Shipping from '../icons/80px/shipping.svg'
import Submit from '../icons/80px/submit.svg'
import Trade1 from '../icons/80px/trade-1.svg'
import Trade2 from '../icons/80px/trade-2.svg'
import Trade3 from '../icons/80px/trade-3.svg'
import Trophy from '../icons/80px/trophy.svg'
import Unfulfilled1 from '../icons/80px/unfulfilled-1.svg'
import Unfulfilled2 from '../icons/80px/unfulfilled-2.svg'
import VacationMode from '../icons/80px/vacation-mode.svg'
import Views from '../icons/80px/views.svg'
import Fees from '../icons/80px/fees.svg'
import Time from '../icons/80px/time.svg'
import Lock from '../icons/80px/lock.svg'
import Envelope from '../icons/80px/envelope.svg'
import WarningTri from '../icons/80px/warning.svg'
import Appraisal2 from '../icons/80px/appraisal-2.svg'
import Shipment2 from '../icons/80px/shipment-2.svg'
import Authenticated2 from '../icons/80px/authenticated-2.svg'

// Payment icons:
import Amex from '../icons/payment/american-express.svg'
import Discover from '../icons/payment/discover.svg'
import Mastercard from '../icons/payment/mastercard.svg'
import Visa from '../icons/payment/visa.svg'

// WYSIWYG icons:
import Bold from '../icons/wysiwyg/bold.svg'
import Italic from '../icons/wysiwyg/italic.svg'
import Underline from '../icons/wysiwyg/underline.svg'
import BulletList from '../icons/wysiwyg/bullet-list.svg'
import OrderedList from '../icons/wysiwyg/ordered-list.svg'
// import { Wrapper } from '../shared/Checkbox'

export const Icon = styled.i`
  position: relative;
  display: block;
  max-width: 100%;
  overflow: visible;

  // Transparent layer above SVG to prevent event bubbling/delegation issues when clicking SVG.
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
  }

  svg {
    /* position: relative; */
    display: block;
    width: 100%;
    height: 100%;
  }
`

function withWrapper(WrappedIcon) {
  // eslint-disable-next-line react/display-name, react/prop-types
  return ({ className }) => (
    // eslint-disable-next-line react/prop-types
    <Icon className={className}>
      <WrappedIcon />
    </Icon>
  )
}

export const CarterCIcon = withWrapper(CarterC)
export const MarkerIcon = withWrapper(Marker)
export const CheckIcon = withWrapper(Check)
export const GraphIcon = withWrapper(Graph)
export const StockIcon = withWrapper(Stock)
export const CameraIcon = withWrapper(Camera)
export const CloseIcon = withWrapper(Close)
export const CrossIcon = withWrapper(Cross)
export const DeleteIcon = withWrapper(Delete)
export const DoIcon = withWrapper(Do)
export const SettingsIcon = withWrapper(Settings)
export const DontIcon = withWrapper(Dont)
export const DownloadIcon = withWrapper(Download)
export const EditIcon = withWrapper(Edit)
export const HeartInactiveIcon = withWrapper(HeartInactive)
export const ReplyIcon = withWrapper(Reply)
export const StarIcon = withWrapper(Star)
export const ViewIcon = withWrapper(View)
export const TimeMoneyIcon = withWrapper(TimeMoney)
export const ShieldIcon = withWrapper(Shield)
export const NoMoneyIcon = withWrapper(NoMoney)
export const NoViewIcon = withWrapper(NoView)
export const WarningIcon = withWrapper(Warning)
export const PublicIcon = withWrapper(Public)
export const PrivateIcon = withWrapper(Private)
export const OfferUnreadIcon = withWrapper(OfferUnread)
export const TradeUnreadIcon = withWrapper(TradeUnread)
export const ArrowNextIcon = withWrapper(ArrowNext)
export const ArrowPrevIcon = withWrapper(ArrowPrev)
export const ArrowIcon = withWrapper(Arrow)
export const ShareIcon = withWrapper(Share)
export const BasketIcon = withWrapper(Basket)
export const NotificationsIcon = withWrapper(Notifications)
export const PortfolioIcon = withWrapper(Portfolio)
export const SavedSearchesIcon = withWrapper(SavedSearches)
export const MailIcon = withWrapper(Mail)
export const ProfileIcon = withWrapper(Profile)
export const DeliveryIcon = withWrapper(Delivery)
export const HeartIcon = withWrapper(Heart)
export const TelIcon = withWrapper(Tel)
export const SearchIcon = withWrapper(Search)
export const ArrowDownIcon = withWrapper(ArrowDown)
export const YoutubeIcon = withWrapper(Youtube)
export const TwitterIcon = withWrapper(Twitter)
export const InstagramIcon = withWrapper(Instagram)
export const FacebookIcon = withWrapper(Facebook)
export const PinIcon = withWrapper(Pin)
export const TradeIcon = withWrapper(Trade)
export const OfferIcon = withWrapper(Offer)
export const DropdownIcon = withWrapper(Dropdown)
export const ListingsIcon = withWrapper(Listing)
export const EmailIcon = withWrapper(Email)
export const LinkIcon = withWrapper(Link)
export const SpikyIcon = withWrapper(Spiky)
export const ReplyBareIcon = withWrapper(ReplyBare)
export const TrashIcon = withWrapper(Trash)
export const HeartBareIcon = withWrapper(HeartBare)
export const StarBareIcon = withWrapper(StarBare)
export const UploadIcon = withWrapper(Upload)
export const StripeIcon = withWrapper(Stripe)
export const CreditCardIcon = withWrapper(CreditCard)
export const PaypalIcon = withWrapper(Paypal)
export const ShipmentIcon = withWrapper(Shipment)
export const AppraisalIcon = withWrapper(Appraisal)
export const AuthenticatedIcon = withWrapper(Authenticated)
export const TruckIcon = withWrapper(Truck)
export const BoxIcon = withWrapper(Box)
export const ReturnIcon = withWrapper(Return)
export const FlagIcon = withWrapper(Flag)
export const Flag2Icon = withWrapper(Flag2)
export const CartIcon = withWrapper(Cart)
export const LandscapeIcon = withWrapper(Landscape)
export const ClipboardIcon = withWrapper(Clipboard)
export const VerifiedIcon = withWrapper(Verified)
export const PlusIcon = withWrapper(Plus)

// 80px icons:
export const PoweredByTnagIcon = withWrapper(PoweredByTnag)
export const ChecklistIcon = withWrapper(Checklist)
export const CommunicateIcon = withWrapper(Communicate)
export const Covered1Icon = withWrapper(Covered1)
export const Covered2Icon = withWrapper(Covered2)
export const DescriptionIcon = withWrapper(Description)
export const DetailsIcon = withWrapper(Details)
export const NetWorthIcon = withWrapper(NetWorth)
export const Offer1Icon = withWrapper(Offer1)
export const Offer2Icon = withWrapper(Offer2)
export const OriginIcon = withWrapper(Origin)
export const PackingIcon = withWrapper(Packing)
export const PaymentIcon = withWrapper(Payment)
export const PhotographyIcon = withWrapper(Photography)
export const PopularityIcon = withWrapper(Popularity)
export const PriceIcon = withWrapper(Price)
export const ReturnsIcon = withWrapper(Returns)
export const SeeWithEaseIcon = withWrapper(SeeWithEase)
export const ShippingIcon = withWrapper(Shipping)
export const SubmitIcon = withWrapper(Submit)
export const Trade1Icon = withWrapper(Trade1)
export const Trade2Icon = withWrapper(Trade2)
export const Trade3Icon = withWrapper(Trade3)
export const TrophyIcon = withWrapper(Trophy)
export const Unfulfilled1Icon = withWrapper(Unfulfilled1)
export const Unfulfilled2Icon = withWrapper(Unfulfilled2)
export const VacationModeIcon = withWrapper(VacationMode)
export const ViewsIcon = withWrapper(Views)
export const FeesIcon = withWrapper(Fees)
export const TimeIcon = withWrapper(Time)
export const LockIcon = withWrapper(Lock)
export const EnvelopeIcon = withWrapper(Envelope)
export const WarningTriIcon = withWrapper(WarningTri)
export const Appraisal2Icon = withWrapper(Appraisal2)
export const Shipment2Icon = withWrapper(Shipment2)
export const Authenticated2Icon = withWrapper(Authenticated2)

// Payment icons:
export const AmexIcon = withWrapper(Amex)
export const DiscoverIcon = withWrapper(Discover)
export const MastercardIcon = withWrapper(Mastercard)
export const VisaIcon = withWrapper(Visa)

// WYSIWYG icons:
export const BoldIcon = withWrapper(Bold)
export const ItalicIcon = withWrapper(Italic)
export const UnderlineIcon = withWrapper(Underline)
export const BulletListIcon = withWrapper(BulletList)
export const OrderedListIcon = withWrapper(OrderedList)
