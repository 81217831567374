import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet-async'
import { useOutletContext } from 'react-router-dom'
import styled from 'styled-components'
import { down } from 'styled-breakpoints'
import { useQuery } from 'urql'
// import Toggle from '../profile/Toggle'
import Links from '../profile/Links'
import MiniProfile from '../profile/MiniProfile'
import Icons from '../profile/Icons'
import Todos from '../profile/Todos'
// import Stats from '../profile/Stats'
import Frame from '../profile/Frame'
import SalesGraph from './SalesGraph'
// import VerticalIcons from '../profile/VerticalIcons'
// import Worth from '../profile/Worth'
import Subheader from '../shared/Subheader'

import Button from '../_atoms/Button'

import { MailIcon, TimeMoneyIcon, ShieldIcon, NoMoneyIcon } from '../_base/Icon'
import Loader from '../shared/Loader'
import { useGlobals, useUnreadMessageCount } from '../hooks'
import Link from '../shared/Link'
import { HeaderL } from '../_abstracts/Type'

// const GET_SELLER_REPORT = `
//   query GetSellerReport {
//     sellerReport {
//       max {
//         amount
//         currency { iso }
//       }
//
//       average {
//         amount
//         currency { iso }
//       }
//
//       total {
//         amount
//         currency { iso }
//       }
//     }
//   }
// `

const GET_ORDERS = `
  query CompletedOrders($filter: OrderListFilter) {
    orders(filter: $filter) {
      edges {
        node {
          id

          lineItems {
            id

            listing {
              id

              item {
                id
                title

                images {
                  id
                  url(size: "cart")
                }

                account {
                  id
                  username
                }
              }
            }
          }

          shipment {
            id
            status
          }
        }
      }
    }
  }
`

const Grid = styled.div`
  display: flex;
  border-top: 1px solid ${(props) => props.theme.colors.midGrey};

  ${down('medium')} {
    flex-wrap: wrap;
  }
`

const Side = styled.div`
  width: 100%;
  max-width: 341px;
  padding: 20px;
  background: ${(props) =>
    props.color == 'blue' ? props.theme.colors.blueTint : 'transparent'};

  &:first-child {
    padding: 20px 0 20px 20px;
  }

  &:last-child {
    padding: 20px;
  }

  ${down('medium')} {
    max-width: initial;
    padding: 20px !important;
  }

  > * + * {
    margin-top: 20px;
  }
`

const Middle = styled.div`
  flex-grow: 1;
  padding: 20px;

  > * + * {
    margin-top: 20px;
  }
`

const iconArgs = {
  icons: [
    {
      text: 'lorem ipsum, dolor',
      children: <TimeMoneyIcon />,
    },
    {
      text: 'lorem ipsum, dolor',
      children: <ShieldIcon />,
    },
    {
      text: 'lorem ipsum, dolor',
      children: <NoMoneyIcon />,
    },
  ],
}

const MessagesCount = styled.span`
  margin-left: 0.4em;
  color: ${({ theme }) => theme.colors.gold};
`

const todoArgs = {
  header: 'Seller To-do’s',
  text: 'Before you can list any items for sale, please complete these fields',
  list: [
    {
      checked: true,
      link: '/liinnnkkkk',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse in nisi odio',
    },
    {
      checked: false,
      link: '/liinnnkkkk',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse in nisi odio',
    },
    {
      checked: false,
      link: '/liinnnkkkk',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse in nisi odio',
    },
    {
      checked: false,
      link: '/liinnnkkkk',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse in nisi odio',
    },
    {
      checked: false,
      link: '/liinnnkkkk',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse in nisi odio',
    },
  ],
}

// const verticalIconArgs = {
//   icons: [
//     {
//       header: '12',
//       text: 'lorem ipsum, dolor',
//       children: <TimeMoneyIcon />,
//     },
//     {
//       header: '180',
//       text: 'lorem ipsum, dolor',
//       children: <ShieldIcon />,
//     },
//   ],
// }

const ProfileHeader = styled.h2`
  ${HeaderL}
  font-size: 28px;
`

export default function ProfileOverviewPage() {
  const { user } = useOutletContext()
  // const [vacationMode, setVacationMode] = useToggle(false)
  // const [{ data: { sellerReport } = { sellerReport: null } }] = useQuery({
  //   query: GET_SELLER_REPORT,
  // })
  // console.log(sellerReport)

  const { profile, fetching, error } = useGlobals('profile')

  const { unreadMessageCount } = useUnreadMessageCount()

  if (fetching) return <Loader height="250px" background="#fff" />

  if (error) {
    console.error(error)
    return <p>{error.message}</p>
  }

  // TODO: Hook up seller todos (not MVP):
  const hasCompletedSellerTodos = true

  return (
    <>
      <Helmet>
        <title>Profile</title>
      </Helmet>
      <Subheader header={profile.profileOverviewTitle}>
        <Button
          to="/selling/list-item/new"
          variant="primaryDark"
          disabled={!hasCompletedSellerTodos}
        >
          List new instrument
        </Button>
        <Button to="/messages" variant="secondary">
          <MailIcon /> Messages
          {unreadMessageCount > 0 && (
            <MessagesCount>({unreadMessageCount})</MessagesCount>
          )}
        </Button>
      </Subheader>

      <Grid>
        <Side>
          <MiniProfile greeting={profile.profileOverviewGreeting} user={user} />
          {/* Priority hide */}
          {/* <Stats
            visitors="180"
            thisWeekDirection="up"
            thisWeekWidth="80%"
            thisWeekChange="10%"
            lastWeekDirection="down"
            lastWeekWidth="80%"
            lastWeekChange="10%"
          /> */}

          {/* Priority hide */}
          {/* <Toggle
            toggleState={vacationMode}
            onChange={setVacationMode}
            header="Vacation mode"
            text="Away for a while? Set yourself as away so buyers know not to hear back from you right away."
          /> */}

          {profile.profileOverviewQuickLinks.length ? (
            <Links header={profile.profileOverviewQuickLinksTitle}>
              <ul>
                {profile.profileOverviewQuickLinks.map(({ itemLink }) => (
                  <li key={itemLink.url}>
                    <Link to={itemLink.url}>{itemLink.text}</Link>
                  </li>
                ))}
              </ul>
            </Links>
          ) : null}
        </Side>

        <Middle>
          {(hasCompletedSellerTodos && (
            <>
              {/* High Priority Hide */}
              {/* <VerticalIcons {...verticalIconArgs} /> */}
              <ProfileHeader>{profile.profileSalesGraphHeading}</ProfileHeader>
              <SalesGraph />
              {/* High Priority Hide */}
              {/* <Worth
                total={{ amount: '450430', currency: { iso: 'USD' } }}
                change="6.93%"
                direction="up"
              /> */}
            </>
          )) || (
            <>
              <Icons {...iconArgs} />
              <Todos {...todoArgs} />
            </>
          )}
        </Middle>

        <Sidebar hasCompletedSellerTodos={hasCompletedSellerTodos} />
      </Grid>
    </>
  )
}

ProfileOverviewPage.propTypes = {}

const Sidebar = ({ hasCompletedSellerTodos }) => {
  const [purchasesQuery] = useQuery({
    query: GET_ORDERS,
  })

  if (purchasesQuery.error) {
    console.error(purchasesQuery.error)
  }

  const purchases =
    purchasesQuery.data?.orders?.edges
      ?.map(({ node }) =>
        node.lineItems.map(({ listing }) => ({ order: node, ...listing }))
      )
      .flat() || []

  const [salesQuery] = useQuery({
    query: GET_ORDERS,
    variables: {
      filter: 'AS_SELLER',
    },
  })

  if (salesQuery.error) {
    console.error(salesQuery.error)
  }

  const sales =
    salesQuery.data?.orders?.edges
      ?.map(({ node }) =>
        node.lineItems.map(({ listing }) => ({ order: node, ...listing }))
      )
      .flat() || []

  return (
    <Side color="blue">
      <Frame
        header="Latest sales"
        listings={sales}
        text={
          (purchasesQuery.fetching && 'Loading…') ||
          (!sales.length
            ? hasCompletedSellerTodos
              ? 'No recent sales'
              : 'Once you’ve completed our seller’s setup to-dos you can start listing and start selling'
            : undefined)
        }
        link="/profile/selling/orders"
      />
      <Frame
        header="Latest purchases"
        listings={purchases}
        text={
          (salesQuery.fetching && 'Loading…') ||
          (!purchases.length ? 'No recent purchases' : undefined)
        }
        link="/profile/buying/purchases"
      />
    </Side>
  )
}

Sidebar.propTypes = {
  hasCompletedSellerTodos: PropTypes.bool,
}
