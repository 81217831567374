import styled from 'styled-components'
import { Helmet } from 'react-helmet-async'
import { useQuery, useMutation } from 'urql'
import toast from 'react-hot-toast'

import { BodyS } from '../../_abstracts/Type'
import Subheader from '../../shared/Subheader'

import { Formik, Form } from 'formik'
import Split from '../Split'
import Checkbox from '../../inputs/Checkbox'
import CheckboxGroup from '../../inputs/CheckboxGroup'
import Button, { ButtonStyle } from '../../_atoms/Button'
import Loader from '../../shared/Loader'
import { down } from 'styled-breakpoints'

const Footer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  > *:first-child:last-child {
    margin-left: auto;
  }

  ${down('small')} {
    align-items: flex-start;

    > *:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  ${ButtonStyle} {
    min-width: 200px;
  }
`

const Link = styled.button`
  ${BodyS};
  border-bottom: 1px solid;
  display: inline-block;

  &:hover {
    color: ${(props) => props.theme.colors.gold};
  }
`

const NOTIFICATION_PREFERENCES = `
  query NotificationPreferences {
    prefs: notificationPreferences {
      emailEventTypes
    }
  }
`

const NOTIFICATION_UPDATE_PREFERENCES = `
  mutation UpdateNotificationPreferences(
    $preferences: NotificationUpdatePreferencesInput!
  ) {
    notificationUpdatePreferences(preferences: $preferences) {
      success
    }
  }
`

export default function DetailsPage() {
  const [{ fetching, data, error }] = useQuery({
    query: NOTIFICATION_PREFERENCES,
  })

  const [, submitUpdatePreferences] = useMutation(
    NOTIFICATION_UPDATE_PREFERENCES
  )

  async function updatePreferences(preferences) {
    const updatePromise = submitUpdatePreferences({
      preferences,
    }).then(({ data, error }) => {
      if (error) throw new Error(error.toString())

      return data
    })

    toast.promise(
      updatePromise,
      {
        loading: 'Updating preferences…',
        success: 'Preferences updated',
        error: 'Unable to update preferences',
      },
      { id: 'notificationPreferences' }
    )
  }

  return (
    <>
      <Helmet>
        <title>Profile | Settings | Notifications</title>
      </Helmet>
      <div>
        <Subheader border header="Notifications" />

        {fetching ? (
          <Loader backgroud="#fff" />
        ) : error ? (
          <p>Unable to load preferences</p>
        ) : (
          <>
            <Split header="Send me emails for:">
              <Formik
                initialValues={{ emailEventTypes: data.prefs.emailEventTypes }}
                onSubmit={updatePreferences}
              >
                {({ setFieldValue }) => (
                  <Form>
                    <CheckboxGroup>
                      <Checkbox
                        name="emailEventTypes"
                        value="offerReceived"
                        label="Offer Received"
                        blueLabel
                      />

                      <Checkbox
                        name="emailEventTypes"
                        value="offerAccepted"
                        label="Offer Accepted"
                        blueLabel
                      />

                      <Checkbox
                        name="emailEventTypes"
                        value="offerDeclined"
                        label="Offer Declined"
                        blueLabel
                      />

                      <Checkbox
                        name="emailEventTypes"
                        value="offerCountered"
                        label="Offer Countered"
                        blueLabel
                      />

                      <Checkbox
                        name="emailEventTypes"
                        value="messageReceived"
                        label="Message Received"
                        blueLabel
                      />

                      <Checkbox
                        name="emailEventTypes"
                        value="orderReceived"
                        label="Order Received"
                        blueLabel
                      />

                      <Checkbox
                        name="emailEventTypes"
                        value="orderShipped"
                        label="Order Shipped"
                        blueLabel
                      />
                    </CheckboxGroup>

                    <Footer>
                      <Link
                        type="button"
                        onClick={() => setFieldValue('emailEventTypes', [])}
                      >
                        Unsubscribe from all
                      </Link>

                      <Button type="submit" variant="primaryDark">
                        Save
                      </Button>
                    </Footer>
                  </Form>
                )}
              </Formik>
            </Split>
          </>
        )}
      </div>
    </>
  )
}
