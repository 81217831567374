import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom'
import { useQuery } from 'urql'
import NotFound from '../404/NotFound'
import Html from '../data/Html'
import Loader from '../shared/Loader'
import ShopListingsLayout from './ShopListingsLayout'
import useSearchQuery, {
  DEFAULT_PER_PAGE,
  DEFAULT_SORT,
} from './useSearchQuery'

const GET_PAGE = `
  query GetPage($slug: [String]!) {
    entry(section: "collections", slug: $slug) {
      id
      title
      url
      uri
      ... on collections_default_Entry {
        heroTitle
        heroText
        seoTitle
        seoDescription
        sectionLinks {
          ... on sectionLinks_item_BlockType {
            image {
              id
              xs: url(transform: "xs")
              small: url(handle: "small")
              width
              height
              title
            }
            itemLink {
              text
              url
              element {
                ... on categories_categories_Entry {
                  sectionLinkImage {
                    id
                    xs: url(transform: "xs")
                    small: url(handle: "small")
                    width
                    height
                    title
                  }
                }
                ... on collections_default_Entry {
                  sectionLinkImage {
                    id
                    xs: url(transform: "xs")
                    small: url(handle: "small")
                    width
                    height
                    title
                  }
                }
                ... on brands_brands_Entry {
                  sectionLinkImage {
                    id
                    xs: url(transform: "xs")
                    small: url(handle: "small")
                    width
                    height
                    title
                  }
                }
              }
            }
          }
        }
        filterBrands {
          title
        }
        filterCategory {
          title
        }
        filterModels {
          title
        }
        filterSellers
        filterFromYear
        filterToYear
        skus
        filterKeywords
        filterPriceMin
        filterPriceMax
        handedness
        sort
      }
    }
  }
`

export default function ShopCollectionPage() {
  const { slug } = useParams()
  const [{ data, fetching, error }] = useQuery({
    query: GET_PAGE,
    variables: { slug },
  })

  const {
    query: { keywords, filters: queryFilters },
    paginationVars: { perPage },
    sort,
  } = useSearchQuery()

  if (fetching) return <Loader background="#fff" />

  if (error) return <p>{error.message}</p>

  if (!data.entry) {
    return <NotFound />
  }

  const { entry } = data

  const filters = []

  if (entry.filterBrands.length) {
    filters.push({
      key: 'brand',
      value: JSON.stringify(entry.filterBrands.map((brand) => brand.title)),
    })
  }

  if (entry.filterCategory.length) {
    filters.push({
      key: 'category',
      value: JSON.stringify(
        entry.filterCategory.map((category) => category.title)
      ),
    })
  }

  if (entry.filterFromYear || entry.filterToYear) {
    filters.push({
      key: 'year',
      value: JSON.stringify([entry.filterFromYear, entry.filterToYear]),
    })
  }

  if (entry.filterModels.length) {
    filters.push({
      key: 'model',
      value: JSON.stringify(entry.filterModels.map((model) => model.title)),
    })
  }

  if (entry.filterSellers.length) {
    filters.push({
      key: 'seller',
      value: JSON.stringify(entry.filterSellers),
    })
  }

  if (entry.filterPriceMin || entry.filterPriceMax) {
    filters.push({
      key: 'price',
      value: JSON.stringify([
        parseInt(entry.filterPriceMin, 10) * 100,
        parseInt(entry.filterPriceMax, 10) * 100,
      ]),
    })
  }

  if (entry.handedness) {
    filters.push({
      key: 'handedness',
      value: entry.handedness,
    })
  }

  if (entry.sort) {
    filters.push({
      key: 'sort',
      value: entry.sort,
    })
  }

  let hiddenKeywords
  if (entry.filterKeywords) {
    hiddenKeywords = entry.filterKeywords
  }

  let hiddenSkus
  if (entry.skus) {
    hiddenSkus = entry.skus.split(',')?.map((sku) => sku.trim())
  }

  const noindex =
    !!keywords ||
    (sort && sort !== DEFAULT_SORT) ||
    (perPage && perPage !== DEFAULT_PER_PAGE) ||
    queryFilters.length > 0

  const breadcrumbs = [{ text: entry.title, url: entry.url }]

  return (
    <>
      <Helmet>
        {noindex ? (
          <meta name="robots" value="noindex" />
        ) : (
          <link rel="canonical" href={entry.url} />
        )}
      </Helmet>
      <ShopListingsLayout
        heroTitle={entry.heroTitle || entry.title}
        heroText={entry.heroText && <Html html={entry.heroText} />}
        seoTitle={entry.seoTitle || entry.title}
        seoDescription={entry.seoDescription}
        sectionLinks={entry.sectionLinks}
        hiddenFilters={filters}
        hiddenKeywords={hiddenKeywords}
        hiddenSkus={hiddenSkus}
        breadcrumbs={breadcrumbs}
      />
    </>
  )
}
