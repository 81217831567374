const GET_GlOBAL_SET = `
  query GetSocial {
    globalSet(handle: "notFound") {
      ... on notFound_GlobalSet {
        id
        notFoundText
        notFoundTitle
        notFoundImage {
          id
          url(handle: "large")
        }
      }
    }
  }
`

export default GET_GlOBAL_SET
