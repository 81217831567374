const GET_GlOBAL_SET = `
  query GetProduct {
    globalSet(handle: "product") {
      ... on product_GlobalSet {
        id

        # Buttons
        productAddToCartButton
        productMakeOfferButton
        productRemoveFromCartButton
        productTradeButton
        productDemoRequestButton

        # Demo Request
        demoRequestTitle
        demoRequestText
        demoRequestSuccessMessage

        # Sidebar CTA
        productSidebarCtaTitle
        productSidebarCtaText
        productSidebarCtaImage {
          id
          small: url(transform: "small")
          medium: url(transform: "medium")
          width
          height
          title
        }
        buttons {
          ... on buttons_item_BlockType {
            id
            buttonTheme
            itemLink {
              url
              text
            }
            openChat
          }
        }

        # Shipping & Returns
        productShippingReturnsTitle
        productShippingReturns
        htmlTooltip
        productInvoiceTerms
        orderInvoiceTerms

        # Value Graph
        valueGraphTitle
        valueGraphText

        # Footer CTA
        cta {
          ... on ctas_ctas_Entry {
            id
            title
            ctaText
            ctaBackgroundColor
            ctaLinks {
              ... on ctaLinks_item_BlockType {
                id
                buttonTheme
                itemLink {
                  text
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`

export default GET_GlOBAL_SET
