import { Form } from 'formik'
import PropTypes from 'prop-types'
import { up } from 'styled-breakpoints'
import styled, { css } from 'styled-components'
import { HeaderL, HeaderM } from '../_abstracts/Type'
import { ICON_OFFSET } from './Fieldset'

const Wrapper = styled.div`
  max-width: 800px;
  margin-right: auto;
  margin-left: auto;

  ${up('large')} {
    max-width: 1080px;
  }
`

const Heading = styled.h1`
  text-align: ${({ align }) => align};

  ${({ size }) =>
    (size === 'large' &&
      css`
        ${HeaderL};
        margin-bottom: 15px;
      `) ||
    (size === 'small' &&
      css`
        ${HeaderM};
        margin-bottom: 25px;
        font-weight: 600;
      `)};

  ${up('large')} {
    margin-right: ${ICON_OFFSET}px;
    margin-left: ${ICON_OFFSET}px;
  }
`

export default function AccordionForm({
  id,
  children,
  heading,
  headingAlign,
  headingElement,
  headingSize,
}) {
  return (
    <Wrapper>
      {heading && (
        <Heading align={headingAlign} as={headingElement} size={headingSize}>
          {heading}
        </Heading>
      )}
      <Form id={id}>{children}</Form>
    </Wrapper>
  )
}

AccordionForm.defaultProps = {
  headingAlign: 'left',
  headingElement: 'h1',
  headingSize: 'large',
}

AccordionForm.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node,
  heading: PropTypes.string,
  headingAlign: PropTypes.oneOf(['left', 'center']),
  headingElement: PropTypes.string,
  headingSize: PropTypes.oneOf(['large', 'small']),
}
