import { useState } from 'react'
import { hideVisually } from 'polished'
import { useQuery } from 'urql'
import styled from 'styled-components'
import Transition from '../_abstracts/Animation'
import { AllCaps } from '../_abstracts/Type'
import Link from '../_atoms/Link'
import DateTime from '../data/DateTime'
import Money from '../data/Money'
import NextPrevious from '../shared/NextPrevious'

const Wrapper = styled.div`
  display: flex;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  border: 1px solid ${({ theme }) => theme.colors.midGrey};
  border-radius: ${({ theme }) => theme.radii};
`

const Inner = styled.div`
  flex-grow: 1;
`

const Table = styled.table`
  width: 100%;
  font-size: 14px;
  border-collapse: collapse;
  line-height: 1.4;

  th,
  td {
    padding: 1em 0.8em;
    vertical-align: middle;

    &:first-child {
      padding-left: 1.2em;
    }

    &:last-child {
      padding-right: 1.2em;
    }
  }

  th {
    font-weight: 700;
  }

  td {
  }

  thead {
    th {
      background-color: ${({ theme }) => theme.colors.lightGrey};

      &:first-child {
        border-top-left-radius: ${({ theme }) => theme.radii};
      }

      &:last-child {
        border-top-right-radius: ${({ theme }) => theme.radii};
      }
    }
  }

  tbody {
    td {
      border-top: 1px solid ${({ theme }) => theme.colors.blueTint};
    }

    tr:first-child td {
      border-color: ${({ theme }) => theme.colors.midGrey};
    }
  }
`

const A11yHeader = styled.span`
  ${hideVisually};
`

const View = styled(Link)`
  ${AllCaps};
  ${Transition({ property: 'color' })};
  font-size: 14px;
  text-decoration: underline;

  &:hover {
    color: ${({ theme }) => theme.colors.accent};
  }
`

const ITEMS_PER_PAGE = 5
const INVOICE_TABLE_ORDERS = `
  query InvoiceTableOrders($first: Int, $last: Int, $after: String, $before: String) {
    ordersAndRefunds(first: $first, last: $last, after: $after, before: $before) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }

	    edges {
	      node {
          __typename

          ...on Order {
	          id

	          total {
	            amount
	            currency { iso }
	          }
	          completedAt
	          number
          }

          ...on OrderRefund {
            id

            refundedAmount {
              amount
              currency { iso }
            }

            order {
              id
              number
            }

            createdAt
          }
	      }
	    }
	  }
	}
`

export default function InvoiceTable() {
  const [pagination, setPagination] = useState({
    first: ITEMS_PER_PAGE,
  })

  const [{ fetching, data, error }] = useQuery({
    query: INVOICE_TABLE_ORDERS,
    variables: {
      ...pagination,
    },
  })

  return (
    <Wrapper>
      <Inner>
        <Table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Type</th>
              <th>Order no.</th>
              <th>Total</th>
              <th>
                <A11yHeader>View</A11yHeader>
              </th>
            </tr>
          </thead>
          <tbody>
            {fetching && !data ? (
              <tr>
                <td colSpan={5}>Loading orders...</td>
              </tr>
            ) : error ? (
              <tr>
                <td colSpan={5}>Error loading orders...</td>
              </tr>
            ) : data && data.ordersAndRefunds.edges.length > 0 ? (
              data.ordersAndRefunds.edges.map(({ node }) => (
                <tr key={node.id}>
                  <td>
                    {node.__typename === 'Order' ? (
                      <DateTime date={new Date(node.completedAt)} noTime />
                    ) : (
                      <DateTime date={new Date(node.createdAt)} noTime />
                    )}
                  </td>
                  <td>{node.__typename === 'Order' ? 'Order' : 'Refund'}</td>
                  <td>
                    <Link
                      to={`/orders/${
                        node.__typename === 'Order' ? node.id : node.order.id
                      }?view=seller`}
                    >
                      {node.__typename === 'Order'
                        ? node.number
                        : node.order.number}
                    </Link>
                  </td>
                  <td>
                    {node.__typename === 'Order' ? (
                      <Money
                        amount={node.total.amount}
                        currency={node.total.currency.iso}
                        includeCurrency
                      />
                    ) : (
                      <Money
                        amount={node.refundedAmount.amount}
                        currency={node.refundedAmount.currency.iso}
                        includeCurrency
                      />
                    )}
                  </td>
                  <td>
                    <View
                      aria-label="View invoice"
                      to={
                        node.__typename === 'Order'
                          ? `/profile/settings/invoices/${node.id}`
                          : `/profile/settings/refunds/${node.id}`
                      }
                    >
                      View
                    </View>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={5}>No orders to show</td>
              </tr>
            )}
          </tbody>
        </Table>

        {data && (
          <NextPrevious
            includeNext={
              data.ordersAndRefunds.pageInfo.hasNextPage || !!pagination.before
            }
            includePrevious={
              data.ordersAndRefunds.pageInfo.hasPreviousPage ||
              !!pagination.after
            }
            onNext={() => {
              setPagination({
                after: data.ordersAndRefunds.pageInfo.endCursor,
                first: ITEMS_PER_PAGE,
              })
            }}
            onPrevious={() => {
              setPagination({
                before: data.ordersAndRefunds.pageInfo.startCursor,
                last: ITEMS_PER_PAGE,
              })
            }}
          />
        )}
      </Inner>
    </Wrapper>
  )
}

InvoiceTable.propTypes = {}
