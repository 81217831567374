import Meta from '../_helpers/Meta'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { useQuery } from 'urql'
import Container from '../_helpers/Container'
import Spacer from '../_helpers/Spacer'
import NotFound from '../404/NotFound'
import DateTime from '../data/DateTime'
import Html from '../data/Html'
import Blocks, { blocksQueryFields } from '../shared/Blocks'
import Cta from '../shared/Cta'
import Hero from '../shared/Hero'
import Loader from '../shared/Loader'
import CenteredTextBlock from '../text/CenteredTextBlock'
import GreyTextBlock, {
  Wrapper as GreyTextBlockWrapper,
} from '../text/GreyTextBlock'

const GET_PAGE = `
  query GetPage($slug: [String]) {
    entry(section: "legal", slug: $slug) {
      id
      title
      url
      dateUpdated
      ... on legal_legal_Entry {
        legalIntro
        legalText
        ${blocksQueryFields}
        seoTitle
        seoDescription
        cta {
          title
          ... on ctas_ctas_Entry {
            ctaText
            ctaBackgroundColor
            ctaLinks {
              ... on ctaLinks_item_BlockType {
                buttonTheme
                itemLink {
                  text
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`

const Inner = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.midGrey};
  border-radius: 6px;

  ${GreyTextBlockWrapper} {
    border-bottom: 1px solid ${({ theme }) => theme.colors.midGrey};

    &:last-child {
      border-bottom: 0;
    }
  }
`

const Content = styled.div`
  max-width: 900px;
  padding: 32px 30px;
  margin-right: auto;
  margin-left: auto;
`

export default function Page() {
  const location = useLocation()

  const [result] = useQuery({
    query: GET_PAGE,
    variables: {
      slug: location.pathname.replace(/^\/|\/$/g, ''),
    },
  })

  const { data, fetching, error } = result

  if (fetching) return <Loader height="250px" background="#fff" />

  if (error) return <p>{error.message}</p>

  if (!data.entry) {
    return <NotFound />
  }

  const { entry } = data
  const cta = entry.cta[0]

  return (
    <>
      <Meta
        title={entry.seoTitle || entry.title}
        description={entry.seoDescription}
        url={entry.url}
      />
      <Hero
        hero={{
          header: entry.title,
          headerElement: 'h1',
          subheader: (
            <>
              Updated <DateTime date={new Date(entry.dateUpdated)} noTime />
            </>
          ),
        }}
      />
      <Spacer size="40px" />
      <Container>
        {entry.legalIntro && (
          <>
            <CenteredTextBlock>
              <Html html={entry.legalIntro} />
            </CenteredTextBlock>
            <Spacer size="40px" />
          </>
        )}
        {entry.legalText || entry.blocks.length ? (
          <Inner>
            {entry.legalText && (
              <GreyTextBlock>
                <Html html={entry.legalText} />
              </GreyTextBlock>
            )}
            {entry.blocks.length ? (
              <Content>
                <Blocks blocks={entry.blocks} />
              </Content>
            ) : null}
          </Inner>
        ) : null}
        {cta && (
          <>
            <Spacer fromSize="40px" toSize="70px" />
            <Cta
              header={cta.title}
              text={<Html html={cta.ctaText} />}
              variant={cta.ctaBackgroundColor}
              buttons={cta.ctaLinks.map((item) => ({
                text: item.itemLink.text,
                to: item.itemLink.url,
                variant: item.buttonTheme,
              }))}
            />
          </>
        )}
        <Spacer fromSize="40px" toSize="80px" />
      </Container>
    </>
  )
}
