import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { HeaderL } from '../_abstracts/Type'
import { ButtonStyle } from '../_atoms/Button'
import { down } from 'styled-breakpoints'

export const Topper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 13px;

  ${down('small')} {
    justify-content: center;
  }

  ${({ $border }) =>
    $border &&
    css`
      border-bottom: 1px solid ${({ theme }) => theme.colors.midGrey};
    `}
`

export const TopperHeader = styled.h2`
  ${HeaderL}
  padding: 13px;
`

const TopperActions = styled.div`
  padding: 7.5px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;

  > * {
    display: inline-flex;
    margin: 5px 0 5px 25px;
  }

  ${ButtonStyle} {
    margin: 7.5px;
  }
  ${down('small')} {
    justify-content: center;
  }
`

export default function Subheader({ border, header, children }) {
  return (
    <Topper $border={border}>
      <TopperHeader>{header}</TopperHeader>
      <TopperActions>{children}</TopperActions>
    </Topper>
  )
}

Subheader.propTypes = {
  border: PropTypes.bool,
  header: PropTypes.string.isRequired,
  children: PropTypes.node,
}
