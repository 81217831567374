import { Field } from 'formik'
import ErrorMessage from '../_atoms/ErrorMessage'
import SharedCheckbox from '../shared/Checkbox'

export default function Checkbox({ name, value, onChange, ...props }) {
  return (
    <Field name={name} value={value} type="checkbox">
      {({ field }) => (
        <SharedCheckbox
          checked={field.checked}
          controlled
          name={field.name}
          value={field.value}
          onBlur={field.onBlur}
          onChange={(checked, event) => {
            onChange?.(checked)
            field.onChange(event)
          }}
          {...props}
        >
          {props.children && props.children}
          <ErrorMessage name={name} />
        </SharedCheckbox>
      )}
    </Field>
  )
}

Checkbox.propTypes = SharedCheckbox.propTypes
