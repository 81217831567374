import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Media, MediaImage } from '../_base/Media'
import {
  Icon,
  MailIcon,
  CartIcon,
  ReturnIcon,
  FlagIcon,
  BoxIcon,
} from '../_base/Icon'
import {
  HeaderSansM,
  HeaderSansL,
  BodyS,
  SmallLetters,
} from '../_abstracts/Type'
import { down } from 'styled-breakpoints'
import Money from '../data/Money'
import BlockList from '../shared/BlockList'
import Modal from '../shared/Modal'
import ModalForm from '../shared/ModalForm'
import DateTime from '../data/DateTime'
import Link from '../shared/Link'
import ZendeskButton from '../shared/ZendeskButton'
import { useMutation } from 'urql'
import toast from 'react-hot-toast'

const Wrapping = styled.div`
  padding: 13px;
  display: flex;

  & + & {
    border-top: 1px solid ${(props) => props.theme.colors.blueTint};
  }

  ${down('medium')} {
    flex-wrap: wrap;
  }

  ${down('small')} {
    padding: 0;
  }
`

const Image = styled.div`
  padding: 13px;
  min-width: 300px;

  ${down('medium')} {
    order: 1;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }

  ${down('small')} {
    max-width: 100%;
    width: 100%;
    padding-top: 0;
  }

  ${Media} {
    border: 1px solid ${(props) => props.theme.colors.midGrey};
    padding-bottom: 100%;
  }
`

const Content = styled.div`
  padding: 7px;
  flex-grow: 1;

  ${down('medium')} {
    order: 0;
  }
`

const Top = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 25px;

  ${down('small')} {
    margin-bottom: 0;
    display: block;
  }
`

const Left = styled.div`
  padding: 7px;

  ${down('medium')} {
    padding-bottom: 0;
  }
`

const Right = styled.div`
  padding: 7px;
  text-align: right;

  ${down('medium')} {
    padding-top: 0;
    text-align: left;
  }
`

const Header = styled(Link)`
  ${HeaderSansM}
  max-width: 538px;
  margin-bottom: 15px;
`

const Deets = styled.p`
  ${SmallLetters}
`

const Author = styled.a`
  color: ${(props) => props.theme.colors.accent};

  &:hover {
    text-decoration: underline;
  }
`

const Copy = styled.p`
  ${BodyS}
`

const Label = styled.span`
  ${SmallLetters}
  display: inline-block;
  padding-right: 10px;
  color: ${(props) => props.theme.colors.accent};
`

const Price = styled.p`
  ${HeaderSansL}
  margin-top: 5px;
`

const MetaItemSpacing = (props) => `
  border-right: 1px solid ${props.theme.colors.midGrey};
  padding-right: 15px;
  margin-right: 15px;
`

const Meta = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 7px;
  margin-bottom: 10px;

  /* for the zendesk widget */
  span {
    ${(props) => MetaItemSpacing(props)}
    vertical-align: middle;
  }
`

const MetaItem = styled.button.attrs({ type: 'button' })`
  ${BodyS}
  display: inline-flex;
  align-items: center;
  background-color: transparent;
  border: 0;
  margin-bottom: 5px;
  cursor: pointer;

  &:not(:last-child) {
    ${(props) => MetaItemSpacing(props)}
  }

  ${Icon} {
    display: inline-block;
    width: 13px;
    height: 13px;
    min-width: 13px;
    margin-right: 8px;
  }

  &:hover {
    color: ${(props) => props.theme.colors.accent};
  }

  &:last-child ${Icon} {
    width: 10px;
    min-width: 10px;
  }
`

const MARK_AS_RECEIVED = `
mutation MarkAsReceived($id: ID!) {
  lineItemSetReceived(id: $id) {
    success
    lineItem {
      id
      state
      validStateToMarkReceived
    }
  }
}
`

export default function Purchase({ order, lineItem }) {
  const [, setLineItemReceived] = useMutation(MARK_AS_RECEIVED)
  async function markAsReceived() {
    const { data } = await setLineItemReceived({ id: lineItem.id })
    if (data?.lineItemSetReceived?.success) {
      toast.success('Marked as received')
    } else {
      toast.error('Could not mark as received. Please contact us')
    }

    return false
  }

  return (
    <Wrapping>
      <Image>
        <Media>
          <MediaImage srcSet={lineItem.listing.item.images[0]?.url} alt="" />
        </Media>
      </Image>

      <Content>
        <Top>
          <Left>
            <Header
              to={`/shop/${lineItem.listing.item.slug}/${lineItem.listing.item.id}`}
            >
              {lineItem.listing.item.title}
            </Header>
            <Deets>
              Sold by{' '}
              <Author
                href={'/profile/' + lineItem.listing.item.account.username}
              >
                {lineItem.listing.item.account.username}
              </Author>
            </Deets>
            <Deets>
              Purchase date:{' '}
              <DateTime
                date={new Date(order.fullyPaidAt ?? order.completedAt)}
                noTime
              />
            </Deets>
          </Left>

          <Right>
            <Copy>
              <Label>Listed price</Label>
              <Money
                amount={lineItem.amount.amount}
                currency={lineItem.amount.currency.iso}
              />
            </Copy>

            <Copy>
              <Label>Shipping</Label>
              <Money
                amount={order.shippingCost.amount}
                currency={order.shippingCost.currency.iso}
              />
            </Copy>

            <Price>
              <Money
                amount={lineItem.amount.amount}
                currency={lineItem.amount.currency.iso}
              />
            </Price>
          </Right>
        </Top>

        <Meta>
          <MetaItem as={Link} to={`/orders/${order.id}`}>
            <BoxIcon />
            View order details
          </MetaItem>

          {lineItem.listing.item.account.isCarterVintage ? (
            <ZendeskButton>
              <MetaItem as={Link} to="#">
                <MailIcon />
                Message seller
              </MetaItem>
            </ZendeskButton>
          ) : (
            <MetaItem
              as={Link}
              to={`/messages/compose?itemId=${lineItem.listing.item.id}`}
            >
              <MailIcon />
              Message seller
            </MetaItem>
          )}

          <Modal
            size="large"
            trigger={
              <MetaItem>
                <ReturnIcon />
                Return item
              </MetaItem>
            }
          >
            <ModalForm
              successMessage="Return request sent"
              header="Return item"
              handle="returnItem"
              info="Please enter your reasoning for wishing to return this item."
              fields={{
                itemId: lineItem.listing.item.id,
              }}
            />
          </Modal>

          <MetaItem
            as={Link}
            to={`/selling/list-item/resell/${lineItem.listing.item.id}`}
          >
            <CartIcon />
            Sell item
          </MetaItem>

          <Modal
            size="large"
            trigger={
              <MetaItem>
                <FlagIcon />
                Report an issue
              </MetaItem>
            }
          >
            <ModalForm
              successMessage="Message sent"
              info="Please let us know if you're having issues."
            />
          </Modal>

          {order.canMarkReceived && lineItem.validStateToMarkReceived && (
            <MetaItem onClick={markAsReceived}>
              <BoxIcon />
              Mark as received
            </MetaItem>
          )}
        </Meta>

        <BlockList
          list={[
            {
              title: 'Status',
              value: order.shipment.status,
            },
            {
              title: 'Order #',
              value: order.number,
            },
            {
              title: 'Tracking #',
              value:
                order.shipment.trackingNumber == null
                  ? 'Unassigned'
                  : order.shipment.trackingNumber,
            },
            {
              title: 'Carrier',
              value:
                order.shipment.carrierName == null
                  ? 'Unassigned'
                  : order.shipment.carrierName,
            },
          ]}
        />
      </Content>
    </Wrapping>
  )
}

Purchase.propTypes = {
  lineItem: PropTypes.object.isRequired,
  slug: PropTypes.string.isRequired,
  order: PropTypes.object.isRequired,
}
