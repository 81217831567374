import styled from 'styled-components'
import PropTypes from 'prop-types'
import { HeaderXL, Body, BodyS, HeaderSansXS } from '../_abstracts/Type'
import Avatar from '../shared/Avatar'
import { Icon, MarkerIcon, VerifiedIcon } from '../_base/Icon'
import Money from '../data/Money'
import Button from '../_atoms/Button'
import MakeOfferModal from '../offers/MakeOfferModal'

const Heading = styled.h1`
  ${HeaderXL};
  color: ${(props) => props.theme.colors.darkBlue};
  text-align: center;
  max-width: 1070px;
  margin: 40px auto;
`

const UserInfoWrapper = styled.div`
  margin: 0 auto;
  border-radius: ${(props) => props.theme.radii};
  border: 1px solid ${(props) => props.theme.colors.blueTint};
  width: 100%;
  max-width: 542px;
`

const UserInfoTop = styled.div`
  padding: 15px;
  display: flex;
`

const UserInfoContent = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  > *:not(:last-child) {
    margin-right: 15px;
  }

  span,
  li {
    ${BodyS}
    font-weight: 300;
  }
`

const Name = styled.p`
  ${Body};
  font-weight: normal;
`

const MetaItem = styled.li`
  display: inline-block;

  &:not(:last-child) {
    margin-right: 25px;
  }

  ${Icon} {
    width: 14px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
  }
`

const UserInfoExtra = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`

const Content = styled.div`
  display: flex;
`

const Avvie = styled.div`
  padding-right: 15px;
`

const Deets = styled.div`
  ${BodyS};
  flex-grow: 1;
  font-weight: 300;
`

const Sales = styled.p`
  padding-right: 10px;

  &:before {
    display: inline-block;
    content: '|';
    margin-right: 10px;
  }
`

const Items = styled.p`
  padding-right: 10px;

  &:before {
    display: inline-block;
    content: '|';
    margin-right: 10px;
  }
`

const UserInfoFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid ${(props) => props.theme.colors.blueTint};
`

const Verified = styled.p`
  ${BodyS};
  color: ${(props) => props.theme.colors.accent};

  ${Icon} {
    display: inline-block;
    height: 25px;
    width: 25px;
    vertical-align: middle;
  }
`

const Price = styled.p`
  ${HeaderSansXS}
  font-weight: 700;
  color: ${(props) => props.theme.colors.darkBlue};

  span {
    ${BodyS}
    font-weight: 700;
  }
`

const FooterContent = styled.div`
  padding: 15px;
`

const FooterButton = styled.div`
  border-left: 1px solid ${(props) => props.theme.colors.blueTint};
  padding: 10px 22px;

  &:first-child:last-child {
    margin-right: auto;
    margin-left: auto;
    border-left: 0;
  }
`

export default function HeaderDetail({
  heading,
  location,
  user,
  sales,
  items,
  listing,
  valuation,
  item,
}) {
  return (
    <>
      <Heading>{heading}</Heading>
      <UserInfoWrapper>
        <UserInfoTop>
          <Content>
            <Avvie>
              <Avatar size="small" user={user} />
            </Avvie>

            <Deets>
              <UserInfoContent>
                <Name>{user.username}</Name>

                {location && (
                  <MetaItem>
                    <MarkerIcon />
                    {location}
                  </MetaItem>
                )}
              </UserInfoContent>

              {(sales || items) && (
                <UserInfoExtra>
                  {sales && <Sales>Sales Made: {sales}</Sales>}
                  {items && <Items>Items in collection: {items}</Items>}
                </UserInfoExtra>
              )}
            </Deets>
          </Content>
        </UserInfoTop>

        {(listing || valuation || item.acceptingOffers) && (
          <UserInfoFooter>
            {(listing || valuation) && (
              <FooterContent>
                <Verified>
                  {listing && <>Listing price</>}
                  {valuation && (
                    <>
                      TNAG Verified Valuation <VerifiedIcon />
                    </>
                  )}
                </Verified>

                {(listing && (
                  <Price>
                    <Money
                      amount={listing.askingPrice.amount}
                      currency={listing.askingPrice.currency.iso}
                    />{' '}
                    <span>{listing.askingPrice.currency.iso}</span>
                  </Price>
                )) ||
                  (valuation && (
                    <Price>
                      <Money
                        amount={valuation.amount}
                        currency={valuation.currency.iso}
                      />{' '}
                      <span>{valuation.currency.iso}</span>
                    </Price>
                  ))}
              </FooterContent>
            )}

            {(listing && (
              <FooterButton>
                <Button
                  variant="primaryDark"
                  to={`/shop/${item.slug}/${item.id}`}
                >
                  View listing
                </Button>
              </FooterButton>
            )) ||
              (item.acceptingOffers && (
                <FooterButton>
                  <MakeOfferModal buttonLabel="Make an offer" item={item} />
                </FooterButton>
              ))}
          </UserInfoFooter>
        )}
      </UserInfoWrapper>
    </>
  )
}

HeaderDetail.propTypes = {
  heading: PropTypes.string.isRequired,
  user: PropTypes.shape({
    username: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.string,
  sales: PropTypes.string,
  items: PropTypes.node.isRequired,
  listing: PropTypes.object,
  valuation: PropTypes.object,
  item: PropTypes.object,
  slug: PropTypes.string.isRequired,
}
