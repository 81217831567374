import { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import Flickity from 'react-flickity-component'
import './flickity.css'
import { HiddenScrollBar } from '../_helpers/ScrollBar'

import { Icon, ArrowNextIcon, ArrowPrevIcon } from '../_base/Icon'
import Transition from '../_abstracts/Animation'
import { Media, MediaImage } from '../_base/Media'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .flickity-viewport {
    width: 100%;
  }
`

const Slide = styled(Media)`
  width: 100%;
  flex-shrink: 0;
  background-color: ${(props) => props.theme.colors.white};

  ${MediaImage} {
    object-fit: contain;
  }
`

const Arrows = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
  z-index: 2;
  padding: 0 16px;
  pointer-events: none;
`

const Arrow = styled.button.attrs({ type: 'button' })`
  ${Transition({ property: 'color, transform, opacity' })}
  pointer-events: all;

  &:hover {
    transform: scale(1.1);
  }

  &[disabled] {
    pointer-events: none;
    opacity: 0.2;
  }
`

export const MainSlider = styled.div`
  position: relative;
  width: 100%;
  border: 1px solid ${(props) => props.theme.colors.midGrey};
  border-radius: ${(props) => props.theme.radii};
  overflow: hidden;

  .main-carousel {
    display: flex;
    overflow: hidden;
  }

  ${Icon} {
    width: 43px;
  }
`

const Thumbs = styled.div`
  position: relative;
  max-width: 100%;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 20px;

  ${Arrows} {
    padding: 0;
  }

  ${Icon} {
    width: 19px;

    path {
      stroke-width: 1;
    }
  }
`

const ThumbList = styled.div`
  ${HiddenScrollBar}
  display: flex;
  overflow-y: hidden;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  button {
    width: 70px;
    margin-right: 6px;
    margin-left: 6px;
  }
`

const Thumb = styled(Media)`
  ${Transition({ property: 'border-color' })}
  flex-shrink: 0;
  border: 1px solid ${(props) => props.theme.colors.midGrey};
  border-radius: ${(props) => props.theme.radii};

  &::before {
    display: block;
    padding-top: 100%;
    content: '';
  }

  ${MediaImage} {
    ${Transition({ property: 'opacity' })}
    object-fit: contain;
    opacity: 0.7;
  }

  &:hover {
    ${MediaImage} {
      opacity: 1;
    }
  }

  ${({ selected, theme }) =>
    selected &&
    css`
      border-color: ${theme.colors.darkBlue};

      ${MediaImage} {
        opacity: 1;
      }
    `}
`

const mainOptions = {
  cellAlign: 'center',
  pageDots: false,
  prevNextButtons: false,
  contain: true,
  resize: true,
  adaptiveHeight: true,
  lazyLoad: true,
}

export default function ProductCarousel({ images }) {
  const flick = useRef()
  const thumbList = useRef()
  const thumbs = useRef([])
  const slides = useRef([])
  const [selectedIndex, setSelectedIndex] = useState(0)
  const atStart = selectedIndex === 0
  const atEnd = selectedIndex === images.length - 1
  const flkty = flick.current?.flkty
  const [imageSizes, setImageSizes] = useState(
    images.map(({ dimensions: { width, height } }) => ({ width, height }))
  )

  function previous() {
    if (flkty && !atStart) {
      flkty.previous()
    }
  }

  function next() {
    if (flkty && !atEnd) {
      flkty.next()
    }
  }

  const selectedThumb = thumbs.current?.[selectedIndex]

  const thumbListElement = thumbList.current
  useEffect(() => {
    // Center selected thumb
    if (thumbListElement && selectedThumb) {
      const thumbPosition = selectedThumb.getBoundingClientRect()
      const thumbListPosition = thumbListElement.getBoundingClientRect()
      thumbListElement.scrollTo({
        left:
          thumbPosition.left -
          thumbListPosition.left -
          (thumbListPosition.width - thumbPosition.width) / 2 +
          thumbListElement.scrollLeft,
        behavior: 'smooth',
      })
    }
  }, [selectedIndex, thumbListElement, selectedThumb])

  useEffect(() => {
    if (flkty) {
      flkty.on('select', setSelectedIndex)
    }
  }, [flkty])

  useEffect(() => {
    const eventListenerRemovals = []

    function setCellImageSize(cell, index) {
      const image = cell.querySelector('img')
      if (!image) return

      const onImageLoad = () => {
        // If image dimensions are available and do not match those in image metadata
        // (due to e.g. different EXIF orientation), use natural image size
        if (
          image?.naturalWidth &&
          image?.naturalHeight &&
          (imageSizes[index].width !== image.naturalWidth ||
            imageSizes[index].height !== image.naturalHeight)
        ) {
          setImageSizes((sizes) => {
            sizes[index] = {
              width: image.naturalWidth,
              height: image.naturalHeight,
            }
            return sizes.slice()
          })
        }
      }

      if (image.naturalWidth && image.naturalHeight) {
        onImageLoad()
      } else {
        image.addEventListener('load', onImageLoad)
        eventListenerRemovals.push(() => {
          image.removeEventListener('load', onImageLoad)
        })
      }
    }

    function processCellImages(index) {
      const cell = flkty?.cells?.[index]?.element
      if (!cell) return

      setCellImageSize(cell, index)
    }

    // Load hi-res image for current cell
    processCellImages(selectedIndex)

    // Load hi-res image for next cell, unless at end of slider
    if (selectedIndex + 1 < images.length) {
      processCellImages(selectedIndex + 1)
    }

    return () => {
      for (const eventListenerRemoval of eventListenerRemovals) {
        eventListenerRemoval()
      }
    }
  }, [flkty, selectedIndex, imageSizes, images.length])

  return (
    <Wrapper cellCount={images.length}>
      <MainSlider>
        {images.length > 1 && (
          <Arrows>
            <Arrow onClick={previous} disabled={atStart}>
              <ArrowPrevIcon />
            </Arrow>
            <Arrow onClick={next} disabled={atEnd}>
              <ArrowNextIcon />
            </Arrow>
          </Arrows>
        )}
        <Flickity
          className="main-carousel"
          options={mainOptions}
          ref={flick}
          disableImagesLoaded
        >
          {images.map((image, index) => (
            <Slide
              ref={(element) => {
                slides.current[index] = element
              }}
              key={image.id}
              style={{
                paddingBottom: `${
                  (imageSizes[index].height / imageSizes[index].width) * 100
                }%`,
              }}
            >
              <MediaImage
                src={index === 0 ? image.url : undefined}
                data-flickity-lazyload={index > 0 ? image.url : undefined}
                alt=""
              />
            </Slide>
          ))}
        </Flickity>
      </MainSlider>
      {images.length > 1 && (
        <Thumbs>
          <Arrows>
            <Arrow onClick={previous} disabled={atStart}>
              <ArrowPrevIcon />
            </Arrow>
            <Arrow onClick={next} disabled={atEnd}>
              <ArrowNextIcon />
            </Arrow>
          </Arrows>
          <ThumbList ref={thumbList}>
            {images.map((image, index) => (
              <Thumb
                ref={(element) => {
                  thumbs.current[index] = element
                }}
                selected={selectedIndex === index}
                as="button"
                type="button"
                key={image.id}
                onClick={() => {
                  if (flkty && flkty.selectedIndex !== index) {
                    flkty.select(index)
                  }
                }}
              >
                <MediaImage srcSet={image.thumb} loading="lazy" alt="" />
              </Thumb>
            ))}
          </ThumbList>
        </Thumbs>
      )}
    </Wrapper>
  )
}

ProductCarousel.propTypes = {
  images: PropTypes.array.isRequired,
}
