import styled from 'styled-components'
import toast from 'react-hot-toast'
import { useMutation } from 'urql'
import { HeaderL } from '../_abstracts/Type'
import Button, { ButtonStyle } from '../_atoms/Button'
import { PinIcon } from '../_base/Icon'
import Money from '../data/Money'
import Spacer from '../_helpers/Spacer'
import Alert from '../shared/Alert'
import Loader from '../shared/Loader'
import { useCartContext } from './CartContext'
import { Wrapper as TooltipWrapper } from '../shared/Tooltip'
import Item, { Wrapper as ItemStyle } from './Item'
import { down } from 'styled-breakpoints'
import { useUserContext } from '../auth/UserContext'
import AdminActions from './AdminActions'
import OpenOrderList from './OpenOrderList'

const Wrapper = styled.div`
  border-radius: 5px;

  ${down('small')} {
    flex-wrap: wrap;
  }
`

const Header = styled.div`
  padding: 24px 35px;
  background-color: ${({ theme }) => theme.colors.lightGrey};
  border-bottom: 1px solid ${({ theme }) => theme.colors.blueTint};
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  text-align: center;
`

const Heading = styled.h2`
  ${HeaderL};
  line-height: 1.2;
`

const Content = styled.div`
  padding: 20px;
`

const Order = styled.div`
  &:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit;
  }

  ${ItemStyle} {
    padding-top: 17px;
    margin-top: 17px;
    border-top: 1px solid ${({ theme }) => theme.colors.blueTint};

    &:first-child {
      padding-top: 0;
      margin-top: 0;
      border-top: 0;
    }
  }
`

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 14px 20px;
  text-align: right;
  background-color: ${({ theme }) => theme.colors.lightGrey};
  border-top: 1px solid ${({ theme }) => theme.colors.blueTint};

  ${down('small')} {
    flex-wrap: wrap;
  }

  ${Order}:last-child & {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit;
  }
`

const Total = styled.div`
  font-weight: 700;
  line-height: 1.4;

  ${TooltipWrapper} {
    position: relative;
    top: -2px;
    display: inline-block;
    margin-left: 5px;
  }
`

const Amount = styled.span`
  margin-left: 10px;
`

const TotalNote = styled.p`
  font-size: 14px;
  line-height: 1.4;
`

const Checkout = styled(Button)`
  margin-left: 20px;

  ${down('small')} {
    flex-grow: 1;
    margin-top: 10px;
    width: 100%;
  }
`

const Message = styled.p`
  text-align: center;
  font-size: 18px;
  padding-bottom: 20px;

  ${ButtonStyle} {
    margin-top: 20px;
    display: block;
    width: 100%;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }
`

const REMOVE_LINE_ITEMS = `
  mutation RemoveLineItems($lineItemIds: [ID!]!, $orderId: [ID!]) {
    cartRemoveLineItems(lineItemIds: $lineItemIds, orderId: $orderId) {
      success
      cart(orderId: $orderId) {
        orders {
          id
        }
      }
    }
  }
`

export default function Cart() {
  const {
    cart,
    hasInvalidItems,
    invalidLineItems,
    fetching,
    error,
    openOrderIds,
  } = useCartContext()
  const { user } = useUserContext()
  const [{ fetching: removing }, removeLineItems] =
    useMutation(REMOVE_LINE_ITEMS)

  async function removeInvalidLineItems() {
    const { data } = await removeLineItems({
      lineItemIds: invalidLineItems.map((item) => item.id),
      orderId: openOrderIds,
    })

    if (!data?.cartRemoveLineItems?.success) {
      toast.error('Unable to remove from cart', { id: 'cart' })
    }
  }

  return (
    <Wrapper>
      <Header>
        <Heading>Your order</Heading>
      </Header>
      {(fetching && <Loader background="#fff" height="250px" />) ||
        (error ? (
          <Content>
            <Message>{error.toString()}</Message>
          </Content>
        ) : hasInvalidItems ? (
          <Content>
            <Message>
              Some items in you your cart have become unavailable and need to be
              removed. Click OK to update your cart.
              <Button
                onClick={removeInvalidLineItems}
                disabled={fetching || removing}
              >
                {fetching || removing ? 'Removing...' : 'OK'}
              </Button>
            </Message>
          </Content>
        ) : cart?.orders?.length ? (
          cart.orders.map((order) => (
            <Order key={order.id}>
              <Content>
                {order.lineItems.map((lineItem) => (
                  <Item
                    key={lineItem.id}
                    lineItemId={lineItem.id}
                    price={lineItem.amount}
                    quantity={lineItem.quantity}
                    orderCompleted={!!order.completedAt}
                    allowCartNotes={lineItem.allowCartNotes}
                    cartNotes={lineItem.cartNotes}
                    listing={lineItem.listing}
                  />
                ))}
              </Content>
              {user?.isAdmin && <AdminActions order={order} />}
              <Footer>
                <div>
                  <Total>
                    {/* To Do: bring back when multiple currency options available */}
                    {/* Estimated total:
                    <Tooltip>Total explanation text</Tooltip> */}
                    Total:
                    <Amount>
                      <Money
                        amount={order.total.amount}
                        currency={order.total.currency.iso}
                        includeCurrency
                      />
                    </Amount>
                  </Total>
                  <TotalNote>
                    + shipping and tax, calculated at the checkout
                    {order.localPickupOnly && (
                      <>
                        <Spacer size="10px" />
                        <Alert
                          center
                          icon={<PinIcon />}
                          iconWidth="0.9em"
                          size="small"
                          type="info"
                        >
                          Local pickup only
                        </Alert>
                      </>
                    )}
                  </TotalNote>
                </div>
                <Checkout to="/checkout" variant="primaryDark">
                  Checkout
                </Checkout>
              </Footer>
            </Order>
          ))
        ) : (
          <Content>
            <Message>
              Your cart is empty.
              <Button variant="primaryDark" to="/shop">
                Visit the shop
              </Button>
            </Message>
          </Content>
        ))}
      {user?.isAdmin && <OpenOrderList />}
    </Wrapper>
  )
}
