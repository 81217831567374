import PropTypes from 'prop-types'
import { down } from 'styled-breakpoints'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { OfferIcon, Icon, ReplyBareIcon } from '../_base/Icon'
import { Body, BodyS } from '../_abstracts/Type'
import Avatar from '../shared/Avatar'
import DateTime from '../data/DateTime'
import { Wrapper as HtmlWrapper } from '../data/Html'
import Roundel from '../shared/Roundel'
import Transition from '../_abstracts/Animation'
// import SaveButton from './SaveButton'

const Wrapper = styled(Link)`
  position: relative;
  display: block;
  width: 100%;
  cursor: pointer;
  will-change: box-shadow;
  ${Transition({ property: 'box-shadow', duration: '200ms' })}

  &:hover {
    box-shadow: ${(props) => props.theme.shadows.small};
    z-index: 1;
  }
`

const Inner = styled.div`
  display: flex;
  align-items: center;
  padding: 15px;

  ${down('small')} {
    flex-flow: row wrap;
  }
`

const AvatarSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;

  > * {
    flex-shrink: 0;
  }

  ${down('small')} {
    flex: 0 0 min-content;
  }
`

const MessageState = styled.div`
  display: block;
  margin-right: 18px;
`

const Reply = styled.div`
  ${Icon} {
    width: 15px;
    height: 15px;
  }
`

const Dot = styled.div`
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.accent};
`

const Content = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  max-width: 970px;
  flex-grow: 1;

  ${down('small')} {
    flex: 1 0 70%;
  }
`

const Name = styled.h2`
  ${Body}
  color: ${(props) => props.theme.colors.black};
  font-weight: 600;
`

const Text = styled.div`
  ${Body}
  color: ${(props) => props.theme.colors.xDarkGrey};

  &,
  ${HtmlWrapper} {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`

const Meta = styled.div`
  text-align: right;
  flex-shrink: 0;
  display: flex;
  flex-flow: column nowrap;
  > * {
    margin-left: auto;
  }

  ${down('small')} {
    flex-flow: row-reverse nowrap;
    margin-top: 15px;
    width: 100%;
    align-items: center;
  }
`

const Item = styled.span`
  font-weight: 400;
  font-style: italic;
  color: ${(props) => props.theme.colors.xDarkGrey};
`

// const Actions = styled.div`
//   display: flex;
//   justify-content: flex-end;
//   align-items: center;
//   margin-bottom: 10px;

//   ${down('small')} {
//     margin-bottom: 0;
//   }
// `

const Time = styled.div`
  ${BodyS}
  color: ${(props) => props.theme.colors.darkGrey};
  margin-bottom: 5px;
  white-space: nowrap;
  flex-grow: 1;

  ${down('small')} {
    margin-bottom: 0;
    margin-right: 10px;
  }
`

export default function Preview({ message, item }) {
  // TODO: Hook up
  const isUnread = false
  const haveReplied = false

  return (
    <Wrapper to={`/messages/${message.id}`}>
      <Inner>
        <AvatarSection>
          <MessageState>
            {isUnread && <Dot />}
            {haveReplied && (
              <Reply>
                <ReplyBareIcon />
              </Reply>
            )}
          </MessageState>
          <Avatar size="small" user={message.user} />
        </AvatarSection>

        <Content>
          <Name>
            {message.user.username}
            {item && <Item> - {item.title}</Item>}
          </Name>
          {message.summary && <Text>{message.summary}</Text>}
        </Content>

        <Meta>
          {/* <Actions>
            <SaveButton isSaved={message.saved} />
          </Actions> */}

          {message.date && (
            <Time>
              <DateTime date={message.date} />
            </Time>
          )}
          {message.offer && (
            <Roundel variant="red" icon={<OfferIcon />} size="small">
              Offer
            </Roundel>
          )}
        </Meta>
      </Inner>
    </Wrapper>
  )
}

Preview.propTypes = {
  message: PropTypes.shape({
    id: PropTypes.string.isRequired,
    summary: PropTypes.node,
    offer: PropTypes.bool,
    // saved: PropTypes.bool,
    date: PropTypes.instanceOf(Date),
    user: PropTypes.shape({
      username: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  item: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }),
}
