import { css } from 'styled-components'

const A11yElement = css`
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  border: 0;
  height: 1px;
  font-size: 21px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

export default A11yElement
