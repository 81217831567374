import styled from 'styled-components'
import PropTypes from 'prop-types'
import { BodyS, HeaderS, HeaderL } from '../_abstracts/Type'
import { Icon } from '../_base/Icon'

import { down } from 'styled-breakpoints'

export const Wrapper = styled.div`
  color: ${(props) => props.theme.colors.white};
  padding: 20px 20px 50px;
  background-image: linear-gradient(45deg, #2c2c5a, #06062d);

  ${down('medium')} {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }
`

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 13px;
`

const Header = styled.h2`
  ${HeaderL}
  width: 100%;
  text-align: center;
  padding: 13px;
  margin-bottom: 20px;
`

const Item = styled.div`
  width: 100%;
  max-width: 360px;
  padding: 0 13px 13px;
  margin-top: 30px;
`

const Marker = styled.span`
  position: relative;
  z-index: 1;
  display: block;
  margin-left: auto;
  margin-right: auto;
  transform: translateY(-50%);
  width: 62px;
  height: 62px;
  border-radius: 50%;
  position: relative;
  border: 1px solid ${(props) => props.theme.colors.midGrey};
  background-color: ${(props) => props.theme.colors.white};

  ${Icon} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: inline-block;
    width: 60%;
  }
`

const Content = styled.div`
  padding: 0 20px 20px;
  border: 1px solid ${(props) => props.theme.colors.blueTint};
  background-color: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.darkBlue};
`

const Inner = styled.div``

const Title = styled.h3`
  ${HeaderS}
  margin-bottom: 10px;
`

const Text = styled.div`
  ${BodyS}

  > * + * {
    margin-top: 20px;
  }
`

export default function Cards({ list, header }) {
  return (
    <Wrapper>
      <Container>
        <Header>{header}</Header>
        {list.map((item, index) => (
          <Item key={index}>
            <Content>
              {item.icon && <Marker>{item.icon}</Marker>}
              <Inner>
                <Title>{item.title}</Title>
                <Text>{item.text}</Text>
              </Inner>
            </Content>
          </Item>
        ))}
      </Container>
    </Wrapper>
  )
}

Cards.propTypes = {
  header: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.node,
      title: PropTypes.string.isRequired,
      text: PropTypes.node.isRequired,
    })
  ).isRequired,
}
