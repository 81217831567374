const GET_GlOBAL_SET = `
  query GetSocial {
    globalSet(handle: "social") {
      ... on social_GlobalSet {
        id
        socialYoutube
        socialTwitter
        socialFacebook
        socialInstagram
      }
    }
  }
`

export default GET_GlOBAL_SET
