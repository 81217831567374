import { Outlet, Navigate } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { useCartContext } from '../cart/CartContext'
import { ButtonStyle } from '../_atoms/Button'
import Container from '../_helpers/Container'
import Spacer from '../_helpers/Spacer'
import Tabs from '../shared/Tabs'

const Wrapper = styled.div`
  flex-grow: 1;
  background-color: ${(props) => props.theme.colors.xLightGrey};
`

export const Footer = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  text-align: right;

  ${(props) =>
    (props.size === 'large' &&
      css`
        max-width: 1115px;
      `) ||
    (props.size === 'medium' &&
      css`
        max-width: 805px;
      `) ||
    (props.size === 'small' &&
      css`
        max-width: 600px;
      `) ||
    css`
      max-width: 100%;
    `}

  ${ButtonStyle} {
    width: 100%;
    max-width: 300px;
  }
`

export default function CheckoutLayout() {
  const { hasInvalidItems } = useCartContext()

  if (hasInvalidItems) return <Navigate to="/cart" />

  return (
    <Wrapper>
      <Container>
        <Spacer fromSize="30px" toSize="50px" />
        <Tabs
          disableNextTabs
          tabs={[
            { title: '1. Shipping', url: '/checkout/shipping' },
            { title: '2. Payment', url: '/checkout/payment' },
            { title: '3. Confirmation', url: '/checkout/confirmation' },
          ]}
          variant="slim"
        />
        <Outlet />
        <Spacer fromSize="40px" toSize="80px" />
      </Container>
    </Wrapper>
  )
}
