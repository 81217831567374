import { useFloating } from '@floating-ui/react-dom'
import { useCallback, useState, useEffect } from 'react'
import { useLocation, Link } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'

import styled from 'styled-components'
import { Query } from 'urql'
import Transition from '../_abstracts/Animation'
import { Icon } from '../_base/Icon'
import SavedSearchLink from '../feed/SavedSearchLink'

import SearchBar, { Form as SearchForm } from '../search/SearchBar'

const transitionDuration = 200

const Wrapper = styled.div`
  position: relative;
  width: 100%;

  ${SearchForm} {
    max-width: 100%;
  }
`

const Container = styled.div`
  ${Transition({ property: 'opacity', duration: `${transitionDuration}ms` })};
  position: relative;
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
  width: 100%;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: ${(props) => props.theme.radii};
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);
  border: 1px solid ${(props) => props.theme.colors.accent};
  font-size: 16px;
  opacity: ${({ state }) => (state === 'entered' ? 1 : 0)};
  padding: 15px;
  overflow: auto;
  max-height: 200px;
  margin-top: 4px;

  li {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    text-align: left;
    padding: 2px 8px;
    ${Transition({
      property: 'background-color',
      duration: `${transitionDuration}ms`,
    })};

    &:hover {
      background-color: ${(props) => props.theme.colors.blueTint};
    }
  }

  li + li {
    border-top: 1px solid ${(props) => props.theme.colors.blueTint};
    margin-top: 5px;
  }

  p {
    display: inline-block;
  }

  button {
    width: 24px;
    height: 24px;
    min-width: 24px;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: '';
      z-index: 1;
      cursor: pointer;
    }
  }

  ${Icon} {
    display: block;
    width: 24px;
    height: 24px;
  }
`

export const TriggerWrapper = styled.div`
  display: block;
`

const SAVED_SEARCHES = `
  query SavedSearches {
    listingSavedSearches {
      id
      keywords
      filters {
        key
        label
        value
      }
    }
  }
`

const Bold = styled.span`
  font-weight: 700;
  padding-left: 5px;
`

const formatSavedSearchLabel = (label) => {
  if (label.includes('[')) {
    try {
      const parsed = JSON.parse(label)
      return parsed.join(', ')
      // eslint-disable-next-line no-empty
    } catch {}
  }

  return label
}

export default function SearchBarWithSaved(props) {
  const [isActive, setIsActive] = useState(false)

  const toggle = useCallback(
    (active) => {
      setIsActive(active)
    },
    [setIsActive]
  )

  const { x, y, reference, floating, strategy, update, refs } = useFloating({
    placement: 'bottom',
  })

  // Close on escape key
  useEffect(() => {
    window.addEventListener('keyup', close)

    function close(event) {
      if (event.keyCode === 27 && isActive === true) {
        toggle(false)
      }
    }

    return () => {
      window.removeEventListener('keyup', close)
    }
  }, [isActive, toggle])

  // Close on click outside popup/trigger
  useEffect(() => {
    const onClick = (ev) => {
      if (
        !refs.reference.current?.contains(ev.target) &&
        !refs.floating.current?.contains(ev.target)
      ) {
        toggle(false)
      }
    }

    document.addEventListener('click', onClick)
    document.addEventListener('touchend', onClick)

    return () => {
      document.removeEventListener('click', onClick)
      document.removeEventListener('touchend', onClick)
    }
  }, [refs.reference, refs.floating, toggle])

  // Close popup when page changes (e.g. if clicking a link inside popup):
  const location = useLocation()
  useEffect(() => {
    toggle(false)
  }, [location, toggle])

  const contents = (
    <CSSTransition
      in={isActive}
      timeout={{ exit: transitionDuration }}
      mountOnEnter
      unmountOnExit
    >
      {(state) => (
        <Container
          ref={floating}
          state={state}
          style={{
            position: strategy,
            top: y ?? '',
            left: x ?? '',
          }}
        >
          <ul>
            <Query query={SAVED_SEARCHES}>
              {({ fetching, data, error }) => {
                if (fetching) return <li>Loading...</li>
                if (error) return <li>Unable to load your saved searches</li>

                return (
                  <>
                    {data.listingSavedSearches.map((search) => (
                      <li key={search.id}>
                        <SavedSearchLink search={search}>
                          {search.keywords && (
                            <>
                              <Bold>Keywords:</Bold> {search.keywords}
                            </>
                          )}
                          {search.filters &&
                            search.filters.map((filter, index) => (
                              <p key={index}>
                                {' '}
                                <Bold>{filter.label}:</Bold>{' '}
                                {formatSavedSearchLabel(filter.value)}
                              </p>
                            ))}
                        </SavedSearchLink>
                      </li>
                    ))}
                    <li>
                      <Link to="/my-saved-searches/customize">
                        Manage your saved searches
                      </Link>
                    </li>
                  </>
                )
              }}
            </Query>
          </ul>
        </Container>
      )}
    </CSSTransition>
  )

  return (
    <>
      <Wrapper>
        {contents}
        <TriggerWrapper
          onClick={() => toggle(!isActive) && update()}
          ref={reference}
        >
          <SearchBar {...props} />
        </TriggerWrapper>
      </Wrapper>
    </>
  )
}

SearchBarWithSaved.defaultProps = SearchBar.defaultProps
SearchBarWithSaved.propTypes = SearchBar.propTypes
