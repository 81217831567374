import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import { useId } from 'react-id-generator'
import styled, { css } from 'styled-components'
import Transition from '../_abstracts/Animation'
import { getHeaderHeight } from '../_abstracts/Layout'
import { Wrapper as HtmlWrapper } from '../data/Html'

const transitionDuration = 150

export const Wrapper = styled.span`
  position: relative;
  display: inline-flex;
`

const Button = styled.button`
  ${Transition({ property: 'color, background-color, border-color' })}
  text-align: center;
  font-size: 0;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.blue};
  background-color: ${({ theme }) => theme.colors.blue20};
  border: 1px solid ${({ theme }) => theme.colors.blue};
  border-radius: 50%;
  cursor: help;
  z-index: 1;

  ${({ size }) =>
    (size === 'small' &&
      css`
        width: 14px;
        height: 14px;
        font-size: 10px;
        line-height: 13px;
      `) ||
    (size === 'medium' &&
      css`
        width: 16px;
        height: 16px;
        font-size: 11px;
        line-height: 15px;
      `)}

  &:hover {
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.blue};
  }

  ${({ visible }) =>
    visible &&
    css`
      color: ${({ theme }) => theme.colors.white};
      background-color: ${({ theme }) => theme.colors.blue};
    `}
`

const Content = styled.div`
  ${Transition({ property: 'opacity', duration: `${transitionDuration}ms` })}
  position: absolute;
  left: calc(50% + ${({ leftOffset }) => leftOffset}px);
  width: max-content;
  max-width: ${({ maxWidth }) => maxWidth};
  max-width: min(${({ maxWidth }) => maxWidth}, 75vw);
  padding: 7px 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.35;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.blue};
  border-radius: ${({ theme }) => theme.radii};
  transform: translateX(-50%);
  z-index: 9999;

  &::before {
    position: absolute;
    width: 30px;
    height: 10px;
    left: 50%;
    transform: translateX(-50%);
    content: '';
  }

  ${({ visible }) =>
    !visible &&
    css`
      opacity: 0;
    `}

  ${({ end, offset }) =>
    (end === 'top' &&
      css`
        bottom: calc(100% + ${offset}px);

        &::before {
          top: 100%;
        }
      `) ||
    (end === 'bottom' &&
      css`
        top: calc(100% + ${offset}px);

        &::before {
          bottom: 100%;
        }
      `)}

  ${HtmlWrapper} a {
    transition-property: opacity;

    &:hover {
      color: inherit;
      opacity: 0.7;
    }
  }
`

export default function Tooltip({
  children,
  offset,
  size,
  maxWidth = '240px',
}) {
  const buttonRef = useRef(null)
  const contentRef = useRef(null)
  const [visible, setVisible] = useState(false)
  const [leftOffset, setLeftOffset] = useState(0)
  const [end, setEnd] = useState(null)
  const contentId = useId()[0]
  let showTimeout
  let hideTimeout

  const show = () => {
    if (visible) return

    setVisible(true)
  }

  const hide = () => {
    if (!visible) return

    setVisible(false)
  }

  const onMouseEnter = () => {
    clearTimeout(hideTimeout)
    showTimeout = setTimeout(show, 100)
  }

  const onMouseLeave = () => {
    clearTimeout(showTimeout)
    hideTimeout = setTimeout(hide, 200)
  }

  useEffect(() => {
    return () => {
      clearTimeout(showTimeout)
      clearTimeout(hideTimeout)
    }
  }, [showTimeout, hideTimeout])

  useEffect(() => {
    if (!visible) return

    const buttonPosition = buttonRef.current.getBoundingClientRect()
    const contentPosition = contentRef.current.getBoundingClientRect()
    const buttonLeft = buttonPosition.left + buttonPosition.width / 2
    const halfTooltipWidth = contentPosition.width / 2
    const bodyWidth = document.body.offsetWidth
    const padding = 10

    if (buttonLeft - halfTooltipWidth < padding) {
      setLeftOffset(halfTooltipWidth + padding - buttonLeft)
    } else if (buttonLeft + halfTooltipWidth > bodyWidth - padding) {
      setLeftOffset(bodyWidth - padding - (buttonLeft + halfTooltipWidth))
    } else {
      setLeftOffset(0)
    }

    if (
      contentPosition.height + offset * 2 >
      buttonPosition.top - getHeaderHeight()
    ) {
      setEnd('bottom')
    } else {
      setEnd('top')
    }
  }, [visible, offset])

  // May want to use a Portal for Content if z-index becomes an issue
  return (
    <Wrapper onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <Button
        aria-describedby={contentId}
        ref={buttonRef}
        size={size}
        type="button"
        visible={visible}
      >
        ?
      </Button>
      <CSSTransition
        nodeRef={contentRef}
        in={visible}
        timeout={{ exit: transitionDuration }}
        mountOnEnter
        unmountOnExit
      >
        {(state) => (
          <Content
            end={end}
            id={contentId}
            offset={offset}
            role="tooltip"
            leftOffset={leftOffset}
            visible={state === 'entered'}
            ref={contentRef}
            maxWidth={maxWidth}
          >
            {children}
          </Content>
        )}
      </CSSTransition>
    </Wrapper>
  )
}

Tooltip.defaultProps = {
  size: 'medium',
  offset: 5,
}

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  offset: PropTypes.number.isRequired,
  maxWidth: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium']).isRequired,
}
