import PropTypes from 'prop-types'
import styled from 'styled-components'
import { colors } from '../theme'

const themes = {
  dark: {
    color: colors.white,
    backgroundColor: colors.darkBlue,
  },
  grey: {
    color: colors.darkBlue,
    backgroundColor: colors.grey,
  },
  light: {
    color: colors.darkBlue,
    backgroundColor: colors.white,
  },
}

const Wrapper = styled.div`
  color: ${({ theme }) => theme.color};
  background-color: ${({ theme }) => theme.backgroundColor};
`

export default function Theme({ children, theme }) {
  if (!themes[theme]) {
    if (theme) {
      console.error('Unrecognised theme', theme)
    }

    theme = 'light'
  }

  return <Wrapper theme={themes[theme]}>{children}</Wrapper>
}

Theme.defaultProps = {
  theme: 'light',
}

Theme.propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.oneOf(['light', 'grey', 'dark']),
}
