import { rgba } from 'polished'
import PropTypes from 'prop-types'
import styled, { css, keyframes } from 'styled-components'
import { colors } from '../theme'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const Wrapper = styled.div`
  flex-grow: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: ${({ background }) => background};
  border-radius: 4px;

  ${({ height }) =>
    height &&
    css`
      height: ${height};
    `}
`

const Spinner = styled.div`
  display: block;
  font-size: 10px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: linear-gradient(
    ${colors.midGrey} 50%,
    ${rgba(colors.midGrey, 0)}
  );
  position: relative;
  animation: ${rotate} 0.7s infinite linear;

  &::before {
    background-color: ${({ background }) => background};
    width: 22px;
    height: 22px;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`

export default function Loader({ background, height }) {
  return (
    <Wrapper background={background} height={height}>
      <Spinner
        background={background === 'transparent' ? colors.white : background}
      ></Spinner>
    </Wrapper>
  )
}

Loader.defaultProps = {
  background: colors.lightGrey,
}

Loader.propTypes = {
  background: PropTypes.string,
  height: PropTypes.string,
}
