import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import styled from 'styled-components'
import Input, { InputField } from '../_atoms/Input'

export const Field = styled(Input)`
  ${InputField} {
    padding-right: 35px;
    background-image: url("data:image/svg+xml,%3Csvg width='7' height='6' viewBox='0 0 7 6' xmlns='http://www.w3.org/2000/svg' %3E%3Cpolygon transform='matrix(1 0 0 -1 -1125 2180)' points='1128.3074 2174 1131.3074 2180 1125.3074 2180' fill='%2306062D' fill-rule='evenodd'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 15px top 50%;
    background-size: 7px auto;
  }
`

const Select = forwardRef(({ options, ...props }, ref) => {
  return (
    <Field inputAs="select" ref={ref} {...props}>
      {props.placeholder && <option value="">{props.placeholder}</option>}
      {options &&
        options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
    </Field>
  )
})

Select.propTypes = {
  className: PropTypes.string,
  description: PropTypes.node,
  disabled: PropTypes.bool,
  disabledToggle: PropTypes.bool,
  disabledToggleLabel: PropTypes.string,
  error: PropTypes.bool,
  color: PropTypes.string,
  hideLabel: PropTypes.bool,
  inline: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  note: PropTypes.node,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
      ]).isRequired,
    })
  ),
  placeholder: PropTypes.string,
  readonly: PropTypes.bool,
  required: PropTypes.bool,
  size: PropTypes.oneOf(['small']),
  parse: PropTypes.func,
  onChange: PropTypes.func,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
}

Select.displayName = 'Select'

export default Select
