import App from './App'
import { BrowserRouter } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import React from 'react'
import { hydrate } from 'react-dom'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import createUrqlClient from './urqlClient'
import ScrollToTop from './_helpers/ScrollToTop'

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  environment: process.env.NODE_ENV,
  integrations: [new BrowserTracing()],

  tracesSampleRate: 0.05,
})

Sentry.setTag('serviceName', 'frontend')

hydrate(
  <HelmetProvider>
    <BrowserRouter>
      <ScrollToTop />
      <App createUrqlClient={createUrqlClient} />
    </BrowserRouter>
  </HelmetProvider>,
  document.getElementById('root')
)

if (module.hot) {
  module.hot.accept()
}
