import Meta from './_helpers/Meta'
import styled from 'styled-components'
import { useQuery } from 'urql'
import NotFound from './404/NotFound'
import Html from './data/Html'
import Hero from './homepage/Hero'
import Circles from './shared/Circles'
import Loader from './shared/Loader'
import Sections, { query as sectionsQuery } from './shared/Sections'
import { down } from 'styled-breakpoints'

const GET_PAGE = `
  query GetPage {
    entry(section: "home") {
      ... on home_home_Entry {
        id
        title
        url
        heroSlider {
          ... on heroSlider_item_BlockType {
            id
            linkSlider {
              url
            }
            image {
              id
              medium: url(transform: "medium")
              large: url(transform: "large")
              xl: url(transform: "xl")
              xxl: url(transform: "xxl")
              width
              height
              title
            }
            header
            text
          }
        }
        seoTitle
        seoDescription
        sectionLinks {
          ... on sectionLinks_item_BlockType {
            id
            image {
              id
              xs: url(transform: "xs")
              small: url(transform: "small")
              width
              height
              title
            }
            itemLink {
              text
              url
              element {
                ... on categories_categories_Entry {
                  id
                  sectionLinkImage {
                    id
                    xs: url(transform: "xs")
                    small: url(handle: "small")
                    width
                    height
                    title
                  }
                }
                ... on collections_default_Entry {
                  id
                  sectionLinkImage {
                    id
                    xs: url(transform: "xs")
                    small: url(handle: "small")
                    width
                    height
                    title
                  }
                }
                ... on brands_brands_Entry {
                  id
                  sectionLinkImage {
                    id
                    xs: url(transform: "xs")
                    small: url(handle: "small")
                    width
                    height
                    title
                  }
                }
              }
            }
          }
        }
        ${sectionsQuery}
      }
    }
  }
`

const CirclesWrap = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 30px;
  margin-top: -60px;
  background-color: ${(props) => props.theme.colors.lightGrey};

  ${down('small')} {
    margin-top: 0;
    padding-bottom: 15px;
    padding-top: 15px;
  }
`

export default function Home() {
  const [{ data, fetching, error }] = useQuery({ query: GET_PAGE })

  if (fetching) return <Loader background="#fff" />

  if (error) return <p>{error.message}</p>

  if (!data.entry) {
    return <NotFound />
  }

  const { entry } = data

  const circleArgs = []
  for (const section of entry.sectionLinks) {
    const image =
      section.image[0] || section.itemLink.element?.sectionLinkImage[0]

    circleArgs.push({
      title: section.itemLink.text,
      url: section.itemLink.url,
      image,
    })
  }

  return (
    <>
      <Meta
        title={entry.seoTitle}
        description={entry.seoDescription}
        url={entry.url}
      />
      <Hero
        options={{
          prevNextButtons: false,
          autoPlay: 6000,
          pageDots: false,
          wrapAround: true,
          setGallerySize: false,
        }}
        items={entry.heroSlider.map((item) => ({
          ...item,
          text: item.text && <Html html={item.text} />,
        }))}
      />
      {entry.sectionLinks.length ? (
        <CirclesWrap>
          <Circles circles={circleArgs} />
        </CirclesWrap>
      ) : null}
      <Sections sections={entry.sections} />
    </>
  )
}
