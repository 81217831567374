import styled from 'styled-components'
import PropTypes from 'prop-types'
import { BodyS, HeaderXS, SmallLetters } from '../_abstracts/Type'
import { Media, MediaImage } from '../_base/Media'

import { EditIcon, Icon } from '../_base/Icon'
import Button from '../_atoms/Button'
import Spacer from '../_helpers/Spacer'

const Wrapper = styled.div`
  border-radius: ${(props) => props.theme.radii};
  padding: 15px;
  background-color: ${(props) => props.theme.colors.white};
`

const Header = styled.div`
  ${HeaderXS}
  text-align: center;
  margin-bottom: 10px;
  color: ${(props) => props.theme.colors.black};
`

const Text = styled.div`
  ${BodyS}
  max-width: 253px;
  margin-right: auto;
  margin-left: auto;
  color: ${(props) => props.theme.colors.xDarkGrey};
  text-align: center;
`

const Items = styled.div`
  max-height: 437px;
  overflow: auto;
`

const Item = styled.div`
  padding: 10px;
  border: 1px solid ${(props) => props.theme.colors.midGrey};
  border-radius: ${(props) => props.theme.radii};

  & + & {
    margin-top: 15px;
  }
`

const Inner = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`

const Figure = styled.div`
  min-width: 64px;
  width: 64px;

  ${Media} {
    padding-bottom: 100%;
  }
`

const Content = styled.h2`
  ${SmallLetters}
  flex-grow: 1;
  line-height: 1.3;
  padding-left: 10px;
`

const Update = styled.button`
  cursor: pointer;
  position: relative;
  top: 5px;
  background: transparent;
  border: 0;
  transition: 0.15s;

  &:hover {
    color: ${(props) => props.theme.colors.accent};
  }

  ${Icon} {
    vertical-align: middle;
    width: 20px;

    circle {
      fill: transparent;
    }
  }
`

const Footer = styled.div`
  padding: 10px;
  margin-top: 10px;
  background-color: ${(props) => props.theme.colors.lightGrey};
`

const Meta = styled.p`
  ${SmallLetters}
  color: ${(props) => props.theme.colors.xDarkGrey};
`

const Label = styled.strong`
  color: ${(props) => props.theme.colors.accent};
  display: inline-block;
  padding-right: 10px;
`

export default function Frame({ header, text, listings, link }) {
  return (
    <Wrapper>
      <Header>{header}</Header>

      {text && <Text>{text}</Text>}

      {listings && (
        <>
          <Items>
            {listings.map(({ id, item, order }) => {
              const image = item.images?.[0]
              return (
                <Item key={`${order.id}-${id}`}>
                  <Inner>
                    {image && (
                      <Figure>
                        <Media>
                          <MediaImage src={image.url} alt={item.title} />
                        </Media>
                      </Figure>
                    )}

                    <Content>{item.title}</Content>
                  </Inner>

                  {order && (
                    <Footer>
                      <Meta>
                        <Label>Status</Label> {order.shipment.status}{' '}
                        {item.updateStatus && (
                          <Update>
                            <EditIcon />
                          </Update>
                        )}
                      </Meta>

                      {item.tracking && (
                        <Meta>
                          <Label>Tracking No.</Label> {item.tracking}{' '}
                          {item.updateTracking && (
                            <Update>
                              <EditIcon />
                            </Update>
                          )}
                        </Meta>
                      )}
                    </Footer>
                  )}
                </Item>
              )
            })}
          </Items>

          <Spacer size="15px" />
          <Button variant="small" fullWidth to={link}>
            View all
          </Button>
        </>
      )}
    </Wrapper>
  )
}

Frame.propTypes = {
  header: PropTypes.string.isRequired,
  text: PropTypes.string,
  listings: PropTypes.arrayOf(PropTypes.object),
  link: PropTypes.string.isRequired,
}
