import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useQuery } from 'urql'
import Fieldset from '../forms/Fieldset'
import AddressList, {
  Wrapper as AddressListWrapper,
} from '../shared/AddressList'
import { ShippingIcon } from '../_base/Icon'
import Warning, { Notification as WarningWrapper } from '../shared/Warning'

const GET_ADDRESSES = `
  query GetAddresses($accountId: ID) {
    addresses(first: 1, accountId: $accountId) {
      edges {
        node {
          id
        }
      }
    }
  }
`

const Wrapper = styled.div`
  width: 100%;

  ${AddressListWrapper} {
    padding-top: 13px;
    padding-bottom: 13px;
  }
`

const Info = styled.div`
  padding-top: 13px;
  display: flex;
  justify-content: center;

  ${WarningWrapper} {
    margin-bottom: 10px;
  }
`

const Content = ({ accountId, hasAddress }) => {
  const [{ data }, reexecuteQuery] = useQuery({
    query: GET_ADDRESSES,
    variables: { accountId },
    requestPolicy: 'network-only',
  })

  const [userAddedAddress, setUserAddedAddress] = useState(false)
  const userHasAddress = data?.addresses.edges.length > 0

  useEffect(() => {
    // Notify parent component of user's address status
    hasAddress(userHasAddress)
  }, [userHasAddress, hasAddress])

  // If the user has an address - we don't need to render the UI for them to add a new address
  // But if they didn't have an address and add one now, don't hide the UI after that, as it's confusing
  if (userHasAddress && !userAddedAddress) return null

  return (
    <Fieldset accordion legend="Your Address" icon={<ShippingIcon />}>
      <Wrapper>
        {!userHasAddress && (
          <Info>
            <Warning
              color="aqua"
              text="You must add an address to create a listing"
            />
          </Info>
        )}
        <AddressList
          accountId={accountId}
          onAddressesChange={() => {
            setUserAddedAddress(true)
            reexecuteQuery({ requestPolicy: 'network-only' })
          }}
        />
      </Wrapper>
    </Fieldset>
  )
}

Content.propTypes = {
  accountId: PropTypes.string.isRequired,
  hasAddress: PropTypes.func.isRequired,
}

export default function AddressCheck({ accountId, hasAddress }) {
  const [firstRender, setFirstRender] = useState(true)

  // Fix Suspense error related to network-only requestPolicy when rendering on server
  useEffect(() => {
    setFirstRender(false)
  }, [firstRender])

  if (firstRender) return null

  return <Content accountId={accountId} hasAddress={hasAddress} />
}

AddressCheck.propTypes = {
  accountId: PropTypes.string.isRequired,
  hasAddress: PropTypes.func.isRequired,
}
