import PropTypes from 'prop-types'
import styled from 'styled-components'
import { linkStates } from '../_abstracts/Type'
import { Icon, CrossIcon } from '../_base/Icon'
import { translate } from '../filter/Select'

const Wrapper = styled.div`
  display: flex;
  align-items: baseline;
  padding: 24px;
  border-bottom: 1px solid ${(props) => props.theme.colors.midGrey};
`

const Label = styled.p`
  margin-right: 10px;
`

const Clear = styled.button.attrs({ type: 'button' })`
  ${linkStates}
  margin-left: auto;
  font-size: 14px;
`

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: -5px 5px -5px -5px;
`

const Item = styled.li`
  margin: 5px;
`

const Filter = styled.button.attrs({ type: 'button' })`
  display: flex;
  align-items: center;
  padding: 3px 7px;
  font-size: 14px;
  background-color: ${({ theme }) => theme.colors.accentTint};
  border: 1px solid ${({ theme }) => theme.colors.accent};
  border-radius: ${(props) => props.theme.radii};

  ${Icon} {
    width: 12px;
    margin-left: 5px;
  }
`

export default function ActiveFilters({
  filters,
  clearFilter,
  clearAllFilters,
}) {
  return (
    <Wrapper>
      <Label>Filters:</Label>
      <List>
        {filters.map(({ key, value, multiple }) => (
          <Item key={`${key}-${value}`}>
            <Filter onClick={() => clearFilter(key, value, { multiple })}>
              {key === 'year'
                ? `${JSON.parse(value)[0]} – ${JSON.parse(value)[1]}`
                : key === 'price'
                ? `$${JSON.parse(value)[0] / 100} – $${
                    JSON.parse(value)[1] / 100
                  }`
                : translate({ key }, { value })}
              <CrossIcon />
            </Filter>
          </Item>
        ))}
      </List>
      <Clear onClick={clearAllFilters}>Clear filters</Clear>
    </Wrapper>
  )
}

ActiveFilters.propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      multiple: PropTypes.bool,
    })
  ),
  clearFilter: PropTypes.func.isRequired,
  clearAllFilters: PropTypes.func.isRequired,
}
