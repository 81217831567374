import { rgba } from 'polished'

export const colors = {
  //primary
  white: '#FFFFFF',
  black: '#000000',
  accent: '#891919',
  lightAccent: '#BC0404',
  accentTint: '#FBF0F0',
  gold: '#BE824B',
  darkBlue: '#06062D',
  // secondary
  xDarkGrey: '#505050',
  midDarkGrey: '#D8D8D8',
  darkGrey: '#979797',
  midGrey: '#C4C6C8',
  grey: '#F2F2F1',
  lightGrey: '#F5F5F5',
  lighterGrey: '#f9f9f9',
  xLightGrey: '#FBFBFB',
  blue: '#2C2C5A',
  blue20: rgba('#2C2C5A', 0.2),
  // state
  aqua: '#7DB9BA',
  red: '#891919',
  teal: '#066464',
  olive: '#846C5B',
  // tint
  aquaTint: '#ECF9F9',
  redTint: '#EFDFE3',
  goldTint: '#F8F2ED',
  darkGoldTint: '#F2E6DB',
  blueTint: '#E6E7EA',
  darkBlueTint: '#06062d',
  lightBlue: '#eeeefa',
  //btn greys
  lightBtnGrey: '#E6E7EA',
  darkBtnGrey: '#CECFD6',
}
export const shadows = {
  mini: `0 2px 10px -2px ${rgba(colors.darkBlue, 0.2)}, 0 0 0 1px ${rgba(
    colors.darkBlue,
    0.05
  )}`,
  small: `0 0 23px ${rgba(colors.darkBlue, 0.12)}`,
}

export const fonts = {
  serif: 'raleway, sans-serif',
  sansSerif: 'roboto, sans-serif',
}

export const radii = '5px'

//------grid------//
export const breakpoints = {
  xsmall: '0',
  small: '480px',
  medium: '768px',
  large: '1024px',
  xlarge: '1200px',
  xxlarge: '1440px',
}
export const globalWidth = '1296px'
export const gridMarginGutters = {
  xsmall: '20',
  medium: '24',
}
export const gridContainerPadding = {
  xsmall: '40px',
  medium: '72px',
}

export default {
  colors,
  shadows,
  fonts,
  radii,
  breakpoints,
  globalWidth,
  gridMarginGutters,
  gridContainerPadding,
}
