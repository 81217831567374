import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useQuery } from 'urql'
import Button, { ButtonStyle } from '../_atoms/Button'
import NotFound from '../404/NotFound'
import Loader from '../shared/Loader'
import ItemForm from './ItemForm'

const GET_ITEM = `
  query GetItem($id: ID!) {
    item(id: $id) {
      id
      privacy
      title
      category {
        id
      }
      brand {
        id
      }
      model {
        id
      }
      madeInYear
      madeInCountry {
        iso
      }
      handedness
      finish {
        id
      }
      materials {
        top {
          id
        }
        back {
          id
        }
        neck {
          id
        }
        fretboard {
          id
        }
        body {
          id
        }
      }
      description
      acceptingOffers
      offerDeclineThreshold
    }
  }
`

const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${ButtonStyle} {
    margin-top: 10px;
  }
`

const Message = styled.p`
  text-align: center;
`

export default function ResellItemForm({
  heading,
  headingAlign,
  headingElement,
  headingSize,
  itemId,
  onSave,
  photographPrice,
}) {
  const [result, reexecuteQuery] = useQuery({
    query: GET_ITEM,
    variables: { id: itemId },
  })
  const { data, fetching, error } = result

  if (fetching) return <Loader background="#f9f9f9" />

  if (error) {
    console.error(error)
    return (
      <MessageWrapper>
        <Message>An error occurred.</Message>
        <Button onClick={reexecuteQuery} type="button">
          Reload?
        </Button>
      </MessageWrapper>
    )
  }

  const { item } = data

  if (item == null) {
    return <NotFound />
  }

  return (
    <ItemForm
      heading={heading}
      headingAlign={headingAlign}
      headingElement={headingElement}
      headingSize={headingSize}
      includeListingFields
      photographPrice={photographPrice}
      item={{
        title: item.title,
        categoryId: item.category.id,
        brandId: item.brand.id,
        modelId: item.model.id,
        madeInYear: item.madeInYear,
        madeInCountry: item.madeInCountry?.iso,
        handedness: item.handedness,
        finishId: item.finish.id,
        materials: {
          topId: item.materials.top?.id,
          backId: item.materials.back?.id,
          neckId: item.materials.neck?.id,
          fretboardId: item.materials.fretboard?.id,
          bodyId: item.materials.body?.id,
        },
        uploads: [],
      }}
      onSave={onSave}
    />
  )
}

ResellItemForm.propTypes = {
  heading: PropTypes.string,
  headingAlign: PropTypes.oneOf(['left', 'center']),
  headingElement: PropTypes.string,
  headingSize: PropTypes.oneOf(['large', 'small']),
  itemId: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  photographPrice: PropTypes.number,
}
