import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { down, up } from 'styled-breakpoints'
import styled from 'styled-components'
import { useQuery } from 'urql'
import { useGlobals } from '../hooks'
import Button, { ButtonStyle } from '../_atoms/Button'
import Total from '../profile/Total'
import CardList from '../shared/CardList'
import NextPrevious from '../shared/NextPrevious'
import Card from './Card'
import Loader from '../shared/Loader'
import SearchBar, { Form as SearchForm } from '../search/SearchBar'
import { scrollToElement } from '../_abstracts/Layout'
import PortfolioValueGraph from './PortfolioValueGraph'

const ITEMS_PER_PAGE = 12

const GET_ITEMS = `
  query GetItems($filter: ItemFilter, $first: Int, $last: Int, $after: String, $before: String, $keywords: String) {
    items(filter: $filter, first: $first, last: $last, after: $after, before: $before, keywords: $keywords) {
      totalCount

      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }

      edges {
        cursor
        node {
          id
          images {
            id
            url(size: "productThumb")
          }
          listing {
            id
          }
          brand { id title }
          wishlistCount
          madeInYear
          title
          privacy
        }
      }
    }
  }
`

const Grid = styled.div`
  display: flex;
  border-top: 1px solid ${(props) => props.theme.colors.midGrey};

  ${down('medium')} {
    flex-wrap: wrap;
  }
`

const Side = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: ${(props) =>
    props.color == 'blue' ? props.theme.colors.blueTint : 'transparent'};

  ${down('medium')} {
    width: 100%;
  }

  ${up('large')} {
    order: 10;
    max-width: 310px;
  }
`

const SideInner = styled.div`
  align-self: flex-start;
  padding: 20px;
  width: 100%;

  ${up('large')} {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  > * + * {
    margin-top: 20px;
  }
`

const Middle = styled.div`
  flex-grow: 1;
  max-width: 100%;
  padding: 20px;

  ${up('large')} {
    padding: 32px;
  }

  > * + * {
    margin-top: 20px;
  }
`

const Inner = styled.div`
  margin-top: 32px;
`

const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${ButtonStyle} {
    margin-top: 10px;
  }
`

const Message = styled.p`
  text-align: center;
`

const MessageInner = styled.p`
  text-align: left;
`

const Search = styled.div`
  display: flex;
  justify-content: center;

  ${SearchForm} {
    max-width: 100%;
  }
`

const ValueGraph = ({ setValue }) => {
  const { profile } = useGlobals('profile')

  return (
    <PortfolioValueGraph
      noDataMessage={profile?.portfolioGraphNoDataMessage}
      explainerText={profile?.profileValueGraphExplainerText}
      setCurrentValue={setValue}
    />
  )
}

ValueGraph.propTypes = {
  setValue: PropTypes.func,
}

export default function PortfolioGrid() {
  const wrapRef = useRef()
  const [pagination, setPagination] = useState({ first: ITEMS_PER_PAGE })
  const [searchParams] = useSearchParams()
  const query = searchParams.get('q')
  const [result, reexecuteQuery] = useQuery({
    query: GET_ITEMS,
    variables: {
      keywords: query,
      ...pagination,
    },
  })
  const { data, fetching, error } = result
  const totalCount = data?.items?.totalCount
  const pageInfo = data?.items?.pageInfo
  const items = data?.items?.edges
  const [totalValue, setTotalValue] = useState()

  useEffect(() => {
    if (query) {
      setPagination({ first: ITEMS_PER_PAGE })
    }
  }, [query, setPagination])

  function refetchItems() {
    // Re-fetch items after deletion, avoiding cache
    reexecuteQuery({ requestPolicy: 'cache-and-network' })
  }

  const scrollToTop = () => {
    if (wrapRef.current) {
      scrollToElement(wrapRef.current)
    }
  }

  return (
    <>
      <Grid>
        <Side color="blue">
          <SideInner>
            {(totalValue || totalCount !== undefined) && (
              <Total
                amount={
                  totalValue?.amountCents
                    ? totalValue.amountCents / 100
                    : undefined
                }
                currency={totalValue?.currency.iso}
                items={totalCount}
              />
            )}
            <Search>
              <SearchBar
                action=""
                name="q"
                placeholder="Search portfolio listings"
              />
            </Search>
          </SideInner>
        </Side>
        <Middle>
          <ValueGraph setValue={setTotalValue} />

          <Inner ref={wrapRef}>
            {(fetching && <Loader height="200px" background="#fff" />) ||
              (error && (
                <MessageWrapper>
                  <Message>An error occurred.</Message>
                  <Button onClick={reexecuteQuery} type="button">
                    Retry?
                  </Button>
                </MessageWrapper>
              )) ||
              ((!items || !items.length) && (
                <MessageInner>
                  Your Portfolio tracks the value of your guitars over time. For
                  any guitars that we don&apos;t have Carter pricing history on,
                  you can request a free price estimate from our Appraisals
                  team.
                </MessageInner>
              )) || (
                <CardList>
                  {items.map((item) => (
                    <Card
                      key={item.node.id}
                      item={item.node}
                      refetchItems={refetchItems}
                    />
                  ))}
                </CardList>
              )}
          </Inner>
        </Middle>
      </Grid>

      <NextPrevious
        includeNext={pageInfo?.hasNextPage || !!pagination.before}
        includePrevious={pageInfo?.hasPreviousPage || !!pagination.after}
        onNext={() => {
          scrollToTop()
          setPagination({ after: pageInfo.endCursor, first: ITEMS_PER_PAGE })
        }}
        onPrevious={() => {
          scrollToTop()
          setPagination({
            before: pageInfo.startCursor,
            last: ITEMS_PER_PAGE,
          })
        }}
      />
    </>
  )
}

PortfolioGrid.propTypes = {}
