import ComboBox from '../inputs/ComboBox'

const GET_COUNTRIES = `
  query GetCountries {
    countries {
      name
      iso
    }
  }
`

const GET_COUNTRY = `
  query GetCountry($iso: String!) {
    country(iso: $iso) {
      name
      iso
    }
  }
`

export default function Country(props) {
  return <ComboBox {...props} />
}

Country.defaultProps = {
  ...ComboBox.defaultProps,
  itemLabelKey: 'name',
  itemValueKey: 'iso',
  itemKey: 'country',
  itemsKey: 'countries',
  itemQuery: GET_COUNTRY,
  itemsQuery: GET_COUNTRIES,
  lazy: false,
  placeholder: 'Search countries…',
}

Country.propTypes = ComboBox.propTypes
