export const flattenFilters = (filters) => {
  const flatFilters = []

  for (const filter of filters) {
    if (filter.key !== 'year' && filter.key !== 'price') {
      try {
        // `filter.value` is a stringified array for multiple selection filters, e.g. Brand
        const values = JSON.parse(filter.value)

        if (Array.isArray(values)) {
          for (const value of values) {
            flatFilters.push({ key: filter.key, value, multiple: true })
          }

          continue
        }
      } catch (_error) {
        // Shouldn't need to handle error, as single selection filters are expected to not parse as JSON
      }
    }

    flatFilters.push(filter)
  }

  return flatFilters
}
