import PropTypes from 'prop-types'
import {
  Appraisal2Icon,
  Authenticated2Icon,
  ChecklistIcon,
  CommunicateIcon,
  Covered1Icon,
  Covered2Icon,
  DescriptionIcon,
  DetailsIcon,
  EnvelopeIcon,
  FeesIcon,
  LockIcon,
  NetWorthIcon,
  Offer1Icon,
  Offer2Icon,
  OriginIcon,
  PackingIcon,
  PaymentIcon,
  PhotographyIcon,
  PopularityIcon,
  PriceIcon,
  ReturnsIcon,
  SeeWithEaseIcon,
  ShippingIcon,
  Shipment2Icon,
  SubmitIcon,
  TimeIcon,
  Trade1Icon,
  Trade2Icon,
  Trade3Icon,
  TrophyIcon,
  Unfulfilled1Icon,
  Unfulfilled2Icon,
  VacationModeIcon,
  ViewsIcon,
  WarningIcon,
} from '../_base/Icon'

export default function Icon({ icon }) {
  switch (icon) {
    case 'appraisal-2':
      return <Appraisal2Icon />

    case 'authenticated-2':
      return <Authenticated2Icon />

    case 'checklist':
      return <ChecklistIcon />

    case 'communicate':
      return <CommunicateIcon />

    case 'covered-1':
      return <Covered1Icon />

    case 'covered-2':
      return <Covered2Icon />

    case 'description':
      return <DescriptionIcon />

    case 'details':
      return <DetailsIcon />

    case 'envelope':
      return <EnvelopeIcon />

    case 'fees':
      return <FeesIcon />

    case 'lock':
      return <LockIcon />

    case 'net-worth':
      return <NetWorthIcon />

    case 'offer-1':
      return <Offer1Icon />

    case 'offer-2':
      return <Offer2Icon />

    case 'origin':
      return <OriginIcon />

    case 'packing':
      return <PackingIcon />

    case 'payment':
      return <PaymentIcon />

    case 'photography':
      return <PhotographyIcon />

    case 'popularity':
      return <PopularityIcon />

    case 'price':
      return <PriceIcon />

    case 'returns':
      return <ReturnsIcon />

    case 'see-with-ease':
      return <SeeWithEaseIcon />

    case 'shipping':
      return <ShippingIcon />

    case 'shipment-2':
      return <Shipment2Icon />

    case 'submit':
      return <SubmitIcon />

    case 'time':
      return <TimeIcon />

    case 'trade-1':
      return <Trade1Icon />

    case 'trade-2':
      return <Trade2Icon />

    case 'trade-3':
      return <Trade3Icon />

    case 'trophy':
      return <TrophyIcon />

    case 'unfulfilled-1':
      return <Unfulfilled1Icon />

    case 'unfulfilled-2':
      return <Unfulfilled2Icon />

    case 'vacation-mode':
      return <VacationModeIcon />

    case 'views':
      return <ViewsIcon />

    case 'warning':
      return <WarningIcon />

    default:
      return <p>Icon not found: {icon}</p>
  }
}

Icon.propTypes = {
  icon: PropTypes.string,
}
