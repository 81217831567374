import styled from 'styled-components'
import PropTypes from 'prop-types'
import { HeaderXS } from '../_abstracts/Type'
import { Icon } from '../_base/Icon'
import { down } from 'styled-breakpoints'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  background: ${(props) => props.theme.colors.accentTint};
  padding: 13px;
`

const Item = styled.div`
  min-width: 33.33%;
  flex-grow: 1;
  width: 100px;
  text-align: center;
  padding: 13px;

  ${down('small')} {
    width: 50%;
    min-width: 0;
  }

  ${Icon} {
    display: inline-block;
    width: 80px;
    height: 80px;
  }
`

const Text = styled.p`
  ${HeaderXS}
  color: inherit;
  margin-top: 10px;
`

export default function Icons({ icons }) {
  return (
    <Wrapper>
      {icons.map((item, index) => {
        return (
          <Item key={index}>
            {item.children}

            <Text>{item.text}</Text>
          </Item>
        )
      })}
    </Wrapper>
  )
}

Icons.propTypes = {
  icons: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      children: PropTypes.node.isRequired,
    })
  ).isRequired,
}
