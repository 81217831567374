import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { linkStates } from '../_abstracts/Type'
import { UserConsumer } from '../auth/UserContext'
import Link from '../_atoms/Link'
import StopImpersonatingButton from '../auth/StopImpersonatingButton'
import LogoutButton from '../auth/LogoutButton'
import Button from '../_atoms/Button'
import { Media } from '../_base/Media'
import { ContainerStyles } from '../_helpers/Container'
import { useGlobals } from '../hooks'
import Navigation from './Navigation'
import SearchBar from '../search/SearchBar'
import SearchBarWithSaved from '../search/SearchBarWithSaved'
import { TelIcon } from '../_base/Icon'

const layoutTransitionDuration = '150ms'
const layoutTransitionEase = 'cubic-bezier(0.455, 0.03, 0.515, 0.955)' // ease-in-out-quad

const StyledHeader = styled.header`
  background-color: ${(props) => props.theme.colors.white};
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  padding-right: var(--scrollbar-gap);

  @media (max-width: 989px) {
    display: none;
  }
`

const Spacer = styled.div`
  height: 168px; // Approx header height when not using compact layout

  @media (max-width: 989px) {
    display: none;
  }
`

//dark background when opening utility menu
const DarkBackground = styled.div`
  pointer-events: ${(props) => (props.state ? 'all' : 'none')};
  background-color: ${(props) =>
    props.state ? `rgba(6, 6, 45, 0.45)` : 'transparent'};
  transition: background-color 150ms ease-in-out;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
`

const TopContentWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  position: relative;
  z-index: 1;
`

const TopContentNumberWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.darkBlue};
`

const TopContentNumber = styled.div`
  ${ContainerStyles}
`

const Num = styled.div`
  background-color: ${(props) => props.theme.colors.accent};
  color: ${(props) => props.theme.colors.white};
  width: fit-content;
  min-height: 24px;
  margin-left: auto;
  padding: 0 10px;
  font-size: 14px;
  display: flex;
  align-items: center;

  a {
    text-decoration: underline;
    margin-left: 5px;
  }

  i {
    width: 17px;
    margin-right: 5px;
  }
`

const TopContent = styled.div`
  display: grid;
  grid-template-columns: 1fr minmax(max-content, 1.5fr) 1fr;
  padding-top: 18px;
  padding-bottom: 18px;
  justify-content: space-between;
  align-items: center;
  transition: padding ${layoutTransitionDuration} ${layoutTransitionEase};
  ${ContainerStyles}

  ${({ $isCompact }) =>
    $isCompact &&
    css`
      padding-top: 5px;
      padding-bottom: 5px;
    `}
`

const TopNav = styled.div`
  grid-column: 3;
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  font-size: 15px;
  text-transform: capitalize;

  > * {
    &:not(:first-child) {
      margin-left: 20px;
    }
  }

  ${Media} {
    color: ${(props) => props.theme.colors.darkBlue};
    text-decoration: none;

    &:hover {
      color: ${(props) => props.theme.colors.accent};
      text-decoration: none;
    }
  }
`

const LogoutLink = styled.button.attrs({ type: 'button' })`
  ${linkStates};
`

const LogoWrap = styled.div`
  grid-column: 1;
  display: flex;
  align-items: center;
`

const Logo = styled(Link)`
  img {
    width: 298px;
    max-width: 28vw;
    transform-origin: 0 50%;
    transition: transform ${layoutTransitionDuration} ${layoutTransitionEase};
    will-change: transform;

    ${({ $isCompact }) =>
      $isCompact &&
      css`
        transform: scale(0.82);
      `}
  }
`

const Search = styled.div`
  grid-column: 2;
  display: flex;
  justify-content: center;
  margin-right: 30px;
  margin-left: 30px;
`

const ButtonLink = () => {
  const { header } = useGlobals('header')
  const { text, url } = header?.headerButtonLink || {}

  return text && url ? (
    <Button to={url} variant="primaryRed">
      {text}
    </Button>
  ) : null
}

export default function Header({ logo }) {
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [isCompact, setIsCompact] = useState(false)

  useEffect(() => {
    const calculateLayoutProgress = () => {
      if (window.scrollY > 28) {
        setIsCompact(true)
      } else {
        setIsCompact(false)
      }
    }

    calculateLayoutProgress()

    window.addEventListener('scroll', calculateLayoutProgress, {
      passive: true,
    })

    return () => {
      window.removeEventListener('scroll', calculateLayoutProgress)
    }
  }, [])

  return (
    <UserConsumer>
      {({ user, impersonator }) => (
        <>
          <Spacer />
          <StyledHeader id="header">
            <DarkBackground state={isPopupOpen} />
            <TopContentWrapper>
              <TopContentNumberWrapper>
                <TopContentNumber>
                  <Num>
                    <TelIcon />
                    Call <a href="tel:(615) 915-1851">(615) 915-1851</a>
                  </Num>
                </TopContentNumber>
              </TopContentNumberWrapper>
              <TopContent $isCompact={isCompact}>
                <LogoWrap>
                  <Logo to="/" $isCompact={isCompact}>
                    <img
                      src={logo}
                      alt="Carter Vintage Guitars Logo"
                      width="310"
                      height="52"
                    />
                  </Logo>
                </LogoWrap>
                <Search>
                  {user ? (
                    <SearchBarWithSaved action="/shop" />
                  ) : (
                    <SearchBar action="/shop" />
                  )}
                </Search>
                <TopNav>
                  {(user && (
                    <>
                      {impersonator && (
                        <StopImpersonatingButton>
                          <LogoutLink $reverse>Stop Impersonating</LogoutLink>
                        </StopImpersonatingButton>
                      )}

                      <LogoutButton>
                        <LogoutLink $reverse>Logout</LogoutLink>
                      </LogoutButton>
                    </>
                  )) || (
                    <>
                      <Link $reverse to="/login">
                        Login/Register
                      </Link>
                    </>
                  )}
                  <ButtonLink />
                </TopNav>
              </TopContent>
            </TopContentWrapper>
            <Navigation setIsPopupOpen={setIsPopupOpen} user={user} />
          </StyledHeader>
        </>
      )}
    </UserConsumer>
  )
}

Header.defaultProps = {
  logo: '/images/logo.svg?v2',
}

Header.propTypes = {
  logo: PropTypes.string,
}
