import { Helmet } from 'react-helmet-async'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { LoginReturnNavigate } from '../_atoms/ReturnLink'
import Container from '../_helpers/Container'
import linearGradient from '../_helpers/linearGradient'
import Spacer from '../_helpers/Spacer'
import { UserConsumer } from '../auth/UserContext'
import EditItemForm from './EditItemForm'

const Wrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-image: ${linearGradient(
    'to bottom',
    'white 1 0%',
    'lighterGrey 1 500px'
  )};
`

const Inner = styled(Container)`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`

export default function EditItemPage() {
  const navigate = useNavigate()
  const { itemId } = useParams()

  return (
    <UserConsumer>
      {({ user }) => {
        if (!user) return <LoginReturnNavigate />

        return (
          <>
            <Helmet>
              <title>Profile | Portfolio | Edit Item</title>
            </Helmet>
            <Wrapper>
              <Spacer size="45px" />
              <Inner>
                <EditItemForm
                  heading="Edit portfolio item"
                  itemId={itemId}
                  onSave={({ item }) =>
                    navigate(`/profile/portfolio/${item.id}`)
                  }
                />
              </Inner>
              <Spacer fromSize="40px" toSize="80px" />
            </Wrapper>
          </>
        )
      }}
    </UserConsumer>
  )
}
