import styled from 'styled-components'
import PropTypes from 'prop-types'
import Circle, { Wrapper } from './Circle'
import { useUserContext } from '../auth/UserContext'
import { colors } from '../theme'
import { CameraIcon } from '../_base/Icon'
import Modal from '../shared/Modal'
import UserImageUpload from '../account/UserImageUpload'
import { HeaderM } from '../_abstracts/Type'

export { Wrapper }

const Upload = styled.button.attrs({ type: 'button' })`
  position: absolute;
  left: 15%;
  bottom: 17%;
  width: 40px;
  max-width: 28%;
  transform: translate(-50%, 50%);

  &:hover {
    color: ${(props) => props.theme.colors.white};

    circle {
      fill: ${(props) => props.theme.colors.darkBlue};
    }
  }
`

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 20px 32px;

  form {
    width: 100%;
  }
`

const ModalHeader = styled.h2`
  ${HeaderM};
  font-weight: 700;
  text-align: center;
  margin-bottom: 20px;
`

export default function Avatar({ user, upload, size, state }) {
  const { user: currentUser } = useUserContext()

  let image = user?.avatarImage?.url

  // If user has no avatar image, use SVG of username first character
  if (!image && user?.username) {
    const svg = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
      <circle fill="#06062D" width="64" height="64" cx="32" cy="32" r="32" />
      <text x="50%" y="50%" fill="#fff" font-size="30" dy=".1em" style="line-height: 1; font-family: Arial, sans-serif;" alignment-baseline="middle" text-anchor="middle" dominant-baseline="middle">
        ${user.username[0].toUpperCase()}
      </text>
    </svg>`

    image = `data:image/svg+xml;base64,${btoa(svg)}`
  }

  let background = colors.blueTint
  let blendMode
  let border = colors.midGrey

  switch (state) {
    case 'loggedIn':
      background = colors.white
      border = colors.accent
      break

    case 'loggedOut':
      background = colors.white
      break

    case 'error':
      background = colors.redTint
      blendMode = 'luminosity'
      border = colors.red
      break
  }

  return (
    <Circle
      background={background}
      blendMode={blendMode}
      border={border}
      image={{
        url: image,
        title: user.username,
      }}
      size={size}
    >
      {upload && user.id === currentUser?.id && (
        <Modal
          size="standard"
          trigger={
            <Upload>
              <CameraIcon />
            </Upload>
          }
        >
          <ModalWrapper>
            <ModalHeader>Add Profile Images</ModalHeader>
            <UserImageUpload />
          </ModalWrapper>
        </Modal>
      )}
    </Circle>
  )
}

Avatar.propTypes = {
  size: PropTypes.string,
  state: PropTypes.oneOf(['error', 'loggedIn', 'loggedOut']),
  upload: PropTypes.bool,
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    avatarImage: PropTypes.shape({
      url: PropTypes.string,
    }),
  }).isRequired,
}
