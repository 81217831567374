import styled from 'styled-components'
import { useQuery } from 'urql'
import Card from './Card'
import { ButtonStyle } from '../_atoms/Button'

// eslint-disable-next-line no-unused-vars
const Footer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  > *:first-child:last-child {
    margin-left: auto;
  }

  ${ButtonStyle} {
    min-width: 150px;
  }
`

const MANAGE_CARDS = `
  query ManageCards {
    cards: paymentSavedStripeCards {
      id
      name
      brand
      expMonth
      expYear
      last4
    }
  }
`

export default function ManageCards() {
  const [{ fetching, data, error }] = useQuery({
    query: MANAGE_CARDS,
    requestPolicy: 'cache-and-network',
  })

  if (fetching) return <p>Loading saved cards</p>

  if (error) {
    console.error(error)

    return <p>Unable to load saved cards</p>
  }

  return (
    <>
      {data && data.cards.length > 0 ? (
        <>
          {(data?.cards || []).map((card) => (
            <Card
              key={card.id}
              id={card.id}
              expires={`${card.expMonth}/${card.expYear}`}
              icon={card.brand.toLowerCase()}
              lastDigits={card.last4}
              title={card.brand}
              name={card.name}
            />
          ))}
        </>
      ) : (
        <p>You don&apos;t currently have any saved cards.</p>
      )}

      {/*
      <Formik>
        <Form>
          <Footer>
            <Button variant="primaryDark">Add a new card</Button>
          </Footer>
        </Form>
      </Formik>
      */}
    </>
  )
}
