import styled, { css } from 'styled-components'
import { Link as PlainLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import { HeaderSansXS, BodyS } from '../_abstracts/Type'
import { up } from 'styled-breakpoints'

export const Wrapper = styled.div`
  background: ${(props) => props.theme.colors.accentTint};
  border-top: 1px solid ${(props) => props.theme.colors.accent};
  padding-top: 14px;
  padding-bottom: 14px;
`

const List = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 909px;
  margin-left: auto;
  margin-right: auto;
`

const Link = styled(PlainLink)`
  ${BodyS}
  line-height: 20px;
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  transition: 0.15s;

  &:hover {
    transform: scale(1.1);
  }

  ${up('medium')} {
    ${HeaderSansXS}
    width: 25px;
    height: 25px;
    line-height: 23px;
  }

  ${(props) =>
    props.active === 'true'
      ? css`
          background: ${(props) => props.theme.colors.grey};
          border: 1px solid;

          &:hover {
            transform: none;
          }
        `
      : css``}

  ${(props) =>
    props.disabled
      ? css`
          opacity: 0.25;
          cursor: default;

          &:hover {
            transform: none;
          }
        `
      : css`
          cursor: pointer;
        `}
`

const First = styled.li`
  padding: 5px;
  margin-right: auto;
  display: none;

  ${up('medium')} {
    padding: 8px;
    display: inline-block;
  }

  ${Link} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 28px;
    height: auto;
    border-color: transparent;

    &::before {
      content: '';
      display: inline-block;
      height: 17px;
      width: 1px;
      vertical-align: middle;
      background-color: currentColor;
    }

    &::after {
      width: 12px;
      height: 12px;
      display: inline-block;
      content: '';
      border-right: 1px solid;
      border-top: 1px solid;
      transform: rotate(-135deg);
    }
  }
`

const Previous = styled.li`
  display: inline-block;
  padding: 5px;

  ${up('medium')} {
    padding: 8px;
  }

  ${Link} {
    width: auto;
    height: auto;
    border-color: transparent;

    &::before {
      width: 12px;
      height: 12px;
      display: block;
      content: '';
      border-right: 1px solid;
      border-top: 1px solid;
      transform: rotate(-135deg);
    }
  }
`

const PageNum = styled.li`
  display: inline-block;
  padding: 5px;

  ${up('medium')} {
    padding: 8px;
  }
`

const Next = styled.li`
  display: inline-block;
  padding: 5px;

  ${up('medium')} {
    padding: 8px;
  }

  ${Link} {
    height: auto;
    border-color: transparent;
    width: auto;

    &::before {
      width: 12px;
      height: 12px;
      display: block;
      content: '';
      border-right: 1px solid;
      border-top: 1px solid;
      transform: rotate(45deg);
    }
  }
`

const Last = styled.li`
  padding: 5px;
  margin-left: auto;
  display: none;

  ${up('medium')} {
    padding: 8px;
    display: inline-block;
  }

  ${Link} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 28px;
    height: auto;
    border-color: transparent;

    &::after {
      content: '';
      display: inline-block;
      height: 17px;
      width: 1px;
      vertical-align: middle;
      background-color: currentColor;
    }

    &::before {
      width: 12px;
      height: 12px;
      display: inline-block;
      content: '';
      border-right: 1px solid;
      border-top: 1px solid;
      transform: rotate(45deg);
    }
  }
`

export default function Pagination({
  first,
  last,
  current,
  pages,
  prefix,
  index,
  buildLink,
  onClick,
  scrollToTop = true,
}) {
  const separator = 5
  return (
    <Wrapper>
      <List>
        <First>
          <Link
            disabled={first}
            to={buildLink ? buildLink(1) : index}
            onClick={(event) =>
              first ? event.preventDefault() : onClick?.(event, 1)
            }
            aria-label="Go to first page"
            state={{ scrollToTop }}
          ></Link>
        </First>

        <Previous>
          <Link
            disabled={first}
            to={buildLink ? buildLink(current - 1) : `${prefix}${current - 1}`}
            onClick={(event) =>
              first ? event.preventDefault() : onClick?.(event, current - 1)
            }
            aria-label="Go to previous page"
            state={{ scrollToTop }}
          ></Link>
        </Previous>

        {[...Array(pages).keys()].map((item) => {
          const page = item + 1

          if (pages > separator && page >= separator && page <= pages - 1) {
            if (page == separator) {
              return (
                <PageNum key="…">
                  <span>…</span>
                </PageNum>
              )
            }
          } else {
            return (
              <PageNum key={page}>
                <Link
                  active={page === current ? 'true' : 'false'}
                  to={buildLink ? buildLink(page) : `${prefix}${page}`}
                  onClick={(event) => onClick?.(event, page)}
                  state={{ scrollToTop }}
                >
                  {page}
                </Link>
              </PageNum>
            )
          }
        })}

        <Next>
          <Link
            disabled={last}
            to={buildLink ? buildLink(current + 1) : `${prefix}${current + 1}`}
            onClick={(event) =>
              last ? event.preventDefault() : onClick?.(event, current + 1)
            }
            aria-label="Go to next page"
            state={{ scrollToTop }}
          ></Link>
        </Next>

        <Last>
          <Link
            disabled={last}
            to={buildLink ? buildLink(pages) : `${prefix}${pages}`}
            onClick={(event) =>
              last ? event.preventDefault() : onClick?.(event, pages)
            }
            aria-label="Go to last page"
            state={{ scrollToTop }}
          ></Link>
        </Last>
      </List>
    </Wrapper>
  )
}

Pagination.propTypes = {
  first: PropTypes.bool,
  last: PropTypes.bool,
  current: PropTypes.number,
  pages: PropTypes.number,
  prefix: PropTypes.string,
  index: PropTypes.string,
  buildLink: PropTypes.func,
  onClick: PropTypes.func,
  scrollToTop: PropTypes.bool,
}
