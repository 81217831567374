import PropTypes from 'prop-types'
import styled from 'styled-components'
import Accordion from '../blocks/Accordion'
import Grid from '../blocks/Grid'
import Heading, { Element as HeadingElement } from '../blocks/Heading'
import ProsAndCons from '../blocks/ProsAndCons'
import Masonry from '../blocks/Masonry'
import Form from '../blocks/Form'
import Sections from '../blocks/Sections'
import Text, { Element as TextElement } from '../blocks/Text'
import Html from '../data/Html'

export const blocksQueryFields = `
  blocks {
    ... on blocks_accordion_BlockType {
      accordion {
        ... on accordion_item_BlockType {
          text
          itemTitle
        }
      }
      typeHandle
    }
    ... on blocks_heading_BlockType {
      heading
      headingSize
      typeHandle
    }
    ... on blocks_masonry_BlockType {
      typeHandle
      photos {
        ... on photos_image_BlockType {
          id
          image {
            url(handle: "medium")
          }
          text
        }
      }
    }
    ... on blocks_sections_BlockType {
      numbered
      typeHandle
      children {
        ... on blocks_sectionItem_BlockType {
          typeHandle
          children {
            typeHandle
            ... on blocks_text_BlockType {
              text
              textFormat
              typeHandle
            }
          }
          image {
            url(handle: "medium")
            title
          }
          icon
          heading
          text
          typeHandle
          buttons {
            ... on buttons_item_BlockType {
              buttonTheme
              itemLink {
                url
                text
              }
              openChat
            }
          }
        }
      }
    }
    ... on blocks_text_BlockType {
      text
      textFormat
      typeHandle
    }
    ... on blocks_form_BlockType {
      formSuccessMessage
      form {
        handle
      }
      typeHandle
    }
    ... on blocks_grid_BlockType {
      numbered
      grid {
        ... on grid_item_BlockType {
          itemTitle
          text
          isSmall
          image {
            url(handle: "medium")
          }
        }
      }
      typeHandle
    }
    ... on blocks_prosAndCons_BlockType {
      children {
        ... on blocks_pros_BlockType {
          heading
          listTextSimple {
            ... on listTextSimple_item_BlockType {
              text
            }
          }
          typeHandle
        }
        ... on blocks_cons_BlockType {
          heading
          listTextSimple {
            ... on listTextSimple_item_BlockType {
              text
            }
          }
          typeHandle
        }
      }
      typeHandle
    }
    ... on blocks_embed_BlockType {
      embed
      typeHandle
    }
  }
`

const Wrapper = styled.div`
  > * + * {
    margin-top: 35px;
  }

  > ${HeadingElement} + ${TextElement} {
    margin-top: 20px;
  }
`

export default function Blocks({ blocks }) {
  return (
    <Wrapper>
      {blocks.map((block, index) => {
        const key = `${index}-${block.typeHandle}`

        switch (block.typeHandle) {
          case 'accordion':
            return <Accordion {...block} key={key} />

          case 'grid':
            return <Grid {...block} key={key} />

          case 'heading':
            return <Heading {...block} key={key} />

          case 'masonry':
            return <Masonry {...block} key={key} />

          case 'prosAndCons':
            return <ProsAndCons {...block} key={key} />

          case 'sections':
            return <Sections {...block} key={key} />

          case 'form':
            return <Form {...block} key={key} />

          case 'text':
            return <Text {...block} key={key} />

          case 'embed':
            return <Html html={block.embed} dangerousRaw key={key} />

          default:
            console.error('Unrecognised block type', block)
            return <p key={key}>Block type missing: {block.typeHandle}</p>
        }
      })}
    </Wrapper>
  )
}

Blocks.propTypes = {
  blocks: PropTypes.arrayOf(PropTypes.object),
}
