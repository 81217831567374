import { hideVisually } from 'polished'
import PropTypes from 'prop-types'
import styled from 'styled-components'

export const StyledLabel = styled.label`
  display: inline-block;
  font-size: 14px;

  ${({ hide }) => hide && hideVisually()}
`

export default function Label(props) {
  return (
    <StyledLabel as={props.as} hide={props.hide} htmlFor={props.for}>
      {props.children}
    </StyledLabel>
  )
}

Label.propTypes = {
  as: PropTypes.string,
  children: PropTypes.node.isRequired,
  hide: PropTypes.bool,
  for: PropTypes.string,
}
