const GET_GlOBAL_SET = `
  query GetListingForm {
    globalSet(handle: "listingForm") {
      ... on listingForm_GlobalSet {
        id

        # Details
        listingFormDetailsTitle
        listingFormDetailsHelp
        listingFormDetailsText

        # Description
        listingFormDescriptionTitle
        listingFormDescriptionHelp
        yourPriceEstimateHelpText

        # Photos
        listingFormPhotosTitle
        listingFormPhotosHelp

        # Price & Offers
        listingFormPriceTitle
        listingFormPriceHelp

        # Privacy & Offers
        listingFormPrivacyTitle
        listingFormPrivacyHelp

        # Shipping
        listingFormShippingTitle
        listingFormShippingHelp
        listingFormShippingText
        listingFormShippingCosts
        listingFormSecurePackingPrice
        listingFormSecurePackingYesLabel
        listingFormSecurePackingNoLabel

        # Type
        listingFormTypeTitle
        listingFormTypeHelp
        listingFormTypeText
        planSelect {
          ... on plans_default_Entry {
            id
            plansHandle
            plansLongTitle
            plansDetails
            plansIcon
            plansLabel
            plansSubtitle
            tooltip
            plansTheme
            plansList {
              ... on plansList_item_BlockType {
                id
                icon
                plansLabel
              }
            }
          }
        }

        # Brand Request
        listingFormBrandRequestTitle
        listingFormBrandRequestText
        listingFormBrandRequestSuccessMessage
        listingFormBrandRequestMessageText
        listingFormBrandRequestMessageLink

        # Model Request
        listingFormModelRequestTitle
        listingFormModelRequestText
        listingFormModelRequestSuccessMessage
        listingFormModelRequestMessageText
        listingFormModelRequestMessageLink

        # Finish Request
        listingFormFinishRequestTitle
        listingFormFinishRequestText
        listingFormFinishRequestSuccessMessage
        listingFormFinishRequestMessageText
        listingFormFinishRequestMessageLink

        # Repair Terms
        listingFormRepairTerms
      }
    }
  }
`

export default GET_GlOBAL_SET
