import PropTypes from 'prop-types'
import toast from 'react-hot-toast'
import { useEffect, useState } from 'react'
import { up } from 'styled-breakpoints'
import styled, { css } from 'styled-components'
import { useQuery, useMutation } from 'urql'
import Transition from '../_abstracts/Animation'
import Button, { ButtonStyle } from '../_atoms/Button'
import StyledLink from '../_atoms/Link'
import {
  Icon,
  HeartIcon,
  BasketIcon,
  NotificationsIcon,
  MailIcon,
  CrossIcon,
  PortfolioIcon,
  SavedSearchesIcon,
} from '../_base/Icon'
import Container from '../_helpers/Container'
import LogoutButton from '../auth/LogoutButton'
import { CartConsumer } from '../cart/CartContext'
import Link from './Link'
import Loader from './Loader'
import Popup, {
  Container as PopupContainer,
  Section as PopupSection,
} from './Popup'
import { useUnreadMessageCount } from '../hooks'
import Badge from './Badge'
import { useUserContext } from '../auth/UserContext'

export const GET_NAVIGATION = `
  query GetNavigation($handle: String!) {
    nodes(navHandle: $handle, level: 1) {
      id
      title
      url
      ... on headerNavigation_Node {
        id
        attributes: customAttributes {
          attribute
          value
        }
        children {
          id
          title
          url

          ...on headerNavigation_Node {
            id
            attributes: customAttributes {
              attribute
              value
            }
            navHighlightItem
          }
        }
        navHighlightItem
      }
    }
  }
`

const GET_UTILITY_MENU = (handle) => `
  query GetNavigation($handle: String!) {
    nodes (navHandle: $handle, level: 1) {
      id
      title
      url
      ... on ${handle}_Node {
        navUtilityMenuPosition
        navDesktop
      }
    }
  }
`

const GET_NOTIFICATIONS = `
  query GetNotifications {
    notifications {
      pageInfo {
        hasNextPage
        startCursor
        endCursor
      }

      edges {
        cursor
        node {
          id

          ...on OfferNotification {
            offer {
              id
              item {
                id
                title
              }
              root {
                toAccount { id }
              }
            }
          }

          ...on MessageNotification {
            thread {
              id
            }
          }

          ...on OrderNotification {
            order {
              id
            }
          }
        }
      }
    }
  }
`

const DISMISS_NOTIFICATION = `
  mutation DismissNotification($notificationIds: [ID!]!) {
    notificationDismiss(notificationIds: $notificationIds) {
      success
    }
  }
`

const Wrapper = styled.div`
  border-top: 1px solid ${(props) => props.theme.colors.blueTint};
  border-bottom: 1px solid ${(props) => props.theme.colors.blueTint};
  background-color: ${(props) => props.theme.colors.white};
  position: relative;
  text-transform: capitalize;

  @media print {
    display: none;
  }
`

const Inner = styled.div`
  display: flex;
`

const Item = styled.div`
  max-width: 160px;
  display: flex;
  white-space: nowrap;
  border-right: 1px solid ${(props) => props.theme.colors.blueTint};
  padding: 7px 15px;

  &:last-child {
    border-right: 0;
  }

  > a,
  > button {
    ${Transition({ property: 'color' })};
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: ${(props) => props.theme.colors.darkBlue};

    ${up('xlarge')} {
      min-width: 68px;
    }

    &:hover {
      color: ${(props) => props.theme.colors.accent};
      text-decoration: none;
    }

    ${(props) =>
      props.icon &&
      css`
        justify-content: flex-end;
      `}

    ${(props) =>
      props.highlight &&
      css`
        color: ${props.theme.colors.accent};

        &:hover {
          color: ${(props) => props.theme.colors.darkBlue};
        }
      `}

    > ${Icon} {
      margin-top: auto;
      margin-bottom: auto;

      ${(props) =>
        (props.portfolio &&
          css`
            width: 19px;
          `) ||
        (props.savedSearches &&
          css`
            position: relative;
            top: -1px;
            width: 23px;
          `) ||
        css`
          width: 24px;
        `}
    }

    > ${Badge} {
      position: absolute;
      top: 15%;
      right: 15%;
    }
  }

  ${PopupContainer} {
    white-space: normal;
  }
`

const ItemLabel = styled.span`
  font-size: 12px;
  line-height: 1;
`

const Section = styled.div`
  flex-shrink: 0;
  display: flex;
  margin-left: auto;
  border-left: 1px solid ${(props) => props.theme.colors.blueTint};
`

const Utility = styled.div`
  display: flex;
  align-items: center;
  padding: 9px 0 9px 18px;
`

const UtilityMenuButton = styled.button.attrs({ type: 'button' })`
  ${Transition({ property: 'color' })};
  cursor: pointer;
  display: block;
  position: relative;
  overflow: hidden;

  &:hover {
    color: ${(props) => props.theme.colors.accent};
  }
`

const UtilityPadding = '30px'

const UtilityMenuWrapper = styled.div`
  text-align: left;

  a {
    display: block;
    padding: 5px 10px;
    border-radius: ${(props) => props.theme.radii};
    transition-property: text-decoration-color, color, background-color;

    &:hover {
      background-color: ${(props) => props.theme.colors.lightGrey};
    }
  }
`

const popupInnerStyles = css`
  width: 265px;
  padding: 0;
`

const UtilityLinks = styled.ul`
  padding-top: 15px;
  padding-bottom: 10px;
  margin-left: ${UtilityPadding};
  margin-right: ${UtilityPadding};
`

const HelperLinks = styled.ul`
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: ${UtilityPadding};
  margin-right: ${UtilityPadding};
  font-size: 14px;
  border-top: 1px solid ${(props) => props.theme.colors.lightGrey};
  color: ${(props) => props.theme.colors.xDarkGrey};
`

const UtilityBottom = styled.div`
  background-color: ${(props) => props.theme.colors.lightGrey};
  padding: 10px ${UtilityPadding};
  display: flex;
  flex-direction: column;
  text-align: center;

  &:first-child {
    background-color: transparent;
  }

  a,
  button {
    margin-top: 7px;

    &:last-child {
      margin-bottom: 12px;
    }
  }

  a {
    font-size: 14px;
  }
`

const LinksNav = styled.nav`
  position: relative;
  min-width: 0;
  flex-grow: 1;
  display: flex;
  font-size: 15px;
`

const LinksList = styled.ul`
  flex-grow: 1;
  display: flex;

  ${Item} {
    flex-grow: 1;

    li {
      font-weight: 700;
    }

    a {
      font-weight: 400;
    }

    &:first-child {
      a {
        padding-left: 0;
      }
    }

    &:last-child {
      li {
        font-weight: 700;
      }
    }
  }
`

const Message = styled.p`
  align-self: center;
`

const Dropdown = styled.ul`
  text-align: left;

  a {
    display: block;
    padding: 5px 10px;
    border-radius: ${(props) => props.theme.radii};
    transition-property: text-decoration-color, color, background-color;

    &:hover {
      background-color: ${(props) => props.theme.colors.lightGrey};
    }

    > &:last-child {
      font-weight: 700 !important:
      padding: 5px 0 !important;
    }
  }
`

const StyledNotification = styled.li`
  ${Transition({ property: 'opacity' })};
  position: relative;
  padding-top: 18px;
  margin-top: 18px;
  font-size: 14px;
  text-align: left;
  border-top: 1px solid ${({ theme }) => theme.colors.blueTint};
  list-style: none;

  &::marker {
    display: none;
  }

  &:first-child {
    padding-top: 0;
    margin-top: 0;
    border-top: 0;
  }

  ${({ isDismissing }) =>
    isDismissing &&
    css`
      opacity: 0.5;
    `}

  span {
    display: block;
    line-height: 1.4;
  }

  ${ButtonStyle} {
    margin-top: 8px;
  }
`

const DismissNotification = styled.button.attrs({ type: 'button' })`
  ${Transition({ property: 'color, background-color' })};
  position: absolute;
  top: 18px;
  right: 0;
  padding: 4px;
  border-radius: 30px;
  background-color: ${({ theme }) => theme.colors.darkGoldTint};

  ${StyledNotification}:first-child & {
    top: 0;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.accent};
  }

  ${Icon} {
    display: block;
    width: 11px;
    height: 11px;
  }
`

const dropdownStyles = css`
  width: 265px;
`

const MessagesLink = styled(StyledLink)`
  display: flex;
  justify-content: center;
  align-items: center;

  ${Icon} {
    width: 24px;
    margin-right: 8px;
  }

  ${Badge} {
    margin-left: 7px;
  }
`

const ItemText = styled.p`
  font-size: 15px;
`

const UtilityMenu = ({ user }) => {
  const handle = user
    ? 'headerUtilityMenuLoggedIn'
    : 'headerUtilityMenuLoggedOut'

  const [{ data, fetching, error }] = useQuery({
    query: GET_UTILITY_MENU(handle),
    variables: {
      handle,
    },
  })

  if (fetching) return <Loader background="#fff" height="150px" />

  if (error) return <Message>{error.toString()}</Message>

  // Only include links with `navDesktop` field enabled
  const links = data?.nodes?.filter((link) => link.navDesktop) || []

  const topLinks = links.filter(
    (link) =>
      link.navUtilityMenuPosition === 'top' || !link.navUtilityMenuPosition
  )
  const middleLinks = links.filter(
    (link) => link.navUtilityMenuPosition === 'middle'
  )
  const bottomLinks = links.filter(
    (link) => link.navUtilityMenuPosition === 'bottom'
  )

  return (
    <UtilityMenuWrapper>
      {topLinks.length ? (
        <UtilityLinks>
          {topLinks.map((link, index) => (
            <li key={index}>
              <StyledLink to={link.url} $reverse>
                {link.title}
              </StyledLink>
            </li>
          ))}
        </UtilityLinks>
      ) : null}
      {middleLinks.length ? (
        <HelperLinks>
          {middleLinks.map((link, index) => (
            <li key={index}>
              <StyledLink to={link.url} $reverse>
                {link.title}
              </StyledLink>
            </li>
          ))}
        </HelperLinks>
      ) : null}
      <UtilityBottom>
        {(user && (
          <LogoutButton>
            <Button variant="primaryDark">Logout</Button>
          </LogoutButton>
        )) || (
          <Button to="/login" variant="primaryDark">
            Login
          </Button>
        )}
        {bottomLinks.map((link, index) => (
          <StyledLink key={index} to={link.url} $reverse>
            {link.title}
          </StyledLink>
        ))}
      </UtilityBottom>
    </UtilityMenuWrapper>
  )
}

UtilityMenu.propTypes = {
  user: PropTypes.object,
}

const Stl = styled(Link)``

const DropdownMenu = ({ nodes, setIsPopupOpen, title }) => {
  return (
    <Popup
      includeClose={false}
      innerStyles={dropdownStyles}
      onToggle={setIsPopupOpen}
      portal={false}
      scrollable
      trigger={
        <button type="button">
          <span>{title}</span>
        </button>
      }
    >
      <Dropdown>
        {nodes.map((item) => (
          <li
            key={item.id}
            as="li"
            style={{
              fontWeight: item.navHighlightItem ? 'bold' : 'normal',
              padding: item.navHighlightItem ? '5px 0' : '0',
            }}
          >
            {item.url ? (
              <Stl
                style={{
                  fontWeight: item.navHighlightItem ? 'bold' : 'normal',
                  padding: item.navHighlightItem ? '5px 0' : '5px 10px',
                }}
                robots={robots(item.attributes)}
                to={item.url}
                $reverse
              >
                {item.title}
              </Stl>
            ) : (
              item.title
            )}
          </li>
        ))}
      </Dropdown>
    </Popup>
  )
}

DropdownMenu.propTypes = {
  nodes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      url: PropTypes.string,
      attributes: PropTypes.arrayOf(
        PropTypes.shape({
          attribute: PropTypes.string,
          value: PropTypes.string,
        })
      ),
    })
  ),
  setIsPopupOpen: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
}

export const robots = (attributes) => {
  const record = attributes?.find((r) => r.attribute === 'robots')

  if (record) {
    return record.value
  }
}

const Links = ({ setIsPopupOpen }) => {
  const [{ data, fetching, error }] = useQuery({
    query: GET_NAVIGATION,
    variables: {
      handle: 'headerNavigation',
    },
  })

  return (
    <LinksNav>
      {(fetching && <Message>Loading…</Message>) ||
        (error && <Message>{error.toString()}</Message>) || (
          <>
            <LinksList>
              {data?.nodes?.map((item) => (
                <Item key={item.id} highlight={item.navHighlightItem} as="li">
                  {(item.url && (
                    <Link to={item.url} rel={robots(item.attributes)}>
                      {item.title}
                    </Link>
                  )) ||
                    (item.children.length && (
                      <DropdownMenu
                        nodes={item.children}
                        setIsPopupOpen={setIsPopupOpen}
                        title={item.title}
                      >
                        {item.title}
                      </DropdownMenu>
                    )) ||
                    item.title}
                </Item>
              ))}
            </LinksList>
          </>
        )}
    </LinksNav>
  )
}

Links.propTypes = {
  setIsPopupOpen: PropTypes.func.isRequired,
}

const Notification = ({ id, offer, thread, order, onDismiss }) => {
  const [{ fetching }, dismiss] = useMutation(DISMISS_NOTIFICATION)
  const { user } = useUserContext()

  async function handleDismiss() {
    const promise = dismiss({ notificationIds: [id] })

    toast.promise(promise, {
      loading: 'Dismissing notification...',
      success: 'Notification dismissed',
      error: 'Unable to dismiss notification',
    })

    const { data } = await promise

    if (data?.notificationDismiss?.success) {
      onDismiss(id)
    }
  }

  return (
    <StyledNotification isDismissing={fetching}>
      {(offer && (
        <>
          <p>
            <strong>New Offer Update</strong>
            <br />
            <span>{offer.item.title}</span>
          </p>
          {user.id === offer?.root?.toAccount?.id ? (
            <Button
              to="/profile/selling/offers"
              variant="small"
              onClick={handleDismiss}
            >
              View Offers
            </Button>
          ) : (
            <Button
              to="/profile/buying/offers"
              variant="small"
              onClick={handleDismiss}
            >
              View Offers
            </Button>
          )}
        </>
      )) ||
        (thread && (
          <>
            <p>
              <strong>New Message Update</strong>
            </p>
            <Button
              to={`/messages/${thread.id}`}
              variant="small"
              onClick={handleDismiss}
            >
              View Message
            </Button>
          </>
        )) ||
        (order && (
          <>
            <p>
              <strong>New Order Update</strong>
            </p>
            <Button
              to="/profile/selling/orders"
              variant="small"
              onClick={handleDismiss}
            >
              View Orders
            </Button>
          </>
        ))}
      <DismissNotification
        disabled={fetching}
        onClick={handleDismiss}
        aria-label="Dismiss notification"
      >
        <CrossIcon />
      </DismissNotification>
    </StyledNotification>
  )
}

Notification.propTypes = {
  id: PropTypes.string.isRequired,
  offer: PropTypes.shape({
    root: PropTypes.shape({
      toAccount: PropTypes.shape({
        id: PropTypes.string,
      }),
    }),
    item: PropTypes.shape({
      title: PropTypes.string.isRequired,
    }).isRequired,
  }),
  thread: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  order: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  onDismiss: PropTypes.func.isRequired,
}

export const Notifications = ({ includeLabel, setIsPopupOpen }) => {
  const [notifications, setNotifications] = useState([])
  const [
    { data, fetching: notificationsFetching, error: notificationsError },
    reexecuteQuery,
  ] = useQuery({
    query: GET_NOTIFICATIONS,
  })

  useEffect(() => {
    if (!notificationsFetching) {
      const id = setInterval(
        () => reexecuteQuery({ requestPolicy: 'cache-and-network' }),
        60000
      )
      return () => clearTimeout(id)
    }
  }, [notificationsFetching, reexecuteQuery])

  useEffect(() => {
    if (
      data?.notifications?.edges &&
      (!data.notifications.edges.length ||
        // TODO: Find out why notifications were being overwritten by blank nodes without offer/message/order data
        data.notifications.edges[0].node?.offer ||
        data.notifications.edges[0].node?.thread ||
        data.notifications.edges[0].node?.order)
    ) {
      setNotifications(
        data.notifications.edges.map((notification) => notification.node)
      )
    }
  }, [data, setNotifications])

  const {
    unreadMessageCount,
    fetching: messagesFetching,
    error: messagesError,
  } = useUnreadMessageCount()
  const fetching = notificationsFetching || messagesFetching
  const error = notificationsError || messagesError
  return (
    <Popup
      includeClose={false}
      innerStyles={dropdownStyles}
      onToggle={setIsPopupOpen}
      portal={false}
      scrollable
      trigger={
        <button type="button">
          <NotificationsIcon />
          {notifications?.length ? <Badge>{notifications.length}</Badge> : null}
          {includeLabel && <ItemLabel>Notifications</ItemLabel>}
        </button>
      }
    >
      {(fetching && <Loader background="#fff" height="150px" />) ||
        (error && <Message>{error.message}</Message>) || (
          <>
            <PopupSection>
              {notifications.length ? (
                <ul>
                  {notifications.map((notification) => (
                    <Notification
                      key={notification.id}
                      onDismiss={() =>
                        reexecuteQuery({ requestPolicy: 'cache-and-network' })
                      }
                      {...notification}
                    />
                  ))}
                </ul>
              ) : (
                <Message>No new notifications</Message>
              )}
            </PopupSection>
            <PopupSection>
              <MessagesLink to="/messages">
                <MailIcon />
                <span>View messages</span>
                <Badge>{unreadMessageCount}</Badge>
              </MessagesLink>
            </PopupSection>
          </>
        )}
    </Popup>
  )
}

Notifications.propTypes = {
  includeLabel: PropTypes.bool,
  setIsPopupOpen: PropTypes.func.isRequired,
}

export default function Navigation({ setIsPopupOpen, user }) {
  const [isLinksDropdownOpen, setIsLinksDropdownOpen] = useState(false)
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false)
  const [isUtilityMenuOpen, setIsUtilityMenuOpen] = useState(false)

  useEffect(() => {
    setIsPopupOpen(
      isLinksDropdownOpen || isNotificationsOpen || isUtilityMenuOpen
    )
  }, [
    isLinksDropdownOpen,
    isNotificationsOpen,
    isUtilityMenuOpen,
    setIsPopupOpen,
  ])

  return (
    <Wrapper>
      <Container>
        <Inner>
          <Links setIsPopupOpen={setIsLinksDropdownOpen} />
          <Section>
            {user && (
              <>
                <Item icon portfolio>
                  <Link to="/profile/portfolio">
                    <PortfolioIcon />
                    <ItemLabel>Portfolio</ItemLabel>
                  </Link>
                </Item>
                <Item icon savedSearches>
                  <Link to="/my-saved-searches">
                    <SavedSearchesIcon />
                    <ItemLabel>Saved Searches</ItemLabel>
                  </Link>
                </Item>
                <Item icon>
                  <Link to="/wishlist">
                    <HeartIcon />
                    <ItemLabel>Wishlist</ItemLabel>
                  </Link>
                </Item>
                <Item icon>
                  <Notifications
                    includeLabel
                    setIsPopupOpen={setIsNotificationsOpen}
                  />
                </Item>
              </>
            )}
            <Item icon>
              <CartConsumer>
                {({ openModal, totalItems }) => (
                  // Show modal cart on click, but support middle-clicking to open cart page in new tab
                  <a
                    href="/cart"
                    onClick={(ev) => {
                      ev.preventDefault()
                      openModal()
                    }}
                  >
                    <BasketIcon />
                    {totalItems ? <Badge>{totalItems}</Badge> : null}
                    <ItemLabel>Cart</ItemLabel>
                  </a>
                )}
              </CartConsumer>
            </Item>
            <Utility>
              <Popup
                includeClose={false}
                innerStyles={popupInnerStyles}
                onToggle={setIsUtilityMenuOpen}
                portal={false}
                scrollable
                trigger={
                  <UtilityMenuButton state={isUtilityMenuOpen}>
                    <ItemText>Profile</ItemText>
                  </UtilityMenuButton>
                }
              >
                <UtilityMenu user={user} />
              </Popup>
            </Utility>
          </Section>
        </Inner>
      </Container>
    </Wrapper>
  )
}

Navigation.propTypes = {
  setIsPopupOpen: PropTypes.func,
  user: PropTypes.object,
}
