const GET_GlOBAL_SET = `
  query GetHeader {
    globalSet(handle: "header") {
      ... on header_GlobalSet {
        id
        headerButtonLink {
          text
          url
        }
      }
    }
  }
`

export default GET_GlOBAL_SET
