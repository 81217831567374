import PropTypes from 'prop-types'
import styled from 'styled-components'
import { HeaderS } from '../_abstracts/Type'
import DateTime from '../data/DateTime'
import Money from '../data/Money'
import Alert, { Wrapper as AlertWrapper } from '../shared/Alert'

const Wrapper = styled.div`
  max-width: 827px;
  padding: 50px 20px;
  margin-right: auto;
  margin-left: auto;
`

const Header = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -8px -20px;
`

const Details = styled.div`
  margin-right: auto;
  margin: 8px auto 8px 20px;
`

const Heading = styled.h2`
  ${HeaderS};
`

const Info = styled.p`
  margin-top: 5px;
  font-size: 14px;
`

const Content = styled.div`
  margin-top: 20px;
`

const Breakdown = styled.div`
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 5px;
  margin-bottom: 30px;
  border-top: 1px solid ${({ theme }) => theme.colors.blueTint};
  border-bottom: 1px solid ${({ theme }) => theme.colors.blueTint};

  table {
    min-width: 30%;
    margin-left: auto;
    border-collapse: collapse;
    line-height: 1.4;
  }

  td {
    padding: 0.5em 1em;
    vertical-align: baseline;
    border-top: 1px solid ${({ theme }) => theme.colors.blueTint};

    &:first-child {
      padding-left: 0;
      font-size: 14px;
    }

    &:last-child {
      padding-right: 0;
      text-align: right;
    }
  }

  tr {
    &:first-child td {
      padding-top: 0;
      border-top: 0;
    }

    &:last-child td {
      padding-bottom: 0;
      font-weight: 700;
    }
  }
`

const Footer = styled.div`
  display: flex;

  ${AlertWrapper} {
    margin-left: auto;
  }
`

export default function Invoice({ refund, breakdown, orderDate, orderNumber }) {
  return (
    <Wrapper>
      <Header>
        <Details>
          <Heading>
            Invoice for {refund ? 'refund' : 'order'} {orderNumber}
          </Heading>
          <Info>
            <DateTime date={orderDate} noTime />
          </Info>
        </Details>
      </Header>
      <Content>
        <p>Total {refund ? 'refunded' : 'sold'}</p>
        <Breakdown>
          <table>
            <tbody>
              {breakdown.map((line, index) => (
                <tr key={index}>
                  <td>{line.name}</td>
                  <td>
                    <Money
                      amount={line.amount}
                      currency={line.currency}
                      includeCurrency
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Breakdown>
      </Content>
      <Footer>
        <Alert>
          If you have any questions about your statement contact us{' '}
          <a href="/contact">here</a> {/* TODO: Add correct contact URL */}
        </Alert>
      </Footer>
    </Wrapper>
  )
}

Invoice.propTypes = {
  refund: PropTypes.bool,
  breakdown: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      amount: PropTypes.string.isRequired,
      currency: PropTypes.string.isRequired,
    })
  ).isRequired,
  orderDate: PropTypes.instanceOf(Date).isRequired,
  orderNumber: PropTypes.string.isRequired,
}
