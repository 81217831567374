import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { useQuery } from 'urql'
import Button from '../_atoms/Button'

const GET_OFFER_THREAD = `
  query GetOfferThread($filter: MessageThreadParticipantFilter!) {
    messageThreadByParticipants(filter: $filter) {
      id
    }
  }
`

export default function OfferReplyButton({
  participantId,
  itemId,
  label = 'Reply',
}) {
  const navigate = useNavigate()
  const [pause, setPause] = useState(true)
  const [{ data, fetching, error }] = useQuery({
    query: GET_OFFER_THREAD,
    variables: {
      filter: {
        accountId: [participantId],
        itemId,
      },
    },
    pause,
  })

  if (error) {
    console.error(error)
  }

  useEffect(() => {
    if (data) {
      const threadId = data.messageThreadByParticipants?.id

      if (threadId) {
        // Redirect to existing thread page
        navigate(`/messages/${threadId}`)
      } else {
        // Redirect to new thread creation page as a fallback
        navigate(
          `/messages/compose?itemId=${itemId}&accountId=${participantId}`
        )
      }
    }

    let toastId
    if (fetching) {
      toastId = toast.loading('Redirecting…')
    }

    return () => {
      if (toastId) {
        toast.dismiss(toastId)
      }
    }
  }, [data, fetching, itemId, navigate, participantId])

  return (
    <Button fullWidth variant="secondary" onClick={() => setPause(false)}>
      {label}
    </Button>
  )
}

OfferReplyButton.propTypes = {
  participantId: PropTypes.string.isRequired,
  itemId: PropTypes.string.isRequired,
  label: PropTypes.string,
}
