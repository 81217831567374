import { Field, useFormikContext } from 'formik'
import PropTypes from 'prop-types'
import A11yElement from '../_base/Accessibility'
import { BodyS, HeaderS, HeaderXS } from '../_abstracts/Type'
import Transition from '../_abstracts/Animation'
import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  width: 100%;

  ${(props) =>
    props.wrapper &&
    css`
      display: inline-flex;
      flex-direction: column;
      padding: ${props.padding || '11px 15px'};
      width: auto;
      border: 1px solid ${(props) => props.theme.colors.midGrey};
      border-radius: ${(props) => props.theme.radii};

      ${props.center &&
      css`
        align-items: center;
      `}

      ${props.verticalCenter &&
      css`
        justify-content: center;
      `}

      ${Inner} {
        width: auto;
      }
    `};

  ${(props) =>
    props.wrapper &&
    props.checked &&
    css`
      border-color: ${(props) => props.theme.colors.accent};
      box-shadow: 0 0 12px 0 rgba(6, 6, 45, 0.12);
    `};

  ${(props) =>
    props.large &&
    css`
      width: 155px;
    `};

  ${(props) =>
    props.center &&
    css`
      text-align: center;
    `};

  ${({ image }) =>
    image &&
    css`
      width: auto;
    `};

  ${({ $layout }) =>
    $layout === 'vertical' &&
    css`
      display: block;
      padding: 0;
    `}

  &:hover {
    cursor: pointer;
  }
`

const Inner = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;

  &::before {
    position: absolute;
    inset: 0;
    content: '';
  }

  ${({ image }) =>
    image &&
    css`
      flex-direction: column;
    `}

  ${({ $layout }) =>
    $layout === 'vertical' &&
    css`
      flex-direction: column-reverse;
      align-items: stretch;
    `}
`

const Text = styled.div`
  display: block;
  margin-top: 10px;
  font-size: 12px;
  line-height: 1.2;
`

const RadioContainer = styled.div`
  ${({ $layout, checked, theme }) =>
    $layout === 'vertical' &&
    css`
      padding: 20px;
      display: flex;
      justify-content: center;
      border-top: 1px solid
        ${checked ? theme.colors.accent : theme.colors.midGrey};
    `}
`

const HiddenRadio = styled(Field).attrs({ type: 'radio' })`
  ${A11yElement};
`

export const RadioLabel = styled.span`
  ${BodyS};
  display: block;
  font-weight: ${({ boldLabel }) => (boldLabel ? 700 : 400)};
  line-height: 1.3;
  ${({ font }) =>
    (font === 'serif' && HeaderS) || (font === 'medium' && HeaderXS)};
  color: ${(props) =>
    props.blueLabel
      ? props.theme.colors.darkBlue
      : props.theme.colors.xDarkGrey};
  text-decoration: underline;
  text-decoration-color: transparent;
  text-decoration-thickness: 1px;
  text-underline-offset: 2px;
  text-transform: capitalize;

  ${({ checked }) => checked && labelCheckedState};

  ${RadioContainer} + & {
    ${({ $layout }) =>
      $layout === 'horizontal' &&
      css`
        margin-left: 8px;
      `}
  }

  ${({ $layout }) =>
    $layout === 'vertical' &&
    css`
      padding: 20px;
      text-decoration: none;
    `}

  ${(props) =>
    props.resultCount
      ? css`
          display: flex;
          justify-content: space-between;
          width: 100%;
        `
      : null};
`

const labelCheckedState = css`
  text-decoration-color: currentColor;
`

const StyledRadio = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
  background: ${(props) =>
    props.checked ? props.theme.colors.darkBlue : props.theme.colors.white};
  border: 1px solid
    ${(props) =>
      props.checked
        ? props.theme.colors.darkBlue
        : props.theme.colors.darkGrey};
  border-radius: 50%;
  ${Transition({ property: 'background-color, border' })};

  &::before {
    position: absolute;
    left: 50%;
    top: 50%;
    height: 12px;
    border-radius: 50%;
    width: 12px;
    background-color: ${(props) => props.theme.colors.white};
    content: '';
    transform: translate(-50%, -50%);
    transform-origin: left bottom;
  }

  input:focus-visible + & {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
  }
`

export const Content = styled.div`
  padding-left: 33px;
  margin-top: 15px;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.darkBlue};

  ${({ wrapper }) =>
    wrapper &&
    css`
      padding: 16px;
      border-top: 1px solid ${({ theme }) => theme.colors.midGrey};
      border-radius: inherit;
      border-bottom-right-radius: inherit;
      border-bottom-left-radius: inherit;
    `};

  > * + * {
    margin-top: 10px;
  }
`

const imageCheckedState = css`
  border-color: ${({ theme }) => theme.colors.accent};
  box-shadow: ${({ theme }) => theme.shadows.small};
`

const Image = styled.figure`
  ${Transition({ property: 'box-shadow, border-color' })};
  padding: 8px;
  margin-bottom: 15px;
  background-color: ${({ theme }) => theme.colors.white};
  border: 2px solid ${({ theme }) => theme.colors.midGrey};
  border-radius: 50%;

  ${({ checked }) => checked && imageCheckedState};

  ${Inner}:hover &,
  ${Inner}:focus-within & {
    ${imageCheckedState};
  }

  img {
    width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: inherit;
  }
`

export default function Radio({
  children,
  label,
  checkedLabel,
  blueLabel,
  boldLabel,
  resultCount,
  copy,
  wrapper = false,
  center = false,
  verticalCenter,
  large = false,
  padding,
  name,
  value,
  font,
  layout,
  parse,
  required,
  onCheck,
  imageUrl,
}) {
  const formik = useFormikContext()

  // Check if Formik values include a field with this name at the top level - if so, use that.
  // If not, expand field name to match a nested object (e.g. 'item.title' -> { item: { title }})
  // https://formik.org/docs/guides/arrays#nested-objects
  const checked = formik.values[name]
    ? formik.values[name] === value
    : name.split('.').reduce((r, k) => r?.[k], formik.values) === value

  return (
    <Wrapper
      image={imageUrl}
      wrapper={wrapper}
      center={center}
      verticalCenter={verticalCenter}
      padding={padding}
      large={large}
      checked={checked}
      $layout={layout}
    >
      <Inner image={imageUrl} $layout={layout}>
        {imageUrl && (
          <Image checked={checked}>
            <img src={imageUrl} />
          </Image>
        )}
        <RadioContainer $layout={layout} checked={checked}>
          <HiddenRadio
            checked={checked}
            name={name}
            value={value}
            onChange={(ev) => {
              formik.setFieldValue(
                name,
                parse ? parse(ev.target.value) : ev.target.value
              )
              onCheck?.(ev.target.value)
            }}
            required={required}
          />
          {!imageUrl && <StyledRadio checked={checked} />}
        </RadioContainer>
        {label && (
          <RadioLabel
            blueLabel={blueLabel}
            boldLabel={boldLabel}
            font={font}
            resultCount={resultCount > 0}
            checked={checked}
            $layout={layout}
          >
            <p>{checked && checkedLabel ? checkedLabel : label}</p>
            {resultCount > 0 && <p>({resultCount})</p>}
          </RadioLabel>
        )}
      </Inner>
      {copy && <Text>{copy}</Text>}
      {children && <Content>{children}</Content>}
    </Wrapper>
  )
}

Radio.defaultProps = {
  font: 'sans',
  layout: 'horizontal',
}

Radio.propTypes = {
  children: PropTypes.node,
  label: PropTypes.node,
  checkedLabel: PropTypes.string,
  blueLabel: PropTypes.bool,
  boldLabel: PropTypes.bool,
  resultCount: PropTypes.number,
  wrapper: PropTypes.bool,
  center: PropTypes.bool,
  verticalCenter: PropTypes.bool,
  large: PropTypes.bool,
  copy: PropTypes.string,
  padding: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]).isRequired,
  font: PropTypes.oneOf(['sans', 'serif', 'medium']).isRequired,
  layout: PropTypes.oneOf(['horizontal', 'vertical']).isRequired,
  parse: PropTypes.func,
  required: PropTypes.bool,
  onCheck: PropTypes.func,
  imageUrl: PropTypes.string,
}
