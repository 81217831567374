import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'

import styled, { css } from 'styled-components'

import Button from '../_atoms/Button'
import Avatar from '../shared/Avatar'
import Link from '../_atoms/Link'
import UserLocation from '../shared/UserLocation'
import { BodyS, Body } from '../_abstracts/Type'
import Transition from '../_abstracts/Animation'
import ReturnLink from '../_atoms/ReturnLink'
import { UserConsumer } from '../auth/UserContext'
import Popup from '../shared/Popup'
import ZendeskButton from '../shared/ZendeskButton'

const Wrapper = styled.div`
  border: 1px solid ${(props) => props.theme.colors.midGrey};
  border-radius: ${(props) => props.theme.radii};
  overflow: hidden;
`

const Header = styled.h2`
  background-color: ${(props) => props.theme.colors.white};
  padding: 10px 17px;
  text-align: center;
`

const InnerWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  padding: 20px 17px;
  border-top: 1px solid ${(props) => props.theme.colors.midGrey};
`

const UserInfo = styled.div`
  display: flex;
  align-items: center;
`

const UserInfoInner = styled.div`
  ${BodyS}
  margin-left: 10px;
  color: ${(props) => props.theme.colors.xDarkGrey};
  flex-grow: 6;
`

const UserInfoName = styled.div`
  display: flex;
  > *:not(:first-child) {
    margin-left: 15px;
  }
`

const UserInfoSales = styled.div`
  display: flex;
  margin-bottom: 5px;

  &:last-child {
    margin-bottom: 0;
  }

  > *:not(:first-child) {
    margin-left: 15px;
  }
`

const UserName = styled(Link)`
  ${Body}
  color: ${(props) => props.theme.colors.darkBlue};
`

const Bio = styled.div`
  background-color: ${(props) => props.theme.colors.blueTint};
  padding: 10px 15px 12px;
  margin-top: 12px;
  color: ${(props) => props.theme.colors.xDarkGrey};
  border-radius: ${(props) => props.theme.radii};
`

const BioText = styled.div`
  overflow: hidden;
  display: block;
  font-size: 15px;

  ${({ readMore }) =>
    !readMore &&
    css`
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
    `};
`

const Expand = styled.a`
  color: ${(props) => props.theme.colors.accent};
  text-decoration: underline;
  text-decoration-color: currentColor;
  cursor: pointer;
  ${Transition({ property: 'text-decoration-color' })}

  &:hover {
    text-decoration-color: transparent;
  }
`

const Buttons = styled.div`
  margin-top: 20px;
`

const MessageButton = ({ item, isExchangePlus, seller }) => {
  return (
    <UserConsumer>
      {({ user }) =>
        user
          ? ((isExchangePlus || seller.isCarterVintage) && (
              <ZendeskButton>
                <Button variant="secondary" fullWidth>
                  Message Carter Vintage
                </Button>
              </ZendeskButton>
            )) || (
              <Button
                fullWidth
                to={`/messages/compose?itemId=${item.id}`}
                variant="secondary"
              >
                Message Seller
              </Button>
            )
          : ((isExchangePlus || seller.isCarterVintage) && (
              <ZendeskButton>
                <Button variant="secondary" fullWidth>
                  Talk to us about this instrument
                </Button>
              </ZendeskButton>
            )) || (
              <Popup
                trigger={<Button variant="secondary">Message Seller</Button>}
              >
                <div>
                  <p>Log in or register to send messages</p>
                  <ReturnLink to="/login">
                    <Button variant="primaryLight">Log in</Button>
                  </ReturnLink>
                </div>
              </Popup>
            )
      }
    </UserConsumer>
  )
}

MessageButton.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  isExchangePlus: PropTypes.bool,
  seller: PropTypes.shape({
    id: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    isCarterVintage: PropTypes.bool.isRequired,
  }).isRequired,
}

export default function SellerInfoCard({ item, isExchangePlus, user }) {
  const bioTextRef = useRef()
  const [showReadMore, setShowReadMore] = useState(false)
  const [readMore, setReadMore] = useState(false)
  const linkName = readMore ? 'Read Less' : 'Read More'

  // Show 'Read more' link if description text overflows
  useEffect(() => {
    const resize = () => {
      setShowReadMore(
        readMore ||
          bioTextRef.current?.scrollHeight > bioTextRef.current?.offsetHeight
      )
    }

    resize()
    window.addEventListener('resize', resize)

    return () => window.removeEventListener('resize', resize)
  }, [bioTextRef, readMore, setShowReadMore])

  return (
    <Wrapper>
      <Header>Seller Info</Header>
      <InnerWrapper>
        <UserInfo>
          <Avatar size="small" user={user} />
          <UserInfoInner>
            <UserInfoName>
              <UserName $reverse to={`/profile/${user.username}/listings`}>
                {user.displayName}
              </UserName>
            </UserInfoName>
            {user.numSalesMade && (
              <UserInfoSales>
                <p>Sales made: {user.numSalesMade}</p>
              </UserInfoSales>
            )}
            {user.locationCountry && (
              <UserLocation locationCountry={user.locationCountry} />
            )}
          </UserInfoInner>
        </UserInfo>
        {user.bio && (
          <Bio>
            <BioText readMore={readMore} ref={bioTextRef}>
              {user.bio}
            </BioText>
            {showReadMore && (
              <Expand
                onClick={() => {
                  setReadMore(!readMore)
                }}
              >
                {linkName}
              </Expand>
            )}
          </Bio>
        )}
        <Buttons>
          <MessageButton
            item={item}
            isExchangePlus={isExchangePlus}
            seller={user}
          />
        </Buttons>
      </InnerWrapper>
    </Wrapper>
  )
}

SellerInfoCard.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  isExchangePlus: PropTypes.bool,
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    bio: PropTypes.string,
    numSalesMade: PropTypes.number,
    locationCountry: PropTypes.string,
    isCarterVintage: PropTypes.bool.isRequired,
  }).isRequired,
}
