import { useEffect, useRef, useState } from 'react'
import toast from 'react-hot-toast'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { useMutation } from 'urql'
import { HeaderXL, SmallLetters, Body } from '../_abstracts/Type'
import { Icon, CarterCIcon, MarkerIcon } from '../_base/Icon'
import { down, up } from 'styled-breakpoints'

import Transition from '../_abstracts/Animation'
import Button, { ButtonStyle } from '../_atoms/Button'
import Avatar, { Wrapper as AvatarWrapper } from '../shared/Avatar'
import Video from '../_atoms/Video'

const Wrapper = styled.div`
  max-width: 1220px;
  margin-right: auto;
  margin-left: auto;
  color: ${(props) => props.theme.colors.darkBlue};
  padding-bottom: 20px;

  ${up('large')} {
    padding-bottom: 30px;
  }

  ${(props) =>
    props.dark
      ? css`
          ${Cta} {
            background-color: ${(props) => props.theme.colors.darkBlue};
          }
        `
      : css`
          ${Cta} {
            background-color: ${(props) => props.theme.colors.accent};
          }
        `}

  @media print {
    display: none;
  }
`

const Cta = styled.div`
  position: relative;
  min-height: 100px;
  text-align: center;
  padding: 20px;
  border-radius: 5px;
  background-color: ${(props) => props.theme.colors.accent};

  ${up('medium')} {
    min-height: 175px;
  }

  ${Icon} {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100px;
    opacity: 0.15;
    color: ${(props) => props.theme.colors.white};
    transform: translate(-50%, -50%);

    ${up('medium')} {
      width: 150px;
    }
  }
`

const Buttons = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  ${up('medium')} {
    position: absolute;
    bottom: 10px;
    right: 10px;
    justify-content: flex-end;
  }

  ${ButtonStyle} {
    min-width: 200px;
    margin: 10px;
    border-color: ${(props) => props.theme.colors.white};
  }
`

const Grid = styled.div`
  display: grid;
  column-gap: 20px;
  row-gap: 20px;
  grid-template-columns: min(230px, 18vw) 1fr;

  ${down('medium')} {
    padding-top: 20px;
  }

  ${up('large')} {
    column-gap: 30px;
    row-gap: 25px;
  }

  ${({ withVideo }) =>
    (withVideo &&
      css`
        @media (min-width: 550px) {
          grid-template-columns: min(230px, 18vw) 1fr 42%;
        }

        ${up('medium')} {
          grid-template-columns: min(230px, 18vw) 1fr 30%;
        }
      `) ||
    css`
      ${up('medium')} {
        padding-right: 25px;
      }
    `};
`

const Image = styled.div`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  grid-column: 1;

  ${up('medium')} {
    padding-left: 25px;

    ${AvatarWrapper} {
      margin-top: -50%;
    }
  }

  ${up('large')} {
    grid-row: 1 / 3;
  }

  ${({ withVideo }) =>
    withVideo &&
    css`
      ${down('medium')} {
        grid-row: 1;
      }
    `};
`

const Details = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 2;
  grid-row: 1;

  ${up('large')} {
    padding-top: 32px;
  }

  ${({ withVideo }) =>
    withVideo &&
    css`
      @media (min-width: 550px) {
        grid-column: 2 / 4;
      }

      ${up('large')} {
        grid-column: 2;
      }
    `};
`

const Media = styled.div`
  display: flex;
  align-items: flex-end;
  grid-column: 1 / 3;
  grid-row: 3;

  @media (min-width: 550px) {
    grid-column: 3;
    grid-row: 2;
  }

  ${up('large')} {
    grid-row: 1 / 3;
    padding-top: 30px;
  }
`

const Nom = styled.div`
  ${HeaderXL}
  margin-bottom: auto;

  ${down('large')} {
    font-size: 55px;
  }

  ${down('medium')} {
    margin-top: auto;
    font-size: 50px;
  }

  ${down('small')} {
    font-size: 40px;
  }
`

const Meta = styled.ul`
  ${SmallLetters}
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 7px;
  margin-bottom: -10px;
`

const MetaItem = styled.li`
  display: inline-block;
  margin-bottom: 10px;

  &:not(:last-child) {
    margin-right: 25px;
  }

  ${Icon} {
    top: -1px;
    width: 12px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
  }
`

const Description = styled.div`
  ${Body}
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid ${(props) => props.theme.colors.midGrey};
  background-color: ${(props) => props.theme.colors.white};
  padding: 15px;
  border-radius: 5px;
  grid-row: 2;
  grid-column: 2;

  ${down('medium')} {
    grid-column: 1 / 3;
  }

  ${up('large')} {
    max-width: 645px;
  }
`

const Text = styled.div`
  overflow: hidden;
  display: block;
  max-height: ${(props) => (props.readMore ? 'none' : '82px')};
`

const More = styled.button`
  ${Body}
  ${Transition({ property: 'color' })};
  border: 0;
  background-color: transparent;
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.001) 0,
    ${(props) => props.theme.colors.white} 30px
  );
  text-decoration: underline;
  color: ${(props) => props.theme.colors.accent};
  padding-left: 40px;
  position: absolute;
  bottom: 16px;
  right: 16px;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.darkBlue};
  }
`

const Edit = styled(Link)`
  display: flex;
  align-items: center;
  margin-top: 10px;

  &:first-child {
    margin-top: 0;
  }

  span {
    ${Transition({ property: 'color' })}
    margin-left: 10px;
  }

  ${Icon} {
    ${Transition({ property: 'color' })}
    width: 24px;
    border: 0;
    background-color: transparent;
    font-size: 0;
    cursor: pointer;
    color: ${(props) => props.theme.colors.black};

    circle {
      ${Transition({ property: 'fill' })}
    }
  }

  &:hover {
    span {
    }

    ${Icon} {
      color: ${(props) => props.theme.colors.white};

      circle {
        fill: ${(props) => props.theme.colors.darkBlue};
      }
    }
  }
`

const ADD_SELLER_SEARCH = `
  mutation AddSavedSellerSearch($query: SearchQuery!) {
    listingSearchSave(search: { query: $query }) {
      success
    }
  }
`

export default function Profile({
  user,
  country,
  sales,
  viewing = false,
  dark = false,
}) {
  const textRef = useRef()
  const textInnerRef = useRef()
  const [showReadMore, setShowReadMore] = useState(!!user.videoEmbed)
  const [readMore, setReadMore] = useState(false)
  const linkName = readMore ? 'Read Less' : 'Read More'

  let video = null
  if (user?.videoEmbed && user?.videoAspect) {
    video = <Video embed={user.videoEmbed} aspect={user.videoAspect} />
  }

  // Show 'Read more' link if description text overflows
  useEffect(() => {
    const resize = () => {
      setShowReadMore(
        readMore ||
          textInnerRef.current?.offsetHeight > textRef.current?.offsetHeight
      )
    }

    resize()
    window.addEventListener('resize', resize)

    return () => window.removeEventListener('resize', resize)
  }, [textRef, textInnerRef, readMore, setShowReadMore, user.videoEmbed])

  const [, addSellerSearch] = useMutation(ADD_SELLER_SEARCH)
  async function handleAdd() {
    const toastId = toast('Adding seller search…')

    const { data } = await addSellerSearch(
      {
        query: { filters: [{ key: 'seller', value: `["${user.username}"]` }] },
      },
      { additionalTypenames: ['ListingSavedSearch'] }
    )

    if (data?.listingSearchSave.success) {
      toast.success('Added seller to saved searches', { id: toastId })
    } else {
      toast.error('Unable to add seller to saved searches', { id: toastId })
    }
  }

  return (
    <Wrapper dark={dark}>
      <Cta
        style={
          user.bannerImage?.url
            ? {
                backgroundImage: `url(${user.bannerImage.url})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }
            : undefined
        }
      >
        {!user.bannerImage?.url && <CarterCIcon />}

        <Buttons>
          {viewing ? (
            <>
              <Button variant="secondary" onClick={handleAdd}>
                Add to my saved searches
              </Button>
              <Button
                to={`/messages/compose?accountId=${user.id}`}
                variant="primaryLight"
              >
                Message seller
              </Button>
            </>
          ) : null}
        </Buttons>
      </Cta>

      <Grid withVideo={!!video}>
        <Image withVideo={!!video}>
          <Avatar upload user={user} />
        </Image>

        <Details withVideo={!!video}>
          <Nom>{user.displayName}</Nom>
          {(country || sales) && (
            <Meta>
              {country && (
                <MetaItem>
                  <MarkerIcon />
                  {country}
                </MetaItem>
              )}

              {sales && (
                <MetaItem>{sales && <> Sales Made: {sales}</>}</MetaItem>
              )}
            </Meta>
          )}
        </Details>

        {(!viewing || user.bio) && (
          <Description>
            {user.bio && (
              <Text readMore={readMore} ref={textRef}>
                <div ref={textInnerRef}>{user.bio}</div>
              </Text>
            )}

            {!viewing && (
              <Edit to="/profile/settings/details#profile">
                {!user.bio && <span>Add a bio</span>}
              </Edit>
            )}

            {showReadMore && (
              <More
                onClick={() => {
                  setReadMore(!readMore)
                }}
              >
                {linkName}
              </More>
            )}
          </Description>
        )}
        {video && <Media>{video}</Media>}
      </Grid>
    </Wrapper>
  )
}

Profile.propTypes = {
  sales: PropTypes.string,
  country: PropTypes.string,
  user: PropTypes.shape({
    id: PropTypes.string,
    bio: PropTypes.string,
    username: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    videoEmbed: PropTypes.string.isRequired,
    videoAspect: PropTypes.number.isRequired,
    bannerImage: PropTypes.shape({
      url: PropTypes.string,
    }),
  }).isRequired,
  dark: PropTypes.bool,
  viewing: PropTypes.bool,
}
