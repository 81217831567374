import { useRef } from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'
import { up } from 'styled-breakpoints'

import { HeaderXL } from '../_abstracts/Type'
import { Media, MediaImage } from '../_base/Media'
import Flickity from 'react-flickity-component'
import '../shared/flickity.css'
import { Icon, ArrowNextIcon, ArrowPrevIcon } from '../_base/Icon'
import Link from '../shared/Link'

const Slider = styled.div`
  position: relative;
  overflow: hidden;
  min-height: 100px;
  background-color: ${(props) => props.theme.colors.darkBlue};

  &::before {
    display: block;
    width: 100%;
    content: '';
    padding-bottom: 32.23%;
  }

  > div,
  .flickity-enabled,
  .flickity-viewport {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const Arrows = styled.div`
  position: absolute;
  z-index: 99;
  top: 50% !important;
  left: 0;
  width: 100%;
  height: auto !important;
  transform: translateY(-50%);
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding-left: 20px;
  padding-right: 20px;
  pointer-events: none;
`

const Arrow = styled.button`
  position: relative;
  z-index: 2;
  background-color: transparent;
  border: 0;
  color: white;
  cursor: pointer;
  transition: 0.15s;
  pointer-events: auto;

  ${up('large')} {
    width: 58px;
    height: 58px;
    color: ${({ theme }) => theme.colors.darkBlue};
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 50%;

    &:first-child {
      ${Icon} {
        left: 15px;
      }
    }

    &:last-child {
      ${Icon} {
        left: 4px;
      }
    }
  }

  &:hover {
    transform: scale(1.1);
  }

  ${Icon} {
    width: 39px;
  }

  .flickity-prev-next-button.previous {
    left: -50px;
  }
  .flickity-prev-next-button.next {
    right: -50px;
  }
`

const ItemLink = styled(Link)`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;

  form {
    padding-top: 10px;
  }

  ${Media} {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const InnerWrapper = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  padding: 50px 25px 100px;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
`

const Header = styled.h1`
  ${HeaderXL}
  text-align: center;
  color: ${(props) => props.theme.colors.white};
  text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.5);
`

const Text = styled.div`
  max-width: 850px;
  font-size: 18px;
  text-align: center;
  margin-right: auto;
  margin-left: auto;
  color: ${(props) => props.theme.colors.white};
`

export default function Hero({ items, options }) {
  const flick = useRef()

  function next() {
    flick.current.flkty.next()
  }

  function previous() {
    flick.current.flkty.previous()
  }

  return (
    <>
      <Slider>
        {items.length > 1 ? (
          <Arrows>
            <Arrow onClick={previous}>
              <ArrowPrevIcon />
            </Arrow>

            <Arrow onClick={next}>
              <ArrowNextIcon />
            </Arrow>
          </Arrows>
        ) : null}

        <Flickity options={options} ref={flick} disableImagesLoaded={true}>
          {items.map((item, index) => (
            <ItemLink to={item.linkSlider.url} key={index}>
              {item.image[0] && (
                <Media>
                  <MediaImage
                    srcSet={`${item.image[0].medium} 900w, ${item.image[0].large} 1680w, ${item.image[0].xl} 2400w, ${item.image[0].xxl} 3000w`}
                    width={item.image[0].width}
                    height={item.image[0].height}
                    alt={item.image[0].title || ''}
                    sizes="100vw"
                  />
                </Media>
              )}
              {(item.header || item.text) && (
                <InnerWrapper>
                  {item.header && <Header>{item.header}</Header>}
                  {item.text && <Text>{item.text}</Text>}
                </InnerWrapper>
              )}
            </ItemLink>
          ))}
        </Flickity>
      </Slider>
    </>
  )
}

Hero.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.arrayOf(
        PropTypes.shape({
          medium: PropTypes.string.isRequired,
          large: PropTypes.string.isRequired,
          xl: PropTypes.string.isRequired,
          xxl: PropTypes.string.isRequired,
          width: PropTypes.number.isRequired,
          height: PropTypes.number.isRequired,
          title: PropTypes.string,
        })
      ).isRequired,
      linkSlider: PropTypes.shape({
        url: PropTypes.string.isRequired,
      }).isRequired,
      header: PropTypes.string,
      text: PropTypes.node,
    })
  ).isRequired,
  options: PropTypes.object,
}
