import PropTypes from 'prop-types'
import styled from 'styled-components'
import Button from '../_atoms/Button'
import { useCartContext } from './CartContext'
import { useState } from 'react'
import { useMutation } from 'urql'
import toast from 'react-hot-toast'

const ParkButton = styled(Button)`
  &::before {
    background: ${(props) => props.theme.colors.darkBlue};
  }
`
const SaveRefButton = styled(Button)`
  margin-right: 5px;
`

const TextInput = styled.input`
  border: 1px solid #000;
`

const Form = styled.form`
  input {
    border: 1px solid #e6e7ea;
    border-radius: 3px;
    text-align: left;
    padding: 7px 5px;
    margin-right: 5px;
  }
`

const AdminActionsContainer = styled.div`
  padding: 20px;

  > * {
    display: inline;
  }
`

const SET_REFERENCE = `
  mutation OrderSetReference($id: ID!, $reference: String!) {
    orderSetReference(id: $id, reference: $reference) {
      id
      reference
    }
  }
`

export default function AdminActions({ order }) {
  const [, updateOrderReference] = useMutation(SET_REFERENCE)
  const { openOrderIds, updateOpenOrderIds } = useCartContext()
  const [reference, setReference] = useState(order.reference || '')

  function handleParkClick() {
    updateOpenOrderIds(openOrderIds.filter((id) => id !== order.id))
  }

  async function handleRefSave(event) {
    event.preventDefault()
    event.stopPropagation()
    const response = await updateOrderReference({ id: order.id, reference })
    if (response?.data?.orderSetReference?.id)
      toast.success('Reference was updated')
    else toast.error('Something went wrong')

    return false
  }

  return (
    <AdminActionsContainer>
      <Form onSubmit={handleRefSave}>
        <TextInput
          value={reference}
          type="text"
          onChange={(e) => setReference(e.target.value)}
        />
        <SaveRefButton onClick={handleRefSave}>Save</SaveRefButton>
      </Form>
      <ParkButton onClick={handleParkClick}>Park</ParkButton>
    </AdminActionsContainer>
  )
}

AdminActions.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.string.isRequired,
    reference: PropTypes.string,
  }).isRequired,
}
