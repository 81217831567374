import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Body } from '../_abstracts/Type'

export const Wrapper = styled.div`
  ${Body};
  background-color: ${(props) => props.theme.colors.blueTint};
  padding: 45px 35px;
  color: ${(props) => props.theme.colors.darkBlue};

  &:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
  }

  &:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit;
  }
`

const Inner = styled.div`
  max-width: 800px;
  margin-right: auto;
  margin-left: auto;
`

export default function GreyTextBlock({ children }) {
  return (
    <Wrapper>
      <Inner>{children}</Inner>
    </Wrapper>
  )
}

GreyTextBlock.propTypes = {
  children: PropTypes.node.isRequired,
}
