import PropTypes from 'prop-types'
import styled from 'styled-components'
import CraftForm from '../shared/CraftForm'

export const Element = styled.div``

export default function Form({ form, formSuccessMessage }) {
  return (
    <Element>
      <CraftForm handle={form[0].handle} successMessage={formSuccessMessage} />
    </Element>
  )
}

Form.propTypes = {
  form: PropTypes.shape({
    handle: PropTypes.string.isRequired,
  }).isRequired,
  formSuccessMessage: PropTypes.string,
}
