import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useQuery } from 'urql'
import Button, { ButtonStyle } from '../_atoms/Button'
import Container from '../_helpers/Container'
import Spacer from '../_helpers/Spacer'
import NotFound from '../404/NotFound'
import Loader from '../shared/Loader'
import ItemForm from './ItemForm'
import SelectedItem from './SelectedItem'
import ButtonOpenAdmin from '../shared/ButtonOpenAdmin'

const GET_ITEM = `
  query GetItem($id: ID!) {
    item(id: $id) {
      id
      privacy
      title
      originalParts
      originalFinish
      originalCase

      category {
        id
      }
      brand {
        id
        title
      }
      model {
        id
        title
      }
      madeInYear
      madeInCountry {
        iso
      }
      handedness
      finish {
        id
        title
      }
      materials {
        top {
          id
        }
        back {
          id
        }
        neck {
          id
        }
        fretboard {
          id
        }
        body {
          id
        }
      }
      description
      acceptingOffers
      offerDeclineThreshold
      images {
        id
        url(size: "productThumb")
        size
      }
      userEstimate {
        amount
        currency { iso }
      }
      listing {
        id
        askingPrice {
          amount
          currency {
            iso
          }
        }
        securePackaging
        type
      }
    }
  }
`

const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${ButtonStyle} {
    margin-top: 10px;
  }
`

const Message = styled.p`
  text-align: center;
`

export default function EditItemForm({
  heading,
  headingAlign,
  headingElement,
  headingSize,
  includeListingFields,
  itemId,
  onSave,
  photographPrice,
}) {
  const [result, reexecuteQuery] = useQuery({
    query: GET_ITEM,
    variables: { id: itemId },
  })
  const { data, fetching, error } = result
  const itemImages = data?.item?.images
  const [images, setImages] = useState(itemImages || [])

  useEffect(() => {
    setImages(itemImages)
  }, [itemImages])

  if (fetching) return <Loader background="#f9f9f9" />

  if (error) {
    return (
      <MessageWrapper>
        <Message>An error occurred.</Message>
        <Button onClick={reexecuteQuery} type="button">
          Reload?
        </Button>
      </MessageWrapper>
    )
  }

  const { item } = data

  if (item == null) {
    return <NotFound />
  }

  const deleteImage = (image) => {
    const index = images.findIndex((img) => img.id === image.id)
    if (index > -1) {
      const imagesClone = images.slice()
      imagesClone[index].isDeleted = true
      setImages(imagesClone)
    }
  }

  return (
    <>
      <Container>
        <SelectedItem image={images?.[0]} title={item.title} />
        <Spacer size="55px" />
      </Container>
      <ButtonOpenAdmin item={{ id: itemId }} />
      <ItemForm
        heading={heading}
        headingAlign={headingAlign}
        headingElement={headingElement}
        headingSize={headingSize}
        images={images}
        photographPrice={photographPrice}
        includeListingFields={includeListingFields}
        existingTitleParts={{
          brand: item.brand.title,
          model: item.model.title,
          year: item.madeInYear,
          finish: item.finish.title,
        }}
        item={{
          privacy: item.privacy,
          title: item.title,
          categoryId: item.category.id,
          brandId: item.brand.id,
          brandName:
            item.brand.id === 'ephemeral' ? item.brand.title : undefined,
          modelId: item.model.id,
          modelName:
            item.model.id === 'ephemeral' ? item.model.title : undefined,
          madeInYear: item.madeInYear,
          madeInCountry: item.madeInCountry?.iso,
          handedness: item.handedness,
          finishId: item.finish.id,
          finishName:
            item.finish.id === 'ephemeral' ? item.finish.title : undefined,
          materials: {
            topId: item.materials.top?.id,
            backId: item.materials.back?.id,
            neckId: item.materials.neck?.id,
            fretboardId: item.materials.fretboard?.id,
            bodyId: item.materials.body?.id,
          },
          description: item.description,
          acceptingOffers: item.acceptingOffers,
          offerDeclineThreshold:
            item.offerDeclineThreshold != null
              ? item.offerDeclineThreshold * 100
              : '',
          uploads: [],
          originalFinish: item.originalFinish,
          originalParts: item.originalParts,
          originalCase: item.originalCase,
          userEstimate: {
            amountCents:
              item.userEstimate?.amount != null
                ? parseFloat(item.userEstimate?.amount)
                : '',
            currencyIso: item.userEstimate?.currency?.iso || 'USD',
          },
        }}
        itemId={item.id}
        listing={{
          askingPrice: {
            amountCents:
              item.listing?.askingPrice?.amount != null
                ? parseFloat(item.listing.askingPrice.amount)
                : '',
            currencyIso: item.listing?.askingPrice?.currency?.iso || 'USD',
          },
          securePackaging:
            item.listing?.securePackaging != null
              ? item.listing?.securePackaging
              : '',
        }}
        listingId={item.listing?.id}
        onDeleteImage={deleteImage}
        onSave={onSave}
      />
    </>
  )
}

EditItemForm.propTypes = {
  heading: PropTypes.string,
  headingAlign: PropTypes.oneOf(['left', 'center']),
  headingElement: PropTypes.string,
  headingSize: PropTypes.oneOf(['large', 'small']),
  includeListingFields: PropTypes.bool,
  itemId: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  photographPrice: PropTypes.number,
}
