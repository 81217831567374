import { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { down } from 'styled-breakpoints'
import { Link } from 'react-router-dom'

import { breakpoints } from '../theme'
import { Media, MediaImage } from '../_base/Media'
import { Icon, ReplyIcon, OfferIcon } from '../_base/Icon'
import {
  HeaderSansM,
  BodyS,
  Body,
  SmallLetters,
  HeaderSansL,
} from '../_abstracts/Type'
import Money from '../data/Money'
import BlockList from '../shared/BlockList'
import DateTime from '../data/DateTime'
import Avatar from '../shared/Avatar'
import Roundel, { Wrapper as RoundelWrapper } from '../shared/Roundel'
import SellerRoundel from '../shared/SellerRoundel'
import Transition from '../_abstracts/Animation'
// import SaveButton, { Button as SaveButtonElement } from './SaveButton'

const Wrapping = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors.blueTint};
`

const Profile = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 25px;
`

const Deets = styled(Link)`
  display: flex;
  align-items: center;
`

const Copy = styled.div`
  padding-left: 15px;
`

const Name = styled.p`
  ${Body}
`

const Time = styled.p`
  ${SmallLetters}
  color: ${(props) => props.theme.colors.midGrey};
`

const ToolBar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  ${RoundelWrapper} {
    margin-top: 8px;
  }
`

const Actions = styled.div`
  display: flex;
  margin: -5px;
`

const Action = styled.span`
  margin: 5px;

  a {
    ${Transition({ property: 'color' })}

    circle {
      ${Transition({ property: 'fill' })}
    }

    &:hover {
      color: ${(props) => props.theme.colors.white};

      circle {
        fill: ${(props) => props.theme.colors.darkBlue};
      }
    }
  }

  ${Icon} {
    width: 24px;
    height: 24px;
  }
`

const Inner = styled.div`
  padding: 20px;
  margin: -13px;
  display: flex;
  border-top: 1px solid ${(props) => props.theme.colors.blueTint};

  ${down('small')} {
    flex-wrap: wrap;
  }
`

const Image = styled.div`
  margin: 13px;
  min-width: 255px;

  ${Media} {
    border: 1px solid ${(props) => props.theme.colors.blueTint};
    padding-bottom: 100%;
  }

  ${down('small')} {
    flex: 1 1 calc(30% - 10px);
    margin: 0;
    min-width: 150px;
    margin: 5px;
  }

  ${down('xsmall')} {
    flex: 1 1 100%;
    margin: 0;
    min-width: 150px;
    margin: 5px;
  }
`

const Content = styled.div`
  margin: 13px;
  flex-grow: 1;

  ${down('small')} {
    display: flex;
  }
`

const Right = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;

  ${down('small')} {
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    margin: -5px;
  }
`

const Top = styled.div`
  display: flex;
  flex-direction: column;

  ${down('small')} {
    flex: 2 1 calc(60% - 10px);
    margin: 5px;
    justify-content: space-between;
  }
`

const Mons = styled.div`
  > * {
    margin-top: 10px;
  }
`

const Item = styled.p`
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
`

const Header = styled.h2`
  ${HeaderSansM}
  max-width: 540px;
`

const Label = styled.span`
  ${SmallLetters}
  padding-right: 10px;
  color: ${(props) => props.theme.colors.accent};
  display: flex;
  align-items: center;

  ${RoundelWrapper} {
    margin-left: 10px;
  }
`

const Price = styled.span`
  ${(props) =>
    props.size == 'large'
      ? css`
          ${HeaderSansL}
          line-height: 1;
        `
      : css`
          ${BodyS}
        `}
`

const List = styled.div`
  margin-top: auto;
  > * {
    padding: 0;

    > * {
      ${down('medium')} {
        flex-grow: 1;
        max-width: 170px;
        width: auto;
      }

      ${down('small')} {
        max-width: initial;
        flex-grow: initial;
        width: calc(50% - 10px);
      }
    }
  }

  ${down('small')} {
    flex: 1 0 100%;
    margin: 5px;
  }
`

export default function Summary({ summary, user, item, anchor, shipping }) {
  const medium = breakpoints.medium.match(/\d+/)
  const [isTablet, setIsTablet] = useState(false)

  useEffect(() => {
    function resize() {
      if (window.innerWidth < medium) {
        setIsTablet(true)
      } else {
        setIsTablet(false)
      }
    }

    resize()
    window.addEventListener('resize', resize)

    return () => {
      window.removeEventListener('resize', resize)
    }
  }, [medium])

  const image = item?.images[0]

  const scrollToAnchor = () => {
    const element = document.getElementById(anchor.replace('#', ''))
    element?.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <Wrapping>
      <Profile>
        {/* TODO: Check user URL */}
        <Deets to={`/profile/${user.username}/listings`}>
          <Avatar size="small" user={user} />
          <Copy>
            <Name>{user.username}</Name>
            {summary?.date && (
              <Time>
                <DateTime date={summary.date} />
              </Time>
            )}
          </Copy>
        </Deets>

        <ToolBar>
          <Actions>
            {/* Low priorty hide */}
            {/* <SaveButton isSaved={summary?.saved} /> */}
            {anchor && (
              <Action>
                <a
                  href={anchor}
                  title="Reply"
                  aria-label="Reply"
                  onClick={(ev) => {
                    ev.preventDefault()
                    scrollToAnchor()
                  }}
                >
                  <ReplyIcon />
                </a>
              </Action>
            )}
          </Actions>
          {summary?.offer && (
            <Roundel variant="red" icon={<OfferIcon />} size="small">
              Offer
            </Roundel>
          )}
        </ToolBar>
      </Profile>
      {item && (
        <Inner>
          {!isTablet && image && (
            <Image>
              <Media>
                <MediaImage src={image.url} alt={item.title} />
              </Media>
            </Image>
          )}
          <Content>
            <Right>
              {isTablet && image && (
                <Image>
                  <Media>
                    <MediaImage src={image.url} alt={item.title} />
                  </Media>
                </Image>
              )}
              <Top>
                <Header>
                  <Link to={`/item/${item.id}`}>{item.title}</Link>
                  <Label>
                    Sold by
                    <Link to={`/profile/${user.username}/listings`}>
                      <SellerRoundel user={item.account} />
                    </Link>
                  </Label>
                </Header>

                <Mons>
                  <Item>
                    <Price size="large">
                      <Money
                        amount={item.listing.askingPrice.amount}
                        currency={item.listing.askingPrice.currency.iso}
                      />
                    </Price>
                  </Item>

                  {shipping && (
                    <Item>
                      <Label>Shipping</Label>
                      <Price>
                        <Money
                          amount={shipping.amount}
                          currency={shipping.currency.iso}
                        />
                      </Price>
                    </Item>
                  )}
                </Mons>
              </Top>
              {summary?.list && summary.list.length ? (
                <List>
                  <BlockList list={summary.list} />
                </List>
              ) : null}
            </Right>
          </Content>
        </Inner>
      )}
    </Wrapping>
  )
}

Summary.propTypes = {
  summary: PropTypes.shape({
    offer: PropTypes.bool,
    saved: PropTypes.bool,
    date: PropTypes.instanceOf(Date),
    list: PropTypes.array,
  }),
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
  }).isRequired,
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    account: PropTypes.shape({
      isPrivateSeller: PropTypes.bool.isRequired,
      isProfessionalSeller: PropTypes.bool.isRequired,
      username: PropTypes.string.isRequired,
    }),
    title: PropTypes.string.isRequired,
    images: PropTypes.arrayOf(
      PropTypes.shape({ url: PropTypes.string.isRequired })
    ).isRequired,

    listing: PropTypes.shape({
      askingPrice: PropTypes.shape({
        amount: PropTypes.string,
        currency: PropTypes.shape({
          iso: PropTypes.string,
        }),
      }),
    }).isRequired,
  }),
  shipping: PropTypes.shape({
    amount: PropTypes.string,
    currency: PropTypes.shape({
      iso: PropTypes.string,
    }),
  }),
  anchor: PropTypes.string,
}
