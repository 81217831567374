import { subWeeks, subMonths, subYears, startOfDay } from 'date-fns'
import { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { useQuery } from 'urql'
import { BodyS, HeaderSansM } from '../_abstracts/Type'
import Transition from '../_abstracts/Animation'
import LineChart from '../data/LineChart'
import Money, { formatMoney } from '../data/Money'
import Loader from '../shared/Loader'

const GET_SELLER_HISTORY = `
  query GetSellerHistory($after: Date!, $grouping: TimeGrouping!) {
    sellerHistory(after: $after, grouping: $grouping) {
      date
      amount {
        amount
        amountCents
        currency { iso }
      }
    }
  }
`

const Wrapper = styled.div`
  border: 1px solid ${(props) => props.theme.colors.midGrey};
  border-radius: ${(props) => props.theme.radii};
  color: ${(props) => props.theme.colors.black};
`

const Group = styled.div`
  position: relative;
`

const Top = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const Item = styled.div`
  padding: 15px 20px;
  flex-grow: 1;
  border-bottom: 1px solid ${(props) => props.theme.colors.blueTint};

  & + & {
    border-left: 1px solid ${(props) => props.theme.colors.blueTint};
  }
`

const Label = styled.p`
  ${BodyS}
  color: ${(props) => props.theme.colors.accent};
`

const Mons = styled.p`
  ${HeaderSansM}
  line-height: 1.2;
`

const Mid = styled.div`
  padding: 15px;
`

const Bottom = styled.div`
  padding: 0 20px 20px;
  display: flex;
  align-items: center;
  gap: 15px;
`

const Trigger = styled.button`
  padding: 0.5em 1em;
  border-radius: ${(props) => props.theme.radii};
  border: 1px solid;
  cursor: pointer;
  font-family: ${(props) => props.theme.fonts.sansSerif};
  font-size: 15px;
  text-align: center;
  background-color: ${(props) => props.theme.colors.blueTint};
  border-color: ${(props) => props.theme.colors.midGrey};
  color: ${(props) => props.theme.colors.darkGrey};
  flex-grow: 1;
  ${Transition({ property: 'background-color, color, border-color' })};
  margin: -1px;

  &:hover {
    color: ${(props) => props.theme.colors.accent};
  }

  ${(props) =>
    props.active &&
    css`
      position: relative;
      padding: calc(0.5em + 1px) 1em;
      background-color: ${props.theme.colors.white};
      border-color: ${props.theme.colors.accent};
      color: ${props.theme.colors.darkBlue};
    `}
`

const Message = styled.p`
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
`

const decimalPlaces = (value) => {
  if (Math.floor(value) === value) return 0
  return value.toString().split('.')[1].length || 0
}

const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    tooltip: {
      backgroundColor: '#ECF9F9',
      bodyColor: '#066464',
      borderColor: '#066464',
      bodyAlign: 'center',
      displayColors: false,
      xAlign: 'center',
      yAlign: 'bottom',
      borderWidth: 1,
      padding: {
        x: 7,
        y: 3,
      },
      callbacks: {
        title: () => '',
        label: (ctx) =>
          formatMoney(Number(ctx.parsed.y), ctx.raw.data.amount.currency.iso),
      },
    },
  },
  scales: {
    x: {
      grid: {
        lineWidth: 0,
      },
      type: 'time',
    },
    y: {
      ticks: {
        callback: (val) => {
          let formatted = val / 1000
          if (decimalPlaces(formatted) > 2) formatted = formatted.toFixed(2)
          return formatted.toString().replace(/[.,]00$/, '') + 'K'
        },
      },
    },
  },
}

const GraphContent = () => {
  const [period, setPeriod] = useState('MONTH')

  let date = new Date()
  if (period === 'WEEK') {
    date = subWeeks(date, 1)
  } else if (period === 'MONTH') {
    date = subMonths(date, 1)
  } else if (period === 'YEAR') {
    date = subYears(date, 1)
  }
  date = startOfDay(date)

  const [{ data, fetching, error }] = useQuery({
    query: GET_SELLER_HISTORY,
    variables: {
      after: date,
      grouping: period === 'YEAR' ? 'MONTHLY' : 'DAILY',
    },
  })

  if (error) return <p>{error.message}</p>

  const sellerHistory = data?.sellerHistory || []

  let max
  let total = {
    amountCents: 0,
    currency: sellerHistory[0]?.amount.currency || 'USD', // Displaying first order's currency for now
  }

  for (let order of sellerHistory) {
    // TODO: Account for different order currencies
    total.amountCents += order.amount.amountCents

    // TODO: Account for different order currencies
    if (!max || max.amountCents < order.amount.amountCents) {
      max = order
    }
  }

  const average = {
    // TODO: Account for different order currencies
    amountCents: total.amountCents / sellerHistory.length,
    currency: sellerHistory[0]?.amount.currency || 'USD', // Displaying first order's currency for now
  }

  const chartData = {
    datasets: [
      {
        data: sellerHistory.map((order) => ({
          y: order.amount.amount, // TODO: Account for different order currencies, rather than e.g. £5000 and $5000 being the same point
          x: new Date(order.date),
          data: order,
        })),
        borderColor: 'rgb(0, 0, 0)',
        backgroundColor: 'rgba(255, 255, 255)',
      },
    ],
  }

  options.scales.x.time = { unit: period === 'YEAR' ? 'month' : 'day' }

  return (
    <Wrapper>
      <Group>
        <Top>
          <Item>
            <Label>Top order</Label>
            <Mons>
              {max ? (
                <Money
                  amount={max.amount.amountCents / 100}
                  currency={max.amount.currency.iso}
                />
              ) : (
                <>-</>
              )}
            </Mons>
          </Item>
          <Item>
            <Label>Avg. order</Label>
            <Mons>
              {average.amountCents ? (
                <Money
                  amount={average.amountCents / 100}
                  currency={average.currency.iso}
                />
              ) : (
                <>-</>
              )}
            </Mons>
          </Item>
          <Item>
            <Label>Total made</Label>
            <Mons>
              {total.amountCents ? (
                <Money
                  amount={total.amountCents / 100}
                  currency={total.currency.iso}
                />
              ) : (
                <>-</>
              )}
            </Mons>
          </Item>
        </Top>

        <Mid>
          {(fetching && <Loader background="#fff" height="100px" />) ||
            (sellerHistory.length ? (
              <LineChart data={chartData} options={options} />
            ) : (
              <Message>No sales data for last {period.toLowerCase()}</Message>
            ))}
        </Mid>
      </Group>

      <Bottom>
        <Trigger active={period === 'WEEK'} onClick={() => setPeriod('WEEK')}>
          One week
        </Trigger>
        <Trigger active={period === 'MONTH'} onClick={() => setPeriod('MONTH')}>
          One month
        </Trigger>
        <Trigger active={period === 'YEAR'} onClick={() => setPeriod('YEAR')}>
          One year
        </Trigger>
      </Bottom>
    </Wrapper>
  )
}

export default function Graph(props) {
  const [showContent, setShowContent] = useState(false)

  // Wait until after client-side hydration to show, otherwise the date timezone passed to the sellerHistory
  // query may mismatch on the server/client and cause the resulting HTML to be different
  useEffect(() => {
    setShowContent(true)
  }, [setShowContent])

  if (!showContent) {
    return <Loader height="100px" />
  }

  return <GraphContent {...props} />
}
