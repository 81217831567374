import toast from 'react-hot-toast'

export const REMOVE_FROM_CART = `
  mutation RemoveFromCart($listingId: ID!, $orderId: [ID!]) {
    cartRemoveListing(listingId: $listingId, orderId: $orderId) {
      success
      cart(orderId: $orderId) {
        orders {
          id
        }
      }
    }
  }
`

export const removeItemFromCart = async ({
  listingId,
  mutation,
  openOrderIds,
  updateOpenOrderIds,
}) => {
  const removeItemPromise = mutation({ listingId, orderId: openOrderIds })

  toast.promise(
    removeItemPromise,
    {
      loading: 'Removing from cart…',
      success: 'Item removed from cart',
      error: 'Unable to remove from cart',
    },
    { id: 'cart' }
  )

  try {
    const response = await removeItemPromise
    const ids =
      response?.data?.cartRemoveListing?.cart?.orders?.map((o) => o.id) || []
    updateOpenOrderIds(ids)

    if (response.error || response.data?.cartRemoveListing?.success === false) {
      throw new Error(response.error?.toString())
    }
  } catch (error) {
    toast.error('Unable to remove from cart', { id: 'cart' })
    console.error(error)
    return
  }
}
