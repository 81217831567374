import { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import styled, { css } from 'styled-components'
import { down } from 'styled-breakpoints'
import { useQuery } from 'urql'

import useSearchQuery from '../shop/useSearchQuery'
import Subheader from '../shared/Subheader'
import Toggle from '../shared/Toggle'

import Subnav from '../profile/Subnav'

import Summary from './Summary'
import Stats from './Stats'
// import ActionCarousel from './ActionCarousel'
import Button from '../_atoms/Button'
import Loader from '../shared/Loader'
import NextPrevious from '../shared/NextPrevious'
import SearchBar, { Form as SearchForm } from '../search/SearchBar'

const GET_LISTINGS = `
  query SearchListings($first: Int, $last: Int, $after: String, $before: String, $keywords: String) {
    viewer {
      id
      totalSales {
        amount
        currency { iso }
      }
    }

    listings(first: $first, last: $last, after: $after, before: $before, keywords: $keywords) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          askingPrice {
            amount
            currency {
              iso
            }
          }
          approvedAt
          item {
            id
            slug
            images {
              id
              url(size: "productThumb")
            }
            title
            acceptingOffers
          }
        }
      }
    }
  }
`

const Grid = styled.div`
  display: flex;
  border-top: 1px solid ${(props) => props.theme.colors.midGrey};

  ${(props) =>
    !props.toggleState &&
    css`
      ${Side} {
        display: none;
      }
    `}

  ${down('medium')} {
    flex-wrap: wrap;
  }
`

const Side = styled.div`
  width: 100%;
  max-width: 341px;
  overflow: hidden;
  background: ${(props) =>
    props.color == 'blue' ? props.theme.colors.blueTint : 'transparent'};
  border-bottom-right-radius: ${(props) => props.theme.radii};

  ${down('medium')} {
    max-width: initial;
  }
`

const SideInner = styled.div`
  align-self: flex-start;
  padding: 20px;
  width: 100%;

  > * + * {
    margin-top: 20px;
  }
`

const Middle = styled.div`
  flex-grow: 1;

  > * + * {
    margin-top: 20px;
  }
`

const Trigger = styled.div`
  & + * {
    margin-left: 30px;
  }

  ${down('small')} {
    margin-top: 10px;
  }
`

const Message = styled.p`
  margin: 30px auto;
  text-align: center;
`

const Search = styled.div`
  display: flex;
  justify-content: center;
  margin-right: 30px;

  ${SearchForm} {
    max-width: 600px;
  }

  ${down('small')} {
    margin-right: 0;

    ${SearchForm} {
      max-width: 400px;
    }
  }
`

// const carouselArgs = {
//   options: {
//     cellAlign: 'left',
//     pageDots: true,
//     prevNextButtons: false,
//     wrapAround: true,
//     initialIndex: 1,
//   },
//   listings: [
//     {
//       title: 'Allison D1 A, Sunburst - 2009',
//       meta: 'Sunburst - 2009',
//       desc: 'Would you like to reduce the asking price? Or maybe enquire with us to trade your guitar inhouse.',
//       image: '/content/allison.jpeg',
//       alt: 'nop',
//       buttons: (
//         <>
//           <Button fullWidth variant="primaryDark">
//             Reduce price
//           </Button>
//           <Button fullWidth variant="primaryLight">
//             Trade with TNAG
//           </Button>
//         </>
//       ),
//     },
//   ],
// }

export default function SellingListingsPage() {
  const [toggleState, setToggleState] = useState(true)

  const gridRef = useRef()
  const { query, paginationVars } = useSearchQuery()
  const [pagination, setPagination] = useState({
    first: paginationVars.perPage,
  })

  const keywords = query?.keywords

  const [{ fetching, data, error }, reexecuteQuery] = useQuery({
    query: GET_LISTINGS,
    variables: {
      keywords,
      ...pagination,
    },
  })

  useEffect(() => {
    if (keywords) {
      setPagination({ first: paginationVars.perPage })
    }
  }, [keywords, paginationVars.perPage, setPagination])

  const scrollToTop = () =>
    gridRef.current?.scrollIntoView({ behavior: 'smooth' })

  if (fetching) return <Loader background="#fff" height="250px" />

  if (error) {
    console.error(error)
    return <Message>{error.message}</Message>
  }

  const {
    listings: { totalCount, pageInfo, edges },
  } = data
  const listings = edges.map(({ node }) => node)

  function handleToggle() {
    setToggleState(!toggleState)
  }

  function refetchListings() {
    // Re-fetch listings after deletion, avoiding cache
    reexecuteQuery({ requestPolicy: 'cache-and-network' })
  }

  return (
    <>
      <Helmet>
        <title>Profile | Selling | Listings</title>
      </Helmet>
      <Subheader header="Listings">
        <Button to="/selling/list-item/new" variant="primaryDark">
          List new instrument
        </Button>
      </Subheader>

      <Subnav
        header={
          totalCount
            ? `${totalCount} listing${totalCount === 1 ? '' : 's'}`
            : undefined
        }
      >
        <Search>
          <SearchBar
            action=""
            name="keywords"
            placeholder="Search selling listings"
          />
        </Search>
        <Trigger toggleState={toggleState}>
          <Toggle
            label="Show stats"
            toggleState={toggleState}
            onChange={handleToggle}
          />
        </Trigger>
      </Subnav>

      <Grid toggleState={toggleState} ref={gridRef}>
        <Middle>
          {listings.length ? (
            listings.map((listing) => {
              return (
                <Summary
                  key={listing.id}
                  listing={listing}
                  refetchListings={refetchListings}
                />
              )
            })
          ) : (
            <Message>No current listings</Message>
          )}
        </Middle>

        <Side color="blue">
          <SideInner>
            {data.viewer.totalSales.map((money) => (
              <Stats
                key={money.currency.iso}
                header={
                  data.viewer.totalSales.length > 1
                    ? `Total sales (${money.currency.iso})`
                    : `Total sales`
                }
                amount={money.amount}
                currency={money.currency.iso}
              />
            ))}
          </SideInner>
          {/* TODO: Show listings older than 2 weeks in carousel: */}
          {/* Medium Priority */}
          {/* <ActionCarousel {...carouselArgs} /> */}
        </Side>
      </Grid>

      <NextPrevious
        includeNext={pageInfo?.hasNextPage || !!pagination.before}
        includePrevious={pageInfo?.hasPreviousPage || !!pagination.after}
        onNext={() => {
          scrollToTop()
          setPagination({
            after: pageInfo.endCursor,
            first: paginationVars.perPage,
          })
        }}
        onPrevious={() => {
          scrollToTop()
          setPagination({
            before: pageInfo.startCursor,
            last: paginationVars.perPage,
          })
        }}
      />
    </>
  )
}

SellingListingsPage.propTypes = {}
