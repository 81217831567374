import PropTypes from 'prop-types'
import { useRef, useState } from 'react'
import styled from 'styled-components'
import { useMutation, useQuery } from 'urql'
import Input from '../_atoms/Input'
import Button from '../_atoms/Button'
import { useUserContext } from '../auth/UserContext'
import Warning from '../shared/Warning'
import toast from 'react-hot-toast'

const SALES_TEAM = `
query SalesTeam {
  team: entries(section: "salesTeam") {
    id
    title
  }
}
`

const SET_SHIP_DATE = `
mutation SetShipDate($id: ID!, $shipDate: Date) {
  orderSetShipDate(id: $id, shipDate: $shipDate) {
    id
    shipDate
  }
}
`

const SET_TAX_EXEMPT = `
mutation SetTaxExempt ($id: ID!, $isExempt: Boolean!, $reason: String, $number: String) {
  orderSetTaxExempt(id: $id, isExempt: $isExempt, reason: $reason, number: $number) {
    id
    taxExempt
    taxExemptReason

    total {
      amount
      amountCents
    }

    tax {
      amount
      amountCents
    }
  }
}
`

const SET_ACCOUNT_TAX_EXEMPT = `
mutation AccountSetTaxExempt ($id: ID!, $input: AccountTaxExemptInput!) {
  accountSetTaxExempt(id: $id, input: $input) {
    success
    account {
      id
    }
  }
}
`

const SET_SALES_TYPE = `
mutation SetSalesType ($id: ID!, $salesType: String!) {
  orderSetSalesType(id: $id, salesType: $salesType) {
    id
    salesType
  }
}
`

const SET_SALESPERSON = `
mutation SetSalesPerson ($id: ID!, $name: String!) {
  orderSetSalesperson(id: $id, name: $name) {
    id
    salesperson
  }
}
`

const FormWrapper = styled.div`
  max-width: 300px;
  margin-bottom: 15px;
`

const Select = styled.select`
  appearance: auto;
  border: 1px solid #efefef;
  border-radius: 3px;
  width: 100%;
`

function SalesPersonForm({ id, salesperson }) {
  const [{ data, fetching, error }] = useQuery({ query: SALES_TEAM })
  const [currentSalesperson, setSalesperson] = useState(salesperson)
  const [, persistSalesperson] = useMutation(SET_SALESPERSON)

  function handleSelectChange(event) {
    setSalesperson(event.target.value)
    persistSalesperson({ id, name: event.target.value })
  }

  if (error) {
    return <FormWrapper>Couldn&apos;t load sales team</FormWrapper>
  }

  if (fetching) {
    return <FormWrapper>Loading...</FormWrapper>
  }

  return (
    <FormWrapper>
      Salesperson:
      <Select onChange={handleSelectChange}>
        <option value="-1">Please Choose</option>
        {data.team.map((member) => (
          <option
            selected={member.title === currentSalesperson}
            key={`member-${member.id}`}
            value={member.title}
          >
            {member.title}
          </option>
        ))}
      </Select>
    </FormWrapper>
  )
}

SalesPersonForm.propTypes = {
  id: PropTypes.string.isRequired,
  salesperson: PropTypes.string,
}

function ShipDateForm({ id, shipDate }) {
  const inputRef = useRef(null)
  const [, persistShipDate] = useMutation(SET_SHIP_DATE)

  return (
    <div style={{ display: 'flex', alignItems: 'flex-end', gap: 20 }}>
      <Input
        required
        label="Ship date"
        type="date"
        ref={inputRef}
        defaultValue={shipDate}
        min={new Date().toISOString().split('T')[0]}
        onChange={(event) => {
          persistShipDate({ id, shipDate: event.currentTarget.value })
        }}
      />
      <Button
        style={{ marginBottom: 22 }}
        onClick={(event) => {
          event.preventDefault()

          if (inputRef.current) {
            inputRef.current.value = ''
          }

          persistShipDate({ id, shipDate: null })
        }}
      >
        Clear
      </Button>
    </div>
  )
}

ShipDateForm.propTypes = {
  id: PropTypes.string.isRequired,
  shipDate: PropTypes.string,
}

const reasons = ['Reseller', 'Charitable Organization']

function TaxExemptForm({
  id,
  taxExempt,
  taxExemptReason,
  taxExemptNo,
  contactId,
}) {
  const [isTaxExempt, setIsTaxExempt] = useState(taxExempt)
  const [useCustomReason, setUseCustomReason] = useState(
    taxExemptReason && !reasons.includes(taxExemptReason)
  )
  const [currentReason, setCurrentReason] = useState(taxExemptReason)
  const [currentExemptNo, setCurrentExemptNo] = useState(taxExemptNo)

  const [, persistTaxExempt] = useMutation(SET_TAX_EXEMPT)
  const [, persistAccountTaxExempt] = useMutation(SET_ACCOUNT_TAX_EXEMPT)

  return (
    <FormWrapper>
      Tax Exemption
      <Select
        defaultValue={useCustomReason ? 'other' : taxExemptReason}
        onChange={(event) => {
          const reason = event.currentTarget.value

          setCurrentReason(reason)

          if (reason) {
            setIsTaxExempt(true)

            if (reasons.includes(reason)) {
              setUseCustomReason(false)
            } else {
              setUseCustomReason(true)
            }
          } else {
            setIsTaxExempt(false)
            persistTaxExempt({
              id,
              isExempt: false,
              reason: null,
              number: null,
            })
          }
        }}
      >
        <option value="">Not exempt</option>
        {reasons.map((reason) => (
          <option key={reason} value={reason}>
            {reason}
          </option>
        ))}
        <option value="other">Other</option>
      </Select>
      {isTaxExempt && (
        <>
          {useCustomReason && (
            <div style={{ paddingTop: '15px' }}>
              <Input
                label="Custom reason"
                defaultValue={currentReason === 'other' ? '' : currentReason}
                onChange={(event) =>
                  setCurrentReason(event.currentTarget.value)
                }
              />
            </div>
          )}
          <div style={{ paddingTop: '15px' }}>
            <Input
              label="Tax Exemption No."
              defaultValue={currentExemptNo}
              maxLength="25"
              onChange={(event) =>
                setCurrentExemptNo(event.currentTarget.value)
              }
            />
          </div>
          <div style={{ paddingTop: '15px' }}>
            <Button
              onClick={async (event) => {
                event.preventDefault()
                toast.promise(
                  persistTaxExempt({
                    id,
                    isExempt: true,
                    reason: currentReason,
                    number: currentExemptNo,
                  }),
                  {
                    loading: 'Saving tax information to order...',
                    success: 'Order was updated.',
                    error: 'Order update failed. Please try again.',
                  }
                )

                if (contactId) {
                  toast.promise(
                    persistAccountTaxExempt({
                      id: contactId,
                      input: {
                        taxExemptNo: currentExemptNo,
                      },
                    }),
                    {
                      loading: 'Updating contact...',
                      success: 'Contact was updated.',
                      error: 'Could not update contact. Please try again.',
                    }
                  )
                }
              }}
            >
              Save Tax Exempt Details
            </Button>
          </div>
          <div style={{ paddingTop: '15px' }}>
            <Warning
              color="aqua"
              text="Please ensure that you have sent a tax exemption certificate to Angela before proceeding with this sale"
            />
          </div>
        </>
      )}
    </FormWrapper>
  )
}

TaxExemptForm.propTypes = {
  id: PropTypes.string.isRequired,
  taxExempt: PropTypes.bool,
  taxExemptReason: PropTypes.string,
  taxExemptNo: PropTypes.string,
  contactId: PropTypes.string,
}

const salesTypes = ['Phone', 'In-Store', 'Zendesk Chat']

function SalesTypeForm({ id, salesType }) {
  const [useCustomSalesType, setUseCustomSalesType] = useState(
    salesType && !salesTypes.includes(salesType)
  )

  const [currentSalesType, setCurrentSalesType] = useState(salesType)

  const [, persistSalesType] = useMutation(SET_SALES_TYPE)

  return (
    <FormWrapper>
      Sales Type
      <Select
        required
        defaultValue={useCustomSalesType ? 'other' : salesType}
        onChange={(event) => {
          const newSalesType = event.currentTarget.value

          if (!newSalesType) return

          setCurrentSalesType(newSalesType)

          if (salesTypes.includes(newSalesType)) {
            setUseCustomSalesType(false)
            persistSalesType({ id, salesType: newSalesType })
          } else {
            setUseCustomSalesType(true)
          }
        }}
      >
        <option value="">Please Choose</option>
        {salesTypes.map((st) => (
          <option key={st} value={st}>
            {st}
          </option>
        ))}
        <option value="other">Other</option>
      </Select>
      {useCustomSalesType && (
        <div style={{ paddingTop: '15px' }}>
          <Input
            label="Custom sales type"
            defaultValue={currentSalesType === 'other' ? '' : currentSalesType}
            onChange={(event) => setCurrentSalesType(event.currentTarget.value)}
          />
          <Button
            onClick={(event) => {
              event.preventDefault()

              persistSalesType({ id, salesType: currentSalesType })
            }}
          >
            Save sales type
          </Button>
        </div>
      )}
    </FormWrapper>
  )
}

SalesTypeForm.propTypes = {
  id: PropTypes.string.isRequired,
  salesType: PropTypes.string,
}

export default function AdminControls({
  id,
  taxExempt,
  taxExemptReason,
  taxExemptNo,
  salesperson,
  salesType,
  shipDate,
  contactId,
}) {
  const { user } = useUserContext()
  if (!user?.isAdmin) {
    return null
  }

  const canShowTaxForm = !!contactId

  return (
    <>
      <SalesPersonForm {...{ id, salesperson }} />
      <SalesTypeForm id={id} salesType={salesType} />
      <ShipDateForm id={id} shipDate={shipDate} />
      {canShowTaxForm && (
        <TaxExemptForm
          {...{ id, taxExempt, taxExemptReason, taxExemptNo, contactId }}
        />
      )}
      {!canShowTaxForm && (
        <p>Assign a contact to this order to view tax exemption options</p>
      )}
    </>
  )
}

export function adminDetailsComplete(user, orders) {
  if (!user?.isAdmin) {
    return true
  }

  const incomplete = orders.filter((order) => {
    return ['', '-1'].includes((order.salesperson || '').trim())
  })

  return incomplete.length === 0
}

AdminControls.propTypes = {
  id: PropTypes.string.isRequired,
  taxExempt: PropTypes.bool,
  taxExemptReason: PropTypes.string,
  taxExemptNo: PropTypes.string,
  salesperson: PropTypes.string,
  salesType: PropTypes.string,
  shipDate: PropTypes.string,
  contactId: PropTypes.string,
}
