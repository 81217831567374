import Button from '../_atoms/Button'
import { useMemo } from 'react'
import toast from 'react-hot-toast'
import { useMutation } from 'urql'
import { useLocation, useNavigate } from 'react-router-dom'
import useSearchQuery from '../shop/useSearchQuery'
import styled from 'styled-components'
import Alert from '../shared/Alert'
import Spacer from '../_helpers/Spacer'
import Link from '../shared/Link'
import { useUserContext } from '../auth/UserContext'

const Separator = styled.span`
  display: block;
  margin-top: 6px;
  margin-bottom: 7px;
  font-size: 14px;
  line-height: 1;
  text-align: center;
  color: ${({ theme }) => theme.colors.darkGrey};
`

const SAVE_SEARCH = `
  mutation SaveSearch($keywords: String, $filters: [SearchFilterInput!]) {
    listingSearchSave(search:{
      query: {
        keywords: $keywords
        filters: $filters
      }
    }) {
      success
      savedSearch {
        id
        keywords
        filters {
          key
          label
          value
        }
        emailOnMatch
      }
    }
  }
`

const UPDATE_SAVED_SEARCH = `
  mutation UpdateSavedSearch($id: ID!, $keywords: String, $filters: [SearchFilterInput!]) {
    listingSavedSearchRemove(id: $id) {
      success
    }

    listingSearchSave(search:{
      query: {
        keywords: $keywords
        filters: $filters
      }
    }) {
      success
      savedSearch {
        id
        keywords
        filters {
          key
          label
          value
        }
        emailOnMatch
      }
    }
  }
`

export default function SavedSearchButton() {
  const { user } = useUserContext()
  const [, updateSavedSearch] = useMutation(UPDATE_SAVED_SEARCH)
  const [, saveSearch] = useMutation(SAVE_SEARCH)
  const navigate = useNavigate()
  const location = useLocation()
  const { query } = useSearchQuery()
  const savedSearch = location?.state?.savedSearch

  const handleUpdateSavedSearch = async () => {
    const toastId = toast('Updating saved search…')

    const { data } = await updateSavedSearch({
      ...query,
      id: savedSearch.id,
    })

    if (
      data?.listingSavedSearchRemove?.success &&
      data?.listingSearchSave?.success
    ) {
      toast.success('Updated saved search', { id: toastId })

      navigate(`${location.pathname}${location.search}`, {
        state: {
          ...location.state,
          savedSearch: data.listingSearchSave.savedSearch,
        },
      })
    } else {
      toast.error('Unable to update saved search', { id: toastId })
    }
  }

  const saveAvailable = useMemo(() => {
    if (query.keywords && query.keywords !== savedSearch?.keywords) return true

    const originalFilters = savedSearch ? savedSearch.filters : []

    if (!query.filters || query.filters.length < 1) return false

    if (query.filters.length !== originalFilters.length) return true

    for (const curr of query.filters) {
      const orig = originalFilters.find((f) => f.key === curr.key)

      if (!orig || orig.value !== curr.value) return true
    }

    return false
  }, [query, savedSearch])

  const handleSaveSearch = async () => {
    const toastId = toast('Saving search…')

    const { data, error } = await saveSearch(query)

    if (data?.listingSearchSave.success) {
      toast.success('Saved search', { id: toastId })

      navigate(`${location.pathname}${location.search}`, {
        state: {
          ...location.state,
          savedSearch: data.listingSearchSave.savedSearch,
        },
      })
    } else if (error.message.includes('Saved searches must be unique')) {
      toast.error('Unable to add duplicate saved search', { id: toastId })
    } else {
      toast.error('Unable to add saved search', { id: toastId })
    }
  }

  if (!user) {
    return (
      <Button to="/login" as={Link}>
        Log in to save searches
      </Button>
    )
  }

  return (
    <>
      {savedSearch && (
        <>
          <Alert type="success" size="medium">
            This is a saved search
          </Alert>
          <Spacer size="10px" />
        </>
      )}

      {saveAvailable && (
        <>
          {savedSearch && (
            <>
              <Button
                fullWidth
                variant="primaryLight"
                onClick={handleUpdateSavedSearch}
              >
                Update saved search
              </Button>
              <Separator>&ndash; or &ndash;</Separator>
            </>
          )}

          <Button
            fullWidth
            variant={savedSearch ? 'secondary' : 'primaryLight'}
            onClick={handleSaveSearch}
          >
            Save as new saved search
          </Button>

          <Spacer size="14px" />
        </>
      )}
    </>
  )
}
