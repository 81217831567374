import styled from 'styled-components'
import { linkStates } from '../_abstracts/Type'
import Link from '../shared/Link'

const StyledLink = styled(Link)`
  ${linkStates};
  cursor: pointer;
  display: inline-block;
  color: currentColor;
`

export default StyledLink
