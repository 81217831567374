import { forwardRef } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { HeaderM, Body } from '../_abstracts/Type'
import Html from '../data/Html'
import { down } from 'styled-breakpoints'

const List = styled.ol`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -13px;
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;

  ${({ numbered }) =>
    numbered &&
    css`
      counter-reset: numbered-block-counter 0;
    `}
`

const Item = styled.div`
  width: 50%;
  padding: 13px;

  ${({ small }) =>
    small &&
    css`
      width: 33.33%;

      ${down('medium')} {
        width: 50%;
      }
    `}

  ${down('small')} {
    width: 100%;
  }
`

const Image = styled.img`
  margin-bottom: 25px;
`

const Title = styled.h3`
  ${HeaderM}
  margin-top: -0.2em;
  margin-bottom: 15px;
  font-weight: 600;

  &:last-child {
    margin-bottom: 0;
  }

  ${({ numbered }) =>
    numbered &&
    css`
      counter-increment: numbered-block-counter 1;

      &:before {
        content: counter(numbered-block-counter) '. ';
        color: ${(props) => props.theme.colors.accent};
      }
    `}
`

const Text = styled.div`
  ${Body};
  color: ${(props) => props.theme.colors.xDarkGrey};

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${({ theme }) => theme.colors.darkBlue};
  }
`

const Grid = forwardRef(({ grid, numbered }, ref) => {
  return (
    <List numbered={numbered} ref={ref}>
      {grid.map((item, index) => (
        <Item key={index} small={item.isSmall}>
          {item.image.length ? <Image src={item.image[0].url} alt="" /> : null}
          <Title numbered={numbered}>{item.itemTitle}</Title>
          {item.text && (
            <Text>
              <Html html={item.text} />
            </Text>
          )}
        </Item>
      ))}
    </List>
  )
})

Grid.propTypes = {
  grid: PropTypes.arrayOf(
    PropTypes.shape({
      isSmall: PropTypes.bool,
      itemTitle: PropTypes.string.isRequired,
      text: PropTypes.string,
      image: PropTypes.shape({
        url: PropTypes.string.isRequired,
      }),
    })
  ).isRequired,
  numbered: PropTypes.bool,
}

Grid.displayName = 'Grid'

export default Grid
