import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { down, up } from 'styled-breakpoints'

import styled, { css } from 'styled-components'

import Avatar from '../shared/Avatar'

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;

  ${(props) =>
    props.state === 'sent' &&
    css`
      flex-direction: row-reverse;
    `}

  > *:first-child {
    flex-shrink: 0;

    ${down('small')} {
      width: 46px;
    }
  }
`

const Bubble = styled.div`
  position: relative;
  width: 100%;
  padding: 20px 40px;
  border-radius: ${(props) => props.theme.radii};
  margin-left: ${(props) => (props.state === 'received' ? '30px' : 0)};
  margin-right: ${(props) => (props.state === 'sent' ? '30px' : 0)};
  border: 1px solid;

  ${up('large')} {
    width: 75%;

    ${({ fullWidth }) =>
      fullWidth &&
      css`
        width: 100%;
      `}
  }

  &::after {
    content: '';
    position: absolute;
    height: 16px;
    width: 16px;
    top: calc(80px / 2 - 8px);
    left: ${(props) => props.state === 'received' && '-9px'};
    right: ${(props) => props.state === 'sent' && '-9px'};
    transform: ${(props) =>
      props.state === 'received' ? 'rotate(225deg)' : 'rotate(45deg)'};
    border-top: 1px solid;
    border-right: 1px solid;
  }

  ${down('small')} {
    width: 75%;
    padding: 20px;
    margin-left: ${(props) => (props.state === 'received' ? '20px' : 0)};
    margin-right: ${(props) => (props.state === 'sent' ? '20px' : 0)};
    &::after {
      top: calc(46px / 2 - 8px);
    }
  }

  ${(props) =>
    (props.color === 'sent' &&
      css`
        border-color: ${(props) => props.theme.colors.blueTint};
        background-color: ${(props) => props.theme.colors.blueTint};

        &::after {
          border-color: ${(props) => props.theme.colors.blueTint};
          background-color: ${(props) => props.theme.colors.blueTint};
        }
      `) ||
    (props.color === 'received' &&
      css`
        border-color: ${(props) => props.theme.colors.accentTint};
        background-color: ${(props) => props.theme.colors.accentTint};

        &::after {
          border-color: ${(props) => props.theme.colors.accentTint};
          background-color: ${(props) => props.theme.colors.accentTint};
        }
      `) ||
    (props.color === 'offer' &&
      css`
        max-width: 100%;
        width: 100%;
        border-color: ${(props) => props.theme.colors.accent};
        background-color: ${(props) => props.theme.colors.accentTint};

        &::after {
          border-color: ${(props) => props.theme.colors.accent};
          background-color: ${(props) => props.theme.colors.accentTint};
        }
      `) ||
    (props.color === 'accepted' &&
      css`
        border-color: ${(props) => props.theme.colors.aqua};
        background-color: ${(props) => props.theme.colors.aquaTint};

        &::after {
          border-color: ${(props) => props.theme.colors.aqua};
          background-color: ${(props) => props.theme.colors.aquaTint};
        }
      `) ||
    (props.color === 'declined' &&
      css`
        border-color: ${(props) => props.theme.colors.red};
        background-color: ${(props) => props.theme.colors.redTint};

        &::after {
          border-color: ${(props) => props.theme.colors.red};
          background-color: ${(props) => props.theme.colors.redTint};
        }
      `)}
`

export default function MessageBubble({
  id,
  user,
  children,
  state = 'sent',
  color = 'sent',
  fullWidth,
}) {
  return (
    <Wrapper id={id} state={state}>
      <Link to={`/profile/${user.username}/listings`}>
        {state == 'received' ? (
          <Avatar size="medium" user={user} />
        ) : (
          <Avatar size="medium" state="loggedIn" user={user} />
        )}
      </Link>
      <Bubble state={state} color={color} fullWidth={fullWidth}>
        {children}
      </Bubble>
    </Wrapper>
  )
}

MessageBubble.propTypes = {
  id: PropTypes.string,
  user: PropTypes.shape({
    username: PropTypes.string.isRequired,
  }).isRequired,
  state: PropTypes.PropTypes.oneOf(['received', 'sent']),
  color: PropTypes.PropTypes.oneOf([
    'sent',
    'received',
    'accepted',
    'declined',
    'offer',
  ]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  fullWidth: PropTypes.bool,
}
