import PropTypes from 'prop-types'
import { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import linearGradient from '../_helpers/linearGradient'
import Container from '../_helpers/Container'
import Spacer from '../_helpers/Spacer'
import { UserConsumer } from '../auth/UserContext'
import EditItemForm from '../portfolio/EditItemForm'
import ResellItemForm from '../portfolio/ResellItemForm'
import ItemForm from '../portfolio/ItemForm'
import { LoginReturnNavigate } from '../_atoms/ReturnLink'
import Hero from '../shared/Hero'
import { useQuery } from 'urql'
import Loader from '../shared/Loader'
import NotFound from '../404/NotFound'

const Wrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-image: ${linearGradient(
    'to bottom',
    'white 1 0%',
    'lighterGrey 1 500px'
  )};
`

const GET_PAGE = `
  query GetPage {
    entry(section: "sellViaExchange") {
      id
      title
      ... on sellViaExchange_sellViaExchange_Entry {
        shortTitle
        backgroundImage {
          url(handle: "large")
        }
      }
    }

    photographyExtra: entry(section: "exchangePlusExtras", slug: ["photography"]) {
      id
      ... on exchangePlusExtras_default_Entry {
        exchangePlusExtraPrice
      }
    }
  }
`

export default function ListItemPage({ source }) {
  const [{ data, fetching, error }] = useQuery({ query: GET_PAGE })
  const navigate = useNavigate()
  const { itemId } = useParams()
  const location = useLocation()
  const [type] = useState(location.state?.type)

  if (fetching) return <Loader background="#fff" />

  if (error) return <p>{error.message}</p>

  if (!data.entry) {
    return <NotFound />
  }

  const { entry } = data

  return (
    <UserConsumer>
      {({ user }) => {
        if (!user) return <LoginReturnNavigate />

        return (
          <>
            <Helmet>
              <title>Selling | List Item</title>
            </Helmet>
            <Wrapper>
              <Hero
                hero={{
                  header: 'Sell via Exchange',
                  headerWeight: 600,
                  subheader: entry.shortTitle,
                }}
              />
              {(source === 'new' && (
                <Container>
                  <Spacer size="60px" />
                  <ItemForm
                    includeListingFields
                    onSave={({ listing }) => {
                      console.log(listing)
                      navigate('/profile/selling/listings')
                    }}
                    photographPrice={
                      (data?.photographyExtra?.exchangePlusExtraPrice || 0) /
                      100
                    }
                    listing={
                      type && {
                        ...ItemForm.defaultProps,
                        type,
                      }
                    }
                  />
                  <Spacer fromSize="40px" toSize="80px" />
                </Container>
              )) ||
                (source === 'resell' && (
                  <Container>
                    <Spacer size="60px" />
                    <ResellItemForm
                      heading="Please review the information and fill in any blank areas that are necessary."
                      headingAlign="center"
                      headingSize="small"
                      itemId={itemId}
                      photographPrice={
                        (data?.photographyExtra?.exchangePlusExtraPrice || 0) /
                        100
                      }
                      onSave={({ listing }) => {
                        console.log(listing)
                        navigate('/profile/selling/listings')
                      }}
                    />
                    <Spacer fromSize="40px" toSize="80px" />
                  </Container>
                )) ||
                (itemId && (
                  <Container>
                    <Spacer size="60px" />
                    <EditItemForm
                      heading="Please review the information and fill in any blank areas that are necessary."
                      headingAlign="center"
                      headingSize="small"
                      includeListingFields
                      itemId={itemId}
                      photographPrice={
                        (data?.photographyExtra?.exchangePlusExtraPrice || 0) /
                        100
                      }
                      onSave={({ listing }) => {
                        console.log(listing)
                        navigate('/profile/selling/listings')
                      }}
                    />
                    <Spacer fromSize="40px" toSize="80px" />
                  </Container>
                ))}
            </Wrapper>
          </>
        )
      }}
    </UserConsumer>
  )
}

ListItemPage.propTypes = {
  source: PropTypes.oneOf(['portfolio', 'new', 'resell']),
}
