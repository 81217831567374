import PropTypes from 'prop-types'
import { useState } from 'react'
import Container from '../_helpers/Container'
import { up, down } from 'styled-breakpoints'

import styled from 'styled-components'

const Playlist = styled.div`
  width: 75%;
  margin: 0 auto;
  background-color: pink;
  background-color: ${(props) => props.theme.colors.darkBlue};
  border-radius: 5px;
  overflow: hidden;

  ${up('small')} {
    display: flex;
  }

  ${down('small')} {
    width: 90%;
  }
`
const Main = styled.div`
  width: 100%;
`

const Video = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const Sidebar = styled.div`
  position: relative;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  mask-image: linear-gradient(
    90deg,
    transparent,
    currentColor 30px,
    currentColor calc(100% - 75px),
    transparent
  );
  height: 100px;

  ${Video} {
    width: 100px;
    flex-shrink: 0;
    margin-left: 25px;
    padding-bottom: 18.25%;
  }

  ${up('small')} {
    width: 25%;
    height: auto;
    overflow-x: hidden;
    overflow-y: auto;
    mask-image: linear-gradient(
      transparent,
      currentColor 30px,
      currentColor calc(100% - 75px),
      transparent
    );

    ${Video} {
      width: 100%;
      margin-top: 25px;
      margin-left: 0;
      padding-bottom: 56.25%;
    }
  }
`

const VideoList = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  > * {
    display: flex;
    align-items: center;
    height: 100%;
  }
  ${up('small')} {
    > * {
      display: block;
    }
  }
`

export default function VideoPlayer({ videos }) {
  const videoList = videos
    .map((video) => {
      const url = video
        .replace(/(>|<)/gi, '')
        .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/)

      return url[2]?.split(/[^0-9a-z_-]/i)?.[0]
    })
    .filter((id) => !!id)

  const [currentVideo, setCurrentVideo] = useState(videoList[0])

  return (
    <Playlist>
      <Main>
        <Video>
          <iframe
            width="853"
            height="480"
            src={`https://www.youtube.com/embed/${currentVideo}`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </Video>
      </Main>
      {videoList.length > 1 && (
        <Sidebar>
          <VideoList>
            <Container>
              {videoList
                .filter((v) => v !== currentVideo)
                .map((video) => (
                  <Video onClick={() => setCurrentVideo(video)} key={video}>
                    <img src={`http://img.youtube.com/vi/${video}/0.jpg`}></img>
                  </Video>
                ))}
            </Container>
          </VideoList>
        </Sidebar>
      )}
    </Playlist>
  )
}

VideoPlayer.propTypes = {
  videos: PropTypes.array.isRequired,
}
