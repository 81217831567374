import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Body, HeaderSansM } from '../_abstracts/Type'
import Money from '../data/Money'

const Wrapper = styled.div`
  overflow: hidden;
  border: 1px solid ${(props) => props.theme.colors.darkBlue};
  border-radius: ${(props) => props.theme.radii};
`

const Top = styled.div`
  background: ${(props) => props.theme.colors.darkBlue};
  color: ${(props) => props.theme.colors.white};
  text-align: center;
  padding: 8px 13px 15px;
`

const Header = styled.h2`
  ${Body}
  color: ${(props) => props.theme.colors.white};
`

const Mons = styled.p`
  ${HeaderSansM}
  margin-top: 3px;
  line-height: 1;
`

const Bottom = styled.div`
  padding: 10px;
  background: ${(props) => props.theme.colors.accentTint};
  text-align: center;
`

export default function Total({ amount, currency, items }) {
  return (
    <Wrapper>
      {amount && (
        <Top>
          <Header>Current portfolio value</Header>
          <Mons>
            <Money amount={amount} currency={currency} includeCurrency />
          </Mons>
        </Top>
      )}
      {items != null && (
        <Bottom>
          {items} item{items === 1 ? '' : 's'} in portfolio
        </Bottom>
      )}
    </Wrapper>
  )
}

Total.propTypes = {
  amount: PropTypes.number,
  currency: PropTypes.string,
  items: PropTypes.number,
}
