import { Formik, Form } from 'formik'
import { forwardRef, useRef, useState } from 'react'
import { down } from 'styled-breakpoints'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useMutation } from 'urql'
import * as Yup from 'yup'
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'
// import { Icon, LandscapeIcon, ClipboardIcon } from '../_base/Icon'
// import { BodyS } from '../_abstracts/Type'
import { useGlobals } from '../hooks'
import Wysiwyg, { Inner as WysiwygInner } from '../inputs/Wysiwyg'
// import Upload from '../inputs/Upload'
// import ListingCheckbox from '../inputs/ListingCheckbox'
import Button, { ButtonStyle } from '../_atoms/Button'
import Alert from '../shared/Alert'
import ZendeskButton from '../shared/ZendeskButton'

const CREATE_THREAD = `
  mutation CreateThread($thread: MessageThreadInput!) {
    messageThreadCreate(thread: $thread) {
      success
      thread {
        id
      }
    }
  }
`

const CREATE_MESSAGE = `
  mutation CreateMessge($threadId: ID!, $message: MessageInput!) {
    messageCreate(threadId: $threadId, message: $message) {
      success
    }
  }
`

const Schema = Yup.object().shape({
  body: Yup.string()
    .max(10000, 'Max 10000 characters')
    .trim()
    .required('Required'),
})

const Wrap = styled.div`
  position: relative;
  max-width: 817px;
  margin-right: auto;
  margin-left: auto;

  ${WysiwygInner} {
    border: 0;
  }
`

const Inner = styled.div`
  border: 1px solid ${(props) => props.theme.colors.midGrey};
  background-color: ${(props) => props.theme.colors.white};
  padding: 15px 15px 12px;
  border-radius: ${(props) => props.theme.radii};
`

// const Actions = styled.div`
//   padding-top: 5px;
//   margin-top: 10px;
//   border-top: 1px solid ${(props) => props.theme.colors.blueTint};
// `

// const Action = styled.div`
//   --activeColor: ${(props) => props.theme.colors.midGrey};
//   ${BodyS}
//   font-weight: 300;
//   margin-right: 20px;
//   transition: 0.25s;
//   opacity: ${(props) => (props.active ? 0.35 : 1)};
//   display: inline-block;
//   cursor: pointer;

//   &:hover {
//     opacity: 0.35;
//   }

//   & + & {
//     position: relative;
//     padding-left: 20px;
//   }

//   & + &::before {
//     position: absolute;
//     top: 50%;
//     left: 0;
//     transform: translateY(-50%);
//     content: '';
//     height: 15px;
//     border-left: 1px solid ${(props) => props.theme.colors.midGrey};
//   }

//   ${Icon} {
//     display: inline-block;
//     margin-right: 10px;
//   }

//   &:nth-child(1) ${Icon} {
//     width: 16px;
//     height: 12px;
//   }

//   &:nth-child(2) ${Icon} {
//     width: 10px;
//     height: 14px;
//   }
// `

// const Contents = styled.div`
//   position: relative;
// `

// const Content = styled.div`
//   padding-bottom: 10px;
//   padding-top: 10px;

//   ${ButtonStyle} {
//     width: 100%;
//     max-width: 380px;
//   }
// `

// const Grid = styled.div`
//   border: 2px dashed #d4d4dd;
//   border-radius: 5px;
//   display: flex;
//   flex-wrap: wrap;
//   padding: 10px;
//   overflow: auto;
//   background-color: ${(props) => props.theme.colors.lightGrey};
//   max-height: 320px;

//   ${down('medium')} {
//     max-height: 407px;
//   }
// `

// const Item = styled.div`
//   padding: 5px;
//   width: 33.33%;

//   ${down('medium')} {
//     width: 50%;
//   }

//   ${down('xsmall')} {
//     width: 100%;
//   }

//   > * > * {
//     width: 100%;
//     min-width: 0;
//     max-width: none;
//     background-color: ${(props) => props.theme.colors.white};
//   }
// `

// const ItemInner = styled.div`
//   position: relative;
// `

// const Counter = styled.p`
//   font-size: 14px;
//   font-weight: 300;
//   color: ${(props) => props.theme.colors.xDarkGrey};
//   margin-top: 5px;
// `

const Footer = styled.div`
  text-align: center;
  margin-top: 20px;

  ${ButtonStyle} {
    width: 100%;
    max-width: 400px;
  }
`

const MessageAlert = styled.div`
  display: flex;
  row-gap: 0.4em;
  column-gap: 1em;
  align-items: center;

  ${down('medium')} {
    flex-wrap: wrap;
  }

  p {
    flex-grow: 1;
  }

  button {
    margin: 3px 0;
    white-space: nowrap;
  }
`

const MessageInput = forwardRef(({ autofocus }, wysiwygRef) => {
  const { messages } = useGlobals('messages')
  const [containsKeyword, setContainsKeyword] = useState(false)
  const keywords =
    messages?.keywords.split(',').map((word) => word.trim()) || []

  const onUpdate = ({ editor }) => {
    // Show an alert message if the user includes a flagged keyword (e.g. to discourage off-site trades)
    const words = editor.getText().toLowerCase().split(/\s+/)
    let foundKeyword = false

    for (const word of words) {
      if (keywords.includes(word)) {
        foundKeyword = true
        break
      }
    }

    setContainsKeyword(foundKeyword)
  }

  return (
    <>
      <Wysiwyg
        autofocus={autofocus}
        name="body"
        label="Message"
        hideLabel
        required
        onUpdate={onUpdate}
        ref={wysiwygRef}
      />
      {containsKeyword && messages && (
        <Alert>
          <MessageAlert>
            <p>{messages.alertText}</p>
            <ZendeskButton message={messages.zendeskMessage}>
              <Button variant="small">{messages.zendeskButtonLabel}</Button>
            </ZendeskButton>
          </MessageAlert>
        </Alert>
      )}
    </>
  )
})

MessageInput.propTypes = {
  autofocus: PropTypes.bool,
}

MessageInput.displayName = 'MessageInput'

export default function Editor({
  autofocus,
  participants,
  itemId,
  messageThreadId,
  onMessageCreate,
}) {
  const wysiwygRef = useRef()
  // const [mediaIsOpen, setMediaIsOpen] = useState({ el: null, state: false })
  // const [listingsIsOpen, setListingsIsOpen] = useState({
  //   el: null,
  //   state: false,
  // })
  // const [counter, setCounter] = useState(0)

  // function handleCounter(checked) {
  //   if (checked) {
  //     setCounter((count) => count + 1)
  //   }
  //   if (!checked) {
  //     setCounter((count) => count - 1)
  //   }
  // }

  // function handleMediaClick(e) {
  //   if (mediaIsOpen.state) {
  //     setMediaIsOpen(false)
  //     e.currentTarget.style.color = 'initial'
  //   }
  //   if (!mediaIsOpen.state) {
  //     if (listingsIsOpen.state) {
  //       setListingsIsOpen({ state: false })
  //       listingsIsOpen.el.style.color = 'initial'
  //     }
  //     setMediaIsOpen({ el: e.currentTarget, state: true })
  //     e.currentTarget.style.color = 'var(--activeColor)'
  //   }
  // }
  // function handleListingClick(e) {
  //   if (listingsIsOpen.state) {
  //     setListingsIsOpen(false)
  //     e.currentTarget.style.color = 'initial'
  //   }
  //   if (!listingsIsOpen.state) {
  //     if (mediaIsOpen.state) {
  //       setMediaIsOpen({ state: false })
  //       mediaIsOpen.el.style.color = 'initial'
  //     }
  //     setListingsIsOpen({ el: e.currentTarget, state: true })
  //     e.currentTarget.style.color = 'var(--activeColor)'
  //   }
  // }

  const [threadId, setThreadId] = useState(messageThreadId)
  const [, createThread] = useMutation(CREATE_THREAD)
  const [, createMessage] = useMutation(CREATE_MESSAGE)
  const navigate = useNavigate()

  async function handleSubmit(fields) {
    toast.loading('Sending message…', { id: 'messages' })

    const parsedFields = Schema.cast(fields, { strict: true })
    const { body } = parsedFields

    let currentThreadId = threadId
    let shouldNavigate = false

    if (!currentThreadId) {
      const { data, error } = await createThread({
        thread: { participants, item: itemId || undefined },
      })

      if (error) {
        console.error(error)
        toast.error('Message thread could not be created', { id: 'messages' })
        return
      }

      currentThreadId = data.messageThreadCreate.thread.id
      setThreadId(currentThreadId)
      shouldNavigate = true
    }

    const { data, error } = await createMessage({
      threadId: currentThreadId,
      message: {
        body,
      },
    })

    if (shouldNavigate) {
      navigate(`/messages/${currentThreadId}`, {
        state: { scrollToTop: false },
      })
    }

    if (error) {
      console.error(error)
      toast.error('Message could not be sent', { id: 'messages' })
      return
    }

    if (data?.messageCreate?.success) {
      toast.success('Message was sent', { id: 'messages' })
      onMessageCreate?.()
      wysiwygRef?.current?.clear()
    }
  }

  return (
    <Wrap>
      <Formik
        initialValues={{ body: '' }}
        onSubmit={handleSubmit}
        validationSchema={Schema}
      >
        <Form>
          <Inner>
            <>
              <MessageInput ref={wysiwygRef} autofocus={autofocus} />
              {/* High and medium Prioirty hide */}
              {/* <Actions>
                <Action onClick={(e) => handleMediaClick(e)}>
                  <LandscapeIcon />
                  <span title="Add images">Add images</span>
                </Action>

                <Action onClick={(e) => handleListingClick(e)}>
                  <ClipboardIcon />
                  <span title="Add from your listings">Add listings</span>
                </Action>
              </Actions> */}
              {/*
              <Contents>
                {mediaIsOpen.state && (
                  <Content>
                    <Upload
                      name="attachments"
                      label="Attachments"
                      images={images}
                      border={false}
                    />
                  </Content>
                )}
                {listings && listingsIsOpen.state && (
                  <Content>
                    <Grid>
                      {listings.map((listing, index) => {
                        return (
                          <Item key={index}>
                            <ItemInner>
                              <ListingCheckbox
                                handleCounter={handleCounter}
                                name="listing"
                                value={listing.item.title}
                                listing={listing}
                              />
                            </ItemInner>
                          </Item>
                        )
                      })}
                    </Grid>
                    <Counter>({counter})Selected</Counter>
                  </Content>
                )}
              </Contents> */}
            </>
          </Inner>

          <Footer>
            <Button type="submit" variant="primaryDark">
              Send
            </Button>
          </Footer>
        </Form>
      </Formik>
    </Wrap>
  )
}

Editor.propTypes = {
  autofocus: PropTypes.bool,
  images: PropTypes.array,
  listings: PropTypes.array,
  messageThreadId: PropTypes.string,
  participants: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  itemId: PropTypes.string,
  onMessageCreate: PropTypes.func,
}
