import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Media } from '../_base/Media'
import { HeaderL, BodyS, SmallLetters } from '../_abstracts/Type'
import { down } from 'styled-breakpoints'
import DateTime from '../data/DateTime'
import OrderSummaryTable from '../orders/OrderSummaryTable'
import Address from '../address/Address'
import Money from '../data/Money'
import Link from '../_atoms/Link'
import BroughtToShippingButton from './BroughtToShippingButton'
import { useUserContext } from '../auth/UserContext'
import Button from '../_atoms/Button'
import Table from '../shared/Table'
import { useGlobals } from '../hooks'
import { Error } from '../_atoms/ErrorMessage'
import SellingTrackingForm from '../selling/TrackingForm'

const Wrapper = styled.div``

const Item = styled.div`
  &:not(:last-child) {
    border-bottom: 1px solid ${(props) => props.theme.colors.midDarkGrey};
  }
`

const Header = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 7.5px;
`

const Info = styled.ul`
  padding: 7.5px;
`

const InfoItem = styled.li`
  ${BodyS}
  margin-left: 25px;
  display: inline-block;
`

const OrderIndex = styled.div`
  ${HeaderL}
  padding: 7.5px;
  color: ${(props) => props.theme.colors.accent};
  vertical-align: top;
`

const Details = styled.div`
  position: relative;
  overflow: hidden;
  padding: 15px;
  border-bottom: 1px solid ${(props) => props.theme.colors.blueTint};
`

const Amount = styled.div`
  padding-top: 10px;
  vertical-align: bottom;
  max-width: 340px;
  margin-left: auto;
`

const ItemInner = styled.div`
  display: flex;

  ${Media} {
    width: 120px;
    min-width: 120px;

    img {
      width: 100%;
    }
  }
`

const Content = styled.div`
  padding-left: 25px;
`

const Title = styled(Link)`
  ${BodyS}
  font-weight: 700;
  margin-bottom: 20px;
`

const Sold = styled.div`
  ${SmallLetters}
  color: ${(props) => props.theme.colors.xDarkGrey};
`

const Sku = styled.div`
  ${SmallLetters}
  color: ${(props) => props.theme.colors.xDarkGrey};
`

const LineItemMeta = styled.div`
  ${SmallLetters}
  color: ${(props) => props.theme.colors.xDarkGrey};
`

const Seller = styled(Link)`
  display: inline-block;
  text-decoration: none;
  color: ${(props) => props.theme.colors.accent};
`

const Summary = styled.div`
  padding-left: 20px;
  padding-right: 20px;

  table {
    ${BodyS}
    color: ${(props) => props.theme.colors.darkBlue};
    width: 100%;
    border-collapse: collapse;
  }

  th {
    font-weight: inherit;
    padding: 8px 0;
    text-align: left;
  }

  td {
    padding: 8px 0;
    text-align: right;
  }

  tr:not(:last-child) th,
  tr:not(:last-child) td {
    border-bottom: 1px solid ${(props) => props.theme.colors.blueTint};
  }

  tr:last-child th,
  tr:last-child td {
    font-weight: 900;
  }
`

const Shipping = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 15px 30px;
  border-top: 1px solid ${(props) => props.theme.colors.blueTint};
  padding-bottom: 20px;

  ${down('medium')} {
    padding: 10px;
  }
`

const Terms = styled.div`
  font-size: 12px;
  padding: 5px 20px 4px 0;
  margin-top: -20px;
  margin-bottom: 20px;
  max-width: 400px;
`

const SmallButton = styled(Button)`
  ${SmallLetters}
  margin-top: 15px;
`

export const Column = styled.div`
  ${SmallLetters}
  padding: 10px;
  display: inline-flex;
  flex-direction: column;
  width: 33%;
  color: ${(props) => props.theme.colors.xDarkGrey};

  ${down('small')} {
    width: 50%;
  }

  strong {
    ${BodyS}
    font-weight: 600;
    display: inline-block;
    padding-bottom: 5px;
    color: ${(props) => props.theme.colors.blue};
  }

  > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }

  > * + * {
    margin-top: 20px;
  }
`

function paymentTypeString(orderPayment) {
  const { payment } = orderPayment
  switch (payment?.__typename) {
    case 'PaymentStripe': {
      let label = 'Stripe'

      if (payment.terminal) label += ' (Terminal)'

      return label
    }

    case 'PaymentPayPal':
      return 'PayPal'
    case 'PaymentManual':
      return 'Manual'
    case 'PaymentPrepaidCode':
      return orderPayment?.isTrade ? 'Trade' : 'Code'
  }

  return 'Unknown'
}

function deconstructDate(dateString) {
  if (!dateString) return null

  const parts = dateString
    .split('-')
    .map((s) => parseInt(s))
    .filter((n) => !isNaN(n))
  if (parts.length != 3) return null

  return {
    year: parts[0],
    month: parts[1] - 1, // JS dates are 0-indexed
    day: parts[2],
  }
}

export function deconstructedDate(dateString) {
  const parts = deconstructDate(dateString)
  if (!parts) return null

  return new Date(parts.year, parts.month, parts.day)
}

function Order({ index, order }) {
  const { user } = useUserContext()
  const shipDate = deconstructedDate(order.shipDate)
  const { product: productGlobals } = useGlobals('product')

  return (
    <Item>
      <Header>
        <OrderIndex>{index}</OrderIndex>

        <Info>
          <InfoItem>Order number: {order.number}</InfoItem>
          <InfoItem>
            Date:{' '}
            <DateTime
              date={new Date(order?.fullyPaidAt ?? order.completedAt)}
            />
          </InfoItem>
        </Info>
      </Header>

      {order.refunds && order.refunds.length > 0 && (
        <div style={{ paddingInline: 16 }}>
          <Error>This order has had a refund processed.</Error>
        </div>
      )}

      <Details>
        {order.lineItems.map((lineItem) => {
          const image = lineItem.listing.item.images?.[0]
          return (
            <ItemInner key={lineItem.id}>
              {image && (
                <Media>
                  <img src={image.url} alt={lineItem.listing.item.title} />
                </Media>
              )}

              <Content>
                <Title
                  to={`/shop/${lineItem.listing.item.slug}/${lineItem.listing.item.id}`}
                  $reverse
                >
                  {lineItem.listing.item.title}
                </Title>
                {productGlobals?.orderInvoiceTerms && (
                  <Terms>
                    <p>{productGlobals.orderInvoiceTerms}</p>
                  </Terms>
                )}
                {lineItem?.listing?.item?.sku && (
                  <Sku>SKU: {lineItem.listing.item.sku}</Sku>
                )}
                <Sold>
                  Sold by:{' '}
                  <Seller
                    to={`/profile/${lineItem.listing.item.account.username}/listings`}
                  >
                    {lineItem.listing.item.account.username}
                  </Seller>
                </Sold>
                <LineItemMeta>Quantity: {lineItem.quantity}</LineItemMeta>
                <LineItemMeta>
                  Price:{' '}
                  <Money
                    amount={lineItem.unitCost.amount}
                    currency={lineItem.unitCost.currency.iso}
                  />
                </LineItemMeta>
                {lineItem.cartNotes && (
                  <LineItemMeta>
                    <br />
                    <p>Cart Notes:</p>

                    <p>
                      {lineItem.cartNotes.split('\n').map((line) => (
                        <>
                          {line}
                          <br />
                        </>
                      ))}
                    </p>
                  </LineItemMeta>
                )}
                {user?.isAdmin && user.id !== order?.account?.id && (
                  <div style={{ marginTop: '15px' }}>
                    <BroughtToShippingButton lineItem={lineItem} />
                  </div>
                )}
                {order.canSetTracking && (
                  <SellingTrackingForm
                    shipmentId={order.shipment.id}
                    values={{
                      trackingNumber: order.shipment.trackingNumber ?? '',
                      carrierName: order.shipment.carrierName ?? '',
                    }}
                  />
                )}
                {user?.isAdmin && (
                  <SmallButton
                    to={`/admin/exchange/line-items/${lineItem.id}`}
                    forceRegularLink
                  >
                    View in craft
                  </SmallButton>
                )}
              </Content>
            </ItemInner>
          )
        })}
      </Details>

      <Amount>
        <Summary>
          <OrderSummaryTable order={order} />
        </Summary>
      </Amount>

      <Shipping>
        <Column>
          {order.shipment.address && (
            <Address
              heading="Shipping address"
              address={order.shipment.address}
            />
          )}

          {!order.shipment.address && order.account.id !== user.id && (
            <Address
              heading="Shipping address for authentication"
              address={{
                firstName: 'Carter',
                lastName: 'Vintage',
                line1: '625 8th Ave S',
                city: 'Nashville',
                region: { name: 'Tennessee' },
                country: { name: 'United States' },
                postalCode: '37203',
              }}
            />
          )}
        </Column>

        {order.shippingMethod && (
          <Column>
            <p>
              <strong>Shipping details</strong>
              <br />
              {order.shippingMethod.name}
              {' - '}
              <Money
                amount={order.shippingMethod.price.amount}
                currency={order.shippingMethod.price.currency.iso}
              />

              {shipDate && (
                <>
                  <br />
                  Ship date: {shipDate.toLocaleDateString()}
                </>
              )}
            </p>
          </Column>
        )}

        <Column>
          <div>
            <strong>Payment details</strong>
            Paid:{' '}
            <Money
              amount={order.paymentBalance.amount}
              currency={order.paymentBalance.currency.iso}
            />
            <br />
            Remaining:{' '}
            <Money
              amount={order.amountRemaining.amount}
              currency={order.amountRemaining.currency.iso}
            />
            <br />
            {order.taxExemptReason && (
              <>
                Tax exempt reason: {order.taxExemptReason}
                <br />
              </>
            )}
            {order?.contact && order?.contact?.emailable ? (
              <>
                Email: {order.contact.email}
                <br />
              </>
            ) : (
              <>
                <>
                  Email: {order.account.email}
                  <br />
                </>
              </>
            )}
            {order?.salesperson && (
              <>
                Sold by: {order.salesperson}
                <br />
              </>
            )}
          </div>
          <div>
            <Payments payments={order.capturedPayments} />
          </div>
          {order.refunds && order.refunds.length > 0 && (
            <div>
              <Refunds refunds={order.refunds} />
            </div>
          )}
        </Column>
      </Shipping>
    </Item>
  )
}

Order.propTypes = {
  index: PropTypes.number.isRequired,
  order: PropTypes.object.isRequired,
}

const paymentPropType = PropTypes.shape({
  isTrade: PropTypes.bool,
  capturedAmount: PropTypes.shape({
    amount: PropTypes.string,
    currency: PropTypes.shape({
      iso: PropTypes.string,
    }),
  }),
  payment: PropTypes.shape({
    capturedAt: PropTypes.string,
    type: PropTypes.string,
    code: PropTypes.string,
  }),
})

const paymentOverrideTypes = {
  PaymentPrepaidCode: function PaymentPrepaidCode({ payment }) {
    return (
      <tr>
        <td>
          <DateTime date={new Date(payment.payment.capturedAt)} />
        </td>
        <td>{paymentTypeString(payment)}</td>
        <td>
          <Money
            amount={payment.capturedAmount.amount}
            currency={payment.capturedAmount.currency.iso}
          />
        </td>
        <td>{payment?.payment?.code || 'Code Not Found'}</td>
      </tr>
    )
  },
}

paymentOverrideTypes.PaymentPrepaidCode.propTypes = {
  payment: paymentPropType,
}

function DisplayPayment({ payment }) {
  return (
    <tr>
      <td>
        {payment?.payment ? (
          <DateTime date={new Date(payment.payment.capturedAt)} />
        ) : (
          <>Date not available</>
        )}
      </td>
      <td>
        {payment?.payment ? (
          paymentTypeString(payment)
        ) : (
          <>Type not available</>
        )}
      </td>
      <td>
        <Money
          amount={payment.capturedAmount.amount}
          currency={payment.capturedAmount.currency.iso}
        />
      </td>
      <td>{payment?.payment?.type ? payment?.payment?.type : '-'}</td>
    </tr>
  )
}

DisplayPayment.propTypes = {
  payment: paymentPropType,
}

function Payments({ payments }) {
  return (
    <>
      <strong>Payments</strong>
      <Table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Type</th>
            <th>Amount</th>
            <th>Additional</th>
          </tr>
        </thead>
        <tbody>
          {payments.map((payment) => {
            let PaymentComponent
            if (paymentOverrideTypes[payment?.payment?.__typename]) {
              PaymentComponent =
                paymentOverrideTypes[payment?.payment?.__typename]
            } else {
              PaymentComponent = DisplayPayment
            }

            return <PaymentComponent key={payment.id} payment={payment} />
          })}
        </tbody>
      </Table>
    </>
  )
}

Payments.propTypes = {
  payments: PropTypes.arrayOf(paymentPropType),
}

function Refunds({ refunds }) {
  return (
    <>
      <strong>Refunds</strong>
      <Table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          {refunds.map((refund) => (
            <tr key={refund.id}>
              <td>
                <DateTime date={new Date(refund.createdAt)} />
              </td>
              <td>
                <Money
                  amount={refund.refundedAmount.amount}
                  currency={refund.refundedAmount.currency.iso}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  )
}

Refunds.propTypes = {
  refunds: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      refundedAmount: PropTypes.shape({
        amount: PropTypes.string,
        currency: PropTypes.shape({
          iso: PropTypes.string,
        }),
      }).isRequired,
      createdAt: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default function Orders({ orders }) {
  return (
    <Wrapper>
      {orders.edges.map((order, i) => (
        <Order key={order.node.id} index={i + 1} order={order.node} />
      ))}
    </Wrapper>
  )
}

Orders.propTypes = {
  orders: PropTypes.shape({
    edges: PropTypes.arrayOf(
      PropTypes.shape({
        node: PropTypes.shape({
          id: PropTypes.string.isRequired,
          completedAt: PropTypes.string.isRequired,
          fullyPaidAt: PropTypes.string,
          number: PropTypes.string.isRequired,
          salesperson: PropTypes.string,
          account: PropTypes.shape({
            email: PropTypes.string,
          }),
          contact: PropTypes.shape({
            email: PropTypes.string,
            emailable: PropTypes.bool,
          }),
          seller: PropTypes.shape({
            id: PropTypes.string,
            username: PropTypes.string,
          }),
          paymentBalance: PropTypes.shape({
            amount: PropTypes.string,
            currency: PropTypes.shape({
              iso: PropTypes.string,
            }),
          }),
          amountRemaining: PropTypes.shape({
            amount: PropTypes.string,
            currency: PropTypes.shape({
              iso: PropTypes.string,
            }),
          }),
          amountRemainingLessRefunds: PropTypes.shape({
            amount: PropTypes.string,
            currency: PropTypes.shape({
              iso: PropTypes.string,
            }),
          }),
          lineItems: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.string.isRequired,
              state: PropTypes.string.isRequired,
              unitCost: PropTypes.shape({
                amount: PropTypes.string.isRequired,
                currency: PropTypes.shape({
                  iso: PropTypes.string.isRequired,
                }).isRequired,
              }).isRequired,
              cartNotes: PropTypes.string,
              listing: PropTypes.shape({
                item: PropTypes.shape({
                  title: PropTypes.string.isRequired,
                  sku: PropTypes.string,
                  slug: PropTypes.string.isRequired,
                  account: PropTypes.shape({
                    username: PropTypes.string.isRequired,
                  }).isRequired,
                }).isRequired,
              }).isRequired,
            })
          ).isRequired,
          shipment: PropTypes.shape({
            address: Address.propTypes.address.isRequired,
          }).isRequired,
          shippingMethod: PropTypes.shape({
            name: PropTypes.string.isRequired,
            price: PropTypes.shape({
              amount: PropTypes.string.isRequired,
              currency: PropTypes.shape({
                iso: PropTypes.string.isRequired,
              }).isRequired,
            }).isRequired,
          }),
          shipDate: PropTypes.string,
          taxExemptReason: PropTypes.string,
        }).isRequired,
      })
    ).isRequired,
  }).isRequired,
}
