import { useEffect, useState } from 'react'
import { useQuery } from 'urql'
import { useCartContext } from '../cart/CartContext'

const GET_RECENT_ITEMS = `
  fragment RecentlyViewedFields on Item {
    id
    title
    slug
    acceptingOffers
    offerDeclineThreshold
    inViewerWishlist

    images {
      id
      url(size: "orders")
    }

    account {
      id
      username
      displayName
      isPrivateSeller
      isProfessionalSeller
    }

    listing {
      id

      inViewerCart(orderId: $orderId)
      isAvailable

      askingPrice {
        amount
        currency { iso }
      }
    }
  }

  query RecentlyViewed($id1: ID!, $id2: ID!, $id3: ID!, $id4: ID!, $id5: ID!, $id6: ID!, $id7: ID!, $id8: ID!, $id9: ID!, $id10: ID!, $orderId: [ID!]) {
    recentlyViewed1: item(id: $id1) {
      ...RecentlyViewedFields
    }
    recentlyViewed2: item(id: $id2) {
      ...RecentlyViewedFields
    }
    recentlyViewed3: item(id: $id3) {
      ...RecentlyViewedFields
    }
    recentlyViewed4: item(id: $id4) {
      ...RecentlyViewedFields
    }
    recentlyViewed5: item(id: $id5) {
      ...RecentlyViewedFields
    }
    recentlyViewed6: item(id: $id6) {
      ...RecentlyViewedFields
    }
    recentlyViewed7: item(id: $id7) {
      ...RecentlyViewedFields
    }
    recentlyViewed8: item(id: $id8) {
      ...RecentlyViewedFields
    }
    recentlyViewed9: item(id: $id9) {
      ...RecentlyViewedFields
    }
    recentlyViewed10: item(id: $id10) {
      ...RecentlyViewedFields
    }
  }
`

const RECENT_ITEM_IDS_LOCALSTORAGE_KEY = 'recentItemIds'

const getRecentlyViewedItemIds = () => {
  let recentItemIds = []

  if (typeof window !== 'undefined') {
    try {
      let itemIds = localStorage.getItem(RECENT_ITEM_IDS_LOCALSTORAGE_KEY)

      if (itemIds) {
        itemIds = JSON.parse(itemIds)

        if (Array.isArray(itemIds)) {
          recentItemIds = itemIds
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  return recentItemIds
}

export const setRecentlyViewedItemId = (itemId) => {
  const recentItemIds = getRecentlyViewedItemIds()

  // Remove current item ID from recently viewed (if already there)
  if (recentItemIds.includes(itemId)) {
    recentItemIds.splice(recentItemIds.indexOf(itemId), 1)
  }

  // Add current item ID as first entry in recently viewed
  recentItemIds.unshift(itemId)

  // Re-save recently viewed item IDs
  try {
    localStorage.setItem(
      RECENT_ITEM_IDS_LOCALSTORAGE_KEY,
      JSON.stringify(recentItemIds)
    )
  } catch (error) {
    console.error(error)
  }
}

export default function useRecentlyViewed() {
  const [firstRender, setFirstRender] = useState(true)
  const recentItemIds = getRecentlyViewedItemIds()

  // Fill object with keys id1-10 with recently viewed item IDs, padding remainder with blank values
  const variables = Object.fromEntries(
    Array.from(Array(10)).map((_, index) => [
      `id${index + 1}`,
      recentItemIds[index] || '',
    ])
  )

  const { openOrderIds } = useCartContext()

  variables.orderId = openOrderIds

  const [{ data, fetching, error }] = useQuery({
    query: GET_RECENT_ITEMS,
    variables,
    // Only send the request if recent item IDs exist
    pause: recentItemIds.length === 0,
  })

  // For SSR, need first render to return loading state so HTML matches on server/client
  useEffect(() => {
    setFirstRender(false)
  }, [firstRender])

  if (error) {
    console.error(error)
  }

  if (recentItemIds.length === 0) {
    return { items: [], fetching: firstRender || fetching }
  }

  // Filter out any blank items caused by padding id1-10 with blank values
  const items = data ? Object.values(data).filter(Boolean) : []

  return { items, fetching: firstRender || fetching, error }
}
