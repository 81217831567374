import { subYears, startOfDay } from 'date-fns'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { up } from 'styled-breakpoints'
import { useQuery } from 'urql'
import Alert, { Wrapper as AlertWrapper } from '../shared/Alert'
import { Wrapper as FieldWrapper } from '../forms/Field'
import { InputContainer, InputLabel } from '../_atoms/Input'
import Money from '../data/Money'

const GET_PRICE_SUGGESTION = `
  query GetPriceSuggestion($input: CatalogProductSearchAttributes!, $after: Date!) {
    itemPriceSuggestion(input: $input, after: $after) {
      min {
        sale {
          date
          amount {
            amount
            currency { iso }
          }
        }
      }
      max {
        sale {
          date
          amount {
            amount
            currency { iso }
          }
        }
      }
    }
  }
`

const Wrapper = styled.div`
  padding-top: 24px;

  ${up('small')} {
    ${AlertWrapper} {
      max-width: 330px;
    }
  }

  ${up('medium')} {
    display: flex;
    align-items: center;

    ${FieldWrapper} {
      margin-top: 5px;
    }

    ${InputContainer} {
      margin-right: 14px;
      margin-bottom: 0;
      flex-basis: 0;
      flex-grow: 1;

      &:last-child {
        margin-right: 0;
      }
    }

    ${InputLabel} {
      margin-top: -29px;
    }

    ${AlertWrapper} {
      flex-basis: 0;
      flex-grow: 1;
      margin-left: 14px;
    }
  }
`

const PriceSuggestion = ({
  children,
  categoryId,
  brandId,
  modelId,
  year,
  handedness,
  originalFinish,
  originalParts,
  builtInElectronics,
  bodyShapeId,
}) => {
  // Filter out blank input values:
  const input = Object.fromEntries(
    Object.entries({
      categoryId,
      brandId,
      modelId,
      year,
      handedness,
      originalFinish,
      originalParts,
      builtInElectronics,
      bodyShapeId,
    }).filter(([_, value]) => value)
  )

  const [{ data, error }] = useQuery({
    query: GET_PRICE_SUGGESTION,
    variables: {
      input,
      after: startOfDay(subYears(new Date(), 3)), // 3 years ago
    },
  })

  const [firstRender, setFirstRender] = useState(true)

  // For SSR, need first render to return loading state so HTML matches on server/client
  useEffect(() => {
    setFirstRender(false)
  }, [firstRender])

  if (firstRender) return null

  if (error) {
    console.error(error)
    // Allow the price input to render, just without suggestions
    // return <p>{error.message}</p>
  }

  const values = data?.itemPriceSuggestion

  return (
    <Wrapper>
      {children}
      {values?.max?.sale.amount && (
        <Alert>
          Instruments similar to your description sold{' '}
          {(values?.min?.sale.amount && (
            <>
              between{' '}
              <Money
                amount={values.min.sale.amount.amount}
                currency={values.min.sale.amount.currency.iso}
              />{' '}
              and{' '}
            </>
          )) || <>for </>}
          <Money
            amount={values.max.sale.amount.amount}
            currency={values.max.sale.amount.currency.iso}
          />{' '}
          in the last year
        </Alert>
      )}
    </Wrapper>
  )
}

PriceSuggestion.propTypes = {
  children: PropTypes.node,
  categoryId: PropTypes.string,
  brandId: PropTypes.string,
  modelId: PropTypes.string,
  year: PropTypes.number,
  handedness: PropTypes.oneOf(['RIGHT_HANDED', 'LEFT_HANDED']),

  // ItemForm doesn't currently have fields for these, so they won't be passed in (but are supported as input to itemPriceSuggestion if needed):
  originalFinish: PropTypes.bool,
  originalParts: PropTypes.bool,
  builtInElectronics: PropTypes.bool,
  bodyShapeId: PropTypes.string,
}

export default function PriceSuggestionWrapper(props) {
  const [showContent, setShowContent] = useState(false)

  // Wait until after client-side hydration to show, otherwise the date query may mismatch on the
  // server/client and cause the resulting HTML to be different
  useEffect(() => {
    setShowContent(true)
  }, [setShowContent])

  if (!showContent) return null

  return <PriceSuggestion {...props} />
}

PriceSuggestionWrapper.propTypes = PriceSuggestion.propTypes
