import styled, { css } from 'styled-components'

import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { Media, MediaImage } from '../_base/Media'
import Money from '../data/Money'
import { BodyS, HeaderSansXXS } from '../_abstracts/Type'
import { Icon, SettingsIcon, SearchIcon } from '../_base/Icon'
import Transition from '../_abstracts/Animation'
import SellerRoundel from './SellerRoundel'
import Button, { ButtonStyle } from '../_atoms/Button'
import Avatar from '../shared/Avatar'
import WishlistButton, {
  Button as StyledWishlistButton,
} from '../shop/WishlistButton'

export const minCardWidth = '205px'

const ViewButton = styled.div`
  position: absolute;
  left: 50%;
  bottom: 10px;
  transform: translate(-50%, 10px);
  width: 90%;
  opacity: 0;
  will-change: opacity, transform;
  ${Transition({ property: 'opacity, transform' })}

  ${ButtonStyle} {
    &:hover {
      color: ${({ theme }) => theme.colors.darkBlue};
    }
  }
`

export const CardWrapper = styled.div`
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 33%;
  min-width: ${minCardWidth};
  border: 1px solid ${(props) => props.theme.colors.lightBtnGrey};
  border-radius: 5px;
  ${Transition({ property: 'border' })}
  text-decoration: none;
  color: ${({ theme }) => theme.colors.darkBlue};
  background-color: ${(props) => props.theme.colors.white};
  overflow: hidden;

  ${StyledWishlistButton} {
    position: absolute;
    z-index: 1;
    top: 10px;
    right: 10px;
    width: 26px;
    height: 26px;
  }

  ${StyledWishlistButton} {
    ${Transition({ property: 'opacity, border-color' })}
    background-color: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.blueTint};
    border-radius: 50%;
    opacity: ${({ inViewerWishlist }) => (inViewerWishlist ? 1 : 0)};

    &:hover {
      border-color: ${({ theme }) => theme.colors.darkBlue};
    }
  }

  @media (hover: hover) {
    &:hover,
    &:focus-within {
      border: 1px solid ${(props) => props.theme.colors.accent};

      ${ViewButton} {
        opacity: 1;
        transform: translate(-50%, 0);
      }

      ${StyledWishlistButton} {
        opacity: 1;
      }
    }
  }

  @media (hover: none) {
    ${StyledWishlistButton} {
      opacity: 1;
    }
  }
`

const ListingInfo = styled.div`
  padding: 12px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`

const Profile = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`

const User = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${Icon} {
    opacity: 1;
    visibility: visible;
    position: static;
    width: 15px;
  }

  circle,
  path {
    stroke: ${(props) => props.theme.colors.accent};
  }

  p {
    ${BodyS}
    color: ${(props) => props.theme.colors.accent};
    padding-left: 10px;
  }
`

const Settings = styled.button`
  position: static;
  width: 24px;
  height: 24px;
  display: inline-block;
  cursor: pointer;

  ${Icon} {
    position: static;
    width: 24px;
    height: 24px;
    opacity: 1;
    visibility: visible;
  }
`

const Title = styled.h2`
  ${BodyS};

  a {
    &::before {
      position: absolute;
      inset: 0;
      content: '';
    }
  }
`

const Stats = styled.div`
  padding-top: 15px;
  margin-top: auto;

  > *:first-child {
    margin-right: 5px;
  }
`

const Stat = styled.div`
  margin-bottom: 5px;
`

const Price = styled.div`
  ${HeaderSansXXS};
  font-weight: 600;
`

const Label = styled.div`
  color: ${(props) => props.theme.colors.darkGrey};
  font-size: 12px;
`

const ImageWrapper = styled(Media)`
  flex-shrink: 0;
  background-color: ${({ theme }) => theme.colors.grey};

  ${({ hasImage }) =>
    (hasImage &&
      css`
        &::before {
          display: block;
          padding-top: 100%;
          content: '';
        }
      `) ||
    css`
      // Tall enough that overlaid buttons are fully visible
      height: 215px;
    `}
`

export default function ListingCard({
  askingPrice,
  linkType,
  savedSearch,
  item,
}) {
  const image = item.images?.[0]

  return (
    <CardWrapper inViewerWishlist={item.inViewerWishlist}>
      <WishlistButton
        itemId={item.id}
        inViewerWishlist={item.inViewerWishlist}
      />
      <ImageWrapper hasImage={!!image}>
        {image && (
          <MediaImage src={image.url} alt={item.title || ''} loading="lazy" />
        )}

        <ViewButton>
          <Button as="span" variant="secondary" fullWidth>
            View
          </Button>
        </ViewButton>
      </ImageWrapper>
      <ListingInfo>
        {savedSearch && (
          <Profile>
            <User>
              <SearchIcon />
              <p>Saved search</p>
            </User>

            {/* TODO: What should settings button do on click? */}
            <Settings>
              <SettingsIcon />
            </Settings>
          </Profile>
        )}
        <Title>
          {(linkType === 'listing' && (
            <Link to={`/shop/${item.slug}/${item.id}`}>{item.title}</Link>
          )) ||
            (linkType === 'item' && (
              <Link to={`/item/${item.id}`}>{item.title}</Link>
            )) ||
            item.title}
        </Title>
        <Stats>
          {askingPrice ? (
            <Stat>
              <Label>Price</Label>
              <Price>
                <Money
                  amount={askingPrice.amount}
                  currency={askingPrice.currency.iso}
                />
              </Price>
            </Stat>
          ) : null}
          <Stat>
            <SellerRoundel user={item.account} />
          </Stat>
        </Stats>
      </ListingInfo>
    </CardWrapper>
  )
}

ListingCard.defaultProps = {
  linkType: 'listing',
}

ListingCard.propTypes = {
  askingPrice: PropTypes.shape({
    amount: PropTypes.string,
    currency: PropTypes.shape({
      iso: PropTypes.string,
    }),
  }),
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    images: PropTypes.arrayOf(
      PropTypes.shape({ url: PropTypes.string.isRequired })
    ),
    userData: PropTypes.shape(Avatar.propTypes),
    account: PropTypes.shape({
      id: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired,
      displayName: PropTypes.string,
      isPrivateSeller: PropTypes.bool.isRequired,
      isProfessionalSeller: PropTypes.bool.isRequired,
    }),
    inViewerWishlist: PropTypes.bool,
  }).isRequired,
  savedSearch: PropTypes.bool,
  linkType: PropTypes.oneOf(['listing', 'item', 'none']),
}
