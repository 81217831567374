import PropTypes from 'prop-types'
import { useState } from 'react'
import ReactModal from 'react-modal'

import styled from 'styled-components'

import Button from '../_atoms/Button'
import { Icon, ShareIcon, CrossIcon } from '../_base/Icon'
import Share from './Share'

const Wrapper = styled.div`
  display: inline-block;
  position: relative;
`

const customStyles = {
  overlay: {
    background: 'rgba(6, 6, 45, 0.45)',
  },
}

const Container = styled(ReactModal)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  visibility: ${(props) => (props.isOpen ? 'visible' : 'hidden')};
  transition: opacity 150ms ease-in-out;
`

const Content = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 30px 40px 40px 40px;
  width: fit-content;
  max-width: 90%;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > *:last-child {
    margin-top: 10px;
  }
`

const Close = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 1;
  background-color: transparent;
  font-size: 0;
  width: auto;
  height: auto;
  border: 0;
  cursor: pointer;
  transition: 0.15s;

  &:hover {
    transform: scale(1.1);
  }

  ${Icon} {
    width: 14px;
    height: 14px;
  }
`

const Trigger = styled.div`
  > * {
    min-width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover path {
      fill: currentColor;
      stroke: currentColor;
    }

    ${Icon} {
      margin-right: 10px;
    }
  }

  path {
    fill: currentColor;
    stroke: currentColor;
  }
`

export default function ShareButton({ url, img, text }) {
  const [modalIsOpen, setIsOpen] = useState(false)

  return (
    <Wrapper>
      <Container
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(false)}
        style={customStyles}
      >
        <Content>
          <Close onClick={() => setIsOpen(false)}>
            <CrossIcon />
          </Close>
          <h2>Share it with others</h2>
          <Share url={url} img={img} text={text} />
        </Content>
      </Container>

      <Trigger>
        <Button variant="secondary" onClick={() => setIsOpen(true)}>
          {' '}
          <ShareIcon />
          Share
        </Button>
      </Trigger>
    </Wrapper>
  )
}

ShareButton.propTypes = {
  url: PropTypes.string,
  img: PropTypes.string,
  text: PropTypes.string,
}
