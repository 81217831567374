import styled from 'styled-components'
import PropTypes from 'prop-types'
import { BodyS, HeaderXS } from '../_abstracts/Type'

const Wrapper = styled.div`
  ${BodyS}
  border: 1px solid ${(props) => props.theme.colors.midGrey};
  border-radius: ${(props) => props.theme.radii};
`

const Header = styled.div`
  ${HeaderXS}
  color: ${(props) => props.theme.colors.black};
  font-weight: 600;
  background-color: ${(props) => props.theme.colors.lightGrey};
  border-bottom: 1px solid ${(props) => props.theme.colors.midGrey};
  padding: 10px 15px;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
`

const Text = styled.div`
  padding: 10px 15px;

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  li + li {
    margin-top: 10px;
  }
`

export default function Links({ header, children }) {
  return (
    <Wrapper>
      <Header>{header}</Header>

      <Text>{children}</Text>
    </Wrapper>
  )
}

Links.propTypes = {
  header: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}
