import { Navigate, useParams, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import { useQuery } from 'urql'
import { useGlobals } from '../hooks'
import Container from '../_helpers/Container'
import Spacer from '../_helpers/Spacer'
import FormWrapper from '../checkout/FormWrapper'
import Orders, { Column } from '../checkout/Orders'
import Hero, { Header as HeroHeader, HeroMedia } from '../shared/Hero'
import Loader from '../shared/Loader'
import { colors } from '../theme'
import Button from '../_atoms/Button'
import Back from '../shared/Back'

const Wrapper = styled.div`
  @media print {
    ${HeroHeader},
    ${HeroMedia} {
      display: none;
    }

    ${Column} {
      max-width: 33%;
    }
  }
`

export const ORDERS_QUERY = `
  query CompletedOrders($orderIds: [ID!]!, $filter: OrderListFilter!) {
    orders(ids: $orderIds, filter: $filter) {
      edges {
        node {
          id
          number
          completedAt
          fullyPaidAt
          analyticsShown

          lineItems {
            id
            state
            quantity
            unitCost {
              amount
              currency { iso }
            }
            amount {
              amount
            }

            cartNotes

            listing {
              id
              item {
                id
                slug
                title
                sku

                images {
                  id
                  url(size: "product")
                }

                account {
                  id
                  username
                  displayName
                }
              }
            }
          }

          contact {
            id
            email
            emailable
            firstName
            lastName
          }

          salesperson

          account {
            id
            email
          }

          seller {
            id
            username
          }

          subTotal {
            amount
            currency { iso }
          }

          paymentBalance {
            amount
            amountCents
            currency { iso }
          }

          amountRemaining {
            amount
            currency { iso }
          }

          amountRemainingLessRefunds {
            amount
            currency { iso }
          }

          tax {
            amount
            currency { iso }
          }

          shippingCost {
            amount
            currency { iso }
          }

          discountTotal {
            amount
            amountCents
            currency { iso }
          }

          total {
            amount
            currency { iso }
          }

          canSetTracking

          shipment {
            address {
              firstName
              lastName
              company
              phone
              line1
              line2
              city
              region { name }
              country { name }
              postalCode
            }

            id
            trackingNumber
            carrierName
          }

          shippingMethod {
            name
            price {
              amount
              currency { iso }
            }
          }

          shipDate
          taxExemptReason

          capturedPayments {
            id

            isTrade

            capturedAmount {
              amount
              currency { iso }
            }

            payment {
              id
              __typename

              capturedAt

              ...on PaymentPrepaidCode {
                code
              }

              ...on PaymentStripe {
                terminal
              }

              ...on PaymentManual {
                type
              }
            }
          }

          refunds {
            id

            refundedAmount {
              amount
              currency { iso }
            }

            createdAt
          }
        }
      }
    }
  }
`

export default function OrderDetailsPage() {
  const { orderId } = useParams()
  const { checkoutConfirmation, fetching: globalsFetching } = useGlobals(
    'checkoutConfirmation'
  )
  const [searchParams] = useSearchParams()

  let backUrl = '/profile/buying/purchases'
  let filter = 'AS_BUYER'
  if (searchParams.get('view') === 'seller') {
    backUrl = '/profile/selling/orders'
    filter = 'AS_SELLER'
  }
  const [{ fetching, error, data }] = useQuery({
    query: ORDERS_QUERY,
    variables: {
      orderIds: [orderId],
      filter,
    },
  })

  if (fetching || globalsFetching)
    return <Loader background={colors.xLightGrey} height="250px" />
  if (error) return <p>{error.message}</p>

  if (data.orders.edges.length === 0) return <Navigate to={backUrl} />

  return (
    <Wrapper>
      <Container>
        <FormWrapper>
          <Hero
            animate={false}
            hero={{
              header: 'Your Order',
              image: checkoutConfirmation.backgroundImage[0]?.url,
              alt: '',
              icon: true,
            }}
          >
            <Button onClick={() => window.print()}>Print Order</Button>
          </Hero>
          <Back header="Back to Orders" size="small" url={backUrl} wrapped />
          <Orders orders={data.orders} />
        </FormWrapper>
      </Container>
      <Spacer fromSize="40px" toSize="80px" />
    </Wrapper>
  )
}

OrderDetailsPage.propTypes = {}
