import PropTypes from 'prop-types'
import styled from 'styled-components'
import { HeaderL, linkStates } from '../_abstracts/Type'
import { down } from 'styled-breakpoints'
import { CardWrapper as Card } from './ListingCard'

const Wrapper = styled.div`
  margin: 0 !important;

  ${Card} {
    background-color: ${(props) => props.theme.colors.white};
    border: 1px solid ${(props) => props.theme.colors.midGrey};
    box-shadow: ${(props) => props.theme.shadows.small};
  }
`

const Inner = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const Intro = styled.div`
  padding: 40px;
  width: 45%;
  background-color: ${(props) => props.theme.colors.blueTint};
  border-right: 1px solid ${(props) => props.theme.colors.midGrey};

  ${down('small')} {
    padding: 20px;
    width: 100%;
  }
`

const Header = styled.h2`
  ${HeaderL};
  margin-bottom: 20px;
`

const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 55%;

  ${down('small')} {
    width: 100%;
  }

  &:first-child {
    width: 100%;
  }

  > * {
    flex-grow: 1;
  }
`

const Footer = styled.div`
  background-color: ${(props) => props.theme.colors.lightGrey};
  border-top: 1px solid ${(props) => props.theme.colors.midGrey};
  padding: 20px;
  text-align: right;
  min-height: 70px;

  ${down('small')} {
    min-height: 0;
  }
`

export const FooterLink = styled.button`
  ${linkStates}
  margin-right: 30px;
  font-size: 14px;
`

export default function Base({ header, content, children, footer }) {
  return (
    <Wrapper>
      <Inner>
        {(header || content) && (
          <Intro>
            {header && <Header>{header}</Header>}
            {content}
          </Intro>
        )}

        <Main>{children}</Main>
      </Inner>

      {footer && <Footer>{footer}</Footer>}
    </Wrapper>
  )
}

Base.propTypes = {
  header: PropTypes.string.isRequired,
  content: PropTypes.node,
  children: PropTypes.node.isRequired,
  footer: PropTypes.node,
}
