import { useRef, useState } from 'react'
import { Formik } from 'formik'
import toast from 'react-hot-toast'
import styled from 'styled-components'
import { useMutation } from 'urql'
import Button from '../_atoms/Button'
import Form from '../_atoms/Form'
import Field from '../forms/Field'
import { Fields } from '../forms/Fieldset'
import Upload, { uploadFiles } from '../inputs/Upload'

const UPDATE_USER = `
  mutation UpdateUserImages($account: AccountUpdateInput!) {
    accountUpdate(account: $account) {
      success
      account {
        id

        avatarImage {
          id
        }

        bannerImage {
          id
        }
      }
    }
  }
`

const Avatar = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  li {
    width: 150px;
  }

  img {
    height: 150px;
    border-radius: 50%;
  }

  button[aria-label='Remove file'] {
    display: none;
  }
`

const Banner = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  img {
    height: 55px;
  }

  button[aria-label='Remove file'] {
    display: none;
  }
`

export default function UserImageUpload() {
  const [, updateUser] = useMutation(UPDATE_USER)
  const [fetching, setFetching] = useState(false)
  const [lastAvatarUploadId, setLastAvatarUploadId] = useState()
  const [lastBannerUploadId, setLastBannerUploadId] = useState()

  const avatarRef = useRef()
  const bannerRef = useRef()

  async function uploadImage(ref) {
    if (ref.current.items.length) {
      const uploads = await uploadFiles({ ref })
      return uploads?.[0]?.uploadId
    }
  }

  async function handleSubmit() {
    setFetching(true)

    try {
      let avatarUploadId = await uploadImage(avatarRef)
      if (avatarUploadId === lastAvatarUploadId) avatarUploadId = undefined

      let bannerUploadId = await uploadImage(bannerRef)
      if (bannerUploadId === lastBannerUploadId) bannerUploadId = undefined

      toast.loading('Updating profile images', { id: 'userImages' })

      const { data, error } = await updateUser({
        account: {
          avatarUploadId,
          bannerUploadId,
        },
      })

      if (!data?.accountUpdate?.success) {
        throw new Error(error)
      }

      toast.success('Profile images updated', { id: 'userImages' })

      // Avoid sending the same upload ID if the form is submitted again (not valid to reuse)
      if (avatarUploadId) setLastAvatarUploadId(avatarUploadId)
      if (bannerUploadId) setLastBannerUploadId(bannerUploadId)
    } catch (err) {
      toast.error('Unable to update profile images', { id: 'userImages' })
    }

    setFetching(false)
  }

  return (
    <Formik
      initialValues={{ avatarImage: '', bannerImage: '' }}
      onSubmit={handleSubmit}
    >
      <Form>
        <Fields>
          <Field align="center" width="half">
            <Avatar>
              <Upload
                ref={avatarRef}
                label="Avatar Image"
                name="avatarImage"
                multiple={false}
              />
            </Avatar>
          </Field>
          <Field align="center" width="half">
            <Banner>
              <Upload
                ref={bannerRef}
                label="Banner Image"
                name="bannerImage"
                multiple={false}
              />
            </Banner>
          </Field>
          <Field align="center">
            <div>
              <Button disabled={fetching} submit variant="primaryDark">
                Upload
              </Button>
            </div>
          </Field>
        </Fields>
      </Form>
    </Formik>
  )
}
