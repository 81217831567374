import { useMemo } from 'react'
import { Helmet } from 'react-helmet-async'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Link from '../_atoms/Link'

const List = styled.ul`
  display: flex;
  font-size: 0;
`

const Item = styled.li`
  &::after {
    margin-right: 0.5em;
    margin-left: 0.5em;
    font-size: 14px;
    content: '>';
  }

  &:last-child::after {
    content: '';
  }

  a {
    font-size: 14px;

    &[aria-current='page'] {
      opacity: 0.5;
    }
  }
`

export default function Breadcrumbs({ items }) {
  const jsonLd = useMemo(
    () =>
      JSON.stringify({
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: items.map(({ text, url }, index) => ({
          '@type': 'ListItem',
          position: index + 1,
          name: text,
          item: url,
        })),
      }),
    [items]
  )

  return (
    <>
      <Helmet>
        <script type="application/ld+json">{jsonLd}</script>
      </Helmet>
      <List>
        {items.map(({ text, url }, index) => (
          <Item key={url}>
            <Link
              to={url}
              aria-current={index === items.length - 1 ? 'page' : undefined}
              $reverse
            >
              {text}
            </Link>
          </Item>
        ))}
      </List>
    </>
  )
}

Breadcrumbs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      url: PropTypes.string,
    })
  ),
}
