import { useUserContext } from '../auth/UserContext'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const Wrapper = styled.p`
  text-align: center;
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
    margin-top: 10px;
  }

  a {
    color: red;
    font-size: 90%;
    margin: 0 3px;
    text-decoration: underline;
  }
`

export default function ButtonOpenAdmin({ item }) {
  const { user } = useUserContext()
  if (!user?.isAdmin) return null

  let editLink = null
  if (item?.account?.id === user?.id) {
    if (item?.listing) {
      editLink = <Link to={`/selling/list-item/${item.id}`}>Edit listing</Link>
    } else {
      editLink = (
        <Link to={`/profile/portfolio/${item.id}/edit`}>Edit item</Link>
      )
    }
  }

  return (
    <Wrapper>
      <Link to={`/admin/exchange/items/${item.id}`} target="_blank">
        View in admin
      </Link>
      {editLink}
    </Wrapper>
  )
}

ButtonOpenAdmin.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    account: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
    listing: PropTypes.object,
  }).isRequired,
}
