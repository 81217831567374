import {
  cloneElement,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { useLocation } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Transition from '../_abstracts/Animation'
import { Icon, CrossIcon } from '../_base/Icon'
import ReactModal from 'react-modal'
import { useScrollBlock } from '../hooks'
import { down } from 'styled-breakpoints'

ReactModal.setAppElement('#root')

const transitionDuration = 200

export const Wrapper = styled.div`
  display: inline-block;
`

const customStyles = {
  overlay: {
    background: 'rgba(6, 6, 45, 0.45)',
  },
}

const Overlay = styled.section`
  ${Transition({ property: 'opacity', duration: `${transitionDuration}ms` })};
  z-index: 1;
  display: flex;
  padding: 2%;
  overflow-x: hidden;
  overflow-y: scroll;
  opacity: ${({ state }) => (state === 'entered' ? 1 : 0)};
`

const Container = styled(ReactModal)``

const Content = styled.div`
  position: relative;
  width: calc(100% - 20px);
  max-width: 700px;
  margin: auto;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 4px;
  overflow: hidden;

  ${(props) =>
    (props.size === 'large' &&
      css`
        max-width: 1000px;
      `) ||
    (props.size === 'standard' &&
      css`
        max-width: 750px;
      `) ||
    (props.size === 'medium' &&
      css`
        max-width: 600px;
      `) ||
    (props.size === 'small' &&
      css`
        max-width: 300px;
      `) ||
    (props.size === 'full' &&
      css`
        max-width: 100%;
      `)}

  ${down('small')} {
    width: 100%;
  }
`

export const Close = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 1;
  padding: 5px;
  background-color: transparent;
  font-size: 0;
  width: auto;
  height: auto;
  border: 0;
  cursor: pointer;
  transition: 0.15s;
  border-radius: 4px;

  &:hover {
    transform: scale(1.1);
  }

  ${Icon} {
    width: 14px;
    height: 14px;
  }
`

const Modal = forwardRef(
  (
    {
      children,
      className,
      trigger,
      onTriggerClick,
      hideClose,
      size = 'medium',
    },
    ref
  ) => {
    const overlayRef = useRef(null)
    const [modalIsOpen, setIsOpen] = useState(false)
    const { blockScroll, allowScroll } = useScrollBlock()

    useImperativeHandle(ref, () => ({
      close: () => {
        setIsOpen(false)
      },
      open: () => {
        setIsOpen(true)
      },
    }))

    // Close modal when page changes (e.g. if clicking 'Checkout' link in cart modal):
    const location = useLocation()
    useEffect(() => {
      setIsOpen(false)
    }, [location, setIsOpen])

    const onContentClick = (ev) => {
      if (ev.target.closest('[data-modal-close]')) {
        ev.stopPropagation()
        setIsOpen(false)
      }
    }

    return (
      <>
        <CSSTransition
          nodeRef={overlayRef}
          in={modalIsOpen}
          timeout={{ exit: transitionDuration }}
          mountOnEnter
          unmountOnExit
        >
          {(state) => (
            <Container
              className={className}
              isOpen={modalIsOpen}
              onRequestClose={() => setIsOpen(false)}
              style={customStyles}
              overlayElement={(props, contentElement) => (
                <Overlay {...props} state={state} ref={overlayRef}>
                  {contentElement}
                </Overlay>
              )}
              contentElement={(props, children) => (
                <Content onClick={onContentClick} size={size} {...props}>
                  {!hideClose && (
                    <Close onClick={() => setIsOpen(false)}>
                      <CrossIcon />
                    </Close>
                  )}

                  {children}
                </Content>
              )}
              onAfterOpen={blockScroll}
              onAfterClose={allowScroll}
              closeTimeoutMS={transitionDuration}
            >
              {children}
            </Container>
          )}
        </CSSTransition>

        {trigger &&
          cloneElement(trigger, {
            onClick: () => {
              setIsOpen(true)
              onTriggerClick?.()
            },
          })}
      </>
    )
  }
)

Modal.displayName = 'Modal'

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  trigger: PropTypes.node,
  onTriggerClick: PropTypes.func,
  size: PropTypes.oneOf(['large', 'medium', 'standard', 'small', 'full']),
  hideClose: PropTypes.bool,
}

export default Modal
