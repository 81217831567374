import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import Transition from '../_abstracts/Animation'
import { Icon } from '../_base/Icon'
import Link from '../shared/Link'

const disabledStyles = css`
  background-color: ${(props) => props.theme.colors.blueTint} !important;
  border-color: ${(props) => props.theme.colors.blueTint} !important;
  color: #979797;
  cursor: default;
  pointer-events: none;

  &::before,
  &::after {
    content: normal;
  }
`

export const ButtonStyle = styled.button`
  padding: 8px 16px;
  border-radius: 5px;
  border: 1px solid;
  cursor: pointer;
  display: inline-block;
  font-family: ${(props) => props.theme.fonts.sansSerif};
  font-size: 15px;
  text-align: center;
  text-decoration: none;
  overflow: hidden;
  text-transform: capitalize;
  /* z-index needed for hover effect background to show */
  z-index: 0;
  ${Transition({ property: 'background-color, color, border-color' })};

  & + & {
    margin-left: 0;
  }

  ${(props) =>
    (props.variant === 'primaryLight' &&
      css`
        color: ${(props) => props.theme.colors.white};
        border-color: ${(props) => props.theme.colors.accent};
        background-color: transparent;
        position: relative;

        &::before,
        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          z-index: -1;
        }
        &::before {
          background-color: ${(props) => props.theme.colors.accent};
        }
        &::after {
          transform: translateX(-110%);
          background-color: ${(props) => props.theme.colors.white};
          ${Transition({ property: 'transform' })};
        }

        &:hover {
          border-color: ${(props) => props.theme.colors.darkBlue};
          color: ${(props) => props.theme.colors.darkBlue};

          &::after {
            transform: translateX(0%);
          }
        }

        &:active {
          background-color: ${(props) => props.theme.colors.lightBtnGrey};
          border-color: ${(props) => props.theme.colors.darkBlue};
          color: ${(props) => props.theme.colors.darkBlue};
        }
      `) ||
    (props.variant === 'primaryDark' &&
      css`
        border-color: ${(props) => props.theme.colors.darkBlue};
        color: ${(props) => props.theme.colors.white};
        background-color: transparent;
        position: relative;
        width: 20%;

        &::before,
        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          z-index: -1;
        }
        &::before {
          background-color: ${(props) => props.theme.colors.darkBlue};
        }
        &::after {
          transform: translateX(-110%);
          background-color: ${(props) => props.theme.colors.white};
          ${Transition({ property: 'transform' })};
        }

        &:hover {
          border-color: ${(props) => props.theme.colors.darkBlue};
          color: ${(props) => props.theme.colors.darkBlue};

          &::after {
            transform: translateX(0%);
          }
        }

        &:active {
          background-color: ${(props) => props.theme.colors.lightBtnGrey};
          border-color: ${(props) => props.theme.colors.darkBlue};
          color: ${(props) => props.theme.colors.darkBlue};
        }
      `) ||
    (props.variant === 'primaryRed' &&
      css`
        border-color: ${(props) => props.theme.colors.darkBlue};
        color: ${(props) => props.theme.colors.white};
        background-color: transparent;
        position: relative;

        &::before,
        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          z-index: -1;
        }
        &::before {
          background-color: ${(props) => props.theme.colors.darkBlue};
        }
        &::after {
          transform: translateX(-110%);
          background-color: ${(props) => props.theme.colors.accent};
          ${Transition({ property: 'transform' })};
        }

        &:hover {
          border-color: ${(props) => props.theme.colors.accent};
          color: ${(props) => props.theme.colors.white};

          &::after {
            transform: translateX(0%);
          }
        }

        &:active {
          background-color: ${(props) => props.theme.colors.lightBtnGrey};
          border-color: ${(props) => props.theme.colors.darkBlue};
          color: ${(props) => props.theme.colors.darkBlue};
        }
      `) ||
    (props.variant === 'secondary' &&
      css`
        background-color: ${(props) => props.theme.colors.white};
        border-color: ${(props) => props.theme.colors.accent};
        color: ${(props) => props.theme.colors.darkBlue};
        position: relative;

        &:hover {
          color: ${(props) => props.theme.colors.accent};
        }
        &:active {
          background-color: ${(props) => props.theme.colors.lightBtnGrey};
          color: ${(props) => props.theme.colors.xDarkGrey};
        }
      `) ||
    (props.variant === 'tertiary' &&
      css`
        border-color: ${(props) => props.theme.colors.darkBtnGrey};
        color: black;
        ${(props) => props.theme.colors.xDarkGrey};
        background-color: transparent;
        position: relative;

        &::before,
        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          z-index: -1;
        }
        &::before {
          background-color: ${(props) => props.theme.colors.darkBtnGrey};
        }
        &::after {
          transform: translateX(-110%);
          background-color: ${(props) => props.theme.colors.white};
          ${Transition({ property: 'transform' })};
        }

        &:hover {
          border-color: ${(props) => props.theme.colors.lightBtnGrey};

          &::after {
            transform: translateX(0%);
          }
        }
        &:active {
          background-color: ${(props) => props.theme.colors.darkBtnGrey};
          border-color: ${(props) => props.theme.colors.darkBtnGrey};
        }
      `) ||
    (props.variant === 'success' &&
      css`
        cursor: default;
        color: ${(props) => props.theme.colors.white};
        background-color: ${(props) => props.theme.colors.teal};
      `) ||
    (props.variant === 'small' &&
      css`
        border-color: ${(props) => props.theme.colors.accent};
        color: ${(props) => props.theme.colors.darkBlue};
        background-color: transparent;
        position: relative;
        padding: 0.3em 0.8em;
        font-size: 14px;

        &::before,
        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          z-index: -1;
        }
        &::before {
          background-color: ${(props) => props.theme.colors.accentTint};
        }
        &::after {
          transform: translateX(-110%);
          background-color: ${(props) => props.theme.colors.white};
          ${Transition({ property: 'transform' })};
        }

        &:hover {
          border-color: ${(props) => props.theme.colors.accent};
          color: ${(props) => props.theme.colors.darkBlue};

          &::after {
            transform: translateX(0%);
          }
        }

        &:active {
          background-color: ${(props) => props.theme.colors.lightBtnGrey};
          border-color: ${(props) => props.theme.colors.darkBlue};
          color: ${(props) => props.theme.colors.darkBlue};
        }
      `)}

  ${(props) =>
    props.$fullWidth
      ? css`
          width: 100%;
        `
      : css`
          width: fit-content;
        `}

  ${Icon} {
    display: inline-block;
    margin-right: 5px;
    width: 21px;
    pointer-events: none;
    vertical-align: middle;
  }

  ${Icon} path {
    will-change: fill, stroke;
    ${Transition({ property: 'fill, stroke' })}
  }

  &[disabled] {
    ${disabledStyles}
  }

  ${({ disabled }) => disabled && disabledStyles}
`

const Button = React.forwardRef(
  (
    {
      className,
      children,
      fullWidth,
      variant = 'primaryLight',
      submit = false,
      to = null,
      forceRegularLink,
      disabled,
      ...props
    },
    ref
  ) => {
    const type = submit ? 'submit' : 'button'
    const as = to ? Link : 'button'

    return (
      <ButtonStyle
        ref={ref}
        className={className}
        variant={variant}
        $fullWidth={fullWidth}
        type={type}
        as={as}
        to={to}
        forceRegular={forceRegularLink}
        disabled={disabled}
        {...props}
      >
        {children}
      </ButtonStyle>
    )
  }
)

export default Button

Button.displayName = 'Button'

Button.propTypes = {
  variant: PropTypes.oneOf([
    'primaryLight',
    'primaryDark',
    'primaryRed',
    'secondary',
    'small',
    'tertiary',
    'success',
  ]),
  fullWidth: PropTypes.bool,
  type: PropTypes.string,
  as: PropTypes.string,
  to: PropTypes.string,
  forceRegularLink: PropTypes.boolean,
  children: PropTypes.node,
  submit: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
}
