import styled from 'styled-components'
import PropTypes from 'prop-types'
import { HeaderM, HeaderSansXS } from '../_abstracts/Type'

const Wrapper = styled.div`
  flex-grow: 1;
  padding: 75px 20px 50px;
  background: ${(props) => props.theme.colors.lighterGrey};
`

const Inner = styled.div`
  position: relative;
  max-width: 600px;
  margin-right: auto;
  margin-left: auto;
  overflow: hidden;
  background: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.midGrey};
  border-radius: ${(props) => props.theme.radii};
  text-align: center;
`

const Header = styled.h2`
  ${HeaderM};
  font-weight: 700;
  padding: 15px;
  border-bottom: 1px solid ${(props) => props.theme.colors.midGrey};
  color: ${(props) => props.theme.colors.darkBlue};
  background-color: ${(props) => props.theme.colors.lightGrey};
`

const Children = styled.div`
  ${HeaderSansXS};
  padding: 25px;

  > * + * {
    margin-top: 20px;
  }

  a:not([class*='Button__ButtonStyle']) {
    color: ${(props) => props.theme.colors.accent};
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  p {
    max-width: 550px;
    margin-right: auto;
    margin-left: auto;
  }

  strong {
    font-weight: normal;
  }

  input {
    text-align: left;
  }
`

export default function FormWrapper({ header, children }) {
  return (
    <Wrapper>
      <Inner>
        <Header>{header}</Header>
        <Children>{children}</Children>
      </Inner>
    </Wrapper>
  )
}

FormWrapper.propTypes = {
  header: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}
