import { cloneElement } from 'react'
import PropTypes from 'prop-types'
import toast from 'react-hot-toast'
import { useMutation } from 'urql'
import { UserConsumer } from './UserContext'
import { useCartContext } from '../cart/CartContext'

const DESTROY_SESSION = `
  mutation DestroySession {
    sessionDestroy {
      success
    }
  }
`

export default function LogoutButton({ children }) {
  const [, destroySession] = useMutation(DESTROY_SESSION)
  const { clearOpenOrderIds } = useCartContext()

  return (
    <UserConsumer>
      {({ reloadUser }) => {
        return cloneElement(children, {
          onClick: async () => {
            await destroySession()
            clearOpenOrderIds()
            reloadUser('/')
            toast.success('Logged out')
          },
        })
      }}
    </UserConsumer>
  )
}

LogoutButton.propTypes = {
  children: PropTypes.node.isRequired,
}
